import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { VotingValidation } from "actions/controller/validation";
import config from 'lib/config'
import { toast, Slide, Zoom, Flip, Bounce } from "react-toastify";
import Footer from "components/Footer/Footer.js";
import {
    DataGrid,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Select from "react-select";
import { Account_Connect, Account_disConnect } from "actions/redux/action";
import { ChangeToVote, GetOwnerList, ReduceAndNewOwner } from "../actions/v1/Fractional"
import Calender from "../components/Calender/Calender"
import { Button } from "react-bootstrap";
import { network } from "../views/network";
import { useNFTBalCheckToken } from 'hooks/useMethods';
import { useDeployerCon, useTokenFractionApprove, useTransferReceiptFraction } from "hooks/useReceipts"
import { LMBalanceOfFraction, LMTokenTrasBack20, ContactMintFraction, LMTokenTransferBack } from 'actions/v1/token';
import Web3 from 'web3';
import lazyimg from "../assets/images/lazymintingnew/logodark.png";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { connectWallet, getServiceFees } from '../hooks/useWallet';
import Config from '../lib/config';
// import Slider from 'react-rangeslider';
// import 'react-rangeslider/lib/index.css';
import ProgressBar from 'react-bootstrap/ProgressBar';

toast.configure();

// Scroll to Top
function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return null;
}

const CreateVote = (props) => {
    console.log("propspropspropspropspropspropsprops", props);
    const Wallet_Details = useSelector((state) => state.wallet_connect_context);
    const dashboardRoutes = [];
    const { ...rest } = props;
    const useStyles = makeStyles(styles);
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const InitialForm = {
        VotingCat: "",
        VotingEndDate: "",
        VotingStartedDate: "",
        Description: "",
        SellAmount: 0,
        ShortName: "",
        tokenID: "",
        TokenOwner: "",
        WalletAddress: Wallet_Details.UserAccountAddr,
        CreateStatus: "Create",
        promote: 0,
        UniqueId: Date.now(),
        VoteCount: 0,
        BannerImage: {},
        Multiplefiles: {}
    };
    const now = 60;
    const [volumess, setvolumess] = useState(30);
    const handleOnChanges = (value) => {
        setvolumess(value)
    }
    const [Formdata, setFormdata] = useState(InitialForm);
    const [FaitRv, SetFiatRv] = useState([])
    const [ClaimList, SetClaimList] = useState([])
    const [YourRequest, SetYourRequest] = useState([])
    const [AucCalendar, setAucCalender] = useState("Start");
    const [PicStartselected, setPicStartselected] = useState(false);
    const [AuctionStart, setAuctionStart] = useState(null);
    const [AuctionEnd, setAuctionEnd] = useState(null);
    const [currentFractions, setcurrentFractions] = useState(props?.data)
    const [VotePer, setVotPer] = useState(0)
    const [HideShow, setHideShow] = useState(false)
    const [NewOwnerAddress, setNewOwnerAddress] = useState("")
    const [Imagefile, setImagefiles] = useState(undefined)
    const [MultipleImg, setMulipleImg] = useState(null)
    const [Errors, setErrors] = useState({})
    const [OwnersList, setOwnersList] = useState([])
    const [Status1, setStatus1] = useState("init")
    const [Status2, setStatus2] = useState("init")

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#fff",
            position: "relative",
            top: "3px",
            left: "0px",
            width: "100%",
            maxWidth: "100%",
            color: "#000"
        }),
        option: (provided, state) => ({
            ...provided,
            marginBottom: "5px",
            backgroundColor: "transparent",
            maxWidth: "356px",
            color: "#fff",
            borderBottom: "#fff",
            "&:hover": {
                transition: "all 0.5s ease",
                color: "#e50914"
            },
            "&:active": {
                transition: "all 0.5s ease",
                color: "#000"
            }
        }),
        control: (provided, state) => ({
            ...provided,
            border: "1px solid transparent",
            boxShadow: "0px 0px",
            "&:hover": {
                border: "1px solid transparent",
                boxShadow: "0px 0px",
            },
            "&:focus": {
                border: "1px solid transparent"
            }
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            border: "1px solid transparent"
        })
    }

    const [options, setoptions] = useState([
        { value: 'Selling Vote', label: 'Selling Vote' },
        { value: 'Community Vote', label: 'Community Vote' },
    ]);

    const [options2, setoptions2] = useState([
        { value: 'Community Vote', label: 'Community Vote' },
    ]);

    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOption1, setSelectedOption1] = useState(null);

    const [options1, setoptions1] = useState(props?.data?.VoteDetails);

    const ChangeStatus = async (Data) => {
        console.log("DataaaaDattttt", Data);
        window.$('#createavote_modal').modal('show');
        setoptions1(Data?.VoteDetails)
    }

    useEffect(() => {
        console.log("selectedOption", selectedOption1, currentFractions, selectedOption?.VotCategory);
        if (selectedOption1 !== null && selectedOption1?.value !== "No Data") {
            console.log("kadsdnaskjndkas");
            let Form = Formdata;
            Form = { ...Form, ...selectedOption1 };
            console.log("FormFormFormFormForm", Form);
            // Formdata.ShortName = selectedOption1.ShortName
            // Formdata.VotingCat = selectedOption1.VotCategory
            // Formdata.VotingEndDate = selectedOption1.VotingEndDate
            // Formdata.VotingStartedDate = selectedOption1.VotingStartedDate
            // Formdata.VoteMsg = selectedOption1.VoteMsg
            console.log("OwnersList.length", OwnersList);
            let Percentagecal = ((selectedOption1?.VoteCount || 0) / (OwnersList.length))
            // (currentFractions?.NFTDetailDet?.tokenQuantity || 0)

            let Multiply = Percentagecal * 100

            console.log("PercentagecalPercentagecal", Multiply);

            setVotPer(Math.round(Multiply))
            setAuctionStart(selectedOption1?.VotingStartedDate)
            setFormdata(Form)
            setAuctionEnd(selectedOption1?.VotingEndDate);

            setImagefiles(selectedOption1?.BannerImage)
            setMulipleImg(selectedOption1?.Multiplefiles)
            if (selectedOption1.tokenID) {
                GetTokenOwnerList(selectedOption1.tokenID)
            }
            localStorage.setItem("CreateStatus", "Update");
        } else {
            localStorage.setItem("CreateStatus", "Create");
        }

    }, [selectedOption1])
    useEffect(() => {
        console.log("OwnersList.length", OwnersList);
        let Percentagecal = ((selectedOption1?.VoteCount || 0) / (OwnersList.length))
        // (currentFractions?.NFTDetailDet?.tokenQuantity || 0)

        let Multiply = Percentagecal * 100

        console.log("PercentagecalPercentagecal", Multiply);

        setVotPer(Math.round(Multiply))
    }, [selectedOption1, OwnersList])
    const GetTokenOwnerList = async (tokenID) => {
        let Arg = {
            tokenID: tokenID
        }
        const GetList = await GetOwnerList(Arg)
        console.log("dncjkdsckjsdank", GetList);
        if (GetList.data.Success) {
            setOwnersList(GetList.data.Data)
        }
    }

    const ChildReply = (ChildData, Calstate) => {
        if (Calstate === "Start") {
            setAuctionStart(ChildData)
            Formdata.VotingStartedDate = ChildData;
        }
        else {
            setAuctionEnd(ChildData);
            Formdata.VotingEndDate = ChildData;
        }
    };

    const OnChangehandle = (e) => {
        e.preventDefault();
        const { id, value } = e.target;
        let Form = Formdata;

        if (id == "Multiplefiles") {
            const reader = new FileReader();
            let files = e.target.files
            console.log("filesfghfhbhgjh", files)
            Form = { ...Form, ...{ [id]: files } }
            setMulipleImg([...files])
        }
        if (id == "BannerImage") {
            const reader = new FileReader();
            let files = e.target.files[0]
            console.log("filesfghfhbhgjh", files)
            Form = { ...Form, ...{ [id]: files } }
            setImagefiles(files)
        }
        Form = { ...Form, ...{ [id]: value } };

        setFormdata(Form);
        console.log("dkjsamdmas", Form);

    };

    const OnSubmited = async (data, sat) => {
        console.log("Forrrrrrrrr", data, Formdata, selectedOption);
        Formdata.VotingCat = selectedOption
        Formdata.tokenID = data.tokenID
        Formdata.TokenOwner = data.tokenOwner
        Formdata.WalletAddress = Wallet_Details.UserAccountAddr
        Formdata.Multiplefiles = MultipleImg
        Formdata.BannerImage = Imagefile
        Formdata.CreateStatus = sat ? sat == "Stop" ? "Stop" : "Cancel" : localStorage.getItem("CreateStatus")
        const validcheck = await VotingValidation(Formdata)
        console.log("validcheck", validcheck);
        if (validcheck.Success) {
            let VoteTochange = await ChangeToVote(Formdata)
            console.log("VoteTochange", VoteTochange);
            if (VoteTochange.data.Success) {
                toast.success(VoteTochange.data.message)
                window.$('#createavote_modal').modal('hide');
                setFormdata({
                    VotingCat: "",
                    VotingEndDate: "",
                    VotingStartedDate: "",
                    Description: "",
                    SellAmount: 0,
                    ShortName: "",
                    promote: 0,
                    tokenID: "",
                    TokenOwner: "",
                    WalletAddress: Wallet_Details.UserAccountAddr,
                    CreateStatus: "Create",
                    UniqueId: Date.now()
                })
                // GetFaitReveueDetails()
                // if (sat == "Stop") {

                // } else {
                dispatch({
                    type: Account_Connect,
                    Account_Detail: {
                        setshowvote: false
                    }
                })
                // }
            } else {
                toast.error(VoteTochange.data.message)
                window.$('#createavote_modal').modal('hide');
                setFormdata({
                    VotingCat: "",
                    VotingEndDate: "",
                    VotingStartedDate: "",
                    Description: "",
                    SellAmount: 0,
                    ShortName: "",
                    promote: 0,
                    tokenID: "",
                    TokenOwner: "",
                    WalletAddress: Wallet_Details.UserAccountAddr,
                    CreateStatus: "Create",
                    UniqueId: Date.now()
                })
                dispatch({
                    type: Account_Connect,
                    Account_Detail: {
                        setshowvote: false
                    }
                })
                // GetFaitReveueDetails()
            }
            setErrors({})
        } else {
            setErrors(validcheck)
            toast.error("Validation failed")
        }
    }

    const [SelectAndShow, SetSelectAndShow] = useState(false)

    const handleChange = (selectedOption) => {
        setSelectedOption1(selectedOption);
        localStorage.setItem("CancelShow", "Show");
        let SelectedCatt = selectedOption?.VotCategory == "Selling Vote" ? { value: 'Selling Vote', label: 'Selling Vote' } : { value: 'Community Vote', label: 'Community Vote' }
        setoptions([SelectedCatt])
        setoptions2([SelectedCatt])
        SetSelectAndShow(true)
    };

    const BalCheck = async () => {
        setStatus1("start")
        console.log("asdasdnetwoooooookkkkk", Wallet_Details.networkConfiguration.Chainid, String(props.data.NFTDetailDet.SelectedNetwork));

        let networkConfiguration = {}
        if (String(props.data.NFTDetailDet.SelectedNetwork) == Config.MATIC_ID) {
            console.log("netter eorrrrkkk maticz", network.MATIC);
            networkConfiguration = network.MATIC
        }
        else if (String(props.data.NFTDetailDet.SelectedNetwork) == Config.ETH_ID) {
            console.log("netter eorrrrkkk", network.ETH);
            networkConfiguration = network.ETH
        }
        else if (String(props.data.NFTDetailDet.SelectedNetwork) == Config.CAM_ID) {
            console.log("netter eorrrrkkk", network.CAM);
            networkConfiguration = network.CAM
        }
        console.log("qeweqweqweasdas", String(props.data.NFTDetailDet.SelectedNetwork), networkConfiguration);
        if (String(networkConfiguration.Chainid) !== String(props.data.NFTDetailDet.SelectedNetwork)) {
            var accountDetailsss = await switchNetwork(String(props.data.NFTDetailDet.SelectedNetwork), networkConfiguration)
        }
        var TransferAddress = NewOwnerAddress.toLowerCase();
        var web3 = new Web3()
        var validation = web3.utils.isAddress(TransferAddress)
        if (validation == true) {
            const id = toast.loading("Checking Balance & Validating", { closeButton: true });
            let chainid = currentFractions?.Network
            let networkConfiguration = {}
            console.log("sdfsfdfsdfsdfs", Number(chainid), network.MATIC.Chainid);
            if (Number(chainid) === network.MATIC.Chainid) {
                console.log("netter eorrrrkkk maticz", network.MATIC);
                networkConfiguration = network.MATIC
            }
            else if (Number(chainid) === network.ETH.Chainid) {
                console.log("netter eorrrrkkk", network.ETH);
                networkConfiguration = network.ETH
            }
            else if (Number(chainid) === network.CAM.Chainid) {
                console.log("netter eorrrrkkk", network.CAM);
                networkConfiguration = network.CAM
            }
            Wallet_Details.networkConfiguration = networkConfiguration
            let Arg = {
                NFTConAddress: networkConfiguration.NativeToken,
                NFTId: currentFractions.tokenID,
                NFTOwner: currentFractions.tokenOwner,
                Status: true,
                BulK: "Bulkmint"
            }
            let Type = 721;
            console.log("else iffffff");
            if (localStorage.walletConnectType !== "LMWallet") {
                var ContractNFTBal = await NFTHookFun(Arg, Wallet_Details)
                console.log("sdqeqwwqeqwqweqw", ContractNFTBal);
            } else {
                var key = null

                var Argument = [currentFractions.tokenOwner]
                var ContractNFTBal = await LMBalanceCheck(Arg, Type, Wallet_Details, Arg.NFTConAddress, Type == 721 ? "Single" : "Multiple", Arg.NFTConAddress, "balanceOf", Argument, key, Arg.NFTOwner)
            }

            console.log("ContractNFTBal", ContractNFTBal, selectedOption)
            if (Number(ContractNFTBal) >= Formdata.SellAmount) {
                toast.update(id, { render: "Validation Success", type: "success", isLoading: false, autoClose: 2500, });
                const id1 = toast.loading("Approve Token", { closeButton: true });
                var Receipt
                let Argment = [props.data.NFTDetailDet.ERC20_Address, web3.utils.toWei(String(selectedOption1.SellAmount))]
                if (localStorage.walletConnectType !== "LMWallet") {
                    Receipt = await ContactHookTokenApp(Argment, Wallet_Details, "approve", Wallet_Details.networkConfiguration.NativeToken)
                } else {
                    var Key = null
                    Receipt = await LMWalletCall(Argment, Wallet_Details, "approve", "Token", Key, Wallet_Details.networkConfiguration.NativeToken)
                }

                if (Receipt.status) {
                    toast.update(id1, { render: "Token Approved Successfully", type: "success", isLoading: false, autoClose: 2500, });
                    setStatus1("done")
                } else {
                    toast.update(id1, { render: "Transcation Failed. Tryit Again Later", type: "error", isLoading: false, autoClose: 2500, });
                    setStatus1("tryagain")
                }

            }
            else {
                toast.update(id, { render: "Insufficient Token Balance", type: "error", isLoading: false, autoClose: 2500, });
                setStatus1('tryagain')

            }
        } else {
            toast.error("Please Enter Valid Address(Metamask Address)")
            setStatus1('tryagain')
        }
    }

    const PullBackNfts = async () => {
        setStatus2('start')
        const id1 = toast.loading("Getting All NFTs & Spliting Amount", { closeButton: true });
        var web3 = new Web3()
        const SendData = {
            Wallet_Details: Wallet_Details,
            Arg: [Wallet_Details.networkConfiguration.NativeToken, OwnersList, web3.utils.toWei(String(selectedOption1.SellAmount)), NewOwnerAddress],
            Method: "fractionBulkReturn",
            TokAddres: props.data.NFTDetailDet.ERC20_Address

        }

        let SendLmTokenPullBack
        if (localStorage.walletConnectType !== "LMWallet") {


            SendLmTokenPullBack = await NFTTokTranfer(SendData)
            console.log("zxjkcckjasasdasda", SendLmTokenPullBack);


        } else {
            var key = null


            SendLmTokenPullBack = await LMTokenTransferBackToken(SendData)
        }
        console.log("SendLmTokenPullBack", SendLmTokenPullBack);
        //   if(SendLmTokenPullBack.status){
        //     toast.update(id1, { render: "Transcation Success", type: "success", isLoading: false, autoClose: 2500, });
        //   }else{
        //     toast.update(id1, { render: "Transcation Failed. Tryit Again Later", type: "success", isLoading: false, autoClose: 2500, });
        //   }
        if (SendLmTokenPullBack.status) {
            console.log("selectedOption1.UniqueId", selectedOption1.UniqueId);
            const FinalBackendData = {
                tokenID: props.data.NFTDetailDet.tokenID,
                WalletAddress: NewOwnerAddress,
                tokenCreator: props.data.NFTDetailDet.tokenCreator,
                totalQuantity: props.data.NFTDetailDet.tokenQuantity,
                Network: props.data.NFTDetailDet.SelectedNetwork,
                tokenOwner: props.data.NFTDetailDet.tokenOwner,
                UniqueId: selectedOption1.UniqueId
            }
            const ReduceAndNewOwnerGet = await ReduceAndNewOwner(FinalBackendData)
            console.log("ReduceAndNewOwner", ReduceAndNewOwnerGet);
            if (ReduceAndNewOwnerGet.data.Success) {
                toast.update(id1, { render: "Transcation Successfully", type: "success", isLoading: false, autoClose: 2500, });
                window.$('#createNewOwner_modalLabel').modal('hide');
                history.push('/')
            } else {
                toast.update(id1, { render: "Transcation Failed. Tryit Again Later", type: "error", isLoading: false, autoClose: 2500, });
                setStatus2('tryagain')
            }
        } else {
            toast.update(id1, { render: "Transcation Failed. Tryit Again Later", type: "error", isLoading: false, autoClose: 2500, });
            setStatus2('tryagain')
        }
    }

    const TransferOwnerShip = async () => {
        const id1 = toast.loading("Transferring Ownership", { closeButton: true });
        var Erc20Owner
        const NewOwnerDet = {
            Wallet_Details: Wallet_Details,
            Arg: [NewOwnerAddress],
            Method: 'transferOwnership',
            TokAddres: props.data.NFTDetailDet.ERC20_Address
        }
        if (localStorage.walletConnectType !== "LMWallet") {


            Erc20Owner = await ContactHookTokenApp(NewOwnerDet.Arg, Wallet_Details, "transferOwnership", NewOwnerDet.TokAddres)


        } else {
            var key = null

            var Argument = [networkConfiguration.MATIC.NativeToken]
            Erc20Owner = await LMTokenTransferBack(Arg, Type, Wallet_Details, Arg.NFTConAddress, Type == 721 ? "Single" : "Multiple", Arg.NFTConAddress, "balanceOf", Argument, key, Arg.NFTOwner)
        }

        if (Erc20Owner.status) {
            toast.update(id1, { render: "Ownership Transferred Successfully", type: "success", isLoading: false, autoClose: 2500, });
        } else {
            toast.update(id1, { render: "Transcation Failed. Tryit Again Later", type: "success", isLoading: false, autoClose: 2500, });
        }
    }

    const TransferNft = async () => {
        const id1 = toast.loading("Transferring Ownership", { closeButton: true });
        let Transfer721
        const ArgAction = { NFTOwner: props.data.NFTDetailDet.tokenOwner, ToAddress: NewOwnerAddress, NFTId: props.data.NFTDetailDet.tokenID }
        if (localStorage.walletConnectType !== "LMWallet") {
            Transfer721 = await ContactHookTranfer(ArgAction, 721, Wallet_Details)
        } else {
            var key = null
            var Argument = [networkConfiguration.NativeToken]
            Transfer721 = await LMTokenTransferBack(Arg, Type, Wallet_Details, Arg.NFTConAddress, Type == 721 ? "Single" : "Multiple", Arg.NFTConAddress, "balanceOf", Argument, key, Arg.NFTOwner)
        }
        if (Transfer721.status) {
            const FinalBackendData = {
                tokenID: props.data.NFTDetailDet.tokenID,
                WalletAddress: NewOwnerAddress,
                tokenCreator: props.data.NFTDetailDet.tokenCreator,
                totalQuantity: props.data.NFTDetailDet.tokenQuantity,
                Network: props.data.NFTDetailDet.SelectedNetwork,
                tokenOwner: props.data.NFTDetailDet.tokenOwner
            }
            const ReduceAndNewOwner = await ReduceAndNewOwner(FinalBackendData)
            console.log("ReduceAndNewOwner", ReduceAndNewOwner);
            if (ReduceAndNewOwner) {
                toast.update(id1, { render: "NFT Transferred Successfully", type: "success", isLoading: false, autoClose: 2500, });
            } else {
                toast.update(id1, { render: "Transcation Failed. Tryit Again Later", type: "success", isLoading: false, autoClose: 2500, });
            }
        }
    }

    const ContactHookTranfer = async (Arg, Type, Provider) => {
        var Receipt = await useTransferReceiptFraction(Arg, Type, Provider)
        return Receipt
    }

    const NFTHookFun = async (Arg, Wallet_Details) => {
        var ContractNFTBal = await useNFTBalCheckToken(Arg, Wallet_Details)
        return ContractNFTBal
    }

    const ContactHookTokenApp = async (Arg, Provider, Method, TokenAddress) => {
        var Receipt = await useTokenFractionApprove(Arg, Provider, Method, TokenAddress)
        return Receipt
    }

    const NFTTokTranfer = async (Arg) => {
        const ReturnData = await useDeployerCon(Arg)
        return ReturnData
    }

    const LMBalanceCheck = async (Arg, Type, Wallet_Details, IntractContact, NameUse, Contract, method, data, Key, tokenOwner) => {
        console.log("LMBalanceCheck", Arg, Type, Wallet_Details, IntractContact, NameUse, Contract, method, data, Key);
        var Arg = {
            Method: method,
            Data: data,
            Mail: localStorage.loggedinaddress,
            LoginAddress: localStorage.userAddress,
            ContactAddress: Contract,
            Type: String(Type),
            Chain: String(props.data.NFTDetailDet.SelectedNetwork),
            IntractContract: IntractContact,
            ContactType: NameUse,
            value: 0,
            Key: Key,
            tokenOwner: tokenOwner
        }
        var Balance = await LMBalanceOfFraction(Arg)
        console.log("Balance", Balance);
        return Balance.data.receipt
    }

    const LMWalletCall = async (Arg, Wallet_Details, method, NameUse, Key, TokenAddress) => {
        var receipt = null
        console.log("Comming Inside the LmWallet", Arg, Wallet_Details, method, Key);
        var Arg = {
            Method: method,
            Data: Arg,
            Mail: localStorage.loggedinaddress,
            LoginAddress: localStorage.userAddress,
            ContactAddress: Wallet_Details.networkConfiguration.FractionTradeContract,
            Chain: String(props.data.NFTDetailDet.SelectedNetwork),
            ContactType: NameUse,
            IntractContract: Wallet_Details.networkConfiguration.FractionTradeContract,
            Key: Key,
            Status: false,
            TokenAddress: TokenAddress
        }
        var Receiptfun = await ContactMintFraction(Arg)

        console.log("ArgReceipt", Receiptfun.data.receipt);
        return Receiptfun.data.receipt
    }

    const LMTokenTransferBackToken = async (Arg) => {
        console.log("LMBalanceCheck", Arg);
        var Arg = {
            Method: Arg.Method,
            Data: Arg.Arg,
            Mail: localStorage.loggedinaddress,
            LoginAddress: localStorage.userAddress,
            ContactAddress: Arg.TokAddres,
            Type: String(721),
            Chain: String(props.data.NFTDetailDet.SelectedNetwork),
            IntractContract: Arg.TokAddres,
            ContactType: "token",
            value: 0,
            Key: Arg.Key,
            tokenOwner: Wallet_Details.UserAccountAddr
        }
        const Balance = await LMTokenTransferBack(Arg)
        console.log("nmmmmnmnmnmnmnm", Balance);
        return Balance.data.receipt
    }

    const ShowModelssss = () => {
        localStorage.setItem("setshowvote", "Show")
        dispatch({
            type: Account_Connect,
            Account_Detail: {
                setshowvote: false
            }
        })
    }

    const HideShowOn = async (dat) => {
        window.$('#createNewOwner_modalLabel').modal('show');
    }

    async function switchNetwork(configdata, networkConfiguration) {
        var type = ""
        var networkConfiguration = {}
        const chainId = await Wallet_Details.Web3Pro.eth.getChainId();
        console.log("adsadasdasd", configdata, chainId, networkConfiguration);
        // if(configdata ==)
        console.log("dfadsaddasdadas", Wallet_Details, localStorage.walletConnectType);
        if (localStorage.walletConnectType !== "Torus") {
            if (Number(configdata) !== Number(chainId)) {
                const id = toast.loading("Switching Network", { closeButton: true, theme: "dark" });
                if (configdata) {
                    if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask') {

                        type = "MetaMask"
                    }
                    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'WalletConnect' && localStorage.walletConnectType != null) {
                        type = "WalletConnect"
                    }
                    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'Venly' && localStorage.walletConnectType != null) {
                        type = "Venly"
                    }
                    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'Torus' && localStorage.walletConnectType != null) {
                        type = "Torus"
                    }
                    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'TorusWallet' && localStorage.walletConnectType != null) {
                        type = "TorusWallet"
                    }
                    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'LMWallet' && localStorage.walletConnectType != null) {
                        console.log("adasd comming on torus");
                        type = "LMWallet"
                        localStorage.setItem("ChainId", tokDetails.SelectedNetwork)
                    }
                    // console.log("connecttype............", type, configdata)
                    var accountDetails = await connectWallet(type, Number(configdata), localStorage.walletConnectType == "LMWallet" ? "register" : "Create", Wallet_Details, "Tor")
                    // console.log("accountDetailsin create page......................", accountDetails)

                    // var web3 = new Web3(window.ethereum);
                    // console.log("dfghrtfh", web3);
                    // if (window.ethereum.isMetaMask == true) {

                    const chainId = await accountDetails?.web3?.eth?.getChainId();
                    // console.log("fghdtgj", chainId);

                    if (Number(configdata) === network.MATIC.Chainid) {
                        networkConfiguration = network.MATIC
                    }
                    else if (Number(configdata) === network.ETH.Chainid) {
                        networkConfiguration = network.ETH
                    }
                    else if (Number(configdata) === network.CAM.Chainid) {
                        networkConfiguration = network.CAM
                    }


                    // }
                    // setPriceoption()
                    console.log("dsnsdkngksfnvkfdn", networkConfiguration);
                    if (accountDetails != '' && accountDetails?.web3?._provider != '') {
                        // console.log("Wallet Connecting...increate", accountDetails.web3._provider);
                        dispatch({
                            type: Account_Connect,
                            Account_Detail: {
                                UserAccountAddr: accountDetails.accountAddress,
                                UserAccountBal: accountDetails.coinBalance,
                                WalletConnected: "true",
                                Wen_Bln: accountDetails.tokenBalance,
                                Accounts: accountDetails.accountAddress,
                                Web3Pro: accountDetails.web3,
                                providerss: accountDetails.web3._provider,
                                networkConfiguration: networkConfiguration
                            }
                        })
                        Serfee(networkConfiguration.Chainid);
                    }
                    if (networkConfiguration.currencySymbol) {
                        toast.update(id, {
                            render: "Network switched Succesfully",
                            type: "success",
                            isLoading: false,
                            autoClose: 2500,
                            closeButton: true,
                            theme: "dark"
                        });
                    }
                }
                return accountDetails
            } else {
                dispatch({
                    type: Account_Connect,
                    Account_Detail: {
                        UserAccountAddr: Wallet_Details?.UserAccountAddr,
                        UserAccountBal: Wallet_Details?.UserAccountBal,
                        WalletConnected: "true",

                        Wen_Bln: Wallet_Details?.Wen_Bln,
                        Accounts: Wallet_Details?.Accounts,

                        Web3Pro: Wallet_Details?.Web3Pro,
                        providerss: Wallet_Details?.providerss,
                        networkConfiguration: networkConfiguration
                    }
                })
                Serfee(networkConfiguration.Chainid);
                return Wallet_Details.Web3Pro
            }
        } else {
            try {
                const id = toast.loading("Switching Network", { closeButton: true, theme: "dark" });
                var accountDetails = await connectWallet(localStorage.walletConnectType, Number(configdata), "Create", Wallet_Details, "Torus")

                console.log("asdasdasdasdas", accountDetails);
                const chainId = await accountDetails?.web3?.eth?.getChainId();
                console.log("fghdtgj", configdata, network, chainId);

                if (Number(configdata) === network.MATIC.Chainid) {
                    networkConfiguration = network.MATIC
                }
                else if (Number(configdata) === network.ETH.Chainid) {
                    networkConfiguration = network.ETH
                }
                else if (Number(configdata) === network.CAM.Chainid) {
                    networkConfiguration = network.CAM
                }
                console.log("huiyooijmoijolmj", networkConfiguration);
                if (accountDetails != '') {
                    dispatch({
                        type: Account_Connect,
                        Account_Detail: {
                            UserAccountAddr: accountDetails?.accountAddress,
                            UserAccountBal: accountDetails?.coinBalance,
                            WalletConnected: "true",

                            Wen_Bln: accountDetails?.tokenBalance,
                            Accounts: accountDetails?.accountAddress,

                            Web3Pro: accountDetails?.web3,
                            providerss: accountDetails?.web3?._provider,
                            networkConfiguration: networkConfiguration
                        }
                    })
                }
                var functiobn = await NefunctionTriger(Wallet_Details)
                console.log("Asdasdaeqweqwe", networkConfiguration.currencySymbol);
                if (networkConfiguration.currencySymbol) {
                    toast.update(id, {
                        render: "Network switched Succesfully",
                        type: "success",
                        isLoading: false,
                        autoClose: 2500,
                        closeButton: true,
                        theme: "dark"
                    });
                }
            } catch (e) {
                console.log("adqeqweqweqweqweqweqwe", e);
            }
        }

    }

    const NefunctionTriger = async (Wallet_Details1) => {
        console.log("Wallet_Details1", Wallet_Details1);
        const chainIdSwitched = await Wallet_Details1?.Web3Pro?.eth?.getChainId();
        var balance = await Wallet_Details1?.Web3Pro?.eth.getBalance(Wallet_Details1.UserAccountAddr);
        console.log("chainIdSwitched", chainIdSwitched, balance);
        return chainIdSwitched
    }

    const Serfee = async (Address) => {
        console.log("khlhujnoasdasdasdikljiuio", Address);
        var Fee = await getServiceFees(Address)
        console.log("ndkdsnfkdsn", Fee);
        dispatch({
            type: Account_Connect,
            Account_Detail: {
                Service_Fee: Fee?.Serfee,
                Service_Fee_Fraction: Fee?.SerfeeFraction
            }
        })
    }

    return (


        <>
            {console.log("Adjasdcjkdsaca", Formdata, Imagefile, MultipleImg)}
            <div className="home_headersss">

                {/* <ScrollToTopOnMount /> */}

                {/* {!HideShow ? */}
                <div className="container">
                    {Wallet_Details.CreateOrEdit == "Create" ?
                        <div className="pl-4 pt-5 pb-5">
                            <button className="btn create_btn mb-4"
                                onClick={() => ShowModelssss()}
                            >
                                Back
                            </button>

                            {/* <div className="daoback mb-3">
                                <p className="smallp">Create Your DAO</p>
                                <h3 className="set_p mt-2 mb-2">Settings</h3>
                                <p className="alert_p">Important alert</p>
                            </div> */}



                            <div className="mb-3">
                                <label className="primary_label" htmlFor="desccription">Voting type</label>

                                <Select
                                    className="yes1 form-control primary_inp select1 selxet_app"
                                    defaultValue={selectedOption}
                                    onChange={(e) => setSelectedOption(e.value)}
                                    options={currentFractions.tokenOwner == currentFractions.tokenCreator || currentFractions.RandomName == "New" ? options : options2}
                                    // value={Formdata.VotingCat}
                                    styles={customStyles}
                                    classNamePrefix="react-select"/*  */
                                />
                                {Errors && Errors.VotingCat &&
                                    <p className="Warning_label">{Errors && Errors.VotingCat}</p>
                                }
                            </div>

                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label className="primary_label mb-2" htmlFor="desccription">Banner Images</label>

                                        <div className="pos_rel">
                                            <input type="file" className="form-control" id="BannerImage"
                                                name="BannerImage" onChange={(e) => OnChangehandle(e)} />
                                            <button className="btn create_btn cursor">Upload Image</button>
                                        </div>

                                    </div>
                                    <img
                                        className='mt-3 objectimg1 mb-3'
                                        src={Imagefile
                                            ? typeof Imagefile == "object"
                                                ? window.URL.createObjectURL(Imagefile)
                                                : ""
                                            // `${config.ImG}/cmsimages/${val}`
                                            : lazyimg
                                        }
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label className="primary_label" htmlFor="desccription">Multiple Images<span className="text-muted"> (Optional)</span></label>
                                        <div className="pos_rel">
                                            <input type="file" multiple
                                                className="form-control primary_inp "
                                                id="Multiplefiles"
                                                name="Multiplefiles"
                                                onChange={(e) => OnChangehandle(e)}
                                            />  <button className="btn create_btn cursor">Upload Multiple Image</button>
                                        </div>
                                    </div>
                                    {console.log("MultipleImg", MultipleImg)}
                                    {MultipleImg && MultipleImg.length > 0 &&

                                        MultipleImg?.map((val) => {
                                            { console.log("uytutrutr", val) }
                                            return (

                                                <img
                                                    className='mt-3 objectimg1 ml-3'
                                                    src={val
                                                        ? typeof val == "object"
                                                            ? window.URL.createObjectURL(val)
                                                            : ""
                                                        // `${config.ImG}/cmsimages/${val}`
                                                        : ""
                                                    }
                                                />

                                            )

                                        })

                                    }
                                </div>
                            </div>



                            {/* } */}

                            <div className="row voterow ml-1 ml-sm-0 mr-1 mt-3">
                                <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label className="primary_label" htmlFor="desccription">Short Name</label>
                                        <div className="mb-3 input_grp_style_1">
                                            <input
                                                type="text"
                                                id="ShortName"
                                                className="form-control"
                                                placeholder="Short Name"
                                                value={Formdata.ShortName}
                                                onChange={(e) => OnChangehandle(e)}
                                            />
                                        </div>
                                        {Errors && Errors.ShortName &&
                                            <p className="Warning_label">{Errors && Errors.ShortName}</p>
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label className="primary_label" htmlFor="desccription">Description</label>
                                        <div className="mb-3 input_grp_style_1">
                                            <textarea class="form-control textarea_des" id="Description" onChange={(e) => OnChangehandle(e)} value={Formdata.Description} rows="3"></textarea>
                                        </div>
                                        {Errors && Errors.Description &&
                                            <p className="Warning_label">{Errors && Errors.Description}</p>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className={(selectedOption == "Selling Vote" || Formdata.VotCategory == "Selling Vote") ? "voterow ml-1 ml-sm-0 mr-1" : ""}>
                                {(selectedOption == "Selling Vote" || Formdata.VotCategory == "Selling Vote") &&
                                    <div className="mb-3 ml-2">
                                        <label className="primary_label" htmlFor="desccription">Enter Sell Price</label>
                                        <div className="mb-3 input_grp_style_1">
                                            <input
                                                type="number"
                                                id="SellAmount"
                                                className="form-control"
                                                placeholder="Enter Selling Price"
                                                value={Formdata.SellAmount}
                                                onChange={(e) => OnChangehandle(e)}
                                            />
                                        </div>
                                        {Errors && Errors.SellAmount &&
                                            <p className="Warning_label">{Errors && Errors.SellAmount}</p>
                                        }
                                    </div>
                                }

                                {console.log("Formdata?.VoteCount", Formdata?.VoteCount)}
                                {Formdata?.VoteCount > 0 &&
                                    <div className="mb-2">
                                        <label className="primary_label" htmlFor="desccription">Vote Count</label>
                                        <div className="mb-3 input_grp_style_1">
                                            <input
                                                type="number"
                                                id="VoteCount"
                                                className="form-control"
                                                placeholder="VoteCount"
                                                value={Formdata.VoteCount}
                                                disabled
                                            // onChange={(e) => OnChangehandle(e)}
                                            />
                                        </div>
                                        {Errors && Errors.VotingEndDate &&
                                            <p className="Warning_label">{Errors && Errors.VotingEndDate}</p>
                                        }
                                    </div>
                                    // : <></>
                                }
                            </div>

                            <div className="row align-items-center voterow ml-1 ml-sm-0 mr-1">
                                <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label className="primary_label" htmlFor="desccription">Vote Percentage</label>
                                        <div className="mb-3 input_grp_style_1">
                                            <input
                                                type="number"
                                                id="promote"
                                                className="form-control"
                                                placeholder="Enter vote Percent"
                                                value={Formdata.promote}
                                                onChange={(e) => OnChangehandle(e)}
                                            />
                                        </div>
                                        {Errors && Errors.VotingPercent &&
                                            <p className="Warning_label">{Errors && Errors.VotingPercent}</p>
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-6 voteslide">
                                    {/* <Slider
                                        min={0}
                                        max={100}
                                        value={volumess}
                                        onChange={handleOnChanges}
                                        tooltip={false}                                        
                                    /> */}
                                    <ProgressBar className="mt-3" now={Formdata.promote} label={`${Formdata.promote}%`} />
                                </div>
                            </div>

                            <div className="row voterow ml-1 ml-sm-0 mr-1">

                                <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label className="primary_label" htmlFor="desccription">Start Date</label>
                                        <div className="mb-3 input_grp_style_1 pl-3 py-2">

                                            <div
                                                style={{ fontWeight: "500" }}
                                                className="cursor"
                                                data-toggle="modal"
                                                data-target="#calendar_modal"
                                                onClick={() => { setAucCalender("Start"); setPicStartselected(true) }}
                                            >
                                                {AuctionStart ? (AuctionStart).toLocaleString() : "Starting Date"}
                                                {/* Pick specific date */}
                                            </div>
                                            {Errors && Errors.VotingStartedDate &&
                                                <p className="Warning_label">{Errors && Errors.VotingStartedDate}</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label className="primary_label" htmlFor="desccription">End Date</label>
                                        <div className="mb-3 input_grp_style_1 pl-3 py-2">

                                            <div
                                                className="cursor"
                                                data-toggle="modal"
                                                data-target="#calendar_modal"
                                                onClick={() => setAucCalender("End")}
                                            >
                                                {AuctionEnd ? (AuctionEnd).toLocaleString() : "Expiration Date"}
                                                {/* Pick specific date */}
                                            </div>
                                            {Errors && Errors.VotingEndDate &&
                                                <p className="Warning_label">{Errors && Errors.VotingEndDate}</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>




                            {/* } */}
                            <div className="d-block d-sm-flex">
                                <div className="mb-2">
                                    <Button
                                        className="create_btn mt-1 mb-2"
                                        onClick={() => OnSubmited(currentFractions)}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </div>


                            {/* :
                        <div className="modal-content">
                            <div className="modal-header text-center">
                                <h5 className="modal-title" id="createavote_modalLabel">
                                    GetBack NFTs & Transfer to New Owner
                                </h5>
                                <button
                                    id="buy_closs"
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body px-0">
                                <div className="mb-3">
                                    <label htmlFor="qty" className="">New Owner Address</label>
                                    <div className="mb-3 input_grp_style_1">
                                        <input
                                            type="text"
                                            id="ShortName"
                                            className="form-control"
                                            placeholder="Short Name"
                                            // value={Formdata.ShortName}
                                            onChange={(e) => setNewOwnerAddress(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <Button
                                        className="create_btn mt-3"
                                        onClick={() => BalCheck(currentFractions)}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </div>

                        </div>
                    } */}
                        </div>

                        :

                        <div className="pl-4 pt-5 pb-5">
                            <button className="btn create_btn mb-4"
                                onClick={() => ShowModelssss()}
                            >
                                Back
                            </button>

                            {!selectedOption &&
                                <div className="mb-3">
                                    <label htmlFor="qty" className="primary_label whtclrss">Voting Pools</label>
                                    <Select
                                        className="yes1 form-control primary_inp select1 selxet_app"
                                        defaultValue={selectedOption1}
                                        onChange={handleChange}
                                        options={options1}
                                        styles={customStyles}
                                        classNamePrefix="react-select"/*  */
                                    />
                                </div>
                            }
                            {SelectAndShow &&
                                <>

                                    <div className="mb-3">
                                        <label className="primary_label" htmlFor="desccription">Voting type</label>

                                        <Select
                                            className="yes1 form-control primary_inp select1 selxet_app"
                                            defaultValue={selectedOption}
                                            onChange={(e) => setSelectedOption(e.value)}
                                            options={currentFractions.tokenOwner == currentFractions.tokenCreator ? options : options2}
                                            // value={Formdata.VotingCat}
                                            styles={customStyles}
                                            classNamePrefix="react-select"/*  */
                                        />
                                        {Errors && Errors.VotingCat &&
                                            <p className="Warning_label">{Errors && Errors.VotingCat}</p>
                                        }
                                    </div>


                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="mb-3">
                                                <label className="primary_label mb-2" htmlFor="desccription">Banner Images</label>

                                                <div className="pos_rel">
                                                    <input type="file" className="form-control" id="BannerImage"
                                                        name="BannerImage" onChange={(e) => OnChangehandle(e)} />
                                                    <button className="btn create_btn cursor">Upload Image</button>
                                                </div>
                                            </div>
                                            {console.log("asdajskdjkasdas", Formdata, Imagefile, typeof Imagefile)}
                                            <img
                                                className='mt-3 objectimg1 mb-3'
                                                src={typeof Imagefile == "object" ? window.URL.createObjectURL(Imagefile) : `${config.Back_URL + "/FractionalVote/" + Formdata.WalletAddress + "/BannerImage/" + Imagefile}`}
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-3">
                                                <label className="primary_label" htmlFor="desccription">Multiple Images<span className="text-muted"> (Optional)</span></label>
                                                <div className="pos_rel">
                                                    <input type="file" multiple
                                                        className="form-control primary_inp "
                                                        id="Multiplefiles"
                                                        name="Multiplefiles"
                                                        onChange={(e) => OnChangehandle(e)}
                                                    />  <button className="btn create_btn cursor">Upload Multiple Image</button>
                                                </div>
                                            </div>
                                            {console.log("MultipleImg", MultipleImg)}
                                            {MultipleImg && MultipleImg.length > 0 &&

                                                MultipleImg?.map((val) => {
                                                    { console.log("uytutrutr", val) }
                                                    return (

                                                        <img
                                                            className='mt-3 objectimg1 ml-3'
                                                            src={val
                                                                ? typeof val == "object"
                                                                    ? window.URL.createObjectURL(val)
                                                                    : `${config.Back_URL + "/FractionalVote/" + Formdata.WalletAddress + "/Multiplefiles/" + val}`
                                                                : ""

                                                            }
                                                        />

                                                    )

                                                })

                                            }
                                        </div>
                                    </div>

                                    <div className="row voterow mt-3 ml-1 ml-sm-0 mr-1">
                                        <div className="col-lg-6">
                                            <div className="mb-3">
                                                <label className="primary_label" htmlFor="desccription">Short Name</label>
                                                <div className="mb-3 input_grp_style_1">
                                                    <input
                                                        type="text"
                                                        id="ShortName"
                                                        className="form-control"
                                                        placeholder="Short Name"
                                                        value={Formdata.ShortName}
                                                        onChange={(e) => OnChangehandle(e)}
                                                    />
                                                </div>
                                                {Errors && Errors.VotingEndDate &&
                                                    <p className="Warning_label">{Errors && Errors.VotingEndDate}</p>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-3">
                                                <label className="primary_label" htmlFor="desccription">Description</label>
                                                <div className="mb-3 input_grp_style_1">
                                                    <textarea class="form-control textarea_des" id="Description" onChange={(e) => OnChangehandle(e)} value={Formdata.Description} rows="3"></textarea>
                                                </div>
                                                {Errors && Errors.Description &&
                                                    <p className="Warning_label">{Errors && Errors.Description}</p>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    {/* } */}

                                    <div className="voterow ml-1 ml-sm-0 mr-1 mt-3">
                                        {(selectedOption == "Selling Vote" || Formdata.VotCategory == "Selling Vote") &&
                                            <div className="mb-3">
                                                <label className="primary_label" htmlFor="desccription">Enter Sell Price</label>
                                                <div className="mb-3 input_grp_style_1">
                                                    <input
                                                        type="number"
                                                        id="SellAmount"
                                                        className="form-control"
                                                        placeholder="Enter Selling Price"
                                                        value={Formdata.SellAmount}
                                                        onChange={(e) => OnChangehandle(e)}
                                                    />
                                                </div>
                                                {Errors && Errors.SellAmount &&
                                                    <p className="Warning_label">{Errors && Errors.SellAmount}</p>
                                                }
                                            </div>
                                        }
                                        <div className="mb-3">
                                            <label className="primary_label" htmlFor="desccription">Vote Percentage</label>
                                            <div className="mb-3 input_grp_style_1">
                                                <input
                                                    type="number"
                                                    id="promote"
                                                    className="form-control"
                                                    placeholder="Enter vote Percent"
                                                    value={Formdata.promote}
                                                    onChange={(e) => OnChangehandle(e)}
                                                />
                                            </div>
                                            {Errors && Errors.VotingPercent &&
                                                <p className="Warning_label">{Errors && Errors.VotingPercent}</p>
                                            }
                                        </div>
                     
                                    </div>

                                    <div className="row voterow mt-3 ml-1 ml-sm-0 mr-1">
                                        <div className="col-lg-6">
                                            <div className="mb-3">
                                                <label className="primary_label" htmlFor="desccription">Start Date</label>
                                                <div className="mb-3 input_grp_style_1 pl-3 py-2">
                                                    {/* {AuctionStart ? (AuctionStart).toLocaleString() : "Starting Date"} */}
                                                    <div
                                                        style={{ fontWeight: "500" }}
                                                        className="cursor"
                                                        data-toggle="modal"
                                                        data-target="#calendar_modal"
                                                        onClick={() => { setAucCalender("Start"); setPicStartselected(true) }}
                                                    >
                                                        {AuctionStart ? (AuctionStart).toLocaleString() : "Starting Date"}
                                                        {/* Pick specific date */}
                                                    </div>
                                                    {Errors && Errors.VotingEndDate &&
                                                        <p className="Warning_label">{Errors && Errors.VotingEndDate}</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-3">
                                                <label className="primary_label" htmlFor="desccription">End Date</label>
                                                <div className="mb-3 input_grp_style_1 pl-3 py-2">

                                                    <div
                                                        className="cursor"
                                                        data-toggle="modal"
                                                        data-target="#calendar_modal"
                                                        onClick={() => setAucCalender("End")}
                                                    >
                                                        {AuctionEnd ? (AuctionEnd).toLocaleString() : "Expiration Date"}
                                                        {/* Pick specific date */}
                                                    </div>
                                                    {Errors && Errors.VotingEndDate &&
                                                        <p className="Warning_label">{Errors && Errors.VotingEndDate}</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row voterow ml-1 ml-sm-0 mr-1">
                                        <div className="col-md-8">
                                            {console.log("Formdata?.VoteCount", Formdata?.VoteCount)}
                                            {Formdata?.VoteCount > 0 &&
                                                <div className="mb-2">
                                                    <label className="primary_label" htmlFor="desccription">Vote Count</label>
                                                    <div className="mb-3 input_grp_style_1">
                                                        <input
                                                            type="number"
                                                            id="VoteCount"
                                                            className="form-control"
                                                            placeholder="VoteCount"
                                                            value={Formdata.VoteCount}
                                                            disabled
                                                        // onChange={(e) => OnChangehandle(e)}
                                                        />
                                                    </div>
                                                    {Errors && Errors.VotingEndDate &&
                                                        <p className="Warning_label">{Errors && Errors.VotingEndDate}</p>
                                                    }
                                                </div>
                                                // : <></>
                                            }

                                            {/* {VotePer > 0 && VotePer && */}
                                            <div className="mb-2">
                                                <label className="primary_label" htmlFor="desccription">Voted Percentage</label>
                                                <div className="mb-3 input_grp_style_1">
                                                    <input
                                                        type="number"
                                                        id="VotePer"
                                                        className="form-control"
                                                        placeholder="VotePer"
                                                        value={VotePer}
                                                        disabled
                                                    // onChange={(e) => OnChangehandle(e)}
                                                    />
                                                </div>
                                                {Errors && Errors.VotingPercent &&
                                                    <p className="Warning_label">{Errors && Errors.VotingPercent}</p>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div style={{ width: 200, height: 200, margin: "auto" }}>
                                                <CircularProgressbar value={VotePer} text={`${VotePer}%`} styles={buildStyles({
                                                    pathColor: `green, ${VotePer / 100})`,
                                                    textColor: 'green',
                                                    trailColor: '#d6d6d6', backgroundColor: 'green'
                                                })} />
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="col-md-4">
                                <div style={{ width: 200, height: 200, margin: "auto" }}>
                                    <CircularProgressbar value={VotePer} text={`${VotePer}%`} styles={buildStyles({
                                        pathColor: `green, ${VotePer / 100})`,
                                        textColor: 'green',
                                        trailColor: '#d6d6d6', backgroundColor: 'green'
                                    })} />;
                                </div>
                            </div> */}
                                    <></>
                                    {/* } */}
                                    <div className="d-block d-sm-flex">
                                        <div className="mb-2">
                                            <Button
                                                className="create_btn mt-1 mb-2"
                                                onClick={() => OnSubmited(currentFractions)}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                        {localStorage.getItem("CancelShow") == "Show" && Formdata.AdditionalStatus &&
                                            < div className="mb-2 ml-0 ml-sm-2">
                                                <Button
                                                    className="create_btn mt-1 mb-2"
                                                    onClick={() => { OnSubmited(currentFractions, "Cancel") }}
                                                >
                                                    Cancel Voting
                                                </Button>
                                            </div>
                                        }
                                        {Formdata.promote <= VotePer && Formdata.AdditionalStatus &&
                                            <div className="mb-2 ml-0 ml-sm-2">
                                                <Button
                                                    className="create_btn mt-1 mb-2"
                                                    onClick={() => { OnSubmited(currentFractions, "Stop") }}
                                                >
                                                    Stop Voting
                                                </Button>
                                            </div>
                                        }

                                        {Formdata.promote <= VotePer && !Formdata.AdditionalStatus && Formdata.VotCategory == "Selling Vote" &&
                                            <div className="mb-2 ml-0 ml-sm-2">
                                                <Button
                                                    className="create_btn mt-1 mb-2"
                                                    onClick={() => HideShowOn(true)}
                                                >
                                                    Claim NFTs
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                </>
                            }
                            <div
                                className="modal fade primary_modal"
                                id="createNewOwner_modalLabel"
                                tabIndex="-1"
                                role="dialog"
                                aria-labelledby="createavote_modalCenteredLabel"
                                aria-hidden="true"
                                data-backdrop="static"
                                data-keyboard="false"
                            >
                                <div
                                    className="modal-dialog modal-dialog-centered modal-sm"
                                    role="document"
                                >
                                    <div className="modal-content">
                                        <div className="modal-header text-center">
                                            <h5 className="modal-title" id="New">
                                                GetBack NFTs & Transfer to New Owner
                                            </h5>
                                            <button
                                                id="buy_closs"
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body px-0">
                                            <div className="mb-3">
                                                <label htmlFor="qty" className="">New Owner Address</label>
                                                <div className="mb-3 input_grp_style_1">
                                                    <input
                                                        type="text"
                                                        id="ShortName"
                                                        className="form-control"
                                                        placeholder="Short Name"
                                                        // value={Formdata.ShortName}
                                                        onChange={(e) => setNewOwnerAddress(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <Button
                                                    className="create_btn mt-3"
                                                    onClick={() => BalCheck(currentFractions)}
                                                    disabled={(Status1 == "start" || Status1 == "done")}
                                                >
                                                    Approve
                                                </Button>
                                            </div>
                                            <div className="mb-3">
                                                <Button
                                                    className="create_btn mt-3"
                                                    onClick={() => PullBackNfts(currentFractions)}
                                                    disabled={(Status1 == "init" || Status1 == "start") || (Status2 == "start")}
                                                >
                                                    Get All NFTs
                                                </Button>
                                            </div>
                                            {/* <div className="mb-3">
                                                <Button
                                                    className="create_btn mt-3"
                                                    onClick={() => TransferOwnerShip(currentFractions)}
                                                >
                                                    Transfer Tokens
                                                </Button>
                                            </div>
                                            <div className="mb-3">
                                                <Button
                                                    className="create_btn mt-3"
                                                    onClick={() => TransferNft(currentFractions)}
                                                >
                                                    Tranfer NFT 
                                                </Button>
                                            </div> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    }
                    <Footer />
                </div>
            </div >
            <Calender CalenderState={AucCalendar} ParentCall={ChildReply} />
        </>
    )
}

export default CreateVote

