import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button } from "@material-ui/core";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link, useLocation, useHistory } from "react-router-dom";
import Web3Utils from 'web3-utils'
import Select from "react-select";
import chargeSample from '../assets/images/SoulboudFinal.xlsx';

import $ from "jquery";
import { NFTValidation, SoulBoundValidation, ImageValidation, ImageValidationExcel } from "actions/controller/validation";
import { SoulBoundNFT, FinalDataSave, SoulBoundNFTSecond, UserPackCheck, RouterChecker, ContactMint } from '../actions/v1/token';
import { useSoulBoundMintTransfer } from "hooks/useReceipts";
import configImp from "../lib/config";
import "react-toastify/dist/ReactToastify.css";
import { toast, Slide } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { Account_Connect } from "actions/redux/action";
import { connectWallet } from '../hooks/useWallet';
import { network } from "./network"
toast.configure();

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return null;
}

export default function CreateSingle(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { ...rest } = props;
    const history = useHistory();
    const options = [
        { value: "Art", label: "Art" },
        { value: "Collectibles", label: "Collectibles" },
        { value: "Domain Names", label: "Domain Names" },
        { value: "Music", label: "Music" },
        { value: "Photography", label: "Photography" },
        { value: "Sports", label: "Sports" },
        { value: "Trading Cards", label: "Trading Cards" },
        { value: "Utility", label: "Utility" },
        { value: "Virtual Worlds", label: "Virtual Worlds" },
    ];
    const Wallet_Details = useSelector((state) => state.wallet_connect_context);

    const CreatePage = useLocation();
    const Bulkminting = CreatePage.pathname === "/SolBound";

    const InitialForm = {
        type: 721,
        contractaddress: "",
        CollectionName: "",
        NetworkName: "",
        Coverimg: "",
        Profileimg: "",
        Category: "",
        NFTSubCategory: ""
    };
    const [Formdata, setFormdata] = useState(InitialForm);
    const [PutonSale, setPutonSale] = useState(false);
    const [FixedPrice, setFixedPrice] = useState(false);
    const [Auction, setAuction] = useState(false);
    const [NFTFile, setNFTFile] = useState(null);
    const [NFTFileExcel, setNFTFileExcel] = useState(null);
    const [NFTFilename, setNFTFilename] = useState(null);
    const [Category, SetCategory] = useState("")
    const [Count, setCount] = useState(0)
    const [categorysate, Setcategorysate] = useState({})
    const [Preview, setPreview] = useState(
        require("../assets/images/lazymintingnew/footer_logo.png")
    );
    const [NFTFile1, setNFTFile1] = useState(null);
    const [NFTFile2, setNFTFile2] = useState(null);
    const [Preview1, setPreview1] = useState(
        require("../assets/images/lazymintingnew/logo.png")
    );
    const [Preview2, setPreview2] = useState(
        require("../assets/images/lazymintingnew/logo.png")
    );
    const [NFTFilename1, setNFTFilename1] = useState(null);
    const [NFTFilename2, setNFTFilename2] = useState(null);
    const [put, setPut] = useState("");
    const [ValidationError, setValidationError] = useState(null)
    const [networkoption, setnetworkoption] = React.useState([]);
    const [config, setconfig] = React.useState(InitialForm);
    const [IpfsStatusTxt, setIpfsStatusTxt] = useState("Start");
    const [CloseStatus, SetCloseStatus] = useState("Start");
    const [IpfsStatusTxt1, setIpfsStatusTxt1] = useState("Start");
    const [MintStatusTxt, setMintStatusTxt] = useState("Start");
    const [MintStatus, setMintStatus] = useState(true);
    const [SoulBoundIPFS, setSoulBoundIPFS] = useState([]);
    const [IPFSarray, setIPFSarray] = useState([]);
    const [ToAdrressArr, setToAdrressArr] = useState([]);
    const [Routeee, SetRouteeee] = useState("Not Allowed")
    const [Subcategorysate, SubSetcategorysate] = useState({})

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#fff",
            position: "relative",
            top: "3px",
            left: "0px",
            width: "100%",
            maxWidth: "100%",
            color: "#000"
        }),
        option: (provided, state) => ({
            ...provided,
            marginBottom: "5px",
            backgroundColor: "transparent",
            maxWidth: "356px",
            color: "#fff",
            borderBottom: "#fff",
            "&:hover": {
                transition: "all 0.5s ease",
                color: "#e50914"
            },
            "&:active": {
                transition: "all 0.5s ease",
                color: "#000"
            }
        }),
        control: (provided, state) => ({
            ...provided,
            border: "1px solid transparent",
            boxShadow: "0px 0px",
            "&:hover": {
                border: "1px solid transparent",
                boxShadow: "0px 0px",
            },
            "&:focus": {
                border: "1px solid transparent"
            }
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            border: "1px solid transparent"
        })
    }
    
    const renderer = ({
        days,
        Month,
        Year,
        hours,
        minutes,
        seconds,
        completed,
    }) => {
        if (completed) {
            return <span></span>;
        } else {
            return (
                <span>
                    {days}d {hours}h {minutes}m {seconds}s left
                </span>
            );
        }
    };

    useEffect(() => {

        if (Wallet_Details?.CategorySBT) {
            var Categorydata = Wallet_Details?.CategorySBT.filter(Cat => (Cat.label != 'Ticket'))

            if (Categorydata) {
                Setcategorysate(Categorydata)
            }
        }
        if (Wallet_Details && Wallet_Details.networkoption.length > 0) {

            setnetworkoption(Wallet_Details.networkoption)
        }
    }, [Wallet_Details?.Category]);



    const OnChangehandle = (e) => {
        e.preventDefault();
        const { id, value } = e.target;
        let Form = Formdata;
        Form = { ...Form, ...{ [id]: value } };

        setFormdata(Form);

    };



    const Handlefile1 = (e) => {

        if (e.target && e.target.files) {

            var file = e.target.files[0];
            var Resp = ImageValidationExcel(file, "Edit")
            if (Resp.Success) {
                setNFTFileExcel(file)
                toast.success("Excel File upload Success")
            } else {
                toast.error('upload `.xlsx` Excel File')
            }

        }
    };



    const Handlefile = (data) => {
        var file = data.target.files;
        if (file) {
            setNFTFile(file)

            toast.success("File upload Success")
        }
        else {
            toast.error("Error in File Upload Please ReUpload")
        }
    }


    const Handlefile2 = (e) => {
        if (e.target && e.target.files) {
            var file = e.target.files[0];
            var fileName = file.name;
            setNFTFilename1(fileName);
            Formdata.ImageName = fileName;
            var Resp = ImageValidation(file, "Edit")
            if (Resp.Success) {
                setNFTFile1(file);
                setPreview1(URL.createObjectURL(file));

            }
            else
                toast.error("Error in File Upload Please ReUpload")
        }
    };

    const Handlefile3 = (e) => {
        if (e.target && e.target.files) {
            var file = e.target.files[0];
            var fileName = file.name;
            setNFTFilename2(fileName);
            Formdata.ImageName = fileName;
            var Resp = ImageValidation(file, "Edit")
            if (Resp.Success) {
                setNFTFile2(file);
                setPreview2(URL.createObjectURL(file));
            }
            else
                toast.error("Error in File Upload Please ReUpload")
        }
    };


    async function switchNetwork(configdata) {
        var type = ""
        var networkConfiguration = {}
        const chainId = await Wallet_Details.Web3Pro.eth.getChainId();
        if (localStorage.walletConnectType !== "Torus") {
            if (Number(configdata.Chainid) !== Number(chainId)) {
                const id = toast.loading("Switching Network", { closeButton: true, theme: "dark" });
                if (configdata) {
                    if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask') {

                        type = "MetaMask"
                    }
                    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'WalletConnect' && localStorage.walletConnectType != null) {
                        type = "WalletConnect"
                    }
                    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'Venly' && localStorage.walletConnectType != null) {
                        type = "Venly"
                    }
                    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'Torus' && localStorage.walletConnectType != null) {
                        type = "Torus"
                    }
                    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'TorusWallet' && localStorage.walletConnectType != null) {
                        type = "TorusWallet"
                    }
                    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'LMWallet' && localStorage.walletConnectType != null) {

                        type = "LMWallet"
                        localStorage.setItem("ChainId", Formdata.NetworkName)
                    }
                    var accountDetails = await connectWallet(type, configdata.Chainid, localStorage.walletConnectType == "LMWallet" ? "register" : "Create", Wallet_Details, "Tor")

                    const chainId = await accountDetails?.web3?.eth?.getChainId();
                    if (chainId === network.MATIC.Chainid) {
                        networkConfiguration = network.MATIC
                    }
                    else if (chainId === network.ETH.Chainid) {
                        networkConfiguration = network.ETH
                    }
                    else if (chainId === network.CAM.Chainid) {
                        networkConfiguration = network.CAM
                    }
                    if (accountDetails != '' && accountDetails?.web3?._provider != '') {
                        dispatch({
                            type: Account_Connect,
                            Account_Detail: {
                                UserAccountAddr: accountDetails.accountAddress,
                                UserAccountBal: accountDetails.coinBalance,
                                WalletConnected: "true",
                                Wen_Bln: accountDetails.tokenBalance,
                                Accounts: accountDetails.accountAddress,
                                Web3Pro: accountDetails.web3,
                                providerss: accountDetails?.web3?._provider,
                                networkConfiguration: networkConfiguration
                            }
                        })
                    }

                    if (networkConfiguration.currencySymbol) {
                        toast.update(id, {
                            render: "Network switched Succesfully",
                            type: "success",
                            isLoading: false,
                            autoClose: 2500,
                            closeButton: true,
                            theme: "dark"
                        });
                    }

                }
                return accountDetails
            }
            else {
                dispatch({
                    type: Account_Connect,
                    Account_Detail: {
                        UserAccountAddr: Wallet_Details?.UserAccountAddr,
                        UserAccountBal: Wallet_Details?.UserAccountBal,
                        WalletConnected: "true",

                        Wen_Bln: Wallet_Details?.Wen_Bln,
                        Accounts: Wallet_Details?.Accounts,

                        Web3Pro: Wallet_Details?.Web3Pro,
                        providerss: Wallet_Details?.providerss,
                        networkConfiguration: configdata
                    }
                })
                return Wallet_Details.Web3Pro
            }
        } else {
            try {
                const id = toast.loading("Switching Network", { closeButton: true, theme: "dark" });
                var accountDetails = await connectWallet(localStorage.walletConnectType, configdata.Chainid, "Create", Wallet_Details, "Torus")

                const chainId = await accountDetails?.web3?.eth?.getChainId();

                if (configdata.Chainid === network.MATIC.Chainid) {
                    networkConfiguration = network.MATIC
                }
                else if (configdata.Chainid === network.ETH.Chainid) {
                    networkConfiguration = network.ETH
                }else if (configdata.Chainid === network.CAM.Chainid) {
                    networkConfiguration = network.CAM
                }
                if (accountDetails != '') {
                    dispatch({
                        type: Account_Connect,
                        Account_Detail: {
                            UserAccountAddr: accountDetails?.accountAddress,
                            UserAccountBal: accountDetails?.coinBalance,
                            WalletConnected: "true",

                            Wen_Bln: accountDetails?.tokenBalance,
                            Accounts: accountDetails?.accountAddress,

                            Web3Pro: accountDetails?.web3,
                            providerss: accountDetails?.web3?._provider,
                            networkConfiguration: networkConfiguration
                        }
                    })
                }
                var functiobn = await NefunctionTriger(Wallet_Details)

                if (networkConfiguration.currencySymbol) {
                    toast.update(id, {
                        render: "Network switched Succesfully",
                        type: "success",
                        isLoading: false,
                        autoClose: 2500,
                        closeButton: true,
                        theme: "dark"
                    });
                }
            } catch (e) {
                console.log("Catch logs", e);
            }
        }
    }

    const NefunctionTriger = async (Wallet_Details1) => {
        const chainIdSwitched = await Wallet_Details1?.Web3Pro?.eth?.getChainId();
        var balance = await Wallet_Details1?.Web3Pro?.eth.getBalance(Wallet_Details1.UserAccountAddr);
        return chainIdSwitched
    }


    const Validate = async () => {
        var err = {};
        if (NFTFile == "" || NFTFile == null || NFTFile == undefined) {
            err.NFTFile = false
        } else {
            err.NFTFile = true
        }

        if (NFTFileExcel == "" || NFTFileExcel == null || NFTFileExcel == undefined) {
            err.NFTFileExcel = false
        } else {
            err.NFTFileExcel = true
        }
        if (err.NetworkName == false || err.NFTFile == false || err.NFTFileExcel == false || err.CollectionName == false) {
            toast.error('Please upload the File or Excel Sheet')
        }
        else {
            if (!localStorage.userAddress || localStorage.userAddress !== Wallet_Details.UserAccountAddr) {
                window.$("#triggerlogin").click();
                window.$("div").removeClass('modal-backdrop fade show')

                return toast.error("Login with correct Wallet Address and credentials")
            }
            var SendDatass = {
                Address: Wallet_Details.UserAccountAddr
            }

            var createLimit = await UserPackCheck(SendDatass)
            if (createLimit.data.data.Message == "success") {

                var SoulboundLimit = createLimit?.data?.data?.UserBalance


                var mintbal = createLimit?.data?.data?.UserBalance

                if (SoulboundLimit.PurchaseDetails.length > 0) {

                    var EmptyArr = []

                    var mapdata = mintbal.PurchaseDetails.map(async (item, index) => {


                        var Arrayaaaa = item.SoulboundLimit == "true" ? EmptyArr.push(Number(item.CommonCount)) : ""


                    })
                    if (EmptyArr.length > 0) {
                        var sum = Object.values(EmptyArr).reduce((a, b) => a + b, 0)

                        if (sum < Number(NFTFile.length)) {
                            return toast.error("Minting Limit is Low.Please purchase pack")
                        }
                    } else if (EmptyArr.length == 0) {
                        return toast.error("Purchase pack to Mint SBT")
                    }
                } else {
                    return toast.error("Purchase pack to Mint SBT")
                }
            } else {
                return toast.error("purchase plan to mint SBT")
            }

            if (Wallet_Details.UserAccountBal == 0) {
                return toast.error("Insufficient Funds")
            }
            const id2 = toast.loading("Validating Details", { transition: Slide, theme: "dark" });
            var SaleType = (PutonSale) ? (FixedPrice) ? "Fixed" : (Auction) ? "Auction" : "Unlimited" : PutonSale
            Formdata.File = NFTFile
            Formdata.Profile = NFTFile2
            Formdata.Coverimg = NFTFile1
            Formdata.Excel = NFTFileExcel
            Formdata.Category = Category
            var result = await SoulBoundValidation(Formdata, NFTFile, NFTFileExcel, Formdata, NFTFile2, NFTFile1)
            setTimeout(() => {
                if (result.Success) {
                    toast.update(id2, { render: "Validation Success", type: "success", isLoading: false, autoClose: 2000 });

                    setValidationError(result)

                    $('#SoulBoundClick').trigger("click");
                }
                else {
                    toast.update(id2, { render: "Validation Failed", type: "error", isLoading: false, autoClose: 2500 });

                    setValidationError(result)
                }
            }, 1000);
        }
    }


    const onSubmit = async (e) => {
        setIpfsStatusTxt("On progress")
        SetCloseStatus("On progress")
        var networkConfiguration = {}
        const id2 = toast.loading("Checking & Validating", { transition: Slide, theme: "dark" });
        const chainId = await Wallet_Details?.Web3Pro?.eth?.getChainId();
        if (chainId === network.MATIC.Chainid) {
            networkConfiguration = network.MATIC
        }
        else if (chainId === network.ETH.Chainid) {
            networkConfiguration = network.ETH
        } 
        else if (chainId === network.CAM.Chainid) {
            networkConfiguration = network.CAM
        }
        Wallet_Details.networkConfiguration = networkConfiguration
        Formdata.contractaddress = Wallet_Details.networkConfiguration.SoulBoundContract
        Formdata.Category = Category
        if (Wallet_Details.UserAccountAddr && Wallet_Details.networkConfiguration.multipleContract) {
            var reqdata = {
                BulkImage: NFTFile,
                ExcelFile: NFTFileExcel,
                Category: Formdata,
                Curraddressurr: Wallet_Details.UserAccountAddr,
                Profileimg: NFTFile2,
                Coverimg: NFTFile1
            }
        }
        var addcolldata = await SoulBoundNFT(reqdata);
        if (addcolldata.message.data.Message == 'success' && addcolldata.message.data.SoulBounddata) {
            toast.update(id2, { render: "Image and Excel Sheets Matched", type: "success", isLoading: false, autoClose: 2000 });
            const id22 = toast.loading("Image Merging Processing", { transition: Slide, theme: "dark" });
            setTimeout(() => {
                setTimeout(() => {
                    var ipfsdtring = []
                    var TransferAddress = []
                    toast.update(id22, { render: "Image and Processed Successfully", type: "success", isLoading: false, autoClose: 2000 });
                    setSoulBoundIPFS(addcolldata.message.data.SoulBounddata)
                    var Extract = addcolldata.message.data.SoulBounddata
                    setIpfsStatusTxt("Done")
                }, 100000
                );
            }, 100000
            );
        }
        else {
            toast.update(id2, { render: addcolldata.message.data.data, type: "error", isLoading: false, autoClose: 2000 });
            //toast.error(addcolldata.message.data.data);
            setIpfsStatusTxt("Try again")
        }
    };

    const onSubmitSecond = async (e) => {
        setIpfsStatusTxt1("On progress");
        const id22 = toast.loading("Image Uploading in IPFS Server", { transition: Slide, theme: "dark" });

        setTimeout(async () => {
            try {
                const addcolldata = await SoulBoundNFTSecond(SoulBoundIPFS);

                if (addcolldata.data.Message == 'Fail') {
                    toast.update(id22, { render: addcolldata.data.data, type: "error", isLoading: false, autoClose: 2000 });
                    setIpfsStatusTxt1("Try again");
                } else {
                    const ipfsdtring = [];
                    const TransferAddress = [];

                    toast.update(id22, { render: "IPFS Uploaded successfully", type: "success", isLoading: false, autoClose: 2000 });
                    setIpfsStatusTxt1("Done");
                    setSoulBoundIPFS(addcolldata.data.SoulBounddata);
                    const Extract = addcolldata.data.SoulBounddata;

                    Extract.ImageAndNFTDetails.forEach((ipfs) => {
                        ipfsdtring.push(ipfs.ipfsmetadata);
                        TransferAddress.push(ipfs.tokenOwner);
                    });

                    setIPFSarray(ipfsdtring);
                    setToAdrressArr(TransferAddress);
                }
            } catch (error) {
                console.error("Error occurred during image uploading:", error);
                toast.error("An error occurred. Please try again later.");
                setIpfsStatusTxt1("Try again");
            }
        }, 150000);
    };


    const SoulBoundMint = async (Index) => {
        setMintStatusTxt("On progress");
        const countValue = Index !== undefined ? Index : 0;
        const ValueSize = IPFSarray.length > 20 ? 150 : IPFSarray.length;
        const IPFS = [];
        const TOADD = [];

        if (IPFSarray.length > 0) {
            for (let i = 0; i < IPFSarray.length; i += ValueSize) {
                IPFS.push(IPFSarray.slice(i, i + ValueSize));
                TOADD.push(ToAdrressArr.slice(i, i + ValueSize));
            }
        }

        let MintTransfer;
        if (localStorage.walletConnectType !== "LMWallet") {
            MintTransfer = await ContactHook(IPFS[countValue], TOADD[countValue], Wallet_Details);
        } else {
            const Key = null;
            const Argument = [IPFS[countValue], TOADD[countValue]];
            const SoulboundContract = Wallet_Details.networkConfiguration.SoulBoundContract
            MintTransfer = await LMWalletCall("mint", SoulboundContract, SoulboundContract, Argument, "Soul", Key, false, 0);
        }

        if (MintTransfer != null) {
            const isMaticNetwork = Wallet_Details.networkConfiguration.Chainid === Number(configImp.MATIC_ID);

            const isMaticCumlumbus = Wallet_Details.networkConfiguration.Chainid === Number(configImp.CAM_ID);

            if (MintTransfer.logs && MintTransfer.logs.length > 0) {
                const tokenID = [];
                const logIndex = isMaticNetwork ? 1 : isMaticCumlumbus ? 1 : 3;

                for (let i = 1; i < MintTransfer.logs.length; i += 2) {
                    const tokenid = MintTransfer.logs[i].topics[logIndex];
                    const someString = Web3Utils.hexToNumber(tokenid);
                    tokenID.push({ tokenID: someString });
                }

                if (countValue === IPFS.length - 1) {
                    const dbdatafinal = SoulBoundIPFS.ImageAndNFTDetails.map((obj, index) => ({
                        ...obj,
                        ...tokenID[index]
                    }));
                    const data = { SoulBoundIPFS, dbdatafinal };
                    const dbDataSave = await FinalDataSave(data);

                    if (dbDataSave.data.Message === "success") {
                        $('#ClosingSoulBound').trigger("click");
                        const destination = isMaticNetwork ? `/certificate/SBT/Soulbound/${Formdata.Category}` : '/collections/Soulbound';
                        history.push(destination);
                        toast.success("Soulbound certificate added successfully");
                        setMintStatusTxt("Done");
                    } else {
                        toast.error("Error in server");
                        setMintStatusTxt("Try again");
                    }
                } else {
                    const countValue1 = countValue + 1;
                    SoulBoundMint(countValue1);
                }
            } else {
                setMintStatusTxt("Try again");
            }
        }
    };


    const ContactHook = async (IPFS, TOADD, Wallet_Details) => {
        var MintTransfer = await useSoulBoundMintTransfer(IPFS, TOADD, Wallet_Details)
        return MintTransfer
    }
    const LMWalletCall = async (method, Contract, IntractContact, Data, NameUse, Key, Status, fee) => {
        var receipt = null
        var Arg = {
            Method: method,
            Data: Data,
            Mail: localStorage.loggedinaddress,
            LoginAddress: localStorage.userAddress,
            ContactAddress: Contract,
            Type: "Soul",
            Chain: String(Formdata.NetworkName),
            IntractContract: IntractContact,
            ContactType: NameUse,
            value: fee,
            Key: Key,
            Status: Status
        }
        var Receiptfun = await ContactMint(Arg)
        return Receiptfun.data.receipt
    }

    // const networkConfigs = {
    //     ETH: {
    //         id: configImp.ETH_ID,
    //         currency: configImp.Currency_ETH,
    //         config: network.ETH,
    //     },
    //     CAM: {
    //         id: configImp.CAM_ID,
    //         currency: configImp.Currency_CAM,
    //         config: network.CAM,
    //     },
    //     MAT: {
    //         id: configImp.MATIC_ID,
    //         currency: configImp.Currency_MATIC,
    //         config: network.MATIC,
    //     },
    //     // Add new networks here
    // };
    
    // const networkMap = Object.keys(networkConfigs).reduce((map, key) => {
    //     const networkKey = configImp[`Network_${key}`];
    //     if (networkKey) {
    //         map[networkKey] = {
    //             id: Number(networkConfigs[key].id),
    //             currency: networkConfigs[key].currency,
    //             config: networkConfigs[key].config,
    //         };
    //     }
    //     return map;
    // }, {});
    
    // const selectChange = async (e) => {
    //     const selectedNetwork = networkMap[e.value] || networkMap[configImp.Network_MAT];
    
    //     Formdata.NetworkName = selectedNetwork.id;
    //     Formdata.Coinname = selectedNetwork.currency;
    
    //     if (e.name === "networkname") {
    //         Wallet_Details.networkConfiguration = selectedNetwork.config;
    //         const accountDetails = await switchNetwork(selectedNetwork.config, selectedNetwork.id);
    //         setconfig(selectedNetwork.config);
    //     }
    // };
    

    const selectChange = async (e) => {
        Formdata.NetworkName = e.value === configImp.Network_ETH ? Number(configImp.ETH_ID) :  e.value === configImp.Network_CAM ? Number(configImp.CAM_ID) :Number(configImp.MATIC_ID);
        
        Formdata.Coinname = e.value === configImp.Network_ETH ? configImp.Currency_ETH : e.value === configImp.Network_CAM ? configImp.Currency_CAM : configImp.Currency_MATIC;

        if (e.name === "networkname") {
            var configdata, accountDetails;
            if (e.value === configImp.Network_ETH) {
                configdata = network.ETH;
                Wallet_Details.networkConfiguration = network.ETH;
                accountDetails = await switchNetwork(configdata, Number(configImp.ETH_ID));
            } else if (e.value === configImp.Network_MAT) {
                configdata = network.MATIC;
                Wallet_Details.networkConfiguration = network.MATIC;
                accountDetails = await switchNetwork(configdata, Number(configImp.MATIC_ID));
            }
            else if (e.value === configImp.Network_CAM) {
                configdata = network.CAM;
                Wallet_Details.networkConfiguration = network.CAM;
                accountDetails = await switchNetwork(configdata, Number(configImp.CAM_ID));
            }
            setconfig(configdata);
        }
    };

    // const selectChange = async (e) => {
    //     const networkMap = {
    //         [configImp.Network_ETH]: {
    //             id: Number(configImp.ETH_ID),
    //             currency: configImp.Currency_ETH,
    //             config: network.ETH,
    //         },
    //         [configImp.Network_CAM]: {
    //             id: Number(configImp.CAM_ID),
    //             currency: configImp.Currency_CAM,
    //             config: network.CAM,
    //         },
    //         [configImp.Network_MAT]: {
    //             id: Number(configImp.MATIC_ID),
    //             currency: configImp.Currency_MATIC,
    //             config: network.MATIC,
    //         },
    //     };
    
    //     const selectedNetwork = networkMap[e.value] || networkMap[configImp.Network_MAT];
        
    //     Formdata.NetworkName = selectedNetwork.id;
    //     Formdata.Coinname = selectedNetwork.currency;
    
    //     if (e.name === "networkname") {
    //         Wallet_Details.networkConfiguration = selectedNetwork.config;
    //         const accountDetails = await switchNetwork(selectedNetwork.config, selectedNetwork.id);
    //         setconfig(selectedNetwork.config);
    //     }
    // };

    
    useEffect(() => {
        Route()
    }, [])
    const Route = async () => {
        let Addresss = {
            Address: Wallet_Details.UserAccountAddr
        }
        let SubscriberList = await RouterChecker(Addresss)

        if (SubscriberList?.data?.data?.Message == "Allowed") {
            SetRouteeee(SubscriberList?.data?.data?.userValue)
        }

    }

    const CategorySet = async (e) => {

        SetCategory(e)

        let data = categorysate.filter((item) => item.name == e)

        SubSetcategorysate(data[0].BulkSubCategory.filter(Cat => (Cat.show == 'false' || Cat.show == false)))
    }
    return (
        <div className="home_header create_single_mulitple">
            <Button className="
      d-none" data-toggle="modal" data-target="#register_modal" id="triggerlogin" ></Button>

            <Header
                color="transparent"
                routes={dashboardRoutes}
                brand={
                    <Link to="/home">
                        <div className="header_logo_align">


                        </div>
                    </Link>
                }
                leftLinks={<HeaderLinks />}
                changeColorOnScroll={{
                    height: 20,
                    color: "white",
                }}
                {...rest}
            />
            <ScrollToTopOnMount />
            <div className={classes.pageHeader + " inner_pageheader inner_pagh_bg_2"}>

                <div className={classes.container}>

                    <GridContainer>

                        <GridItem xs={12} sm={12} md={12}>

                            <p class="heading_big_blk mb-3"> <span><Link to="/create" className="mr-3"><i class="fas fa-long-arrow-alt-left" /></Link></span>Create Soulbound Certificates</p>

                        </GridItem>

                        <Button className="fileindees"><p className="donwloadfiles">You can download your sample excel sheet format here</p>
                            <a href={chargeSample} download="SampleExcelSheet"><i class="fas fa-download"></i> </a></Button>
                    </GridContainer>
                </div>

                <div className="container">
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>

                            <p class="bulking_cover"> Cover Image (Image size must be 1480px * 280 px)</p>
                            <div className="bulkminting_profile">

                                <div className="item_inner_img">

                                    {NFTFilename &&
                                        NFTFilename.split(".").pop() == "mp4" ? (
                                        <video
                                            src={Preview1}
                                            alt="collections"
                                            autoPlay={true}
                                            muted={true}
                                            controls
                                        />
                                    ) : (
                                        <img
                                            src={Preview1}
                                            alt="Collections"
                                            className="img-fluid lazy_logo_align"
                                        />

                                    )}
                                </div>
                                <input type="file" name="file" onChange={(e) => Handlefile2(e)} />

                                <i class="fas fa-pencil-alt"></i>
                                {ValidationError && ValidationError.Coverimg &&
                                    <p className="Warning_label">{ValidationError && ValidationError.Coverimg}</p>
                                }
                            </div>

                            <div className="bulkminting_profileimg mt-3 mb-2">
                                <p class="bulking_cover profileimges"> Profile Image</p>

                                <div className="">

                                    {NFTFilename &&
                                        NFTFilename.split(".").pop() == "mp4" ? (
                                        <video
                                            src={Preview2}
                                            alt="collections"
                                            autoPlay={true}
                                            muted={true}
                                            controls
                                        />
                                    ) : (
                                        <img
                                            src={Preview2}
                                            alt="Collections"
                                            className="img-fluid"
                                        />
                                    )}
                                </div>
                                <input type="file" name="file" onChange={(e) => Handlefile3(e)} />

                                <i class="fas fa-pencil-alt"></i>

                            </div>
                            {ValidationError && ValidationError.Profile &&
                                <p className="Warning_label">{ValidationError && ValidationError.Profile}</p>
                            }
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <div className="form-group col-md-12 p-0">

                                <label className="primary_label" htmlFor="category">
                                    Select Network<span className="text-danger">*</span>
                                </label>

                                <Select
                                    className="yes1 form-control primary_inp select1 selxet_app"
                                    onChange={(e) => selectChange(e)}
                                    options={networkoption}
                                    label="Single select"
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                    styles={customStyles}
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                />
                                {ValidationError && ValidationError.NetworkName &&
                                    <p className="Warning_label">{ValidationError && ValidationError.NetworkName}</p>
                                }
                            </div>
                            <div className="form-group col-md-12 p-0">
                                <label className="primary_label" htmlFor="category">
                                    Category<span className="text-danger">*</span>
                                </label>

                                <Select
                                    className="yes1 form-control primary_inp select1 selxet_app"
                                    onChange={(e) => CategorySet(e.name)}
                                    options={Wallet_Details.CategorySBT}

                                    label="Single select"
                                    isSearchable={false}
                                    classNamePrefix="react-select"
                                    styles={customStyles}
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                />
                                {ValidationError && ValidationError.Category &&
                                    <p className="Warning_label">{ValidationError && ValidationError.Category}</p>
                                }
                            </div>
                            <div className="form-group col-md-12 p-0">

                                <label className="primary_label" htmlFor="category">
                                    Sub Category<span className="text-danger">*</span>
                                </label>

                                <Select
                                    className="yes1 form-control primary_inp select1 selxet_app"
                                    onChange={(e) => Formdata.NFTSubCategory = e.name}
                                    options={Subcategorysate}

                                    label="Single select"
                                    isSearchable={false}
                                    classNamePrefix="react-select"
                                    styles={customStyles}
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                />
                                {ValidationError && ValidationError.NFTSubCategory &&
                                    <p className="Warning_label">{ValidationError && ValidationError.NFTSubCategory}</p>
                                }
                            </div>
                            <form className="formCls pb-5">
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <div className="d-flex justify-content-between align-items-start">
                                            <div>
                                                <label className="primary_label" htmlFor="inputEmail4">
                                                    Upload file
                                                </label>
                                                <p className="form_note">
                                                    PNG, WEBP, JPG. Max 30mb, Max 150 Images. Maz 500KB
                                                </p>

                                                {ValidationError && ValidationError.File &&
                                                    <p className="Warning_label">{ValidationError && ValidationError.File}</p>
                                                }

                                            </div>
                                            <div className="file_tn_classf">
                                                <div className="file_btn btn">
                                                    {NFTFile && NFTFile ? "Uploaded" : "Upload"}
                                                    <input
                                                        className="inp_file"
                                                        type="file"
                                                        name="file"
                                                        webkitdirectory="true"
                                                        multiple
                                                        onChange={(e) => Handlefile(e)}
                                                    />
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <div className="d-flex justify-content-between align-items-start">
                                            <div>
                                                <label className="primary_label" htmlFor="inputEmail4">
                                                    Upload Excel Sheet
                                                </label>
                                                <p className="sheetselected">
                                                    Upload .xlsx Format Only.
                                                </p>
                                                {ValidationError && ValidationError.Excel &&
                                                    <p className="Warning_label">{ValidationError && ValidationError.Excel}</p>
                                                }
                                            </div>
                                            <div className="file_tn_classf">
                                                <div className="file_btn btn">
                                                    {NFTFileExcel && NFTFileExcel ? "Uploaded" : "Upload"}
                                                    <input
                                                        className="inp_file"
                                                        type="file"
                                                        name="file"
                                                        onChange={(e) => Handlefile1(e)}
                                                    />
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>


                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label className="primary_label" htmlFor="CollectionName">
                                            SolBound Collection Name<span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control primary_inp"
                                            id="CollectionName"
                                            autoComplete="off"
                                            onChange={(e) => OnChangehandle(e)}
                                            placeholder="e.g. Redeemable"
                                        />
                                        {ValidationError && ValidationError.name &&
                                            <p className="Warning_label">{ValidationError && ValidationError.name}</p>
                                        }
                                        {ValidationError && ValidationError.CollectionName &&
                                            <p className="Warning_label">{ValidationError && ValidationError.CollectionName}</p>
                                        }
                                    </div>
                                </div>


                                <div>
                                    {(Wallet_Details.UserAccountAddr !== "") ?
                                        <Button
                                            className="submitbtng mt-3"
                                            onClick={() => Validate()}
                                        >
                                            Submit
                                        </Button>
                                        :
                                        <Link to="/connect-wallet">
                                            <Button className="submitbtng mt-3">Connect Wallet</Button>
                                        </Link>
                                    }
                                </div>
                            </form>
                        </GridItem>
                    </GridContainer>
                </div>
                <button id="SoulBoundClick" className="d-none" data-target="#SoulBound_item_modal" data-toggle="modal"></button>
            </div>

            <Footer />
            <div
                class="modal fade primary_modal"
                id="learn_modal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="learn_modalCenteredLabel"
                aria-hidden="true"
                data-backdrop="static"
                data-keyboard="false"
            >
                <div
                    class="modal-dialog modal-dialog-centered modal-sm"
                    role="document"
                >
                    <div class="modal-content">
                        <div class="modal-header text-center">
                            <h5 class="modal-title" id="learn_modalLabel">
                                How timed auction work
                            </h5>
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p>
                                When you put your item on timed auction, you choose currency,
                                minimum bid, starting and ending dates of your auction.
                            </p>

                            <p>
                                The bidder can only place a bid which satisfies the following
                                conditions:
                            </p>
                            <ol>
                                <li>It is at least minimum bid set for the auction</li>
                                <li>
                                    It is at least 5% higher than the current highest bid or it is
                                    at least 2.3B OPEN higher than the current highest bid
                                </li>
                            </ol>

                            <p>
                                Note that some bids may disappear with time if the bidder
                                withdraws their balance. At the same time, some bids may
                                reappear if the bidder has topped up their balance again.
                            </p>

                            <p>
                                Auction cannot be cancelled after any valid bid was made. Any
                                bid placed in the last 10 minutes extends the auction by 10
                                minutes.
                            </p>

                            <p>
                                In the 48 hours after the auction ends you will only be able to
                                accept the highest available bid placed during the auction. As
                                with regular bids, you will need to pay some gas to accept
                                it.Note that you can always decrease the price of your listing
                                for free, without making a transaction or paying gas. You can
                                view all your items here.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* end learn modal */}
            <div
                class="modal fade primary_modal"
                id="SoulBound_item_modal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="create_item_modalCenteredLabel"
                aria-hidden="true"
                data-backdrop="static"
                data-keyboard="false"
            >
                <div
                    class="modal-dialog modal-dialog-centered modal-sm"
                    role="document"
                >
                    <div class="modal-content">
                        <div class="modal-header text-center">
                            <h5 class="modal-title" id="create_item_modalLabel">
                                Follow Steps
                            </h5>
                            <button
                                type="button"
                                className={CloseStatus == "On progress" ? "close d-none" : "close"}
                                data-dismiss="modal"
                                id="ClosingSoulBound"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                            {/* } */}
                        </div>
                        <div class="modal-body">
                            <form>
                                <div className="media approve_media">
                                    {IpfsStatusTxt === "Try again" ? (
                                        <i
                                            className="fas fa-exclamation-triangle mr-3 text-danger"
                                            aria-hidden="true"
                                        ></i>
                                    ) : IpfsStatusTxt === "Done" ? (
                                        <i
                                            className="fas fa-check mr-3 pro_complete"
                                            aria-hidden="true"
                                        ></i>
                                    ) : (
                                        <i className="fas fa-check mr-3" aria-hidden="true"></i>
                                    )}
                                    <div className="media-body">
                                        <p className="mt-0 approve_text">Image Upload</p>
                                        <p className="mt-0 approve_desc">
                                            Image Uploading in Server
                                        </p>
                                    </div>
                                </div>
                                <div className="text-center my-3">
                                    <Button
                                        className="create_btn btn-block"
                                        onClick={() => onSubmit()}
                                        disabled={IpfsStatusTxt === "Done"}
                                    >
                                        {IpfsStatusTxt == "On progress" && (
                                            <i
                                                class="fa fa-spinner mr-3 spinner_icon"
                                                aria-hidden="true"
                                            ></i>
                                        )}
                                        {IpfsStatusTxt}
                                    </Button>
                                </div>
                                <div className="media approve_media">
                                    {IpfsStatusTxt1 === "Try again" ? (
                                        <i
                                            className="fas fa-exclamation-triangle mr-3 text-danger"
                                            aria-hidden="true"
                                        ></i>
                                    ) : IpfsStatusTxt1 === "Done" ? (
                                        <i
                                            className="fas fa-check mr-3 pro_complete"
                                            aria-hidden="true"
                                        ></i>
                                    ) : (
                                        <i className="fas fa-check mr-3" aria-hidden="true"></i>
                                    )}
                                    <div className="media-body">
                                        <p className="mt-0 approve_text">IPFS MetaData Data</p>
                                        <p className="mt-0 approve_desc">
                                            Generates IPFS Metadata for Your NFT
                                        </p>
                                    </div>
                                </div>

                                <div className="text-center my-3">

                                    <Button
                                        className="create_btn btn-block"
                                        onClick={() => onSubmitSecond()}
                                        disabled={IpfsStatusTxt === "On progress" || IpfsStatusTxt === "Start" || IpfsStatusTxt1 == "Done"}
                                    >
                                        {IpfsStatusTxt1 == "On progress" && (
                                            <i
                                                class="fa fa-spinner mr-3 spinner_icon"
                                                aria-hidden="true"
                                            ></i>
                                        )}
                                        {IpfsStatusTxt1}
                                    </Button>
                                </div>
                                <div className="media approve_media">
                                    {MintStatusTxt === "Try again" ? (
                                        <i
                                            className="fas fa-exclamation-triangle mr-3 text-danger"
                                            aria-hidden="true"
                                        ></i>
                                    ) : MintStatusTxt === "Done" ? (
                                        <i
                                            className="fas fa-check mr-3 pro_complete"
                                            aria-hidden="true"
                                        ></i>
                                    ) : (
                                        <i className="fas fa-check mr-3" aria-hidden="true"></i>
                                    )}
                                    <div className="media-body">
                                        <p className="mt-0 approve_text">
                                            Upload files & Mint token
                                        </p>
                                        <p className="mt-0 approve_desc">Call contract method</p>
                                    </div>
                                </div>
                                <div className="text-center my-3">
                                    <Button
                                        className="create_btn btn-block"
                                        disabled={IpfsStatusTxt1 !== "Done"}
                                        onClick={() => SoulBoundMint()}
                                    >
                                        {MintStatusTxt == "On progress" && (
                                            <i
                                                class="fa fa-spinner mr-3 spinner_icon"
                                                aria-hidden="true"
                                            ></i>
                                        )}
                                        {MintStatusTxt}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
