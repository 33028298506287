import Header from "components/Header/Header";
import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import Footer from "components/Footer/Footer.js";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styled from "../../node_modules/styled-components";
import $ from "jquery";
import { Link } from "react-router-dom";
import { PackGet } from "actions/v1/token";
import { toast } from "react-toastify";
import ReactHTMLParser from "react-html-parser";

import config from "../lib/config";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useSelector, useDispatch } from "react-redux";
import { PaypalJS } from "./separate/PaypalBuy";
import { PackPurchase, SubcribedUserOrNot } from "../actions/v1/token";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AOS from "aos";
import "aos/dist/aos.css";
import isEmpty from "../lib/isEmpty";
toast.configure();
const Icon = styled((props) => (
  <div {...props}>
    <div className="minus">
      <i class="fa-solid fa-angle-up" />
    </div>
    <div className="plus">
      <i class="fa-solid fa-angle-down" />
    </div>
  </div>
))`
  & > .plus {
    display: block;
    color: #a30726;
    font-size: 24px;
  }
  & > .minus {
    display: none;
    color: #a30726;
    font-size: 24px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;
const dashboardRoutes = [];
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

function PricingPage(props) {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  useEffect(() => {
    AOS.init();
  }, []);

  const { ...rest } = props;

  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  console.log("Wallet_DetailsWallet_Details", Wallet_Details);
  const history = useHistory();
  const [expanded, setExpanded] = React.useState("panel-1");
  const [PackDeatail, SetPackDetail] = useState([]);
  const [Paypalstate, setPaypalstate] = useState({});
  const [Transcation, SetTransCation] = useState({});
  const [ProcessStatus, SetProcess] = useState("Start");
  const [CalPrice, SetCalPrice] = useState(0);
  const [Position, SetPosition] = useState(0);
  const [UpfeePayPal, SetUpfeePayPal] = useState(0);
  const [App_Btn, SetApp_Btn] = useState("Start");
  const [AddSetUpFee, SetAddSetUpFee] = useState("false");
  const [QuantityUserChoose, SetQuantityUserChoose] = useState(0);
  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const responsive = {
    0: {
      items: 1,
    },
    424: {
      items: 1,
    },
    576: {
      items: 2,
    },
    768: {
      items: 3,
    },
    992: {
      items: 4,
    },
    1200: {
      items: 4,
    },
  };

  useEffect(() => {
    if (Wallet_Details?.UserAccountAddr !== "") {
      AlreadySub();
    }
    GetPackDetails();
  }, [Wallet_Details?.UserAccountAddr]);

  const AlreadySub = async () => {
    var PackGetDetails = {
      Address: Wallet_Details?.UserAccountAddr,
    };
    var Subscribed = await SubcribedUserOrNot(PackGetDetails);
    if (Subscribed.data.data.message == "User Not Subscribed") {
      SetAddSetUpFee("false");
    } else {
      SetAddSetUpFee("true");
    }
  };
  const GetPackDetails = async () => {
    if (Wallet_Details.UserAccountAddr !== "") {
      var PackGetDetails = {
        Address: Wallet_Details?.UserAccountAddr,
        Country: Wallet_Details?.AddressUserDetails?.Country,
        Currency: Wallet_Details?.AddressUserDetails?.Currency,
      };
      var GetPacked = await PackGet(PackGetDetails);
      SetPackDetail(GetPacked.data.data.GetPack);
    } else {
      var PackGetDetails = {
        Address: "null",
        Country: "null",
        Currency: "null",
      };
      var GetPacked = await PackGet(PackGetDetails);
      SetPackDetail(GetPacked.data.data.GetPack);
    }
  };

  const paypalfun = async (detail) => {
    if (detail) {
      var validationss = await validations(CalPrice);
      if (isEmpty(validationss)) {
        setPaypalstate(detail);
        console.log("yuyugyuyu");
        window.$("#paypal_item_modal").modal("show");
      } else {
        toast.error(validationss);
      }
    }
  };

  const DBsavePackBuy = async (data, PaypalstateRe) => {
    const reqdata = {
      Transcation: data,
      Paypalstate: PaypalstateRe,
      Address: Wallet_Details.UserAccountAddr,
      Quantity: QuantityUserChoose,
      Price: UpfeePayPal == 0 ? CalPrice : UpfeePayPal,
    };
    SetProcess("Processing");
    var PurchasedData = await PackPurchase(reqdata);
    if (PurchasedData.data.success == true) {
      SetProcess("Done");
      toast.success("purchased successfully");
      window.$("#paypal_item_modal").modal("hide");
      window.location.reload();
    } else {
      toast.error("error in Server");
      SetProcess("Try Again");
    }
  };

  const OnChangehandle = async (e, item, ind) => {
    let QuantityEntered = e.target.value;
    var Condition = item.RangeAndPrice[0];
    var keyEmpty = [];
    Object.entries(Condition).forEach(([key, value, entries]) => {
      keyEmpty.push(value);
    });
    var Quantity = [];
    var Pricessss = [];
    for (var i = 0; i < keyEmpty.length; ++i) {
      if (i % 2 === 0) {
        Quantity.push(keyEmpty[i]);
      } else {
        Pricessss.push(keyEmpty[i]);
      }
    }
    var FinalQuantityValue = 0;
    for (var i = 0; i < Quantity.length; i++) {
      if (e.target.value <= Number(Quantity[i])) {
        FinalQuantityValue = Quantity[i];
        break;
      } else {
        FinalQuantityValue = Quantity[Quantity.length - 1];
      }
    }
    if (FinalQuantityValue !== 0) {
      var findIndex = Quantity.indexOf(FinalQuantityValue);
      var PriceMultiply = Pricessss[findIndex] * e.target.value;
      if (AddSetUpFee == "false") {
        var ToFixedd = toFixedNumber(PriceMultiply);
        SetCalPrice(ToFixedd);
        SetUpfeePayPal(PriceMultiply + Number(item.Price));
        SetPosition(ind);
        SetQuantityUserChoose(e.target.value);
      } else {
        SetCalPrice(PriceMultiply);
        SetPosition(ind);
        SetQuantityUserChoose(e.target.value);
      }
    }
  };

  const toFixedNumber = (x) => {
    if (Math.abs(x) < 1.0) {
      var e = parseInt(x.toString().split("e-")[1]);
      if (e) {
        x *= Math.pow(10, e - 1);
        x = "0." + new Array(e).join("0") + x.toString().substring(2);
      }
    } else {
      var e = parseInt(x.toString().split("+")[1]);
      if (e > 20) {
        e -= 20;
        x /= Math.pow(10, e);
        x += new Array(e + 1).join("0");
      }
    }
    return x.toString();
  };

  const validations = async (data) => {
    var validationerr = {};
    let dateRegex = /[^0-9]/;
    if (dateRegex.test(data) == true) {
      validationerr = "Quantity cannot be Empty And it must be in Number";
    }
    if (data == 0) {
      validationerr = "Quantity must be greater than zero";
    }
    return validationerr;
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };

  return (
    <>
      {/* <Header/> */}
      <div className="home_header home header_bar_align">
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand={
            <Link to="/home">
              <div className="header_logo_align"></div>
            </Link>
          }
          leftLinks={<HeaderLinks />}
          changeColorOnScroll={{
            height: 50,
            color: "dark",
          }}
          {...rest}
        />
        <ScrollToTopOnMount />
        <div className="container pricing_home_banner_align">
          <GridContainer>
            {console.log("qewewqqweqw", PackDeatail)}
            <GridItem xs={12} sm={12} md={12}>
              <div className="banner_Pricing_txt_align">
                <h1
                  className="fw_700 heading_blue_txt playfair_txt"
                  data-aos="fade-up"
                >
                  Choose An On-Demand Plan To Suit Your Needs
                </h1>
                <p className="on_demand_hinttxt_align dark_gray_text">
                  Every business and organization has different needs and the
                  key is making it value for money and affordable for all. So,
                  we had designed different on-demand packages for you, whether
                  you are sole-proprietor, small business or large business and
                  even for advertising or media agency to help your clients.
                </p>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="container">
          <GridContainer>
            {PackDeatail &&
              PackDeatail.length > 0 &&
              PackDeatail.map((Item, index) => {
                return (
                  <GridItem
                    xs={12}
                    sm={6}
                    md={6}
                    lg={3}
                    xl={3}
                    xxl={3}
                    className="third_tab_top_align mb-3"
                  >
                    <div className="pricing_card_align">
                      <h3 className="heading_blue_txt type_dif_txt ">
                        {Item?.displayName}
                      </h3>
                      <p className="gray_text plan_type_display mb-4 mt-4">
                        {Item?.ShortDiscription}
                      </p>
                
                      <div className="prcng_blue_cmnheight">
                        <h2>
                          {Number(Item.Price) == 0
                            ? "FREE"
                            : " $" + Item.LastValue + "/ Token"}
                        </h2>

                        {Number(Item.Price) == 0 ? (
                          ""
                        ) : (
                          <div className="col-sm-12 text-center">
                            {index == Position ? (
                              <h5 className="hide_txt_align">
                                {CalPrice == 0
                                  ? ""
                                  : "USD $ " +
                                    Math.round(Number(CalPrice) * 100) / 100 +
                                    " " +
                                    ""}
                              </h5>
                            ) : (
                              ""
                            )}

                            <input
                              type="number"
                              className="line_input_align "
                              id="name"
                              autoComplete="off"
                              onChange={(e) => OnChangehandle(e, Item, index)}
                              placeholder="No.of Tokens"
                              onKeyDown={handleKeyDown}
                              onWheel={(e) => e.target.blur()}
                            />
                          </div>
                        )}
                      </div>

                      
                      <button
                        className="pricing_buynow_btn_align"
                        disabled={Number(Item.Price) == 0 ? true : false}
                        onClick={() => paypalfun(Item)}
                      >
                        {Item.Price == 0 ? "FREE" : "Buy Now"}
                      </button>

                      <div>
                        
                        <div className="pricing_card_dtls_align">
                          
                          <p className="pricing_card_txt_align gray_text">
                            {ReactHTMLParser(Item.Discrption)}
                          </p>
                        </div>
                      </div>
                     
                    </div>
                  </GridItem>
                );
              })}
          </GridContainer>
        </div>

        <div className="container mt-5">
          <div className="whole_accord_dtls_align">
            <div className="pricing_accordtitle_align">
              <h1 className="common_fontweigt_align common_title_align heading_blue_txt playfair_txt">
                Pricing FAQ's
              </h1>
              <p className="common_hint_align heading_blue_txt playfair_txt">
                Some questions which we often hear from our customers.
              </p>
            </div>
            <div className="main_accord_align">
              <GridContainer>
                <GridItem xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
                  <img
                    className="question_img_align"
                    src={require("../assets/images/faces/question_img.png")}
                  />
                </GridItem>
                <GridItem xxl={9} xl={9} lg={9} md={6} sm={12} xs={12}>
                  <Accordion
                    expanded={expanded === "panel1"}
                    className="pricing_accordian_align"
                    onChange={handleChangeFaq("panel1")}
                  >
                    <AccordionSummary
                      expandIcon={<Icon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <div className="accordian_head">
                        <h6 className="sub_blue_text accod_title_align">
                          How do I create a free trial account on LazyMinter?
                        </h6>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordian_para">
                        <p>
                          Click on the “Free Trial” button and we direct you to
                          create a free account with LazyMinter.{" "}
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel2"}
                    className="pricing_accordian_align"
                    onChange={handleChangeFaq("panel2")}
                  >
                    <AccordionSummary
                      expandIcon={<Icon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <div className="accordian_head">
                        <h6 className="sub_blue_text accod_title_align">
                          How long must I commit when signing up for the
                          packages?
                        </h6>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordian_para">
                        <p>
                          We charge according to the number certificates,
                          tickets or loyalty cards, or other giftcards that
                          you’ll mint on the blockchain and all under your
                          control. The NFTs credits will last for 12 months and
                          after that we’ll reset the credits to zero. So, there
                          is really no time frame that you need to commit as it
                          is an on-demand NFT minting service.{" "}
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "panel3"}
                    className="pricing_accordian_align"
                    onChange={handleChangeFaq("panel3")}
                  >
                    <AccordionSummary
                      expandIcon={<Icon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <div className="accordian_head">
                        <h6 className="sub_blue_text accod_title_align">
                          Will there be someone to help me along to guide me?
                        </h6>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordian_para">
                        <p>
                          Definitely, our customer success directors will help
                          you onboard easily with chats and demo for basic
                          introduction. We also recommend you to watch our 5
                          mins demo tour to familiarize yourself before you
                          start minting on LazyMinter. If you need more support
                          to ensure you run your project successfully, we
                          recommend your purchase our “LaunchPad Services’
                          during the checkout process, and it is optional for
                          you.{" "}
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "panel4"}
                    className="pricing_accordian_align"
                    onChange={handleChangeFaq("panel4")}
                  >
                    <AccordionSummary
                      expandIcon={<Icon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <div className="accordian_head">
                        <h6 className="sub_blue_text accod_title_align">
                          What are 'LaunchPad Services'?
                        </h6>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordian_para">
                        <p>
                          LaunchPad Services are a set of consulting and support
                          services that we provide for clients. We will be your
                          extended team to consult and help you launch your
                          project successfully on LazyMinter (arts and music
                          NFTs projects, ticketing, certificates distributions,
                          authentications, membership program etc.). If you
                          purchase a UNIT (=$800) of LaunchPad Service, it is
                          equivalent for 2-hours session for any one of the
                          following services:
                          <br />
                          – Go-to-Market Strategic Advisory
                          <br />
                          – Campaign Planning & Tracking
                          <br />
                          – Brainstorming Ideas
                          <br />
                          – LazyMinter BlockChain Technical Support
                          <br />
                          – Preparing Data and Images for Bulk LazyMinting
                          <br />
                          – Setting up DAO
                          <br />
                          – Community Building
                          <br />
                          Our consultants are all highly trained experts in the
                          field with practical knowledge and know-how and you’ll
                          have a peace of mind knowing your projects would be
                          launched successfully. However, if you would like a
                          more customised solution, please feel free to contact
                          us via the chat or email us at
                          marketing@lazyminter.com{" "}
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>

        <div className="container">
          <GridContainer>
            <GridItem
              className="mb-3"
              xxl={4}
              xl={4}
              lg={4}
              md={6}
              sm={6}
              xs={12}
            >
              <h1 className="common_title_align client_title_align common_fontweigt_align playfair_txt">
                Our Clients
              </h1>
              <p className=" onboard_dtls_align sub_blue_text playfair_txt">
                Onboarding Web3.0 for leading brands
              </p>
            </GridItem>
            <GridItem xxl={8} xl={8} lg={8} md={6} sm={6} xs={12}>
              <OwlCarousel
                className="owl-theme owl_carousal_align"
                autoplay={true}
                responsive={responsive}
                nav={false}
                loop
                margin={0}
                dots={true}
              >
                <div class="item owl_item_align">
                  <div>
                    <img
                      className="owl_img_align"
                      src={require("../assets/images/faces/atgroup.png")}
                    />
                  </div>
                </div>
                <div class="item owl_item_align">
                  <div>
                    <img
                      className="owl_img_align"
                      src={require("../assets/images/faces/ESG.png")}
                    />
                  </div>
                </div>
                <div class="item owl_item_align">
                  <div>
                    <img
                      className="owl_img_align"
                      src={require("../assets/images/faces/Jeep.png")}
                    />
                  </div>
                </div>
                <div class="item owl_item_align">
                  <div>
                    <img
                      className="owl_img_align newton_img"
                      src={require("../assets/images/faces/newton.png")}
                    />
                  </div>
                </div>
                <div class="item owl_item_align">
                  <div>
                    <img
                      className="owl_img_align"
                      src={require("../assets/images/faces/trio.png")}
                    />
                  </div>
                </div>
                <div class="item owl_item_align">
                  <div>
                    <img
                      className="owl_img_align"
                      src={require("../assets/images/faces/w3l.png")}
                    />
                  </div>
                </div>
              </OwlCarousel>
            </GridItem>
          </GridContainer>
        </div>

        <div className="container mt-5">
          <GridContainer>
            <div className="whole_journey_dtls_align">
              <h1
                className="common_fontweigt_align common_title_align heading_blue_txt playfair_txt"
                data-aos="fade-up"
              >
                Create The Right Customer Journey
              </h1>
              <p className="common_hint_one_align gray_text ">
                If you still need some time to consider, why not watch our
                product walkthrough demo to see how easy is it for you to get
                started.
              </p>
              <button
                onClick={() =>
                  openInNewTab(
                    "https://scribehow.com/page-embed/LazyMinter_Onboarding_Manual__9nYdqH49Q0C3iX-bWOPiww"
                  )
                }
                className="pricing_freetial_btn_align"
              >
                Onboarding Manual
              </button>
              <img
                className="img-fluid lady_selfie_img_align"
                data-aos="fade-right"
                src={require("../assets/images/lady_selfie.png")}
              />
              <img
                className="img-fluid boy_study_img_align"
                src={require("../assets/images/boy_study.png")}
              />
            </div>
          </GridContainer>
        </div>
        <div className="container hide_img_align"></div>

        <Footer />

        {/* end learn modal */}

        <div
          class="modal fade primary_modal"
          id="paypal_item_modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="create_item_modalCenteredLabel"
          aria-hidden="true"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-sm"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header d-flex justify-content-between">
                <h5 class="modal-title" id="create_item_modalLabel">
                  Please select your preferred mode of payment
                </h5>
               
                <button
                  type="button"
                  
                  data-dismiss="modal"
                  id="ClosingSoulBound"
                  aria-label="Close"
                  className="closebtn"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                {/* } */}
              </div>
              <div class="modal-body">
                <form>
                  {console.log("qweiooiqwe", CalPrice)}
                  <div className="text-center my-3">
                    <PayPalScriptProvider
                      options={{
                        "client-id": config.PayPalKey,
                      }}
                    >
                      <PayPalButtons
                        style={{ layout: "vertical" }}
                        disabled={App_Btn == "Done" ? true : false}
                        forceReRender={[CalPrice, UpfeePayPal, Paypalstate]}
                        createOrder={(data, actions) => {
                          SetApp_Btn("process");
                          return actions.order
                            .create({
                              purchase_units: [
                                {
                                  amount: {
                                    currency_code: "USD",
                                    value:
                                      UpfeePayPal == 0 ? CalPrice : UpfeePayPal,
                                  },
                                },
                              ],
                            })
                            .then((orderId) => {
                            
                              return orderId;
                            });
                        }}
                        onApprove={(data, actions) => {
                          return actions.order.capture().then((details) => {
                            
                            const Data = {
                              Buyername: details.payer.name.given_name,
                              id:
                                details.purchase_units[0].payments.captures[0]
                                  .id,
                              TranscationId: details.id,
                            };
                            SetApp_Btn("Done");
                            SetTransCation(Data);
                            console.log("qqweweoioiio", Data.Buyername);
                           
                            DBsavePackBuy(Data, Paypalstate);
                           
                          });
                        }}
                        onError={(err) => {
                          SetApp_Btn("Try Again");
                          toast.error(err, { autoClose: 1000 });
                        }}
                      />
                    </PayPalScriptProvider>
                  </div>
                 
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PricingPage;
