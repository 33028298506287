import React, { useState } from "react";
import { Button } from "@material-ui/core";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { FractionalIPFS, Fractional2DB } from "actions/controller/tokenControl";
import { ContactMintFraction, LMSignCall } from "actions/v1/token"
import { ReciptCommon, useTokenFractionApprove } from "hooks/useReceipts";
import { useSelector } from "react-redux";
import $ from "jquery";
import Web3 from 'web3';
import config from 'lib/config'
import { useHistory } from "react-router-dom";
import randomInteger from 'random-int';
import Web3Utils from 'web3-utils'

export default function MintingCard(props) {
  const { NFTDetails } = props;
  let history = useHistory()
  const [IpfsStatus, setIpfsStatus] = useState(false);
  const [MintStatus, setMintStatus] = useState(true);
  const [SignSellStatus, setSignSellStatus] = useState(true);
  const [IpfsStatusTxt, setIpfsStatusTxt] = useState("Start");
  const [MintStatusTxt, setMintStatusTxt] = useState("Start");
  const [SignTxt, SetSignTxt] = useState("Start");
  const [SignatureHash, setSignatureHash] = useState('');
  const [NonceHash, setNonceHash] = useState(0);
  const [PurchaseCallStatus, setPurchaseCallStatus] = useState('')
  const [RandomName, setRandomName] = useState('')
  const [CloseStatus, setCloseStatus] = useState('Start')
  const [MessageApprove, setMessageApprove] = useState("Start")
  const [StatusApprove, setStatusApprove] = useState("Start")

  const Wallet_Details = useSelector((state) => state.wallet_connect_context);

  const IPFS = async () => {
    const id = toast.loading("Generating IPFS Data");
    setIpfsStatus(true);
    setIpfsStatusTxt("On progress");
    var result = await FractionalIPFS(NFTDetails, NFTDetails.File, Wallet_Details.UserAccountAddr);
    setTimeout(() => {
      if (result.Success) {
        setIpfsStatusTxt("Done");
        setMintStatus(false)
        // console.log("ipfs result", result)
        NFTDetails.Ipfs = `${config.IPFS_IMG}/${result.data.ipfsmetadata}`
        NFTDetails.IpfsImage = result.data.ipfsimage
        NFTDetails.metajson = result.data.MetFileName
        NFTDetails.ImageName = result.data.ImageName
        NFTDetails.additionalImage = result.data.additionalImage


        toast.update(id, { render: result.Message, type: "success", isLoading: false, autoClose: 2500 });
      } else {
        setIpfsStatus(false);
        setIpfsStatusTxt("Try again");
        toast.update(id, { render: result.Message, type: "error", isLoading: false, autoClose: 2500 });
      }
    }, 1000);
  };
  /// ---------> old Mint call

  const MintCallFractions = async () => {
    const id = toast.loading("Awaiting Response");
    setMintStatusTxt("On Progress")
    setMintStatus(true)
    try {
      console.log("NFTDetails", NFTDetails);
      var web3RpcPro = new Web3(Wallet_Details.networkConfiguration.RPC_URL)

      var Argument = [[NFTDetails.Ipfs, NFTDetails.name, NFTDetails.name], [web3RpcPro.utils.toWei(String(NFTDetails.royalties)), NFTDetails.quantity, web3RpcPro.utils.toWei(String(NFTDetails.TokenPrice))]]
      if (localStorage.walletConnectType !== "LMWallet") {
        var Blockresp = await ReciptCommon(Argument, Wallet_Details, "minting")
      } else {
        var Key = null
        var Argument = [[NFTDetails.Ipfs, NFTDetails.name, NFTDetails.name], [web3RpcPro.utils.toWei(String(NFTDetails.royalties)), NFTDetails.quantity, web3RpcPro.utils.toWei(String(NFTDetails.TokenPrice))]]
        var Blockresp = await LMWalletCall(Argument, Wallet_Details, "minting", "Mint", Key)
      }
      console.log("BlockrespBlockresp", Blockresp);
      if (Blockresp && Blockresp.status) {
        var HexStringAddress = Blockresp.logs[3].topics[1];
        console.log("HexStringAddress", HexStringAddress);
        var HexStringToken = Blockresp.logs[4].topics[2];
        console.log("HexStringToken", HexStringToken);
        const ERC20 = web3RpcPro.eth.abi.decodeParameter("address", HexStringAddress);
        //  Web3Utils.hexToNumber(HexStringToken)
        const TokenID = Web3Utils.hexToNumber(HexStringToken);
        console.log("ERC20ERC20", ERC20, TokenID);
        NFTDetails.TokenID = TokenID
        NFTDetails.Status = Blockresp.status
        NFTDetails.HashValue = Blockresp.transactionHash
        NFTDetails.ERC20 = ERC20
        toast.update(id, { render: "Block Successfully Created", type: "success", isLoading: false, autoClose: 2000 });
        setMintStatusTxt("Done")
        setSignSellStatus(false)
      }
      else {
        toast.update(id, { render: "Failed To Confirm", type: "error", isLoading: false, autoClose: 2000 });
        setMintStatusTxt("Try Again")
        setMintStatus(false)
      }
      console.log("respformminthook", Blockresp)
    }
    catch (e) {
      toast.update(id, { render: "Failed to Confirm", type: "error", isLoading: false, autoClose: 2000 });
      console.log("respformminthook", e)
      setMintStatusTxt("Try Again")
      setMintStatus(false)
    }
  }

  const ApproveTokenCall = async () => {
    console.log("NFTDetails.ERC20", NFTDetails);
    setStatusApprove(true)
    setMessageApprove("In Progress")
    const id = toast.loading("Awaiting Response");
    var Arg = [NFTDetails.ERCTrade, NFTDetails.quantity]
    var Provider = Wallet_Details
    if (localStorage.walletConnectType !== "LMWallet") {
      var Receipt = await ContactHookTokenApp(Arg, Provider, "approve", NFTDetails.ERC20)
    } else {
      var Key = null
      var Receipt = await LMWalletCall(Arg, Provider, "approve", "Token", Key, NFTDetails.ERC20)
    }
    if (Receipt.status) {
      var Resp = await Fractional2DB(NFTDetails, NFTDetails.File, Wallet_Details.UserAccountAddr, RandomName, NonceHash, SignatureHash)
      console.log("RespResp", Resp);
      if (Resp) {
        setStatusApprove(true)
        setMessageApprove("Done")
        toast.update(id, { render: "NFT Minted Successfully", type: "success", isLoading: false, autoClose: 2000 });
        $('#ClosingMintCard').trigger("click");
        history.push('/my-items');
      } else {
        setStatusApprove(false)
        setMessageApprove("Try Again")
        toast.update(id, { render: "Failed to Upload NFT", type: "success", isLoading: false, autoClose: 2000 });
      }
    } else {
      setStatusApprove(false)
      setMessageApprove("Try Again")
      // toast.error("Try it Again Later.Please Check Your Funds")
      toast.update(id, { render: "Failed to Approve.", type: "error", isLoading: false, autoClose: 2000 });
    }
  }

  const ContactHookTokenApp = async (Arg, Provider, Method, TokenAddress) => {
    var Receipt = await useTokenFractionApprove(Arg, Provider, Method, TokenAddress)
    return Receipt
  }

  const LMWalletCall = async (Arg, Wallet_Details, method, NameUse, Key, TokenAddress) => {
    var receipt = null
    console.log("Comming Inside the LmWallet", Arg, Wallet_Details, method, Key);
    var Arg = {
      Method: method,
      Data: Arg,
      Mail: localStorage.loggedinaddress,
      LoginAddress: localStorage.userAddress,
      ContactAddress: Wallet_Details.networkConfiguration.FractionTradeContract,
      Chain: String(NFTDetails.NetworkName),
      ContactType: NameUse,
      IntractContract: Wallet_Details.networkConfiguration.FractionTradeContract,
      Key: Key,
      Status: false,
      TokenAddress: TokenAddress
    }
    var Receiptfun = await ContactMintFraction(Arg)

    console.log("ArgReceipt", Receiptfun.data.receipt);
    return Receiptfun.data.receipt
  }

  async function signcall() {
    try {
      const id = toast.loading("Awaiting Response");
      var generator = require('generate-password');
      var randomNum = randomInteger(10000000, 100000000);
      var password = generator.generate({
        length: 10,
        numbers: true
      });
      var web3RpcPro = new Web3(Wallet_Details.networkConfiguration.RPC_URL)
      var chain = NFTDetails.NetworkName == "polygon" ? config.MATIC_ID : NFTDetails.NetworkName == "camino" ? config.CAM_ID : config.ETH_ID
      const to = Wallet_Details.UserAccountAddr
      const _amount = NFTDetails.TokenPrice == "" || NFTDetails.TokenPrice == undefined ? 0 : web3RpcPro.utils.toWei(String(NFTDetails.TokenPrice));
      const _nonce = Date.now();
      setRandomName(password)
      var tot = _nonce + Number(randomNum);
      setNonceHash(tot);
      if (localStorage.walletConnectType !== "LMWallet") {
        if (Wallet_Details.Web3Pro) {
          var web3Rpc = new Web3(Wallet_Details.providerss)
          if (web3Rpc) {
            SetSignTxt('On progress')
            setCloseStatus('On progress')
            var web3RpcPro = new Web3(Wallet_Details.providerss);
            const result = web3RpcPro.utils.soliditySha3(to, _amount, password, tot);
            const signhash = await Wallet_Details.Web3Pro.eth.personal.sign(result, to);
            toast.update(id, { render: "NFT Minted Successfully", type: "success", isLoading: false, autoClose: 2000 });
            if (signhash) {
              SetSignTxt('done')
              setPurchaseCallStatus('init')
              setSignatureHash(signhash);
            }
          }
        }
      }
      else {
        var Arg = {
          to: to,
          _amount: _amount,
          password: password,
          tot: tot,
          Type: NFTDetails.type == 721 ? "721" : "1155",
          Chain: String(chain),
          Mail: localStorage.loggedinaddress,
          LoginAddress: localStorage.userAddress,
          Key: null
        }
        var receipt = await LMSignCall(Arg)

        if (receipt.data.Status) {
          toast.update(id, { render: "Signature Verified Successfully", type: "success", isLoading: false, autoClose: 2000 });
          SetSignTxt('done')
          setPurchaseCallStatus('init')
          setSignatureHash(receipt.data.signedTx);
        }
      }
    } catch (e) {
      SetSignTxt('Try again')
      toast.update(id, { render: "Signature Verification Failed. Try It Again Later", type: "error", isLoading: false, autoClose: 2000 });
    }
  }


  return (
    <>
      {/* create_item Modal */}
      <div
        class="modal fade primary_modal"
        id="create_item_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="create_item_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="create_item_modalLabel">
                Follow Steps
              </h5>
              <button
                type="button"
                className={CloseStatus == "On progress" ? "close d-none" : "close"}
                data-dismiss="modal"
                id="ClosingMintCard"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                {NFTDetails.Fiat &&
                  <>
                    <div className="media approve_media">
                      {SignTxt === "Try again" ? (
                        <i
                          className="fas fa-exclamation-triangle mr-3 text-danger"
                          aria-hidden="true"
                        ></i>
                      ) : SignTxt === "done" ? (
                        <i
                          className="fas fa-check mr-3 pro_complete"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i className="fas fa-check mr-3" aria-hidden="true"></i>
                      )}
                      <div className="media-body">
                        <p className="mt-0 approve_text">Sign transaction</p>
                        <p className="mt-3 purchase_desc"></p>
                      </div>
                    </div>
                    <div>
                      <Button
                        type="button"
                        onClick={() => signcall()}
                        className={"create_btn btn-block"}
                        disabled={(SignTxt === "done") || (SignTxt === "On progress")}
                      >
                        {SignTxt == "On progress" && (
                          <i
                            class="fa fa-spinner mr-3 spinner_icon"
                            aria-hidden="true"
                          ></i>
                        )}
                        {SignTxt}
                      </Button>
                    </div>
                  </>
                }

                <div className="media approve_media">
                  {IpfsStatusTxt === "Try again" ? (
                    <i
                      className="fas fa-exclamation-triangle mr-3 text-danger"
                      aria-hidden="true"
                    ></i>
                  ) : IpfsStatusTxt === "Done" ? (
                    <i
                      className="fas fa-check mr-3 pro_complete"
                      aria-hidden="true"
                    ></i>
                  ) : (
                    <i className="fas fa-check mr-3" aria-hidden="true"></i>
                  )}
                  <div className="media-body">
                    <p className="mt-0 approve_text">IPFS MetaData</p>
                    <p className="mt-0 approve_desc">
                      Generates IPFS Metadata for Your NFT
                    </p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button
                    className="create_btn btn-block"
                    onClick={() => IPFS()}
                    disabled={NFTDetails.Fiat ? ((((SignTxt === "On progress") || IpfsStatusTxt === "Done")) || (SignTxt === "Start")) : ((SignTxt === "On progress") || IpfsStatusTxt === "Done")}
                  >
                    {IpfsStatusTxt == "On progress" && (
                      <i
                        class="fa fa-spinner mr-3 spinner_icon"
                        aria-hidden="true"
                      ></i>
                    )}
                    {IpfsStatusTxt}
                  </Button>
                </div>
                <div className="media approve_media">
                  {MintStatusTxt === "Try Again" ? (
                    <i
                      className="fas fa-exclamation-triangle mr-3 text-danger"
                      aria-hidden="true"
                    ></i>
                  ) : MintStatusTxt === "Done" ? (
                    <i
                      className="fas fa-check mr-3 pro_complete"
                      aria-hidden="true"
                    ></i>
                  ) : (
                    <i className="fas fa-check mr-3" aria-hidden="true"></i>
                  )}
                  <div className="media-body">
                    <p className="mt-0 approve_text">Mint token</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button
                    className="create_btn btn-block"
                    onClick={() => MintCallFractions()}
                    disabled={MintStatus}
                  >
                    {MintStatusTxt == "On progress" && (
                      <i
                        class="fa fa-spinner mr-3 spinner_icon"
                        aria-hidden="true"
                      ></i>
                    )}
                    {MintStatusTxt}
                  </Button>
                </div>
                <div className="media approve_media">
                  {MessageApprove === "Try Again" ? (
                    <i
                      className="fas fa-exclamation-triangle mr-3 text-danger"
                      aria-hidden="true"
                    ></i>
                  ) : MessageApprove === "Done" ? (
                    <i
                      className="fas fa-check mr-3 pro_complete"
                      aria-hidden="true"
                    ></i>
                  ) : (
                    <i className="fas fa-check mr-3" aria-hidden="true"></i>
                  )}
                  <div className="media-body">
                    <p className="mt-0 approve_text">
                      Aprrove Token & Upload Files
                    </p>
                    <p className="mt-0 approve_desc">Call contract method</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button
                    className="create_btn btn-block"
                    disabled={(MessageApprove == "On Progress" || MintStatusTxt === "On Progress") || (MintStatusTxt == "On progress" || MintStatusTxt === "Start")}
                    onClick={() => ApproveTokenCall()}
                  >
                    {MessageApprove == "On progress" && (
                      <i
                        class="fa fa-spinner mr-3 spinner_icon"
                        aria-hidden="true"
                      ></i>
                    )}
                    {MessageApprove}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end create_item modal */}
    </>
  );
}