import React, { useEffect, useState } from "react";

import Tickets1 from "../../assets/images/lazymintingnew/tickets.jpg";
import user from "../../assets/images/lazymintingnew/user.png"
import ETH from "../../assets/images/lazymintingnew/eth_logo.png"
import Polygon from "../../assets/images/lazymintingnew/polygon-matic-icon.svg"
import polygon_violet_lighttheme from '../../assets/images/lazymintingnew/polygon_violet_lighttheme.png'
import { toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Banner from "../../assets/images/lazymintingnew/myitemsprofile.jpg"
import Banner1 from "../../assets/images/lazymintingnew/img-2.png"
import config from "lib/config";

toast.configure();
import { Account_Connect, Account_disConnect } from "actions/redux/action";
export default function Collection_Card(props) {
    console.log("gdfusdfiuyhfduhweufsi", props);
    const dispatch = useDispatch();
    const Wallet_Details = useSelector((state) => state.wallet_connect_context);

    const ChooseNftIds = async (Ids, e) => {
        console.log("Ids", e, Ids);

        if (e == false) {

            var Pushh = Wallet_Details.VaultNFts
            Pushh.push(Ids)
            dispatch({
                type: Account_Connect,
                Account_Detail: {
                    VaultNFts: Pushh
                }
            })
        } else if (e == true) {
            var Pushh = Wallet_Details.VaultNFts
            Pushh.length > 0 && Pushh.splice(Pushh.findIndex(a => a.tokenID === Ids.tokenID), 1)
            console.log("ajdasmndasdjasdas", Pushh);
            //Indexesss.length > 0 && Indexesss.splice(Indexesss.findIndex(a => a === Ids._id), 1)
            //console.log("sdfsdfearqewrwe", Indexesss);
            dispatch({
                type: Account_Connect,
                Account_Detail: {
                    VaultNFts: Pushh
                }
            })
        }
    }
    console.log("asdadqweqwe", Wallet_Details?.VaultNFts);
    return (
        <>
            {props.Item.status !== "Soulbound" ?
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 col-12 mb-4">
                    <div className="style_prevu_ki1t yellowheight collectionscard" id="ticketscards">



                        <Link to={{ pathname: `/info/${props?.Item?.contractAddress}/${props?.Item?.tokenCreator}/${props?.Item?.tokenID}`, state: props?.Item, location: "collection" }} >
                            <div className="trendingban">
                                {/* {console.log("sdfdfsdfdafdf",props?.Item?.ref)} */}
                                {console.log("adfa,sdasd", `${config.Back_URL}/nftImg/${props?.Item?.tokenCreator}/${props?.Item?.image}`)}
                                {
                                    props &&
                                    props?.Item?.image &&
                                    (props?.Item?.image.split(".").pop() == "mp4" ? (
                                        <video
                                            id="my-video"
                                            className="img-fluid"
                                            autoPlay
                                            playsInline
                                            loop
                                            muted
                                            preload="auto"
                                            controls controlsList="nodownload"
                                        >
                                            <source src={`${config.Back_URL}/nftImg/${props?.Item?.tokenCreator}/${props?.Item?.image}`} type="video/mp4" />
                                        </video>

                                    ) : (
                                        <img
                                            src={`${config.Back_URL}/nftImg/${props?.Item?.tokenCreator}/${props?.Item?.image}`}
                                            alt="Collections"
                                            className="img-fluid"
                                        />

                                    ))}

                            </div>
                        </Link>

                        <div className="content">
                            <div className="header">
                                <div className="left-main">
                                    <label className="market-label">Limited Edition</label>
                                    <h1 className="titlegenereal">{props?.Item?.tokenName.slice(0, 10)}</h1>
                                    <div className="brand">
                                        <span> <img src={user} className="profilebamds" /></span>
                                        <p>{props?.Item?.tokenCreator.slice(0, 7)}</p>
                                    </div>
                                </div>
                                <div className="time-stamp-container">
                                    {/* <div className="left">

                                   <div className="left__date-month-wrapper">
                                        <div className="left__date-month-wrapper-inner">
                                             <label>Nov</label>
                                             <h2>3-4</h2>
                                        </div>
                                        
                                   </div>
                              </div> */}

                                    <div className="right">
                                        <div className="left__date-month-wrapper">
                                            <div className="left__date-month-wrapper-inner">
                                                <h2>{props?.Item?.startDateToken}-</h2>
                                                <h2>{props?.Item?.endDateToken}</h2>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="line"></div>
                            <div className="price-container">
                                <div className="left w-50">

                                    <div className="top">
                                        Price :{props?.Item?.tokenPrice}
                                        &emsp;&emsp;{props?.Item?.coinname}
                                    </div>
                                    {/* <div className="bottom top">
                  
                    Quantity :{props?.Item?.tokenQuantity}
                    

                  <br />
                </div> */}
                                </div>
                                <div className="right w-50">
                                    <div className="top">
                                        Description
                                    </div>
                                    <div className="bottom text-right">
                                        <span className="primary-price">
                                            <span>

                                                {props?.Item?.tokenDesc.slice(0, 50)}

                                            </span>
                                        </span>


                                    </div>
                                </div>
                            </div>
                            <div className="card-button-container">
                                <div className="icon">
                                    {props?.Item?.coinname == 'ETH' ? <img src={ETH} /> :
                                        <img src={polygon_violet_lighttheme} />}
                                </div>
                                <div className="button">
                                    <Link to={{ pathname: `/info/${props?.Item?.contractAddress}/${props?.Item?.tokenCreator}/${props?.Item?.tokenID}`, state: props?.Item, location: "collection" }} >{props?.Item?.tokenCreator == Wallet_Details.UserAccountAddr ? <button>You Own This </button> : <button>Buy Now </button>}</Link>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>

                :
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 col-12 mb-4">
                    <div className="style_prevu_ki1t yellowheight collectionscard" id="ticketscards" onClick={(e) => ChooseNftIds(props.Item, Wallet_Details?.VaultNFts?.some(it => it.tokenID == props.Item?.tokenID))}>



                        <Link to={{ pathname: `/SoulInfo/${props?.Item?.contractAddress}/${props?.Item?.tokenOwner}/${props?.Item?.QR_tokenID}`, state: props?.Item, location: "Soul" }} >
                            <div className="trendingban">
                                {/* {console.log("sdfdfsdfdafdf",props?.Item?.ref)} */}

                                {
                                    props &&
                                    props?.Item?.bulkImgPath &&
                                    props?.Item?.ref &&
                                    (props?.Item?.ref.split(".").pop() == "mp4" ? (
                                        <video
                                            id="my-video"
                                            className="img-fluid"
                                            autoPlay
                                            playsInline
                                            loop
                                            muted
                                            preload="auto"
                                            controls controlsList="nodownload"
                                        >
                                            <source src={`${config.Back_URL}/${props?.Item?.FinalOutBulkPath}`} type="video/mp4" />
                                        </video>

                                    ) : (
                                        <img
                                            src={`${config.Back_URL}/${props?.Item?.FinalOutBulkPath}`}
                                            alt="Collections"
                                            className="img-fluid"
                                        />

                                    ))}

                            </div>
                        </Link>

                        <div className="content">
                            <div className="header">
                                <div className="left-main">
                                    <label className="market-label">Limited Edition</label>
                                    <h1 className="titlegenereal">{props?.Item?.NFTImageName.slice(0, 10)}</h1>
                                    <div className="brand">
                                        <span> <img src={user} className="profilebamds" /></span>
                                        <p>{props?.Item?.Address.slice(0, 7)}</p>
                                    </div>
                                </div>
                                <div className="time-stamp-container">
                                    {/* <div className="left">

                           <div className="left__date-month-wrapper">
                                <div className="left__date-month-wrapper-inner">
                                     <label>Nov</label>
                                     <h2>3-4</h2>
                                </div>
                                
                           </div>
                      </div> */}

                                    <div className="right">
                                        <div className="left__date-month-wrapper">
                                            <div className="left__date-month-wrapper-inner">
                                                <h2>{props?.Item?.startDateToken}-</h2>
                                                <h2>{props?.Item?.endDateToken}</h2>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="line"></div>
                            <div className="price-container">
                                <div className="left w-50">

                                    <div className="top">
                                        Quantity :{props?.Item?.tokenQuantity}

                                    </div>
                                    {/* <div className="bottom top">
          
            Quantity :{props?.Item?.tokenQuantity}
            

          <br />
        </div> */}
                                </div>
                                <div className="right w-50">
                                    <div className="top">
                                        Description
                                    </div>
                                    <div className="bottom text-right">
                                        <span className="primary-price">
                                            <span>

                                                {props?.Item?.tokenDesc.slice(0, 50)}

                                            </span>
                                        </span>


                                    </div>
                                </div>
                            </div>
                            {/* <div className="card-button-container">
                <div className="icon">
                  {props?.Item?.Coinname == 'ETH' ? <img src={ETH} /> :
                    <img src={polygon_violet_lighttheme} />}
                </div>
                <div className="button">
                  <Link to={{ pathname: `/info/${props?.Item?.ContractAddress}/${props?.Item?.Address}/${props?.Item?.tokenID}`, state: props?.Item, location: "collection" }} > <button>Buy Now </button></Link>
                </div>
              </div> */}
                            <div>
                                <div className="round_check lightcheck">
                                    <form>
                                        <input
                                            class="form-check-input" type="checkbox"
                                            checked={Wallet_Details?.VaultNFts?.some(it => it?.tokenID == props?.Item?.tokenID)}
                                        // checked={Wallet_Details?.MyItemNFTs?.some(it => it == NFTDetails._id)}
                                        //{NFTDetails?.check}
                                        />

                                    </form>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            }
        </>

    );
}

