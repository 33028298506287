import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import $ from 'jquery'
import prof from 'assets/images/small-profile.png'
import masonary1 from 'assets/images/masonary_04.png'

import emojiRegex from "emoji-regex";
import "react-toastify/dist/ReactToastify.css";
import { toast, Slide } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { UserValidation, ImageValidation } from "actions/controller/validation";
import config from 'lib/config'
import isEmpty from "lib/isEmpty";
import { EditProfiledata, GetProfiledata } from 'actions/controller/userControl'
import { v1_verifyuserMail } from "actions/v1/user";


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const EditProfCont = (props) => {
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const classes = useStyles();
  const history = useHistory()
  const initialForm = {
    Bio: "",
    CustomUrl: "",
    Twitter: "",
    Instagram: "",
    Facebook: "",
    Youtube: "",
    Email: "",
    Name: "",
    Site: "",
    PrimaryAddr: ""
  }
  const [Formdata, setFormdata] = useState({ initialForm })
  const [valError, setvalError] = useState({})
  const { ...rest } = props;
  const emoji = emojiRegex()
  const [UpdateState, setUpdateState] = useState(false)
  const [Preview, setPreview] = useState('')

  useEffect(() => {
    if (Wallet_Details.UserAccountAddr == "") {
      // toast.warning("Please Connect Your Wallet")
      setPreview(require("../assets/images/lazymintingnew/logodark.png"))
    }
    else
      GetProfile()
  }, [Wallet_Details.UserAccountAddr])

  const GetProfile = async () => {
    var Payload = { user: { curraddress: Wallet_Details.UserAccountAddr } }
    var Resp = await GetProfiledata(Payload)
    console.log("asdasdasasqwewqewqda", Resp);
    if (!isEmpty(Resp)) {
      setFormdata(Resp)
      if (Resp.photo != "")
        setPreview(`${config.Back_URL}/Users/${Resp.PrimaryAddr}/profileImg/${Resp.photo}`)
      else
        setPreview(require("../assets/images/lazymintingnew/logo.png"))
    }
  }

  const Handlefile = (e) => {
    if (e.target && e.target.files) {
      var file = e.target.files[0];
      var Resp = ImageValidation(file, "Edit")
      if (Resp.Success) {
        Formdata.File = file
        setPreview(URL.createObjectURL(file));
      }
      else
        toast.error(Resp.Message)
    }
  };

  const HandleChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...Formdata, ...{ [id]: value } }
    setFormdata(formData)
  }

  const Submit = async () => {
    setUpdateState(true)
    Formdata.curraddress = Formdata.PrimaryAddr
    var check = UserValidation(Formdata)
    if (check === true && Formdata.curaddress != "") {
      const id = toast.loading("Updating Data Please Wait")
      var resp = await EditProfiledata(Formdata)
      // console.log("Editprofiledetail",resp)
      if (resp.Success) {
        setTimeout(() => {
          toast.update(id, { render: resp.Message, type: "success", isLoading: false, autoClose: 2000 });
          history.push('/my-items')
        }, 500);
        setUpdateState(false)
        if (Formdata.Email) {
          if (resp.Mail) {
            toast.success(`A verification Mail will be sent to ${Formdata.Email}`, { transition: Slide, theme: "dark", autoClose: false, closeButton: true, position: "bottom-right" })
          }
          else {
            setTimeout(() => {
              history.push('/my-items')
            }, 2500)
          }
        }
      }
      else {
        setUpdateState(false)
        setTimeout(() => {
          toast.update(id, { render: resp.Message, type: "error", isLoading: false, autoClose: 2000 });
        }, 500);
        $('html, body').animate({ scrollTop: 0 }, 'slow');
      }
    }
    else {
      setUpdateState(false)
      setvalError(check)
      toast.error("Form validation failed", { autoClose: 2500 })
      $('html, body').animate({ scrollTop: 0 }, 'slow');
    }
  }

  const SendVeriMail = async (e) => {
    e.preventDefault()
    //alert("1")
    var Arg = {
      curraddress: Wallet_Details.UserAccountAddr,
      email: Formdata.Email
    }
    const mailid = toast.loading("Mail sending in Process..")
    var Resp = await v1_verifyuserMail(Arg)
    // console.log("bfkhbfdhgbdfbvhkdnf",Resp,Arg)
    if (Resp.Mail) {
      setTimeout(() => {
        toast.update(mailid, { render: `Verification Mail Sent to ${Formdata.Email}`, type: "success", isLoading: false, autoClose: 5000 });
      }, 500);
    }
    else {
      toast.error("Error occured, Try again sometime later", { autoClose: 5000, closeButton: false })
    }
  }

  return (
    <>

      <div className="container pb-5">
        <div className="row">
          <div className="col-12 mx-auto">
            <GridContainer>




              <GridItem xs={12} sm={12} md={3}>
                <div className="holder">
                  <img src={Preview} alt="logo" id="imgPreview" className="img-fluid lazy_logo_align" />
                </div>
                <div className="profile_edit_panel">
                  <div className="profile_edit_icon">
                    <i className="fas fa-pencil-alt"></i>
                  </div>
                  <input type="file" onChange={(e) => Handlefile(e)} name="photograph" id="photo" className="photo" />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={9}>
                <form>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label className="primary_label" htmlFor="name">Display Name</label>
                      <input type="text" onChange={(e) => { HandleChange(e) }} value={Formdata.Name} className="form-control primary_inp" id="Name" placeholder="Enter your display name" />
                      {valError !== true && valError.Name &&
                        <p className="Warning_label">{valError.Name}</p>
                      }
                    </div>
                    <div className="form-group col-md-12">
                      <label className="primary_label" htmlFor="name">Wallet Address</label>
                      <input type="text" disabled className="form-control primary_inp" id="name" defaultValue={Wallet_Details.UserAccountAddr} />
                    </div>
                    <div className="form-group col-md-12">
                      <label className="primary_label" htmlFor="name">Custom URL</label>
                      <input type="text" onChange={(e) => { HandleChange(e) }} value={Formdata.CustomUrl} className="form-control primary_inp" id="CustomUrl" placeholder="Ex. Ronaldo, @ronaldo" />
                      {valError !== true && valError.CustomUrl &&
                        <p className="Warning_label">{valError.CustomUrl}</p>
                      }
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-12 inputbtn">
                      <label className="primary_label" htmlFor="desccription">Email</label>
                      <input type="text" onChange={(e) => { HandleChange(e) }} value={Formdata.Email} className="form-control primary_inp" id="Email" placeholder="Your email for marketplace notifications" />
                      {/* {Formdata.verifiedmail !== "true" &&
                    <button onClick={(e)=>SendVeriMail(e)} className="create_btn">Verify</button>
                    } */}
                      {valError !== true && valError.Email &&
                        <p className="Warning_label">{valError.Email}</p>

                      }
                    </div>

                    <div className="form-group col-md-12">
                      <label className="primary_label" htmlFor="name">Bio</label>
                      <textarea onChange={(e) => { HandleChange(e) }} rows='5' value={Formdata.Bio} className="form-control primary_inp" id="Bio" placeholder="Tell about yourself in a few words"></textarea>
                    </div>
                    <div className="form-group col-md-12">
                      <label className="primary_label" htmlFor="desccription">Personal site or portfolio</label>
                      <input type="text" className="form-control primary_inp" onChange={(e) => { HandleChange(e) }} id="Site" value={Formdata.Site} placeholder="your own webite link" />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-12 mb-0">
                      <label className="primary_label" htmlFor="name">Social Media Links</label>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <input type="text" onChange={(e) => { HandleChange(e) }} value={Formdata.Twitter} className="form-control primary_inp" id="Twitter" placeholder="Twitter account link" />
                    </div>
                    <div className="form-group col-md-12">
                      <input type="text" onChange={(e) => { HandleChange(e) }} value={Formdata.Youtube} className="form-control primary_inp" id="Youtube" placeholder="Youtube channel link" />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <input type="text" onChange={(e) => { HandleChange(e) }} value={Formdata.Facebook} className="form-control primary_inp" id="Facebook" placeholder="Facebook account link" />
                    </div>
                    <div className="form-group col-md-12">
                      <input type="text" onChange={(e) => { HandleChange(e) }} value={Formdata.Instagram} className="form-control primary_inp" id="Instagram" placeholder="Instagram account link" />
                    </div>
                  </div>
                  <div className="mt-3">
                    <Button className="create_btn" disabled={UpdateState} onClick={() => { Submit() }}>
                      Update Profile
                    </Button>
                  </div>
                </form>
              </GridItem>
            </GridContainer>
          </div>
        </div>

      </div>

    </>
  )
}

export default EditProfCont

