//Calender Component
//Last Update : 24.01.2022
//Current Update : 24.01.2022
import Datetime from "react-datetime";
import moment from "moment";
import React, { useState, useRef } from "react";
import "react-datetime/css/react-datetime.css";
import $, { data } from "jquery";

export default function ExploreCard(props) {

  //Component Variable
  //Last Update : 24.01.2022
  //Current Update : 24.01.2022


  const { CalenderState, ParentCall } = props;
  const [AuctionStart, setAuctionStart] = useState(null);
  const [AuctionEnd, setAuctionEnd] = useState(null);
  var yesterday = moment().subtract(1, "day");

  var valid = function (current) {
    if (CalenderState === "Start" && AuctionEnd === null) 
    return current.isAfter(yesterday);
    else if(AuctionStart !== null)
    return current.isAfter(new Date(AuctionStart));
    else if(AuctionEnd !== null)
    return current.isAfter(yesterday) && current.isBefore(new Date(AuctionEnd));
    else
    return current.isAfter(moment());
  };

  const Auctionset = (date) => {
    var datesel = (new Date(date))
    if (CalenderState === "Start") {
    //  alert("start")
      setAuctionStart(date)
      ParentCall(datesel,CalenderState)
    } else {
     // alert('end')
      setAuctionEnd(date)
      ParentCall(datesel,CalenderState)
    }
  };

  return (
    <>
      {/* calendar Modal */}
      <div
        class="modal fade primary_modal"
        id="calendar_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="calendar_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="calendar_modalLabel">
                Choose date
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="pb-3">
                <Datetime
                  input={false}
                  isValidDate={valid}
                  timeFormat="HH:mm:ss"
                  timeConstraints={{
                    hours: { min: new Date(Date.now()).getHours(), max: 23 },
                  }}
                  onChange={(value) => {
                    Auctionset(value);
                  }}
                />
              </div>
              <div className="text-center">
                <button
                  className="create_btn"
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Done
                </button>
                </div>
            </div>
          </div>
        </div>
      </div>
      {/* end calendar modal */}
    </>
  );
}
