import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from "@material-ui/core";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link, useLocation } from "react-router-dom";
import Countdown from "react-countdown";
import Select from "react-select";
import Web3 from 'web3';
//Functionality Components
import MintingCard from "./separate/Minting";
import Calender from "../components/Calender/Calender";
import axios from "axios";
import $ from "jquery";
import { NFTValidation, ImageValidation } from "actions/controller/validation";
import configImp from "../lib/config";
import "react-toastify/dist/ReactToastify.css";
import { toast, Slide, Zoom, Flip, Bounce } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { Account_Connect, Account_disConnect } from "actions/redux/action";
import { connectWallet, WalletConnect } from '../hooks/useWallet';
import { network } from "./network"
import Abi721 from '../ABI/721.json'
import Abi1155 from '../ABI/1155.json'
import {
  WALLET_ADAPTERS,
  CHAIN_NAMESPACES,
  SafeEventEmitterProvider,
} from "@web3auth/base";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  getWhiteListUser, UserPackCheck, LMApproveCheck
} from '../actions/v1/token';
toast.configure();

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function CreateSingle(props) {
  //Internal Functions
  //Last Update : 24.01.2022
  //Current Update : 24.01.2022
  //Functions : BNBprice, OnChangehandle, PriceCalculate
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { ...rest } = props;

  const options = [
    { value: "Art", label: "Art" },
    { value: "Collectibles", label: "Collectibles" },
    { value: "Domain Names", label: "Domain Names" },
    { value: "Music", label: "Music" },
    { value: "Photography", label: "Photography" },
    { value: "Sports", label: "Sports" },
    { value: "Trading Cards", label: "Trading Cards" },
    { value: "Utility", label: "Utility" },
    { value: "Virtual Worlds", label: "Virtual Worlds" },
  ];
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  console.log("Wallet_DetailsWallet_Details", Wallet_Details);
  const CreatePage = useLocation();
  const CreateMultiple = CreatePage.pathname === "/create-single";
  const InitialForm = {
    name: "",
    description: "",
    quantity: 1,
    properties: "",
    royalties: "",
    curraddress: localStorage.userAddress,
    type: CreateMultiple ? 721 : 1155,
    contractaddress: '',
    NFTCategory: "",
    TokenPrice: "0",
    Date: '',
    Location: '',
    Section: '',
    NetworkName: '',
    check: '',
    Standard: '',
    TokenGat: '',
    TokenGating: '',
    Fiat: false,
    CharityDetails: [],
    Charity: false,
    BuyType: "Crypto"
  };
  const [Formdata, setFormdata] = useState(InitialForm);
  const [PutonSale, setPutonSale] = useState(false);
  const [FixedPrice, setFixedPrice] = useState(false);
  const [Auction, setAuction] = useState(false);
  const [Unlockable, setUnlockable] = useState(false);
  const [USDPrice, setUSDPrice] = useState(0);
  const [TokenPrice, setTokenPrice] = useState(0);
  const [USDTokenPrice, setUSDTokenPrice] = useState(0);
  const [AucCalendar, setAucCalender] = useState("Start");
  const [PicStartselected, setPicStartselected] = useState(false);
  const [AuctionStart, setAuctionStart] = useState(null);
  const [AuctionEnd, setAuctionEnd] = useState(null);
  const [NFTFile, setNFTFile] = useState(null);
  const [NFTFilename, setNFTFilename] = useState(null);
  const [Coinname, setCoinname] = useState('')
  const [config, setconfig] = React.useState(InitialForm);
  const [categorysate, Setcategorysate] = useState({})
  const [Preview, setPreview] = useState(
    require("../assets/images/lazymintingnew/logo.png")
  );
  const [Standard, setselectStandard] = useState("")
  const [networkoption, setnetworkoption] = React.useState([]);
  const [CategoryOption, setCategoryOption] = useState([{ value: 'ERC721', label: 'ERC721' }, { value: 'Bulkminting', label: 'Bulkminting' },
  { value: 'Fractional NFT', label: 'Fractional NFT' }
  ]);
  const [put, setPut] = useState("");
  const [category, Setcategory] = useState("")
  const [ValidationError, setValidationError] = useState(null)
  const [userdet, setUser] = useState();
  const [MinUSD, setMinUSD] = useState(0);
  const [approvecheck, setapprovecheck] = useState(false)
  const [TokenGatingTog, setTokenGating] = useState(false);
  const [Charity, setCharity] = useState(false);
  const [Fiat, setFiat] = useState(false);
  const handleChange = () => {
    // this.setState({ selectedOption });
    // console.log(`Option selected:`, selectedOption);
  };
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "#fff",
      position: "relative",
      top: "3px",
      left: "0px",
      width: "100%",
      maxWidth: "100%",
      color: "#000"
    }),
    option: (provided, state) => ({
      ...provided,
      marginBottom: "5px",
      backgroundColor: "transparent",
      maxWidth: "356px",
      color: "#fff",
      borderBottom: "#fff",
      "&:hover": {
        transition: "all 0.5s ease",
        color: "#e50914"
      },
      "&:active": {
        transition: "all 0.5s ease",
        color: "#000"
      }
    }),
    control: (provided, state) => ({
      ...provided,
      border: "1px solid transparent",
      boxShadow: "0px 0px",
      "&:hover": {
        border: "1px solid transparent",
        boxShadow: "0px 0px",
      },
      "&:focus": {
        border: "1px solid transparent"
      }
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      border: "1px solid transparent"
    })
  }
  const renderer = ({
    days,
    Month,
    Year,
    hours,
    minutes,
    seconds,
    completed,
  }) => {
    if (completed) {
      return <span></span>;
    } else {
      return (
        <span>
          {days}d {hours}h {minutes}m {seconds}s left
        </span>
      );
    }
  };

  useEffect(() => {
    BNBprice();
  }, [Wallet_Details]);

  useEffect(() => {
    // console.log("rtetyrrrrewyrteyt", Wallet_Details?.Category);
    if (Wallet_Details?.Category) {
      var Categorydata = Wallet_Details?.Category.filter(Cat => (Cat.BulkStatus !== 'true'))
      // console.log("categoryydatatatata", Categorydata);
      if (Categorydata) {
        Setcategorysate(Categorydata)
      }
    }

    if (Wallet_Details && Wallet_Details.networkoption.length > 0) {
      // console.log("skdvnskdnvjksdnvksdn",Wallet_Details.networkoption);
      setnetworkoption(Wallet_Details.networkoption)
    }
    console.log("sfdweffdfsf", CreateMultiple, userdet);
    //userdet && userdet.length > 0 && userdet[0].Address ==
    if (CreateMultiple) {
      if (Wallet_Details.UserAccountAddr) {
        setCategoryOption([{ value: 'ERC721', label: 'ERC721' }
          ,
        { value: 'Fractional NFT', label: 'Fractional NFT' }
        ])
      } else {
        // setCategoryOption([{ value: 'ERC721', label: 'ERC721' }])
        setCategoryOption([{ value: 'ERC721', label: 'ERC721' },
        { value: 'Fractional NFT', label: 'Fractional NFT' }
        ])
      }
    } else {
      if (Wallet_Details.UserAccountAddr) {
        setCategoryOption([{ value: 'ERC1155', label: 'ERC1155' }, { value: 'Bulkminting', label: 'Bulkminting' }])
      } else {
        // setCategoryOption([{ value: 'ERC1155', label: 'ERC1155' }])
        setCategoryOption([{ value: 'ERC1155', label: 'ERC1155' }, { value: 'Bulkminting', label: 'Bulkminting' }])
      }
    }


  }, [Wallet_Details?.Category]);
  useEffect(() => {
    getWhiteList();
  }, [Wallet_Details.UserAccountAddr])
  const getWhiteList = async () => {
    var reqbody = {
      Address: Wallet_Details.UserAccountAddr
    }
    var test = await getWhiteListUser(reqbody);
    // console.log("mlsvkdnvdfkmnvd", test);
    if (test.Success) setUser(test.userValue);
  };



  const BNBprice = () => {
    var varname = Wallet_Details.networkConfiguration.currencySymbol
    // console.log("asdasdasdzxczxczxczasdasd",varname);
    axios
      .get(
        `https://min-api.cryptocompare.com/data/price?fsym=${varname}&tsyms=USD`
      )
      .then((result) => {
        // console.log("Api Success", result,Coinname);
        if (result.data && result.data.USD && result.data.USD) {
          var Price = result.data.USD;
          setUSDPrice(Number(Price));
          var minusd = 0.0002 * Number(Price)
          setMinUSD(minusd)
        }
      })
      .catch((error) => {
        console.log("Api Overloaded", error);
      });
  };


  const OnChangehandle = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let Form = Formdata;
    Form = { ...Form, ...{ [id]: value } };

    setFormdata(Form);

  };

  const PriceCalculate = (e) => {
    e.preventDefault();
    const { value, id } = e.target;
    console.log("value,USDPrice", value, USDPrice, (1 * value) / USDPrice)
    if (id === "USDTokenPrice") {
      var tokPrice = (1 * value) / USDPrice;
      console.log("sdfsd", tokPrice, (tokPrice).toFixed(3), String((tokPrice).toFixed(3)))
      setUSDTokenPrice(value)
      var Temp = Number(tokPrice).toFixed(4)
      setTokenPrice(String(Temp));
      Formdata.TokenPrice = String((tokPrice).toFixed(3));
      var check = ["AuctionStart", "AuctionEnd", "MinimumBid"];
      check.forEach((e) => (e in Formdata ? delete Formdata[e] : false));
    } else if (id === "MinimumBid") {
      Formdata.MinimumBid = String(value);
      var check = ["TokenPrice"];
      check.forEach((e) => (e in Formdata ? delete Formdata[e] : false));
    } else if (id === "TokenPrice") {
      setTokenPrice(Number(value))
      console.log("token price id value", value, USDPrice)
      Formdata.TokenPrice = Number(value);
      var tokPrice = value * USDPrice;
      setUSDTokenPrice(tokPrice)
    }
  };

  const ChildReply = (ChildData, Calstate) => {
    console.log("sdnfjkadnfka", ChildData, Calstate);
    if (Calstate === "Start") {
      setAuctionStart(ChildData)
      Formdata.AuctionStart = ChildData;
    }
    else {
      setAuctionEnd(ChildData);
      Formdata.AuctionEnd = ChildData;
    }
  };

  const CalAction = (a, day) => {
    var myDate = new Date();
    var newdat = myDate.setDate(myDate.getDate() + parseInt(day));
    var newdate = new Date(newdat)
    if (a === "now") {
      setAuctionStart(newdate);
      Formdata.AuctionStart = newdate;
    } else {
      setAuctionEnd(newdate);
      Formdata.AuctionEnd = newdate;
    }
  };

  const Handlefile = (e) => {
    if (e.target && e.target.files) {
      console.log("dnjcsdanjkda", e.target.files[0]);
      var file = e.target.files[0];
      var fileName = file.name;


      var Resp = ImageValidation(file)
      if (Resp.Success) {
        setNFTFilename(fileName);
        Formdata.ImageName = fileName;
        setNFTFile(file);
        setPreview(URL.createObjectURL(file));
      }
      else
        toast.error(Resp.Message)
    }
  };

  const Validate = async () => {

    console.log("qzxcqwewqzxcxzc", Wallet_Details, localStorage.userAddress, Wallet_Details.UserAccountAddr);
    if (!localStorage.userAddress || localStorage.userAddress !== Wallet_Details.UserAccountAddr) {
      window.$("#triggerlogin").click();
      window.$("div").removeClass('modal-backdrop fade show')

      return toast.error("Login with correct Wallet Address and credentials")

    }
    var SendDatass = {
      Address: Wallet_Details.UserAccountAddr
    }
    var createLimit = await UserPackCheck(SendDatass)
    console.log("asdasdasdasd", createLimit, createLimit.data.data.Message);
    if (createLimit.data.data.Message == "success") {
      console.log("asdsaddasdasd", createLimit.data.data.Message);
      var SingleLimit = createLimit?.data?.data?.UserBalance?.SingleCount
      var MultipleLimit = createLimit?.data?.data?.UserBalance?.MultipleCount
      var checkverify = CreateMultiple ? SingleLimit : MultipleLimit
      console.log("asdssadasdasdasd", checkverify, typeof (checkverify));
      if (Number(checkverify) !== 0) {
        if (Number(checkverify) < 1) {
          console.log("asdasdasdasdadsas", checkverify);
          return toast.error("Minting Limit is Low.Please purchase pack")
        }
      } else {
        var mintbal = createLimit?.data?.data?.UserBalance
        if (mintbal.PurchaseDetails.length > 0) {

          var EmptyArr = []

          var mapdata = mintbal.PurchaseDetails.map(async (item, index) => {
            console.log("qewweqwewqeqweqwe", item.CommonCount);

            EmptyArr.push(Number(item.CommonCount))


          })
          if (EmptyArr.length > 0) {
            var sum = Object.values(EmptyArr).reduce((a, b) => a + b, 0)
            console.log("EmptyArrEmptyArr", sum, typeof (sum));
            if (sum == 0) {
              return toast.error("Minting Limit is Low.Please purchase pack")
            }
          }
        } else {
          return toast.error("Purchase pack to Mint")
        }
        // if (mintbal < 1) {
        //   return toast.error("Minting Limit is Low.Please purchase pack")
        // }
      }
    } else {
      return toast.error("purchase plan to mint")
    }
    console.log("Wallet_Details.UserAccountAddr",Wallet_Details.UserAccountAddr);
    var approvedata = {
      curradd: Wallet_Details.UserAccountAddr,
      tradeadd: Wallet_Details.networkConfiguration.TradeContract
    }
    var check = await orderApprovecheck(approvedata);
    console.log("dnfksdjnfkjsdnfk", check);

    setapprovecheck(check)

    Formdata.check = check
    const id2 = toast.loading("Validating Details", { transition: Slide, theme: "dark" });
    var SaleType = (PutonSale) ? (FixedPrice) ? "Fixed" : (Auction) ? "Auction" : "Unlimited" : PutonSale
    Formdata.File = NFTFile
    Formdata.Standard = CreateMultiple ? "ERC721" : "ERC1155"
    Formdata.TokenGat = TokenGatingTog
    Formdata.Charity = Charity
    Formdata.TokenPrice = Fiat || PutonSale ? Formdata.TokenPrice : "0";
    Formdata.coin
    Formdata.CharityDetails = Charity ? inputList : []
    Formdata.Fiat = Fiat
    Formdata.BuyType = Fiat ? SaleType = "Fiat" : "Crypto"
    console.log("validate", Formdata, inputList, Fiat)
    var result = await NFTValidation(Formdata, PutonSale, SaleType, Formdata.BuyType)
    console.log("qweertrtrtrrtr", result);
    // console.log("qwerrttttttwerweiofjowd", PutonSale);
    Formdata.PutonSale = PutonSale
    Formdata.SaleType = SaleType
    // console.log("m vmksfvkdsfkm",Coinname);
    Formdata.Coinname = Fiat ? SaleType = "USD" : Coinname

    console.log("nsfgkfngkfsj", Formdata);
    Formdata.contractaddress = CreateMultiple ? Wallet_Details.networkConfiguration.singleContract : Wallet_Details.networkConfiguration.multipleContract
    setTimeout(() => {
      if (result.Success) {
        toast.update(id2, { render: "Validation Success", type: "success", isLoading: false, autoClose: 2500 });
        // console.log("resultttttt errorrr validaterrrrrr", result);
        setValidationError(result)
        $('#MintcardClick').trigger("click");
      }
      else {
        toast.update(id2, { render: "Validation Failed", type: "error", isLoading: false, autoClose: 2500 });
        setValidationError(result)
      }
    }, 1000);
  }

  const selectChange = async (e) => {

    // console.log("sdgsdrh", e.value, e.name);
    // console.log("fjfdngnfdgndfkg",put);
    Formdata.NetworkName = e.value == configImp.Network_ETH ? Number(configImp.ETH_ID) :  e.value == configImp.Network_CAM ? Number(configImp.CAM_ID) : Number(configImp.MATIC_ID)
    // Formdata.Coinname = e.value == "binance" ? "ETH" : "MATIC"
    var coinnn = e.value == configImp.Network_ETH ? configImp.Currency_ETH : e.value == configImp.Network_CAM ? configImp.Currency_CAM : configImp.Currency_MATIC
    setCoinname(coinnn)
    if (e.name == "networkname") {
      if (e.name == "networkname" && e.value == configImp.Network_ETH) {
        console.log("comming on binance");
        var configdata = network.ETH
        Wallet_Details.networkConfiguration = network.ETH
        var accountDetails = await switchNetwork(configdata, Number(configImp.ETH_ID))
        setconfig(configdata)
      }
      else if (e.name == "networkname" && e.value == configImp.Network_MAT) {
        var configdata = network.MATIC
        Wallet_Details.networkConfiguration = network.MATIC
        var accountDetails = await switchNetwork(configdata, Number(configImp.MATIC_ID))
        setconfig(configdata)
      }
      else if (e.name == "networkname" && e.value == configImp.Network_CAM) {
        console.log("e.value == configImp.Network_CAM",e.value , configImp.Network_CAM);
        var configdata = network.CAM
        Wallet_Details.networkConfiguration = network.CAM
        var accountDetails = await switchNetwork(configdata, Number(configImp.Network_CAM))
        setconfig(configdata)
      }

    }
    // else{
    //   toast.warning("please connect your wallet")
    //  }

  }
  const selectStandard = async (e) => {
    console.log("sdgsdrh Dropdown for selectStandard", e.value);
    if (e.value == "Bulkminting") {
      history.push('/Bulkminting')
    } else if (e.value == "Fractional NFT") {
      history.push('/FractionalNFT')
    }
    setselectStandard(e.value)
  }

  async function switchNetwork(configdata, networkNUM) {
    var type = ""
    var networkConfiguration = {}
    const chainId = await Wallet_Details.Web3Pro.eth.getChainId();
    console.log("adsadasdasd", configdata.Chainid, chainId);
    console.log("dfadsaddasdadas", Wallet_Details, localStorage.walletConnectType);
    // if(configdata ==)
    if (localStorage.walletConnectType !== "Torus") {
      if (Number(configdata.Chainid) !== Number(chainId)) {
        const id = toast.loading("Switching Network", { closeButton: true, theme: "dark" });
        if (configdata) {
          if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask') {

            type = "MetaMask"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'WalletConnect' && localStorage.walletConnectType != null) {
            type = "WalletConnect"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'Venly' && localStorage.walletConnectType != null) {
            type = "Venly"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'Torus' && localStorage.walletConnectType != null) {
            console.log("adasd comming on torus");
            type = "Torus"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'TorusWallet' && localStorage.walletConnectType != null) {
            console.log("adasd comming on torus");
            type = "TorusWallet"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'LMWallet' && localStorage.walletConnectType != null) {
            console.log("adasd comming on torus");
            type = "LMWallet"
            localStorage.setItem("ChainId", networkNUM)
          }
          // console.log("dthstrhrtjhsrt",type,config.Chainid);

          //     var accountDetails = await connectWallet(type,config.Chainid)

          // console.log("accountDetailscreateeeeeeeeeee",accountDetails);

          window.$('#connect_modal').modal('hide');
          console.log("connecttype............", type, configdata.Chainid, networkNUM, localStorage.walletConnectType)

          var accountDetails = await connectWallet(localStorage.walletConnectType, configdata.Chainid, "Create", Wallet_Details, "Tor")
          console.log("accountDetailsin create page......................", accountDetails)

          const chainId = await accountDetails?.web3?.eth?.getChainId();
          console.log("fghdtgj", configdata, network, chainId);

          if (configdata.Chainid === network.MATIC.Chainid) {
            networkConfiguration = network.MATIC
          }
          else if (configdata.Chainid === network.ETH.Chainid) {
            networkConfiguration = network.ETH
          }
          else if (configdata.Chainid === network.CAM.Chainid) {
            networkConfiguration = network.CAM
          }
          // console.log("asdasdazxczxsdasdadas",networkConfiguration);

          // }
          //setPriceoption()

          if (accountDetails != '') {
            dispatch({
              type: Account_Connect,
              Account_Detail: {
                UserAccountAddr: accountDetails?.accountAddress,
                UserAccountBal: accountDetails?.coinBalance,
                WalletConnected: "true",

                Wen_Bln: accountDetails?.tokenBalance,
                Accounts: accountDetails?.accountAddress,

                Web3Pro: accountDetails?.web3,
                providerss: accountDetails?.web3?._provider,
                networkConfiguration: networkConfiguration
              }
            })
          }
          // else{
          //   toast.warning("please connect your wallet")
          // }
        }
        if (networkConfiguration.currencySymbol) {
          toast.update(id, {
            render: "Network switched Succesfully",
            type: "success",
            isLoading: false,
            autoClose: 2500,
            closeButton: true,
            theme: "dark"
          });
        }



        //   var ReqData = {
        //     addr: String(accountDetails.accountAddress).toLowerCase()
        // }
        // var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
        // if (Resp && Resp.data && Resp.data.data && Resp.data.data.User) {
        //     return Resp.data.data.User
        // } else {
        //     return null
        // }
        return accountDetails

      }
      else {
        dispatch({
          type: Account_Connect,
          Account_Detail: {
            UserAccountAddr: Wallet_Details?.UserAccountAddr,
            UserAccountBal: Wallet_Details?.UserAccountBal,
            WalletConnected: "true",

            Wen_Bln: Wallet_Details?.Wen_Bln,
            Accounts: Wallet_Details?.Accounts,

            Web3Pro: Wallet_Details?.Web3Pro,
            providerss: Wallet_Details?.providerss,
            networkConfiguration: configdata
          }
        })
        return Wallet_Details.Web3Pro
      }
    } else {
      try {
        const id = toast.loading("Switching Network", { closeButton: true, theme: "dark" });
        var accountDetails = await connectWallet(localStorage.walletConnectType, configdata.Chainid, "Create", Wallet_Details, "Torus")
        // if (Number(networkNUM) == 97) {
        //   console.log("Comming on last else for switchCahin", Wallet_Details.web3auth, Wallet_Details?.web3);
        //   var addingChain = await Wallet_Details.web3auth.addChain({
        //     chainId: "0x61",
        //     displayName: "binance",
        //     chainNamespace: CHAIN_NAMESPACES.EIP155,
        //     tickerName: "BNB Smart Chain Testnet",
        //     ticker: "BNB",
        //     decimals: 18,
        //     rpcTarget: "https://data-seed-prebsc-1-s1.binance.org:8545/",
        //     blockExplorer: "https://testnet.bscscan.com/",
        //   });
        //   console.log("addingChain Web3Auth", addingChain);
        //   var switched = await Wallet_Details.web3auth.switchChain({ chainId: "0x61" });
        //   console.log("switched in create page uh", switched);
        // }else{
        //   console.log("Comming on last else for switchCahin", Wallet_Details.web3auth, Wallet_Details?.web3);
        //   var addingChain = await Wallet_Details.web3auth.addChain({
        //     chainId: "0x13881",
        //     displayName: "Mumbai",
        //     chainNamespace: CHAETHIN_NAMESPACES.EIP155,
        //     tickerName: "Mumbai",
        //     ticker: "MATIC",
        //     decimals: 18,
        //     rpcTarget: "https://matic-mumbai.chainstacklabs.com",
        //     blockExplorer: "https://mumbai.polygonscan.com/",
        //   });
        //   console.log("addingChain Web3Auth", addingChain);
        //   var switched = await Wallet_Details.web3auth.switchChain({ chainId: "0x13881" });
        //   console.log("switched in create page uh", switched);
        // }
        console.log("asdasdasdasdas", accountDetails);
        const chainId = await accountDetails?.web3?.eth?.getChainId();
        console.log("fghdtgj", configdata, network, chainId);

        if (configdata.Chainid === network.MATIC.Chainid) {
          networkConfiguration = network.MATIC
        }
        else if (configdata.Chainid === network.ETH.Chainid) {
          networkConfiguration = network.ETH
        }
        else if (configdata.Chainid === network.CAM.Chainid) {
          networkConfiguration = network.CAM
        }
        if (accountDetails != '') {
          dispatch({
            type: Account_Connect,
            Account_Detail: {
              UserAccountAddr: accountDetails?.accountAddress,
              UserAccountBal: accountDetails?.coinBalance,
              WalletConnected: "true",

              Wen_Bln: accountDetails?.tokenBalance,
              Accounts: accountDetails?.accountAddress,

              Web3Pro: accountDetails?.web3,
              providerss: accountDetails?.web3?._provider,
              networkConfiguration: networkConfiguration
            }
          })
        }
        var functiobn = await NefunctionTriger(Wallet_Details)

        if (networkConfiguration.currencySymbol) {
          toast.update(id, {
            render: "Network switched Succesfully",
            type: "success",
            isLoading: false,
            autoClose: 2500,
            closeButton: true,
            theme: "dark"
          });
        }
      } catch (e) {
        console.log("adqeqweqweqweqweqweqwe", e);
      }
    }



  }
  const NefunctionTriger = async (Wallet_Details1) => {
    console.log("Wallet_Details1", Wallet_Details1);
    const chainIdSwitched = await Wallet_Details1?.Web3Pro?.eth?.getChainId();
    var balance = await Wallet_Details1?.Web3Pro?.eth.getBalance(Wallet_Details1.UserAccountAddr);
    console.log("chainIdSwitched", chainIdSwitched, balance);
    return chainIdSwitched
  }
  async function orderApprovecheck(item) {
    try {
      console.log("dcasdsaadadasdas");
      if (localStorage.walletConnectType !== "LMWallet") {
        if (Wallet_Details.providerss == null) {
          toast.warning("OOPS!..connect Your Wallet");
          return false;
        }
        // console.log("adndakmnka",CreateMultiple);
        var web3 = new Web3(Wallet_Details.Web3Pro);
        var MultiContract = new web3.eth.Contract(
          (CreateMultiple ? Abi721 : Abi1155),
          CreateMultiple ? Wallet_Details.networkConfiguration.singleContract : Wallet_Details.networkConfiguration.multipleContract
        )
        var status = await MultiContract.methods.isApprovedForAll(
          Wallet_Details.UserAccountAddr,
          Wallet_Details.networkConfiguration.TradeContract
        ).call();
        // console.log("OrderApprove Check", status);
        return status
      } else {
        console.log("Comming on elsee bavkend calll", Formdata.NetworkName);
        var Arg = {
          Method: "isApprovedForAll",
          Data: [Wallet_Details.UserAccountAddr, Wallet_Details.networkConfiguration.TradeContract],
          Mail: localStorage.loggedinaddress,
          LoginAddress: localStorage.userAddress,
          ContactAddress: CreateMultiple ? Wallet_Details.networkConfiguration.singleContract : Wallet_Details.networkConfiguration.multipleContract,
          Type: CreateMultiple ? "721" : "1155",
          Chain: Formdata.NetworkName,
          IntractContract: CreateMultiple ? Wallet_Details.networkConfiguration.singleContract : Wallet_Details.networkConfiguration.multipleContract,
          ContactType: CreateMultiple ? "Single" : "Multiple",
          Key: null,
        }
        console.log("ArgArgArg", Arg);
        var Balance = await LMApproveCheck(Arg)
        console.log("Balance", Balance);
        return Balance.data.receipt
      }
    }
    catch (e) {
      console.log("OrderApprove Check", e);

      return false

    }

  }

  const [inputList, setInputList] = useState([{ Address: "", Share: "", Royality: 1 }]);
  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value.toLowerCase();
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    console.log("adsadadqwweqwe", index);
    const list = [...inputList];
    console.log("dakmfklsmflksd", list);
    const remove = list.filter((item, indexFilter) => !(indexFilter === index));
    console.log("zxmcdscsldfsfa", remove);
    // list.splice(index, 1);
    setInputList(remove);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    if (inputList.length > 4) {
      toast.error("Maximum 5 Users Only")
    } else {
      setInputList([...inputList, { Address: "", Share: "", Royality: 1 }]);
    }
  };


  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };

  return (
    <div className="home_header create_single_mulitple">
      <Button className="
      d-none" data-toggle="modal" data-target="#register_modal" id="triggerlogin" ></Button>

      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={
          <Link to="/home">
            <div className="header_logo_align">


            </div>
          </Link>
        }
        leftLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 20,
          color: "white",
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader inner_pagh_bg_2"}>

        <div className={classes.container}>

          <GridContainer>

            <GridItem xs={12} sm={12} md={12}>
              {CreateMultiple ? (
                <p class="heading_big_blk p-b-sec"> <span><Link to="/create" className="mr-3"><i class="fas fa-long-arrow-alt-left" /></Link></span>Create Single Collectible</p>
              ) : (
                <p class="heading_big_blk p-b-sec"><span><Link to="/create" className="mr-3"><i class="fas fa-long-arrow-alt-left" /></Link></span>Create Multiple Collectible</p>
              )}
            </GridItem>

          </GridContainer>
        </div>

        <div className="container">
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <label className="heading_sm_blk_new">Preview</label>
              <div className="single_collectible masonry mx-0">
                <div className="item">
                  <div className="card_main_iner">
                    <div className="card_inner_item pos-reltv">
                      <div className="item_inner_img">
                        <div className="like_div_round">
                          <i className="far fa-heart liked"></i>
                        </div>
                        {NFTFilename &&
                          NFTFilename.split(".").pop() == "mp4" ? (
                          <video
                            src={Preview}
                            alt="collections"
                            autoPlay={true}
                            muted={true}
                            controls disablePictureInPicture controlsList="nodownload"
                          />
                        ) : (
                          <img
                            src={Preview}
                            alt="Collections"
                            className="img-fluid lazy_logo_align"
                          />
                        )}
                      </div>
                      <div className="img_desc_nft px-3 py-3">
                        <img
                          src={require("../assets/images/lazymintingnew/footer_logo.png")}
                          alt="User"
                          className="img-fluid profiel_img_pos "
                        />
                        <img
                          src={require("../assets/images/star.png")}
                          alt="Collections"
                          className="img-fluid star_img_blue"
                        />

                        <div className="d-flex justify-content-between mt-2">
                          <h2>
                            {Formdata.name == "" ? "TokenName" : (Formdata.name).toString().slice(0, 20) + "..."}
                          </h2>
                          <div>
                            <h3 className="my-2">
                              {FixedPrice ?
                                <span>{Number(TokenPrice).toFixed(4)} {config.currencySymbol}</span>
                                :
                                <badge className="badge badge-dark badge-timer mb-1 make_offer_badge"> Make offer</badge>
                              }
                            </h3>
                          </div>
                          {/* <div className="d-flex creators_details">
                                      <img src={require("../assets/images/small-profile.png")} alt="User" className="img-fluid align-self-center" />
                                      <img src={require("../assets/images/small-profile.png")} alt="User" className="img-fluid align-self-center" />
                                      <img src={require("../assets/images/small-profile.png")} alt="User" className="img-fluid align-self-center" />
                                      </div> */}
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                          {/* <div>
                            <p className="like_count">0 likes</p>
                          </div> */}
                          {Auction && AuctionEnd && (
                            <div className="timeleft">
                              <span>
                                {" "}
                                <i class="far fa-clock mr-1"></i>
                                {/* {new Date(new Date(AuctionEnd).toISOString()).getTime()} */}
                              </span>
                              <span>
                                <Countdown
                                  date={new Date(AuctionEnd).getTime()}
                                  renderer={renderer}
                                ></Countdown>
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={8}>
              <form className="formCls pb-5">
                <div className="form-row">
                  <div className="form-group col-md-12 p-0">

                    <label className="primary_label" htmlFor="category">
                      Select Standard<span className="text-danger">*</span>
                    </label>
                    {/* { Wallet_Details?.Category.filter(items => (items.label != "Ticket"))} */}
                    {/* {console.log("qweqweqweqwwe", categorysate)} */}
                    <Select
                      className="yes1 form-control primary_inp select1 selxet_app"
                      onChange={(e) => selectStandard(e)}
                      options={CategoryOption}
                      label="Single select"
                      isSearchable={false}
                      styles={customStyles}

                      classNamePrefix="react-select"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {ValidationError && ValidationError.Standard &&
                      <p className="Warning_label">{ValidationError && ValidationError.Standard}</p>
                    }
                  </div>
                  <div className="form-group col-md-12">
                    <div className="d-flex justify-content-between align-items-start">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">
                          Upload file
                        </label>
                        <p className="form_note">
                          PNG, GIF, WEBP or MP4. Max 30mb.
                        </p>
                        {ValidationError && ValidationError.File &&
                          <p className="Warning_label">{ValidationError && ValidationError.File}</p>
                        }
                      </div>
                      <div className="file_btn btn ">
                        {NFTFile == null ? "Upload" : "Uploaded"}
                        <input
                          className="inp_file"
                          type="file"
                          name="file"
                          autoComplete="off"
                          onChange={(e) => Handlefile(e)}
                        />
                      </div>

                    </div>
                  </div>
                </div>
                <div className="form-group col-md-12 p-0">

                  <label className="primary_label" htmlFor="category">
                    Select Network<span className="text-danger">*</span>
                  </label>
                  {/* { Wallet_Details?.Category.filter(items => (items.label != "Ticket"))} */}
                  {/* {console.log("qweqweqweqwwe", categorysate)} */}
                  <Select
                    className="yes1 form-control primary_inp select1 selxet_app"
                    onChange={(e) => selectChange(e)}
                    options={networkoption}
                    label="Single select"
                    isSearchable={false}
                    styles={customStyles}

                    classNamePrefix="react-select"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                  {ValidationError && ValidationError.NetworkName &&
                    <p className="Warning_label">{ValidationError && ValidationError.NetworkName}</p>
                  }
                </div>
                {!PutonSale &&
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <div className="d-flex justify-content-between align-items-start grid_toggle">
                        <div>
                          <label className="primary_label" htmlFor="inputEmail4">
                            Fiat
                          </label>
                          <p className="form_note">
                            You’ll receive Fiat Currency on this item
                          </p>
                        </div>
                        <label className="switch toggle_custom">
                          <input
                            type="checkbox"
                            onChange={() =>
                              setFiat((prevState) => !prevState)
                            }
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      {Fiat && (

                        <div className="row mx-0 mt-3 fixed_price_sec">
                          <label className="primary_label" htmlFor="price_new">
                            Price<span className="text-danger">*</span>
                          </label>
                          <div className="form-row w-100">
                            <div className="form-group col-md-6">
                              <div className="input-group input_grp_style_1 min_h_45">
                                <input
                                  type="number"
                                  id="TokenPrice"
                                  className="form-control"
                                  onChange={(e) => PriceCalculate(e)}
                                  // value={(Number(TokenPrice))}
                                  placeholder="Equivalent Value of USD"
                                  aria-label="TokenPrice"
                                  aria-describedby="basic-addon2"
                                  onKeyDown={handleKeyDown}
                                  onWheel={(e) => e.target.blur()}
                                />
                                <div className="input-group-append">
                                  <span
                                    className="input-group-text pl-0 min_h_45_px tokenpeorcs"
                                    id="basic-addon2"
                                  >

                                  </span>

                                </div>
                              </div>
                              {ValidationError && ValidationError.TokenPrice &&
                                <p className="Warning_label">{ValidationError && ValidationError.TokenPrice}</p>
                              }
                            </div>
                          </div>
                          {/* <div class="input-group mb-1">
               <input type="text" id="price_new" class="form-control selct_form_input_h" placeholder="Enter price for one piece" aria-label="Recipient's username" aria-describedby="basic-addon2" />
               <div class="input-group-append">
               <Select
                       className="yes1 form-control primary_inp select1 selxet_app"

                       id="tokenCategory"
                       name="tokenCategory"
                       onChange={handleChange}
                       options={options}
                       label="Single select"
                       formControlProps={{
                         fullWidth: true
                       }}
                     />
               </div>

             </div> */}
                          {console.log("afdasdasdasdas", Wallet_Details)}
                          <p className="form_note">
                            Service fee{" "}
                            <span className="font_we_700_note_txt">2.5%</span>
                            {/* <br /> You will receive{" "}
                            <span className="font_we_700_note_txt">0B OPEN</span> */}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                }
                {!Fiat &&
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <div className="d-flex justify-content-between align-items-start grid_toggle">
                        <div>
                          <label className="primary_label" htmlFor="inputEmail4">
                            Put on Sale
                          </label>
                          <p className="form_note">
                            You’ll receive bids on this item
                          </p>
                        </div>
                        <label className="switch toggle_custom">
                          <input
                            type="checkbox"
                            onChange={() => {
                              setPutonSale((prevState) => !prevState);
                              setFixedPrice(false);
                              setAuction(false);
                            }}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      {PutonSale && (
                        <div className="connect_row mt-3 putonsale_sec connect_row_blk">
                          {/* <div className="mb-3"> */}
                          <div
                            className={put == "fixed_price" ? "create_box create_sing_bx card_user_bg_1 active" : "create_box create_sing_bx card_user_bg_1"}
                            onClick={() =>
                              setFixedPrice(
                                (prevState) => !prevState,
                                setAuction(false),
                                // setCoinname(Wallet_Details.networkConfiguration.currencySymbol),
                                setPut("fixed_price")
                              )
                            }
                          >
                            {/* <i class="fas fa-tag"></i> */}
                            <img
                              src={require("../assets/images/price_svg.svg").default}
                              alt="Fixed Price"
                              className="img-fluid"
                            />

                            <p>Fixed Price</p>
                            {/* </div> */}
                          </div>
                          {/* <div className="mb-3"> */}
                          {CreateMultiple &&
                            <div
                              className={put == "timed_auction" ? "create_box create_sing_bx card_user_bg_1 active" : "create_box create_sing_bx card_user_bg_1"}
                              onClick={() =>
                                setAuction(
                                  (prevState) => !prevState,
                                  setFixedPrice(false),
                                  setCoinname(Wallet_Details.networkConfiguration.tokenSymbol),
                                  setPut("timed_auction")
                                )
                              }
                            >
                              {/* <i class="fas fa-clock"></i> */}
                              <img
                                src={require("../assets/images/timed_svg.svg").default}
                                alt="Timed Auction"
                                className="img-fluid"
                              />
                              <p>Timed Auction</p>
                              {/* </div> */}
                            </div>
                          }
                          {/* <div className="mb-3"> */}
                          <div
                            className={put == "unlimited" ? "create_box create_sing_bx card_user_bg_3 active" : "create_box create_sing_bx card_user_bg_3"}
                            onClick={() => {
                              setFixedPrice(false);
                              setAuction(false);
                              setPut("unlimited");
                            }}
                          >
                            <img
                              src={require("../assets/images/unlimited_svg.svg").default}
                              alt="Unlimited Auction"
                              className="img-fluid"
                            />

                            <p>Unlimited Auction</p>
                            {/* </div> */}
                          </div>
                        </div>
                      )}

                      {FixedPrice && (
                        <div className="row mx-0 mt-3 fixed_price_sec">
                          <label className="primary_label" htmlFor="price_new">
                            Price<span className="text-danger">*</span>
                          </label>

                          <div className="form-row w-100">
                            <div className="form-group col-md-6">
                              <div className="input-group input_grp_style_1 min_h_45">
                                <input
                                  type="number"
                                  id="TokenPrice"
                                  className="form-control"
                                  onChange={(e) => PriceCalculate(e)}
                                  // value={(Number(TokenPrice))}
                                  placeholder="Equivalent Value of USD"
                                  aria-label="TokenPrice"
                                  aria-describedby="basic-addon2"
                                  onKeyDown={handleKeyDown}
                                  onWheel={(e) => e.target.blur()}
                                />
                                <div className="input-group-append">
                                  <span
                                    className="input-group-text pl-0 min_h_45_px tokenpeorcs"
                                    id="basic-addon2"
                                  >
                                    {config.currencySymbol}
                                  </span>
                                  {/* <Select
                                  className="yes1 form-control primary_inp select1 selxet_app"
                                  id="tokenCategory"
                                  name="tokenCategory"
                                  onChange={handleChange}
                                  options={options}
                                  label="Single select"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                /> */}
                                </div>
                              </div>
                              {ValidationError && ValidationError.TokenPrice &&
                                <p className="Warning_label">{ValidationError && ValidationError.TokenPrice}</p>
                              }
                            </div>
                            <div className="form-group col-md-6">
                              <div className="input-group input_grp_style_1">
                                <input
                                  type="number"
                                  id="USDTokenPrice"
                                  // onChange={(e) => PriceCalculate(e)}
                                  value={USDTokenPrice}
                                  max={1e12}
                                  className="form-control"
                                  placeholder="Enter your Price"
                                  aria-label="USDTokenPrice"
                                  aria-describedby="basic-addon2"
                                  disabled
                                />
                                <div className="input-group-append">
                                  <span
                                    className="input-group-text pl-0 min_h_45_px tokenpeorcs"
                                    id="basic-addon2"
                                  >
                                    USD
                                  </span>
                                </div>
                              </div>
                            </div>

                          </div>
                          {/* <div class="input-group mb-1">
                <input type="text" id="price_new" class="form-control selct_form_input_h" placeholder="Enter price for one piece" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                <div class="input-group-append">
                <Select
                        className="yes1 form-control primary_inp select1 selxet_app"

                        id="tokenCategory"
                        name="tokenCategory"
                        onChange={handleChange}
                        options={options}
                        label="Single select"
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                </div>

              </div> */}
                          <p className="form_note">
                            Service fee{" "}
                            <span className="font_we_700_note_txt">2.5%</span>
                            {/* <br /> You will receive{" "}
                            <span className="font_we_700_note_txt">0B OPEN</span> */}
                          </p>
                        </div>
                      )}
                      {Auction && (
                        <div className="row mt-3 timed_sec">
                          <div className="col-12 mb-3">
                            <label className="primary_label" htmlFor="price_new">
                              Minimum bid<span className="text-danger">*</span>
                            </label>

                            <div class="input-group mb-1">
                              <input
                                type="number"
                                id="MinimumBid"

                                onChange={(e) => PriceCalculate(e)}
                                class="form-control selct_form_input_h"
                                placeholder="Enter minimum bid"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon3"
                                min={0.0001}
                                max={1e12}
                                onKeyDown={handleKeyDown}
                                onWheel={(e) => e.target.blur()}
                              />
                              <div class="input-group-append">
                                <span
                                  className="input-group-text pl-0 min_h_45_px"
                                  id="basic-addon2"
                                >
                                  {/* {config.currencySymbol} */}
                                </span>
                                {/* <Select
                                className="yes1 form-control primary_inp select1 selxet_app"
                                id="tokenCategory"
                                name="tokenCategory"
                                onChange={handleChange}
                                options={options1}
                                label="Single select"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              /> */}
                              </div>
                            </div>
                            <p className="form_note">
                              Bids below this amount won't be allowed.
                            </p>
                            {ValidationError && ValidationError.MinimumBid &&
                              <p className="Warning_label">{ValidationError && ValidationError.MinimumBid}</p>
                            }
                          </div>
                          <div className="col-12 col-lg-6  mb-3">
                            <div className="single_dd_1">
                              <label
                                className="primary_label"
                                htmlFor="start_date"
                              >
                                Starting Date<span className="text-danger">*</span>
                              </label>
                              <div class="dropdown">
                                <button
                                  class="btn btn-secondary dropdown-toggle filter_btn"
                                  type="button"
                                  id="dropdownMenuButton"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  {AuctionStart ? (AuctionStart).toLocaleString() : "Starting Date"}
                                  <i class="fas fa-chevron-down"></i>
                                </button>
                                <div
                                  class="dropdown-menu filter_menu"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <div onClick={() => { ; setPicStartselected(false); CalAction("now", 0) }}>
                                    Right after listing
                                  </div>
                                  <div
                                    data-toggle="modal"
                                    data-target="#calendar_modal"
                                    onClick={() => { setAucCalender("Start"); setPicStartselected(true) }}
                                  >
                                    Pick specific date
                                  </div>
                                </div>
                              </div>
                              {ValidationError && ValidationError.Auctiondates &&
                                <p className="Warning_label">{ValidationError && ValidationError.Auctiondates}</p>
                              }
                            </div>
                          </div>
                          <div className="col-12 col-lg-6 mb-3">
                            <div className="single_dd_1">
                              <label
                                className="primary_label"
                                htmlFor="start_date"
                              >
                                Expiration Date<span className="text-danger">*</span>
                              </label>
                              <div class="dropdown">
                                <button
                                  class="btn btn-secondary dropdown-toggle filter_btn"
                                  type="button"
                                  id="dropdownMenuButton"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  {AuctionEnd ? (AuctionEnd).toLocaleString() : "Expiration Date"}
                                  <i class="fas fa-chevron-down"></i>
                                </button>
                                <div
                                  class="dropdown-menu filter_menu"
                                  aria-labelledby="dropdownMenuButton"
                                >{!PicStartselected && <>
                                  <div onClick={() => CalAction("end", 1)}>
                                    1 day
                                  </div>
                                  <div onClick={() => CalAction("end", 2)}>
                                    2 days
                                  </div></>
                                  }
                                  <div
                                    data-toggle="modal"
                                    data-target="#calendar_modal"
                                    onClick={() => setAucCalender("End")}
                                  >
                                    Pick specific date
                                  </div>
                                </div>
                              </div>
                              {ValidationError && ValidationError.Auctiondates &&
                                <p className="Warning_label">{ValidationError && ValidationError.Auctiondates}</p>
                              }
                            </div>

                          </div>
                          <div className="col-12">
                            <p className="form_note">
                              Any bid placed in the last 10 minutes extends the
                              auction by 10 minutes.
                            </p>
                            <p
                              className="form_note_link_boild"
                              data-toggle="modal"
                              data-target="#learn_modal"
                            >
                              Learn more how timed auctions work
                            </p>
                          </div>
                        </div>
                      )}
                    </div>

                  </div>}

                <div className="form-row">
                  <div className="form-group col-md-12">
                    <div className="d-flex justify-content-between align-items-start grid_toggle">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">
                          Unlock Once Purchased
                        </label>
                        <p className="form_note">
                          Content will be unlocked after successful transaction
                        </p>
                      </div>
                      <label className="switch toggle_custom">
                        <input
                          type="checkbox"
                          onChange={() =>
                            setUnlockable((prevState) => !prevState)
                          }
                        />
                        <span className="slider"></span>
                      </label>
                    </div>
                    {Unlockable && (
                      <div className="form-group unlock_sec mt-4">
                        <input
                          type="text"
                          className="form-control primary_inp mb-1"
                          id="digitalkey"
                          autoComplete="off"
                          onChange={(e) => {
                            Formdata.UnlockContent = e.target.value;
                          }}
                          placeholder="Digital key, code to redeem or link to a file.."
                        />
                        <p class="form_note mt-4">
                          Tip: Unlock Feature Promotes your sale
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                {(Fiat) &&
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <div className="d-flex justify-content-between align-items-start grid_toggle">
                        <div>
                          <label className="primary_label" htmlFor="inputEmail4">
                            Revenue Sharing
                          </label>
                          <p className="form_note">
                            Enter the Wallet Address. Whom You Want to Share Your Profits
                          </p>
                        </div>
                        <label className="switch toggle_custom">
                          <input
                            type="checkbox"
                            onChange={() =>
                              setCharity((prevState) => !prevState)
                            }
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      {Charity && (
                        <div className="form-group unlock_sec mt-4">
                          {console.log("log Fro InPut Length", inputList)}

                          <>
                            {
                              inputList.map((x, i) => {
                                return (
                                  <div className="box">
                                    <div className="row">
                                      <div className="col-7 col-md-6 col-sm-5">
                                        {i == 0 && <label>Address</label>}
                                        <input
                                          className="form-control primary_inp mb-2"
                                          name="Address"
                                          placeholder="Address"
                                          value={x.Address}
                                          onChange={(e) => handleInputChange(e, i)}
                                        />
                                      </div>
                                      <div className="col-5 col-md-2 col-sm-2">
                                        {i == 0 && <label>Percentage</label>}
                                        <input
                                          className="ml10 form-control primary_inp mb-2"
                                          name="Share"
                                          type="number"
                                          placeholder="Percentage"
                                          value={x.Share}
                                          onChange={(e) => handleInputChange(e, i)}
                                          onKeyDown={handleKeyDown}
                                          onWheel={(e) => e.target.blur()}
                                        />
                                      </div>
                                      <div className="col-5 col-md-2 col-sm-2">
                                        {i == 0 && <label>Royality</label>}
                                        <input
                                          type="number"
                                          className="ml10 form-control primary_inp mb-2"
                                          name="Royality"
                                          placeholder="Royality"
                                          value={x.Royality}
                                          onChange={(e) => handleInputChange(e, i)}
                                          onKeyDown={handleKeyDown}
                                          onWheel={(e) => e.target.blur()}
                                        />
                                      </div>
                                      <div className="col-5 col-md-2 col-sm-3">
                                        {i == 0 && <label>&nbsp;</label>}
                                        <div className="btn-box">
                                          {inputList.length !== 1 && (
                                            <Button className="mr10 mt-0 w-100 btn create_btn" onClick={() => handleRemoveClick(i)}>
                                              Remove
                                            </Button>
                                          )}
                                          {inputList.length - 1 === i && (
                                            <Button className={inputList.length !== 1 ? "btn create_btn w-100 mt-2" : "btn create_btn w-100"} onClick={() => handleAddClick()}>Add</Button>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                );
                              })}
                          </>



                        </div>
                      )}
                    </div>
                    {ValidationError && ValidationError.CharityRoyalty &&
                      <p className="Warning_label">{ValidationError && ValidationError.CharityRoyalty}</p>
                    }
                    {ValidationError && ValidationError.CharityAddress &&
                      <p className="Warning_label">{ValidationError && ValidationError.CharityAddress}</p>
                    }
                    {ValidationError && ValidationError.CharityShare &&
                      <p className="Warning_label">{ValidationError && ValidationError.CharityShare}</p>
                    }
                  </div>
                }
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">
                      Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control primary_inp"
                      id="name"
                      autoComplete="off"
                      onChange={(e) => OnChangehandle(e)}
                      placeholder="e.g. Redeemable"
                    />
                    {ValidationError && ValidationError.name &&
                      <p className="Warning_label">{ValidationError && ValidationError.name}</p>
                    }
                  </div>

                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="desccription">
                      Description <span className="text-muted">(Optional)</span>
                    </label>
                    <input
                      type="text"
                      className="form-control primary_inp"
                      id="description"
                      autoComplete="off"
                      onChange={(e) => OnChangehandle(e)}
                      placeholder="e.g. After purchasing..."
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="royalties">
                      Royalties <span className="text-muted">(Max:20%)</span><span className="text-danger">*</span>
                    </label>
                    <div className="inp_grp">
                      <input
                        type="number"
                        className="form-control primary_inp"
                        id="royalties"
                        autoComplete="off"
                        max={20}
                        min={1}
                        onChange={(e) => OnChangehandle(e)}
                        placeholder="Eg. 5%, 10%, 15%,..20%"
                        onKeyDown={handleKeyDown}
                        onWheel={(e) => e.target.blur()}
                      />
                      <p className="inp_append">%</p>
                    </div>
                    {ValidationError && ValidationError.royalties &&
                      <p className="Warning_label">{ValidationError && ValidationError.royalties}</p>
                    }
                  </div>
                  <div className="form-group col-md-6">

                    <label className="primary_label" htmlFor="category">
                      Category<span className="text-danger">*</span>
                    </label>
                    {/* { Wallet_Details?.Category.filter(items => (items.label != "Ticket"))} */}
                    {/* {console.log("qweqweqweqwwe", categorysate)} */}
                    <Select
                      className="yes1 form-control primary_inp select1 selxet_app"
                      onChange={(e) => Formdata.NFTCategory = e.name}
                      options={!CreateMultiple ? categorysate : categorysate}
                      label="Single select"
                      classNamePrefix="react-select"
                      isSearchable={false}
                      styles={customStyles}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {ValidationError && ValidationError.NFTCategory &&
                      <p className="Warning_label">{ValidationError && ValidationError.NFTCategory}</p>
                    }
                  </div>
                </div>
                {/* {console.log("wallet category", Formdata)} */}
                <div className="form-row">
                  {!CreateMultiple && (
                    <div className="form-group col-md-6">
                      <label className="primary_label" htmlFor="quantity">
                        Quantity <span className="text-muted">(Max:500)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control primary_inp"
                        id="quantity"
                        autoComplete="off"
                        onChange={(e) => OnChangehandle(e)}
                        placeholder="e.g. 10, 20"
                      />
                    </div>
                  )}
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="properties">
                      Properties <span className="text-muted">(Optional)</span>
                    </label>
                    <input
                      type="text"
                      className="form-control primary_inp"
                      id="properties"
                      autoComplete="off"
                      onChange={(e) => OnChangehandle(e)}
                      placeholder="e.g. size"
                    />
                  </div>
                </div>
                {Formdata.NFTCategory == 'Ticket' &&
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="primary_label" htmlFor="properties">
                        Date <span className="text-muted">(DD-MM-YYYY)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control primary_inp"
                        id="Date"
                        autoComplete="off"
                        onChange={(e) => OnChangehandle(e)}
                        placeholder="e.g .(DD-MM-YYYY)"
                      />
                      {ValidationError && ValidationError.Date &&
                        <p className="Warning_label">{ValidationError && ValidationError.Date}</p>
                      }
                    </div>

                    <div className="form-group col-md-6">
                      <label className="primary_label" htmlFor="properties">
                        Location <span className="text-muted">(Address)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control primary_inp"
                        id="Location"
                        autoComplete="off"
                        onChange={(e) => OnChangehandle(e)}
                        placeholder="Enter Ticket Address"
                      />
                      {ValidationError && ValidationError.Location &&
                        <p className="Warning_label">{ValidationError && ValidationError.Location}</p>
                      }
                    </div>
                    <div className="form-group col-md-6">
                      <label className="primary_label" htmlFor="properties">
                        Section <span className="text-muted"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control primary_inp"
                        id="Section"
                        autoComplete="off"
                        onChange={(e) => OnChangehandle(e)}
                        placeholder="Section"
                      />
                      {ValidationError && ValidationError.Section &&
                        <p className="Warning_label">{ValidationError && ValidationError.Section}</p>
                      }
                    </div>
                    <div className="form-group col-md-12">
                      <div className="d-flex justify-content-between align-items-start grid_toggle">
                        <div>
                          <label className="primary_label" htmlFor="inputEmail4">
                            Token Gating
                          </label>
                          <p className="form_note">
                            proof of NFT ownership
                          </p>
                        </div>
                        <label className="switch toggle_custom">
                          <input
                            type="checkbox"
                            onChange={() =>
                              setTokenGating(true)
                            }
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      {TokenGatingTog && (
                        <div className="form-group unlock_sec mt-4">
                          <input
                            type="text"
                            className="form-control primary_inp mb-1"
                            id="TokenGating"
                            autoComplete="off"
                            onChange={(e) => OnChangehandle(e)}
                            placeholder="link to a file.."
                          />
                          {ValidationError && ValidationError.TokenGating &&
                            <p className="Warning_label">{ValidationError && ValidationError.TokenGating}</p>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }
                <div>
                  {(Wallet_Details.UserAccountAddr !== "") ?
                    <Button
                      className="create_btn mt-3"
                      onClick={() => Validate()}
                    >
                      Create item
                    </Button>
                    :
                    <Link to="/connect-wallet">
                      <Button className="create_btn mt-3">Connect Wallet</Button>
                    </Link>
                  }
                </div>
              </form>
            </GridItem>
          </GridContainer>
        </div>
        <button id="MintcardClick" className="d-none" data-target="#create_item_modal" data-toggle="modal"></button>
      </div>
      {/* {console.log("mdflsdmflsdlfmsd",Formdata,Wallet_Details)} */}
      <MintingCard NFTDetails={Formdata} />
      <Calender CalenderState={AucCalendar} ParentCall={ChildReply} />
      <Footer />
      {/* learn Modal */}
      <div
        class="modal fade primary_modal"
        id="learn_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="learn_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="learn_modalLabel">
                How timed auction work
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>
                When you put your item on timed auction, you choose currency,
                minimum bid, starting and ending dates of your auction.
              </p>

              <p>
                The bidder can only place a bid which satisfies the following
                conditions:
              </p>
              <ol>
                <li>It is at least minimum bid set for the auction</li>
                <li>
                  It is at least 5% higher than the current highest bid or it is
                  at least 2.3B OPEN higher than the current highest bid
                </li>
              </ol>

              <p>
                Note that some bids may disappear with time if the bidder
                withdraws their balance. At the same time, some bids may
                reappear if the bidder has topped up their balance again.
              </p>

              <p>
                Auction cannot be cancelled after any valid bid was made. Any
                bid placed in the last 10 minutes extends the auction by 10
                minutes.
              </p>

              <p>
                In the 48 hours after the auction ends you will only be able to
                accept the highest available bid placed during the auction. As
                with regular bids, you will need to pay some gas to accept
                it.Note that you can always decrease the price of your listing
                for free, without making a transaction or paying gas. You can
                view all your items here.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* end learn modal */}
    </div>
  );
}
