import React, { useEffect, useState } from "react";
import config from "lib/config";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { LikeAction } from "actions/v1/token";
import { toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Countdown from "react-countdown";
import Badge from "components/Badge/Badge";
import LazyLoad from 'react-lazyload';
import LazyLoader from "../lazyloader";
toast.configure();

export default function ExploreCard(props) {
    const history = useHistory();
    const renderer = ({
        days,
        Month,
        Year,
        hours,
        minutes,
        seconds,
        completed,
    }) => {
        if (completed) {
            return <span></span>;
        } else {
            return (
                <span>
                    {days}d {hours}h {minutes}m {seconds}s left
                </span>
            );
        }
    };
    const Wallet_Details = useSelector((state) => state.wallet_connect_context);
    const { NFTDetails, Cardclass } = props;
    const [Templike, setTemplike] = useState(NFTDetails.mylikes ? true : false);
    const [TempCount, setTempCount] = useState(NFTDetails.likecount);
    var NFTLink = `/FractionDetails/${NFTDetails.contractAddress}/${NFTDetails.tokenOwnerInfo.curraddress}/${NFTDetails.tokenID}`;
    console.log("nftdetials", NFTDetails)
    if (NFTDetails.additionalImage == "")
        var NFT = `${config.IPFS_IMG}/${NFTDetails.ipfsimage}`;
    else
        var NFT = `${config.Back_URL}/nftImg/${NFTDetails.tokenCreator}/${NFTDetails.image}`;

    // console.log("nft image path", NFT)

    if (NFTDetails.tokenOwnerInfo.image !== "")
        var Owner = `${config.Back_URL}/Users/${NFTDetails.tokenOwnerInfo.PrimaryAddr}/profileImg/${NFTDetails.tokenOwnerInfo.image}`;
    else var Owner = `${require("../../assets/images/lazymintingnew/logo.png")}`;

    if (NFTDetails.tokenOwnerInfo.customurl)
        var OwnerLink = `/my-items/user/${NFTDetails.tokenOwnerInfo.customurl}`;
    else var OwnerLink = `/my-items/${NFTDetails.tokenOwnerInfo.curraddress}`;

    const LikeAct = async () => {
        if (Wallet_Details.UserAccountAddr !== "") {
            if (Templike) {
                toast.success("Removed from Liked", {
                    closeButton: true,
                    autoClose: 1500,
                    transition: Flip,
                });
            }
            else {
                toast.success("Added to Liked", {
                    closeButton: true,
                    autoClose: 1500,
                    transition: Flip,
                });
            }
            var Payload = {
                tokenID: NFTDetails.tokenID,
                tokenName: NFTDetails.tokenName,
                curraddress: Wallet_Details.UserAccountAddr,
            };
            var resp = await LikeAction(Payload);
            if (resp.data.Success) {
                if (Templike) {
                    setTempCount(TempCount - 1);
                    setTemplike(false);
                } else {
                    setTempCount(TempCount + 1);
                    setTemplike(true);
                }
            } else
                toast.warning(resp.data.Message, {
                    closeButton: true,
                    autoClose: 1500,
                    transition: Flip,
                });
        } else history.push("/connect-wallet");
    };

    return (
        <div className="item col-xl-3 col-12 col-sm-6 col-md-6 col-lg-4 mb-2 card_whole">
            <div className="card_inner_item">
                <div className="trendingban">
                    {/* <div
              onClick={() => {
                LikeAct();
              }}
              className="like_div_round"
            >
              {Templike ? (
                <i className="far fa-heart liked"></i>
              ) : (
                <i className="far fa-heart liked2"></i>
              )}
            </div> */}
                    <Link to={NFTLink}>
                        <LazyLoad height={200} placeholder={<LazyLoader />} offset={[-200, 0]} debounce={500}>

                            {/* <img src={require("../../assets/images/img_info.png")} alt="Collections" className="img-fluid" /> */}
                            {NFTDetails.image != "" &&
                                (NFTDetails.image.split(".").pop() == "mp4" ? (
                                    <video
                                        id="my-video"
                                        class="img-fluid"
                                        autoPlay
                                        playsInline
                                        loop
                                        muted
                                        preload="auto"
                                        controls disablePictureInPicture controlsList="nodownload"
                                    >
                                        <source src={NFT} type="video/mp4" />
                                    </video>
                                ) : (
                                    <img src={NFT} alt="Collections" className="img-fluid" />
                                ))}
                        </LazyLoad>
                    </Link>
                </div>
                <div className="usercontrolicon">
                    <div className="sliderbanncont">
                        {/* {console.log("qweerwerwrerer", NFTDetails)} */}
                        {
                            NFTDetails.tokenOwnerInfo.image === "" &&
                            <Link to={OwnerLink}>
                                <img src={Owner} alt="SmallProfile" className="profilebamds1" />
                            </Link>
                        }
                        {
                            NFTDetails.tokenOwnerInfo.image !== "" &&
                            <Link to={OwnerLink}>
                                <img src={`${config.Back_URL}/Users/${NFTDetails.tokenOwnerInfo.PrimaryAddr}/profileImg/${NFTDetails.tokenOwnerInfo.image}`} />
                            </Link>
                        }

                        {/* {console.log("qweqwrqrrewrewr", NFTDetails.tokenOwnerInfo)} */}
                        <Link to={OwnerLink}>
                            <h2 className="user_title">{NFTDetails.tokenOwnerInfo.name
                                ? NFTDetails.tokenOwnerInfo.name
                                : NFTDetails?.tokenOwnerInfo?.curraddress?.slice(0, 10)}</h2>
                        </Link>

                    </div>
                    <div className="faHeartflex">
                        <div onClick={() => {
                            LikeAct();
                        }}>
                            {/* <h3 className="my-2">
                  <span>{(NFTDetails.tokenOwnerInfo.curraddress === Wallet_Details.UserAccountAddr)?
                          (NFTDetails.tokenowners_current.balance === 0)?"You Created This":"You Own This"
                          :
                           new Date(NFTDetails.auctionend).getTime() > Date.now() ?
                                `${NFTDetails.minimumBid} ${config.currencySymbol}`
                                :
                                (NFTDetails.tokenowners_current.tokenPrice)?
                                    `${NFTDetails.tokenowners_current.tokenPrice} ${config.currencySymbol}` 
                                    : (NFTDetails.tokenOwnerInfo.curraddress === Wallet_Details.UserAccountAddr)?
                                      "You Own This"
                                      :
                                        <badge className="badge badge-dark badge-timer mb-1 make_offer_badge"> Make offer</badge>
                                    }</span>{" "}
                </h3> */}
                            {/* <i className="fa fa-heart"></i>{TempCount} */}
                            {Templike ? (
                                <i className="far fa-heart liked"></i>
                            ) : (
                                <i className="far fa-heart liked2"></i>
                            )}{TempCount}

                        </div>
                    </div>

                </div>

                <div className="img_desc_nft pt-2 cardItem card_price">
                    <Link to={OwnerLink}>
                        <img
                            src={Owner}
                            alt="User"
                            className="img-fluid profiel_img_pos"
                            title={`Owner : ${NFTDetails.tokenOwnerInfo.name
                                ? NFTDetails.tokenOwnerInfo.name
                                : NFTDetails.tokenOwnerInfo.curraddress
                                }`}
                        />
                        <img
                            src={require("../../assets/images/star.png")}
                            alt="Collections"
                            className="img-fluid star_img_blue"
                        />
                    </Link>
                    <div className="d-block mt-2 card_details">
                        <h2 className="tokename_title">{NFTDetails.tokenName}</h2>

                    </div>

                </div>
                <div className="usercontroliconstock">
                    <div>
                        {NFTDetails.auctionend &&
                            new Date(NFTDetails.auctionstart).getTime() <= Date.now() &&
                            new Date(NFTDetails.auctionend).getTime() > Date.now() ?
                            <div className="timeleft">
                                {/* {" "} */}
                                <i class="far fa-clock mr-1"></i>
                                <Countdown
                                    date={new Date(NFTDetails.auctionend).getTime()}
                                    renderer={renderer}
                                ></Countdown>

                                {/* 03h 56mm 06s left */}
                            </div>
                            :
                            <h2 className="like_count">
                                {NFTDetails.tokenowners_current.balance} Fractions in stock
                            </h2>

                        }
                    </div>

                    <div className="price offers mt-2">
                        {/* <p className="like_count">
                  <i className="far fa-heart liked mr-1" />
                  {TempCount} likes
                </p> */}
                        {/* {console.log("sdnsdnsdsdkfd", NFTDetails)} */}
                        <div className="price mr-auto">
                            <p className="mx-0">{(NFTDetails.tokenOwnerInfo.curraddress === Wallet_Details.UserAccountAddr) ?
                                (NFTDetails.tokenowners_current.balance === 0) ? "You Created This" : "You Own This"
                                :
                                new Date(NFTDetails.auctionend).getTime() > Date.now() ?
                                    `${NFTDetails.minimumBid} ${Wallet_Details.networkConfiguration.tokenSymbol}`
                                    :
                                    (NFTDetails.tokenowners_current.tokenPrice) ?
                                        `${NFTDetails.tokenowners_current.tokenPrice} ${NFTDetails.tokenowners_current.coinname}`
                                        : (NFTDetails.tokenOwnerInfo.curraddress === Wallet_Details.UserAccountAddr) ?
                                            "You Own This"
                                            :
                                            <Link to={NFTLink}>
                                                <badge className="badge badge-dark badge-timer mb-1 make_offer_badge mx-2"> Make offer</badge></Link>
                            }</p>
                            {/* <p className="d-flex align-items-center mt-1 eth"><img src={require("../../assets/images/eth.png")} alt="Eth" className="img-fluid"/>&nbsp;0.15&nbsp;ETH</p> */}

                        </div>
                    </div>
                </div>

                {/* <div className="d-flex justify-content-between align-items-center">
              <div className="price">
               
                <div className="price mr-auto">
                                <p>{(NFTDetails.tokenOwnerInfo.curraddress === Wallet_Details.UserAccountAddr)?
                          (NFTDetails.tokenowners_current.balance === 0)?"You Created This":"You Own This"
                          :
                           new Date(NFTDetails.auctionend).getTime() > Date.now() ?
                                `${NFTDetails.minimumBid} ${config.currencySymbol}`
                                :
                                (NFTDetails.tokenowners_current.tokenPrice)?
                                    `${NFTDetails.tokenowners_current.tokenPrice} ${config.currencySymbol}` 
                                    : (NFTDetails.tokenOwnerInfo.curraddress === Wallet_Details.UserAccountAddr)?
                                      "You Own This"
                                      :
                                        <badge className="badge badge-dark badge-timer mb-1 make_offer_badge"> Make offer</badge>
                                    }</p>
                             
              
                            </div>
              </div>
              
                    <Link to={OwnerLink}>


</Link> 


            </div> */}
            </div>
        </div>
    );
}



{/* <Link to={OwnerLink}>
<img
  src={Owner}
  alt="User"
  className="img-fluid profiel_img_pos"
  title={`Owner : ${
    NFTDetails.tokenOwnerInfo.name
      ? NFTDetails.tokenOwnerInfo.name
      : NFTDetails.tokenOwnerInfo.curraddress
  }`}
/>
<img
  src={require("../../assets/images/star.png")}
  alt="Collections"
  className="img-fluid star_img_blue"
/>
</Link> */}
// require("../../assets/images/small-profile.png")