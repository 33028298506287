import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import HeaderLinks from "components/Header/HeaderLinks.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import { v1_GetPrivacy } from "actions/v1/user";
import { toast } from "react-toastify";
import ReactHtmlParser from 'react-html-parser';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Privacypolicy(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [PrivacyPol,setPrivacyPol] = useState({})
  const GetPrivacy = async()=>{
    var Resp = await v1_GetPrivacy()
    if(Resp.Success){
        setPrivacyPol(Resp.userValue)}
    else
      toast.error("Try it Again Later",{autoClose:2000})
  }
  useEffect(()=>{
    GetPrivacy()
  },[])
  return (
    <div className="home_header">
     <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home">
          <div className="header_logo_align">
            

          </div>
          </Link>}
        leftLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader inner_pagh_bg_1 pb-5"}>
        <div className="container mt-1">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} className="privacy_policy">
              
           {PrivacyPol.answer && ReactHtmlParser(PrivacyPol.answer)}
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
