import React, {
    useEffect,
    useState,
    forwardRef,
    useImperativeHandle,
  } from "react";
  import Web3 from 'web3';
  import { useHistory } from "react-router-dom";
  import { Button } from "@material-ui/core";
  import { TailSpin } from "react-loader-spinner";
  import { useDispatch, useSelector } from "react-redux";
  import { toast } from "react-toastify";
  import config from "lib/config";
  import $ from "jquery"
  import { ContactMint } from "actions/v1/token";
  import { v1_BurnToken } from "actions/v1/Fractional";
  import { useBurnReceiptFraction, useBurnReceiptBulk ,useTokenReceiptFraction} from "hooks/useReceipts";
  import { useNFTBalCheck, useBulBalnceCheck } from "hooks/useMethods";
  import { LMBalanceOf ,LMTokenTransferBack ,LMTokenBurnTokenFrac } from "actions/v1/token";
  import { v1_ConNFTBalCheck } from 'actions/v1/Fractional'
  import { network } from "../network"
  import { connectWallet, getServiceFees } from '../../hooks/useWallet';
  import { Account_Connect } from "actions/redux/action";
  
  export const BurnToken = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const Wallet_Details = useSelector((state) => state.wallet_connect_context);
    const [BurnState, setBurnState] = useState(false);
    const [NoofToken, setNoofToken] = useState(0)
    const [NFTImage, setNFTImage] = useState("");
    const [NFTDet, setNFTDet] = useState({});
    const [NFT, setNFT] = useState({ CurrentOwner: {} });
    const history = useHistory()
    useImperativeHandle(ref, () => ({
      async BurnTokenClick(ClickedNFT, NFTimage, NFT) {
        var chainid = NFT.SelectedNetwork
        const chainId = await Wallet_Details.Web3Pro.eth.getChainId();
        var networkConfiguration = {}
        if (Number(chainid) === network.MATIC.Chainid) {
          networkConfiguration = network.MATIC
        }
        else if (Number(chainid) === network.ETH.Chainid) {
          networkConfiguration = network.ETH
        }
        else if (Number(chainid) === network.CAM.Chainid) {
          networkConfiguration = network.CAM
        }
        Serfee(chainid);
        Wallet_Details.networkConfiguration = networkConfiguration
        var accountDetailsss = ''
        if (Number(chainId) == Number(NFT.SelectedNetwork)) {
          accountDetailsss = true
        }
        else {
          if (Number(chainid) !== Number(chainId)) {
            var accountDetailsss = await switchNetwork(chainid, networkConfiguration)
          }
        }
        setNFTImage(NFTimage);
        setNFTDet(NFT);
        if (Number(chainid) === Number(chainId)) {
          if (Wallet_Details.UserAccountAddr) {
            window.$('#burn_order_modal').modal('show');
            setNFT(ClickedNFT);
          }
        }
      },
    }));
  
    async function switchNetwork(configdata, networkConfiguration) {
      var type = ""
      var networkConfiguration = {}
      const chainId = await Wallet_Details.Web3Pro.eth.getChainId();
      if (localStorage.walletConnectType !== "Torus") {
        if (Number(configdata) !== Number(chainId)) {
          const id = toast.loading("Switching Network", { closeButton: true, theme: "dark" });
          if (configdata) {
            if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask') {
              type = "MetaMask"
            }
            else if (localStorage.walletConnectType && localStorage.walletConnectType == 'WalletConnect' && localStorage.walletConnectType != null) {
              type = "WalletConnect"
            }
            else if (localStorage.walletConnectType && localStorage.walletConnectType == 'Torus' && localStorage.walletConnectType != null) {
              type = "Torus"
            }
            else if (localStorage.walletConnectType && localStorage.walletConnectType == 'TorusWallet' && localStorage.walletConnectType != null) {
              type = "TorusWallet"
            }
            else if (localStorage.walletConnectType && localStorage.walletConnectType == 'LMWallet' && localStorage.walletConnectType != null) {
              console.log("adasd comming on torus");
              type = "LMWallet"
              localStorage.setItem("ChainId", NFTDet.SelectedNetwork)
            }
            var accountDetails = await connectWallet(type, Number(configdata), localStorage.walletConnectType == "LMWallet" ? "register" : "Create", Wallet_Details, "Tor")
            const chainId = await accountDetails?.web3?.eth?.getChainId();
            if (Number(configdata) === network.MATIC.Chainid) {
              networkConfiguration = network.MATIC
            }
            else if (Number(configdata) === network.ETH.Chainid) {
              networkConfiguration = network.ETH
            }
            else if (Number(configdata) === network.CAM.Chainid) {
              networkConfiguration = network.CAM
            }
            if (accountDetails != '') {
              dispatch({
                type: Account_Connect,
                Account_Detail: {
                  UserAccountAddr: accountDetails.accountAddress,
                  UserAccountBal: accountDetails.coinBalance,
                  WalletConnected: "true",
                  Wen_Bln: accountDetails.tokenBalance,
                  Accounts: accountDetails.accountAddress,
                  Web3Pro: accountDetails?.web3,
                  providerss: accountDetails?.web3?._provider,
                  networkConfiguration: networkConfiguration
                }
              })
              Serfee(networkConfiguration.Chainid);
            }
            if (networkConfiguration.currencySymbol) {
              toast.update(id, {
                render: "Network switched Succesfully",
                type: "success",
                isLoading: false,
                autoClose: 2500,
                closeButton: true,
                theme: "dark"
              });
            }
          }
          return accountDetails
        } else {
          dispatch({
            type: Account_Connect,
            Account_Detail: {
              UserAccountAddr: Wallet_Details?.UserAccountAddr,
              UserAccountBal: Wallet_Details?.UserAccountBal,
              WalletConnected: "true",
              Wen_Bln: Wallet_Details?.Wen_Bln,
              Accounts: Wallet_Details?.Accounts,
              Web3Pro: Wallet_Details?.Web3Pro,
              providerss: Wallet_Details?.providerss,
              networkConfiguration: networkConfiguration
            }
          })
          Serfee(networkConfiguration.Chainid);
          return Wallet_Details.Web3Pro
        }
      } else {
        try {
          const id = toast.loading("Switching Network", { closeButton: true, theme: "dark" });
          var accountDetails = await connectWallet(localStorage.walletConnectType, Number(configdata), "Create", Wallet_Details, "Torus")
          const chainId = await accountDetails?.web3?.eth?.getChainId();
          if (Number(configdata) === network.MATIC.Chainid) {
            networkConfiguration = network.MATIC
          }
          else if (Number(configdata) === network.ETH.Chainid) {
            networkConfiguration = network.ETH
          }
          else if (Number(configdata) === network.CAM.Chainid) {
            networkConfiguration = network.CAM
          }
          if (accountDetails != '') {
            dispatch({
              type: Account_Connect,
              Account_Detail: {
                UserAccountAddr: accountDetails?.accountAddress,
                UserAccountBal: accountDetails?.coinBalance,
                WalletConnected: "true",
                Wen_Bln: accountDetails?.tokenBalance,
                Accounts: accountDetails?.accountAddress,
                Web3Pro: accountDetails?.web3,
                providerss: accountDetails?.web3?._provider,
                networkConfiguration: networkConfiguration
              }
            })
          }
          var functiobn = await NefunctionTriger(Wallet_Details)
          if (networkConfiguration.currencySymbol) {
            toast.update(id, {
              render: "Network switched Succesfully",
              type: "success",
              isLoading: false,
              autoClose: 2500,
              closeButton: true,
              theme: "dark"
            });
          }
        } catch (e) {
          console.log("Catch error Console", e);
        }
      }
    }
  
    const NefunctionTriger = async (Wallet_Details1) => {
      const chainIdSwitched = await Wallet_Details1?.Web3Pro?.eth?.getChainId();
      var balance = await Wallet_Details1?.Web3Pro?.eth.getBalance(Wallet_Details1.UserAccountAddr);
      return chainIdSwitched
    }
  
    const Serfee = async (Address) => {
      var Fee = await getServiceFees(Address)
      dispatch({
        type: Account_Connect,
        Account_Detail: {
          Service_Fee: Fee?.Serfee,
          Service_Fee_Fraction:Fee?.SerfeeFraction
        }
      })
    }
    // newly added to check if the token is minted before burning
    const mintHashcheck = async () => {
      if (NFTDet.hashValue == "not yet minted") {
        setBurnState(true)
        const id = toast.loading("Token Burn On Progress", { closeButton: true });
        var Burn = { Type: NFT.type, Owner: NFT.tokenOwner, id: NFT.tokenID, ConAddr: NFT.contractAddress, NoOfToken: NoofToken, Status: "Burn", Hash: "not yet minted Burn", }
        var Resp = await v1_BurnToken(Burn)
        if (Resp.data.Success) {
          toast.update(id, { render: Resp.data.Message, type: "success", isLoading: false, autoClose: 2500, });
          $('#burn_closs').trigger("click");
          setTimeout(() => {
            history.push('/my-items')
          }, 1000);
        } else {
          setBurnState(false)
          toast.update(id, {
            render: Resp.data.Message,
            type: "error",
            isLoading: false,
            autoClose: 2500,
          });
        }
      }
      else
      BurnCall()
       // BalCheck();
    }
  
    const BalCheck = async () => {
      var Arg = {
        NFTConAddress: NFT.contractAddress,
        NFTId: NFT.tokenID,
        NFTOwner: NFT.tokenOwner,
        Status: NFT.LazyStatus,
        BulK: NFTDet.Bulkmint
      }
      var Type = NFT.type
      if (NFTDet.Bulkmint !== "Bulk") {
        if (localStorage.walletConnectType !== "LMWallet") {
          var ContractNFTBal = await NFTHookFun(Arg, Type, Wallet_Details)
        } else {
          var key = null
          var Argument = Type == 721 ? [Arg.NFTId] : [Arg.NFTOwner, Arg.NFTId]
          var ContractNFTBal = await LMBalanceCheck(Arg, Type, Wallet_Details, Arg.NFTConAddress, Type == 721 ? "Single" : "Multiple", Arg.NFTConAddress, Type == 721 ? "ownerOf" : "balanceOf", Argument, key, Arg.NFTOwner)
        }
      } else {
        if (localStorage.walletConnectType !== "LMWallet") {
          var ContractNFTBal = await NFTHookFun1(Arg, Type, Wallet_Details)
        } else {
          var key = null
          var Argument = Type == 721 ? [Arg.NFTId] : [Arg.NFTOwner, Arg.NFTId]
          var ContractNFTBal = await LMBalanceCheck(Arg, Type, Wallet_Details, Arg.NFTConAddress, "Bulk", Arg.NFTConAddress, Type == 721 ? "ownerOf" : "balanceOf", Argument, key, Arg.NFTOwner)
        }
      }
      console.log("ContractNFTBalContractNFTBal",ContractNFTBal);
      if (Number(ContractNFTBal) !== NFT.balance) {
        Arg.balance = Number(ContractNFTBal)
        var Updated = await v1_ConNFTBalCheck(Arg)
  
        console.log("fjbdshfbsfhsr", Updated)
        if (Updated.data && Updated.data.Success) {
          $('#burn_closs').trigger("click");
          toast.error("Owners NFT Balance Changed. Try Later", { autoClose: 4000, closeButton: true })
          setTimeout(() => {
            history.push('/explore/All')
          }, 1500);
        }
        else {
          $('#burn_closs').trigger("click");
          toast.error("Error Occured. Try Later", { autoClose: 3000, closeButton: true })
          setTimeout(() => {
            history.push('/explore/All')
          }, 1500);
        }
      }
      else {
        console.log("asdasdsadasdas", NFTDet.Bulkmint);
        if (NFTDet.Bulkmint !== "Bulk") {
          BurnCall()
        } else {
          BurnCallBulk()
        }
      }
    }
  
    const NFTHookFun = async (Arg, Type, Wallet_Details) => {
      var ContractNFTBal = await useNFTBalCheck(Arg, Type, Wallet_Details)
      return ContractNFTBal
    }
  
    const NFTHookFun1 = async (Arg, Type, Wallet_Details) => {
      var ContractNFTBal = await useBulBalnceCheck(Arg, Type, Wallet_Details)
      console.log("asdasddasdasdas", NFTHookFun1);
      return ContractNFTBal
    }
  
    const BurnCall = async (Biddet) => {
      console.log("sdfsjkfnsf");
      setBurnState(true)
      const id = toast.loading("Token Burn On Progress", { closeButton: true });
      var Arg = {
        NFTOwner: NFT.tokenOwner,
        NFTId: NFT.tokenID,
        NoofNFTs: 1,
      };
      console.log("Argggggggg",Arg);
      var Type = NFT.type;
      var Provider = Wallet_Details;
      if (localStorage.walletConnectType !== "LMWallet") {
        console.log("Arg, Type, Provider",Arg, Type, Provider);
        var Receipt = await ContactHookBurn(Arg, Type, Provider)
      } else {
        var Key = null
        var Argument = Type == 721 ? [Arg.NFTId] : [Arg.NFTId, Arg.NoofNFTs]
        var Receipt = await LMWalletCall(Arg, NFT.type, Wallet_Details, NFTDet, NFT, Type == 721 ? "Single" : "Multiple", Wallet_Details.networkConfiguration.FractionTradeContract, Wallet_Details.networkConfiguration.FractionTradeContract, Argument, "burnNFT", Key, true, 0)
      }
       console.log("burnreceiot", Receipt)
      if (Receipt.status) {
        var Arg1 = {
          Contact: NFTDet.ERC20_Address,
          NFTOwner: NFT.tokenOwner,
          NFTId: NFT.tokenID,
          NoofNFTs: NFTDet.tokenQuantity,
        }
        if (localStorage.walletConnectType !== "LMWallet") {
          console.log("adkmfadlkmlaks");
          var Receipt1 = await ContactHookTokenBurn(Arg1, Type, Provider)
        } else {
          var Key = null
          var Argument = Type == 721 ? [] : []
          var Receipt1 = await LMTokenTransferBackToken(Arg, NFT.type, Wallet_Details, NFTDet, NFT, "Bulk", NFTDet.ERC20_Address,NFTDet.ERC20_Address, Argument, "burnTotalSupply", Key, true, 0)
        }
       if(Receipt1.status){
        var Burn = { Type: NFT.type, Owner: NFT.tokenOwner, id: NFT.tokenID, ConAddr: NFT.contractAddress, NoOfToken: NFTDet.tokenQuantity, Status: Receipt.status, Hash: Receipt.transactionHash, }
        // console.log("burn token payload", Burn)
        var Resp = await v1_BurnToken(Burn)
        // console.log("burnreceiot", Resp)
        if (Resp.data.Success) {
          toast.update(id, { render: Resp.data.Message, type: "success", isLoading: false, autoClose: 2500, });
          $('#burn_closs').trigger("click");
          setTimeout(() => {
            history.push('/my-items')
          }, 1000);
        } else {
          setBurnState(false)
          toast.update(id, {
            render: Resp.data.Message,
            type: "error",
            isLoading: false,
            autoClose: 2500,
          });
        }
      }else{
        toast.update(id, { render: "Try it Again Later.Please Check Your Funds", type: "error", isLoading: false, autoClose: 2500, });
      }
      } else {
        toast.update(id, { render: "Try it Again Later.Please Check Your Funds", type: "error", isLoading: false, autoClose: 2500, });
      }
    };
  
    const BurnCallBulk = async (Biddet) => {
      setBurnState(true)
      console.log("asdasdasdasdqweqwe");
      const id = toast.loading("Token Burn On Progress", { closeButton: true });
      var Arg = {
        Contact: NFTDet.contractAddress,
        NFTOwner: NFT.tokenOwner,
        NFTId: NFT.tokenID,
        NoofNFTs: NoofToken,
      };
      var Type = NFT.type;
      var Provider = Wallet_Details;
      if (localStorage.walletConnectType !== "LMWallet") {
        var Receipt = await ContactHookBurnBulk(Arg, Type, Provider)
      } else {
        var Key = null
        var Argument = Type == 721 ? [Arg.NFTId] : [Arg.NFTId, Arg.NoofNFTs]
        var Receipt = await LMWalletCall(Arg, NFT.type, Wallet_Details, NFTDet, NFT, "Bulk", NFT.contractAddress, NFT.contractAddress, Argument, "burnNFT", Key, true, 0)
      }
  
      // console.log("burnreceiot", Receipt)
      if (Receipt.status) {
        var Arg1 = {
          Contact: NFTDet.ERC20_Address,
          NFTOwner: NFT.tokenOwner,
          NFTId: NFT.tokenID,
          NoofNFTs: NFTDet.tokenQuantity,
        }
        if (localStorage.walletConnectType !== "LMWallet") {
          var Receipt1 = await ContactHookTokenBurn(Arg, Type, Provider)
        } else {
          var Key = null
          var Argument = Type == 721 ? [Arg.NFTId] : [Arg.NFTId, Arg.NoofNFTs]
          var Receipt1 = await LMWalletCall(Arg, NFT.type, Wallet_Details, NFTDet, NFT, "Bulk", NFT.contractAddress, NFT.contractAddress, Argument, "burnNFT", Key, true, 0)
        }
       if(Receipt1.status){
        var Burn = { Type: NFT.type, Owner: NFT.tokenOwner, id: NFT.tokenID, ConAddr: NFT.contractAddress, NoOfToken: NoofToken, Status: Receipt.status, Hash: Receipt.transactionHash, }
        var Resp = await v1_BurnToken(Burn)
        if (Resp.data.Success) {
          toast.update(id, { render: Resp.data.Message, type: "success", isLoading: false, autoClose: 2500, });
          $('#burn_closs').trigger("click");
          setTimeout(() => {
            history.push('/my-items')
          }, 1000);
        } else {
          setBurnState(false)
          toast.update(id, {
            render: Resp.data.Message,
            type: "error",
            isLoading: false,
            autoClose: 2500,
          });
        }
      }
      } else {
        toast.update(id, { render: "Try it Again Later.Please Check Your Funds", type: "error", isLoading: false, autoClose: 2500, });
      }
    };
  
    const handleKeyDown = (e) => {
      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
        e.preventDefault();
      }
    };
  
    const LMBalanceCheck = async (Arg, Type, Wallet_Details, IntractContact, NameUse, Contract, method, data, Key, tokenOwner) => {
      var Arg = {
        Method: method,
        Data: data,
        Mail: localStorage.loggedinaddress,
        LoginAddress: localStorage.userAddress,
        ContactAddress: Contract,
        Type: String(Type),
        Chain: String(NFTDet.SelectedNetwork),
        IntractContract: IntractContact,
        ContactType: NameUse,
        value: 0,
        Key: Key,
        tokenOwner: tokenOwner
      }
      var Balance = await LMBalanceOf(Arg)
      return Balance.data.receipt
    }
  
  
    const LMWalletCall = async (Arg, type, Wallet_Details, tokDetails, NFT, NameUse, IntractContact, Contract, data, method, Key, Status, fee) => {
      var receipt = null
      var Arg = {
        Method: method,
        Data: data,
        Mail: localStorage.loggedinaddress,
        LoginAddress: localStorage.userAddress,
        ContactAddress: Contract,
        Type: String(type),
        Chain: String(NFTDet.SelectedNetwork),
        IntractContract: IntractContact,
        ContactType: NameUse,
        value: fee,
        Key: Key,
        Status: Status
      }
      var Receiptfun = await ContactMint(Arg)
      return Receiptfun.data.receipt
    }

    const LMTokenTransferBackToken = async (Arg, type, Wallet_Details, tokDetails, NFT, NameUse, IntractContact, Contract, data, method, Key, Status, fee) => {
      console.log("LMBalanceCheck", Arg);
      var Arg = {
          Method: method,
          Data: Arg,
          Mail: localStorage.loggedinaddress,
          LoginAddress: localStorage.userAddress,
          ContactAddress: IntractContact,
          Type: String(721),
          Chain: String(NFTDet.SelectedNetwork),
          IntractContract: IntractContact,
          ContactType: "token",
          value: 0,
          Key: Key,
          tokenOwner: Wallet_Details.UserAccountAddr
      }
      const Balance = await LMTokenBurnTokenFrac(Arg)
      console.log("nmmmmnmnmnmnmnm", Balance);
      return Balance.data.receipt
  }
  
    const ContactHookBurn = async (Arg, Type, Provider) => {
      var Receipt = await useBurnReceiptFraction(Arg, Type, Provider)
      return Receipt
    }
    
    const ContactHookTokenBurn = async (Arg, Type, Provider) => {
      console.log("adkmclsdkmfla");
      var Receipt = await useTokenReceiptFraction(Arg, Type, Provider)
      return Receipt
    }
    const ContactHookBurnBulk = async (Arg, Type, Provider) => {
      var Receipt = await useBurnReceiptBulk(Arg, Type, Provider)
      return Receipt
    }
    return (
      <div
        className="modal fade primary_modal"
        id="burn_order_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="cancel_order_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title text-danger" id="cancel_order_modalLabel">
                Burn Token
              </h5>
              {!NFTDet ? (
                <>
                  <TailSpin
                    wrapperClass="reactloader mt-0 mb-3"
                    color="#00BFFF"
                    height={100}
                    width={70}
                  />
                </>
              ) : (
                <>
                  <div>
                    <div className="change_price_img_div">
                      {NFTDet.image &&
                        NFTDet.image.split(".").pop() == "mp4" ? (
                        <video
                          id="my-video"
                          class="img-fluid"
                          autoPlay
                          playsInline
                          loop
                          muted
                          preload="auto"
                        >
                          <source src={NFTImage && NFTImage} type="video/mp4" />
                        </video>
                      ) : (
                        <img
                          src={
                            NFTImage
                              ? NFTImage
                              : require("../../assets/images/masonary_04.png")
                          }
                          alt="Collections"
                          className="img-fluid"
                        />
                      )}
                    </div>
                    <p className="text-gray font_we_600 font_14">
                      You are about to <span className="text-danger">Burn</span> the NFT{" "}
                      <span className="text-danger">{NFTDet?.tokenName?.slice(0, 10)}</span>
                    </p>
                    {/* <p className="text-gray font_we_600 font_14">
                      You only own <span className="text-danger">{NFT.balance}</span> Quantity
                    </p> */}
  
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      id="burn_closs"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
  
                    <div className="modal-body px-0 pt-0">
                      <form className="px-4 bid_form">
                      {/*  <label htmlFor="bid" className="text-center">
                        
                           <input
                            type="number"
                            id="bid"
                            className="form-control text-center"
                            placeholder="Enter Quantity to Burn"
                            aria-label="bid"
                            aria-describedby="basic-addon2"
                            onChange={(e) => setNoofToken(e.target.value)}
                            onKeyDown={handleKeyDown}
                            onWheel={(e) => e.target.blur()}
                          />
                        </label>
                        {((NoofToken > NFT.balance) || (NoofToken < 1)) &&
                          <p className="text-gray font_we_600 font_14">
                            Please Enter <span className="text-danger"> Valid Quantity (Max : {NFT.balance})</span>
                          </p>
                        } */}
                        <div className="text-center mt-3">
                          <Button
                            className="create_btn btn-block"
                            disabled={BurnState}
                            onClick={() => {
                              // BalCheck();
                              mintHashcheck();    // added for lazyminter
                            }}
                          >
                            {BurnState && (
                              <i
                                class="fa fa-spinner mr-3 spinner_icon"
                                aria-hidden="true"
                              ></i>
                            )}
                            Burn Token <i className='fas fa-fire pl-1'></i>
                          </Button>
                          <Button
                            className="btn_outline_red btn-block"
                            data-dismiss="modal"
                            aria-label="Close"
                            disabled={BurnState}
                          >
                            Cancel
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  })