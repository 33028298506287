import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { ImageValidation, EventsValidation } from "actions/controller/validation";
import config from 'lib/config'
import isEmpty from "lib/isEmpty";
import { getAllCounts, UserpanelSub, ClaimRequestFiat, CheckNfts } from "../actions/v1/token"
import { EditProfiledata, GetProfiledata } from 'actions/controller/userControl'
import { DomainNameValidate } from "actions/v1/token";
import { toast, Slide, Zoom, Flip, Bounce } from "react-toastify";
import {
    DataGrid,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

import { CKEditor } from "ckeditor4-react";
import ReactHTMLParser from "react-html-parser";
import Select from "react-select";
import { EventListNft } from "actions/controller/tokenControl";
import polygon_violet_lighttheme from '../assets/images/lazymintingnew/polygon_violet_lighttheme.png'
import { Account_Connect, Account_disConnect } from "actions/redux/action";
import { GetFiatRevenueData, Eventpublish, DeleteEvent, CampaignTopBox } from "../actions/v1/token"
import Preview from "./Preview"
import moment from "moment"
import dummy from "../assets/images/faces/sublogo.png"
toast.configure();
// import ReactSummernote from "react-summernote";
// import "react-summernote/dist/react-summernote.css"; // import styles

// Scroll to Top
function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return null;
}

function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (

        <Pagination
            color="primary"
            shape="rounded"
            page={page + 1}
            count={pageCount}
            renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
        />
    );
}

function customCheckbox(theme) {
    return {
        '& .MuiCheckbox-root svg': {
            width: 16,
            height: 16,
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.mode === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'
                }`,
            borderRadius: 2,
        },
        '& .MuiCheckbox-root svg path': {
            display: 'none',
        },
        '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
            backgroundColor: '#e50914',
            borderColor: '#e50914',
        },
        '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
            position: 'absolute',
            display: 'table',
            border: '2px solid #fff',
            borderTop: 0,
            borderLeft: 0,
            transform: 'rotate(45deg) translate(-50%,-50%)',
            opacity: 1,
            transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
            content: '""',
            top: '50%',
            left: '39%',
            width: 5.71428571,
            height: 9.14285714,
        },
        '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
            width: 8,
            height: 8,
            backgroundColor: '#62626214',
            transform: 'none',
            top: '39%',
            border: 0,
        },

    };
}

const Events = (props) => {
    const Wallet_Details = useSelector((state) => state.wallet_connect_context);
    const dashboardRoutes = [];
    const dispatch = useDispatch();
    const useStyles = makeStyles(styles);
    const classes = useStyles();
    const history = useHistory()
    const [counts, Setcounts] = useState([])
    const [SubList, SetSubList] = useState([])
    const [TokenIds, SetTokenIds] = useState()
    const [FaitRv, SetFiatRv] = useState([])
    const [TopBoxData, SetTopBoxData] = useState([])
    const [Royalty, SetRoyalty] = useState(0)
    const [Price, SetPrice] = useState(0)
    const [TotalAmount, SetTotalAmount] = useState(0)
    const [PaypalMailID, SetPaypalMailID] = useState("")
    const [ClaimAmount, SetClaimAmount] = useState(0)
    const [ValidateErr, SetValidateErr] = useState({})
    const [YourRequest, SetYourRequest] = useState([])
    const [TotalUserClaimed, SetTotalUserClaimed] = useState()
    const [Balance, SetBalance] = useState()

    const stylesgraybg = {
        option: (styles, { isFocused, isSelected, isHovered }) => ({
            ...styles,
            color: "#fff",
            background: isFocused ? "#000" : isSelected ? "#000" : isHovered ? "red" : "#000",

            zIndex: 1,
            cursor: "pointer",
            fontSize: "13px"
        }),

        option: (styles, { isFocused, isSelected, isHovered }) => {
            // const color = chroma(data.color);


            return {
                ...styles,
                backgroundColor: isHovered ? "#000" : isSelected ? "#000" : isFocused ? "#00" : "",
                color: isHovered ? "#e50914" : isSelected ? "#fff" : isFocused ? "#e50914" : "",
                cursor: "pointer",
                fontSize: "13px"

            };
        },
        valueContainer: (provided, state) => ({
            ...provided,
            height: "44px",
            padding: "0 20px",
            backgroundColor: "#000 ",
            border: "1px solid grey",
            borderRadius: "10px",
            fontSize: "13px"


        }),
        control: (provided, state) => ({
            ...provided,
            height: "44px",
            borderRadius: "10px",
            backgroundColor: "#000",
            border: "none",
            outline: "none",
            boxShadow: "none"

        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: "44px",
            position: "absolute",
            right: 0,
            top: 0,
            color: "#fff",


        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#fff",

        }),
        menuList: (base) => ({
            ...base,
            // kill the white space on first and last option
            padding: 0,
            color: "#fff",
        }),
    };

    const rows = FaitRv.length > 0 && FaitRv.map((item, index) => {
        console.log("dfasdadasdas", item);
        return ({ id: index + 1, WalletAddress: item.Address, tokenID: item.tokenID, NFT_Purcase_Price: item.NFTPrice, Royalties: item.RoyaltyAmount + `%`, Your_Split_Price_Percent: item.SplitAmount, Your_Royalty_Percent: item.YourRoyaltyPercent, YouSplitPercent: item.YouSplitPercent, Date: moment(item.Date).format('D MMMM YYYY') })
    })

    const columns = [
        { field: 'id', headerName: 'ID', width: 10 },
        {
            field: 'WalletAddress',
            headerName: 'Wallet Address',
            width: 170,
            editable: false,
            sortable: false,
        },
        {
            field: 'tokenID',
            headerName: 'TokenID',
            width: 170,
            editable: false,
            sortable: false,
        },
        {
            field: 'NFT_Purcase_Price',
            headerName: 'NFT Price',
            width: 240,
            editable: false,
            sortable: false,
        },
        {
            field: 'Royalties',
            headerName: 'Royalties',
            width: 150,
            editable: false,
            sortable: false,
        },
        {
            field: 'Your_Split_Price_Percent',
            headerName: 'Your Split Price Percent',
            width: 200,
            editable: false,
            sortable: false,
        },
        {
            field: 'Your_Royalty_Percent',
            headerName: 'Your Royalty Percent',
            width: 200,
            editable: false,
            sortable: false,
        },
        {
            field: 'YouSplitPercent',
            headerName: 'YouSplitPercent',
            width: 200,
            editable: false,
            sortable: false,
        },
        {
            field: 'Date',
            headerName: 'Created Date',
            width: 150,
            editable: false,
            sortable: false,

        },

    ];

    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
        border: 0,
        color:
            theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        WebkitFontSmoothing: 'auto',
        letterSpacing: 'normal',
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
        },
        '& .MuiDataGrid-iconSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            borderRight: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
                }`,
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
                }`,
        },
        '& .MuiDataGrid-cell': {
            color:
                theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
        },
        '& .MuiPaginationItem-root': {
            borderRadius: 0,
        },
        '& .MuiDataGrid-footerContainer': {
            display: "flex",
            justifyContent: "center"
        },

        ...customCheckbox(theme),
    }));

    const rows1 = YourRequest.length > 0 && YourRequest.map((item, index) => {
        console.log("Your Request Row", item);
        return ({ id: index + 1, WalletAddress: item.Address, Requested_Amount: item.ClaimAmount, Status: item.Status, Your_Paypal_ID: item.paypalId, Date: moment(item.Date).format('D MMMM YYYY'), Updated_Date: moment(item.UpdatedDate).format('D MMMM YYYY') })
    })

    const columns1 = [
        { field: 'id', headerName: 'ID', width: 10 },
        {
            field: 'WalletAddress',
            headerName: 'Wallet Address',
            width: 170,
            editable: false,
            sortable: false,
        },
        {
            field: 'Requested_Amount',
            headerName: 'Requested Amount',
            width: 170,
            editable: false,
            sortable: false,
        },
        {
            field: 'Status',
            headerName: 'Your Application Status',
            width: 240,
            editable: false,
            sortable: false,
        },
        {
            field: 'Your_Paypal_ID',
            headerName: 'Your Paypal ID',
            width: 150,
            editable: false,
            sortable: false,
        },
        {
            field: 'Date',
            headerName: 'Date',
            width: 200,
            editable: false,
            sortable: false,
        },
        {
            field: 'Updated_Date',
            headerName: 'Updated Date',
            width: 200,
            editable: false,
            sortable: false,
        },
    ];


    useEffect(() => {
        getCounts()
        SubcriberList()
    }, [Wallet_Details.UserAccountAddr])

    useEffect(() => {
        GetFaitReveueDetails()

    }, [Wallet_Details.UserAccountAddr, Wallet_Details.StateChange])


    async function getCounts() {
        if (Wallet_Details.UserAccountAddr) {
            var data = {
                curradd: Wallet_Details.UserAccountAddr
            }
            var counts = await getAllCounts(data)

            if (counts) {
                Setcounts(counts.data.data)
            }
            console.log("sdfbgsfdhb", counts.data.data);
        }
    }

    const SubcriberList = async () => {
        var Addresss = {
            Address: Wallet_Details.UserAccountAddr
        }
        var SubscriberList = await UserpanelSub(Addresss)
        console.log("qweqweqweSubscriberList", SubscriberList);
        if (SubscriberList?.data?.data?.Message == "success") {
            SetSubList(SubscriberList?.data?.data?.userValue)
        }
    }

    const GetFaitReveueDetails = async () => {
        console.log("Wallet_Details.UserAccountAddr", Wallet_Details.UserAccountAddr);
        if (Wallet_Details.UserAccountAddr) {
            var SendData = {
                curraddress: Wallet_Details.UserAccountAddr
            }
            console.log("SendData", SendData);
            var Details = await GetFiatRevenueData(SendData)
            console.log("SendDataDetailsreturn", Details);
            if (Details?.data?.Message?.length > 0) {
                SetFiatRv(Details?.data?.Message)
            }
        }
    }

    useEffect(() => {
        TopBox()
    }, [Wallet_Details.UserAccountAddr])

    const TopBox = async () => {
        var sendData = {
            EventCreator: Wallet_Details.UserAccountAddr
        }
        var Boxs = await CampaignTopBox(sendData)
        console.log("sdadeweqweq", Boxs);
        if (Boxs?.data?.sucess) {
            SetTopBoxData(Boxs?.data?.Data)
        }
    }

    useEffect(() => {
        RoyaltyAndPrice()
        GetYourRequest()
    }, [FaitRv, Wallet_Details.UserAccountAddr])

    const RoyaltyAndPrice = async () => {
        console.log("Comming inside RoyaltyAndPrie", FaitRv);
        if (FaitRv.length > 0) {
            var MapAndReduceRoyalty = FaitRv.map((item, index) => {
                console.log("item inside Map", item);
                var TotalRoyalty = Number(item.YourRoyaltyPercent)
                return TotalRoyalty
            }).reduce((partialSum, a) => Number(partialSum) + Number(a), 0)
            console.log("MapAndReduce", MapAndReduceRoyalty);
            var MapAndReducePrice = FaitRv.map((item, index) => {
                console.log("item inside Map", item)
                var TotalPrice = Number(item.YouSplitPercent)
                return TotalPrice
            }).reduce((partialSum, a) => Number(partialSum) + Number(a), 0)
            console.log("MapAndReducePrice", MapAndReducePrice);

            var TotalAmount = MapAndReducePrice + MapAndReduceRoyalty
            var RequestPending = YourRequest.map((item, index) => {
                console.log("RequestCompletedcc", item);
                return (item.Status == "Pending") ? Number(item.ClaimAmount) : 0
            }).reduce((partialSum, a) => Number(partialSum) + Number(a), 0)
            var BalanceClaimed = TotalAmount - RequestPending
            var Completed = YourRequest.map((item, index) => {
                console.log("RequestCompletedcc", item);
                return (item.Status == "completed") ? Number(item.ClaimAmount) : 0
            }).reduce((partialSum, a) => Number(partialSum) + Number(a), 0)
            var BalanceLeftToClaim = TotalAmount - Completed
            SetRoyalty(Number(MapAndReduceRoyalty).toFixed(2))
            SetPrice(Number(MapAndReducePrice).toFixed(2))
            SetTotalAmount(Number(TotalAmount).toFixed(2))
            SetBalance(Number(BalanceLeftToClaim).toFixed(2))
            SetTotalUserClaimed(Number(BalanceClaimed).toFixed(2))
        }
    }

    const ClaimYourAmount = async () => {
        if (Wallet_Details.UserAccountAddr) {
            var SendData = {
                PaypalMail: PaypalMailID,
                ClaimAmount: ClaimAmount,
                Address: Wallet_Details.UserAccountAddr,
                FindData: "Add"
            }
            var checkValid = await ValidationsCheck(SendData)
            console.log("checkValid", checkValid, isEmpty(checkValid));
            if (isEmpty(checkValid)) {
                var ClaimRequest = await ClaimRequestFiat(SendData)
                console.log("ClaimRequest", ClaimRequest);
                if (ClaimRequest?.data?.Success) {
                    console.log("asdasndfjasnfjs");
                    toast.success(ClaimRequest?.data?.Message)
                } else {
                    console.log("asdasdasdasdasd", ClaimRequest?.data?.Message);
                    //toast.error("Validation Failed")
                    toast.error(ClaimRequest?.data?.Message)
                }
            } else {
                SetValidateErr(checkValid)
                toast.error("Validation Failed")
            }
        }
    }

    const ValidationsCheck = async (data) => {
        console.log("i/p for validaion", data)

        var validationErr = {};
        let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-zA-Z\-0-9]+\.)+[A-Za-zA-Z]{2,}))$/;
        if (data.PaypalMail == "") { validationErr.PaypalMail = "Email cannot be empty" }
        else if (data.PaypalMail != "") {
            if (!emailRegex.test(data.PaypalMail)) { validationErr.PaypalMail = "Enter valid email" }
        }
        if (data.ClaimAmount == "") { validationErr.ClaimAmount = "Amount cannot be empty" }
        if (data.ClaimAmount < 1) { validationErr.ClaimAmount = "Your Claim Amount Should be Greater than 100$" }
        if (data.ClaimAmount >= Balance) { validationErr.ClaimAmount = "Entered Amount is Greater than your Holdings" }
        if (String(data.ClaimAmount).includes('.')) { validationErr.ClaimAmount = "Claim Amount Must be a whole Number" }
        return validationErr;


    }

    const GetYourRequest = async (data) => {
        if (Wallet_Details.UserAccountAddr) {
            var SendData = {
                Address: Wallet_Details.UserAccountAddr,
                FindData: "Get"
            }
            var ClaimRequest = await ClaimRequestFiat(SendData)
            console.log("Your requests", ClaimRequest.data.Details);
            if (ClaimRequest.data.Details.length > 0) {
                SetYourRequest(ClaimRequest.data.Details)
            }
        }
    }


    return (


        <>
            {console.log("Adjasdcjkdsaca", Wallet_Details)}

            <div className='scrolling_move_sec_align'>
                <div className="topcardsecs">
                    <div>
                        <div className='card blackcard_style mb-2'>
                            <div className='firstrow'>
                                <div><i class="fa fa-circle admint_nrm_round admin_green_round mb-2" /></div>
                                <div className='div_text'>Royalty</div>
                            </div>
                            <div className='graph_align'>
                                <div className='count_dtls'>
                                    <span className='count_txt'>$ {Royalty}</span><br />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='card blackcard_style mb-2'>
                            <div className='firstrow'>
                                <div><i class="fa fa-circle admint_nrm_round admin_red_round mb-2"></i></div>
                                <div className='div_text'>Price</div>
                            </div>

                            <div className='graph_align'>
                                <div className='count_dtls'>
                                    <span className='count_txt'>$ {Price}</span><br />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='card blackcard_style mb-2'>
                            <div className='firstrow'>
                                <div><i class="fa fa-circle admint_nrm_round admin_sandal_round mb-2"></i></div>
                                <div className='div_text'>Total Amount</div>
                            </div>

                            <div className='graph_align'>
                                <div className='count_dtls'>
                                    <span className='count_txt'>$ {TotalAmount}</span><br />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='card blackcard_style mb-2'>
                            <div className='firstrow'>
                                <div><i class="fa fa-circle admint_nrm_round admin_violet_round mb-2"></i></div>
                                <div className='div_text'>Claimed Amount</div>
                            </div>

                            <div className='graph_align'>
                                <div className='count_dtls'>
                                    <span className='count_txt'>$ {TotalUserClaimed ? TotalUserClaimed : 0}</span><br />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='card blackcard_style mb-2'>
                            <div className='firstrow'>
                                <div><i class="fa fa-circle admint_nrm_round admin_sandal_round mb-2"></i></div>
                                <div className='div_text'>Balance</div>
                            </div>

                            <div className='graph_align'>
                                <div className='count_dtls'>
                                    <span className='count_txt'>$ {Balance ? Balance : 0}</span><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'>
                        {console.log("Details.data.Data", FaitRv)}
                        <div style={{ height: 300, width: '100%' }}>
                            <StyledDataGrid
                                // checkboxSelection
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                rows={rows}
                                columns={columns}
                                disableColumnMenu
                                components={{
                                    Pagination: CustomPagination,
                                }}
                                className='campaign_muitbl'


                            />


                        </div>
                    </div>
                    <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
                        <div className='cambaign_glazier'>
                            <img className='glazier_img_align' src={require('../assets/images/faces/mask_group.png')} alt="image"/>
                        </div>
                    </div>
                </div>

                <div className="row allrowmobile">
                    <div className="col-md-5 col-lg-4 col-sm-4 mt-2">
                        <div className="row">
                            <div className="col-md-4 mt-md-0 mt-2">
                                <span className="labelspan mb-sm-0 mb-2 mr-2">
                                    Amount
                                </span>
                            </div>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    id="Amount"
                                    className="edit_inputs_align"
                                    placeholder="Enter the amount*"
                                    name="Amount"
                                    //value={Formdata.campaignname}
                                    onChange={(e) => SetClaimAmount(e.target.value)}
                                />
                                <br />
                                {ValidateErr && ValidateErr.ClaimAmount &&
                                    <span className="validation_txt text-center">{ValidateErr && ValidateErr.ClaimAmount}</span>
                                }
                            </div>
                        </div>

                    </div>
                    <div className="col-md-5 col-lg-6 col-sm-5  mt-2">
                        <div className="row">
                            <div className="col-md-4 mt-md-0 mt-2">
                                <span className="labelspan mb-sm-0 mb-2 mr-2">

                                    Paypal Mail Id
                                </span>
                            </div>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    id="Mail"
                                    className="edit_inputs_align"
                                    placeholder="Enter the Paypal Mail ID*"
                                    name="Mail"
                                    //value={Formdata.campaignname}
                                    onChange={(e) => SetPaypalMailID(e.target.value)}
                                />
                                <br />
                                {ValidateErr && ValidateErr.PaypalMail &&
                                    <span className="validation_txt text-center">{ValidateErr && ValidateErr.PaypalMail}</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-3  mt-2">
                        <button
                            className="graybtn confirmbtn btn w-100 mr-2 mb-2"
                            onClick={(e) => ClaimYourAmount()}
                        >
                            Confirm
                        </button>
                    </div>
                </div>

                {/* <div className='row pl-4 mt-3'>
                    <div className="d-sm-flex campaignmdl_inps">
                        <span className="labelspan mb-sm-0 mb-2 mr-2">
                            Enter Amount
                        </span>
                        <input
                            type="text"
                            id="Amount"
                            className="edit_inputs_align"
                            placeholder="Enter the amount*"
                            name="Amount"
                            //value={Formdata.campaignname}
                            onChange={(e) => SetClaimAmount(e.target.value)}
                        />
                        <br />
                        {ValidateErr && ValidateErr.ClaimAmount &&
                            <span className="validation_txt text-center">{ValidateErr && ValidateErr.ClaimAmount}</span>
                        }
                    </div>

                    <div className="d-sm-flex ml-3 campaignmdl_inps">
                        <span className="labelspan mb-sm-0 mb-2 mr-2">
                            Enter You Paypal Mail Id
                        </span>
                        <input
                            type="text"
                            id="Mail"
                            className="edit_inputs_align"
                            placeholder="Enter the Paypal Mail ID*"
                            name="Mail"
                            //value={Formdata.campaignname}
                            onChange={(e) => SetPaypalMailID(e.target.value)}
                        />
                        <br />
                        {ValidateErr && ValidateErr.PaypalMail &&
                            <span className="validation_txt text-center">{ValidateErr && ValidateErr.PaypalMail}</span>
                        }

                    </div>

                    <button
                        className="graybtn btn ml-3 mr-2 mb-2"
                        onClick={(e) => ClaimYourAmount()}
                    >
                        Confirm
                    </button>
               
                </div> */}

                {/* <button
                        className="graybtn btn mr-2 mb-2"
                        onClick={() => SaveNft()}
                    >

                        Confirm
                    </button> */}

                <div className='row mt-3'>
                    <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                        {console.log("Details.data.Data", FaitRv)}
                        <div style={{ height: 300, width: '100%' }}>
                            <StyledDataGrid
                                // checkboxSelection
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                rows={rows1}
                                columns={columns1}
                                disableColumnMenu
                                components={{
                                    Pagination: CustomPagination,
                                }}
                                className='campaign_muitbl'


                            />


                        </div>
                    </div>
                    {/* <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
                        <div className='cambaign_glazier'>
                            <img className='glazier_img_align' src={require('../assets/images/faces/mask_group.png')} />
                        </div>
                    </div> */}
                </div>

            </div>
        </>



    )
}

export default Events

