import Header from 'components/Header/Header'
import React, { useEffect } from 'react'
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Footer from "components/Footer/Footer.js";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import HeaderLinks from "components/Header/HeaderLinks.js";
import styled from "../../node_modules/styled-components";
import { Link } from "react-router-dom";

import AOS from 'aos';
import 'aos/dist/aos.css';

const Icon = styled(props => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #a30726;
    font-size: 24px;
  }
  & > .minus {
    display: none;
    color: #a30726;
    font-size: 24px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;
const dashboardRoutes = [];
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

function PricingPage(props) {

  const learnscroll = () => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    document.getElementById("learnmore_nav").scrollIntoView()

  }




  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };


  useEffect(() => {
    AOS.init();
  }, [])

  const { ...rest } = props;
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const responsive = {
    0: {
      items: 1,
    },
    424: {
      items: 1,
    },
    576: {
      items: 2,
    },
    768: {
      items: 4,
    },
    1200: {
      items: 4,
    },

  }


  return (
    <>
      {/* <Header/> */}
      <div className="home_header home header_bar_align">
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand={<Link to="/home"><div className="header_logo_align">


          </div></Link>}
          leftLinks={<HeaderLinks />}
          changeColorOnScroll={{
            height: 50,
            color: "dark"
          }}
          {...rest}
        />
        <ScrollToTopOnMount />
        <div className=' container pricing_home_banner_align'>
          <GridContainer>
            <GridItem className='mb-3' xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className='pricing_home_banner'>
                <h1 className='pricebanner_title_text_align heading_blue_txt playfair_txt' data-aos="fade-up">Distribute Certificates & Tickets easily on Blockchain</h1>
                <p className='pricebanner_title_hint_align gray_text'>Whether you are a sole-proprietor or a company, distributing certificates, launch a loyalty program or sell event tickets is    simple and easy with a few clicks on lazyminter.</p>
                <div className='pricinghome_banner_btn_align'>
                  <Link to='/pricing'><button className='pricinghome_banner_btn_first'>Try It Free</button></Link>

                  <button onClick={learnscroll} className='pricinghome_banner_btn'>Learn More</button>

                </div>
                <img className='pricebanner_bluedot_img_align' src={require('../assets/images/dots-blue.png')} />
              </div>
            </GridItem>
            <GridItem xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className='w-100 pricebanner_img_align_dtls'>
                <img className='img-fluid pricebanner_main_img_align' src={require('../assets/images/banner_pricinghome.png')} />
                <div className='anim_blue_round' data-aos="zoom-in"></div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className='container'>

          <div id='learnmore_nav'></div>
          <GridContainer>
            <GridItem xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
              <OwlCarousel className='owl-theme owl_carousal_align' autoplay={true} responsive={responsive} nav={false} loop margin={0} dots={true}>
                <div class='item owl_item_align'>
                  <div>
                    <img className='pricinghome_img_align' src={require('../assets/images/faces/atgroup.png')} />
                  </div>
                </div>
                <div class='item owl_item_align'>
                  <div>
                    <img className='pricinghome_img_align' src={require('../assets/images/faces/ESG.png')} />
                  </div>
                </div>
                <div class='item owl_item_align'>
                  <div>
                    <img className='pricinghome_img_align' src={require('../assets/images/faces/Jeep.png')} />
                  </div>
                </div>
                <div class='item owl_item_align'>
                  <div>
                    <img className='pricinghome_img_align newton_img' src={require('../assets/images/faces/newton.png')} />
                  </div>
                </div>
                <div class='item owl_item_align'>
                  <div>
                    <img className='pricinghome_img_align' src={require('../assets/images/faces/trio.png')} />
                  </div>
                </div>
                <div class='item owl_item_align'>
                  <div>
                    <img className='pricinghome_img_align' src={require('../assets/images/faces/w3l.png')} />
                  </div>
                </div>


              </OwlCarousel>
            </GridItem>
          </GridContainer>

        </div>

        <div className='container mt-5' >
          <GridContainer >
            <div className='solution_txt_align' >
              <h1 className='solution_title_text_align heading_blue_txt playfair_txt' data-aos="fade-up">What Are LazyMinter BlockChain Solution?</h1>
              <p className='solution_hint_text_align gray_text'>LazyMinter enables minting and distribution of certificates, tickets or other authentication proofs on blockchain easily, with the use of tools you are familiar (excel or images) or selected APIs</p>
            </div>
            <GridContainer className='mt-5'>
              <GridItem className='mb-3' xxl={4} xl={4} lg={4} md={6} sm={6} xs={12}>
                <div className='solution_options_align'>
                  <img className='solution_images_align' src={require('../assets/images/solution_man.png')} />
                  <h5 className='solution_options_head_align heading_blue_txt'>1-1 Engagement</h5>
                  <p className='text-center solution_options_hint_align gray_text'>Engage your customer one-on-one for new promotions and deals using both emails and wallets as communication passes</p>

                </div>
              </GridItem>

              <GridItem className='mb-3' xxl={4} xl={4} lg={4} md={6} sm={6} xs={12}>
                <div className='solution_options_align'>
                  <img className='solution_images_align' src={require('../assets/images/solution_clock.png')} />
                  <h5 className='solution_options_head_align heading_blue_txt'>Bulk Automation</h5>
                  <p className='text-center solution_options_hint_align gray_text'>From preparing, minting to distribution to your customers, all done seamlessly and easily</p>

                </div>
              </GridItem>

              <GridItem className='mb-3' xxl={4} xl={4} lg={4} md={6} sm={6} xs={12}>
                <div className='solution_options_align'>
                  <img className='solution_images_align' src={require('../assets/images/solution_bulb.png')} />
                  <h5 className='solution_options_head_align heading_blue_txt'>New Features</h5>
                  <p className='text-center solution_options_hint_align gray_text'>LazyMinter is committed to continuing introducing innovative features that is relevant and useful to our customers</p>

                </div>
              </GridItem>
            </GridContainer>
            <GridContainer className='mt-5'>

              <GridItem className='mb-3' xxl={4} xl={4} lg={4} md={6} sm={6} xs={12}>
                <div className='solution_options_align'>
                  <img className='solution_images_align' src={require('../assets/images/solution_pad.png')} />
                  <h5 className='solution_options_head_align heading_blue_txt'>Immutable Records</h5>
                  <p className='text-center solution_options_hint_align gray_text'>Battle-tested blockchain that will ensure records are not easy to be tampered with</p>

                </div>
              </GridItem>

              <GridItem className='mb-3' xxl={4} xl={4} lg={4} md={6} sm={6} xs={12}>
                <div className='solution_options_align'>
                  <img className='solution_images_align' src={require('../assets/images/solution_cup.png')} />
                  <h5 className='solution_options_head_align heading_blue_txt'>Rewards & Performance</h5>
                  <p className='text-center solution_options_hint_align gray_text'>A turnkey loyalty program for you to launch for your customers without complex technical infrastructure – we handle the rest</p>

                </div>
              </GridItem>

              <GridItem className='mb-3' xxl={4} xl={4} lg={4} md={6} sm={6} xs={12}>
                <div className='solution_options_align'>
                  <img className='solution_images_align' src={require('../assets/images/solution_setting.png')} />
                  <h5 className='solution_options_head_align heading_blue_txt'>Secured</h5>
                  <p className='text-center solution_options_hint_align gray_text'>All our data is stored either in decentralized network or in premium grade secured server on AWS or Google Cloud</p>

                </div>
              </GridItem>

            </GridContainer>

          </GridContainer>
        </div>

        <div className='container mt-5'>
          <GridContainer>
            <GridItem xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
              <h1 className='solution_title_text_align heading_blue_txt playfair_txt' data-aos="fade-up">The Best Features of LazyMinter</h1>
              <p className='pricing_features_text_align gray_text'>Launch your web3.0 blockchain or digital collectibles project easily without knowledge of web3.0.  Easy onboarding for your customers from web2.0 space to sign up for an web3.0 account and create a wallet.  </p>

              <GridContainer className='mt-5'>
                <GridItem className='mb-3' xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                  <img className='solution_images_align' src={require('../assets/images/solution_aim.png')} />
                  <h5 className='solution_options_head_align heading_blue_txt'>Acquire Accurately</h5>
                  <p className=' solution_options_hint_align gray_text'>Acquire and distribute your certificates or gifts to your customers’ wallets accurately</p>

                </GridItem>


                <GridItem className='mb-3' xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                  <img className='solution_images_align' src={require('../assets/images/solution_hand.png')} />
                  <h5 className='solution_options_head_align heading_blue_txt'>Retain Loyalty</h5>
                  <p className=' solution_options_hint_align gray_text'>Retain your customers or fans with new engagement tools in the community space</p>

                </GridItem>
                <GridItem className='mb-3' xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                  <img className='solution_images_align' src={require('../assets/images/solution_uphand.png')} />
                  <h5 className='solution_options_head_align heading_blue_txt'>Scale Affordably</h5>
                  <p className=' solution_options_hint_align gray_text'>Charging by number of certificates, collectibles, cards or tickets distributed with efficient pricing at larger quantities</p>

                </GridItem>

                <GridItem className='mb-3' xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                  <img className='solution_images_align' src={require('../assets/images/solution_bulb2.png')} />
                  <h5 className='solution_options_head_align heading_blue_txt'>Compact</h5>
                  <p className=' solution_options_hint_align gray_text'>New innovative features added every 3 weeks for you to test and trial if you subscribed as a paying customer</p>

                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className='best_ftr_dtls'>
                <img className='img-fluid timeshare_img' src={require('../assets/images/timeshare.png')} />
                <div className='anim_small_blue_round' data-aos="zoom-in"></div>
                <img className=' girlcall_img' data-aos="fade-left" src={require('../assets/images/faces/girlcall.png')} />

              </div>
            </GridItem>
          </GridContainer>
        </div>

        <div className='container mt-5 '>
          <GridContainer className='justify-content-center'>
            <div className='pricing_pkatform_dtls_align '>
              <GridItem md={8}>
                <h1 className='pricing_platform_title_align solution_title_text_align playfair_txt heading_blue_txt' data-aos="fade-up">One Platform That You Can Do Everything</h1>
                <button onClick={() => openInNewTab('https://scribehow.com/page-embed/LazyMinter_Onboarding_Manual__9nYdqH49Q0C3iX-bWOPiww')} className='pricing_video_btn_align'>

                  Onboarding Manual</button>
              </GridItem>
            </div>
            <div className='pricing_home_bannerimg_align'>
              <img className=' pricing_home_banner' src={require('../assets/images/pricing_big_banner.png')} />
              <img className='img-fluid boy_usingmbl' data-aos="fade-right" src={require('../assets/images/boy_usingmbg.png')} />
              <img className='img-fluid girl_usinglap' data-aos="fade-left" src={require('../assets/images/girl_usinglap.png')} />
            </div>

          </GridContainer>
        </div>


        <div className='container prcing_review_dtls_align'>
          <GridContainer>
            <GridItem xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
              <div className='pricing_dtls_left_align'>
                <img className='img-fluid quote_img_align' src={require('../assets/images/double_quote.png')} />
                <h1 className='solution_title_text_align heading_blue_txt playfair_txt' data-aos="fade-up">What People Says About Us</h1>

              </div>
            </GridItem>

            <GridItem xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
              <div className='pricing_lying_img_aling'>
                <img className='img-fluid' data-aos="zoom-in" src={require('../assets/images/girl_lying.png')} />
              </div>
            </GridItem>
          </GridContainer>
        </div>

        <div className='container mt-4'>
          <GridContainer>
            <GridItem xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
              <div className='reviewing_dtls_card_align'>

                <p className='pricing_features_text_align mt-3'>Patrick and team knowledge of AI and blockchain was invaluable in guiding our team to a successful project.  Highly recommend their services</p>
                <h5 className='pricing_reviewer_name sub_blue_text'>David</h5>
                <p className='pricing_features_text_align gray_text'>CEO,Dways & Iris</p>
              </div>
            </GridItem>

            <GridItem xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
              <div className='reviewing_dtls_card_align'>

                <p className='pricing_features_text_align mt-3'>JEPP incorporates both RFID and blockchain insights were invaluable in improving our farming operations and increasing profits.</p>
                <h5 className='pricing_reviewer_name sub_blue_text'>Khun Ed</h5>
                <p className='pricing_features_text_align gray_text'>Owner,Trio Farm</p>
              </div>
            </GridItem>

            <GridItem xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
              <div className='reviewing_dtls_card_align'>

                <p className='pricing_features_text_align mt-3'>Clairvoyant Lab Team patiently guided us in using LazyMinter platform to distribute membership tokens, resulting in improved event participation.</p>
                <h5 className='pricing_reviewer_name sub_blue_text'>Presley</h5>
                <p className='pricing_features_text_align gray_text'>Executive Director</p>
              </div>
            </GridItem>
          </GridContainer>
        </div>

        <div className='container customer_journey_dtls_align'>
          <GridContainer>
            <div className='pricing_cusjourney_dtls_align'>
              <h1 className='solution_title_text_align heading_blue_txt playfair_txt' data-aos="fade-up">Create The Right <br /> Customer Journey</h1>
              <p className='pricing_features_text_align'>Map your journey and use LazyMinter to jump start your web3.0 journey now</p>



              <button onClick={() => openInNewTab('https://scribehow.com/page-embed/LazyMinter_Onboarding_Manual__9nYdqH49Q0C3iX-bWOPiww')} className='pricing_freetial_btn_align'>Onboarding Manual</button>


              <img className='img-fluid pricing_selfie_lady_align' data-aos="fade-right" src={require('../assets/images/lazymintingnew/smallgirl.png')} />
              <img className='img-fluid pricing_study_boy_align' src={require('../assets/images/lazymintingnew/smallboy.png')} />
            </div>
          </GridContainer>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default PricingPage