import * as V1User from 'actions/v1/user';

/**
 * @function GetProfiledata
 * @description Get user profile data
 * @param {Object} payload - Payload to send to server
 * @prop {string} curraddress - User's current wallet address
 * @returns {Object} - A promise resolving to the user's profile data
 * @prop {string} photo - User's profile image URL
 * @prop {string} coverimage - User's cover image URL
 * @prop {string} Bio - User's bio
 * @prop {string} CustomUrl - User's custom URL
 * @prop {string} Twitter - User's Twitter handle
 * @prop {string} Instagram - User's Instagram handle
 * @prop {string} Facebook - User's Facebook handle
 * @prop {string} Youtube - User's YouTube handle
 * @prop {string} Name - User's name
 * @prop {string} Email - User's email
 * @prop {boolean} verifiedmail - Whether the user's email is verified
 * @prop {string} Site - User's personal website URL
 * @prop {string} curraddress - User's current wallet address
 * @prop {string} PrimaryAddr - User's primary wallet address
 * @prop {string} LocalAddr - User's local wallet address
 * @prop {boolean} Follow - Whether the user is followed or not
 * @prop {number} Count - Number of followers
 * @prop {string} Joined - Date user joined as a string
 */
export const GetProfiledata = async (payload) =>{
    var formdata = {}
    var Resp = await V1User.v1_GetProfile(payload)
    if(Resp.data.Success)
    {
        formdata['photo'] = Resp.data.UserDet.image || "";
        formdata['coverimage'] = Resp.data.UserDet.coverimage || "";
        formdata['Bio'] = Resp.data.UserDet.bio || "";
        formdata['CustomUrl'] = Resp.data.UserDet.customurl || "";
        formdata['Twitter'] = Resp.data.UserDet.twitter || "";
        formdata['Instagram'] = Resp.data.UserDet.instagram || "";
        formdata['Facebook'] = Resp.data.UserDet.facebook || "";
        formdata['Youtube'] = Resp.data.UserDet.youtube || "";
        formdata['Name'] = Resp.data.UserDet.name || "";
        formdata['Email'] = Resp.data.UserDet.email || "";
        formdata['verifiedmail'] = Resp.data.UserDet.verifiedmail || "";
        formdata['Site'] = Resp.data.UserDet.personalsite || "";
        formdata['curraddress'] = Resp.data.UserDet.curraddress || "";
        formdata['PrimaryAddr'] = Resp.data.UserDet.PrimaryAddr || "";
        formdata['LocalAddr'] = Resp.data.UserDet.LocalAddr || "";
        formdata['Follow'] = (Resp.data.Follow !== false)?Resp.data.Follow:false
        formdata['Count'] = (Resp.data.Count)?Resp.data.Count:false
        formdata['Joined'] = new Date(Resp.data.UserDet.date).toLocaleDateString('en-US',{month:"long",year:"numeric"}) || "";
    }
    // console.log("bunhybvtyghhbijjmnjimn",formdata,Resp.data.Follow)
    return formdata
}

/**
 * Updates the user profile with the provided data.
 * 
 * @async
 * @function EditProfiledata
 * @param {Object} payload - The data to update the profile with.
 * @param {File} [payload.File] - The profile image file to upload.
 * @param {string} [payload.photo] - The name of the profile image.
 * @param {string} [payload.Bio] - The user's biography.
 * @param {string} [payload.Name] - The user's name.
 * @param {string} [payload.CustomUrl] - The user's custom URL.
 * @param {string} [payload.Email] - The user's email address.
 * @param {string} [payload.curraddress] - The user's current wallet address.
 * @param {string} [payload.Facebook] - The user's Facebook handle.
 * @param {string} [payload.Twitter] - The user's Twitter handle.
 * @param {string} [payload.Instagram] - The user's Instagram handle.
 * @param {string} [payload.Youtube] - The user's YouTube handle.
 * @param {string} [payload.Site] - The user's personal website URL.
 * @returns {Object} - A promise that resolves to the server's response data.
 */
export const EditProfiledata = async (payload) => {
var formData = new FormData();
    if(payload.File) { formData.append('Image',payload.File); }
    if(payload.photo) { formData.append('imagename',payload.photo); }
    if(payload.Bio) { formData.append('Bio', payload.Bio); }
    if(payload.Name) { formData.append('name', payload.Name); }
    if(payload.CustomUrl) { formData.append('customurl', payload.CustomUrl); }
    if((payload.Email)) { formData.append('email', payload.Email); }
    if(payload.curraddress) { formData.append('curraddress', payload.curraddress); }
    if(payload.Facebook) { formData.append('facebook', payload.Facebook); }
    if(payload.Twitter) { formData.append('twitter', payload.Twitter); }
    if(payload.Instagram) { formData.append('instagram', payload.Instagram); }
    if(payload.Youtube) { formData.append('youtube', payload.Youtube); }
    if(payload.Site) { formData.append('site', payload.Site); }

    var Resp = await V1User.v1_EditProfile(formData)
    return Resp.data
}

/**
 * Updates the user's cover photo with the provided data.
 * 
 * @async
 * @function EditCoverPhoto
 * @param {Object} payload - The data to update the cover photo with.
 * @param {File} [payload.File] - The cover photo file to upload.
 * @param {string} [payload.curraddress] - The user's current wallet address.
 * @returns {Object} - A promise that resolves to the server's response data.
 */
export const EditCoverPhoto = async (payload) => {
    var formData = new FormData();
    if(payload.File) { formData.append('Image',payload.File); }
    if(payload.curraddress) { formData.append('curraddress', payload.curraddress); }
    console.log(payload)
    var Resp = await V1User.v1_EditCover(formData)
    return Resp
}

/**
 * Retrieves a user's activity, item, or favorite data.
 * 
 * @async
 * @function MyUserData
 * @param {Object} payload - The data to query with.
 * @param {string} payload.curraddress - The user's current wallet address.
 * @param {string} payload.Tab - The tab to query. One of 'activity', 'item', or 'favorite'.
 * @param {string} [payload.Action] - The action to query. Only applicable if `Tab` is 'activity'.
 * @param {string} [payload.TokenID] - The token ID to query. Only applicable if `Tab` is 'item'.
 * @param {string} [payload.Limit] - The maximum number of records to return.
 * @param {string} [payload.Page] - The page number to return.
 * @param {string} [payload.Filter] - The filter to apply to the records. Only applicable if `Tab` is 'activity'.
 * @returns {Object} - A promise that resolves to the server's response data.
 * @prop {boolean} Success - Whether the query was successful.
 * @prop {string} Tab - The tab that was queried.
 * @prop {Array} records - The records returned by the query.
 */
export const MyUserData = async (payload) =>{
    var Resp = await V1User.v1_MyUSerData(payload)
    Resp = {Success:Resp.data.Resp.Success,Tab:Resp.data.Tab,records:Resp.data.Resp.records}
    return Resp
}
