import axios from "axios";
import config from "../../lib/config";
//import Multiple from "../../ABI/userContract1155.json";
//import Single from "../../ABI/userContract721.json";
import { toast } from "react-toastify";
import isEmpty from "lib/isEmpty";
import { decodedata, encodedata } from "../../hooks/useWallet";
import { AES, enc } from 'crypto-js'

export const NFTnameValidation = async (NFTname) => {
    try {
        var respdata = await axios({
            method: "get",
            url: `${config.vUrl}/Fraction/validation/namevalidate/${NFTname}`
        })
        return respdata.data
    }
    catch (e) {
        console.log("Error in NFTnameValidation Function", e)
        return respdata.data
    }
}


export const v1_ipfsImageHashGet = async (formData) => {
    try {
        var respdata = await axios({
            method: "post",
            url: `${config.vUrl}/Fraction/create/ipfsdatum`,
            data: formData,
            'headers': {
                'Authorization': localStorage.user_token
            },
        })
        respdata.data = decodedata(respdata.data);
        return respdata.data
    }
    catch (e) {
        console.log("Error in ipfsImageHashGet Function", e)
        respdata.data = decodedata(respdata.data);
        return respdata.data
    }
};

export const v1_NFTDetails2DB = async (formData) => {
    try {
        var respdata = await axios({
            method: "post",
            url: `${config.vUrl}/Fraction/add/NFT`,
            data: formData,
            'headers': {
                'Authorization': localStorage.user_token
            },
        })
        respdata.data = decodedata(respdata.data);
        return respdata.data
    }
    catch (e) {
        console.log("Error in NFTDetails2DB Function", e)
        respdata.data = decodedata(respdata.data);
        return respdata.data
    }
};

export const ExplorePage = async (payload) => {
    console.log("explore payload", payload)
    try {
        let resp = await axios({
            'method': 'get',
            'url': `${config.vUrl}/Fraction/explore/NFT`,
            params: payload
        });
        console.log()
        return {
            data: resp.data
        }
    }
    catch (err) {
        console.log("ErrorinExplorePageFunction", err)
    }
}

export const v1_NFTInfo = async (payload) => {
    try {
        let resp = await axios({
            'method': 'post',
            'url': `${config.vUrl}/Fraction/NFTInfo`,
            data: payload
        });
        console.log()
        return {
            data: resp.data
        }
    }
    catch (err) {
        console.log("Errorinv1_NFTinfoFunction", err)
    }
}

export const v1_UpdateNFTOwners = async (payload) => {
    console.log("sdcdhcdjdacds", payload);
    try {
        let resp = await axios({
            'method': 'post',
            'url': `${config.vUrl}/Fraction/UpdateNFTOwners`,
            data: payload,
            'headers': {
                'Authorization': localStorage.user_token
            },
        });
        console.log()
        return {
            data: resp.data
        }
    }
    catch (err) {
        console.log("Errorinv1_UpdateNFTOwnersFunction", err)
    }
}

export const v1_UpdateNFTState = async (payload) => {
    try {
        let resp = await axios({
            'method': 'post',
            'url': `${config.vUrl}/Fraction/UpdateNFTState`,
            data: payload,
            'headers': {
                'Authorization': localStorage.user_token
            },
        });
        console.log()
        return {
            data: resp.data
        }
    }
    catch (err) {
        console.log("Errorinv1_UpdateNFTOwnersFunction", err)
    }
}

export const v1_TransferFraction = async (payload) => {
    try {
        let resp = await axios({
            'method': 'post',
            'url': `${config.vUrl}/Fraction/TransferToken`,
            data: payload,
            'headers': {
                'Authorization': localStorage.user_token
            },
        });
        return {
            data: resp.data
        }
    }
    catch (err) {
        console.log("Errorinv1_BurnTokenFunction", err)
    }
}

export const v1_ConNFTBalCheck = async (Payload) => {
    try {
        let resp = await axios({
            'method': 'post',
            'url': `${config.vUrl}/Fraction/ConNFTBalCheck`,
            'data': Payload,
            'headers': {
                'Authorization': localStorage.user_token
            },
        });
        return {
            data: resp.data
        }
    }
    catch (err) {
        console.log("Errorinv1_ConNFTBalCheckFunction", err, Payload)
    }
}

export const FractionalDash = async (payload) => {
    console.log("explore payload", payload)
    try {
        let resp = await axios({
            'method': 'get',
            'url': `${config.vUrl}/Fraction/FractionalDash`,
            params: payload
        });
        console.log()
        return {
            data: resp.data
        }
    }
    catch (err) {
        console.log("ErrorinExplorePageFunction", err)
    }
}

export const GetOwnerList = async (payload) => {
    console.log("explore payload", payload)
    try {
        let resp = await axios({
            'method': 'get',
            'url': `${config.vUrl}/Fraction/GetOwnerList`,
            params: payload
        });
        console.log()
        return {
            data: resp.data
        }
    }
    catch (err) {
        console.log("ErrorinExplorePageFunction", err)
    }
}


export const ChangeToVote = async (payload) => {
    console.log("explore payload", payload)
    try {
        let bodyFormData = new FormData();
        if (payload.Multiplefiles) {
            if (payload.Multiplefiles.length > 0) {
                for (var i = 0; i < payload.Multiplefiles.length; i++) {
                    // bodyFormData.append('File', payload.Filestate[0]);
                    console.log("djnfksdnfksdnkds");
                    bodyFormData.append(`Multiplefiles`, payload.Multiplefiles[i])
                }
            }
        }
        bodyFormData.append('BannerImage', payload.BannerImage);
        bodyFormData.append('VoteCount', payload.VoteCount);
        bodyFormData.append('UniqueId', payload.UniqueId);
        bodyFormData.append('promote', payload.promote);
        bodyFormData.append('CreateStatus', payload.CreateStatus);
        bodyFormData.append('WalletAddress', payload.WalletAddress);
        bodyFormData.append('TokenOwner', payload.TokenOwner);
        bodyFormData.append('tokenID', payload.tokenID);
        bodyFormData.append('ShortName', payload.ShortName);
        bodyFormData.append('SellAmount', payload.SellAmount);
        bodyFormData.append('Description', payload.Description);
        bodyFormData.append('VotingStartedDate', payload.VotingStartedDate);
        bodyFormData.append('VotingEndDate', payload.VotingEndDate);
        bodyFormData.append('VotingCat', payload.VotingCat);


        let resp = await axios({
            'method': 'post',
            'url': `${config.vUrl}/Fraction/ChangeToVote`,
            data: bodyFormData
        });
        console.log()
        return {
            data: resp.data
        }
    }
    catch (err) {
        console.log("ErrorinExplorePageFunction", err)
    }
}

export const VotingEntry = async (payload) => {
    console.log("VotingEntry payload", payload)
    try {
        let resp = await axios({
            'method': 'post',
            'url': `${config.vUrl}/Fraction/VotingEntry`,
            data: payload
        });
        console.log()
        return {
            data: resp.data
        }
    }
    catch (err) {
        console.log("VotingEntryVotingEntry", err)
    }
}

export const ClaimAmount = async (payload) => {
    console.log("ClaimAmount payload", payload)
    try {
        let resp = await axios({
            'method': 'post',
            'url': `${config.vUrl}/Fraction/ClaimAmount`,
            data: payload
        });
        console.log()
        return {
            data: resp.data
        }
    }
    catch (err) {
        console.log("ClaimAmountClaimAmount", err)
    }
}

export const ReduceAndNewOwner = async (payload) => {
    console.log("ClaimAmount payload", payload)
    try {
        let resp = await axios({
            'method': 'post',
            'url': `${config.vUrl}/Fraction/ReduceAndNewOwner`,
            data: payload
        });
        console.log()
        return {
            data: resp.data
        }
    }
    catch (err) {
        console.log("ClaimAmountClaimAmount", err)
    }
}

export const v1_BurnToken = async (payload) => {
    try {
      let resp = await axios({
        'method': 'post',
        'url': `${config.vUrl}/Fraction/burntoken`,
        data: payload,
        'headers': {
          'Authorization': localStorage.user_token
        },
      });
      return {
        data: resp.data
      }
    }
    catch (err) {
      console.log("Errorinv1_BurnTokenFunction", err)
    }
  }