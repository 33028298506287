import React, { useEffect, useState } from 'react'
import Chart from "react-apexcharts";
import { Link } from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { useSelector } from "react-redux";

import { getAllCounts, UserpanelSub, chartdataUserPannel, UserActivityDashBoard, RouterChecker } from "../actions/v1/token"

import config from 'lib/config'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";




export default function QrCode(props) {
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);

  const dashboardRoutes = [];
  const { ...rest } = props;

  const history = useHistory();
  const [SubList, SetSubList] = useState([])
  const [catagory, SetcatChange] = useState("Year")
  const [Xaxis, setXaxis] = useState([])
  const [Yaxis, setYaxis] = useState([])
  const [properties, setProperties] = useState([])
  const [Datess, SetDatess] = useState([])
  const [yearr, Setyear] = useState([])
  const [Monthsss, SetMonthss] = useState([])
  const [resentActivity, SetRecentActivity] = useState([])
  const [counts, Setcounts] = useState([])
  const [Routeee, SetRouteeee] = useState("Not Allowed")
  const [Routeee1, SetRouteeee1] = useState("Not Allowed")

  const [sidebarShrink, setSidebarShrink] = useState(false);
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const data = {
    options: {
      chart: {
        id: "basic-bar",
        foreColor: "#ccc",
        toolbar: {
          autoSelected: "pan",
          show: false
        }
      },
      colors: ["#2769ba"],
      stroke: {
        width: 3
      },
      grid: {
        borderColor: "#555",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: false
          },
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.75,
          opacityTo: 0
        }
      },
      markers: {
        size: 2,
        colors: ["#2769ba"],
        strokeColor: "#2769ba",
        strokeWidth: 3
      },
      tooltip: {
        theme: "dark"
      },
      xaxis: {
        categories: Yaxis
        //[1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
      }
    },
    series: [
      {
        name: "series-1",
        data: Xaxis
        // [30, 40, 45, 50, 49, 60, 70, 91]
      },

    ]
  };

  useEffect(() => {
    Route()
  }, [Wallet_Details.UserAccountAddr])

  useEffect(() => {
    getCounts()
    SubcriberList()
    ChartData()
    RecentActivity()
  }, [Wallet_Details.UserAccountAddr, catagory])

  const Route = async () => {
    let Addresss = {
      Address: Wallet_Details.UserAccountAddr
    }
    let SubscriberList = await RouterChecker(Addresss)
    if (SubscriberList?.data?.data?.Message == "Allowed") {
      SetRouteeee(SubscriberList?.data?.data?.userValue)
    }
  }

  const SubcriberList = async () => {
    let Addresss = {
      Address: Wallet_Details.UserAccountAddr
    }
    let SubscriberList = await UserpanelSub(Addresss)
    if (SubscriberList?.data?.data?.Message == "success") {
      SetSubList(SubscriberList?.data?.data?.userValue)
    }
  }


  async function getCounts() {
    let data = {
      curradd: Wallet_Details.UserAccountAddr
    }
    let counts = await getAllCounts(data)
    if (counts) {
      Setcounts(counts.data.data)
    }
  }

  const catChangeData = async (name) => {
    SetcatChange(name)
  }

  const ChartData = async () => {
    var SendAddress = {
      Address: Wallet_Details?.UserAccountAddr
    }
    if (Wallet_Details?.UserAccountAddr !== "") {
      var Datass = await chartdataUserPannel(SendAddress)
      var yearArr = []
      var dateArr = []
      var MonthArr = []
      var map = Datass.data.data.userValue.map((item, index) => {
        setProperties(item)
        dateArr.push(item.day)
        yearArr.push(item.year)
        MonthArr.push(item.month)
      })
      Setyear(yearArr)
      SetDatess(dateArr)
      SetMonthss(MonthArr)
      if (catagory == "Year") {
        const toFindDuplicates = yearArr => yearArr.filter((item, index) => yearArr.indexOf(item) == index)
        const duplicateElementa = toFindDuplicates(yearArr);
        let elementCounts = yearArr.reduce((count, item) => (count[item] = count[item] + 1 || 1, count), {})
        let Keyss = Object.values(elementCounts)
        let valuess = Object.keys(elementCounts)
        setXaxis(Keyss)
        setYaxis(valuess)
      }
      else if (catagory == "Month") {
        const toFindDuplicates = MonthArr => MonthArr.filter((item, index) => MonthArr.indexOf(item) == index)
        const duplicateElementa = toFindDuplicates(MonthArr);
        let elementCounts = MonthArr.reduce((count, item) => (count[item] = count[item] + 1 || 1, count), {})
        let Keyss = Object.values(elementCounts)
        let valuess = Object.keys(elementCounts)
        setXaxis(Keyss)
        setYaxis(valuess)
      }
      else if (catagory == "Day") {
        const toFindDuplicates = dateArr => dateArr.filter((item, index) => dateArr.indexOf(item) == index)
        const duplicateElementa = toFindDuplicates(dateArr);
        let elementCounts = dateArr.reduce((count, item) => (count[item] = count[item] + 1 || 1, count), {})
        let Keyss = Object.values(elementCounts)
        let valuess = Object.keys(elementCounts)
        setXaxis(Keyss)
        setYaxis(valuess)
      }
    }
  }

  const RecentActivity = async (name) => {
    let resentt = {
      Address: Wallet_Details.UserAccountAddr
    }
    if (Wallet_Details.UserAccountAddr !== "") {
      let Recent = await UserActivityDashBoard(resentt)
      if (Recent.data.data?.Message == "success") {
        SetRecentActivity(Recent?.data?.data?.userValue)
      }
    }
  }

  const CreatedTime = (Actdate) => {
    let today = new Date();
    let Christmas = new Date(Actdate);
    let diffMs = today - Christmas; // milliseconds between now & Christmas
    let diffDays = Math?.floor(diffMs / 86400000); // days
    let diffHrs = Math?.floor((diffMs % 86400000) / 3600000); // hours
    let diffMins = Math?.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    return diffHrs === 0 && diffDays === 0
      ? `${diffMins} minutes ago`
      : diffDays === 0
        ? `${diffHrs} hours ago`
        : `${diffDays} days ago`;
  };

  const setSidebarWidth = () => {
    setSidebarShrink(!sidebarShrink);
    if (sidebarShrink) {
      document.body.classList.remove('sidebar_shr');
    }
    else {
      document.body.classList.add('sidebar_shr');

    }
  }
  useEffect(() => {
    Route1()
  }, [Wallet_Details.UserAccountAddr])

  const Route1 = async () => {
    let Addresss = {
      Address: Wallet_Details.UserAccountAddr
    }
    let SubscriberList = await RouterChecker(Addresss)
    if (SubscriberList?.data?.data?.UserBalance.TokenScanner == "true") {
      SetRouteeee1(SubscriberList?.data?.data?.UserBalance)
    }
  }


  return (
    <>
      <div className='home_header home'>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand={<Link to="/home">
            <div className="header_logo_align">
            </div></Link>}
          leftLinks={<HeaderLinks />}
          changeColorOnScroll={{
            height: 20,
            color: "white"
          }}
          {...rest}
        />
        <div className='container-fluid home_container fluid_padding_align  d-flex'>
          <div className='home_header home'>
            <div id="sidebar" className={sidebarShrink ? "side_shrink" : ""}>

              <button className="get-started-btn rounded_btn_wal shrink_side_btn"
                onClick={() => setSidebarWidth()}
              >
                <i class="fa fa-chevron-right" aria-hidden="true"></i>
              </button>
              <div className='sidebar_btwn_dtls_align'>
                <div>
                  <div className='sidebar_whole_img_align'>

                  </div>
                  <ul className='sidebar_ul'>
                    <Link to='/userdashboard'><li className='sidebar_list_align'><i class="fa-solid fa-chart-line"></i><span className='sidebar_hiding_txt'>Dashboard</span></li></Link>
                    <Link to='/userprofile'><li className='sidebar_list_align'><i class="fa-solid fa-user"></i><span className='sidebar_hiding_txt'>Profile</span></li></Link>
                    {Routeee1.TokenScanner == "true" &&
                      <Link to='/qrcodescan'><li className='sidebar_list_align'><i class="fa-solid fa-qrcode"></i><span className='sidebar_hiding_txt'>Token Scanner</span></li></Link>}

                  </ul>
                </div>
              </div>


            </div>

          </div>

          <div className='scrolling_move_sec_align'>
            <div className="topcardsecs">
              <div>
                <div className='card  blackcard_style mb-1'>
                  <div className='firstrow'>
                    <div><i class="fa fa-circle admint_nrm_round admin_green_round mb-2" /></div>
                    <div className='div_text'>Total Tokens</div>
                  </div>
                  <div className='graph_align'>
                    <div className='count_dtls'>
                      <span className='count_txt'>{counts.totaltokens ? counts.totaltokens : 0}</span><br />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='card five_div_card blackcard_style mb-1'>
                  <div className='firstrow'>
                    <div><i class="fa fa-circle admint_nrm_round admin_red_round mb-2"></i></div>
                    <div className='div_text'>Used Tokens</div>
                  </div>

                  <div className='graph_align'>
                    <div className='count_dtls'>
                      <span className='count_txt'>{counts.usedTokens ? counts.usedTokens : 0}</span><br />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='card five_div_card blackcard_style mb-1'>
                  <div className='firstrow'>
                    <div><i class="fa fa-circle admint_nrm_round admin_sandal_round mb-2"></i></div>
                    <div className='div_text'>Available Tokens</div>
                  </div>
                  <div className='graph_align'>
                    <div className='count_dtls'>
                      <span className='count_txt'>{counts.availableTokens ? counts.availableTokens : 0}</span><br />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='card five_div_card blackcard_style mb-1'>
                  <div className='firstrow'>
                    <div><i class="fa fa-circle admint_nrm_round admin_violet_round mb-2"></i></div>
                    <div className='div_text'>Total SBT available</div>
                  </div>
                  <div className='graph_align'>
                    <div className='count_dtls'>
                      <span className='count_txt'>{counts.totsoulavailable ? counts.totsoulavailable : 0}</span><br />
                    </div>
                  </div>
                </div>
              </div>
              <div>


                <div className='card five_div_card blackcard_style mb-1'>
                  <div className='firstrow'>
                    <div><i class="fa fa-circle admint_nrm_round admin_sandal_round mb-2"></i></div>
                    <div className='div_text'>Total Tokens Sent</div>
                  </div>
                  <div className='graph_align'>
                    <div className='count_dtls'>
                      <span className='count_txt'>{counts.SBTsend ? counts.SBTsend : 0}</span><br />
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className='row mt-2'>
              <div className='col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 mb-3'>

                <div className='admin_chart_align w-100'>
                  <div className='chart_options_whole'>
                    <div className='row w-100'  >
                      <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12' >
                        <p className='admin_new_subscribers'>Number of Subscribers</p>
                      </div>
                      <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 graph_drpdwn_align' >
                        <div class="dropdown">
                          <button class="btn dropdown-toggle dropdown_togle_align dropdown_togle_align_graph" type="button" data-toggle="dropdown" aria-expanded="false">
                            Month
                          </button>
                          <div class="dropdown-menu">
                            <span class="dropdown-item" onClick={() =>
                              SetcatChange("Day")}>Day</span>
                            <span class="dropdown-item" onClick={() =>
                              SetcatChange("Month")}>Month</span>
                            <span class="dropdown-item" onClick={() =>
                              SetcatChange("Year")}>Year</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Chart options={data?.options} className="tradechart"
                    series={data?.series}
                    type="bar" height="165" width="100%" />
                </div>

              </div>

              <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3'>
                <div className='subscriber_whole_dtls_bgalign'>
                  <div className='subscriber_whole_dtls_align d-flex justify-content-between w-100'>
                    <p className='admin_new_subscribers'>New Followers</p>
                  </div>

                  {SubList.length > 0 ?
                    <>
                      {SubList && SubList.map((item, index) => {
                        return (
                          <div className='admin_subscriber_dtls_align w-100'>
                            {item.image == null || undefined ?
                              <img className='admin_dropdown_img_align' src={require('../assets/images/faces/marc.jpg')} />
                              :
                              <img className='admin_dropdown_img_align' src={`${config.Back_URL}/Users/${item.PrimaryAddr}/profileImg/${item?.image}`} />
                            }
                            <div>
                              <p className='subscribers_align_dtls'>{item?.name == undefined ? item?.userAddress?.slice(0, 15) : item?.name}<span className='admin_badge_icon_align'><i class="fa-solid fa-certificate admin_certificate_icon" /><i class="fa-solid fa-check admin_tick_icon" /></span></p>


                              <span className='follwers_count_align'>22k Followers</span>
                            </div>
                          </div>
                        )
                      })}
                    </>
                    : <h6 className='no_fol_found'>No followers found</h6>}
                </div>
              </div>
            </div>

            <div className='row '>
              <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-2'>
                <div className='subscriber_whole_dtls_bgalign'>
                  <div className='subscriber_whole_dtls_align'>
                    <div className=' d-flex justify-content-between w-100'>
                      <div>
                        <p className='admin_new_subscribers'>Last Activity</p>
                        <small>Recently for this month</small>
                      </div>
                      <button className='table_share_btn_align'><i class="fa-solid fa-arrow-up-right-from-square"></i> Share</button>
                    </div>
                    {resentActivity?.length > 0 ?
                      <>
                        {resentActivity && resentActivity.map((item, index) => {
                          return (
                            < div className='last_activity_sub_align row'>
                              <div className='col-xl-4 xol-lg-4 col-md-4 col-sm-12 col-12 mb-3'>
                                <div className='sub_img_dtls_align'>
                                  {Wallet_Details?.AddressUserDetails?.image == "" || null ?
                                    <img className='sub_dtls_img' src={require('../assets/images/faces/marc.jpg')} />
                                    :
                                    <img className='sub_dtls_img' src={`${config.Back_URL}/Users/${Wallet_Details.UserAccountAddr}/profileImg/${Wallet_Details?.AddressUserDetails?.image}`} />}

                                  <div>
                                    <p className='sub_dtls_txt_align'>{item?.action}</p>
                                  </div>
                                </div>
                              </div>
                              <div className='col-xl-4 xol-lg-4 col-md-4 col-sm-12 col-12 mb-3'>
                                <div className='progress_bar_div_align'>
                                  <div className='d-flex'>
                                    <p className='sub_dtls_txt_align mr-2'>37%</p>
                                    <small>Targeted</small>
                                  </div>
                                  <div class="progress qrcode_progress_bar_align">

                                    <div class="progress-bar " role="progressbar" style={{ width: "25%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-xl-4 xol-lg-4 col-md-4 col-sm-12 col-12 mb-3'>
                                <div className='status_details_dtls_align'>
                                  <small>Created {CreatedTime(item.created)}</small><br />
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </>
                      :
                      <>
                        <h3>No Recent Activity Found</h3>
                      </>
                    }
                  </div>
                </div>
              </div>
              <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-2 h-100'>
                <div className='subscriber_whole_dtls_alignred'>
                  <div className='row'>
                    <div className='col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12'>
                      <p className='achieve_head_align'>New Achievement !</p>
                      <p className='congrats_bottom_txt_align'>Congradulations because you have became our member for 1 year. We hope you continue to stay with us.</p>
                      <button className='benefit_btn_align'>See Benefits</button>
                    </div>
                    <div className='col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 glazier_img_div_align'>

                      <img className='glazier_img_align' src={require('../assets/images/faces/mask_group.png')} alt="image" />

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

