import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import { v1_Ranks } from "actions/v1/user";
import { toast } from "react-toastify";
import config from "lib/config";
import { useSelector } from "react-redux";
import { usePrevious } from "./my-items";
import Modal from 'react-modal';
const dashboardRoutes = [];
const useStyles = makeStyles(styles);
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Rankings(props) {
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const classes = useStyles();
  const { ...rest } = props;
  const [Ranking, setRanking] = useState("");
  const [CatName, setCatName] = useState("All");
  const [Time, setTime] = useState(7);
  const [LoadMore, setLoadMore] = useState(true);
  const [Page, setPage] = useState(1);
  const prevPage = usePrevious(Page);
  const [ ShowModal,setShowModal] = useState(false)
  const [theme,setTheme] = useState("#000");

  useEffect(() => {
  
    if(document.getElementById("root").classList.contains("light_theme"))
    {
      setTheme("#FFF")
    }
    else
    {
      setTheme("#000")


    }
  }, []);
  const customStyles = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      // boxShadow: '0 27px 24px 0 rgb(0 0 0 / 20%), 0 40px 77px 0 rgb(0 0 0 / 22%)',
      borderRadius: '30px',
      border: 'none !important',
    },
    overlay: {
      backgroundColor: theme,
      zIndex: 2
    },
  };

  useEffect(() => {
    if(Page === 1)
      setShowModal(true)
    GetRankings();
  }, [Page, Time, CatName]);

  const GetRankings = async () => {
    var Arg = {
      Page: prevPage === Page ? 1 : Page,
      limit: 100,
      Cat: CatName,
      Time: Time,
    };
    var Resp = await v1_Ranks(Arg);
    if (Resp.Success) {
      if (Resp.Ranks.length > 0) {
        setRanking(Resp.Ranks);
        setLoadMore(true);
      } else {
        if(prevPage === Page)
          setRanking(Resp.Ranks);
        setLoadMore(false);
      }
      setTimeout(() => {
        setShowModal(false)
      }, 1000);
    } else toast.error("Error in Rank Fetch", { autoClose: 1500 });
  };

  return (
    <div className="home_header">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={
          <Link to="/home">
            <div className="header_logo_align">
            

            </div>
          </Link>
        }
        leftLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 20,
          color: "white",
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader inner_page_bg_1 rankingsyse"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <p className="heading_big_blk text-center pb-3">Top NFTs</p>
              <p className="heading_sm_blk_new  text-center p-b-sec">
                The top NFTs on Lazyminter, ranked by volume, floor price and
                other statistics.
              </p>
              <div className="d-flex align-items-center justify-content-center drop_d-block">
                <span className="mr-4">
                  {" "}
                  <div className="dropdown">
                    <button
                      className="drop-down itemsselect_dd_new"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      last {Time === 1 ? "24 hours" : `${Time} days`}{" "}
                      <i
                        className="fa fa-angle-down pl-2"
                        aria-hidden="true"
                      ></i>
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <div className="dropdown-item" onClick={() => setTime(1)}>
                        last 24 hours
                      </div>
                      <div className="dropdown-item" onClick={() => setTime(7)}>
                        last 7 days
                      </div>
                      <div
                        className="dropdown-item"
                        onClick={() => setTime(30)}
                      >
                        last 30 days
                      </div>
                    </div>
                  </div>
                </span>

                <span>
                  <div className="dropdown">
                    <button
                      className="drop-down itemsselect_dd_new"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {CatName} Categories{" "}
                      <i
                        className="fa fa-angle-down pl-2"
                        aria-hidden="true"
                      ></i>
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <div
                        className="dropdown-item"
                        onClick={() => setCatName("All")}
                      >
                        All
                      </div>
                      {Wallet_Details.Category &&
                        Wallet_Details.Category.length > 0 &&
                        Wallet_Details.Category.map((Cat, i) => {
                          return (
                            <div
                              className="dropdown-item"
                              onClick={() => setCatName(Cat.name)}
                            >
                              {Cat.name}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </span>
              </div>
            </GridItem>
          </GridContainer>
        </div>
          <div className="container p-t-sec p-b-sec">
            <div className="table-responsive">
              <table class="table activtab table_ranking">
                <thead>
                  <tr>
                    <th scope="col">Creator</th>
                    <th scope="col">Volume</th>
                    <th scope="col">24h%</th>
                    <th scope="col">7d%</th>
                    <th scope="col">Floor Price</th>
                    <th scope="col">Owners</th>
                    <th scope="col">Items</th>
                  </tr>
                </thead>
                <tbody>
                  {Ranking &&
                    Ranking.length > 0 &&
                    Ranking.map((Rank, i) => {
                      return (
                        <tr>
                          <td>
                            <Link
                              to={
                                Rank.Creator.customurl
                                  ? `/my-items/user/${Rank.Creator.customurl}`
                                  : `/my-items/${Rank.Creator.curraddress}`
                              }
                            >
                              <div className="d-flex tableprofimg">
                                <span className="pr-2 num_text_table">
                                  {" "}
                                  {i + 1}{" "}
                                </span>
                                <img
                                  src={
                                    Rank.Creator.image
                                      ? `${config.Back_URL}/Users/${Rank.Creator.PrimaryAddr}/profileImg/${Rank.Creator.image}`
                                      : require("../assets/images/lazymintingnew/logo.png")
                                  }
                                  alt="User"
                                  className="img-fluid"
                                />
                                <p className="mb-0 media_text">
                                  {Rank.Creator.name
                                    ? Rank.Creator.name
                                    : Rank._id.slice(0, 15)}
                                </p>
                              </div>
                            </Link>
                          </td>
                          <td>
                            <div className="price-tab">
                              <img
                                src={require("../assets/images/binance-coin-bnb-logo-freelogovectors.net_ (1).png")}
                              />
                              <span className="ml-2">
                                {Rank.Volume.toFixed(4)}
                              </span>
                            </div>
                          </td>
                          {Rank.Day1 ? (
                            Rank.Day1 === 0 || Rank.Day1 < 0 ? (
                              <td>
                                <span className="text_red_table">
                                  {Rank.Day1}%
                                </span>
                              </td>
                            ) : (
                              <td>
                                <span className="text_green_table">
                                  {Rank.Day1}%
                                </span>
                              </td>
                            )
                          ) : (
                            <td>
                              <span className="text_none_table">---</span>
                            </td>
                          )}
                          {Rank.Day7 ? (
                            Rank.Day7 === 0 || Rank.Day7 < 0 ? (
                              <td>
                                <span className="text_red_table">
                                  {Rank.Day7}%
                                </span>
                              </td>
                            ) : (
                              <td>
                                <span className="text_green_table">
                                  {Rank.Day7}%
                                </span>
                              </td>
                            )
                          ) : (
                            <td>
                              <span className="text_none_table">---</span>
                            </td>
                          )}
                          <td>
                            <div className="price-tab">
                              <img
                                src={require("../assets/images/binance-coin-bnb-logo-freelogovectors.net_ (1).png")}
                              />
                              <span className="ml-2">
                                {Rank.Floor.toFixed(2)}
                              </span>
                            </div>
                          </td>
                          <td>{Rank.Owners}</td>
                          <td>{Rank.Item}</td>
                        </tr>
                      );
                    })
              }
                </tbody>
              </table>
            </div>
            {LoadMore && (
              <div className="pb-5 text-center pt-4">
                <button
                  className="create_btn"
                  onClick={() => setPage(Page + 1)}
                >
                  Load more
                </button>
              </div>
            )}
          </div>
      </div>
      <Footer />
      <Modal
        isOpen={ShowModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {theme === "#000" ?
         <div className="modal-body text-center loading_bgcolor">
         <div className="laodingpart">
         <img src={require("../assets/images/lazymintingnew/logo.png")} alt="Profile" className="img-fluid items_profile smal_main_lm" />
         </div>
       </div> :

<div className="modal-body text-center loading_bgcolor">
<div className="laodingpart">
<img src={require("../assets/images/lazymintingnew/logodark.png")} alt="Profile" className="img-fluid items_profile smal_main_lm" />
</div>
</div>

        }
       
      </Modal>
    </div>
  );
}
