import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import HeaderLinks from "components/Header/HeaderLinks.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import { v1_Terms } from "actions/v1/user";
import { toast } from "react-toastify";
import ReactHtmlParser from 'react-html-parser';
const dashboardRoutes = [];
const useStyles = makeStyles(styles);
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
export default function Terms(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [Terms,setTerms] = useState({})
  const GetTerms = async()=>{
    var Resp = await v1_Terms()
    if(Resp.Success){
      setTerms(Resp.userValue)}
    else
      toast.error("Try it Again Later",{autoClose:2000})
  }
  useEffect(()=>{
    GetTerms()
  },[])
  return (
    <div className="home_header">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home">
          <div className="header_logo_align">
            </div></Link>}
        leftLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader inner_pagh_bg_1 pb-5"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center">               
              <p class="heading_big_blk">Terms and Conditions</p>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="container mt-2">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
            {Terms.answer && ReactHtmlParser(Terms.answer)}
            </GridItem> 
          </GridContainer>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
