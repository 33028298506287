import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styled from "styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link, useParams } from "react-router-dom";
import ExploreCard from "./ExploreCard_Fraction";
import { ExplorePage } from "actions/v1/Fractional";
import { useSelector } from "react-redux";
import { usePrevious } from "hooks/useMethods";
import { TailSpin } from "react-loader-spinner";
import Modal from 'react-modal';
import { toast } from "react-toastify";

const Icon = styled((props) => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #3d2524;
    font-size: 24px;
  }
  & > .minus {
    display: none;
    color: #3d2524;
    font-size: 24px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function FractionalExplore(props) {
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const classes = useStyles();
  const { ...rest } = props;
  const { Collection, Category } = useParams()
  const [ExploreCardNFTs, setExploreCardNFTs] = useState({ 'loader': false, 'All': { page: 1, list: [], onmore: true, Vault: "Show", VaultStatus: "Explore" } });
  const [ExploreViralNFTs, setExploreViralNFTs] = useState([]);
  const [CatName, setCatName] = useState("All")
  const [updated, setupdated] = useState(false)
  const [CatPage, setCatPage] = useState({ "All": { page: 1 } })
  const prevCatPage = usePrevious(CatPage)
  const prevUpdated = usePrevious(updated)
  const prevCat = usePrevious(ExploreCardNFTs)
  const [ShowModal, setShowModal] = useState(false)
  const [ShowLoader, setShowLoader] = useState(false)
  const [categorysate, Setcategorysate] = useState({})
  const [theme, setTheme] = useState("#000");

  useEffect(() => {
    if (document.getElementById("root").classList.contains("light_theme")) {
      setTheme("#FFF")
    }
    else {
      setTheme("#000")
    }
  }, []);

  const customStyles = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      // boxShadow: '0 27px 24px 0 rgb(0 0 0 / 20%), 0 40px 77px 0 rgb(0 0 0 / 22%)',
      borderRadius: '30px',
      border: 'none !important',
    },
    overlay: {
      backgroundColor: theme,
      zIndex: 2
    },
  };

  useEffect(() => {
    setShowLoader(true)
    ExploreCardNFT()
  }, [CatName, Wallet_Details.UserAccountAddr])

  useEffect(() => {
    setShowModal(true)
    ExploreViralNFT()
  }, [Wallet_Details.UserAccountAddr])

  useEffect(() => {
    catChange(Category)
  }, [Category])

  const ExploreViralNFT = async () => {
    var Payload = { curraddress: Wallet_Details.UserAccountAddr, Category: "", Page: 1, limit: 5, Card: "viral", Vault: "Show", VaultStatus: "Explore" }
    var NFT = await ExplorePage(Payload);
    if (NFT.data.Success) {
      if (NFT.data.records.length > 0) {
        setExploreViralNFTs([])
        setExploreViralNFTs(NFT.data.records)
      }
      setTimeout(() => {
        setShowModal(false)
      }, 1000);
    }
  }

  const ExploreCardNFT = async (Catpage) => {
    var Payload = { curraddress: Wallet_Details.UserAccountAddr, Category: CatName, Page: Catpage || 1, limit: 16, Vault: "Show", VaultStatus: "Explore", Exploree: "Myitem" }
    var NFT = await ExplorePage(Payload);
    if (NFT?.data?.Success) {
      if (NFT?.data?.records?.length > 0) {
        if (((ExploreCardNFTs[CatName].list.length !== 0) && (CatName === NFT?.data?.cat) && ((Catpage ? Catpage : ExploreCardNFTs[CatName].page) !== ExploreCardNFTs[CatName].page))) {
          ExploreCardNFTs[CatName].onmore = true
          ExploreCardNFTs[CatName].page = NFT.data.page
          ExploreCardNFTs[CatName].list = ExploreCardNFTs[CatName].list.concat(NFT.data.records)
          setExploreCardNFTs([])
          setExploreCardNFTs(ExploreCardNFTs);
        }
        else if (ExploreCardNFTs[CatName].list.length === 0) {
          ExploreCardNFTs[CatName].onmore = true
          ExploreCardNFTs[CatName].list = NFT?.data?.records
          setExploreCardNFTs([])
          setExploreCardNFTs(ExploreCardNFTs);
        }
      }
      if (NFT?.data?.records?.length === 0) {
        ExploreCardNFTs[CatName].onmore = false
        setExploreCardNFTs([])
        setExploreCardNFTs(ExploreCardNFTs)
      }
      setTimeout(() => {
        setShowLoader(false)
      }, 1000);
    }
    else {
      toast.error("Oops..! Error Detected Refresh Page")
    }
  };

  const catChange = (Category) => {
    if (Category) {
      if (!ExploreCardNFTs[Category]) {
        ExploreCardNFTs[Category] = { page: 1, list: [], onmore: true };
        setExploreCardNFTs(ExploreCardNFTs);
      }
      setCatName(Category)
    }
  }

  const LoadMore = () => {
    var Catpage = ExploreCardNFTs[CatName].page + 1
    ExploreCardNFT(Catpage)
  }

  const BrowseMaketPlace = () => {
    return (
      <div className="text-center py-5">
        <p className="not_found_text">No items found</p>
        <p className="not_found_text_sub">
          Come back soon! Or try to browse something for you on our marketplace
        </p>
        <div className="mt-3">
          <Button className="browsematdk">Browse Marketplace</Button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (Wallet_Details?.Category) {
      var Categorydata = Wallet_Details?.Category.filter(Cat => (Cat.label != 'Ticket'))
      if (Categorydata) {
        Setcategorysate(Categorydata)
      }
    }
  }, [Wallet_Details?.Category]);

  const Reloadble = () => {
    return (
      <>
        {
          ExploreCardNFTs[CatName] &&
          ExploreCardNFTs[CatName].list &&
          ExploreCardNFTs[CatName].list.length > 0 &&
          ExploreCardNFTs[CatName].list.map((NFT) => {
            return (
              NFT.tokenCategory !== "Web3 IDs" &&
              <ExploreCard NFTDetails={NFT} />
            )
          })}
      </>
    )
  }

  return (
    <div className="home_header explore">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={
          <Link to="/home">
            <div className="header_logo_align">
            </div>
          </Link>
        }
        leftLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 20,
          color: "white",
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader inner_page_bg_2"}>
        <div className="explroeapge">
          <div className="container-fluid custom-container">
            <p className="heading_sm_blk p-b-sec heading_marketplace">Explore Fractional NFTs</p>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <nav className="masonry_tab_nav pt-0 items_tab_outer">
                  <div
                    className="nav nav-tabs masonry_tab primary_tab explore_tab_full items_tab justify-content-center items_tab_new pb-0 pl-0"
                    id="nav-tab"
                    role="tablist"
                  >
                    <a
                      className={(CatName === 'All') ? "nav-link active" : "nav-link"}
                      id="all-tab"
                      data-toggle="tab"
                      href="#all"
                      role="tab"
                      aria-controls="onsale"
                      aria-selected="true"
                      onClick={() => catChange('All')}
                    ><div class="tab_head">All</div>
                    </a>
                    {Wallet_Details?.CategoryFractional && Wallet_Details?.CategoryFractional.length > 0 &&
                      Wallet_Details?.CategoryFractional.map((item) => {
                        return (
                          (item.name !== "Ticket" &&
                            <a className={(CatName === item.name) ? "nav-link active" : "nav-link"} onClick={() => catChange(item.name)} data-tabName={item.name} id="all-tab" data-toggle="tab" href="#all" role="tab" aria-controls="all" aria-selected="false"><div class="tab_head">{item.name}</div></a>
                          )
                        )
                      })
                    }
                  </div>
                </nav>
                <div
                  className="tab-content explore_tab_content mt-0 p-t-sec p-b-sec"
                  id="nav-tabContent"
                >
                  <div
                    className="tab-pane fade show active"
                    id="all"
                    role="tabpanel"
                    aria-labelledby="all-tab"
                  >
                    <div className="proposal_panel_overall">
                      {ExploreCardNFTs[CatName] &&
                        ExploreCardNFTs[CatName].list &&
                        ExploreCardNFTs[CatName].list.length === 0 ?
                        <>
                          {
                            ShowLoader ?
                              <div className="text-center py-5">
                                <TailSpin
                                  wrapperClass="searreactloader"
                                  color="#00BFFF"
                                  height={100}
                                  width={70}
                                />
                              </div>
                              :
                              <BrowseMaketPlace />
                          }
                        </>
                        : <>
                          {
                            ShowLoader ?
                              <div className="text-center py-5">
                                <TailSpin
                                  wrapperClass="searreactloader"
                                  color="#00BFFF"
                                  height={100}
                                  width={70}
                                />
                              </div>
                              :
                              <div className="row masonry m-0 ma_no_gap card_main_iner tickets ticketesexplorenpage">
                                {CatName && CatName != 'Ticket' ?
                                  <Reloadble /> : ""
                                }
                              </div>
                          }
                          <div className="col-12 text-center pt-4">
                            {ExploreCardNFTs[CatName] && ExploreCardNFTs[CatName].onmore &&
                              <button className="create_btn" onClick={() => { LoadMore() }}>Load More</button>
                            }
                          </div>
                        </>
                      }
                    </div>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
      <Modal
        isOpen={ShowModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {theme === "#000" ?
          <div className="modal-body text-center loading_bgcolor">
            <div className="laodingpart">
              <img src={require("../../assets/images/lazymintingnew/logo.png")} alt="Profile" className="img-fluid items_profile smal_main_lm" />
            </div>
          </div> :
          <div className="modal-body text-center loading_bgcolor">
            <div className="laodingpart">
              <img src={require("../../assets/images/lazymintingnew/logodark.png")} alt="Profile" className="img-fluid items_profile smal_main_lm" />
            </div>
          </div>
        }
      </Modal>
    </div>
  );
}
