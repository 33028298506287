import React, { useEffect, useState, useRef } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from "@material-ui/core";
import Bannerimga from "../assets/images/neko.png"
import Profile from "../assets/images/info_img.png"
import Banner from "../assets/images/lazymintingnew/myitemsprofile.jpg"
// core components
import { useDispatch, useSelector } from "react-redux";
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
//Nimal
import { GetProfiledata, EditCoverPhoto } from "actions/controller/userControl";
import { ImageValidation } from "actions/controller/validation";
import isEmpty from "lib/isEmpty";
import config from "lib/config";
import $ from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { toast, Flip } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import ExploreCard from "./separate/explore_card";
import ExploreCard_Fraction from "./Fractions/ExploreCard_Fraction"
import VaultCard from "./separate/VaultCard";
import Card from '../views/separate/Card.js';
import ActivityCard from "./separate/ActivityCard";
import Ticketcard from "../views/separate/ticketcard.js"
import { MyItemsData } from "actions/controller/tokenControl";
import { MyUserData } from "actions/controller/userControl";
import { FollowAction } from "actions/v1/user";
import { MyItemCollection, MyItemSoulBound, MyItemSoulBoundOwn, VaultApi } from "actions/v1/token";
import Collectioncard from "./separate/collection_card";
import { useHistory, useParams } from "react-router-dom";
import { Audio, TailSpin } from "react-loader-spinner";
import Modal from 'react-modal';
import { TramRounded } from "@material-ui/icons";
import { Share } from "./separate/ShareNow"
import ReactReadMoreReadLess from "react-read-more-read-less";
import VaultCollCard from "./separate/VaultCollCard"
import { ExplorePage } from "actions/v1/Fractional";
toast.configure();
//
import { Account_Connect, Account_disConnect } from "actions/redux/action";
const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export default function Myitems(props) {
  const ShareNowRef = useRef();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { ...rest } = props;
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  // console.log("zbcjkabckjnakcas",Wallet_Details);
  const history = useHistory();
  const { useradd, userurl } = useParams();
  const InitialForm = {
    Name: "",
    CustomUrl: "",
    Joined: "",
    Bio: "",
    Twitter: "",
    Facebook: "",
    Instagram: "",
    Youtube: "",
    Count: {},
    curraddress: Wallet_Details.UserAccountAddr,
    PrimaryAddr: "",
    LocalAddr: ""
  };
  const [Formdata, setFormdata] = useState(InitialForm);
  const [Pro, setPro] = useState(require("../assets/images/lazymintingnew/logo.png"));
  const [Cover, setCover] = useState(
    require("../assets/images/my_items_baner.png")
  );
  const [MyItemNFTs, setMyItemNFTs] = useState({ onsale: { page: 1, list: [], onmore: true } });
  const [MyUserFollow, setMyUserFollow] = useState({
    followers: { page: 1, list: [], onmore: true },
    activity: { page: 1, list: [], onmore: true },
  });
  const [ExploreCardNFTs, setExploreCardNFTs] = useState({ 'loader': false, 'All': { page: 1, list: [], onmore: true, Vault: "Show", VaultStatus: "Explore" } });
  const [FollowState, setFollowState] = useState(false);
  const [TabName, setTabName] = useState();
  const [Loader, setLoader] = useState(false);
  const [ShowModal, setShowModal] = useState(false)
  const [FollowButState, setFollowButState] = useState(false);
  const [items, setItems] = useState("All");
  const [ListData, SetListData] = useState({});
  const [SoulListData, SetSoulListData] = useState({});
  const [SoulListOwn, SetSoulListOwn] = useState({});
  const [theme, setTheme] = useState("#000");
  const [CatName, setCatName] = useState("All")
  const [ShowLoader, setShowLoader] = useState(false)

  useEffect(() => {

    if (document.getElementById("root").classList.contains("light_theme")) {
      setTheme("#FFF")
    }
    else {
      setTheme("#000")


    }
  }, []);

  const customStyles = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      // boxShadow: '0 27px 24px 0 rgb(0 0 0 / 20%), 0 40px 77px 0 rgb(0 0 0 / 22%)',
      borderRadius: '30px',
      border: 'none !important',
    },
    overlay: {
      backgroundColor: theme,
      zIndex: 2
    },
  };
  useEffect(() => {
    // alert(1);
    // document.getElementsByTagName("body")[0].classList.remove("modal-open");
    setShowModal(true)
    GetProfile();
  }, [userurl, useradd, Wallet_Details.UserAccountAddr]);
  var themecolor = "#000"


  //Function
  const GetProfile = async () => {
    setLoader(true);
    if (userurl)
      var Payload = {
        user: { customurl: userurl },
        followerAddress: Wallet_Details.UserAccountAddr,
      };
    if (useradd)
      var Payload = {
        user: { curraddress: useradd },
        followerAddress: Wallet_Details.UserAccountAddr,
      };
    if (!userurl && !useradd)
      var Payload = {
        user: { curraddress: Wallet_Details.UserAccountAddr },
        followerAddress: Wallet_Details.UserAccountAddr,
      };
    console.log("dsfkaflkmclkdmcdscs", Payload);
    var Resp = await GetProfiledata(Payload);
    console.log("nvsdknvsknvk", Resp);
    if (!isEmpty(Resp)) {
      setFormdata(Resp);
      if (Resp.photo != "")
        setPro(
          `${config.Back_URL}/Users/${Resp.PrimaryAddr}/profileImg/${Resp.photo}`
        );
      else setPro(require("../assets/images/lazymintingnew/logo.png"));
      if (Resp.coverimage)
        setCover(
          `${config.Back_URL}/Users/${Resp.PrimaryAddr}/coverImg/${Resp.coverimage}`
        );
      else setCover(require("../assets/images/my_items_baner.png"));
      if (Resp.Follow) {
        setFollowState(Resp.Follow);
      }
      setMyItemNFTs({ onsale: { list: [] } });
      await MyItemTab("onsale", Resp.LocalAddr, "Profile");
    }
  };
  //Function
  const Handlefile = (e) => {
    if (e.target && e.target.files) {
      var file = e.target.files[0];
      var Resp = ImageValidation(file, "Edit");
      if (Resp.Success) {
        CoverPhoto(file);
      } else toast.error(Resp.Message);
    }
  };
  //Function
  const CoverPhoto = async (file) => {
    var Payload = { File: file, curraddress: Formdata.PrimaryAddr };
    var Cresp = await EditCoverPhoto(Payload);
    $("#coverchoose").trigger("click");
    setCover(URL.createObjectURL(file));
  };
  //Function
  const MyItemTab = async (tab, addr, filter, Pageno) => {
    console.log("tab name", tab)

    if (tab && !MyItemNFTs[tab]) {
      setLoader(true);
      MyItemNFTs[tab] = { page: 1, list: [], onmore: true };
      setMyItemNFTs(MyItemNFTs);
    }
    var curraddress = addr ? addr : Formdata.LocalAddr;
    var Payload = {
      curraddress: curraddress,
      Tab: tab ? tab : "onsale",
      Action: "Click",
      Limit: 16,
      Page: Pageno || 1,
      filter: filter,
    };
    console.log("paylaod for myitem page", Payload)
    if (tab === "activity" || tab === "following" || tab === "followers") {
      var myUser = await MyUserData(Payload);
      console.log("payload to ksdfkl;dsmf; mwfklmwd;fmcreated tab", myUser)
      if (myUser.Success) {
        // console.log("qwerttttt", Payload.Tab);
        setTabName(Payload.Tab);
        var temp = MyUserFollow;
        temp[myUser.Tab] = myUser.records;
        setMyUserFollow([]);
        setMyUserFollow(temp);
      } else {
        setTabName(Payload.Tab);
      }
    }
    else if (tab == "Soulbound") {
      // SoulBoundOwnerFun()
      // SoulBoundCollectionFun()
      var reqData = {
        Address: Wallet_Details.UserAccountAddr,
        VaultStatus: "Show"
      }
      var collectiongal = await MyItemSoulBoundOwn(reqData);
      console.log("explorecomming", collectiongal.data.data.ListingCollection)
      SetSoulListOwn(collectiongal.data.data.ListingCollection)
      setItems(tab)
    } else {
      // console.log("payload to created tab", Payload)
      var myNFT = await MyItemsData(Payload);
      console.log("create tab data", myNFT)
      if (myNFT.Success) {
        if (Pageno && Pageno !== MyItemNFTs[myNFT.Tab].page) {
          MyItemNFTs[myNFT.Tab].page = Pageno
          MyItemNFTs[myNFT.Tab].list = MyItemNFTs[myNFT.Tab].list.concat(myNFT.records);
          MyItemNFTs[myNFT.Tab].onmore = (myNFT.records.length > 0)
          // console.log("wdfwfewfewfwe", MyItemNFTs);
          setMyItemNFTs([]);
          setMyItemNFTs(MyItemNFTs);
        }
        else {
          if (MyItemNFTs[myNFT.Tab].page === 1) {
            MyItemNFTs[myNFT.Tab].list = myNFT.records;
            // console.log("qweeretrertrtr", MyItemNFTs);
            setMyItemNFTs([]);
            setMyItemNFTs(MyItemNFTs);
            setTimeout(() => {
              setShowModal(false)
            }, 1000);
          }
        }

        // console.log("efwejfweifwi", Payload.Tab);
        setTabName(Payload.Tab);
      } else {
        setTabName(Payload.Tab);
      }
    }
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  };
  //Function
  const FollowAct = async () => {
    if (Wallet_Details.UserAccountAddr !== "") {
      setFollowButState(true)
      if (FollowState) {
        toast.success("Removed from Followings", {
          closeButton: true,
          autoClose: 1500,
          transition: Flip,
        });
      }
      else {
        toast.success("Added to Followings", {
          closeButton: true,
          autoClose: 1500,
          transition: Flip,
        });
      }
      var Payload = {
        userAddress: Formdata.LocalAddr,
        followerAddress: Wallet_Details.UserAccountAddr,
      };
      var resp = await FollowAction(Payload);
      if (resp.data.Success) {
        setFollowState(!FollowState);
        setFollowButState(false)
      } else {
        toast.warning(resp.data.Message, {
          closeButton: true,
          autoClose: 1500,
          transition: Flip,
        });
        setFollowButState(false)
      }
    } else history.push("/connect-wallet");
  };
  //Function
  const CollectionFun = async (data) => {
    // console.log("kdnsnvfknks", data);
    var reqData = {
      Address: data ? Wallet_Details.UserAccountAddr : data
    }
    var collectiongal = await MyItemCollection(reqData);
    // console.log("explorecomming", collectiongal.data.data.ListingCollection)
    SetListData(collectiongal.data.data.ListingCollection)
  }
  //Function
  const SoulBoundCollectionFun = async (data) => {
    // console.log("kdnsnvfknks", data);
    var reqData = {
      Address: data ? Wallet_Details.UserAccountAddr : data
    }
    var collectiongal = await MyItemSoulBound(reqData);
    // console.log("explorecomming", collectiongal.data.data.ListingCollection)
    SetSoulListData(collectiongal.data.data.ListingCollection)
  }
  const SoulBoundOwnerFun = async (data) => {
    // console.log("kdnsnvfknks", data);
    var reqData = {
      Address: data ? Wallet_Details.UserAccountAddr : data,
      VaultStatus: "Show"
    }
    var collectiongal = await MyItemSoulBoundOwn(reqData);
    // console.log("explorecomming", collectiongal.data.data.ListingCollection)
    SetSoulListOwn(collectiongal.data.data.ListingCollection)
  }

  //Component
  const MyItemCard = () => {
    return (
      <>
        {Loader ? (
          <>
            <div className="text-center py-5">
              <TailSpin
                wrapperClass="searreactloader"
                color="#00BFFF"
                height={100}
                width={70}
              />
            </div>
          </>
        ) : (

          <>
            {/* {console.log("wfrwfwrfrfrefrwe", TabName)} */}

            {MyItemNFTs &&
              MyItemNFTs[TabName] &&
              MyItemNFTs[TabName].list.length > 0 ? (
              <div className="row masonry m-0 ma_no_gap card_main_iner">
                {MyItemNFTs[TabName].list.map((NFT) => {
                  return (
                    <ExploreCard
                      Cardclass={
                        "item col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3"
                      }
                      NFTDetails={NFT}
                    />
                  );
                })}
                {/* <Card /> */}
              </div>
            ) : (
              <BrowseMarketPlace />
            )}
          </>

        )}

      </>
    );
  };

  const Vault = () => {
    return (
      <>
        {Loader ? (
          <>
            <div className="text-center py-5">
              <TailSpin
                wrapperClass="searreactloader"
                color="#00BFFF"
                height={100}
                width={70}
              />
            </div>
          </>
        ) : (

          <>
            {/* {console.log("wfrwfwrfrfrefrwe", TabName)} */}

            {MyItemNFTs &&
              MyItemNFTs[TabName] &&
              MyItemNFTs[TabName].list.length > 0 ? (
              <div className="row masonry m-0 ma_no_gap card_main_iner">
                {MyItemNFTs[TabName].list.map((NFT) => {
                  return (
                    <VaultCard
                      Cardclass={
                        "item col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3"
                      }
                      NFTDetails={NFT}
                    />
                  );
                })}
                {/* <Card /> */}
              </div>
            ) : (
              <BrowseMarketPlace />
            )}
          </>

        )}

      </>
    );
  };

  const Ticketss = () => {
    return (
      <>
        {Loader ? (
          <>
            <div className="text-center py-5">
              <TailSpin
                wrapperClass="searreactloader"
                color="#00BFFF"
                height={100}
                width={70}
              />
            </div>
          </>
        ) : (

          <>
            {/* {console.log("wfrwfwrfrfrefrwe", TabName, MyItemNFTs)} */}

            {MyItemNFTs &&
              MyItemNFTs[TabName] &&
              (TabName == "Ticket") &&
              MyItemNFTs[TabName].list.length > 0 ? (
              <div className="row tickets">
                {MyItemNFTs["Ticket"].list.map((NFT) => {
                  console.log("fhiewhfijwehfijrwe", NFT);
                  return (
                    <Ticketcard
                      item={NFT}
                    //  className="col-xl-3 col-lg-4 col-md-6 col-sm-12"
                    />
                    // <p>lsdfklsdflksdkl</p>
                  );
                })}
                {/* <Card /> */}
              </div>
            ) : (
              <BrowseMarketPlace />
            )}
          </>

        )}

      </>
    );
  };
  //Component
  const FollowDetails = () => {
    return (
      <>
        {MyUserFollow &&
          MyUserFollow[TabName] &&
          MyUserFollow[TabName].length > 0 &&
          MyUserFollow[TabName].map((user) => {
            console.log("Adadsdas", user);
            if (user.user.image !== "")
              var Userimg = `${config.Back_URL}/Users/${user.user.PrimaryAddr}/profileImg/${user.user.image}`;
            else var Userimg = `${require("../assets/images/user_img_1.png")}`;
            var Username = user.user.name
              ? user.user.name
              : user?.user?.curraddress?.slice(0, 20);
            return (
              <div className="col-12 col-md-6 col-xl-4 mb-4">
                <Link to={(user.user.customurl) ? `/my-items/user/${user.user.customurl}` : `/my-items/${user.user.curraddress}`}>
                  <div className="card acti_card rounded-corners-gradient-borders">
                    <div className="card-body px-3">
                      <div className="media follow_media">
                        <div className="img_media_new  mr-3">
                          <img src={Userimg} alt="User" className="img-fluid" />
                          {/* <img src={require("../assets/images/notable_img.png")} alt="User" className="img-fluid" /> */}
                        </div>

                        <div className="media-body flex_body">
                          <div>
                            <p className="mt-0 media_text">{Username}</p>
                            <p className="mt-0 media_num font_14 mb-0">
                              {user.Followers && user.Followers.count
                                ? user.Followers.count
                                : 0}{" "}
                              followers
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
      </>
    );
  };
  //Component
  const ActivityDetails = () => {
    return (
      <>
        {Loader ? (
          <>
            <div className="text-center py-5">
              <TailSpin
                wrapperClass="searreactloader"
                color="#00BFFF"
                height={100}
                width={70}
              />
            </div>
          </>
        ) : (
          <>
            {MyUserFollow &&
              MyUserFollow[TabName] &&
              TabName === "activity" &&
              MyUserFollow[TabName].length > 0 ? (
              MyUserFollow[TabName].map((act) => {
                // console.log("innnfiknsfkneinact", act);
                return (
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-10 col-xl-9 mx-auto mb-4">
                      <ActivityCard Activity={act} />
                    </div>
                  </div>
                );
              })
            ) : (
              <BrowseMarketPlace />
            )}
          </>
        )}
      </>
    );
  };
  //Component
  const BrowseMarketPlace = () => {
    return (
      <div className="text-center py-5">
        <p className="not_found_text">No items found</p>
        <p className="not_found_text_sub">
          Come back soon! Or try to browse something for you on our marketplace
        </p>
        <div className="mt-3">
          <Link to="/explore/All">
            <Button className="browsematdk">Browse Marketplace</Button>
          </Link>
        </div>
      </div>
    );
  };

  const VaultNFTAction = async () => {
    console.log("dvjksfnjksnfkjs", Wallet_Details.VaultNFts);
    var Arg = {
      NFT: Wallet_Details.VaultNFts,
      curraddress: Wallet_Details.UserAccountAddr,
      Status: "Hide"
    }
    console.log("asdasdaszxczxcdasd");
    if (Wallet_Details.UserAccountAddr) {
      var VaultDataApi = await VaultApi(Arg)
      console.log("VaultDataApi", VaultDataApi);
      if (VaultDataApi.data.sucess) {
        //   toast.success(VaultDataApi.data.Message)
        //   history.push({
        //     pathname: "/my-items",
        // })
        await MyItemTab("onsale", Wallet_Details.UserAccountAddr, "Profile");
        toast.success(VaultDataApi.data.Message)
        dispatch({
          type: Account_Connect,
          Account_Detail: {
            VaultNFts: []
          }
        })
      } else {
        toast.error(VaultDataApi.data.Message)
      }
    } else {
      toast.error("Refreash. And try it Again")
    }
  }

  useEffect(() => {
    setShowLoader(true)
    ExploreCardNFT()
  }, [CatName, Wallet_Details.UserAccountAddr])

  const ExploreCardNFT = async (Catpage) => {
    var Payload = { curraddress: Wallet_Details.UserAccountAddr, Category: CatName, Page: Catpage || 1, limit: 16, Vault: "Show", VaultStatus: "Vault", Exploree: "Myitem" }
    var NFT = await ExplorePage(Payload);
    console.log("NFTNFTNFT", NFT);
    if (NFT?.data?.Success) {
      if (NFT?.data?.records?.length > 0) {
        if (((ExploreCardNFTs[CatName].list.length !== 0) && (CatName === NFT.data.cat) && ((Catpage ? Catpage : ExploreCardNFTs[CatName].page) !== ExploreCardNFTs[CatName].page))) {
          ExploreCardNFTs[CatName].onmore = true
          ExploreCardNFTs[CatName].page = NFT.data.page
          ExploreCardNFTs[CatName].list = ExploreCardNFTs[CatName].list.concat(NFT.data.records)
          setExploreCardNFTs([])
          setExploreCardNFTs(ExploreCardNFTs);
        }
        else if (ExploreCardNFTs[CatName].list.length === 0) {
          ExploreCardNFTs[CatName].onmore = true
          ExploreCardNFTs[CatName].list = NFT.data.records
          setExploreCardNFTs([])
          setExploreCardNFTs(ExploreCardNFTs);
        }
      }
      if (NFT.data.records.length === 0) {
        ExploreCardNFTs[CatName].onmore = false
        setExploreCardNFTs([])
        setExploreCardNFTs(ExploreCardNFTs)
      }
      setTimeout(() => {
        setShowLoader(false)
      }, 1000);
    }
    else {
      toast.error("Oops..! Error Detected Refresh Page")
    }
  };

  const MyItemFractionalCard = () => {
    return (
      <>
        {Loader ? (
          <>
            <div className="text-center py-5">
              <TailSpin
                wrapperClass="searreactloader"
                color="#00BFFF"
                height={100}
                width={70}
              />
            </div>
          </>
        ) : (

          <>
            {ExploreCardNFTs &&
              ExploreCardNFTs['All'] &&
              ExploreCardNFTs['All'].list.length > 0 ? (
              <div className="row masonry m-0 ma_no_gap card_main_iner">
                {ExploreCardNFTs["All"].list.map((NFT) => {
                  return (
                    <ExploreCard_Fraction
                      Cardclass={
                        "item col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3"
                      }
                      NFTDetails={NFT}
                    />
                  );
                })}
                {/* <Card /> */}
              </div>
            ) : (
              <BrowseMarketPlace />
            )}
          </>

        )}

      </>
    );
  };

  return (
    <div className="home_header">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={
          <Link to="/home">
            <div className="header_logo_align">


            </div>
          </Link>
        }
        leftLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 20,
          color: "white",
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader items_header my_items"}>
        <div>
          <GridContainer className="mx-0">
            <GridItem xs={12} sm={12} md={12} className="px-0">
              <div className="items_bg">
                <div className="overlay_my_bg"></div>
                <img src={Cover} alt="profile" className="img-fluid" />
                <div className="container container_full edit_cover_container">
                  <p
                    className="edit_cover_text"
                    data-toggle="modal"
                    data-target="#edit_cover_modal"
                  >
                    Edit cover
                  </p>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="inner_pagh_bg_1">
          <div className="container container_full">
            <div className="item_prof">
              <div className="item_prof_img">
                <img
                  src={Pro}
                  alt="profile"
                  className="img-fluid items_profile"
                />
              </div>
            </div>
          </div>
          <div className="container container_full">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-4">
                <p className="my_items_head">{Formdata.Name}</p>
                <p className="my_items_head_1">{Formdata.CustomUrl}</p>

                <div className="input-group mb-2 inut_gro_stke_1">
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={Formdata.LocalAddr}
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                  />
                  <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon2">
                      <CopyToClipboard
                        text={Formdata.LocalAddr}
                        onCopy={() =>
                          toast.success("Address Copied", {
                            closeButton: true,
                            autoClose: 1500,
                            transition: Flip,
                            theme: "dark",
                          })
                        }
                      >
                        <img
                          src={require("../assets/images/copy_icon.png")}
                          className="img-fluid cur_pointer"
                        />
                      </CopyToClipboard>
                    </span>
                  </div>
                </div>
                {Formdata.Site && (
                  <a href={Formdata.Site} target="_blank">
                    <p className="my_items_head_1">
                      Personal Site:{" "}
                      <span className="text-info">
                        {Formdata.Site.split("/")[2]}
                      </span>
                    </p>
                  </a>
                )}
              </div>
              <div className="col-12 col-md-5 col-lg-5">
                <div className="join_head">
                  <span className="mybiod">Joined {Formdata.Joined}</span>

                </div>
                <p className="my_items_desc_1 mt-2 mybiods viewmoretxt">   <ReactReadMoreReadLess
                  charLimit={400}
                  readMoreText={"Read more ▼"}
                  readLessText={"Read less ▲"}
                >
                  {Formdata.Bio}
                </ReactReadMoreReadLess></p>
                {/* <p className="my_items_desc_1 mt-2 mybiods"></p> */}
                {/* {console.log("asdasdasdadsada",Formdata)} */}
                <p>
                  {Formdata.Twitter !== "" && (
                    <a
                      href={Formdata.Twitter}
                      target="_blank"
                      className="social_a_icon"
                    >
                      <img
                        src={require("../assets/images/twitter_icon.png")}
                        className="img-fluid"
                      />
                      <span>{Formdata.Twitter.split("/")[3]}</span>
                    </a>
                  )}
                  {Formdata.Instagram !== "" && (
                    <a
                      href={Formdata.Instagram}
                      target="_blank"
                      className="social_a_icon pl-3"
                    >
                      <img
                        src={require("../assets/images/insta_icon.png")}
                        className="img-fluid"
                      />
                      <span>{Formdata.Instagram.split("/")[3]}</span>
                    </a>
                  )}
                  {Formdata.Facebook !== "" && (
                    <a
                      href={Formdata.Facebook}
                      target="_blank"
                      className="social_a_icon pl-3"
                    >
                      <img
                        src={require("../assets/images/Face-icon.png")}
                        className="img-fluid"
                      />
                      <span>{Formdata.Facebook.split("/")[3]}</span>
                    </a>
                  )}
                  {Formdata.Youtube !== "" && (
                    <a
                      href={Formdata.Youtube}
                      target="_blank"
                      className="social_a_icon pl-3"
                    >
                      <img
                        src={require("../assets/images/youtube-pl.png")}
                        className="img-fluid"
                      />
                      <span>{Formdata.Youtube.split("/")[3]}</span>
                    </a>
                  )}
                </p>
              </div>
              <div className="col-12 col-md-3 col-lg-3 rightsl">
                {console.log("adasdasdasdas", Wallet_Details.UserAccountAddr, Formdata.curraddress)}
                {Wallet_Details.UserAccountAddr !== Formdata.LocalAddr && (
                  <button
                    className="create_btn btn_rect myclass"
                    disabled={FollowButState}
                    onClick={() => FollowAct()}
                  >
                    <i className="fas fa-user-plus pr-2"></i>
                    {FollowState ? "Following" : "Follow"}
                  </button>
                )}

                <button
                  className="create_btn btn_rect ml-2"
                  data-toggle="modal"
                  data-target="#share_modal"
                  onClick={() => ShareNowRef.current.ShareSocial_Click(window.location.href, Formdata.Name, "myitem")}
                >
                  <i className="fas fa-upload pr-2"></i>
                  Share
                </button>
                {Wallet_Details.UserAccountAddr == Formdata.LocalAddr && (
                  <Link to={"/vault"}>
                    <button
                      className="create_btn btn_rect ml-2"
                    >
                      <i class="fa-solid fa-shield pr-2"></i>
                      Vault
                    </button>
                  </Link>)}
              </div>
            </div>
          </div>
          <div className="container container_full">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <nav className="masonry_tab_nav mt-4 mb-3 items_tab_outer">
                  <div
                    className="nav nav-tabs masonry_tab primary_tab myitemstab items_tab justify-content-center items_tab_new pb-0 pl-0"
                    id="nav-tab"
                    role="tablist"
                  >
                    <a
                      onClick={() => {
                        MyItemTab("onsale");
                      }}
                      className="nav-link active"
                      id="onsale-tab"
                      data-toggle="tab"
                      href="#onsale"
                      role="tab"
                      aria-controls="onsale"
                      aria-selected="true"
                    >
                      <div className="tab_head">OnSale</div>
                    </a>
                    <a
                      onClick={() => {
                        MyItemTab("created");
                      }}
                      className="nav-link"
                      id="created-tab"
                      data-toggle="tab"
                      href="#created"
                      role="tab"
                      aria-controls="created"
                      aria-selected="false"
                    >
                      <div className="tab_head">Created</div>
                    </a>
                    <a
                      onClick={() => {
                        MyItemTab("collectibles");
                      }}
                      className="nav-link"
                      id="collectibles-tab"
                      data-toggle="tab"
                      href="#collectibles"
                      role="tab"
                      aria-controls="collectibles"
                      aria-selected="false"
                    >
                      <div className="tab_head">Collectibles</div>
                    </a>

                    <a
                      onClick={() => {
                        MyItemTab("liked");
                      }}
                      className="nav-link"
                      id="liked-tab"
                      data-toggle="tab"
                      href="#liked"
                      role="tab"
                      aria-controls="liked"
                      aria-selected="false"
                    >
                      <div className="tab_head">Liked</div>
                    </a>
                    {Wallet_Details.UserAccountAddr ===
                      Formdata.LocalAddr && (
                        <a
                          onClick={() => {
                            MyItemTab("activity");
                          }}
                          className="nav-link"
                          id="activity-tab"
                          data-toggle="tab"
                          href="#activity"
                          role="tab"
                          aria-controls="activity"
                          aria-selected="false"
                        >
                          <div className="tab_head">Activity</div>
                        </a>
                      )}
                    <a
                      onClick={() => {
                        MyItemTab("following");
                      }}
                      className="nav-link"
                      id="following-tab"
                      data-toggle="tab"
                      href="#following"
                      role="tab"
                      aria-controls="following"
                      aria-selected="false"
                    >
                      <div className="tab_head">
                        Following
                        <span className="tab_count_badge">
                          {(Formdata.Count && Formdata.Count.Followings) || 0}
                        </span>
                      </div>
                    </a>
                    <a
                      onClick={() => {
                        MyItemTab("followers");
                      }}
                      className="nav-link"
                      id="followers-tab"
                      data-toggle="tab"
                      href="#followers"
                      role="tab"
                      aria-controls="followers"
                      aria-selected="false"
                    >
                      <div className="tab_head">
                        Followers
                        <span className="tab_count_badge">
                          {(Formdata.Count && Formdata.Count.Followers) || 0}
                        </span>
                      </div>
                    </a>

                    <a
                      onClick={() => {
                        MyItemTab("Ticket");
                      }}
                      className="nav-link"
                      id="Ticket-tab"
                      data-toggle="tab"
                      href="#Ticket"
                      role="tab"
                      aria-controls="Ticket"
                      aria-selected="false"
                    >
                      <div className="tab_head">Ticket</div>
                    </a>
                    <a
                      onClick={() => {
                        MyItemTab("collections");
                        CollectionFun(Wallet_Details.UserAccountAddr);
                      }}
                      className="nav-link"
                      id="collections-tab"
                      data-toggle="tab"
                      href="#collecionsimg"
                      role="tab"
                      aria-controls="collection"
                      aria-selected="false"
                    >
                      <div className="tab_head">Collections</div>
                    </a>
                    <a
                      onClick={() => {
                        MyItemTab("SoulBound");
                        SoulBoundCollectionFun(Wallet_Details.UserAccountAddr);
                      }}
                      className="nav-link"
                      id="collections-tab"
                      data-toggle="tab"
                      href="#collecionsimg"
                      role="tab"
                      aria-controls="collection"
                      aria-selected="false"
                    >
                      <div className="tab_head">Non-Transferrable Collection</div>
                    </a>
                    <a
                      onClick={() => {
                        MyItemTab("Certificates");
                        SoulBoundOwnerFun(Wallet_Details.UserAccountAddr);
                      }}
                      className="nav-link"
                      id="collections-tab"
                      data-toggle="tab"
                      href="#collecionsimg"
                      role="tab"
                      aria-controls="collection"
                      aria-selected="false"
                    >
                      <div className="tab_head">Certificate</div>
                    </a>
                    <a
                      onClick={() => {
                        MyItemTab("Owned");
                      }}
                      className="nav-link"
                      id="Owned-tab"
                      data-toggle="tab"
                      href="#Owned"
                      role="tab"
                      aria-controls="Owned"
                      aria-selected="false"
                    >
                      <div className="tab_head">Owned</div>
                    </a>


                    <a
                      onClick={() => {
                        MyItemTab("fractions");
                      }}
                      className="nav-link"
                      id="fractions-tab"
                      data-toggle="tab"
                      href="#fractions"
                      role="tab"
                      aria-controls="fractions"
                      aria-selected="true"
                    >
                      <div className="tab_head">Fractions</div>
                    </a>

                  </div>
                </nav>
                <div
                  className="tab-content explore_tab_content  myitemsdf"
                  id="nav-tabContent"
                >
                  <div
                    className="tab-pane fade show active"
                    id={TabName}
                    role="tabpanel"
                    aria-labelledby={`${TabName}-tab`}
                  >
                    <div className="proposal_panel_overall">
                      {TabName === "onsale" ||
                        TabName === "created" ||
                        TabName === "collectibles" ||
                        TabName === "liked" ? (
                        <>
                          <MyItemCard />
                          <div className="row">
                            {/* <Card /> */}
                          </div>
                          {MyItemNFTs[TabName] && MyItemNFTs[TabName].onmore &&
                            <div className="col-12 pb-5 text-center pt-4">
                              <button className="create_btn"
                                onClick={() => MyItemTab(TabName, Formdata.LocalAddr, null, MyItemNFTs[TabName].page + 1)}>
                                Load more items
                              </button>
                            </div>
                          }
                        </>
                      ) : (
                        <>
                          {TabName === "followers" ||
                            TabName === "following" ? (
                            <>
                              {Loader ? (
                                <div className="text-center py-5">
                                  <TailSpin
                                    wrapperClass="searreactloader"
                                    color="#00BFFF"
                                    height={100}
                                    width={70}
                                  />
                                </div>
                              ) : (
                                <div className="followers_overall py-1">
                                  {MyUserFollow &&
                                    MyUserFollow[TabName] &&
                                    MyUserFollow[TabName].length > 0 ? (
                                    <>
                                      <div className="row mt-3">
                                        <FollowDetails />
                                      </div>
                                      <div className="col-12 pb-5 text-center pt-4">
                                        <button className="create_btn">
                                          Load more items
                                        </button>
                                      </div>
                                    </>
                                  ) : (
                                    <BrowseMarketPlace />
                                  )}
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              {TabName === "activity" ? (
                                <div className="followers_overall py-1">
                                  <div className="row">
                                    <div className="col-12 col-md-12 col-lg-10 col-xl-9 mx-auto mb-4">
                                      <p className="join_head mt-0">Filters</p>
                                      <div className="my-4 btn_filter_row">
                                        <Button
                                          onClick={() => {
                                            MyItemTab(
                                              "activity",
                                              Wallet_Details.UserAccountAddr
                                            );
                                            setItems("All")
                                          }}
                                          className={items == "All" ? "browsematdk mb-3 active" : "browsematdk mb-3"}
                                        >
                                          All
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            MyItemTab(
                                              "activity",
                                              Wallet_Details.UserAccountAddr,
                                              "Profile"
                                            );
                                            setItems("Profile")
                                          }}
                                          className={items == "Profile" ? "browsematdk ml-3 mb-3 active" : "browsematdk ml-3 mb-3"}
                                        >
                                          Profile
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            MyItemTab(
                                              "activity",
                                              Wallet_Details.UserAccountAddr,
                                              "Like"
                                            );
                                            setItems("Like")
                                          }}
                                          className={items == "Like" ? "browsematdk ml-3 mb-3 active" : "browsematdk ml-3 mb-3"}
                                        >
                                          Like
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            MyItemTab(
                                              "activity",
                                              Wallet_Details.UserAccountAddr,
                                              "Mint"
                                            );
                                            setItems("Mint")
                                          }}
                                          className={items == "Mint" ? "browsematdk ml-3 mb-3 active" : "browsematdk ml-3 mb-3"}
                                        >
                                          Mint
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            MyItemTab(
                                              "activity",
                                              Wallet_Details.UserAccountAddr,
                                              "Follow"
                                            );
                                            setItems("Followings")
                                          }}
                                          className={items == "Followings" ? "browsematdk ml-3 mb-3 active" : "browsematdk ml-3 mb-3"}
                                        >
                                          Followings
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                  <ActivityDetails />
                                  <div className="col-12 pb-5 text-center pt-4">
                                    <button className="create_btn">
                                      Load more items
                                    </button>
                                  </div>
                                </div>
                              ) : (TabName === "collections") ? <>
                                <div id="collections-tab" >
                                  <div className={classes.pageHeader + " inner_pageheader"}>
                                    <div className="container-fluid custom-container">
                                      <p className="not_found_text text-center"></p>
                                      {ListData.length > 0 ?
                                        <div className="row mb-5">
                                          {/* {console.log("weqwkejqwekw", ListData)} */}

                                          {ListData.length > 0 && ListData.map((Item, index) => {
                                            // console.log("Bulkminting for Listing componrnt", Item);
                                            return (
                                              <div className="col-lg-3 col-md-6 col-12 mb-4">
                                                <div className="collectionspaed">
                                                  <Link to={`/collectioncard/${Item.Address}/${Item.CollectionName}`}>
                                                    <div className="items_bg">
                                                      <div className="overlay_my_bg"></div>
                                                      {
                                                        Item.CoverImg === "" &&
                                                        <img src={Banner} alt="profile" className="img-fluid" />
                                                      }
                                                      {
                                                        Item.CoverImg !== "" &&
                                                        <img src={`${config.Back_URL}/BulkListing/${Item.Address}/CoverImg/${Item.CoverImg}`} />
                                                      }

                                                      <div className="container container_full edit_cover_container">
                                                        {/* <p
                    className="edit_cover_text"
                    data-toggle="modal"
                    data-target="#edit_cover_modal"
                  >
                    Edit cover
                  </p> */}
                                                      </div>
                                                    </div>
                                                  </Link>
                                                  <div className="collectionsimgecontent">
                                                    {/* 
                                                    {console.log("sdadfsfwefwfrsf", `${config.Back_URL}/BulkListing/${Item.Address}/ProfileImg/${Item.ProfileImg}`)} */}
                                                    {
                                                      Item.ProfileImg === "" &&
                                                      <img src={Profile} className="collectionsimgs" />
                                                    }
                                                    {
                                                      Item.ProfileImg !== "" &&
                                                      <img src={`${config.Back_URL}/BulkListing/${Item.Address}/ProfileImg/${Item.ProfileImg}`} />
                                                    }
                                                    <div>
                                                      <h2>{Item?.CollectionName?.slice(0, 15)}</h2>
                                                      {/* <h3>25 Items</h3> */}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          })}
                                        </div>
                                        :
                                        <BrowseMarketPlace />
                                      }
                                    </div>
                                  </div>
                                </div>
                              </>

                                : (TabName === "SoulBound") ?
                                  <>
                                    <div id="collections-tab" >
                                      <div className={classes.pageHeader + " inner_pageheader"}>
                                        <div className="container-fluid custom-container">
                                          <p className="not_found_text text-center"></p>
                                          {SoulListData.length > 0 ?
                                            <div className="row mb-5">
                                              {/* {console.log("weqwkejqwekw", ListData)} */}

                                              {SoulListData.length > 0 && SoulListData.map((Item, index) => {
                                                // console.log("Bulkminting for Listing componrnt", Item);
                                                return (
                                                  <div className="col-lg-3 col-md-6 col-12 mb-4">
                                                    <div className="collectionspaed">
                                                      <Link to={`/certificate/${Item.Address}/${Item.CollectionName}`}>
                                                        <div className="items_bg">
                                                          <div className="overlay_my_bg"></div>
                                                          {
                                                            Item.CoverImg === "" &&
                                                            <img src={Banner} alt="profile" className="img-fluid" />
                                                          }
                                                          {
                                                            Item.CoverImg !== "" &&
                                                            <img src={`${config.Back_URL}/Soulbound/${Item.Address}/CoverImg/${Item.CoverImg}`} />
                                                          }

                                                          <div className="container container_full edit_cover_container">
                                                            {/* <p
                    className="edit_cover_text"
                    data-toggle="modal"
                    data-target="#edit_cover_modal"
                  >
                    Edit cover
                  </p> */}
                                                          </div>
                                                        </div>
                                                      </Link>
                                                      <div className="collectionsimgecontent">
                                                        {/* 
                                                    {console.log("sdadfsfwefwfrsf", `${config.Back_URL}/BulkListing/${Item.Address}/ProfileImg/${Item.ProfileImg}`)} */}
                                                        {
                                                          Item.ProfileImg === "" &&
                                                          <img src={Profile} className="collectionsimgs" />
                                                        }
                                                        {
                                                          Item.ProfileImg !== "" &&
                                                          <img src={`${config.Back_URL}/Soulbound/${Item.Address}/ProfileImg/${Item.ProfileImg}`} />
                                                        }
                                                        <div>
                                                          <h2>{Item?.CollectionName?.slice(0, 15)}</h2>
                                                          {/* <h3>25 Items</h3> */}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )
                                              })}
                                            </div>
                                            :
                                            <BrowseMarketPlace />
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                  : (TabName === "Certificates") ?
                                    <>

                                      <div className="container-fluid custom-container mb-5 mt-5">
                                        <div className="tickets_collection">
                                          <div className="row">

                                            {SoulListOwn && SoulListOwn.length > 0 &&
                                              SoulListOwn.map((Item, index) => {
                                                // console.log("Ticketcategory for ticket componrnt", Item);
                                                return (
                                                  <Collectioncard
                                                    Item={Item} />
                                                )
                                              })}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                    : (TabName === "Owned") ?
                                      <>
                                        <div className="followers_overall py-1">
                                          <div className="row">
                                            <div className="col-12 col-md-12 col-lg-10 col-xl-9 mx-auto mb-4">
                                              <div className="d-flex justify-content-between align-items-center">
                                                <p className="join_head mt-0">Filters</p>
                                                <Button
                                                  onClick={() => {
                                                    VaultNFTAction()
                                                  }}
                                                  className={items == "AddToVault" ? "browsematdk ml-3 mb-3 active" : "browsematdk ml-3 mb-3"}
                                                >
                                                  Add to Vault
                                                </Button>
                                              </div>

                                              <div className="my-4 btn_filter_row">
                                                <Button
                                                  onClick={() => {
                                                    MyItemTab(
                                                      "Owned",

                                                    );
                                                    setItems("All")
                                                  }}
                                                  className={items == "All" ? "browsematdk mb-3 active" : "browsematdk mb-3"}
                                                >
                                                  All
                                                </Button>
                                                <Button
                                                  onClick={() => {
                                                    MyItemTab(
                                                      "Soulbound",
                                                      Wallet_Details.UserAccountAddr,

                                                    );
                                                    setItems("Soulbound")
                                                  }}
                                                  className={items == "Soulbound" ? "browsematdk ml-3 mb-3 active" : "browsematdk ml-3 mb-3"}
                                                >
                                                  Certificate
                                                </Button>


                                              </div>
                                            </div>
                                          </div>
                                          {console.log("asdSWRQWsaAD", items)}
                                          {items == "All" ?
                                            <>
                                              <Vault />
                                              {MyItemNFTs[TabName] && MyItemNFTs[TabName].onmore &&
                                                <div className="col-12 pb-5 text-center pt-4">
                                                  <button className="create_btn"
                                                    onClick={() => MyItemTab(TabName, Formdata.LocalAddr, null, MyItemNFTs[TabName].page + 1)}>
                                                    Load more items
                                                  </button>
                                                </div>
                                              }
                                            </> :
                                            <>
                                              <div className="container-fluid custom-container mb-5 mt-5">
                                                <div className="tickets_collection">
                                                  <div className="row">

                                                    {SoulListOwn && SoulListOwn.length > 0 &&
                                                      SoulListOwn.map((Item, index) => {
                                                        console.log("Ticketcategory for ticket componrnt", Item);
                                                        return (
                                                          <VaultCollCard
                                                            Item={Item} />
                                                        )
                                                      })}
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          }
                                        </div>

                                      </>
                                      :
                                      (TabName === "fractions") ?
                                        <>
                                          <MyItemFractionalCard />
                                        </>
                                        :
                                        <>
                                          <Ticketss />
                                          <div className="row">
                                            {/* <Card /> */}
                                          </div>

                                        </>}

                            </>

                          )}
                        </>


                      )
                      }
                    </div>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
      {/* edit_cover Modal */}
      <div
        className="modal fade primary_modal"
        id="edit_cover_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="edit_cover_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="edit_cover_modalLabel_1">
                Update cover
              </h5>
              <h5 className="modal-title d-none" id="edit_cover_modalLabel_2">
                Follow Steps
              </h5>

              <button
                id="coverchoose"
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="update_cover_div_1" id="update_cover_div_1">
                <p className="mt-0 approve_desc text-center mb-4">
                  Upload new cover for your profile page. We recommended to
                  upload images in 1140×260 resolution
                </p>
                <form>
                  <div className="file_btn btn primary_btn">
                    Choose image
                    <input
                      className="inp_file"
                      onChange={(e) => Handlefile(e)}
                      type="file"
                      name="file"
                    />
                  </div>
                </form>
              </div>
              <div
                className="update_cover_div_2 d-none"
                id="update_cover_div_2"
              >
                <div className="media approve_media">
                  {/* <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i> */}
                  <i
                    className="fa fa-spinner mr-3 spinner_icon"
                    aria-hidden="true"
                  ></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Preferences</p>
                    <p className="mt-0 approve_desc">Updating cover</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="btn_outline_red btn-block">
                    Inprogress
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end edit_cover modal */}
      <Share ref={ShareNowRef} />
      <Modal
        isOpen={ShowModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {theme === "#000" ?
          <div className="modal-body text-center loading_bgcolor">
            <div className="laodingpart">
              <img src={require("../assets/images/lazymintingnew/logo.png")} alt="Profile" className="img-fluid items_profile smal_main_lm" />

            </div>
          </div> :

          <div className="modal-body text-center loading_bgcolor">
            <div className="laodingpart">
              <img src={require("../assets/images/lazymintingnew/logodark.png")} alt="Profile" className="img-fluid items_profile smal_main_lm" />
            </div>
          </div>

        }

      </Modal>
    </div>
  );
}
