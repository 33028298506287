import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Button, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTokenDecimal, usePrevious, useNFTBalCheck, useBulBalnceCheck } from "hooks/useMethods";
import { usePutonSaleReceipt } from "hooks/useReceipts";
import {
  YouwillGetCalculation,
  PurchaseCalculation,
} from "actions/controller/PriceCalculate";
import Web3 from 'web3';
import { v1_UpdateNFTState, v1_ConNFTBalCheck, LMSignCall, ContactMint, LMBalanceOf, LMContactCalls, LMApproveCheck } from "actions/v1/token";
import { useCancelOrderReceipt } from "hooks/useReceipts";
import { Audio, TailSpin } from "react-loader-spinner";
import { PopupValidation } from "actions/controller/validation";
import { toast } from "react-toastify";
import config from "lib/config";
import { network } from "../../views/network"
import { connectWallet, WalletConnect, getServiceFees } from '../../hooks/useWallet';
import { Account_Connect, Account_disConnect } from "actions/redux/action";
import Abi721 from '../../ABI/721.json'
import Abi1155 from '../../ABI/1155.json'
import randomInteger from 'random-int';
export const PlaceandCancel = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const history = useHistory();
  const [NewTokenDecimal, setNewTokenDecimal] = useState("");
  const [NFTImage, setNFTImage] = useState("");
  const [NFT, setNFT] = useState({ CurrentOwner: {} });
  const [TokenPrice, setTokenPrice] = useState(0);
  const prevTokenPrice = usePrevious(TokenPrice);
  const [Coinname, setCoinname] = useState(config.currencySymbol)
  const [NFTDet, setNFTDet] = useState({});
  const [approvecheck, setapprovecheck] = useState()
  const [GetPrices, setGetPrices] = useState({
    Youwillpay: 0,
    PriceWithSerfee: "",
    ApprovePriceWithSerfee: "",
    PriceWithoutSerfee: "",
  });
  const [ApproveCallStatus, setApproveCallStatus] = React.useState('init');
  const [Prices, setPrices] = useState({
    Youwillpay: 0,
    PriceWithSerfee: "",
    ApprovePriceWithSerfee: "",
    PriceWithoutSerfee: "",
  });
  const [PlaceSate, setPlaceSate] = useState(false);
  const [CancelSate, setCancelSate] = useState(false);

  const [Fiat, setFiat] = useState(false);

  const [SignatureHash, setSignatureHash] = useState('');
  const [NonceHash, setNonceHash] = useState(0);
  const [RandomName, setRandomName] = useState('')
  const [SignTxt, SetSignTxt] = useState("Start");
  const [CloseStatus, setCloseStatus] = useState(true)

  useImperativeHandle(ref, () => ({
    async PlaceandCancelClick(ClickedNFT, NFTimage, NFT, cancel) {
      console.log("nft hashvalue", NFT, ClickedNFT)
      var chainid = NFT?.SelectedNetwork
      const chainId = await Wallet_Details.Web3Pro.eth.getChainId();
      console.log("fgndfgn", chainid, Wallet_Details.networkConfiguration);

      var networkConfiguration = {}
      if (Number(chainid) === network.MATIC.Chainid) {
        console.log("netter eorrrrkkk maticz", network.MATIC);
        networkConfiguration = network.MATIC
        // Serfee(network.MATIC.Chainid);
        var servive = await Serfee(network.MATIC.Chainid);
        console.log("sdfasfsdfsdfsfsdf", servive);
        Wallet_Details.networkConfiguration = network.MATIC
        Wallet_Details.Service_Fee = servive.Serfee
      }
      else if (Number(chainid) === network.ETH.Chainid) {
        console.log("netter eorrrrkkk", network.ETH);
        networkConfiguration = network.ETH
        var servive = await Serfee(network.ETH.Chainid);
        Wallet_Details.networkConfiguration = network.ETH
        Wallet_Details.Service_Fee = servive.Serfee
      }
      else if (Number(chainid) === network.CAM.Chainid) {
        console.log("netter eorrrrkkk", network.CAM);
        networkConfiguration = network.CAM
        var servive = await Serfee(network.CAM.Chainid);
        Wallet_Details.networkConfiguration = network.CAM
        Wallet_Details.Service_Fee = servive.Serfee
      }

      var approvedata = {
        curradd: Wallet_Details.UserAccountAddr,
        tradeadd: networkConfiguration.TradeContract
      }
      console.log("asdsadasdsadasdas", networkConfiguration);

      var check = await orderApprovecheck(approvedata, NFT);
      // console.log("dnfksdjnfkjsdnfk", check);
      setapprovecheck(check)
      setNFTImage(NFTimage);
      setNFTDet(NFT);

      var accountDetailsss = ''

      if (Number(chainId) == Number(NFT?.SelectedNetwork)) {
        accountDetailsss = true
      }
      else {
        var accountDetailsss = await switchNetwork(chainid, networkConfiguration)
      }
      setFiat(ClickedNFT.Fiat)
      if (Number(chainid) === Number(chainId)) {
        if (Wallet_Details.UserAccountAddr) {
          // console.log("adkfndfnks",);
          if (cancel != 'Cancel') {

            window.$('#change_price_modal').modal('show');
          } else {
            window.$('#cancel_order_modal').modal('show');
          }
          setNFT(ClickedNFT);
          console.log("sdjglsdljgslgjkdlgskj", NFT.tokenRoyality, Wallet_Details);
          var data = {
            Price: TokenPrice,
            Quantity: 1,
            Serfee: Wallet_Details.Service_Fee,
            Decimal: 18,
            tokenRoyality: NFT.tokenRoyality
          };
          console.log("sdfsadfadadasasd", data);
          var Data = YouwillGetCalculation(data);
          var Data2 = PurchaseCalculation(data);
          setPrices(Data2);
          setGetPrices(Data);
          // console.log("jdsnbfufnuewrgn", ClickedNFT, NFTimage, Data);
        }
      }
    },
  }));


  async function switchNetwork(configdata, networkConfiguration) {
    var type = ""
    var networkConfiguration = {}
    const chainId = await Wallet_Details.Web3Pro.eth.getChainId();
    console.log("adsadasdasd", configdata, chainId, networkConfiguration);
    // console.log("sdnfvnfkvnfs", configdata);
    if (localStorage.walletConnectType !== "Torus") {
      if (Number(configdata) !== Number(chainId)) {
        const id = toast.loading("Switching Network", { closeButton: true, theme: "dark" });

        // console.log("sdnfvnfkvnfs", configdata);
        if (configdata) {
          if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask') {

            type = "MetaMask"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'WalletConnect' && localStorage.walletConnectType != null) {
            type = "WalletConnect"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'Venly' && localStorage.walletConnectType != null) {
            type = "Venly"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'Torus' && localStorage.walletConnectType != null) {
            type = "Torus"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'TorusWallet' && localStorage.walletConnectType != null) {
            type = "TorusWallet"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'LMWallet' && localStorage.walletConnectType != null) {
            console.log("adasd comming on torus");
            type = "LMWallet"
            localStorage.setItem("ChainId", NFTDet.SelectedNetwork)
          }
          // console.log("connecttype............", type, configdata)
          var accountDetails = await connectWallet(type, Number(configdata), localStorage.walletConnectType == "LMWallet" ? "register" : "Create", Wallet_Details, "Tor")
          // console.log("accountDetailsin create page......................", accountDetails)

          // var web3 = new Web3(window.ethereum);
          // console.log("dfghrtfh", web3);
          // if (window.ethereum.isMetaMask == true) {
          const chainId = await accountDetails?.web3?.eth?.getChainId();
          // console.log("fghdtgj", chainId);

          if (Number(configdata) === network.MATIC.Chainid) {
            networkConfiguration = network.MATIC
          }
          else if (Number(configdata) === network.ETH.Chainid) {
            networkConfiguration = network.ETH
          }
          else if (Number(configdata) === network.CAM.Chainid) {
            networkConfiguration = network.CAM
          }
          // }
          // setPriceoption()
          // console.log("dsnsdkngksfnvkfdn", networkConfiguration);

          if (accountDetails != '' && accountDetails?.web3?._provider != '') {

            // console.log("Wallet Connecting...increate", accountDetails?.web3?._provider);
            dispatch({
              type: Account_Connect,
              Account_Detail: {
                UserAccountAddr: accountDetails.accountAddress,
                UserAccountBal: accountDetails.coinBalance,
                WalletConnected: "true",
                Wen_Bln: accountDetails.tokenBalance,
                Accounts: accountDetails.accountAddress,
                Web3Pro: accountDetails?.web3,
                providerss: accountDetails?.web3?._provider,
                networkConfiguration: networkConfiguration
              }
            })
            Serfee(networkConfiguration.Chainid);
          }

          if (networkConfiguration.currencySymbol) {

            toast.update(id, {
              render: "Network switched Succesfully",
              type: "success",
              isLoading: false,
              autoClose: 2500,
              closeButton: true,
              theme: "dark"
            });
          }
        }

        return accountDetails
      } else {
        dispatch({
          type: Account_Connect,
          Account_Detail: {
            UserAccountAddr: Wallet_Details?.UserAccountAddr,
            UserAccountBal: Wallet_Details?.UserAccountBal,
            WalletConnected: "true",

            Wen_Bln: Wallet_Details?.Wen_Bln,
            Accounts: Wallet_Details?.Accounts,

            Web3Pro: Wallet_Details?.Web3Pro,
            providerss: Wallet_Details?.providerss,
            networkConfiguration: networkConfiguration
          }
        })
        Serfee(networkConfiguration.Chainid);
        return Wallet_Details.Web3Pro
      }
    } else {
      try {
        const id = toast.loading("Switching Network", { closeButton: true, theme: "dark" });
        var accountDetails = await connectWallet(localStorage.walletConnectType, Number(configdata), "Create", Wallet_Details, "Torus")
        console.log("asdasdasdasdas", accountDetails);
        const chainId = await accountDetails?.web3?.eth?.getChainId();
        console.log("fghdtgj", configdata, network, chainId);

        if (Number(configdata) === network.MATIC.Chainid) {
          networkConfiguration = network.MATIC
        }
        else if (Number(configdata) === network.ETH.Chainid) {
          networkConfiguration = network.ETH
        }
        else if (Number(configdata) === network.CAM.Chainid) {
          networkConfiguration = network.CAM
        }
        console.log("huiyooijmoijolmj", networkConfiguration);
        if (accountDetails != '') {
          dispatch({
            type: Account_Connect,
            Account_Detail: {
              UserAccountAddr: accountDetails?.accountAddress,
              UserAccountBal: accountDetails?.coinBalance,
              WalletConnected: "true",

              Wen_Bln: accountDetails?.tokenBalance,
              Accounts: accountDetails?.accountAddress,

              Web3Pro: accountDetails?.web3,
              providerss: accountDetails?.web3?._provider,
              networkConfiguration: networkConfiguration
            }
          })
        }
        var functiobn = await NefunctionTriger(Wallet_Details)
        console.log("Asdasdaeqweqwe", networkConfiguration.currencySymbol);
        if (networkConfiguration.currencySymbol) {
          toast.update(id, {
            render: "Network switched Succesfully",
            type: "success",
            isLoading: false,
            autoClose: 2500,
            closeButton: true,
            theme: "dark"
          });
        }
      } catch (e) {
        console.log("adqeqweqweqweqweqweqwe", e);
      }
    }
  }

  const NefunctionTriger = async (Wallet_Details1) => {
    console.log("Wallet_Details1", Wallet_Details1);
    const chainIdSwitched = await Wallet_Details1?.Web3Pro?.eth?.getChainId();
    var balance = await Wallet_Details1?.Web3Pro?.eth.getBalance(Wallet_Details1.UserAccountAddr);
    console.log("chainIdSwitched", chainIdSwitched, balance);
    return chainIdSwitched
  }

  const Serfee = async (Address) => {
    console.log("AddressAddress", Address);
    var Fee = await getServiceFees(Address)
    console.log("ndkdsnfkdsn", Fee);
    dispatch({
      type: Account_Connect,
      Account_Detail: {
        Service_Fee: Fee?.Serfee,
        Service_Fee_Fraction: Fee?.SerfeeFraction
      }
    })
    return Fee
  }

  useEffect(() => {
    if (TokenPrice !== 0 && prevTokenPrice !== TokenPrice) {
      var data = {
        Price: TokenPrice,
        Quantity: 1,
        Serfee: Wallet_Details.Service_Fee,
        Decimal: 18,
      };
      var Data = YouwillGetCalculation(data);
      var Data2 = PurchaseCalculation(data);
      // console.log("Pricesssssss", Data2);
      setPrices(Data2);
      setGetPrices(Data);
    }
  }, [TokenPrice]);

  const ValidCheck = () => {
    var ValChk = {
      TokenPrice: TokenPrice,
      NoofToken: NFT.balance,
      type: NFT.type,
      MaxToken: NFT.balance,
      Fiat: Fiat
    };
    var Valid = PopupValidation(ValChk);
    // console.log("sdksdjnvksjd", Valid);
    if (Valid.Success) {
      var Check = (NFT.tokenPrice) ? NFT.tokenPrice < TokenPrice || NFT.tokenPrice == TokenPrice : false;
      if (Check) {
        toast.error("Price Must be Lower", { theme: "dark" });
      } else BalCheck("Place");
    } else toast.error(Valid.Message, { theme: "dark" });
  };

  const BalCheck = async (A) => {
    console.log("fdadasasdasd", NFTDet, NFT, Fiat);
    var Arg = {
      NFTConAddress: NFT.contractAddress,
      NFTId: NFT.tokenID,
      NFTOwner: NFT.tokenOwner,
      Status: NFT.LazyStatus,
      BulK: NFTDet.Bulkmint

    }
    var Type = NFT.type

    // newly added to avoid balcheck

    if (NFT.hashValue == "not yet minted" || Fiat) {
      noBalanceCall(A)
    } else {
      BalanceCall(A, Arg, Type)
    }


  }


  // normal function 
  const BalanceCall = async (A, Arg, Type) => {
    // alert("11")
    // console.log("PopupValidation", A);
    console.log("zxcczxczxxcxzczx", Arg, Type);
    var chainid = NFTDet?.SelectedNetwork
    var networkConfiguration = {}
    console.log("sdfsfdfsdfsdfs", Number(chainid), network.MATIC.Chainid);
    if (Number(chainid) === network.MATIC.Chainid) {
      console.log("placecall eorrrrkkk maticz", network.MATIC);
      networkConfiguration = network.MATIC
    }
    else if (Number(chainid) === network.ETH.Chainid) {
      console.log("netter eorrrrkkk", network.ETH);
      networkConfiguration = network.ETH
    }
    else if (Number(chainid) === network.CAM.Chainid) {
      console.log("netter eorrrrkkk", network.CAM);
      networkConfiguration = network.CAM
    }
    Wallet_Details.networkConfiguration = networkConfiguration
    // var ContractNFTBal = await useNFTBalCheck(Arg, Type, Wallet_Details)
    console.log("sdfwaewqedsadniuj", NFTDet.Bulkmint);
    if (NFTDet.Bulkmint !== "Bulk") {
      if (localStorage.walletConnectType !== "LMWallet") {
        var ContractNFTBal = await NFTHookFun(Arg, Type, Wallet_Details)
        console.log("sdqeqwwqeqwqweqw", ContractNFTBal);
      } else {
        var key = null
        var Argument = Type == 721 ? [Arg.NFTId] : [Arg.NFTOwner, Arg.NFTId]
        var ContractNFTBal = await LMBalanceCheck(Arg, Type, Wallet_Details, Arg.NFTConAddress, Type == 721 ? "Single" : "Multiple", Arg.NFTConAddress, Type == 721 ? "ownerOf" : "balanceOf", Argument, key, Arg.NFTOwner)
      }
    } else {
      if (localStorage.walletConnectType !== "LMWallet") {
        var ContractNFTBal = await NFTHookFun1(Arg, Type, Wallet_Details)
        console.log("adsasdasdasdasdad", ContractNFTBal);
      } else {
        var key = null
        var Argument = Type == 721 ? [Arg.NFTId] : [Arg.NFTOwner, Arg.NFTId]
        var ContractNFTBal = await LMBalanceCheck(Arg, Type, Wallet_Details, Arg.NFTConAddress, "Bulk", Arg.NFTConAddress, Type == 721 ? "ownerOf" : "balanceOf", Argument, key, Arg.NFTOwner)
      }
    }
    console.log("token ablance", ContractNFTBal)
    if (Number(ContractNFTBal) !== NFT.balance) {
      Arg.balance = Number(ContractNFTBal)
      var Updated = await v1_ConNFTBalCheck(Arg)
      // console.log("fjbdshfbsfhsr", Updated)
      if (Updated.data && Updated.data.Success) {
        if (A === "Place")
          $("#putonsale_close").trigger("click");
        else
          $("#cancel_closs").trigger("click");

        toast.error("Owners NFT Balance Changed. Try Later", { autoClose: 4000, closeButton: true })
        setTimeout(() => {
          history.push('/explore/All')
        }, 1500);
      }
      else {
        if (A === "Place")
          $("#putonsale_close").trigger("click");
        else
          $("#cancel_closs").trigger("click");

        toast.error("Error Occured. Try Later", { autoClose: 3000, closeButton: true })
        setTimeout(() => {
          history.push('/explore/All')
        }, 1500);
      }
    }
    else {
      if (A === "Place") {
        PlaceCallNormal()
      }
      else {
        CancelCallNormal()
      }
    }
  }

  const NFTHookFun = async (Arg, Type, Wallet_Details) => {
    var ContractNFTBal = await useNFTBalCheck(Arg, Type, Wallet_Details)
    return ContractNFTBal
  }

  const NFTHookFun1 = async (Arg, Type, Wallet_Details) => {
    var ContractNFTBal = await useBulBalnceCheck(Arg, Type, Wallet_Details)
    console.log("asdasddasdasdas", NFTHookFun1);
    return ContractNFTBal
  }

  // added to avoid balance check call from contract
  const noBalanceCall = async (A) => {
    // console.log("value of A", A)
    if (A === "Place") {
      PlaceCall()
    }
    else {
      CancelCall()
    }
  }

  const PlaceCall = async () => {
    setPlaceSate(true);
    var Arg = {
      NFTOwner: NFT.tokenOwner,
      NFTId: NFT.tokenID,
      PriceWithoutSerfee: Prices.PriceWithoutSerfee,
      PriceWithSerfee: Prices.PriceWithSerfee,
      NoofNFTs: 1,
      Status: NFT.LazyStatus,

    };
    var Type = NFT.type;
    var Provider = Wallet_Details;
    console.log("sdfnsdknfkdsf", NFT, NFTDet, Fiat);
    ////===> changed for lazymint <=====\\\\\

    // var Receipt = await usePutonSaleReceipt(Arg, Type, Provider);
    // if (Receipt.status) {
    var Hash = {
      SignatureHash: SignatureHash,
      NonceHash: NonceHash,
      RandomName: RandomName
    }
    console.log("Hash", Hash);
    if (true) {
      var Payload = {
        // Status: Receipt.status,
        // Hash: Receipt.transactionHash,
        // ConAddr: Receipt.to,
        Status: true,
        Hash: Hash,
        ConAddr: Type == 721 ? Wallet_Details.networkConfiguration.singleContract : Wallet_Details.networkConfiguration.multipleContract,
        tokenPrice: TokenPrice,
        NFTDetail: NFT,
        Owner: Wallet_Details.UserAccountAddr,
        tokenName: NFTDet.tokenName,
        SelectedNetwork: NFTDet.SelectedNetwork,
        coinname: Fiat ? "USD" : NFTDet.SelectedNetwork == config.ETH_ID ? config.Currency_ETH : NFTDet.SelectedNetwork == config.CAM_ID ?  config.Currency_CAM  :  config.Currency_MATIC,
        BuyType: Fiat ? "Fiat" : "Crypto",
        Fiat: Fiat
      };
      console.log("paylaod to db for putonsale updation", Payload)
      var Resp = await v1_UpdateNFTState(Payload);
      if (Resp) {
        $("#putonsale_close").trigger("click");
        setTimeout(() => {
          history.push(`/my-items/${Wallet_Details.UserAccountAddr}`);
        }, 1000);
      }
    }
  };

  const CancelCall = async () => {
    setCancelSate(true);
    var Arg = { NFTId: NFT.tokenID };
    var Type = NFT.type;
    var Provider = Wallet_Details;
    // var Receipt = await useCancelOrderReceipt(Arg, Type, Provider);

    // >>>.. changed for lazy mint <<<<<<  \\\
    if (true) {
      setCancelSate(false);
      var Payload = {
        // Status: Receipt.status,
        // Hash: Receipt.transactionHash,
        // ConAddr: Receipt.to,
        Status: true,
        Hash: false,
        ConAddr: Type == 721 ? Wallet_Details.networkConfiguration.singleContract : Wallet_Details.networkConfiguration.multipleContract,
        tokenPrice: 0,
        NFTDetail: NFT,
        Owner: Wallet_Details.UserAccountAddr,
        tokenName: NFTDet.tokenName,
        BuyType: "Crypto",
        Fiat: false
      };
      // console.log("db payload to cancel order", Payload)
      var Resp = await v1_UpdateNFTState(Payload);
      if (Resp) {
        $("#cancel_closs").trigger("click");
        setTimeout(() => {
          history.push(`/my-items/${Wallet_Details.UserAccountAddr}`);
        }, 1000);
      }
    }
  };

  // normal original unchanged marketplace functionalitites
  const PlaceCallNormal = async () => {
    // alert("placecallnormal")
    console.log("sknkjdfgfdkmngkdf", NFT);
    var chainid = NFTDet?.SelectedNetwork
    var networkConfiguration = {}
    console.log("sdfsfdfsdfsdfs", Number(chainid), network.MATIC.Chainid);
    if (Number(chainid) === network.MATIC.Chainid) {
      console.log("placecall eorrrrkkk maticz", network.MATIC);
      networkConfiguration = network.MATIC
    }
    else if (Number(chainid) === network.ETH.Chainid) {
      console.log("netter eorrrrkkk", network.ETH);
      networkConfiguration = network.ETH
    }
    else if (Number(chainid) === network.CAM.Chainid) {
      console.log("netter eorrrrkkk", network.CAM);
      networkConfiguration = network.CAM
    }

    setPlaceSate(true);
    Wallet_Details.networkConfiguration = networkConfiguration
    var Arg = {
      NFTOwner: NFT.tokenOwner,
      NFTId: NFT.tokenID,
      PriceWithoutSerfee: Prices.PriceWithoutSerfee,
      PriceWithSerfee: Prices.PriceWithSerfee,
      NoofNFTs: 1,
      ContractADD: NFT.contractAddress,
      Status: NFT.LazyStatus

    };

    console.log("adasffsdfcxzcxczx", NFT, Wallet_Details);
    var Type = NFT.type;
    var Provider = Wallet_Details;
    if (localStorage.walletConnectType !== "LMWallet") {
      var Receipt = await ContactHook(Arg, NFT.type, Provider)
    } else {
      var Key = null
      var Argument = [NFT.tokenID, Prices.PriceWithoutSerfee, NFT.contractAddress, NFT.tokenOwner, Type, "order"]
      var Receipt = await LMWalletCall(Arg, NFT.type, Wallet_Details, NFTDet, NFT, "Trade", Wallet_Details.networkConfiguration.TradeContract, Wallet_Details.networkConfiguration.TradeContract, Argument, "orderPlace", Key, false)
    }

    if (Receipt.status) {
      var Payload = {
        Status: Receipt.status,
        Hash: Receipt.transactionHash,
        ConAddr: Receipt.to,
        tokenPrice: TokenPrice,
        NFTDetail: NFT,
        Owner: Wallet_Details.UserAccountAddr,
        tokenName: NFTDet.tokenName,
        coinname: Fiat ? "USD" : NFTDet.SelectedNetwork == config.ETH_ID ? config.Currency_ETH :
        NFTDet.SelectedNetwork == config.CAM_ID ? config.Currency_CAM : config.Currency_MATIC,
        BuyType: Fiat ? "Fiat" : "Crypto",
        Fiat: Fiat,
        SelectedNetwork: NFTDet.SelectedNetwork,
      };

      console.log("payload to putonasle db action", Payload)
      var Resp = await v1_UpdateNFTState(Payload);
      if (Resp) {
        toast.success("Order Placed Successfully")
        $("#putonsale_close").trigger("click");
        setTimeout(() => {
          history.push(`/my-items/${Wallet_Details.UserAccountAddr}`);
        }, 1000);
      }
    } else {
      toast.error("Try it Again Later.Please Check Your Funds")
    }
  };

  const CancelCallNormal = async () => {
    // alert("normal cancel")
    var chainid = NFTDet?.SelectedNetwork
    var networkConfiguration = {}
    console.log("sdfsfdfsdfsdfs", Number(chainid), network.MATIC.Chainid);
    if (Number(chainid) === network.MATIC.Chainid) {
      console.log("placecall eorrrrkkk maticz", network.MATIC);
      networkConfiguration = network.MATIC
    }
    else if (Number(chainid) === network.ETH.Chainid) {
      console.log("netter eorrrrkkk", network.ETH);
      networkConfiguration = network.ETH
    }
    else if (Number(chainid) === network.CAM.Chainid) {
      console.log("netter eorrrrkkk", network.CAM);
      networkConfiguration = network.CAM
    }
    Wallet_Details.networkConfiguration = networkConfiguration
    setCancelSate(true);
    var Arg = { NFTId: NFT.tokenID };
    var Type = NFT.type;
    var Provider = Wallet_Details;
    if (localStorage.walletConnectType !== "LMWallet") {
      var Receipt = await ContactHookCancel(Arg, NFT.type, Provider)
    } else {
      var Key = null
      var Argument = [NFT.tokenID]
      var Receipt = await LMWalletCall(Arg, NFT.type, Wallet_Details, NFTDet, NFT, "Trade", Wallet_Details.networkConfiguration.TradeContract, Wallet_Details.networkConfiguration.TradeContract, Argument, "cancelOrder", Key, false)
    }
    console.log("OrderPlaced", Receipt);
    if (Receipt.status) {
      setCancelSate(false);
      var Payload = {
        Status: Receipt.status,
        Hash: Receipt.transactionHash,
        ConAddr: Receipt.to,
        tokenPrice: 0,
        NFTDetail: NFT,
        Owner: Wallet_Details.UserAccountAddr,
        tokenName: NFTDet.tokenName,
        BuyType: Fiat ? "Fiat" : "Crypto",
        Fiat: Fiat
      };
      // console.log('cancel order', Payload)
      var Resp = await v1_UpdateNFTState(Payload);
      if (Resp) {
        toast.success("Order Canceled Successfully")
        $("#cancel_closs").trigger("click");
        setTimeout(() => {
          history.push(`/my-items/${Wallet_Details.UserAccountAddr}`);
        }, 1000);
      }
    } else {
      toast.error("Try it Again Later.Please Check Your Funds")
    }
  };



  async function ApproveCall() {
    var receiptt = null;
    var handlee = null;
    // console.log("adfkmsdlmflksdmf", NFT,Wallet_Details.providerss);
    if (localStorage.walletConnectType !== "LMWallet") {
      if (Wallet_Details.providerss == null) {

        toast.warning("OOPS!..connect Your Wallet");
        return false;
      }
      var web3 = new Web3(Wallet_Details.providerss);
      var currAddr = Wallet_Details.UserAccountAddr
      var MultiContract = new web3.eth.Contract(
        (NFT.type === 721 ? Abi721 : Abi1155),
        NFT.contractAddress
      );
      var chain = await web3.eth.getChainId()
      var encoded = await MultiContract.methods.setApprovalForAll(Wallet_Details.networkConfiguration.TradeContract, true).encodeABI();
      console.log("asdklasmda", encoded);
      const Transcation = {
        from: Wallet_Details.UserAccountAddr,
        to: NFT.contractAddress,
        data: encoded,
      };
      console.log("mdkasmkdlas", Transcation, Wallet_Details.Web3Pro);
      var gasLimit = await Wallet_Details.Web3Pro.eth.estimateGas(Transcation);
      var gasPrice = await Wallet_Details.Web3Pro.eth.getGasPrice();
      console.log("Adasdasdasdas", gasLimit);
      Transcation["gas"] = gasLimit;

      if (currAddr === "") {
        toast.warning("OOPS!..connect Your Wallet");
        return false;
      }
      setApproveCallStatus('processing');
      try {

        // console.log("dfbhadfbhadfsbh", MultiContract, Wallet_Details.networkConfiguration.TradeContract, NFT.tokenOwner);
        if (chain == config.MATIC_ID) {
          await MultiContract.methods.setApprovalForAll(
            Wallet_Details.networkConfiguration.TradeContract,
            true
          ).send({
            from: Wallet_Details.UserAccountAddr, gasLimit: parseInt(gasLimit * 1.5, 10), gasPrice: gasPrice
          }).on('transactionHash', async (transactionHash) => {
            if (transactionHash !== "") {
              handlee = setInterval(async () => {
                receiptt = await web3.eth.getTransactionReceipt(transactionHash);
                clr();
              }, 8000)
            }
          })
        } else {
          await MultiContract.methods.setApprovalForAll(
            Wallet_Details.networkConfiguration.TradeContract,
            true
          ).send({
            from: NFT.tokenOwner,
          }).on('transactionHash', async (transactionHash) => {
            if (transactionHash !== "") {
              handlee = setInterval(async () => {
                receiptt = await web3.eth.getTransactionReceipt(transactionHash);
                clr();
              }, 8000)
            }
          })
        }
      }
      catch (error) {
        // console.log("FSnhbsfgnsfh", error);
        toast.error("Approve failed");
        setApproveCallStatus('tryagain');
      }
    } else {
      const id = toast.loading("Awaiting Response");
      var Arg = {
        Method: "setApprovalForAll",
        Data: [Wallet_Details.networkConfiguration.TradeContract, true],
        Mail: localStorage.loggedinaddress,
        LoginAddress: localStorage.userAddress,
        ContactAddress: Wallet_Details.networkConfiguration.TradeContract,
        Type: NFTDet.type == 721 ? "721" : "1155",
        Chain: String(NFTDet.SelectedNetwork),
        IntractContract: NFTDet.contractAddress
      }
      var receipt = await LMContactCalls(Arg)
      console.log("sajdsakadsda", receipt);

      if (receipt.data.receipt) {
        toast.update(id, { render: "Approve Successfully", type: "success", isLoading: false, autoClose: 2000 });
        //toast.success("Approve Successfully");
        setApproveCallStatus('done');
      } else {
        toast.update(id, { render: "Approve Failed.Try Again Later", type: "success", isLoading: false, autoClose: 2000 });
        setApproveCallStatus('tryagain');
      }
    }
    async function clr() {
      if (receiptt != null) {
        clearInterval(handlee)
        toast.success("Approve Successfully");
        setApproveCallStatus('done');
      }
    }
  }

  async function orderApprovecheck(item, NFT1) {

    try {
      if (localStorage.walletConnectType !== "LMWallet") {
        // console.log("OrderApprove Check", NFT1);
        if (Wallet_Details.providerss == null) {
          toast.warning("OOPS!..connect Your Wallet");
          return false;
        }
        var web3 = new Web3(Wallet_Details.providerss);
        var MultiContract = new web3.eth.Contract(
          (NFT1.type === 721 ? Abi721 : Abi1155),
          NFT1.type === 721 ? Wallet_Details.networkConfiguration.singleContract : Wallet_Details.networkConfiguration.multipleContract
        );
        if (Wallet_Details.UserAccountAddr &&
          Wallet_Details.networkConfiguration.TradeContract) {
          var status = await MultiContract.methods.isApprovedForAll(
            Wallet_Details.UserAccountAddr,
            Wallet_Details.networkConfiguration.TradeContract
          ).call();
        }
        // console.log("adjfnmjkwdnfjnd", status);
        return status;
      } else {
        console.log("Comming on elsee bavkend calll", NFTDet.contractAddress, NFTDet.type, NFTDet.SelectedNetwork);
        var Arg = {
          Method: "isApprovedForAll",
          Data: [Wallet_Details.UserAccountAddr, Wallet_Details.networkConfiguration.TradeContract],
          Mail: localStorage.loggedinaddress,
          LoginAddress: localStorage.userAddress,
          ContactAddress: NFTDet.contractAddress,
          Type: NFTDet.type,
          Chain: NFTDet.SelectedNetwork,
          IntractContract: NFTDet.contractAddress,
          ContactType: NFTDet.type == 721 ? "Single" : "Multiple",
          Key: null,
        }
        var Balance = await LMApproveCheck(Arg)
        console.log("Balance", Balance);
        return Balance.data.receipt
      }
    }
    catch (e) {
      // console.log("OrderApprove Check", e);
      return false

    }
  }
  const selectChange = async (e) => {
    console.log("Place And cnacel", e);
    if (e.length <= 12) {
      // var NumANdDotOnly12 = NumANdDotOnly(e);
      console.log("iooiqwexzxvb", e);
      setTokenPrice(e)
    } else {
      console.log("log for comming in else");
      // toast.error("Token price is greater than 1e18")
      setTokenPrice(0)
    }

  }



  const NumANdDotOnly = async (data) => {
    // console.log("asdafafadfadf",data);
    var data = data.toString()
    var NumDigitOnly = /[^0-9\.]/g
    var str = data ? data.includes('.') ? data.split('.').length >= 3 ? (data.split('.')[0] + '.' + data.split('.')[1]).toString() : data : data : data
    //  console.log("asdadasdasdzxczxcxz",str);
    return str.toString().replace(NumDigitOnly, '')
  }
  const NumberOnly = async (data) => {
    // key.NumDigitOnly = /[^0-9\.]/g
    var NumberOnly = /[^0-9]/g
    return data.toString().replace(NumberOnly, '')
  }

  async function signcall() {
    try {
      var generator = require('generate-password');
      var randomNum = randomInteger(10000000, 100000000);
      var password = generator.generate({
        length: 10,
        numbers: true
      });
      var web3RpcPro = new Web3(Wallet_Details.networkConfiguration.RPC_URL)
      var chain = NFTDet.SelectedNetwork
      const to = Wallet_Details.UserAccountAddr
      const _amount = NFTDet.tokenPrice == "" || NFTDet.tokenPrice == undefined ? 0 : web3RpcPro.utils.toWei(String(NFTDet.tokenPrice));
      const _nonce = Date.now();
      // setNonceHash(_nonce);
      setRandomName(password)
      var tot = _nonce + Number(randomNum);
      setNonceHash(tot);
      if (localStorage.walletConnectType !== "LMWallet") {
        if (Wallet_Details.Web3Pro) {
          var web3Rpc = new Web3(Wallet_Details.providerss)
          if (web3Rpc) {
            SetSignTxt('On progress')
            setCloseStatus(false)
            var web3RpcPro = new Web3(Wallet_Details.providerss);
            console.log("dwqdwqewqewqeqw", to, _amount, password, tot);
            const result = web3RpcPro.utils.soliditySha3(to, _amount, password, tot);
            const signhash = await Wallet_Details.Web3Pro.eth.personal.sign(result, to);
            if (signhash) {
              SetSignTxt('done')
              setSignatureHash(signhash);
              toast.success("Verified Successfully")
            } else {
              toast.error("Signature Failed")
            }
          }
        }
      }
      else {
        SetSignTxt('On progress')
        setCloseStatus(false)
        console.log("Comming On Lm Wallet");
        var Arg = {
          to: to,
          _amount: _amount,
          password: password,
          tot: tot,
          Type: String(NFTDet.type),
          Chain: String(chain),
          Mail: localStorage.loggedinaddress,
          LoginAddress: localStorage.userAddress,
          Key: null
        }
        var receipt = await LMSignCall(Arg)
        console.log("sajdsakadsda", receipt.data);

        if (receipt.data.Status) {
          SetSignTxt('done')
          setSignatureHash(receipt.data.signedTx);
          toast.success("Verified Successfully")
        } else {
          toast.error("Signature Failed")
        }
      }
    } catch (e) {
      console.log("sdasdasdasd", e);
      SetSignTxt('Try again')
      toast.error("Signature Failed")
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };

  const ContactHook = async (Arg, type, Provider) => {
    var Receipt = await usePutonSaleReceipt(Arg, type, Provider,);
    return Receipt
  }

  const LMWalletCall = async (Arg, type, Wallet_Details, tokDetails, NFT, NameUse, IntractContact, Contract, data, method, Key, Status) => {
    var receipt = null
    console.log("Comming Inside the LmWallet", Arg, type, Wallet_Details, tokDetails, NFT, NameUse, IntractContact, Contract, data, method, Key);
    var Arg = {
      Method: method,
      Data: data,
      Mail: localStorage.loggedinaddress,
      LoginAddress: localStorage.userAddress,
      ContactAddress: Contract,
      Type: String(type),
      Chain: String(tokDetails.SelectedNetwork),
      IntractContract: IntractContact,
      ContactType: NameUse,
      value: Prices.PriceWithSerfee,
      Key: Key,
      Status: Status
    }
    var Receiptfun = await ContactMint(Arg)

    console.log("ArgReceipt", Receiptfun.data.receipt);
    return Receiptfun.data.receipt
  }

  const LMBalanceCheck = async (Arg, Type, Wallet_Details, IntractContact, NameUse, Contract, method, data, Key, tokenOwner) => {
    console.log("LMBalanceCheck", Arg, Type, Wallet_Details, IntractContact, NameUse, Contract, method, data, Key);
    var Arg = {
      Method: method,
      Data: data,
      Mail: localStorage.loggedinaddress,
      LoginAddress: localStorage.userAddress,
      ContactAddress: Contract,
      Type: String(Type),
      Chain: String(NFTDet.SelectedNetwork),
      IntractContract: IntractContact,
      ContactType: NameUse,
      value: Prices.PriceWithSerfee,
      Key: Key,
      tokenOwner: tokenOwner
    }
    var Balance = await LMBalanceOf(Arg)
    console.log("Balance", Balance);
    return Balance.data.receipt
  }

  const ContactHookCancel = async (Arg, Type, Provider) => {
    var Receipt = await useCancelOrderReceipt(Arg, Type, Provider);
    return Receipt
  }
  return (
    <>
      {/* change_price Modal */}
      <div
        className="modal fade primary_modal"
        id="change_price_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="change_price_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            {!(GetPrices.PriceWithSerfee === "") ? (
              <>
                <div>
                  <div className="modal-header text-center">
                    <h5 className="modal-title" id="change_price_modalLabel">
                      {NFT.tokenPrice === 0 ? "Put On Sale" : "Lower Price"}
                    </h5>
                    <div className="change_price_img_div">
                      {NFTDet.image &&
                        NFTDet.image.split(".").pop() == "mp4" ? (
                        <video
                          id="my-video"
                          class="img-fluid"
                          autoPlay
                          playsInline
                          loop
                          muted
                          preload="auto"
                        >
                          <source src={NFTImage && NFTImage} type="video/mp4" />
                        </video>
                      ) : (
                        <img
                          src={
                            NFTImage
                              ? NFTImage
                              : require("../../assets/images/masonary_04.png")
                          }
                          alt="Collections"
                          className="img-fluid"
                        />
                      )}
                    </div>
                    <p className="text-gray font_we_600 font_14">
                      {NFT.tokenPrice === 0
                        ? `Your About to Place ${NFTDet?.tokenName?.slice(0, 15)} On Sale`
                        : `Your About to Change Price for ${NFTDet?.tokenName?.slice(0, 10)}`}
                    </p>

                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      id="putonsale_close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body px-0 pt-0">
                    <form className="px-2 bid_form">
                      {NFT.tokenPrice == 0 &&
                        <div className="form-group">
                          <div className="d-flex justify-content-between align-items-start grid_toggle">
                            <div>
                              <label className="primary_label" htmlFor="inputEmail4">
                                Fiat
                              </label>
                              <p className="form_note">
                                You’ll receive Fiat Currency on this item
                              </p>
                            </div>
                            <label className="switch toggle_custom">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  setFiat(e.target.checked)
                                }
                              />
                              <span className="slider"></span>
                            </label>
                          </div>
                        </div>
                      }
                      <div>
                        <label className="text-center" htmlFor="bid">
                          {NFT.tokenPrice === 0
                            ? `On Sale Price - ${TokenPrice}`
                            : `New Price ${TokenPrice}`}{" "}
                          {config.currencySymbol}
                        </label>
                      </div>
                      <div className="input-group mb-2 input_grp_style_1">
                        <input
                          type="number"
                          id="bid"
                          min="1"
                          max="12"
                          value={TokenPrice}
                          onChange={(e) => {
                            selectChange(e.target.value);
                          }}
                          className="form-control"
                          placeholder="Enter new price"
                          aria-label="bid"
                          aria-describedby="basic-addon2"
                          onKeyDown={handleKeyDown}
                          onWheel={(e) => e.target.blur()}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text" id="basic-addon2">
                            {NFT.coinname}
                          </span>
                        </div>
                      </div>
                      {NFT.tokenPrice !== 0 && (
                        <p className="form_note">
                          Price must be less than the actual price{" "}
                          {NFT.tokenPrice}
                        </p>
                      )}
                      {/* <label htmlFor="qty">Enter quantity <span className="label_muted pl-2">(30 available)</span></label>
    <div className="mb-3 input_grp_style_1">
  <input type="text" id="qty" className="form-control" placeholder="1"  />
 
</div> */}
                      <div className="row pb-3">
                        <div className="col-12 col-sm-6">
                          <p className="buy_desc_sm">Seller Service fee</p>
                        </div>
                        <div className="col-12 col-sm-6 text-sm-right">
                          <p className="buy_desc_sm_bold">
                            {Wallet_Details.Service_Fee.Seller}%
                          </p>
                        </div>
                      </div>
                      <div className="row pb-3">
                        <div className="col-12 col-sm-6">
                          <p className="buy_desc_sm">You will get</p>
                        </div>
                        <div className="col-12 col-sm-6 text-sm-right">
                          <p className="buy_desc_sm_bold">
                            {GetPrices.PriceWithSerfee}
                          </p>
                        </div>
                      </div>

                      {approvecheck == false &&

                        <div className="text-center">
                          {/* <div className="media approve_media">
                            {ApproveCallStatus === 'init' && <i className="fas fa-check mr-3 pro_initial" aria-hidden="true"></i>}
                            {ApproveCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}
                            {ApproveCallStatus === 'done' && <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>}
                            {ApproveCallStatus === 'tryagain' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}

                            <div className="media-body">
                              <p className="mt-0 approve_text">Approve</p>
                             
                            </div>
                          </div> */}
                          <Button className={"create_btn btn-block"}
                            disabled={(ApproveCallStatus === 'processing' || ApproveCallStatus === 'done')}
                            onClick={ApproveCall}
                          >
                            {ApproveCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                            {ApproveCallStatus === 'init' && 'Approve'}
                            {ApproveCallStatus === 'processing' && 'In-progress...'}
                            {ApproveCallStatus === 'done' && 'Done'}
                            {ApproveCallStatus === 'tryagain' && 'Try Again'}

                          </Button>
                        </div>


                      }
                      {console.log("dsfdsafadsfafdda", SignTxt)}
                      <div className="text-center">
                        <Button
                          className="create_btn btn-block"
                          disabled={!CloseStatus}
                          onClick={() => {
                            signcall();
                          }}
                        >
                          {" "}
                          {SignTxt == "On progress" && (
                            <i
                              class="fa fa-spinner mr-3 spinner_icon"
                              aria-hidden="true"
                            ></i>
                          )}
                          {NFT.tokenPrice === 0
                            ? "SignCall"
                            : "SignCall"}
                        </Button>

                      </div>
                      <div className="text-center">
                        <Button
                          className="create_btn btn-block"
                          disabled={PlaceSate}
                          onClick={() => {
                            ValidCheck();
                          }}
                        >
                          {" "}
                          {PlaceSate && (
                            <i
                              class="fa fa-spinner mr-3 spinner_icon"
                              aria-hidden="true"
                            ></i>
                          )}
                          {NFT.tokenPrice === 0
                            ? "Put On Sale"
                            : "Change Price"}
                        </Button>

                      </div>




                      {/* {console.log("dfsdsdgsdsdf", approvecheck)} */}

                    </form>
                  </div>
                </div>
              </>
            ) : (
              <>
                <TailSpin
                  wrapperClass="reactloader mt-0 mb-3"
                  color="#00BFFF"
                  height={100}
                  width={70}
                />
              </>
            )}
          </div>
        </div>
      </div>
      {/* end change_price modal */}

      {/* cancel_order Modal */}
      <div
        className="modal fade primary_modal"
        id="cancel_order_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="cancel_order_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="cancel_order_modalLabel">
                Delete Instant Sale
              </h5>
              {!NFT.tokenPrice ? (
                <>
                  <TailSpin
                    wrapperClass="reactloader mt-0 mb-3"
                    color="#00BFFF"
                    height={100}
                    width={70}
                  />
                </>
              ) : (
                <>
                  <div>
                    <div className="change_price_img_div">
                      {NFTDet.image &&
                        NFTDet.image.split(".").pop() == "mp4" ? (
                        <video
                          id="my-video"
                          class="img-fluid"
                          autoPlay
                          playsInline
                          loop
                          muted
                          preload="auto"
                        >
                          <source src={NFTImage && NFTImage} type="video/mp4" />
                        </video>
                      ) : (
                        <img
                          src={
                            NFTImage
                              ? NFTImage
                              : require("../../assets/images/masonary_04.png")
                          }
                          alt="Collections"
                          className="img-fluid"
                        />
                      )}
                    </div>
                    <p className="text-gray font_we_600 font_14">
                      You are about to delete Instant Sale for{" "}
                      {NFTDet?.tokenName?.slice(0, 10)}
                    </p>

                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      id="cancel_closs"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>

                    <div className="modal-body px-0 pt-0">
                      <form className="px-4 bid_form">
                        <label htmlFor="bid" className="text-center">
                          {NFT.tokenPrice} {config.currencySymbol}
                        </label>

                        <div className="text-center mt-3">
                          <Button
                            className="create_btn btn-block"
                            disabled={CancelSate}
                            onClick={() => {
                              BalCheck("Cancel");
                            }}
                          >
                            {CancelSate && (
                              <i
                                class="fa fa-spinner mr-3 spinner_icon"
                                aria-hidden="true"
                              ></i>
                            )}
                            Delete Instant Sale
                          </Button>
                          <Button
                            className="btn_outline_red btn-block"
                            data-dismiss="modal"
                            aria-label="Close"
                            disabled={CancelSate}
                          >
                            Cancel
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* end cancel_order modal */}
    </>
  );
});
