import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Card from '../views/separate/Card.js';
import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styled from "../../node_modules/styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import ExploreCard from "./separate/explore_card";
import { useSelector } from "react-redux";
import { FollowingNFTs } from "actions/v1/token";
import Modal from 'react-modal';

import prof from "assets/images/small-profile.png";
import masonary1 from "assets/images/masonary_04.png";
import { toast } from "react-toastify";



const Icon = styled((props) => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #3d2524;
    font-size: 24px;
  }
  & > .minus {
    display: none;
    color: #3d2524;
    font-size: 24px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Following(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [expanded, setExpanded] = React.useState("panel1");
  const [ExploreFollowingNFTs, setExploreFollowingNFTs] = useState([]);
  const [Page,setPage]=useState(1)
  const [LoadMore,setLoadMore] = useState(true)
  const [ ShowModal,setShowModal] = useState(false)
  const [theme,setTheme] = useState("#000");

  useEffect(() => {
  
    if(document.getElementById("root").classList.contains("light_theme"))
    {
      setTheme("#FFF")
    }
    else
    {
      setTheme("#000")


    }
  }, []);
  const customStyles = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      // boxShadow: '0 27px 24px 0 rgb(0 0 0 / 20%), 0 40px 77px 0 rgb(0 0 0 / 22%)',
      borderRadius: '30px',
      border: 'none !important',
    },
    overlay: {
      backgroundColor: theme,
      zIndex: 2
    },
  };

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);

  useEffect(()=>{
    if(Page === 1)
    setShowModal(true)
    getfollowingNFTs()
  },[Wallet_Details.UserAccountAddr,Page])

  const getfollowingNFTs = async () => {
		var resp = await FollowingNFTs({
			target: 'follower',
			addr: Wallet_Details.UserAccountAddr,
      page:Page,
      limit:16
		});
		if (resp.data && resp.data.Success) {
      if(resp.data.records && resp.data.records.length > 0)
      {
			setExploreFollowingNFTs(resp.data.records)
      if(Page > 1)
      {
        var temp = ExploreFollowingNFTs.concat(resp.data.records)
        setExploreFollowingNFTs(temp)
      }
    }
    else{
      setLoadMore(false)
    }
    // console.log("jdnfkjn",resp)
    setTimeout(() => {
      setShowModal(false)
    }, 1500);
		}
		else {
			setExploreFollowingNFTs([])
      toast.error("Error on Follow Fetch",{autoClose:2000})
		}
	}
  const BrowseMarketPlace = () => {
    return (
        <div className="text-center py-5">
            <p className="not_found_text">No items found</p>
            <p className="not_found_text_sub">
                Come back soon! Or try to browse something for you on our marketplace
            </p>
            <div className="mt-3">
                <Link to="/explore/All">
                    <Button className="browsematdk">Browse Marketplace</Button>
                </Link>
            </div>
        </div>
    );
};

  return (
    <div className="home_header">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={
          <Link to="/home">
            <div className="header_logo_align">
            

            </div>
          </Link>
        }
        leftLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 20,
          color: "white",
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader inner_page_bg_2 followingsys"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <p className="heading_big_blk">Following</p>
            </GridItem>
          </GridContainer>
        </div>
        <div className="container-fluid custom-container  mt-3">
          <div className="proposal_panel_overall">
            {/* {ExploreFollowingNFTs &&
                ExploreFollowingNFTs.length === 0 &&
            <div className="text-center py-5">
              <p className="not_found_text">No items found</p>
              <p className="not_found_text_sub">
                Come back soon! Or try to browse something for you on our
                marketplace
              </p>
              <div className="mt-3">
                <Link to="explore/All">
                <Button className="create_btn">Browse Marketplace</Button>
                </Link>
              </div>
            </div>
          } */}
          {ExploreFollowingNFTs.length > 0 ?
            <div className="row masonry m-0 ma_no_gap card_main_iner">
              {ExploreFollowingNFTs &&
                ExploreFollowingNFTs.length > 0 &&
                ExploreFollowingNFTs.map((NFT) => {
                  return (
                    <ExploreCard
                      Cardclass={
                        "item col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3"
                      }
                      NFTDetails={NFT}
                    />
                  );
                })}
                {/* <Card /> */}
            </div>
            :
            <BrowseMarketPlace />
              }
            {LoadMore &&
            <div className="col-12 pb-5 text-center pt-4">
              <button className="create_btn" onClick={()=>setPage(Page+1)}>Load more items</button>
            </div>
            }
          </div>
        </div>
      </div>
      <Footer />
      <Modal
        isOpen={ShowModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {theme === "#000" ?
         <div className="modal-body text-center loading_bgcolor">
         <div className="laodingpart">
         <img src={require("../assets/images/lazymintingnew/logo.png")} alt="Profile" className="img-fluid items_profile smal_main_lm" />
          {/* {
             theme == "#FFF" ? 
             <p className="text-dark">Loading ....</p>
             :
             <p className="text-white">Loading ....</p>
           } */}
           
         </div>
       </div> :

<div className="modal-body text-center loading_bgcolor">
<div className="laodingpart">
<img src={require("../assets/images/lazymintingnew/logodark.png")} alt="Profile" className="img-fluid items_profile smal_main_lm" />
 {/* {
    theme == "#FFF" ? 
    <p className="text-dark">Loading ....</p>
    :
    <p className="text-white">Loading ....</p>
  } */}
  
</div>
</div>

        }
       
      </Modal>
    </div>
  );
}
