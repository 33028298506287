import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import './index.css';
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Provider } from 'react-redux'
import { store } from './actions/redux/store'
import 'react-toastify/dist/ReactToastify.css';
import  Routes from 'views/Routes'
import { ToastContainer } from "react-toastify";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter >
      <Provider store={store}>
        <Routes />
        <ToastContainer
          closeOnClick={true} />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
