import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styled from "../../node_modules/styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link, useParams, useHistory } from "react-router-dom";
import { v1_Search } from "actions/v1/token";
import ExploreCard from "./separate/explore_card";
import config from "lib/config";
import { usePrevious } from "./my-items";
import { Receipt } from "@material-ui/icons";
import Modal from 'react-modal';

const Icon = styled((props) => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #3d2524;
    font-size: 24px;
  }
  & > .minus {
    display: none;
    color: #3d2524;
    font-size: 24px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Activity(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [expanded, setExpanded] = React.useState("panel1");
  const { q } = useParams();
  const prevQ = usePrevious(q);
  const [Page, setPage] = useState(1);
  const prevPage = usePrevious(Page)
  const [uPage, setuPage] = useState(1);
  const prevuPage = usePrevious(uPage)
  const [Load, setLoad] = useState(true);
  const [uLoad, setuLoad] = useState(true);
  const [ShowModal, setShowModal] = useState(false)
  const [theme, setTheme] = useState("#000");

  useEffect(() => {

    if (document.getElementById("root").classList.contains("light_theme")) {
      setTheme("#FFF")
    }
    else {
      setTheme("#000")


    }
  }, []);
  const customStyles = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      // boxShadow: '0 27px 24px 0 rgb(0 0 0 / 20%), 0 40px 77px 0 rgb(0 0 0 / 22%)',
      borderRadius: '30px',
      border: 'none !important',
    },
    overlay: {
      backgroundColor: theme,
      zIndex: 2
    },
  };
  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [SearchRes, setSearchRes] = useState({
    users: [],
    items: {},
    collections: [],
  });

  useEffect(() => {
    if (prevQ !== q) {
      setLoad(true);
      setPage(1);
    }
    Search(q);
  }, [q, Page, uPage]);

  const Search = async (query) => {
    var useclass1 = document.getElementsByClassName("searchmneu_dd");
    for (var c = 0; c < useclass1.length; c++) {
      useclass1[c].classList.add("d-none");
    }
    let postData = {
      limit: 16,
      page: Page,
      upage: uPage,
      keyword: query,
    };
    var resp = await v1_Search(postData);
    if (resp.data && resp.data.Success) {
      var Result = resp.data.Resp;
      if (prevQ !== q) {
        setShowModal(true)
        SearchRes.items.records = [];
        SearchRes.users = [];
        setSearchRes([]);
        setSearchRes(Result);
      } else {
        if (prevPage !== Page) {
          if (Result.items.records.length > 0) {
            SearchRes.items.records = SearchRes.items.records.concat(
              Result.items.records
            );
            setSearchRes([]);
            setSearchRes(SearchRes);
          } else
            setLoad(false);
        }
        if (prevuPage !== uPage) {
          if (Result.users.length > 0) {
            SearchRes.users = SearchRes.users.concat(Result.users);
            setSearchRes([]);
            setSearchRes(SearchRes);
          } else
            setuLoad(false);
        }
      }
      setTimeout(() => {
        setShowModal(false)
      }, 1000);
    }
  };

  return (
    <div className="home_header">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={
          <Link to="/home">
            <div className="header_logo_align">


            </div>
          </Link>
        }
        leftLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 20,
          color: "white",
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader inner_page_bg_2"}>
        <div className="container-fluid custom-container">
          <p className="heading_big_blk">Search</p>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <nav className="masonry_tab_nav mt-0 pt-0 mb-3 items_tab_outer">
                <div
                  className="nav nav-tabs masonry_tab primary_tab explore_tab_full items_tab justify-content-center items_tab_new pb-0 pl-0"
                  id="nav-tab"
                  role="tablist"
                >
                  <a
                    className="nav-link active"
                    id="items-tab"
                    data-toggle="tab"
                    href="#items"
                    role="tab"
                    aria-controls="all"
                    aria-selected="true"
                  >
                    <span className="tab_head p-2">Items</span>
                  </a>

                  <a
                    className="nav-link"
                    id="users-tab"
                    data-toggle="tab"
                    href="#users"
                    role="tab"
                    aria-controls="following"
                    aria-selected="false"
                  >
                    <span className="tab_head p-2">Users</span>
                  </a>
                </div>
              </nav>
              <div
                className="tab-content explore_tab_content"
                id="nav-tabContent"
              >
                <div
                  className="tab-pane fade show active"
                  id="items"
                  role="tabpanel"
                  aria-labelledby="items-tab"
                >
                  <div className="proposal_panel_overall">
                    {SearchRes.items &&
                      SearchRes.items.Success &&
                      SearchRes.items.records &&
                      SearchRes.items.records.length > 0 ? (
                      <div className="row masonry m-0 ma_no_gap card_main_iner">
                        {SearchRes.items.records.map((Searched) => {
                          return (
                            <ExploreCard
                              Cardclass={
                                "item col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3"
                              }
                              NFTDetails={Searched}
                            />
                          );
                        })}
                      </div>
                    ) : (
                      <div className="text-center py-5">
                        <p className="not_found_text">No items found</p>
                        <p className="not_found_text_sub">
                          Come back soon! Or try to browse something for you on
                          our marketplace
                        </p>
                        <div className="mt-3">
                          <Link to="/explore/All">
                            <Button className="browsematdk">
                              Browse Marketplace
                            </Button>
                          </Link>
                        </div>
                      </div>
                    )}

                    <div className="col-12 pb-5 text-center pt-4">
                      {SearchRes.items &&
                        SearchRes.items.Success &&
                        SearchRes.items.records &&
                        SearchRes.items.records.length > 0 && Load && (
                          <button
                            className="create_btn"
                            onClick={() => setPage(Page + 1)}
                          >
                            Load more items
                          </button>
                        )}
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="users"
                  role="tabpanel"
                  aria-labelledby="users-tab"
                >
                  <div className="proposal_panel_overall pb-4">
                    <div className="followers_overall py-1">
                      {SearchRes.users &&
                        SearchRes.users.length > 0 ?
                        <div className="row mt-3">
                          {
                            SearchRes.users.map((Searched) => {
                              return (
                                <div className="col-12 col-md-6 col-xl-4 mb-4">
                                  <div className="card my-0 acti_card rounded-corners-gradient-borders">
                                    <Link to={(Searched.customurl) ? `/my-items/user/${Searched.customurl}` : `/my-items/${Searched.curraddress}`}>
                                      <div className="card-body px-3">
                                        <div className="media follow_media">
                                          <div className="img_media_new  mr-3">

                                            <img
                                              src={require("../assets/images/img_info.png")}
                                              alt="User"
                                              className="img-fluid"
                                            />
                                          </div>

                                          <div className="media-body flex_body">
                                            <div>
                                              <p className="mt-0 media_num font_14 mb-0">
                                                Lazyminter

                                              </p>
                                              <p className="mt-0 media_text">

                                                Lazy Minter
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                        :
                        <div className="text-center py-5">
                          <p className="not_found_text">No users found</p>
                          <p className="not_found_text_sub">
                            Come back soon! Or try to browse something for you on
                            our marketplace
                          </p>
                          <div className="mt-3">
                            <Link to="/explore/All">
                              <Button className="browsematdk">
                                Browse Marketplace
                              </Button>
                            </Link>
                          </div>
                        </div>
                      }
                    </div>
                    <div className="col-12 pb-5 text-center pt-4">
                      {SearchRes.users &&
                        SearchRes.users.length > 0 && uLoad && (
                          <button
                            className="create_btn"
                            onClick={() => setuPage(uPage + 1)}
                          >
                            Load more items
                          </button>
                        )}
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="collections"
                  role="tabpanel"
                  aria-labelledby="collections-tab"
                >
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
      <Modal
        isOpen={ShowModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {theme === "#000" ?
          <div className="modal-body text-center loading_bgcolor">
            <div className="laodingpart">
              <img src={require("../assets/images/lazymintingnew/logo.png")} alt="Profile" className="img-fluid items_profile smal_main_lm" />

            </div>
          </div> :

          <div className="modal-body text-center loading_bgcolor">
            <div className="laodingpart">
              <img src={require("../assets/images/lazymintingnew/logodark.png")} alt="Profile" className="img-fluid items_profile smal_main_lm" />
            </div>
          </div>

        }
      </Modal>
    </div>
  );
}
