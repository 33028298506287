/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import {
  Notifications,
  AccountBalanceWallet,
  AccountCircle,
} from "@material-ui/icons";

import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import Web3 from "web3";
import Market from "../../ABI/721.json";
import Fractionalmarket from "../../ABI/FractionTrade.json";
// import {
//   WALLET_ADAPTERS,
//   CHAIN_NAMESPACES,
//   SafeEventEmitterProvider,
// } from "@web3auth/base";
// import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
// import { Web3Auth } from "@web3auth/modal";
// import { CHAIN_NAMESPACES } from "@web3auth/base";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload, WbSunny, Brightness3 } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { Scrollbars } from "react-custom-scrollbars";
import {
  Account_disConnect,
  Account_Connect,
} from "../../actions/redux/action";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "../../lib/isEmpty";
import { toast, Flip } from "react-toastify";
import { v1_Search } from "actions/v1/token";
import config from "lib/config";
import { TailSpin, Oval } from "react-loader-spinner";
import CopyToClipboard from "react-copy-to-clipboard";
import { v1_Social } from "actions/v1/user";
import { v1_Category, v1_CategorySbt, v1_CategoryFractional,v1_GetCustomToken } from "actions/v1/user";
import { network } from "../../views/network";
import {
  getServiceFees,
  connectWallet,
  WalletConnect,
} from "../../hooks/useWallet";
import {
  networklist,
  UserPackCheck,
  FreePackSubScribe,
} from "../../actions/v1/token";
import { AddressUserDetails_GetOrSave_Action, userget } from "actions/v1/user";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import { getServiceFees } from "../../hooks/useWallet"
toast.configure();

const useStyles = makeStyles(styles);
// const { Web3Auth } = require("@web3auth/modal");

export default function HeaderLinks(props) {
  const openInNewTabOne = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [useraccounts, setaccounts] = useState("");
  const timerRef = useRef(null);
  const location = useLocation();
  const history = useHistory();

  const [key, setkey] = useState("");
  const [SearchRes, setSearchRes] = useState({
    users: [],
    SoulBound: [],
    items: {},
    collections: [],
  });
  const [cat, setcat] = useState("");
  const [Loader, setLoader] = useState(false);
  const [menulinks, setLinks] = React.useState("");
  const [extra, setExtra] = useState(false);
  const [extra1, setExtra1] = useState(false);
  const [extra1new, setExtra1new] = useState(false);
  const [extra2, setExtra2] = useState(false);
  const [extra3, setExtra3] = useState(false);
  const [extra4, setExtra4] = useState(false);
  const [extra5, setExtra5] = useState(false);
  const [extra6, setExtra6] = useState(false);
  const [extra7, setExtra7] = useState(false);
  const [search, setSearch] = useState(false);
  const [categorysate, Setcategorysate] = useState({});
  const [theme, setTheme] = useState("dark");
  const [theme1, settheme1] = useState(true);
  const [Subcribed, SetSubcribed] = useState("false");

  const [web3auth, setWeb3auth] = useState(null);
  const [provider, setProvider] = useState(null);
  const [address, setAddress] = useState("");
  const [balance, setBalance] = useState("");
  const [chainId, setChainId] = useState("");
  const [userData, setUserData] = useState({});
  useEffect(() => {
    var loc = window.location.href;
    console.log("dfadasdsdsdsdd", loc);
    var loc1 = loc.split("/")[3];
    console.log("asdasdasasd", loc1);
    console.log("Pathaaa", loc1);
    if (loc1 == "LMWallet") {
      localStorage.setItem("theme1", "LT");
      document.getElementById("root").classList.add("light_theme");
      document.getElementById("root").classList.remove("dark_theme");
      // this.setState({theme:false})
      // this.state.theme === false
      settheme1(true);
    }
  }, []);
  useEffect(() => {
    var loc = location.pathname;
    var loc1 = loc.split("/")[2];
    console.log("Path", loc1);
    if (loc1 == "explore") changeextrastate();
  }, []);
  const changeextrastate = async () => {
    setExtra(true);
  };
  useEffect(() => {
    var url = location.pathname;
    var url1 = url.split("/")[2];
    if (url == "/Rankings" || url == "/activity") changeextrastate1();
  }, [location]);
  const changeextrastate1 = async () => {
    setExtra1new(true);
  };
  useEffect(() => {
    var url = location.pathname;
    var url2 = url.split("/")[2];
    // if(loc == "/how-it-works" || loc == "/termsandconditions" || loc == "/privacy-policy")
    if (url == "/how-it-works") changeextrastate2();
  }, []);
  const changeextrastate2 = async () => {
    setExtra2(true);
  };
  useEffect(() => {
    var loc = location.pathname;
    var loc2 = loc.split("/")[2];
    if (
      loc == "/create" ||
      loc == "/create-single" ||
      loc == "/create-multiple"
    )
      changeextrastate3();
  }, []);
  const changeextrastate3 = async () => {
    setExtra3(true);
  };
  useEffect(() => {
    var loc = location.pathname;
    var loc2 = loc.split("/")[2];
    if (loc == "/tickets") changeextrastate6();
  }, []);
  const changeextrastate6 = async () => {
    setExtra6(true);
  };
  console.log("Wallet_Details_Wallet_Details", Wallet_Details);
  useEffect(() => {
    var loc = location.pathname;
    var loc2 = loc.split("/")[2];
    if (loc == "/collection" || loc == "/SoulBound") changeextrastate7();
  }, []);
  const changeextrastate7 = async () => {
    setExtra7(true);
  };
  useEffect(() => {
    var loc = location.pathname;
    var loc2 = loc.split("/")[2];
    if (loc == "/following") changeextrastate4();
  }, []);
  useEffect(() => {
    var loc = location.pathname;
    var loc2 = loc.split("/")[2];
    if (loc == "/collections") changeextrastate5();
  }, []);
  const changeextrastate5 = async () => {
    setExtra5(true);
  };
  const SoCat = async () => {
    var Social = await v1_Social();
    var Category = await v1_Category();
    var CategorySBT = await v1_CategorySbt();
    var CategoryFractional = await v1_CategoryFractional()
    let TokenLists = await v1_GetCustomToken()
    console.log("000000000000",TokenLists);
    var id = "0000";
    var shareTag = [];
    if (Category?.Success) {
      Category = Category?.userValue;
      CategorySBT = CategorySBT?.userValue;
      CategoryFractional = CategoryFractional?.userValue
      TokenLists = TokenLists?.data
    } else Category = "";
    if (Social?.Success) Social = Social?.userValue[0]?.social;
    else Social = "";
    axios.get(config.v1Url + "/admin/panel/getnfttag/" + id).then((data) => {
      if (data && data?.data && data?.data?.userValue) {
        data.data.userValue.map((item) => {
          shareTag.push(item.nfttag);
          // console.log("routes category", Category)
          dispatch({
            type: Account_Connect,
            Account_Detail: {
              shareTag: shareTag,
              Social: Social,
              Category: Category,
              CategorySBT: CategorySBT,
              CategoryFractional: CategoryFractional,
              TokenLists: TokenLists
            },
          });
        });
      }
    });
  };
  const Serfee = async (Address) => {
    var Fee = await getServiceFees(Address);
    console.log("ndkdsnfkdsn", Fee);
    dispatch({
      type: Account_Connect,
      Account_Detail: {
        Service_Fee: Fee?.Serfee,
        Service_Fee_Fraction: Fee?.SerfeeFraction
      },
    });
  };
  useEffect(() => {
    localStorage.setItem("StatusShow", "Show");
    SoCat();
    // if (localStorage.walletConnectType)
    //   getInit(localStorage.walletConnectType);
  }, [Wallet_Details.Wall]);
  useEffect(() => {
    (async () => {
      var web3 = new Web3(window.ethereum);
      // console.log("window", web3)
      var bal = await web3.eth.getBalance(
        "0xeAc09f4720BB8e36E351Dc8E30f22195bCD6c6b9"
      );
      if (bal) console.log("baln in useeffect", bal);
    })();
  }, []);
  useEffect(() => {
    console.log("asdadaqweqqwewq", Wallet_Details.UserAccountAddr);
    if (Wallet_Details.UserAccountAddr !== "") {
      console.log("Comming in side if");
      UserSubcribed();
    }
    networkoption();
    // getBuyTokensList(config.priceoptionETH);
    // getBuyTokensList();
    // getInitialSeviceFee()
  }, [Wallet_Details.UserAccountAddr]);
  useEffect(() => {
    if (
      localStorage.walletConnectType &&
      localStorage.walletConnectType != null &&
      localStorage.walletConnectType == "MetaMask"
    ) {
      console.log("elseeeee2");

      walletConnect("MetaMask", "register");
    } else if (
      localStorage.walletConnectType &&
      localStorage.walletConnectType == "WalletConnect" &&
      localStorage.walletConnectType != null
    ) {
      console.log("elseeeee3");

      walletConnect("WalletConnect", "register");
    } else if (
      localStorage.walletConnectType &&
      localStorage.walletConnectType == "Venly" &&
      localStorage.walletConnectType != null
    ) {
      console.log("elseeeee3");

      walletConnect("Venly", "register");
    } else if (
      localStorage.walletConnectType &&
      localStorage.walletConnectType == "LMWallet" &&
      localStorage.walletConnectType != null
    ) {
      var getChainId = localStorage.getItem("ChainId");
      if (isEmpty(getChainId)) localStorage.setItem("ChainId", config.chainId);
      console.log("elelelelelele4");
      walletConnect("LMWallet", "register");
    }

    console.log(
      "localStorage.walletConnectType111111111111111",
      localStorage.walletConnectType
    );
  }, [localStorage.walletConnectType, Wallet_Details.UserAccountAddr]);
  async function networkoption() {
    var networklistss = await networklist();

    var networkOption = networklistss?.data?.data?.data?.map((item) => {
      // console.log("gnhsrfjntyh", item.networkname);
      return {
        label: item.networkname,
        value: item.networkname,
        name: "networkname",
      };
    });

    // console.log("networklistss", networkOption);
    dispatch({
      type: Account_Connect,
      Account_Detail: {
        networkoption: networkOption,
      },
    });
  }

  const walletConnect = async (type, register) => {
    if (type == "MetaMask") {
      var web3 = new Web3(window.ethereum);
      var ChainID = await web3.eth.getChainId();
      var networkConfiguration = "";
      var accountDetails = await connectWallet(type, ChainID);
      var data1 = {
        curradd: accountDetails?.accountAddress,
        email: localStorage.loggedinaddress,
      };
      var users = await userget(data1);
      var alreadyuser = users?.data?.data?.users;
      if (users?.data?.data?.message == "Allow") {
        if (
          alreadyuser !== null &&
          (alreadyuser?.curraddress.length > 0 ||
            alreadyuser?.curraddress.length == 0)
        ) {
          if (
            String(localStorage?.loggedinaddress) ===
            String(alreadyuser?.signemail) ||
            localStorage?.loggedinaddress === undefined
          ) {
            var data = {
              addr: String(accountDetails.accountAddress).toLowerCase(),
              from: register ? register : "",
              email: localStorage.loggedinaddress,
            };
            var Resp = await AddressUserDetails_GetOrSave_Action(data);
            if (Resp.data.data.RetData.Message === "already registered") {
              toast.error(
                `Email invalid "you already registered with ${Resp?.data?.data?.RetData?.User?.signemail}"`
              );
              setTimeout(() => {
                logoutfunction();
              }, 500);
            } else if (Resp?.data?.data?.RetData.Message === "not registered") {
              toast.error(`please register to login`);
              setTimeout(() => {
                logoutfunction();
              }, 500);
            } else {
              localStorage.setItem(
                "userAddress",
                String(accountDetails?.accountAddress).toLowerCase()
              );
            }
            if (
              Resp &&
              Resp.data &&
              Resp.data.data &&
              Resp.data.data.RetData.User
            ) {
              if (
                accountDetails?.accountAddress !== "" ||
                Resp?.data?.data?.RetData?.User.LocalAddr
              ) {
                var sendData =
                  accountDetails?.accountAddress == null ||
                    accountDetails?.accountAddress == "" ||
                    accountDetails?.accountAddress == undefined
                    ? Resp?.data?.data?.RetData?.User?.LocalAddr
                    : accountDetails?.accountAddress;
                var AddressUserDetails = await FreePackSubScribe({
                  Address: sendData,
                });
              }
              if (accountDetails != "") {
                const IDcheck = await accountDetails?.web3?.eth?.getChainId();
                Serfee(Number(IDcheck));
                if (IDcheck === network.MATIC.Chainid) {
                  networkConfiguration = network.MATIC;
                } else if (IDcheck === network.ETH.Chainid) {
                  networkConfiguration = network.ETH;
                } else if (IDcheck === network.CAM.Chainid) {
                  networkConfiguration = network.CAM;
                }
                dispatch({
                  type: Account_Connect,
                  Account_Detail: {
                    UserAccountAddr: accountDetails?.accountAddress,
                    UserAccountBal: accountDetails?.coinBalance,
                    WalletConnected: "true",
                    Wen_Bln: accountDetails?.tokenBalance,
                    Accounts: accountDetails?.accountAddress,
                    Web3Pro: accountDetails?.web3,
                    providerss: accountDetails?.web3?._provider,
                    networkConfiguration: networkConfiguration,
                    AddressUserDetails: Resp?.data?.data?.RetData?.User,
                  },
                });
              }
              return;
            } else {
              return null;
            }
          } else {
            toast.error("please register to login");
            logoutfunction();
          }
        } else if (
          alreadyuser === null ||
          alreadyuser?.curraddress !== accountDetails?.accountAddress
        ) {
          toast.error(
            "email or address mismatch please login with correct credentials"
          );
          logoutfunction();
        } else {
        }
      } else {
        toast.error(
          "Your are restricted by admin. Contact admin for further deatils"
        );
        setTimeout(() => {
          logoutfunction();
        }, 4000);
      }
    } else if (type == "WalletConnect") {
      var web3 = new Web3(window.ethereum);
      var ChainID = await web3.eth.getChainId();
      var networkConfiguration = "";
      var accountDetails = await connectWallet(type, ChainID);
      var data1 = {
        curradd: accountDetails?.accountAddress,
        email: localStorage.loggedinaddress,
      };
      var users = await userget(data1);
      var alreadyuser = users?.data?.data?.users;
      if (users?.data?.data?.message == "Allow") {
        if (
          alreadyuser != null &&
          (alreadyuser?.curraddress === "" ||
            alreadyuser?.curraddress === accountDetails?.accountAddress ||
            localStorage?.userAddress === accountDetails?.accountAddress)
        ) {
          if (
            String(localStorage?.loggedinaddress) ===
            String(alreadyuser?.signemail) ||
            localStorage?.loggedinaddress === undefined
          ) {
            var data = {
              addr: String(accountDetails?.accountAddress).toLowerCase(),
              from: register ? register : "",
              email: localStorage.loggedinaddress,
            };
            var Resp = await AddressUserDetails_GetOrSave_Action(data);
            if (Resp?.data?.data?.RetData?.Message === "already registered") {
              toast.error(
                `Email invalid "you already registered with ${Resp?.data?.data?.RetData?.User?.signemail}"`
              );
              logoutfunction();
            } else if (Resp.data.data.RetData.Message === "not registered") {
              toast.error(`please register to login`);
              logoutfunction();
            } else {
              localStorage.setItem(
                "userAddress",
                String(accountDetails?.accountAddress).toLowerCase()
              );
            }
            if (
              Resp &&
              Resp?.data &&
              Resp?.data?.data &&
              Resp?.data?.data?.RetData?.User
            ) {
              if (
                accountDetails?.accountAddress !== "" ||
                Resp?.data?.data?.RetData?.User?.LocalAddr
              ) {
                var sendData =
                  accountDetails?.accountAddress == null ||
                    accountDetails?.accountAddress == "" ||
                    accountDetails?.accountAddress == undefined
                    ? Resp?.data?.data?.RetData?.User?.LocalAddr
                    : accountDetails?.accountAddress;
                var AddressUserDetails = await FreePackSubScribe({
                  Address: sendData,
                });
              }
              if (accountDetails != "") {
                const IDcheck = await accountDetails?.web3?.eth?.getChainId()
                Serfee(Number(IDcheck));
                if (IDcheck === network.MATIC.Chainid) {
                  networkConfiguration = network.MATIC;
                } else if (IDcheck === network.ETH.Chainid) {
                  networkConfiguration = network.ETH;
                } else if (IDcheck === network.CAM.Chainid) {
                  networkConfiguration = network.CAM;
                }
                dispatch({
                  type: Account_Connect,
                  Account_Detail: {
                    UserAccountAddr: accountDetails?.accountAddress,
                    UserAccountBal: accountDetails?.coinBalance,
                    WalletConnected: "true",
                    Wen_Bln: accountDetails?.tokenBalance,
                    Accounts: accountDetails?.accountAddress,
                    Web3Pro: accountDetails?.web3,
                    providerss: accountDetails?.web3?._provider,
                    networkConfiguration: networkConfiguration,
                    AddressUserDetails: Resp?.data?.data?.RetData?.User,
                  },
                });
              }
              return Resp.data.data.RetData.User;
            } else {
              return null;
            }
          } else {
            toast.error("please register to login");
            logoutfunction();
          }
        } else if (
          alreadyuser === null ||
          alreadyuser?.curraddress !== accountDetails?.accountAddress
        ) {
          toast.error(
            "email or address mismatch please login with correct credentials"
          );
          logoutfunction();
        } else {
        }
      } else {
        toast.error(
          "Your are restricted by admin. Contact admin for further deatils"
        );
        setTimeout(() => {
          logoutfunction();
        }, 4000);
      }
    } else if (localStorage.walletConnectType == "Venly") {
      var networkConfiguration = "";
      var chainIds = await Wallet_Details?.Web3Pro?.eth?.getChainId();
      var accountDetails = await connectWallet(type, chainIds, "Create");
      var data1 = {
        curradd: accountDetails.accountAddress,
        email: localStorage.loggedinaddress,
      };
      var users = await userget(data1);
      var alreadyuser = users?.data?.data?.users;
      if (alreadyuser) {
        if (
          alreadyuser != null &&
          (alreadyuser?.curraddress === "" ||
            alreadyuser?.curraddress === accountDetails?.accountAddress ||
            localStorage?.userAddress === accountDetails?.accountAddress)
        ) {
          if (
            String(localStorage?.loggedinaddress) ===
            String(alreadyuser?.signemail) ||
            localStorage?.loggedinaddress === undefined
          ) {
            var data = {
              addr: String(accountDetails.accountAddress).toLowerCase(),
              from: register ? register : "",
              email: localStorage.loggedinaddress,
            };
            var Resp = await AddressUserDetails_GetOrSave_Action(data);
            if (Resp.data.data.RetData.Message === "already registered") {
              toast.error(
                `Email invalid "you already registered with ${Resp.data.data.RetData.User.signemail}"`
              );
              logoutfunction();
            } else if (Resp.data.data.RetData.Message === "not registered") {
              logoutfunction();
            } else {
              localStorage.setItem(
                "userAddress",
                String(accountDetails.accountAddress).toLowerCase()
              );
            }
            if (
              Resp &&
              Resp.data &&
              Resp.data.data &&
              Resp.data.data.RetData.User
            ) {
              if (accountDetails != "") {
                const IDcheck = await accountDetails?.web3?.eth?.getChainId();
                Serfee(Number(IDcheck));
                if (IDcheck === network.MATIC.Chainid) {
                  networkConfiguration = network.MATIC;
                } else if (IDcheck === network.ETH.Chainid) {
                  networkConfiguration = network.ETH;
                } else if (IDcheck === network.CAM.Chainid) {
                  networkConfiguration = network.CAM;
                }
                dispatch({
                  type: Account_Connect,
                  Account_Detail: {
                    UserAccountAddr: accountDetails?.accountAddress,
                    UserAccountBal: accountDetails?.coinBalance,
                    WalletConnected: "true",
                    Wen_Bln: accountDetails?.tokenBalance,
                    Accounts: accountDetails?.accountAddress,
                    Web3Pro: accountDetails?.web3,
                    providerss: accountDetails?.web3?._provider,
                    networkConfiguration: networkConfiguration,
                    AddressUserDetails: Resp.data.data.RetData.User,
                  },
                });
              }
              return Resp.data.data.RetData.User;
            } else {
              return null;
            }
          } else {
            logoutfunction();
          }
        } else if (
          alreadyuser === null ||
          alreadyuser?.curraddress !== accountDetails?.accountAddress
        ) {
          toast.error(
            "please import the same address in venly Dont create new addres for every chain"
          );
          logoutfunction();
        } else {
        }
      } else {
        toast.error(
          "Your are restricted by admin. Contact admin for further deatils"
        );
        setTimeout(() => {
          logoutfunction();
        }, 4000);
      }
    } else if (localStorage.walletConnectType == "Torus") {
      var networkConfiguration = "";
      var chainIds = await Wallet_Details?.Web3Pro?.eth?.getChainId();
      var accountDetails = await connectWallet(type, chainIds, "Create");
      var data1 = {
        curradd: accountDetails.accountAddress,
        email: localStorage.loggedinaddress,
      };
      var users = await userget(data1);
      var alreadyuser = users?.data?.data?.users;
      if (alreadyuser) {
        if (
          alreadyuser != null &&
          (alreadyuser?.curraddress === "" ||
            alreadyuser?.curraddress === accountDetails?.accountAddress ||
            localStorage?.userAddress === accountDetails?.accountAddress)
        ) {
          if (
            String(localStorage?.loggedinaddress) ===
            String(alreadyuser?.signemail) ||
            localStorage?.loggedinaddress === undefined
          ) {
            var data = {
              addr: String(accountDetails.accountAddress).toLowerCase(),
              from: register ? register : "",
              email: localStorage.loggedinaddress,
            };
            var Resp = await AddressUserDetails_GetOrSave_Action(data);
            if (Resp.data.data.RetData.Message === "already registered") {
              toast.error(
                `Email invalid "you already registered with ${Resp.data.data.RetData.User.signemail}"`
              );
              logoutfunction();
            } else if (Resp.data.data.RetData.Message === "not registered") {             logoutfunction();
            } else {
              localStorage.setItem(
                "userAddress",
                String(accountDetails.accountAddress).toLowerCase()
              );
            }
            if (
              Resp &&
              Resp.data &&
              Resp.data.data &&
              Resp.data.data.RetData.User
            ) {
              if (accountDetails != "") {
                const IDcheck = await accountDetails?.web3?.eth?.getChainId();
                Serfee(Number(IDcheck));
                if (IDcheck === network.MATIC.Chainid) {
                  networkConfiguration = network.MATIC;
                } else if (IDcheck === network.ETH.Chainid) {
                  networkConfiguration = network.ETH;
                } else if (IDcheck === network.CAM.Chainid) {
                  networkConfiguration = network.CAM;
                }
                dispatch({
                  type: Account_Connect,
                  Account_Detail: {
                    UserAccountAddr: accountDetails?.accountAddress,
                    UserAccountBal: accountDetails?.coinBalance,
                    WalletConnected: "true",
                    Wen_Bln: accountDetails?.tokenBalance,
                    Accounts: accountDetails?.accountAddress,
                    Web3Pro: accountDetails?.web3,
                    providerss: accountDetails?.web3?._provider,
                    networkConfiguration: networkConfiguration,
                    AddressUserDetails: Resp.data.data.RetData.User,
                  },
                });
              }
              return Resp.data.data.RetData.User;
            } else {
              return null;
            }
          } else {
            logoutfunction();
          }
        } else if (
          alreadyuser === null ||
          alreadyuser?.curraddress !== accountDetails?.accountAddress
        ) {
          toast.error(
            "please import the same address in venly Dont create new addres for every chain"
          );
          logoutfunction();
        } else {
        }
      } else {
        toast.error(
          "Your are restricted by admin. Contact admin for further deatils"
        );
        setTimeout(() => {
          logoutfunction();
        }, 4000);
      }
    } else if (localStorage.walletConnectType == "TorusWallet") {
      var networkConfiguration = "";
      var ChainID = config.MATIC_ID;
      var chainIds = await Wallet_Details?.Web3Pro?.eth?.getChainId();
      var accountDetails = await connectWallet(type, ChainID, "Create");
      var data1 = {
        curradd: accountDetails?.accountAddress,
        email: localStorage.loggedinaddress,
      };
      var users = await userget(data1);
      var alreadyuser = users?.data?.data?.users;
      if (users?.data?.data?.message == "Allow") {
        if (
          alreadyuser != null &&
          (alreadyuser?.curraddress === "" ||
            alreadyuser?.curraddress === accountDetails?.accountAddress ||
            localStorage?.userAddress === accountDetails?.accountAddress)
        ) {
          if (
            String(localStorage?.loggedinaddress) ===
            String(alreadyuser?.signemail) ||
            localStorage?.loggedinaddress === undefined
          ) {
            var data = {
              addr: String(accountDetails?.accountAddress).toLowerCase(),
              from: register ? register : "",
              email: localStorage.loggedinaddress,
            };
            var Resp = await AddressUserDetails_GetOrSave_Action(data);
            if (Resp?.data?.data?.RetData?.Message === "already registered") {
              toast.error(
                `Email invalid "you already registered with ${Resp?.data?.data?.RetData?.User?.signemail}"`
              );
              logoutfunction();
            } else if (Resp?.data?.data?.RetData?.Message === "not registered") {
              logoutfunction();
            } else {
              localStorage.setItem(
                "userAddress",
                String(accountDetails?.accountAddress).toLowerCase()
              );
            }
            if (
              Resp &&
              Resp?.data &&
              Resp?.data?.data &&
              Resp?.data?.data?.RetData?.User
            ) {
              if (
                accountDetails?.accountAddress !== "" ||
                Resp?.data?.data?.RetData?.User?.LocalAddr
              ) {
                var sendData =
                  accountDetails?.accountAddress == null ||
                    accountDetails?.accountAddress == "" ||
                    accountDetails?.accountAddress == undefined
                    ? Resp.data.data.RetData.User.LocalAddr
                    : accountDetails?.accountAddress;
                var AddressUserDetails = await FreePackSubScribe({
                  Address: sendData,
                });
              }
              if (accountDetails != "") {
                const IDcheck = await accountDetails?.web3?.eth?.getChainId();
                Serfee(Number(IDcheck));
                if (IDcheck === network.MATIC.Chainid) {
                  networkConfiguration = network.MATIC;
                } else if (IDcheck === network.ETH.Chainid) {
                  networkConfiguration = network.ETH;
                } else if (IDcheck === network.CAM.Chainid) {
                  networkConfiguration = network.CAM;
                }
                dispatch({
                  type: Account_Connect,
                  Account_Detail: {
                    UserAccountAddr: accountDetails?.accountAddress,
                    UserAccountBal: accountDetails?.coinBalance,
                    WalletConnected: "true",
                    Wen_Bln: accountDetails?.tokenBalance,
                    Accounts: accountDetails?.accountAddress,
                    Web3Pro: accountDetails?.web3,
                    providerss: accountDetails?.web3?._provider,
                    networkConfiguration: networkConfiguration,
                    AddressUserDetails: Resp?.data?.data?.RetData?.User,
                  },
                });
              }
              return Resp?.data?.data?.RetData?.User;
            } else {
              return null;
            }
          } else {
            logoutfunction();
          }
        } else if (
          alreadyuser === null ||
          alreadyuser?.curraddress !== accountDetails?.accountAddress
        ) {
          toast.error(
            "please import the same address in venly Dont create new addres for every chain"
          );
          logoutfunction();
        } else {
        }
      } else {
        toast.error(
          "Your are restricted by admin. Contact admin for further deatils"
        );
        setTimeout(() => {
          logoutfunction();
        }, 4000);
      }
    } else if (type == "LMWallet") {
      console.log("registerregister",register)
      var networkConfiguration = "";
      var accountDetails = await connectWallet(
        type,
        (ChainID = Number(localStorage.getItem("ChainId"))),
        register
      );
      console.log("accountDetails",accountDetails,type,
      (ChainID = Number(localStorage.getItem("ChainId"))),
      register);
      dispatch({
        type: Account_Connect,
        Account_Detail: {
          LMWallet: accountDetails?.LMWallet,
        },
      });
      var data1 = {
        curradd: accountDetails?.accountAddress,
        email: localStorage.loggedinaddress,
      };
      var users = await userget(data1);
      var alreadyuser = users?.data?.data?.users;
      if (users?.data?.data?.message == "Allow") {
        if (
          alreadyuser !== null &&
          (alreadyuser?.curraddress.length > 0 ||
            alreadyuser?.curraddress.length == 0)
        ) {
          if (
            String(localStorage?.loggedinaddress) ===
            String(alreadyuser?.signemail) ||
            localStorage?.loggedinaddress === undefined
          ) {
            var data = {
              addr: String(accountDetails?.accountAddress).toLowerCase(),
              from: register ? register : "",
              email: localStorage.loggedinaddress,
            };
            var Resp = await AddressUserDetails_GetOrSave_Action(data);
            if (Resp.data.data.RetData.Message === "already registered") {
              toast.error(
                `Email invalid "you already registered with ${Resp?.data?.data?.RetData?.User?.signemail}"`
              );
              setTimeout(() => {
                logoutfunction();
              }, 500);
            } else if (Resp?.data?.data?.RetData?.Message === "not registered") {
              toast.error(`please register to login`);
              setTimeout(() => {
                logoutfunction();
              }, 500);
            } else {
              localStorage.setItem(
                "userAddress",
                String(accountDetails?.accountAddress).toLowerCase()
              );
            }
            if (
              Resp &&
              Resp?.data &&
              Resp?.data?.data &&
              Resp?.data?.data?.RetData?.User
            ) {
              if (
                accountDetails?.accountAddress !== "" ||
                Resp?.data?.data?.RetData?.User?.LocalAddr
              ) {
                var sendData =
                  accountDetails?.accountAddress == null ||
                    accountDetails?.accountAddress == "" ||
                    accountDetails?.accountAddress == undefined
                    ? Resp?.data?.data?.RetData?.User?.LocalAddr
                    : accountDetails?.accountAddress;
                var AddressUserDetails = await FreePackSubScribe({
                  Address: sendData,
                });
              }
              if (accountDetails != "") {
                const IDcheck = Number(localStorage.getItem("ChainId"));
                Serfee(Number(IDcheck));
                if (IDcheck === network.MATIC.Chainid) {
                  networkConfiguration = network.MATIC;
                } else if (IDcheck === network.ETH.Chainid) {
                  networkConfiguration = network.ETH;
                } else if (IDcheck === network.CAM.Chainid) {
                  networkConfiguration = network.CAM;
                }
                dispatch({
                  type: Account_Connect,
                  Account_Detail: {
                    UserAccountAddr: accountDetails?.accountAddress,
                    UserAccountBal: accountDetails?.coinBalance,
                    WalletConnected: "true",
                    Wen_Bln: accountDetails?.tokenBalance,
                    Accounts: accountDetails?.accountAddress,
                    Web3Pro: accountDetails?.web3,
                    providerss: accountDetails?.web3?._provider,
                    networkConfiguration: networkConfiguration,
                    AddressUserDetails: Resp?.data?.data?.RetData?.User,
                  },
                });
              }
              return;
            } else {
              return null;
            }
          } else {
            toast.error("please register to login");
            logoutfunction();
          }
        } else if (
          alreadyuser === null ||
          alreadyuser?.curraddress !== accountDetails?.accountAddress
        ) {
          toast.error(
            "email or address mismatch please login with correct credentials"
          );
          logoutfunction();
        } else {
        }
      } else {
        toast.error(
          "Your are restricted by admin. Contact admin for further deatils"
        );
        setTimeout(() => {
          logoutfunction();
        }, 4000);
      }
    }
  };

  // const walletConnect = async (type, register) => {
  //   try {
  //     var web3 = new Web3(window.ethereum);
  //     var ChainID = await web3.eth.getChainId();
  //     var networkConfiguration = "";
  //     var accountDetails = await connectWallet(type, ChainID, "Create");
  
  //     var data1 = {
  //       curradd: accountDetails.accountAddress,
  //       email: localStorage.loggedinaddress,
  //     };
  //     var users = await userget(data1);
  //     var alreadyuser = users?.data?.data?.users;
  
  //     if (users?.data?.data?.message !== "Allow") {
  //       toast.error(
  //         "You are restricted by admin. Contact admin for further details"
  //       );
  //       setTimeout(logoutfunction, 4000);
  //       return;
  //     }
  
  //     if (
  //       !alreadyuser ||
  //       (alreadyuser?.curraddress !== "" &&
  //         alreadyuser?.curraddress !== accountDetails?.accountAddress &&
  //         localStorage?.userAddress !== accountDetails?.accountAddress)
  //     ) {
  //       toast.error(
  //         "email or address mismatch. Please login with correct credentials"
  //       );
  //       logoutfunction();
  //       return;
  //     }
  
  //     if (
  //       String(localStorage?.loggedinaddress) !==
  //         String(alreadyuser?.signemail) &&
  //       localStorage?.loggedinaddress !== undefined
  //     ) {
  //       toast.error("Please register to login");
  //       logoutfunction();
  //       return;
  //     }
  
  //     var data = {
  //       addr: String(accountDetails.accountAddress).toLowerCase(),
  //       from: register ? register : "",
  //       email: localStorage.loggedinaddress,
  //     };
  
  //     var Resp = await AddressUserDetails_GetOrSave_Action(data);
  
  //     if (Resp.data.data.RetData.Message === "already registered") {
  //       toast.error(
  //         `Email invalid "you already registered with ${Resp.data.data.RetData.User.signemail}"`
  //       );
  //       setTimeout(logoutfunction, 500);
  //       return;
  //     } else if (Resp.data.data.RetData.Message === "not registered") {
  //       toast.error("Please register to login");
  //       setTimeout(logoutfunction, 500);
  //       return;
  //     }
  
  //     localStorage.setItem(
  //       "userAddress",
  //       String(accountDetails.accountAddress).toLowerCase()
  //     );
  
  //     if (accountDetails?.accountAddress !== "" || Resp.data.data.RetData.User.LocalAddr) {
  //       var sendData =
  //         accountDetails?.accountAddress ?? Resp.data.data.RetData.User.LocalAddr;
  
  //       var AddressUserDetails = await FreePackSubScribe({
  //         Address: sendData,
  //       });
  //     }
  
  //     const IDcheck = await accountDetails?.web3?.eth?.getChainId();
  //     Serfee(Number(IDcheck));
  
  //     if (IDcheck === network.MATIC.Chainid) {
  //       networkConfiguration = network.MATIC;
  //     } else if (IDcheck === network.ETH.Chainid) {
  //       networkConfiguration = network.ETH;
  //     }
  
  //     dispatch({
  //       type: Account_Connect,
  //       Account_Detail: {
  //         UserAccountAddr: accountDetails?.accountAddress,
  //         UserAccountBal: accountDetails?.coinBalance,
  //         WalletConnected: "true",
  //         Wen_Bln: accountDetails?.tokenBalance,
  //         Accounts: accountDetails?.accountAddress,
  //         Web3Pro: accountDetails?.web3,
  //         providerss: accountDetails?.web3?._provider,
  //         networkConfiguration: networkConfiguration,
  //         AddressUserDetails: Resp.data.data.RetData.User,
  //       },
  //     });
  //   } catch (error) {
  //     console.error("Error in walletConnect:", error);
  //     toast.error("An error occurred. Please try again.");
  //     logoutfunction();
  //   }
  // };
  

  window.addEventListener("load", async (event) => {
    console.log("loaddd");
    if (localStorage.getItem("walletConnectType") == "MetaMask") {
      if (window.ethereum) {
        // console.log("tjghjfhio", window.ethereum);
        window.ethereum.on("accountsChanged", function (accounts) {
          if (timerRef.current) {
            clearTimeout(timerRef.current);
          }
          timerRef.current = setTimeout(() => {
            walletConnect("MetaMask");
          }, 1000);
        });

        window.ethereum.on("chainChanged", async function (networkId) {
          //console.log("chainid",networkId)
          // if (networkId == config.chainId) {
          if (networkId) {
            if (timerRef.current) {
              clearTimeout(timerRef.current);
            }
            timerRef.current = setTimeout(() => {
              walletConnect("MetaMask");
            }, 1000);
          } else {
            dispatch({
              type: Account_disConnect,
            });
          }
        });
      }
    } else if (localStorage.walletConnectType == "WalletConnect") {
      var provider3 = null;
      if (provider3 == null) {
        provider3 = await walletConnect("WalletConnect");
      } else if (provider3 != null) {
        provider3.on("connect", () => {
          walletConnect("WalletConnect");
        });
        provider3.on("disconnect", () => {
          localStorage.removeItem("walletConnectType");
        });
      }
    } else if (localStorage.walletConnectType == "Venly") {
      var provider3 = null;
      if (provider3 == null) {
        provider3 = await walletConnect("Venly");
      } else if (provider3 != null) {
        provider3.on("connect", () => {
          walletConnect("Venly");
        });
        provider3.on("disconnect", () => {
          localStorage.removeItem("walletConnectType");
        });
      }
    } else if (localStorage.walletConnectType == "Torus") {
      var provider3 = null;
      if (provider3 == null) {
        console.log("qwewqeqweczcdfsax", provider3);
        provider3 = await walletConnect("Torus");
      } else if (provider3 != null) {
        provider3.on("connect", () => {
          walletConnect("Torus");
        });
        provider3.on("disconnect", () => {
          localStorage.removeItem("walletConnectType");
        });
      }
    }
   
  });

  // window.addEventListener("load", async (event) => {
  //   console.log("loaddd");
  
  //   const handleWalletConnect = async (walletType) => {
  //     let provider = null;
  
  //     if (provider === null) {
  //       provider = await walletConnect(walletType);
  //     }
  
  //     provider.on("connect", () => {
  //       walletConnect(walletType);
  //     });
  
  //     provider.on("disconnect", () => {
  //       localStorage.removeItem("walletConnectType");
  //     });
  
  //     return provider;
  //   };
  
  //   const handleAccountChange = () => {
  //     if (timerRef.current) {
  //       clearTimeout(timerRef.current);
  //     }
  //     timerRef.current = setTimeout(() => {
  //       walletConnect("MetaMask");
  //     }, 1000);
  //   };
  
  //   const handleChainChange = (networkId) => {
  //     if (networkId) {
  //       if (timerRef.current) {
  //         clearTimeout(timerRef.current);
  //       }
  //       timerRef.current = setTimeout(() => {
  //         walletConnect("MetaMask");
  //       }, 1000);
  //     } else {
  //       dispatch({
  //         type: Account_disConnect,
  //       });
  //     }
  //   };
  
  //   if (localStorage.getItem("walletConnectType") === "MetaMask" && window.ethereum) {
  //     window.ethereum.on("accountsChanged", handleAccountChange);
  //     window.ethereum.on("chainChanged", handleChainChange);
  //   } else {
  //     const walletType = localStorage.getItem("walletConnectType");
  //     if (walletType) {
  //       await handleWalletConnect(walletType);
  //     }
  //   }
  // });
  

  // const AfterWalletConnected = async (currentAddress) => {
  //   //var sendaddr = config.currAddress
  //   // console.log("addafdafsdfds", currentAddress);
  //   var ReqData = {
  //     addr: currentAddress,
  //   };
  //   if (currentAddress !== "" && currentAddress !== undefined) {
  //     var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
  //     console.log("asdasfsdfsdfsdfs", Resp);
  //     if (
  //       Resp &&
  //       Resp.data &&
  //       Resp.data.data &&
  //       Resp.data.data.RetData &&
  //       Resp.data.data.RetData.found
  //     ) {
  //       dispatch({
  //         type: Account_Connect,
  //         Account_Detail: {
  //           AddressUserDetails: Resp.data.data.RetData.User,
  //         },
  //       });
  //     } else {
  //       return null;
  //     }
  //   }
  // };
  const triggerfuncton = async () => {
    if (
      !localStorage.getItem("userAddress") ||
      localStorage.getItem("userAddress") !=
      (Wallet_Details.UserAccountAddr && Wallet_Details.UserAccountAddr)
    ) {
      window.$("#triggerlogin").click();
    } else console.log("weong else");
  };

  const AfterWalletConnected = async (currentAddress) => {
    //var sendaddr = config.currAddress
    // console.log("addafdafsdfds", currentAddress);
    var ReqData = {
      addr: currentAddress,
    };
    if (currentAddress !== "" && currentAddress !== undefined) {
      var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
      console.log("asdasfsdfsdfsdfs", Resp);
      if (
        Resp &&
        Resp.data &&
        Resp.data.data &&
        Resp.data.data.RetData &&
        Resp.data.data.RetData.found
      ) {
        dispatch({
          type: Account_Connect,
          Account_Detail: {
            AddressUserDetails: Resp.data.data.RetData.User,
          },
        });
      } else {
        return null;
      }
    }
  };

  useEffect(() => {
    // console.log("rtetyrrrrewyrteyt", Wallet_Details?.Category);
    if (Wallet_Details?.Category) {
      var Categorydata = Wallet_Details?.Category.filter(
        (Cat) => Cat.label != "Ticket"
      );
      // console.log("categoryydatatatata", Categorydata);
      if (Categorydata) {
        Setcategorysate(Categorydata);
      }
    }
  }, [Wallet_Details?.Category]);
  const changeextrastate4 = async () => {
    setExtra4(true);
  };

  useEffect(() => {
    console.log("asdsadfsaasdas", localStorage.getItem("theme1"));
    themechange(localStorage.getItem("theme1"));
  }, []);

  const toggleTheme = () => {
    console.log("theme", theme);
    let bodyDark = document.getElementById("root");
    let bodying = document.getElementsByTagName("body")[0];
    if (theme === "light") {
      bodyDark.classList.remove("light_theme");
      bodyDark.classList.add("dark_theme");
      bodying.classList.remove("light_loading");
      bodying.classList.add("dark_loading");
      setTheme("dark");
      localStorage.setItem("loadertheme", "dark");

      setTimeout(function () {
        var usebody = document.getElementsByClassName("mobile_nav");
        // console.log("usebody",usebody);
        for (var j = 0; j < usebody.length; j++) {
          usebody[j].classList.remove("light_theme");
          usebody[j].classList.add("dark_theme");
        }
      }, 100);
    } else {
      bodyDark.classList.remove("dark_theme");
      bodyDark.classList.add("light_theme");
      bodying.classList.remove("dark_loading");
      bodying.classList.add("light_loading");
      localStorage.setItem("loadertheme", "light");

      setTheme("light");
      setTimeout(function () {
        var usebody = document.getElementsByClassName("mobile_nav");
        // console.log("usebody",usebody);
        for (var j = 0; j < usebody.length; j++) {
          usebody[j].classList.remove("dark_theme");
          usebody[j].classList.add("light_theme");
        }
      }, 100);
    }
  };

  const themechange = (data) => {
    console.log("localStorage.getItem", data);
    if (data == "DT" || data == undefined) {
      localStorage.setItem("theme1", "DT");
      document.getElementById("root").classList.remove("light_theme");
      document.getElementById("root").classList.add("dark_theme");

      // this.state.theme === true
      // this.setState({theme:true})
      settheme1(false);
    } else {
      localStorage.setItem("theme1", "LT");
      document.getElementById("root").classList.add("light_theme");
      document.getElementById("root").classList.remove("dark_theme");

      // this.setState({theme:false})
      // this.state.theme === false
      settheme1(true);
    }
  };

  window.onclick = (e) => {
    if (e.target.classList.contains("dropbox")) {
      document.getElementById("searchmneu_dd").classList.add("d-none");
      document.getElementById("searchmneu_dd").classList.remove("d-block");
      document.getElementById("dropbox").classList.add("d-none");
      document.getElementById("dropbox").classList.remove("d-block");
      document.getElementById("searchid").value = "";
    }
  };
  const toggleSearchmenu = async (e) => {
    document.getElementById("dropbox").classList.add("d-block");
    document.getElementById("dropbox").classList.remove("d-none");

    e.persist();
    var useclass1 = document.getElementsByClassName("searchmneu_dd");
    // var inputbox = document.getElementsByClassName("searchid");
    // inputbox.classList.remove("d-block");
    // inputbox.classList.add("d-none");
    // for (var j = 0; j < useclass1.length; j++) {
    //   useclass1[j].classList.toggle("d-none");
    // }

    if (e.target.value.length == 0) {
      for (var c = 0; c < useclass1.length; c++) {
        useclass1[c].classList.add("d-none");
      }
    }
    if (e && e.target && e.target.value && e.target.value) {
      if (e.target.value.length == 1) {
        for (var c = 0; c < useclass1.length; c++) {
          useclass1[c].classList.remove("d-none");
        }
      }
      let keywordVal = e.target.value;
      setkey(keywordVal);
      let postData = {
        limit: 3,
        keyword: keywordVal,
      };
      if (keywordVal) {
        setLoader(true);
        var resp = await v1_Search(postData);
        if (resp.data && resp.data.Success) {
          console.log("Searchecs", resp);
          setSearchRes([]);
          setSearchRes(resp.data.Resp);
          setTimeout(() => {
            setLoader(false);
          }, 1000);
        }
      }
    }
  };
  useEffect(() => {
    if (isEmpty(localStorage)) {
      Disconnect();
    }

    //notification(Wallet_Details.UserAccountAddr);
  }, []);

  useEffect(() => {
    console.log(
      "Wallet_Details.AddressUserDetails",
      Wallet_Details.AddressUserDetails,
      localStorage.accountInfo
    );
    if (Wallet_Details.AddressUserDetails == null) {
      AfterWalletConnected(localStorage.accountInfo);
    }
  }, [Wallet_Details.AddressUserDetails]);
  const Disconnect = async (a) => {
    console.log("adsasdasdadasdas", Wallet_Details);
    if (localStorage.walletConnectType == "Torus") {
      console.log("Torus login logout comming");
      Wallet_Details.web3auth.logout();
    }
    dispatch({
      type: Account_disConnect,
      Account_Disconnect: {
        UserAccountAddr: "",
        UserAccountBal: 0,
        providerss: null,
        Service_Fee: 0,
        Wen_Bln: 0,
        Accounts: "",
        WalletConnected: "",
        AddressUserDetails: null,
        tokenAddress: null,
        swapFee: 0,
      },
    });
    if (a === "click")
      toast.success("Wallet Disconnected", { autoClose: 3000, theme: "dark" });
    if (
      localStorage.walletconnect != null &&
      localStorage.walletConnectType == "wc"
    ) {
      await Wallet_Details.providerss.disconnect();
      localStorage.removeItem("walletconnect");
    }
    localStorage.removeItem("walletConnectType");
    localStorage.removeItem("accountInfo");
    localStorage.removeItem("user_token");
    // localStorage.removeItem("loggedinaddress");
    // localStorage.removeItem("homepopblock");
  };
  const logoutfunction = () => {
    localStorage.removeItem("loggedinaddress");
    localStorage.removeItem("homepopblock");
    localStorage.removeItem("userAddress");
    localStorage.removeItem("user_token");
    localStorage.clear();
    toast.success("Successfully logout");

    // window.$("#triggerlogin").click();
    Disconnect();
    history.push("/");
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };
  const UserSubcribed = async () => {
    var SendDatass = {
      Address: Wallet_Details.UserAccountAddr,
    };
    if (Wallet_Details.UserAccountAddr !== "") {
      var createLimit = await UserPackCheck(SendDatass);
      console.log("asdasdasdasd", createLimit, createLimit.data.data.Message);
      if (createLimit.data.data.Message == "success") {
        SetSubcribed("true");
      } else {
        console.log("else else");
        SetSubcribed("false");
      }
    }
  };
  // const SetServiceFee1 = async (data) => {
  //   console.log("sadsdasdasda", Wallet_Details);
  //   var web3 = new Web3(Wallet_Details.providerss);

  //   var payload = {
  //     buyerFee: ("0x037f3cdcA193C203Bc58923C841323916C4f8b0C").toLowerCase(),
  //   };
  //   console.log("payload fees", payload);

  //   try {
  //     // var Contract = await useInstance(Wallet_Details.providerss);
  //     var Contract = await new web3.eth.Contract(
  //       Fractionalmarket,
  //       Wallet_Details.networkConfiguration.FractionTradeContract
  //     );
  //     console.log("ContractContract", Contract);
  //     console.log("contractdata", payload.buyerFee, Wallet_Details.UserAccountAddr);
  //     let encoded = await Contract.methods.transferOwnership("0x025c1667471685c323808647299e5DbF9d6AdcC9").encodeABI();
  //     const Transcation = {
  //       from: Wallet_Details.UserAccountAddr,
  //       to: Wallet_Details.networkConfiguration.FractionTradeContract,
  //       data: encoded,
  //     };
  //     var gasLimit = await web3?.eth?.estimateGas(Transcation);
  //     Transcation["gas"] = gasLimit;
  //     console.log("adsfdahsfasdkjsakd", gasLimit, Transcation);
  //     let chain = await web3?.eth?.getChainId();
  //     console.log("daskjdjsaldsa", chain);

  //     // if (chain == 137) {
  //     console.log("ascladmlaksda", chain);
  //     var resp = await web3.eth.sendTransaction(Transcation)
  //       .then(async () => {

  //         console.log("Service fee");
  //       })
  //       .catch(async (err) => {
  //         console.log("sdjhnsfdgjdgt", err);
  //         var servicefee = await Contract.methods.transferOwnership("0x025c1667471685c323808647299e5DbF9d6AdcC9");
  //       });
  //     // }
  //     console.log("resp of sud Admin chainge", resp);
  //     if (resp) return true;
  //   } catch (err) {
  //     console.log("errr", err);
  //   }

  //   // if (resp) {
  //   //   toast.success("service fee updated successfully!");
  //   //   setTimeout(() => {
  //   //     window.location.reload();
  //   //   }, 1000);
  //   // }

  //   // var Contract = await new web3.eth.Contract(
  //   //   Market,
  //   //   Wallet_Details.networkConfiguration.FractionTradeContract
  //   // );
  //   // var consttoo = await Contract.methods.deployerAddress().call()
  //   // console.log("consttooconsttoo", consttoo);
  // };

  // const SetServiceFee = async (Arg, Type, Provider, data) => {
  //   console.log("sadsdasdasda", Wallet_Details);
  //   var web3 = new Web3(Wallet_Details.providerss);
  //   var Contract = await new web3.eth.Contract(
  //     Market,
  //     Wallet_Details.networkConfiguration.singleContract
  //   );
  //   // var Contract = await new web3.eth.Contract(
  //   //   Market,
  //   //   Wallet_Details.networkConfiguration.TradeContract
  //   // );
  //   // var consttoo = await Contract.methods.lazy721().call()
  //   // console.log("consttooconsttoo", consttoo);
  //   try {
  //     await Contract
  //       .methods
  //       .changeCollectionOwner("0xD87ddfE179fE3e2Aea84041118a7E6C7EC975fe3")
  //       .send({ from: Wallet_Details.UserAccountAddr })
  //       .on('transactionHash', async (transactionHash) => {
  //         console.log("HHHHHHHHHHash", transactionHash);
  //       })
  //   }
  //   catch (error) {
  //     console.log("jsndkjncdsnckscs", error);
  //   }
  // }

  return (
    <List className={classes.list + " main_navbar"}>
      <div className="dropbox" id="dropbox"></div>
      {/* {console.log("asdasdasdasdasds", Wallet_Details)} */}
      <ListItem
        className={
          classes.listItem +
          " menu_dropdown dropdown_header_ul user_dd_ul pl-cust-user pl-cust-res rightside"
        }
      >
        <div className={extra == true ? "active" : ""}>
          <Button
            color="transparent"
            className={classes.navLink + " pl-cust-wal but_cls_res"}
            disableRipple
            onClick={() => {
              setLinks("Utilities"), setExtra(true);
            }}
          >
            Utilities
          </Button>
          <div
            className="noti_parent noti_parnt_user exploremneu_dd_res posleft viz_visi"
            id="exploremneu_dd_res"
          >
            <div className="px-0">
              <ul className="user_prof_ul pt-0 mt-0 mb-0">
                {/* <li onClick={() => setcat("All")}>
                  <div onClick={() => setLinks("Explore")}>
                    <Link to="/tickets">
                      <span>Tickets</span>
                    </Link>
                  </div>
                </li> */}

                <li onClick={() => setcat("Web3IDS")}>
                  <div onClick={() => setLinks("Web3Ids")}>
                    <Link to="/Web3Ids">
                      <span>Web3 IDs</span>
                    </Link>
                  </div>
                </li>

                <li
                  className="double_hover_view"
                  onClick={() => setcat("Collection")}
                >
                  <Link
                    to={`/collections/${"Collection"}/All`}
                    onClick={() => {
                      setLinks("SoulBound"), setExtra7(true);
                    }}
                  >
                    <span>Collection</span>
                  </Link>

                  <ul className="user_prof_ul pt-0 mt-0 mb-0 double_hover">
                    {Wallet_Details.Category &&
                      Wallet_Details.Category.length > 0 &&
                      Wallet_Details.Category.map((Cat) => {
                        return (
                          Cat.name !== "Ticket" &&
                          Cat.BulkStatus == "true" && (
                            <>
                              <li
                                className="double_hover_view subhover"
                                onClick={() => setcat(Cat.name)}
                              >
                                <div onClick={() => setLinks("Collection")}>
                                  <Link
                                    to={`/collections/${"Collection"}/${Cat.name
                                      }`}
                                  >
                                    <span>{Cat.name}</span>
                                  </Link>

                                  <ul className="user_prof_ul pt-0 mt-0 mb-0 double_hover double_subhover">
                                    {Cat.BulkSubCategory.map((SubCat) => {
                                      return (
                                        <li
                                          className=""
                                          onClick={() => setcat(SubCat.name)}
                                        >
                                          <div
                                            onClick={() =>
                                              setLinks("Collection")
                                            }
                                          >
                                            <Link
                                              to={`/collections/${"Collection"}/${Cat.name
                                                }`}
                                            >
                                              <span>{SubCat.name}</span>
                                            </Link>
                                          </div>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </li>
                            </>
                          )
                        );
                      })}
                  </ul>

                  <ul className="user_prof_ul pt-0 mt-0 mb-0 mobile_visible">
                    {Wallet_Details?.Category &&
                      Wallet_Details?.Category.length > 0 &&
                      Wallet_Details?.Category?.map((Cat) => {
                        return (
                          Cat.name !== "Ticket" &&
                          Cat.BulkStatus == "true" && (
                            <>
                              <li onClick={() => setcat(Cat.name)}>
                                <div onClick={() => setLinks("Collection")}>
                                  <Link
                                    to={`/collections/${"Collection"}/${Cat.name
                                      }`}
                                  >
                                    <span>{Cat.name}</span>
                                  </Link>
                                </div>
                              </li>
                            </>
                          )
                        );
                      })}
                  </ul>
                </li>

                <li
                  className="double_hover_view"
                  onClick={() => setcat("SoulBound")}
                >
                  <Link
                    to={`/collections/${"SoulBound"}/All`}
                    onClick={() => {
                      setLinks("SoulBound"), setExtra7(true);
                    }}
                  >
                    <span>Non-Transferrable Collection</span>
                  </Link>
                  <ul className="user_prof_ul pt-0 mt-0 mb-0 double_hover">
                    {Wallet_Details.CategorySBT &&
                      Wallet_Details.CategorySBT.length > 0 &&
                      Wallet_Details.CategorySBT.map((Cat) => {
                        return (
                          <>
                            <li
                              className="double_hover_view subhover"
                              onClick={() => setcat(Cat.name)}
                            >
                              <div onClick={() => setLinks("Collection")}>
                                <Link
                                  to={`/certificate/SBT/${"SoulBound"}/${Cat.name
                                    }`}
                                >
                                  <span>{Cat.name}</span>
                                </Link>
                                <ul className="user_prof_ul pt-0 mt-0 mb-0 double_hover double_subhover">
                                  {Cat.BulkSubCategory.map((SubCat) => {
                                    return (
                                      <li
                                        className=""
                                        onClick={() => setcat(SubCat.name)}
                                      >
                                        <div
                                          onClick={() => setLinks("Collection")}
                                        >
                                          <Link
                                            to={`/certificate/SBT/${"SoulBound"}/${Cat.name
                                              }`}
                                          >
                                            <span>{SubCat.name}</span>
                                          </Link>
                                        </div>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </li>
                          </>
                        );
                      })}
                  </ul>
                  <ul className="user_prof_ul pt-0 mt-0 mb-0 mobile_visible">
                    {Wallet_Details.CategorySBT &&
                      Wallet_Details.CategorySBT.length > 0 &&
                      Wallet_Details.CategorySBT.map((Cat) => {
                        return (
                          <>
                            <li
                              className="double_hover_view subhover"
                              onClick={() => setcat(Cat.name)}
                            >
                              <div onClick={() => setLinks("Collection")}>
                                <Link
                                  to={`/certificate/SBT/${"SoulBound"}/${Cat.name
                                    }`}
                                >
                                  <span>{Cat.name}</span>
                                </Link>
                                <ul className="user_prof_ul pt-0 mt-0 mb-0 double_hover double_subhover">
                                  {Cat.BulkSubCategory.map((SubCat) => {
                                    return (
                                      <li
                                        className=""
                                        onClick={() => setcat(SubCat.name)}
                                      >
                                        <div
                                          onClick={() => setLinks("Collection")}
                                        >
                                          <Link
                                            to={`/certificate/SBT/${"SoulBound"}/${Cat.name
                                              }`}
                                          >
                                            <span>{SubCat.name}</span>
                                          </Link>
                                        </div>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </li>
                          </>
                        );
                      })}
                  </ul>

                </li>

                <li
                  className="double_hover_view"
                  onClick={() => setcat("fraction")}
                >
                  <Link
                    to={`/Fraction/All`}
                    onClick={() => {
                      setLinks("fraction"), setExtra7(true);
                    }}
                  >
                    <span>Fractional Ownerships</span>
                  </Link>
                  <ul className="user_prof_ul pt-0 mt-0 mb-0 double_hover">
                    {Wallet_Details?.CategoryFractional &&
                      Wallet_Details?.CategoryFractional.length > 0 &&
                      Wallet_Details?.CategoryFractional.map((Cat) => {
                        return (
                          <>
                            <li
                              className="double_hover_view subhover"
                              onClick={() => setcat(Cat.name)}
                            >
                              <div onClick={() => setLinks("fraction")}>
                                <Link
                                  to={`/Fraction/${Cat.name
                                    }`}
                                >
                                  <span>{Cat.name}</span>
                                </Link>
                                {/* <ul className="user_prof_ul pt-0 mt-0 mb-0 double_hover double_subhover">
                                  {Cat.BulkSubCategory.map((SubCat) => {
                                    return (
                                      <li
                                        className=""
                                        onClick={() => setcat(SubCat.name)}
                                      >
                                        <div
                                          onClick={() => setLinks("Collection")}
                                        >
                                          <Link
                                            to={`/certificate/SBT/${"SoulBound"}/${Cat.name
                                              }`}
                                          >
                                            <span>{SubCat.name}</span>
                                          </Link>
                                        </div>
                                      </li>
                                    );
                                  })}
                                </ul> */}
                              </div>
                            </li>
                          </>
                        );
                      })}
                  </ul>
                  {/* <ul className="user_prof_ul pt-0 mt-0 mb-0 mobile_visible">
                    {Wallet_Details.CategoryFractional &&
                      Wallet_Details.CategoryFractional.length > 0 &&
                      Wallet_Details.CategoryFractional.map((Cat) => {
                        return (
                          <>
                            <li
                              className="double_hover_view subhover"
                              onClick={() => setcat(Cat.name)}
                            >
                              <div onClick={() => setLinks("Collection")}>
                                <Link
                                  to={`/certificate/SBT/${"SoulBound"}/${Cat.name
                                    }`}
                                >
                                  <span>{Cat.name}</span>
                                </Link>
                                <ul className="user_prof_ul pt-0 mt-0 mb-0 double_hover double_subhover">
                                  {Cat.BulkSubCategory.map((SubCat) => {
                                    return (
                                      <li
                                        className=""
                                        onClick={() => setcat(SubCat.name)}
                                      >
                                        <div
                                          onClick={() => setLinks("Collection")}
                                        >
                                          <Link
                                            to={`/certificate/SBT/${"SoulBound"}/${Cat.name
                                              }`}
                                          >
                                            <span>{SubCat.name}</span>
                                          </Link>
                                        </div>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </li>
                          </>
                        );
                      })}
                  </ul> */}

                </li>
              </ul>
            </div>
          </div>
        </div>
      </ListItem>

      <ListItem className={classes.listItem + " rightside "}>
        <div className={extra3 == true ? "active" : " "}>
          <Link
            className={classes.navLink}
            to="/create"
            id="ml_lit_align"
            onClick={() => {
              setLinks("Create"), setExtra3(true);
            }}
          >
            Create
          </Link>

          {/* <button onClick={loginAuth} className="card" style={styles.button}>
            Login
          </button> */}
        </div>
      </ListItem>

       {/* <ListItem className={classes.listItem + " rightside "}>
        <div className={extra3 == true ? "active" : " "} onClick={() => { SetServiceFee1() }}>
          Contact
        </div>
      </ListItem> */}

      <ListItem
        className={
          classes.listItem +
          " menu_dropdown dropdown_header_ul user_dd_ul pl-cust-user pl-cust-res ml-2 rightside"
        }
      >
        <div className={extra1 == true ? "active" : " "}>
          <Button
            color="transparent"
            className={
              classes.navLink +
              " pl-cust-wal but_cls_res mle_15 but_cls_res_stats"
            }
            disableRipple
          >
            Explore
          </Button>
          <div
            className="noti_parent noti_parnt_user statsmneu_dd_res posleft"
            id="statsmneu_dd_res"
          >
            <div className="px-0">
              <ul className="user_prof_ul pt-0 mt-0 mb-0">
                {/* <li onClick={() => setcat('All')}>
                  <Link to={`/Rankings`} onClick={() => { setLinks("Rankings"), setExtra1(true) }}>
                    <span>About</span>
                  </Link>
                </li>
                <li onClick={() => setcat('All')}>
                  <Link to="/how-it-works" onClick={() => { setLinks("Activity"), setExtra1(true) }}>
                    <span>Faq</span>
                  </Link>
                </li>

                <li onClick={() => setcat('All')}>
                  <Link to={`/Rankings`} onClick={() => { setLinks("Activity"), setExtra1(true) }}>
                    <span>Ranking</span>
                  </Link>
                </li>

                <li onClick={() => setcat('All')}>
                  <Link to={`/activity`} onClick={() => { setLinks("Activity"), setExtra1(true) }}>
                    <span>Activity</span>
                  </Link>
                </li>

                <li onClick={() => setcat('All')}>
                  <Link to={`/activity`} onClick={() => { setLinks("Activity"), setExtra1(true) }}>
                    <span>Contact</span>
                  </Link>
                </li> */}
                <li onClick={() => setcat("All")}>
                  <div onClick={() => setLinks("Explore")}>
                    <Link to={`/explore/All`}>
                      <span>All NFTs</span>
                    </Link>
                  </div>
                </li>

                {/* {console.log("yweiuweiiuiet", categorysate)} */}
                {Wallet_Details.Category &&
                  Wallet_Details.Category.length > 0 &&
                  Wallet_Details.Category.map((Cat) => {
                    return (
                      Cat.name !== "Ticket" && (
                        <>
                          <li onClick={() => setcat(Cat.name)}>
                            <div onClick={() => setLinks("Explore")}>
                              <Link to={`/explore/${Cat.name}`}>
                                <span>{Cat.name}</span>
                              </Link>
                            </div>
                          </li>
                        </>
                      )
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </ListItem>

      <ListItem
        className={
          classes.listItem +
          " menu_dropdown dropdown_header_ul user_dd_ul pl-cust-user pl-cust-res ml-2 rightside"
        }
      >
        <div className={extra1 == true ? "active" : " "}>
          <div className={extra4 == true ? "active" : " "}>
            <Link
              className={classes.navLink}
              id="ml_align"
              to="/pricing"
              onClick={() => {
                setLinks("Following"), setExtra4(true);
              }}
            >
              Pricing
            </Link>
          </div>
        </div>
      </ListItem>

      {/* <ListItem
        className={
          classes.listItem +
          " menu_dropdown dropdown_header_ul user_dd_ul pl-cust-user pl-cust-res ml-2 rightside"
        }
      >
        <div className={extra1 == true ? "active" : " "}>

          <div className={extra4 == true ? "active" : " "}>
            <Link className={classes.navLink} id='ml_align' to='/usercommondashboard' onClick={() => { setLinks("Following"), setExtra4(true) }}>common</Link>
          </div>
        </div>
      </ListItem> */}

      {/* <ListItem
        className={
          classes.listItem +
          " menu_dropdown dropdown_header_ul user_dd_ul pl-cust-user pl-cust-res ml-2 rightside"
        }
      >
        <div className={extra1 == true ? "active" : " "}>
          <Button
            color="transparent"
            className={classes.navLink + " pl-cust-wal but_cls_res but_cls_res_stats"} disableRipple
          >
            Stats
          </Button>
          <div
            className="noti_parent noti_parnt_user statsmneu_dd_res posleft"
            id="statsmneu_dd_res"
          >
            <div className="px-0">
              <ul className="user_prof_ul pt-0 mt-0 mb-0">
                <li onClick={() => setcat('All')}>
                  <Link to={`/Rankings`} onClick={() => { setLinks("Rankings"), setExtra1(true) }}>
                    <span>Rankings</span>
                  </Link>
                </li>
                <li onClick={() => setcat('All')}>
                  <Link to={`/activity`} onClick={() => { setLinks("Activity"), setExtra1(true) }}>
                    <span>Activity</span>
                  </Link>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </ListItem> */}

      {/* <ListItem className={classes.listItem + " rightside "}>
        <Link className={classes.navLink} to="/my-items">My items</Link>
        <div className={extra2 == true ? "active" : " "}>
          <Link className={classes.navLink} to="/how-it-works" onClick={() => { setLinks("How"), setExtra2(true) }}>
            FAQ
          </Link>
        </div>
      </ListItem> */}

      {/* <ListItem className={classes.listItem + " rightside "}>
        <div className={extra6 == true ? "active" : " "}>
          <Link className={classes.navLink} to="/tickets">
            Tickets
          </Link>
        </div>
      </ListItem> */}

      {/* <ListItem className={classes.listItem + " rightside "}>
        <div className={extra4 == true ? "active" : " "}>
          <Link className={classes.navLink} to="/following" onClick={() => { setLinks("Following"), setExtra4(true) }}>Following</Link>
        </div>
      </ListItem> */}
      {/* <ListItem className={classes.listItem + " rightside "}>
        <div className={extra5 == true ? "active" : " "}>
          <Link className={classes.navLink} to="/collections" onClick={() => { setLinks("collections"), setExtra5(true) }}>Collections</Link>
        </div>
      </ListItem> */}
      {/* <ListItem
        className={
          classes.listItem +
          " menu_dropdown dropdown_header_ul user_dd_ul pl-cust-user pl-cust-res ml-2 rightside"
        }
      >
        <div className={extra7 == true ? "active" : " "}>
          <Button
            color="transparent"
            className={classes.navLink + " pl-cust-wal but_cls_res but_cls_res_stats"} disableRipple
          >
            Collections
          </Button>
          <div
            className="noti_parent noti_parnt_user statsmneu_dd_res posleft"
            id="statsmneu_dd_res"
          >
            <div className="px-0">
              <ul className="user_prof_ul pt-0 mt-0 mb-0">
                <li onClick={() => setcat('Collection')}>
                  <Link to={`/collections/${"Collection"}`} onClick={() => { setLinks("collections"), setExtra7(true) }}>
                    <span>Collection</span>
                  </Link>
                </li>
                <li onClick={() => setcat('SoulBound')}>
                  <Link to={`/collections/${"SoulBound"}`} onClick={() => { setLinks("SoulBound"), setExtra7(true) }}>
                    <span>SoulBound Collection</span>
                  </Link>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </ListItem> */}

      {/* <ListItem
        className={
          classes.listItem +
          " menu_dropdown dropdown_header_ul user_dd_ul pl-cust-user pl-cust-res ml-2 rightside"
        }
      >
        <div className={extra7 == true ? "active" : " "}>
          <Button
            color="transparent"
            className={classes.navLink + " pl-cust-wal but_cls_res but_cls_res_stats"} disableRipple
          >
            Business
          </Button>
          <div
            className="noti_parent noti_parnt_user statsmneu_dd_res posleft"
            id="statsmneu_dd_res"
          >
            <div className="px-0">
              <ul className="user_prof_ul pt-0 mt-0 mb-0">
                <li >
                  <Link to='/pricinghome' >
                    <span>Business Home</span>
                  </Link>
                </li>
                <li >
                  <Link to='/pricing' >
                    <span>Pricing</span>
                  </Link>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </ListItem> */}

      <ListItem
        className={
          classes.listItem +
          " menu_dropdown dropdown_header_ul user_dd_ul pl-cust-user pl-cust-res ml-2 rightside"
        }
      >
        <div className={extra1new == true ? "active" : " "}>
          <Button
            color="transparent"
            className={
              classes.navLink + " pl-cust-wal but_cls_res but_cls_res_stats"
            }
            disableRipple
          >
            Resources
          </Button>
          <div
            className="noti_parent noti_parnt_user statsmneu_dd_res posleft"
            id="statsmneu_dd_res"
          >
            <div className="px-0">
              <ul className="user_prof_ul pt-0 mt-0 mb-0">
                <li onClick={() => setcat("All")}>
                  <Link
                    to="/about"
                    onClick={() => {
                      setLinks("About");
                    }}
                  >
                    <span>About</span>
                  </Link>
                </li>
                <li onClick={() => setcat("All")}>
                  <Link
                    to="/how-it-works"
                    onClick={() => {
                      setLinks("howitworks");
                    }}
                  >
                    <span>Faq</span>
                  </Link>
                </li>

                <li onClick={() => setcat("All")}>
                  <Link
                    to={`/Rankings`}
                    onClick={() => {
                      setLinks("Ranking");
                    }}
                  >
                    <span>Ranking</span>
                  </Link>
                </li>

                <li onClick={() => setcat("All")}>
                  <Link
                    to={`/activity`}
                    onClick={() => {
                      setLinks("Activity");
                    }}
                  >
                    <span>Activity</span>
                  </Link>
                </li>

                <li onClick={() => setcat("All")}>
                  <Link
                    onClick={() => {
                      setLinks("Blog"),
                        setExtra1(true),
                        openInNewTabOne("https://blog.lazyminter.com");
                    }}
                  >
                    <span>Blog</span>
                  </Link>
                </li>
                <li onClick={() => setcat("All")}>
                  <Link
                    to=""
                    onClick={() => {
                      setLinks("Contact");
                    }}
                  >
                    <span>Contact</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </ListItem>

      {/* <li className="swithcj_li">
                    <div className="d-flex justify-content-between align-items-center heade_switch">
                      <div>
                        <span className="hsder_ul_spn">
                          
                          <svg onClick={ toggleTheme} className="mr-1 sun_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M361.5 1.2c5 2.1 8.6 6.6 9.6 11.9L391 121l107.9 19.8c5.3 1 9.8 4.6 11.9 9.6s1.5 10.7-1.6 15.2L446.9 256l62.3 90.3c3.1 4.5 3.7 10.2 1.6 15.2s-6.6 8.6-11.9 9.6L391 391 371.1 498.9c-1 5.3-4.6 9.8-9.6 11.9s-10.7 1.5-15.2-1.6L256 446.9l-90.3 62.3c-4.5 3.1-10.2 3.7-15.2 1.6s-8.6-6.6-9.6-11.9L121 391 13.1 371.1c-5.3-1-9.8-4.6-11.9-9.6s-1.5-10.7 1.6-15.2L65.1 256 2.8 165.7c-3.1-4.5-3.7-10.2-1.6-15.2s6.6-8.6 11.9-9.6L121 121 140.9 13.1c1-5.3 4.6-9.8 9.6-11.9s10.7-1.5 15.2 1.6L256 65.1 346.3 2.8c4.5-3.1 10.2-3.7 15.2-1.6zM352 256c0 53-43 96-96 96s-96-43-96-96s43-96 96-96s96 43 96 96zm32 0c0-70.7-57.3-128-128-128s-128 57.3-128 128s57.3 128 128 128s128-57.3 128-128z"/></svg>
                          <span className='theme_change_span'>/</span>  
                          <svg onClick={ toggleTheme} className="ml-1 moon_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M223.5 32C100 32 0 132.3 0 256S100 480 223.5 480c60.6 0 115.5-24.2 155.8-63.4c5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6c-96.9 0-175.5-78.8-175.5-176c0-65.8 36-123.1 89.3-153.3c6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z"/></svg>
                        </span>
                      </div>
                     
                    </div>
                  </li> */}

      {/* <ListItem className={classes.listItem + " rightside "}>
        <div className={extra3 == true ? "active" : " "}>
          <Link className={classes.navLink} to="/pricing" onClick={() => { setLinks("Create"), setExtra3(true) }}>
            Pricing
          </Link>
        </div>
      </ListItem>

      <ListItem className={classes.listItem + " rightside "}>
        <div className={extra3 == true ? "active" : " "}>
          <Link className={classes.navLink} to="/pricinghome" onClick={() => { setLinks("Create"), setExtra3(true) }}>
            BusinessHome
          </Link>
        </div>
      </ListItem> */}

      <ListItem
        className={
          classes.listItem +
          " menu_dropdown dropdown_header_ul user_dd_ul seafhcnlksdf "
        }
      >
        <div className="search_inp_group">
          <input
            id="searchid"
            type="text"
            autoComplete="off"
            className={search ? "search_inp d-block" : "d-none search_inp"}
            placeholder="Search items, collections and items"
            onChange={(e) => toggleSearchmenu(e)}
          />
          <div
            className="search_inp_group_prepend"
            onClick={() => setSearch(!search)}
          >
            <i className={search ? "fas fa-times" : "fas fa-search"}></i>
          </div>
        </div>
        <div
          className="noti_parent noti_parnt_user searchmneu_dd d-none searchcontent"
          id="searchmneu_dd"
        >
          <Scrollbars autoHeight autoHeightMax={350} className="nto_scrol_div">
            {Loader ? (
              <>
                <TailSpin
                  wrapperClass="scrreactloader"
                  color="#fe0000"
                  height={100}
                  width={70}
                />
              </>
            ) : (
              <>
                <p className="font_we_700 px-3 pt-3">Items</p>
                <ul className="noti_ul_dd searchscroll">
                  {SearchRes.items &&
                    SearchRes.items.Success &&
                    SearchRes.items.records &&
                    SearchRes.items.records.length > 0 ? (
                    SearchRes.items.records.map((Searched) => {
                      // console.log("asdasdzxcczxcxzasdasdasd",Searched);
                      return (
                        <Link
                          to={`/info/${Searched.contractAddress}/${Searched.tokenowners_current.tokenOwner}/${Searched.tokenID}`}
                        >
                          <li className="px-3">
                            <div className="media">
                              {Searched.image &&
                                Searched.image.split(".").pop() !== "mp4" ? (
                                <img
                                  src={
                                    Searched.additionalImage
                                      ? `${config.Back_URL}/nftImg/${Searched.tokenCreator}/${Searched.image}`
                                      : require("../../assets/images/collections_01.png")
                                  }
                                  alt="User"
                                  className="img-fluid mr-2 img_user_noti align-self-center"
                                />
                              ) : (
                                <video
                                  src={`${config.Back_URL}/nftImg/${Searched.tokenCreator}/${Searched.image}`}
                                  autoPlay
                                  muted
                                  className="img-fluid mr-2 img_user_noti align-self-center"
                                />
                              )}
                              <div className="media-body flex_body">
                                <div>
                                  <p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc">
                                    {Searched.tokenName.slice(0, 20)}
                                  </p>
                                  {Searched.tokenowners_current.tokenPrice ? (
                                    <p className="mt-0 banner_desc_user mb-0 font_12 not_banner_dessc">
                                      {Searched.tokenowners_current.tokenPrice}{" "}
                                      {config.currencySymbol}
                                    </p>
                                  ) : (
                                    <p className="mt-0 banner_desc_user mb-0 font_12 not_banner_dessc">
                                      by{" "}
                                      {Searched?.tokenOwnerInfo?.name
                                        ? `@${Searched?.tokenOwnerInfo?.name}`
                                        : Searched?.tokenOwner?.slice(0, 6)}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </li>
                        </Link>
                      );
                    })
                  ) : (
                    <>
                      {SearchRes.SoulBound &&
                        SearchRes.SoulBound.Success &&
                        SearchRes.SoulBound.records &&
                        SearchRes.SoulBound.records.length > 0 ? (
                        SearchRes.SoulBound.records.map((Searched) => {
                          console.log("SoulllBouuunnd", Searched);
                          return (
                            <Link
                              to={`/SoulInfo/${Searched.contractAddress}/${Searched.tokenOwner}/${Searched.QR_tokenID}`}
                            >
                              <li className="px-3">
                                <div className="media">
                                  {Searched.FinalOutBulkPath &&
                                    Searched.FinalOutBulkPath.split(".").pop() !==
                                    "mp4" ? (
                                    <img
                                      src={
                                        Searched.FinalOutBulkPath
                                          ? `${config.Back_URL}/${Searched.FinalOutBulkPath}`
                                          : require("../../assets/images/collections_01.png")
                                      }
                                      alt="User"
                                      className="img-fluid mr-2 img_user_noti align-self-center"
                                    />
                                  ) : (
                                    <video
                                      src={`${config.Back_URL}/${Searched.FinalOutBulkPath}`}
                                      autoPlay
                                      muted
                                      className="img-fluid mr-2 img_user_noti align-self-center"
                                    />
                                  )}
                                  <div className="media-body flex_body">
                                    <div>
                                      <p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc">
                                        {Searched.tokenName.slice(0, 20)}
                                      </p>
                                      {/* {(Searched.tokenowners_current.tokenPrice) ?
                                        <p className="mt-0 banner_desc_user mb-0 font_12 not_banner_dessc">
                                          {Searched.tokenowners_current.tokenPrice} {config.currencySymbol}
                                        </p>
                                        :
                                        <p className="mt-0 banner_desc_user mb-0 font_12 not_banner_dessc">
                                          by {(Searched?.tokenOwnerInfo?.name) ? `@${Searched?.tokenOwnerInfo?.name}` : Searched?.tokenOwner?.slice(0, 6)}
                                        </p>
                                      } */}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </Link>
                          );
                        })
                      ) : (
                        <h5 className="noitem">--No Item--</h5>
                      )}
                    </>
                  )}
                </ul>

                <p className="font_we_700 px-3 pt-3">Users</p>
                <ul className="noti_ul_dd">
                  {SearchRes.users && SearchRes.users.length > 0 ? (
                    SearchRes.users.map((Searched) => {
                      return (
                        <Link
                          to={
                            Searched.customurl
                              ? `/my-items/user/${Searched.customurl}`
                              : `/my-items/${Searched.curraddress}`
                          }
                        >
                          <li className="px-3">
                            {console.log("Sdasdasdas", Searched)}
                            <div className="media">
                              <img
                                src={
                                  Searched.image
                                    ? `${config.Back_URL}/Users/${Searched.PrimaryAddr}/profileImg/${Searched.image}`
                                    : require("../../assets/images/lazymintingnew/logo.png")
                                }
                                alt="User"
                                className="img-fluid mr-2 user_ul_new align-self-center"
                              />
                              <div className="media-body flex_body">
                                <div>
                                  <p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc">
                                    {Searched.name}
                                  </p>
                                  <p className="mt-0 banner_desc_user mb-0 font_12 not_banner_dessc">
                                    {Searched.customurl &&
                                      `@${Searched.customurl}`}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </li>
                        </Link>
                      );
                    })
                  ) : (
                    <>
                      <h5 className="noitem">--No Users--</h5>
                    </>
                  )}
                </ul>
              </>
            )}
          </Scrollbars>

          <div className="text-center">
            <button className="btn new_btn_grad" tabIndex="0" type="button">
              <span>
                <Link to={`/search/query/${key}`}>Search</Link>
              </span>
            </button>
          </div>
        </div>
      </ListItem>
      {(Wallet_Details.UserAccountAddr == "" ||
        Wallet_Details?.UserAccountAddr == undefined) && (
          <ListItem
            className={
              classes.listItem +
              " menu_dropdown dropdown_header_ul user_dd_ul loginUser_1"
            }
          >
            {localStorage.getItem("loggedinaddress") == undefined ? (
              <Link data-toggle="modal" data-target="#register_modal">
                <i className="fa fa-user-plus mr-2 viji"></i>
              </Link>
            ) : (
              <>
                {/* {localStorage.getItem("loggedinaddress") != undefined && */}
                <i
                  className="fas fa-sign-out-alt log_arrow_icon loggingout"
                  onClick={() => {
                    logoutfunction();
                  }}
                ></i>
                {/* } */}
              </>
            )}
          </ListItem>
        )}
      {/* {Wallet Connect} */}
      {(Wallet_Details.UserAccountAddr == "" ||
        Wallet_Details?.UserAccountAddr == undefined) && (
          <ListItem
            className={
              classes.listItem + " menu_dropdown dropdown_header_ul user_dd_ul"
            }
          >
            {" "}
            <>
              <>
                <Link to={"/connect-wallet"} className="connectwallet">
                  Wallet Connect
                </Link>
                {/* <a className="connectwallet" href="/lazymintingnft/connect-wallet">  </a> */}
              </>
            </>
          </ListItem>
        )}
      {Wallet_Details.UserAccountAddr != "" && (
        <ListItem
          className={
            classes.listItem +
            " menu_dropdown dropdown_header_ul user_dd_ul pl-cust-user"
          }
        >
          {Wallet_Details.UserAccountAddr != "" && (
            <div
              className="noti_parent noti_parnt_user usemneu_dd posleft"
              id="usemneu_dd"
            >
              <div className="px-0">
                <ul className="user_prof_ul pt-0 mt-0 mb-0">
                  {Wallet_Details.UserAccountAddr &&
                    Wallet_Details.UserAccountAddr != "" ? (
                    <>
                      {console.log("qweqweqoiiooioi", Subcribed)}
                      {Subcribed == "false" ? (
                        <li>
                          <Link to="/usercommondashboard">
                            <span>
                              <i className="fas fa-user mr-2"></i>Dashboard
                            </span>
                          </Link>
                          {/* <Link to="/edit-profile"><span><i class="fas fa-user mr-2"></i>My profile</span></Link> */}
                        </li>
                      ) : (
                        <li>
                          <Link to="/usercommondashboard">
                            <span>
                              <i className="fas fa-user mr-2"></i>Dashboard
                            </span>
                          </Link>
                          {/* <Link to="/edit-profile"><span><i class="fas fa-user mr-2"></i>My profile</span></Link> */}
                        </li>
                      )}
                      <li>
                        <Link to="/my-items">
                          <span>
                            <i className="fas fa-file-image mr-2"></i>My items
                          </span>
                        </Link>
                      </li>
                      {localStorage.getItem("loggedinaddress") != "" && (
                        <li>
                          <Link
                            data-toggle="modal"
                            data-target="#register_modal"
                          >
                            <span>
                              <i className="fas fa-sign-out-alt mr-2"></i>Login
                            </span>
                          </Link>
                        </li>
                      )}
                      {/* {console.log("logge address", localStorage.getItem("loggedinaddress"), Wallet_Details.UserAccountAddr, localStorage.getItem("loggedinaddress") == Wallet_Details.UserAccountAddr)} */}
                      {localStorage.getItem("userAddress") ==
                        Wallet_Details.UserAccountAddr && (
                          <li>
                            {/* <Link data-toggle="modal" > */}
                            <span
                              onClick={() => {
                                logoutfunction();
                              }}
                            >
                              <i className="fas fa-sign-out-alt mr-2"></i>Logout
                            </span>
                            {/* </Link> */}
                          </li>
                        )}

                      {(!localStorage.getItem("userAddress") ||
                        localStorage.getItem("userAddress") !=
                        Wallet_Details.UserAccountAddr) && (
                          <li>
                            <Link
                              data-toggle="modal"
                              data-target="#register_modal"
                            >
                              <span>
                                <i className="fa fa-user-plus mr-2 viji1"></i>
                                Register
                              </span>
                            </Link>
                          </li>
                        )}
                      {/* <Link to="/my-items"><span><i class="fas fa-file-image mr-2"></i>My items</span></Link> */}

                      {/* <li>
                    <Link to="/notification">
                      <span>
                        <i className="fas fa-bell mr-2"></i>Notifications
                      </span>
                    </Link>
                    <Link to="/my-items"><span><i class="fas fa-file-image mr-2"></i>My items</span></Link>
                  </li> */}

                      <li>
                        <Link
                          to=""
                          onClick={() => {
                            Disconnect("click");
                          }}
                        >
                          <span>
                            <i className="fas fa-sign-out-alt mr-2"></i>
                            Disconnect
                          </span>
                        </Link>
                      </li>
                    </>
                  ) : (
                    <>
                      {/* <li className="swithcj_li">
                    <div className="d-flex justify-content-between align-items-center heade_switch">
                      <div>
                        <span className="hsder_ul_spn">
                          <i className="fas fa-lightbulb mr-2"></i>Dark theme
                        </span>
                      </div>
                      <label className="switch toggle_custom">
                        <input type="checkbox" onChange={toggletheme} />
                        <span className="slider"></span>
                      </label>
                    </div>
                  </li> */}
                    </>
                  )}
                </ul>
              </div>
            </div>
          )}
        </ListItem>
      )}
      {Wallet_Details.UserAccountAddr != "" && (
        <ListItem
          className={
            classes.listItem +
            " menu_dropdown dropdown_header_ul user_dd_ul pl-cust-user"
          }
        >
          {/* <img src={require("../../assets/images/user_01.png")} alt="User" className="img-fluid user_header" /> */}
          <Button
            color="transparent"
            className={classes.navLink + " pl-cust-wal header_usercirc_align"}
            disableRipple
          >
            {Wallet_Details.AddressUserDetails &&
              Wallet_Details.AddressUserDetails.image ? (
              <Button
                color="transparent"
                className={
                  classes.navLink + " pl-cust-wal header_usercirc_align"
                }
                disableRipple
              >
                {Wallet_Details.AddressUserDetails &&
                  Wallet_Details.AddressUserDetails.image && (
                    <img
                      src={`${config.Back_URL}/Users/${Wallet_Details.AddressUserDetails.PrimaryAddr}/profileImg/${Wallet_Details.AddressUserDetails.image}`}
                      alt="User"
                      className="img-fluid menu_icons"
                    />
                  )}
              </Button>
            ) : (
              <>
                {Wallet_Details.UserAccountAddr != "" &&
                  Wallet_Details?.UserAccountAddr !== undefined && (
                    <>
                      <AccountCircle className="" />
                      <span className="icon_txt">Wallet</span>
                    </>
                  )}
              </>
            )}
          </Button>
          {Wallet_Details.UserAccountAddr != "" && (
            <div
              className="noti_parent noti_parnt_user usemneu_dd posleft"
              id="usemneu_dd"
            >
              {/* <p className="noti_head pt-4 mb-0">
              <span>Enrico Cole</span>
            </p> */}
              <div className="px-0">
                {/* <p className="info_des">oxc4c16ab5ac7d...b21a<i className="fas fa-sticky-note notes_fa cur_pointer"></i></p> */}
                {/* <div className="media header_media pt-0">
              <img src={require("../../assets/images/eth.png")} alt="User" className="img-fluid mr-3 coin_header" />
                <div className="media-body flex_body">
                  <div>
                  <p className="mt-0 media_num">Balance</p>
                  <p className="balance_txt_header pt-0 mb-0">
                    <span>2.3B OPEN</span>
                  </p>
                  
                    </div>
                  
                        </div>
              </div> */}

                <ul className="user_prof_ul pt-0 mt-0 mb-0">
                  {Wallet_Details.UserAccountAddr &&
                    Wallet_Details.UserAccountAddr != "" ? (
                    <>
                      {Subcribed == "false" ? (
                        <li>
                          <Link to="/usercommondashboard">
                            <span>
                              <i className="fas fa-user mr-2"></i>Dashboard
                            </span>
                          </Link>
                          {/* <Link to="/edit-profile"><span><i class="fas fa-user mr-2"></i>My profile</span></Link> */}
                        </li>
                      ) : (
                        <li>
                          <Link to="/usercommondashboard">
                            <span>
                              <i className="fas fa-user mr-2"></i>Dashboard
                            </span>
                          </Link>
                          {/* <Link to="/edit-profile"><span><i class="fas fa-user mr-2"></i>My profile</span></Link> */}
                        </li>
                      )}
                      <li>
                        <Link to="/my-items">
                          <span>
                            <i className="fas fa-file-image mr-2"></i>My items
                          </span>
                        </Link>
                      </li>
                      {localStorage.getItem("userAddress") !=
                        Wallet_Details.UserAccountAddr && (
                          <li>
                            <Link
                              data-toggle="modal"
                              data-target="#register_modal"
                            >
                              <span>
                                <i className="fas fa-sign-out-alt mr-2"></i>Login
                              </span>
                            </Link>
                          </li>
                        )}
                      {/* {console.log("logge address", localStorage.getItem("loggedinaddress"), Wallet_Details.UserAccountAddr, localStorage.getItem("loggedinaddress") == Wallet_Details.UserAccountAddr)} */}
                      {localStorage.getItem("userAddress") ==
                        Wallet_Details.UserAccountAddr && (
                          <li>
                            <Link data-toggle="modal">
                              <span
                                onClick={() => {
                                  logoutfunction();
                                }}
                              >
                                <i className="fas fa-sign-out-alt mr-2"></i>Logout
                              </span>
                            </Link>
                          </li>
                        )}

                      {(!localStorage.getItem("userAddress") ||
                        localStorage.getItem("userAddress") !=
                        Wallet_Details.UserAccountAddr) && (
                          <li>
                            <Link
                              data-toggle="modal"
                              data-target="#register_modal"
                            >
                              <span>
                                <i className="fa fa-user-plus mr-2 viji2"></i>
                                Register
                              </span>
                            </Link>
                          </li>
                        )}
                      {/* <Link to="/my-items"><span><i class="fas fa-file-image mr-2"></i>My items</span></Link> */}

                      {/* <li>
                    <Link to="/notification">
                      <span>
                        <i className="fas fa-bell mr-2"></i>Notifications
                      </span>
                    </Link>
                    <Link to="/my-items"><span><i class="fas fa-file-image mr-2"></i>My items</span></Link>
                  </li> */}

                      {/* <li className="swithcj_li">
                    <div className="d-flex justify-content-between align-items-center heade_switch">
                      <div>
                        <span className="hsder_ul_spn">
                          <i className="fas fa-lightbulb mr-2"></i>Dark theme
                        </span>
                      </div>
                      <label className="switch toggle_custom">
                        <input type="checkbox" onChange={toggletheme} />
                        <span className="slider"></span>
                      </label>
                    </div>
                  </li> */}
                      <li>
                        <Link
                          to=""
                          onClick={() => {
                            Disconnect("click");
                          }}
                        >
                          <span>
                            <i className="fas fa-sign-out-alt mr-2"></i>
                            Disconnect
                          </span>
                        </Link>
                      </li>
                    </>
                  ) : (
                    <>
                      {/* <li className="swithcj_li">
                    <div className="d-flex justify-content-between align-items-center heade_switch">
                      <div>
                        <span className="hsder_ul_spn">
                          <i className="fas fa-lightbulb mr-2"></i>Dark theme
                        </span>
                      </div>
                      <label className="switch toggle_custom">
                        <input type="checkbox" onChange={toggletheme} />
                        <span className="slider"></span>
                      </label>
                    </div>
                  </li> */}
                    </>
                  )}
                </ul>
              </div>
            </div>
          )}
        </ListItem>
      )}

      {localStorage.walletConnectType == "LMWallet" ? (
        <>
          {Wallet_Details.UserAccountAddr &&
            Wallet_Details.UserAccountAddr != "" && (
              <ListItem
                className={
                  classes.listItem +
                  " menu_dropdown dropdown_header_ul user_dd_ul pl-cust-user"
                }
              >
                <Button
                  color="transparent"
                  className={classes.navLink + " pl-cust-wal"}
                  disableRipple
                >
                  <Link to="/LMWallet">
                    {" "}
                    <i class="fas fa-wallet"></i>
                  </Link>{" "}
                  {/* <AccountBalanceWallet className="menu_icons1" /> */}
                  <span className="icon_txt">Wallet</span>
                </Button>
              </ListItem>
            )}
        </>
      ) : (
        <>
          {Wallet_Details.UserAccountAddr &&
            Wallet_Details.UserAccountAddr != "" && (
              <ListItem
                className={
                  classes.listItem +
                  " menu_dropdown dropdown_header_ul user_dd_ul pl-cust-user"
                }
              >
                <Button
                  color="transparent"
                  className={classes.navLink + " pl-cust-wal"}
                  disableRipple
                >
                  <i class="fas fa-wallet"></i>
                  {/* <AccountBalanceWallet className="menu_icons1" /> */}
                  <span className="icon_txt">Wallet</span>
                </Button>

                <div
                  className="noti_parent noti_parnt_user usemneu_dd_wallet posleft"
                  id="usemneu_dd_wallet"
                >
                  <p className="noti_head pt-4 mb-0">
                    <span>
                      {Wallet_Details.AddressUserDetails &&
                        Wallet_Details.AddressUserDetails.name
                        ? Wallet_Details.AddressUserDetails.name
                        : Wallet_Details.UserAccountAddr.slice(0, 15)}
                    </span>
                  </p>
                  <div className="px-3">
                    <p className="info_des text-break">
                      {Wallet_Details.UserAccountAddr.slice(0, 15)}
                      <CopyToClipboard
                        text={Wallet_Details.UserAccountAddr}
                        onCopy={() =>
                          toast.success("Address Copied", {
                            closeButton: true,
                            autoClose: 1500,
                            transition: Flip,
                            theme: "dark",
                          })
                        }
                      >
                        <i className="fas fa-sticky-note notes_fa cur_pointer"></i>
                      </CopyToClipboard>
                    </p>
                    <div className="media header_media pt-3">
                        <img
                          src={Wallet_Details?.networkConfiguration?.Icon}
                          alt="User"
                          className="img-fluid mr-3 coin_header coin_header_pol"
                        />
                
                      <div className="media-body flex_body">
                        <div>
                          <p className="mt-0 media_num">Balance</p>
                          <p className="balance_txt_header pt-0 mb-0">
                            <span>
                              {Wallet_Details?.UserAccountBal?.toFixed(5)}{" "}
                              {
                                Wallet_Details?.networkConfiguration
                                  ?.currencySymbol
                              }
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr className="hr_grey"></hr>
                    <div className="text-center pb-3">
                      <p className="mt-0 media_num">Wallet Connected</p>
                      <span
                        onClick={() => {
                          console.log("oooooooooooooooooooo");
                          if (localStorage.walletConnectType == "LMWallet")
                            history.push("/LMWallet");
                        }}
                      >
                        <img
                          src={
                            localStorage.walletConnectType == "MetaMask"
                              ? require("../../assets/images/wallet_01.png")
                              : localStorage.walletConnectType == "TorusWallet"
                                ? require("../../assets/images/torus.png")
                                : localStorage.walletConnectType == "LMWallet"
                                  ? require("../../assets/images/lazymintingnew/logo.png")
                                  : require("../../assets/images/wallet_03.png")
                          }
                          alt="User"
                          className="img-fluid mr-2 img_user_noti align-self-center mt-1 mb-2"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </ListItem>
            )}
        </>
      )}
      {/* <ListItem className={classes.listItem + " create_btn mode theme_change_btn"}>
        <Button className={classes.navLink} onClick={toggleTheme}>
          <p className="light svg_lft_align"><Brightness3 /></p>
          <p className="dark svg_lft_align"><WbSunny /></p>
        </Button>
      </ListItem> */}

      <ListItem
        className={classes.listItem + " create_btn mode theme_change_btn"}
      >
        {localStorage.getItem("theme1") == "LT" ||
          localStorage.getItem("theme1") == undefined ? (
          <Button
            className={classes.navLink}
            onClick={() => {
              themechange("DT");
            }}
          >
            <p className="light svg_lft_align">
              <Brightness3 />
            </p>
          </Button>
        ) : (
          <Button
            className={classes.navLink}
            onClick={() => {
              themechange("LT");
            }}
          >
            <p className="dark svg_lft_align">
              <WbSunny />
            </p>
          </Button>
        )}
      </ListItem>

      <Button
        className="create_btn btn-block w-25 d-none"
        data-toggle="modal"
        data-target="#register_modal"
        id="triggerlogin"
      ></Button>
    </List>
  );
}
