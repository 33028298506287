import axios from "axios";
import config from "../../lib/config";
//import Multiple from "../../ABI/userContract1155.json";
//import Single from "../../ABI/userContract721.json";
import { toast } from "react-toastify";
import isEmpty from "lib/isEmpty";
import { decodedata, encodedata } from "../../hooks/useWallet";
import { AES, enc } from 'crypto-js'
toast.configure();
let toasterOption = config.toasterOption;

export const NFTnameValidation = async (NFTname) => {
  try {
    var respdata = await axios({
      method: "get",
      url: `${config.vUrl}/token/validation/namevalidate/${NFTname}`
    })
    return respdata.data
  }
  catch (e) {
    console.log("Error in NFTnameValidation Function", e)
    return respdata.data
  }
}


export const v1_ipfsImageHashGet = async (formData) => {
  try {
    var respdata = await axios({
      method: "post",
      url: `${config.vUrl}/token/create/ipfsdatum`,
      data: formData,
      'headers': {
        'Authorization': localStorage.user_token
      },
    })
    respdata.data = decodedata(respdata.data);
    return respdata.data
  }
  catch (e) {
    console.log("Error in ipfsImageHashGet Function", e)
    respdata.data = decodedata(respdata.data);
    return respdata.data
  }
};

export const v1_NFTDetails2DB = async (formData) => {
  try {
    var respdata = await axios({
      method: "post",
      url: `${config.vUrl}/token/add/NFT`,
      data: formData,
      'headers': {
        'Authorization': localStorage.user_token
      },
    })
    respdata.data = decodedata(respdata.data);
    return respdata.data
  }
  catch (e) {
    console.log("Error in NFTDetails2DB Function", e)
    respdata.data = decodedata(respdata.data);
    return respdata.data
  }
};

export const ExplorePage = async (payload) => {
  console.log("explore payload", payload)
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/explore/NFT`,
      params: payload
    });
    console.log()
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("ErrorinExplorePageFunction", err)
  }
}

export const v1_MyItemsData = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/myitems/NFT`,
      params: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("ErrorinMyitemsDataFunction", err)
  }
}

export const LikeAction = async (payload) => {
  try {
    // payload = { encode: encodedata(payload) };

    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/mylike/NFT`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    // resp.data = decodedata(resp.data);
    console.log("dasdasdasdasd", resp.data);
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("ErrorinLikeActionFunction", err)
  }
}

export const v1_NFTInfo = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/NFTInfo`,
      data: payload
    });
    console.log()
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Errorinv1_NFTinfoFunction", err)
  }
}
export const v1_NFTInfo1 = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/collcetionupdate`,
      data: payload
    });
    console.log()
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Errorinv1_NFTinfoFunction", err)
  }
}

export const v1_UpdateNFTOwners = async (payload) => {
  console.log("sdcdhcdjdacds", payload);
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/UpdateNFTOwners`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    console.log()
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Errorinv1_UpdateNFTOwnersFunction", err)
  }
}

export const v1_UpdateNFTOwnersBulk = async (payload) => {
  console.log("sdcdhcdjdacds", payload);
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/UpdateNFTOwners`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    console.log()
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Errorinv1_UpdateNFTOwnersFunction", err)
  }
}

export const v1_UpdateNFTState = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/UpdateNFTState`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    console.log()
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Errorinv1_UpdateNFTOwnersFunction", err)
  }
}

export const v1_BidAction2DB = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/BidAction2DB`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Errorinv1_UpdateNFTOwnersFunction", err)
  }
}

export const v1_CancelBid = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/cancelBid`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Errorinv1_CancelBidFunction", err)
  }
}

export const v1_BurnToken = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/burntoken`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Errorinv1_BurnTokenFunction", err)
  }
}

export const v1_Search = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/SearchQuery`,
      params: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Errorinv1_SearchBidFunction", err)
  }
}

export const v1_TopCreators = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/topCreators`,
      params: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Errorinv1_TopCreatorsFunction", err)
  }
}

export const FollowingNFTs = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/getfollowingNFTs`,
      params: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Errorinv1_TopCreatorsFunction", err)
  }
}

export const v1_Featured = async () => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/Featured`,
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Errorinv1_FeaturedFunction", err)
  }
}

export const v1_ReportToken = async (Payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/Report`,
      data: Payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Errorinv1_ReportTokenFunction", err)
  }
}

export const v1_ConNFTBalCheck = async (Payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/ConNFTBalCheck`,
      'data': Payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Errorinv1_ConNFTBalCheckFunction", err, Payload)
  }
}

//// lazy mint functonality

export const updateTokenID = async (payload) => {
  // alert("token id")
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/UpdatetokenId`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    console.log("respdata", resp.data)
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Errorinv1_UpdateNFTOwnersFunction", err)
  }
}

export const updateTokenIDBulk = async (payload) => {
  // alert("token id")
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/updateTokenIDBulk`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    console.log("respdata", resp.data)
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Errorinv1_UpdateNFTOwnersFunction", err)
  }
}

export const updateTokenIDBulkOwner = async (payload) => {
  // alert("token id")
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/updateTokenIDBulkOwner`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    console.log("respdata", resp.data)
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Errorinv1_UpdateNFTOwnersFunction", err)
  }
}

export const BulkImgGenNext = async (payload) => {
  // alert("token id")
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/BulkImgGenNext`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    console.log("respdata", resp.data)
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Errorinv1_UpdateNFTOwnersFunction", err)
  }
}

export const VerifyAndAdminTransfer = async (payload) => {
  // alert("token id")
  console.log("asadssadsadasdasdas", payload);
  try {
    var formData = new FormData();
    if (payload) { formData.append("name", JSON.stringify(payload)); }
    if (payload.ExcelFile) { formData.append("ExcelFile", payload.ExcelFile); }
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/VerifyAndAdminTransfer`,
      data: formData,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    console.log("VerifyAndAdminTransfer", resp.data)
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("VerifyAndAdminTransfer", err)
  }
}

export const HomeCategory = async (Arg) => {
  // console.log("timeeeeee",Arg)
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/HomeCategory`,
      params: Arg
    });
    return {
      data: resp
    }
  }
  catch (err) {
  }

}

export const BulkmintingNFT = async (payload) => {
  console.log("payloadconfig.Backurl", payload);
  try {
    let bodyFormData = new FormData();
    if (payload.BulkImage) {
      if (payload.BulkImage.length > 0) {
        for (var i = 0; i < payload.BulkImage.length; i++) {
          // bodyFormData.append('File', payload.Filestate[0]);
          console.log("djnfksdnfksdnkds", payload.BulkImage[i]);
          bodyFormData.append(`BulkImageFiles`, payload.BulkImage[i])
        }
      }
    }
    bodyFormData.append('ExcelFile', payload.ExcelFile);
    bodyFormData.append('Profileimg', payload.Profileimg);
    bodyFormData.append('Coverimg', payload.Coverimg);
    bodyFormData.append('Category', encodedata(payload.Category.NFTCategory));
    bodyFormData.append('NFTSubCategory', encodedata(payload.Category.NFTSubCategory));
    bodyFormData.append('contractAddress', encodedata(payload.Category.contractaddress));
    bodyFormData.append('type', encodedata(payload.Category.type));
    bodyFormData.append('CollectionName', encodedata(payload.Category.CollectionName));
    bodyFormData.append('Address', encodedata(payload.Curraddressurr));
    bodyFormData.append('Coinname', encodedata(payload.Category.Coinname));

    let respData = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/bulkminting`,
      'headers': {
        'Content-Type': 'multipart/form-data',
        'Authorization': localStorage.user_token
      },
      'data': bodyFormData,

    });
    console.log("abckanksand", respData);
    respData.data = decodedata(respData.data);
    console.log("asadsadxasdasdas", respData.data);

    return {
      message: respData
    }
  }
  catch (err) {
    return { error: err }
  }
}

export const CollectionValidate = async (NFTname) => {
  try {
    var respdata = await axios({
      method: "get",
      url: `${config.vUrl}/token/validation/collectionnamevalidate/${NFTname}`
    })
    return respdata.data
  }
  catch (e) {
    console.log("Error in NFTnameValidation Function", e)
    return respdata.data
  }
}

export const ListingCollection = async () => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/ListingCollection`,
    });
    return {
      data: resp
    }
  }
  catch (err) {
  }

}
export const ListingCollection1 = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/ListingCollection1`,
      data: payload
    });
    return {
      data: resp
    }
  }
  catch (err) {
  }

}


export const ListingGetdata = async (Arg) => {
  console.log("ARGARGGGg", Arg);
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/ListingGetdata`,
      params: Arg
    });
    return {
      data: resp
    }
  }
  catch (err) {
  }

}

export const TokenAndTokenOwnerUpdate_Bulk_minting = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/BulkMintingTokenDbUpdate`,
      'headers': {
        'Authorization': localStorage.user_token
      },
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

// export const BurnTokenStatus = async (payload) => {
//   try {
//     let resp = await axios({
//       'method': 'post',
//       'url': `${config.vUrl}/token/BurnTokenStatus`,
//       data: payload
//     });
//     return {
//       data: resp.data
//     }
//   }
//   catch (err) {
//   }
// }

export const MyItemCollection = async (Arg) => {
  console.log("ARGARGGGg", Arg);
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/MyItemCollection`,
      params: Arg
    });
    return {
      data: resp
    }
  }
  catch (err) {
  }

}

export const getWhiteListUser = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.vUrl}/token/getWhiteListUser`,
      params: data
    });
    return respData.data
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const networklist = async () => {
  try {
    let respData = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/networklist`,
    });
    //console("CheckToken", respData.data)
    return {
      data: respData
    }
  }
  catch (err) {
  }
}

// export const LoginRegisterFind = async (Arg) => {
//   console.log("timeeeeexczxczxe",Arg)
//   try {
//     let resp = await axios({
//       'method': 'get',
//       'url': `${config.vUrl}/token/LoginRegisterFind`,
//        params: Arg
//     });
//     return {
//       data: resp
//     }
//   }
//   catch (err) {
//   }

// }

export const v1_TransferToken = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/TransferToken`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Errorinv1_BurnTokenFunction", err)
  }
}

export const v1_TransferTokenBulk = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/TransferTokenBulk`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Errorinv1_BurnTokenFunction", err)
  }
}

export const SolBoundNameValidate = async (NFTname) => {
  try {
    var respdata = await axios({
      method: "get",
      url: `${config.vUrl}/token/validation/SolBoundNameValidate/${NFTname}`
    })
    return respdata.data
  }
  catch (e) {
    console.log("Error in SolBoundNameValidate Function", e)
    return respdata.data
  }
}

export const SoulBoundNFT = async (payload) => {
  console.log("payloadconfig.Backurl", payload);
  try {
    let bodyFormData = new FormData();
    if (payload.BulkImage) {
      if (payload.BulkImage.length > 0) {
        for (var i = 0; i < payload.BulkImage.length; i++) {
          // bodyFormData.append('File', payload.Filestate[0]);
          console.log("djnfksdnfksdnkds");
          bodyFormData.append(`BulkImageFiles`, payload.BulkImage[i])
        }
      }
    }
    bodyFormData.append('ExcelFile', payload.ExcelFile);
    bodyFormData.append('Profileimg', payload.Profileimg);
    bodyFormData.append('Coverimg', payload.Coverimg);
    bodyFormData.append('contractAddress', encodedata(payload.Category.contractaddress));
    bodyFormData.append('type', encodedata(payload.Category.type));
    bodyFormData.append('CollectionName', encodedata(payload.Category.CollectionName));
    bodyFormData.append('Address', encodedata(payload.Curraddressurr));
    bodyFormData.append('SelectedNetwork', encodedata(payload.Category.NetworkName))
    bodyFormData.append('Category', encodedata(payload.Category.Category))
    bodyFormData.append('NFTSubCategory', encodedata(payload.Category.NFTSubCategory))
    let respData = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/SoulBoundNFT`,
      'headers': {
        'Content-Type': 'multipart/form-data',
        'Authorization': localStorage.user_token
      },
      'data': bodyFormData,

    });
    console.log("asdasdsadasd", respData);
    respData.data = decodedata(respData.data);
    console.log("asdasdlasdlaskdsadasd", respData);
    return {
      message: respData
    }
  }
  catch (err) {
    return { error: err }
  }
}

export const FinalDataSave = async (payload) => {
  console.log("qweqweqw", payload);
  try {
    let bodyFormData = new FormData();
    bodyFormData.append('Address', encodedata(payload.SoulBoundIPFS.Address));
    bodyFormData.append('type', encodedata(payload.SoulBoundIPFS.type));
    bodyFormData.append('ContractAddress', encodedata(payload.SoulBoundIPFS.ContractAddress))
    bodyFormData.append('Category', encodedata(payload.SoulBoundIPFS.Category))
    bodyFormData.append('NFTSubCategory', encodedata(payload.SoulBoundIPFS.NFTSubCategory))
    bodyFormData.append('CollectionName', encodedata(payload.SoulBoundIPFS.CollectionName))
    bodyFormData.append('ProfileImg', encodedata(payload.SoulBoundIPFS.ProfileImg))
    bodyFormData.append('CoverImg', encodedata(payload.SoulBoundIPFS.CoverImg))
    bodyFormData.append('dbdatafinal', encodedata(JSON.stringify(payload.dbdatafinal)))
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/SoulBoundDB`,
      data: bodyFormData,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    resp.data = decodedata(resp.data);
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Errorinv1_BurnTokenFunction", err)
  }
}

export const ListingSoulBound = async (data) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/SoulBoundCollectionGet`,
      params: data
    });
    return {
      data: resp
    }
  }
  catch (err) {
  }

}

export const ListingGetSoulbound = async (Arg) => {
  console.log("ARGARGGGg", Arg);
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/ListingGetSoulbound`,
      params: Arg
    });
    return {
      data: resp
    }
  }
  catch (err) {
  }

}

export const UserGetSoul = async (Arg) => {
  console.log("ARGARGGGg", Arg);
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/UserGetSoul`,
      params: Arg
    });
    return {
      data: resp
    }
  }
  catch (err) {
  }

}

export const CreateSoul = async (Arg) => {
  console.log("ARGARGGGg", Arg);
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/CreateSoul`,
      params: Arg
    });
    return {
      data: resp
    }
  }
  catch (err) {
  }

}

export const getSoulBoundWhiteList = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.vUrl}/token/getSoulWhite`,
      params: data
    });
    return respData.data
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const MyItemSoulBound = async (Arg) => {
  console.log("ARGARGGGg", Arg);
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/MyItemSoulBound`,
      params: Arg
    });
    return {
      data: resp
    }
  }
  catch (err) {
  }

}

export const MyItemSoulBoundOwn = async (Arg) => {
  console.log("ARGARGGGg", Arg);
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/MyItemSoulBoundOwn`,
      params: Arg
    });
    return {
      data: resp
    }
  }
  catch (err) {
  }

}

export const TicketssGett = async () => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/TicketGet`,
    });
    return {
      data: resp
    }
  }
  catch (err) {
  }

}

export const SoulBoundNFTSecond = async (payload) => {
  try {
    payload = { encode: encodedata(payload) };
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/IPFSCALLL`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    resp.data = decodedata(resp.data);
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Errorinv1_BurnTokenFunction", err)
  }
}

export const UserGetSoulINFO = async (Arg) => {
  console.log("Adag", Arg);
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/UserGetSoulINFO`,
      params: Arg
    });
    return {
      data: resp
    }
  }
  catch (err) {
  }

}

export const PackGet = async (Arg) => {
  // console.log("PackGet",Arg);
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/GetPack`,
      params: Arg
    });
    return {
      data: resp
    }
  }
  catch (err) {
  }

}

export const PackPurchase = async (payload) => {
  console.log("payloadPackPurchase", payload);
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/BuyAPack`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Errorinv1_BurnTokenFunction", err)
  }
}

export const TicketScan = async (payload) => {
  console.log("payloadPackPurchase", payload);
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/TicketScanStatus`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Errorinv1_BurnTokenFunction", err)
  }
}

export const UserPackCheck = async (Arg) => {
  console.log("UserPackCheck", Arg);
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/UserPackCheck`,
      params: Arg
    });
    return {
      data: resp
    }
  }
  catch (err) {
  }

}

export const UserpanelSub = async (Arg) => {
  console.log("UserPackCheck", Arg);
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/UserpanelSub`,
      params: Arg
    });
    return {
      data: resp
    }
  }
  catch (err) {
  }

}

export const getAllCounts = async (Arg) => {
  console.log("UserPackCheck", Arg);
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/getAllCounts`,
      params: Arg
    });
    return {
      data: resp
    }
  }
  catch (err) {
  }

}

export const chartdataUserPannel = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/chartdataUserPannel`,
      params: payload
    });
    return {
      data: resp
    }
  }
  catch (err) {
  }
}

export const UserActivityDashBoard = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/UserActivityDashBoard`,
      params: payload
    });
    return {
      data: resp
    }
  }
  catch (err) {
  }
}

export const RouterChecker = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/RouterChecker`,
      params: payload
    });
    return {
      data: resp
    }
  } catch (e) {
    console.log("errrrr", e);
  }
}

export const FreePackSubScribe = async (payload) => {
  console.log("FreePackSubScribe", payload);
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/FreePackSubScribe`,
      params: payload
    });
    return {
      data: resp
    }
  } catch (e) {
    console.log("errrrr", e);
  }
}

export const SubcribedUserOrNot = async (payload) => {
  console.log("FreePackSubScribe", payload);
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/SubcribedUserOrNot`,
      params: payload
    });
    return {
      data: resp
    }
  } catch (e) {
    console.log("errrrr", e);
  }
}

export const GetJwtToken = async (payload) => {
  // console.log("GetJwtToken",payload);
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/JwtSign`,
      params: payload
    });
    return {
      data: resp
    }
  } catch (e) {
    console.log("errrrr", e);
  }
}

export const scannedtokenlist = async (payload) => {
  console.log("fsdjhf", payload)
  try {
    let resp = await axios({
      'method': 'POST',
      'url': `${config.vUrl}/token/scannedtokenlist`,
      data: payload
    });
    console.log(resp.data, "fhsjhd")
    return resp.data.Scannedtokenlist
  }
  catch (e) {
    console.log("errrrr", e);
  }
}

export const ScannedStatus = async (TokenAdd) => {
  console.log("ScannedStatus", TokenAdd);
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/ScannedStatus`,
      data: TokenAdd
    });
    console.log(resp.data, "fhsjhd")
    return resp
  }
  catch (e) {
    console.log("errrrr", e);
  }
}

export const AuthenticatedTokenlist = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/AuthenticatedTokenlist`,
      params: payload
    });
    console.log(resp.data, "fhsjhfghd")
    return resp.data.Authenticated
  }
  catch (e) {
    console.log("errrrr", e);
  }
}

export const RejecctedToken = async (payload) => {
  try {
    let resp = await axios({
      'method': 'GET',
      'url': `${config.vUrl}/token/RejectedTokenList`,
      params: payload
    });
    console.log(resp.data, "fhsjhfghd")
    return resp.data.Rejected
  }
  catch (e) {
    console.log("errrrr", e);
  }
}

export const progressbarpercent = async (Address) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/progressbar`,
      data: Address
    });
    // console.log(resp.data,"progress")
    return resp.data
  }
  catch (e) {
    console.log("errrrr", e);
  }
}

export const RejectedStatusChange = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/RejectedStatus`,
      data: payload
    });
    console.log(resp.data, "Rejectedtokenstatus")
    return resp.data
  }
  catch (e) {
    console.log("errrrr", e);
  }
}

export const TokenReendem = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/TokenReendem`,
      params: payload
    });
    console.log(resp.data, "fhsjhfghd")
    return resp.data.Rejected
  }
  catch (e) {
    console.log("errrrr", e);
  }
}

export const TotalTicketsSold = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/TotalTicketsSold`,
      params: payload
    });
    console.log(resp.data, "fhsjhfghd")
    return resp.data.Rejected
  }
  catch (e) {
    console.log("errrrr", e);
  }
}

export const BulkImgGen_Db = async (payload) => {
  console.log("qweqweqw", payload);
  try {
    let bodyFormData = new FormData();
    bodyFormData.append('Address', encodedata(payload.Curraddressurr));
    bodyFormData.append('type', encodedata(payload.Category.type));
    bodyFormData.append('ContractAddress', encodedata(payload.Category.contractaddress))
    bodyFormData.append('CollectionName', encodedata(payload.Category.CollectionName))
    bodyFormData.append('dbdatafinal', encodedata(JSON.stringify(payload.SoulBoundIPFS)))
    bodyFormData.append('Coinname', encodedata(payload.Category.Coinname));
    bodyFormData.append('Category', encodedata(payload.Category.NFTCategory));
    bodyFormData.append('NFTSubCategory', encodedata(payload.Category.NFTSubCategory));
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/BulkImgGen_Db`,
      data: bodyFormData,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    resp.data = decodedata(resp.data);
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Errorinv1_BurnTokenFunction", err)
  }
}

export const BulkImgGen_Db_IPFS = async (payload) => {
  console.log("qweadfaasqweqw", payload);
  try {
    let bodyFormData = new FormData();
    bodyFormData.append('Address', encodedata(payload.Curraddressurr));
    bodyFormData.append('type', encodedata(payload.Category.type));
    bodyFormData.append('ContractAddress', encodedata(payload.Category.contractaddress))
    bodyFormData.append('CollectionName', encodedata(payload.Category.CollectionName))
    bodyFormData.append('dbdatafinal', encodedata(JSON.stringify(payload.SoulBoundIPFS)))
    bodyFormData.append('Coinname', encodedata(payload.Category.Coinname));
    bodyFormData.append('Category', encodedata(payload.Category.NFTCategory));
    bodyFormData.append('NetworkName',encodedata(payload.Category.NetworkName))
    bodyFormData.append('NFTSubCategory', encodedata(payload.Category.NFTSubCategory));
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/BulkImgGen_Db_IPFS`,
      data: bodyFormData,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    resp.data = decodedata(resp.data);
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Errorinv1_BurnTokenFunction", err)
  }
}

export const DomainNameValidate = async (NFTname, Code, data) => {
  console.log("svnfkdmvlkdmfsaa", NFTname, Code, data);
  try {
    var respdata = await axios({
      method: "get",
      url: `${config.vUrl}/token/validation/DomainNameValidate/${NFTname}/${Code}/${data}`
    })
    return respdata.data
  }
  catch (e) {
    console.log("Error in DomainNameValidate Function", e)
    return respdata.data
  }
}

export const TittleValidate = async (NFTname, Code, data) => {
  console.log("svnfsdfasdkdmvlkdmfsaa", NFTname, Code, data);
  try {
    var respdata = await axios({
      method: "get",
      url: `${config.vUrl}/token/validation/TittleValidate/${NFTname}/${Code}/${data}`
    })
    return respdata.data
  }
  catch (e) {
    console.log("Error in DomainNameValidate Function", e)
    return respdata.data
  }
}

export const EventSave = async (payload) => {
  console.log("EventSave", payload);
  try {
    let bodyFormData = new FormData();
    bodyFormData.append('curraddress', payload.curraddress);
    bodyFormData.append('backgroundcolor', payload.backgroundcolor);
    bodyFormData.append('bannerImage', payload.bannerImage);
    bodyFormData.append('bannerTittle', payload.bannerTittle);
    bodyFormData.append('bannerdiscription', payload.bannerdiscription);
    bodyFormData.append('campaigncode', payload.campaigncode);
    bodyFormData.append('campaignname', payload.campaignname);
    bodyFormData.append('categoryTittle', payload.categoryTittle);
    bodyFormData.append('companylogo', payload.companylogo);
    bodyFormData.append('country', payload.country);
    bodyFormData.append('domainname', payload.domainname);
    bodyFormData.append('middlebannerdescription', payload.middlebannerdescription);
    bodyFormData.append('middlebannerdescriptionTittle', payload.middlebannerdescriptionTittle);
    bodyFormData.append('TopWinnerTittle', payload.TopWinnerTittle);
    bodyFormData.append('middlebannerdetails', payload.middlebannerdetails);
    bodyFormData.append('EventDeatils', payload.EventDeatils)
    bodyFormData.append('middlebannerimage', payload.middlebannerimage);
    bodyFormData.append('middlebannertittle', payload.middlebannertittle);
    bodyFormData.append('redirect', payload.redirect);
    bodyFormData.append('signemail', payload.signemail);
    bodyFormData.append('template', payload.template);
    bodyFormData.append('mysteryplace', payload.mysteryplace);
    bodyFormData.append('NoOfTreasure', payload.NoOfTreasure);
    bodyFormData.append('mysteryNft', payload.mysteryNft);
    bodyFormData.append('Publish', payload.Publish);
    bodyFormData.append('CreateStatus', payload.CreateStatus);
    bodyFormData.append('NoOfNfts', JSON.stringify(payload.NoOfNfts));
    bodyFormData.append('ChoosenMysterynft', JSON.stringify(payload.ChoosenMysterynft));
    bodyFormData.append('Choosennft', JSON.stringify(payload.Choosennft));
    bodyFormData.append('DupilcateFilter', JSON.stringify(payload.DupilcateFilter));
    bodyFormData.append('PDUPoints', payload.PDUPoints);

    bodyFormData.append("NameField", payload.NameField);
    bodyFormData.append("NameFieldStatus", payload.NameFieldStatus);
    bodyFormData.append("DobField", payload.DobField);
    bodyFormData.append("DobFieldStatus", payload.DobFieldStatus);
    bodyFormData.append("Weight", payload.Weight);
    bodyFormData.append("WeightStatus", payload.WeightStatus);
    bodyFormData.append("Height", payload.Height);
    bodyFormData.append("HeightStatus", payload.HeightStatus);
    bodyFormData.append("VaccineField", payload.VaccineField);
    bodyFormData.append("VaccineStatus", payload.VaccineStatus);
    bodyFormData.append("Mail", payload.Mail);
    bodyFormData.append("MailStatus", payload.MailStatus);
    bodyFormData.append("Discription", payload.Discription);
    bodyFormData.append("DiscriptionStatus", payload.DiscriptionStatus);
    bodyFormData.append("GenderDropDown", payload.GenderDropDown);
    bodyFormData.append("GenderStatus", payload.GenderStatus);
    bodyFormData.append("BreadDropDown", payload.BreadDropDown);
    bodyFormData.append("BreadStatus", payload.BreadStatus);
    bodyFormData.append("Chip", payload.Chip);
    bodyFormData.append("ChipStatus", payload.ChipStatus);
    bodyFormData.append("ContactNum", payload.ContactNum);
    bodyFormData.append("ContactNumStatus", payload.ContactNumStatus);
    bodyFormData.append("FileField", payload.FileField);
    bodyFormData.append("FileStatus", payload.FileStatus);
    bodyFormData.append("FileDiscriptionField", payload.FileDiscriptionField);
    bodyFormData.append("FileDiscriptionStatus", payload.FileDiscriptionStatus);
    bodyFormData.append("CheckBox1", payload.CheckBox1);
    bodyFormData.append("CheckBox1Status", payload.CheckBox1Status);
    bodyFormData.append("CheckBox2", payload.CheckBox2);
    bodyFormData.append("CheckBox2Status", payload.CheckBox2Status);
    bodyFormData.append("TemplateDesignName", payload.TemplateDesignName)
    bodyFormData.append("Dropdown1Category", JSON.stringify(payload.Dropdown1Category))
    bodyFormData.append("Dropdown2Category", JSON.stringify(payload.Dropdown2Category))
    bodyFormData.append("Dropdown3Category", JSON.stringify(payload.Dropdown3Category))
    bodyFormData.append("Option", Option)
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/CampaignAdd`,
      data: bodyFormData,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Errorinv1_BurnTokenFunction", err)
  }
}

export const CampaignImageUpload = async (payload) => {
  console.log("EventSave", payload);
  try {
    let bodyFormData = new FormData();
    bodyFormData.append('curraddress', payload.curraddress);
    bodyFormData.append('Imagename', payload.Imagename);
    bodyFormData.append("Dropdown1Category", payload.Dropdown1Category)
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/CampaignImageUpload`,
      data: bodyFormData,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Errorinv1_BurnTokenFunction", err)
  }
}

export const v1_EventListNft = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/ListNftForEvent/NFT`,
      params: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("ErrorinMyitemsDataFunction", err)
  }
}

export const Eventpublish = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/Eventpublish`,
      params: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("ErrorinMyitemsDataFunction", err)
  }
}

export const ExistingEvent = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/ExistingEvent`,
      params: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("ErrorinMyitemsDataFunction", err)
  }
}

export const CheckNfts = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/CheckNfts`,
      params: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("ErrorinMyitemsDataFunction", err)
  }
}

export const DeleteEvent = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/DeleteEvent`,
      params: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("ErrorinMyitemsDataFunction", err)
  }
}

export const GetEventsData = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/GetEventsData`,
      params: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("ErrorinMyitemsDataFunction", err)
  }
}

export const GetDomainsAndDetails = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/GetDomainsAndDetails`,
      params: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("ErrorinMyitemsDataFunction", err)
  }
}

export const AlreadyClaimNft = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/AlreadyClaimNft`,
      params: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("AlreadyClaimNft", err)
  }
}

export const TopWinners = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/TopWinners`,
      params: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("AlreadyClaimNft", err)
  }
}

export const NftsUnloacked = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/NftsUnloacked`,
      params: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("AlreadyClaimNft", err)
  }
}

export const CampaignTopBox = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/CampaignTopBox`,
      params: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("CampaignTopBox", err)
  }
}

export const CampaignUsers = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/CampaignUsers`,
      params: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("CampaignTopBox", err)
  }
}

export const VaultApi = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/VaultApi`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Erorr in Vault status", err)
  }
}

export const GrantUserSendMail = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/GrantUserSendMail`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Erorr in Vault status", err)
  }
}

export const SendMailNftsVault = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/SendMailNftsVault`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Erorr in Vault status", err)
  }
}

export const GetFiatRevenueData = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/GetFiatRevenueData`,
      params: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("CampaignTopBox", err)
  }
}

export const ClaimRequestFiat = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/ClaimRequestFiat`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Erorr in ClaimRequestFiat", err)
  }
}

export const LMContactCalls = async (payload) => {
  try {
    payload = { encode: encodedata(payload) };
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/LMwallet/ContractFunctions`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    resp.data = decodedata(resp.data);
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Erorr in ClaimRequestFiat", err)
  }
}

export const LMSignCall = async (payload) => {
  try {
    payload = { encode: encodedata(payload) };
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/LMwallet/LMSignCall`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    resp.data = decodedata(resp.data);
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Erorr in ClaimRequestFiat", err)
  }
}

export const ContactMint = async (payload) => {
  try {
    payload = { encode: encodedata(payload) };
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/LMwallet/ContactMint`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    resp.data = decodedata(resp.data);
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Erorr in ClaimRequestFiat", err)
  }
}

export const ContactMintFraction = async (payload) => {
  try {
    payload = { encode: encodedata(payload) };
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/LMwallet/ContactMintFraction`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    resp.data = decodedata(resp.data);
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Erorr in ClaimRequestFiat", err)
  }
}

export const LMBalanceOfFraction = async (payload) => {
  try {
    payload = { encode: encodedata(payload) };
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/LMwallet/LMBalanceOfFraction`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    resp.data = decodedata(resp.data);
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Erorr in ClaimRequestFiat", err)
  }
}

export const LMTokenTrasBack20 = async (payload) => {
  try {
    payload = { encode: encodedata(payload) };
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/LMwallet/LMTokenTrasBack20`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    resp.data = decodedata(resp.data);
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Erorr in ClaimRequestFiat", err)
  }
}

export const LMBalanceOf = async (payload) => {
  try {
    payload = { encode: encodedata(payload) };
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/LMwallet/LMBalanceOf`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    resp.data = decodedata(resp.data);
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("Erorr in LMBalanceOf", err)
  }
}

export const LMApproveCheck = async (payload) => {
  try {
    payload = { encode: encodedata(payload) };
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/LMwallet/LMApproveCheck`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    resp.data = decodedata(resp.data);
    return {

      data: resp.data
    }
  }
  catch (err) {
    console.log("Erorr in LMApproveCheck", err)
  }
}

export const SendCryptoLM = async (payload) => {
  try {
    payload = { encode: encodedata(payload) };
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/LMwallet/transferCrypto`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    resp.data = decodedata(resp.data);
    return {

      data: resp.data
    }
  }
  catch (err) {
    console.log("Erorr in LMApproveCheck", err)
  }
}

export const ImportTokenLM = async (payload) => {
  try {
    payload = { encode: encodedata(payload) };
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/LMwallet/ImportTokenLM`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    resp.data = decodedata(resp.data);
    return {

      data: resp.data
    }
  }
  catch (err) {
    console.log("Erorr in LMApproveCheck", err)
  }
}

export const LMTokenTransferBack = async (payload) => {
  try {
    payload = { encode: encodedata(payload) };
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/LMwallet/LMTokenTransferBack`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    resp.data = decodedata(resp.data);
    return {

      data: resp.data
    }
  }
  catch (err) {
    console.log("Erorr in LMApproveCheck", err)
  }
}

export const LMTokenBurnTokenFrac = async (payload) => {
  try {
    payload = { encode: encodedata(payload) };
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/LMwallet/LMTokenBurnTokenFrac`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    resp.data = decodedata(resp.data);
    return {

      data: resp.data
    }
  }
  catch (err) {
    console.log("Erorr in LMApproveCheck", err)
  }
}


export const GetTokenList = async (payload) => {
  try {
    payload = { encode: encodedata(payload) };
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/LMwallet/TokenFetchLM`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    resp.data = decodedata(resp.data);
    return {

      data: resp.data
    }
  }
  catch (err) {
    console.log("Erorr in LMApproveCheck", err)
  }
}

export const GetTranscationHistory = async (payload) => {
  try {
    payload = { encode: encodedata(payload) };
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/LMwallet/TransactionHistoryLM`,
      data: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    resp.data = decodedata(resp.data);
    console.log("resp.data", resp.data);
    return {

      data: resp.data
    }
  }
  catch (err) {
    console.log("Erorr in LMApproveCheck", err)
  }
}

export const ScannedEventStatus = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/ScannedEventStatus`,
      params: payload,
      'headers': {
        'Authorization': localStorage.user_token
      },
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    console.log("CampaignTopBox", err)
  }
}
