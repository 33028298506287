
import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Button, TextField } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Account_Connect, Account_disConnect } from "actions/redux/action";
import $ from 'jquery';
import Web3Utils from 'web3-utils'
import Select from "react-select";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { PurchaseCalculation, YouwillGetCalculation, CharityAndRoyaltyCalculation } from 'actions/controller/PriceCalculate';
import { useTokenDecimal, usePrevious, useUserTokenBalance, useSignCall, useNFTBalCheck, useBulBalnceCheck } from 'hooks/useMethods';
import { Audio, TailSpin } from 'react-loader-spinner'
import { NightsStay } from '@material-ui/icons';
import { useTokenAppReceipt, useAcceptBidReceipt, useMintAcceptBidReceipt, useAcceptBidTransferReceipt, usePurchaseTicketsBid } from 'hooks/useReceipts';
import { BidAction2DB, UpdateNFTOwners, imageImger, UpdateNFTOwnersBulk } from 'actions/controller/tokenControl';
import { v1_ConNFTBalCheck, LMSignCall, LMApproveCheck, ContactMint, LMContactCalls, LMBalanceOf } from 'actions/v1/token';
import { jsonInterfaceMethodToString } from 'web3-utils';
import config from 'lib/config'
import { toast } from 'react-toastify';
import { PopupValidation } from 'actions/controller/validation';
import { network } from "../../views/network"
import { connectWallet, WalletConnect, getServiceFees } from '../../hooks/useWallet';
import Web3 from 'web3';
import Abi721 from '../../ABI/721.json'
import Abi1155 from '../../ABI/1155.json'

import randomInteger from 'random-int';

export const BidActions = forwardRef((props, ref) => {
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const [NoofToken, setNoofToken] = useState(1)
  const [TokenPrice, setTokenPrice] = useState(0)
  const [NewTokenDecimal, setNewTokenDecimal] = useState('')
  const [BiddingBalance, setBiddingBalance] = useState('')
  const [Prices, setPrices] = useState({ Youwillpay: 0, PriceWithSerfee: "", ApprovePriceWithSerfee: "", PriceWithoutSerfee: "", Display: "" })
  const [NFT, setNFT] = useState({ CurrentOwner: {}, Mybid: {} })
  const [NFTDet, setNFTDet] = useState({})
  const [Bid, setBid] = useState({})
  const [Bidstate, setBidstate] = useState(false)
  const [Signstate, setSignstate] = useState(true)
  const [BidText, setBidText] = useState("Start")
  const [AccBidstate, setAccBidstate] = useState(false)
  const [AccBidText, setAccBidText] = useState("Accept Bid")
  const [ApprovalCallStatus, setApprovalCallStatus] = React.useState('Approve');
  const [approvecheck, setapprovecheck] = useState(false)
  const prevNoofToken = usePrevious(NoofToken)
  const prevTokenPrice = usePrevious(TokenPrice)
  const [SignTxt, SetSignTxt] = useState("Start");
  const [SignLockStatusTxt, setSignLockStatusTxt] = useState("Start");
  const [ApproveCallStatus, setApproveCallStatus] = useState('init');
  const [ApproveTrade, setApproveTrade] = useState('Start');
  const [SignatureHash, setSignatureHash] = useState('');
  const [NonceHash, setNonceHash] = useState(0);
  const [PurchaseCallStatus, setPurchaseCallStatus] = useState('')
  const [RandomName, setRandomName] = useState('')
  const [TokenListsnet, setTokenListsnet ] = useState([])
  const [CusTokenName, setCustomTokenName] = useState("")
  const [CusTokAddress,setCusTokenAddress] = useState("")
  const history = useHistory()
  const dispatch = useDispatch()

  useImperativeHandle(
    ref,
    () => ({
      async PlaceandAcceptBidClick(ClickedNFT, NFT, Bids, status) {
        console.log("asdasdasdasdasd", ClickedNFT);
        var chainid = NFT.SelectedNetwork
        Serfee(Number(chainid));
        var networkConfiguration = {}
        const chainId = await Wallet_Details.Web3Pro.eth.getChainId();
        console.log("fgndfgn", chainid, Wallet_Details.networkConfiguration);
        if (Number(chainid) === network.MATIC.Chainid) {
          console.log("netter eorrrrkkk maticz", network.MATIC);
          networkConfiguration = network.MATIC
        }
        else if (Number(chainid) === network.ETH.Chainid) {
          console.log("netter eorrrrkkk", network.ETH);
          networkConfiguration = network.ETH
        }
        else if (Number(chainid) === network.CAM.Chainid) {
          console.log("netter eorrrrkkk", network.CAM);
          networkConfiguration = network.CAM
        }
        console.log("jdsnbfufnuewrgn", Bids)
        console.log("overall nft , nftdetail,bids", NFT)
        var approvedata = {
          curradd: Wallet_Details.UserAccountAddr,
          tradeadd: networkConfiguration.TradeContract
        }

        Wallet_Details.networkConfiguration = networkConfiguration



        var chainid = NFT.SelectedNetwork
        var accountDetailsss = ''
        setBid(Bids)
        setNFTDet(NFT)
        setNFT(ClickedNFT)
        setNoofToken(NFT.balance);
        var chainid = NFT?.SelectedNetwork
        // console.log("Putonsale wallet connect", chainid, Wallet_Details.networkConfiguration);
        var accountDetailsss = ''
        if (Wallet_Details.networkConfiguration.Chainid == NFT?.SelectedNetwork) {
          // console.log("dknvjksdnkfsnjkfd", Wallet_Details.networkConfiguration.Chainid == NFT?.SelectedNetwork);
          accountDetailsss = true
          setTokenListsnet()
        }
        else {
          // console.log("it comming in else");
          console.log("it comming in else switch", networkConfiguration);
          console.log("sdfsfdfsdfsdfs", Number(chainid), network.MATIC.Chainid);
          if (Number(chainid) === network.MATIC.Chainid) {
            console.log("netter eorrrrkkk maticz", network.MATIC);
            networkConfiguration = network.MATIC
          }
          else if (Number(chainid) === network.ETH.Chainid) {
            console.log("netter eorrrrkkk", network.ETH);
            networkConfiguration = network.ETH
          }
          else if (Number(chainid) === network.CAM.Chainid) {
            console.log("netter eorrrrkkk", network.CAM);
            networkConfiguration = network.CAM
          }

          console.log("qeweqweqweasdas", chainId, networkConfiguration.chainid);
          if (Number(chainid) !== Number(chainId)) {
            var accountDetailsss = await switchNetwork(chainid, networkConfiguration)
          }
        }

        // console.log("sgfsdfgdsgdfg", hookTokBalDecimal);

        // console.log("no of tokenstate", NoofToken, NFT.balance)
        if (chainId == NFT.SelectedNetwork) {
          accountDetailsss = true
          let FilterCurrTokList = Wallet_Details.TokenLists.filter((item)=> String(item.Network) == String(NFT.SelectedNetwork))
          setTokenListsnet(FilterCurrTokList)
        }
        else {
          // console.log("zsdbgdzfhbdf", NFT.SelectedNetwork);
          if (NFT?.SelectedNetwork) {
            chainid = NFT.SelectedNetwork
          }
          // console.log("sbhgsdrbhg", chainid);
          var accountDetailsss = await switchNetwork(chainid)
        }
        if (Number(chainid) === Number(chainId)) {
          var hookTokBalDecimal = await HookbalCheck(Wallet_Details.networkConfiguration.tokenSymbol, Wallet_Details, chainid)
          if (Wallet_Details.UserAccountAddr) {
            var check = await orderApprovecheck(approvedata, NFT);
            console.log("dnfksdjnfkjsdnfk", check);
            setapprovecheck(check)
            if (status == 'accept') {
              window.$('#accept_modal').modal('show');
            }
            else {
              window.$('#place_bid_multiple_modal').modal('show');
            }


            var data = { Price: TokenPrice, Quantity: NFT.balance, Serfee: Wallet_Details.Service_Fee, Decimal: 18 }
            // console.log("data condole,data", data)
            var Data = PurchaseCalculation(data)
            // console.log("DAtaaaaaaaaa ", Bids)
            if (Bids) {
              // console.log("sfdsfjslfklsflks")
              var Data = YouwillGetCalculation({ Price: Bids.tokenBidAmt, Serfee: Wallet_Details.Service_Fee, Quantity: (Bids.pending > ClickedNFT.CurrentOwner.balance) ? ClickedNFT.CurrentOwner.balance : Bids.pending })
              console.log("bid data", Data)
            }
            setPrices(Data)
            // console.log("jdsnbfufnuewrgn", ClickedNFT, NFT, Data, data, hookTokBalDecimal, hookTokBalDecimal)
          }
        }
      }
    }))

  const Serfee = async (Address) => {
    console.log("Asdasdasdasdas", Address);
    var Fee = await getServiceFees(Address)
    // console.log("ndkdsnfkdsn", Fee);
    dispatch({
      type: Account_Connect,
      Account_Detail: {
        Service_Fee: Fee?.Serfee,
        Service_Fee_Fraction:Fee?.SerfeeFraction
      }
    })
  }

  async function switchNetwork(configdata) {
    var type = ""
    var networkConfiguration = {}
    const chainId = await Wallet_Details.Web3Pro.eth.getChainId();
    let TokenLists = await v1_GetCustomToken()
    console.log("adsadasdasd", configdata, chainId, networkConfiguration);
    // console.log("sdnfvnfkvnfs", configdata);
    if (localStorage.walletConnectType !== "Torus") {
      if (Number(configdata) !== Number(chainId)) {
        const id = toast.loading("Switching Network", { closeButton: true, theme: "dark" });
        if (configdata) {
          if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask') {

            type = "MetaMask"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'WalletConnect' && localStorage.walletConnectType != null) {
            type = "WalletConnect"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'Venly' && localStorage.walletConnectType != null) {
            type = "Venly"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'Torus' && localStorage.walletConnectType != null) {
            type = "Torus"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'TorusWallet' && localStorage.walletConnectType != null) {
            type = "TorusWallet"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'LMWallet' && localStorage.walletConnectType != null) {
            console.log("adasd comming on torus");
            type = "LMWallet"
            localStorage.setItem("ChainId", NFTDet.SelectedNetwork)
          }
          // console.log("connecttype............", type, configdata)
          var accountDetails = await connectWallet(type, Number(configdata), localStorage.walletConnectType == "LMWallet" ? "register" : "Create", Wallet_Details, "Tor")
          // console.log("accountDetailsin create page......................", accountDetails)

          // var web3 = new Web3(window.ethereum);
          // console.log("dfghrtfh", web3);
          // if (window.ethereum.isMetaMask == true) {

          const chainId = await accountDetails?.web3?.eth?.getChainId();
          // console.log("fghdtgj", chainId);

          if (Number(configdata) === network.MATIC.Chainid) {
            networkConfiguration = network.MATIC
          }
          else if (Number(configdata) === network.ETH.Chainid) {
            networkConfiguration = network.ETH
          }
          else if (Number(configdata) === network.CAM.Chainid) {
            networkConfiguration = network.CAM
          }


          // }
          // setPriceoption()
          // console.log("dsnsdkngksfnvkfdn", networkConfiguration);
          if (accountDetails != '') {
            // console.log("Wallet Connecting...increate", accountDetails?.web3?._provider);
            dispatch({
              type: Account_Connect,
              Account_Detail: {
                UserAccountAddr: accountDetails.accountAddress,
                UserAccountBal: accountDetails.coinBalance,
                WalletConnected: "true",
                Wen_Bln: accountDetails.tokenBalance,
                Accounts: accountDetails.accountAddress,
                Web3Pro: accountDetails?.web3,
                providerss: accountDetails?.web3?._provider,
                networkConfiguration: networkConfiguration,
                TokenLists: TokenLists,data
              }
            })
            Serfee(networkConfiguration.Chainid);
          }
          if (networkConfiguration.currencySymbol) {
            toast.update(id, {
              render: "Network switched Succesfully",
              type: "success",
              isLoading: false,
              autoClose: 2500,
              closeButton: true,
              theme: "dark"
            });
          }
        }



        //   var ReqData = {
        //     addr: String(accountDetails.accountAddress).toLowerCase()
        // }
        // var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
        // if (Resp && Resp.data && Resp.data.data && Resp.data.data.User) {
        //     return Resp.data.data.User
        // } else {
        //     return null
        // }





        return accountDetails
      } else {
        dispatch({
          type: Account_Connect,
          Account_Detail: {
            UserAccountAddr: Wallet_Details?.UserAccountAddr,
            UserAccountBal: Wallet_Details?.UserAccountBal,
            WalletConnected: "true",

            Wen_Bln: Wallet_Details?.Wen_Bln,
            Accounts: Wallet_Details?.Accounts,

            Web3Pro: Wallet_Details?.Web3Pro,
            providerss: Wallet_Details?.providerss,
            networkConfiguration: networkConfiguration,
            TokenLists: TokenLists,data
          }
        })
        Serfee(networkConfiguration.Chainid);
        return Wallet_Details.Web3Pro
      }
    } else {
      try {
        const id = toast.loading("Switching Network", { closeButton: true, theme: "dark" });
        var accountDetails = await connectWallet(localStorage.walletConnectType, Number(configdata), "Create", Wallet_Details, "Torus")
        // if (Number(networkNUM) == 97) {
        //   console.log("Comming on last else for switchCahin", Wallet_Details.web3auth, Wallet_Details?.web3);
        //   var addingChain = await Wallet_Details.web3auth.addChain({
        //     chainId: "0x61",
        //     displayName: "binance",
        //     chainNamespace: CHAIN_NAMESPACES.EIP155,
        //     tickerName: "BNB Smart Chain Testnet",
        //     ticker: "BNB",
        //     decimals: 18,
        //     rpcTarget: "https://data-seed-prebsc-1-s1.binance.org:8545/",
        //     blockExplorer: "https://testnet.bscscan.com/",
        //   });
        //   console.log("addingChain Web3Auth", addingChain);
        //   var switched = await Wallet_Details.web3auth.switchChain({ chainId: "0x61" });
        //   console.log("switched in create page uh", switched);
        // }else{
        //   console.log("Comming on last else for switchCahin", Wallet_Details.web3auth, Wallet_Details?.web3);
        //   var addingChain = await Wallet_Details.web3auth.addChain({
        //     chainId: "0x13881",
        //     displayName: "Mumbai",
        //     chainNamespace: CHAIN_NAMESPACES.EIP155,
        //     tickerName: "Mumbai",
        //     ticker: "MATIC",
        //     decimals: 18,
        //     rpcTarget: "https://matic-mumbai.chainstacklabs.com",
        //     blockExplorer: "https://mumbai.polygonscan.com/",
        //   });
        //   console.log("addingChain Web3Auth", addingChain);
        //   var switched = await Wallet_Details.web3auth.switchChain({ chainId: "0x13881" });
        //   console.log("switched in create page uh", switched);
        // }
        console.log("asdasdasdasdas", accountDetails);
        const chainId = await accountDetails?.web3?.eth?.getChainId();
        console.log("fghdtgj", configdata, network, chainId);

        if (Number(configdata) === network.MATIC.Chainid) {
          networkConfiguration = network.MATIC
        }
        else if (Number(configdata) === network.ETH.Chainid) {
          networkConfiguration = network.ETH
        }
        else if (Number(configdata) === network.CAM.Chainid) {
          networkConfiguration = network.CAM
        }
        console.log("huiyooijmoijolmj", networkConfiguration);
        if (accountDetails != '') {
          dispatch({
            type: Account_Connect,
            Account_Detail: {
              UserAccountAddr: accountDetails?.accountAddress,
              UserAccountBal: accountDetails?.coinBalance,
              WalletConnected: "true",

              Wen_Bln: accountDetails?.tokenBalance,
              Accounts: accountDetails?.accountAddress,

              Web3Pro: accountDetails?.web3,
              providerss: accountDetails?.web3?._provider,
              networkConfiguration: networkConfiguration,
              TokenLists: TokenLists,data
            }
          })
        }
        var functiobn = await NefunctionTriger(Wallet_Details)
        console.log("Asdasdaeqweqwe", networkConfiguration.currencySymbol);
        if (networkConfiguration.currencySymbol) {
          toast.update(id, {
            render: "Network switched Succesfully",
            type: "success",
            isLoading: false,
            autoClose: 2500,
            closeButton: true,
            theme: "dark"
          });
        }
      } catch (e) {
        console.log("adqeqweqweqweqweqweqwe", e);
      }
    }
  }

  const NefunctionTriger = async (Wallet_Details1) => {
    console.log("Wallet_Details1", Wallet_Details1);
    const chainIdSwitched = await Wallet_Details1?.Web3Pro?.eth?.getChainId();
    var balance = await Wallet_Details1?.Web3Pro?.eth.getBalance(Wallet_Details1.UserAccountAddr);
    console.log("chainIdSwitched", chainIdSwitched, balance);
    return chainIdSwitched
  }

  const HookbalCheck = async (data, walletdetail) => {
    // console.log("vfdkjnvfdvmklfdj", data, Wallet_Details,);
    // var TokenDecimal = await useTokenDecimal({ Coin: data }, 721, Wallet_Details)
    var BiddingBal = await useUserTokenBalance({ Coin: data }, 721, Wallet_Details)
    console.log("token name ,biddiin balance", BiddingBal)
    const tokdecimaldata = await GetDecimal({ Coin: data }, 721, Wallet_Details)
    console.log("tokdecimaldata",tokdecimaldata);
    setNewTokenDecimal(tokdecimaldata)
    setBiddingBalance(BiddingBal)
    setCustomTokenName(data)
  }

  const GetDecimal = async (data,wall,datass) =>{
    const Decimalss = await useTokenDecimal(data,wall,datass)
    return Decimalss
  }
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "#fff",
      position: "relative",
      top: "3px",
      left: "0px",
      width: "100%",
      maxWidth: "100%",
      color: "#000"
    }),
    option: (provided, state) => ({
      ...provided,
      marginBottom: "5px",
      backgroundColor: "transparent",
      maxWidth: "356px",
      color: "#fff",
      borderBottom: "#fff",
      "&:hover": {
        transition: "all 0.5s ease",
        color: "#e50914"
      },
      "&:active": {
        transition: "all 0.5s ease",
        color: "#000"
      }
    }),
    control: (provided, state) => ({
      ...provided,
      border: "1px solid transparent",
      boxShadow: "0px 0px",
      "&:hover": {
        border: "1px solid transparent",
        boxShadow: "0px 0px",
      },
      "&:focus": {
        border: "1px solid transparent"
      }
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      border: "1px solid transparent"
    })
  }
  const ValidCheck = () => {
    var ValChk = { TokenPrice: TokenPrice, NoofToken: NoofToken, type: NFTDet.type, MaxToken: NFTDet.tokenQuantity, NFT: NFTDet }
    var Valid = PopupValidation(ValChk)
    if (Valid.Success) {
      var Check = (BiddingBalance / 10 ** NewTokenDecimal < Prices.PriceWithSerfee / 10 ** NewTokenDecimal)
      if(!CusTokenName){
      return  toast.error("Choose Bidding Token", { theme: "dark" })
      }
      if(!CusTokAddress){
       return toast.error("Choose Bidding Token", { theme: "dark" })
      }
      if (Check) {
      return  toast.error("Insufficient Bidding Balance", { theme: "dark" })
      }
      else {
        $('#hiddenbutton').trigger("click");
      }
    }
    else {
     return toast.error(Valid.Message, { theme: "dark" })
    }
  }

  // newly added for lazyminter

  const Bidcallfunction = async () => {
    console.log("sdfsdfksdvksv", NFTDet, NFT);
    if (NFT.CurrentOwner.hashValue == "not yet minted" && NFT.CurrentOwner.LazyStatus == "pending") {
      console.log("sdfsdfsdjfsdsdfds");
      BidCall();
    }
    else if (NFT.CurrentOwner.hashValue !== "not yet minted" && NFT.CurrentOwner.LazyStatus == "pending") {
      console.log("dfdsknfdsnfksjdnvksf");
      BidCall();
    }
    else if (NFT.CurrentOwner.hashValue !== "not yet minted" && NFT.CurrentOwner.LazyStatus == "completed") {
      console.log("dcfsdnvkjnsfjkv");
      BalCheck();
    }

  }

  const BalCheck = async () => {
    // alert("notml placebid")
    var Arg = {
      NFTConAddress: NFTDet.contractAddress,
      NFTId: NFTDet.tokenID,
      NFTOwner: NFT.CurrentOwner.tokenOwner,
      Status: NFT.CurrentOwner.LazyStatus
    }
    var Type = NFTDet.type
    console.log("fjbdshfbsfhsr", Arg, Type)
    var Provider = Wallet_Details
    var ContractNFTBal = await useNFTBalCheck(Arg, Type, Provider)
    // console.log("sldnsdjnkjsd", ContractNFTBal);
    if (Number(ContractNFTBal) !== NFT.CurrentOwner.balance) {
      Arg.balance = Number(ContractNFTBal)
      var Updated = await v1_ConNFTBalCheck(Arg)
      // console.log("fjbdshfbsfhsr", Updated)
      if (Updated.data && Updated.data.Success) {
        toast.error("Owners NFT Balance Changed. Try Later", { autoClose: 4000, closeButton: true })
        $('#bidpro_closs').trigger("click");
        setTimeout(() => {
          history.push('/explore/All')
        }, 1500);
      }
      else {
        toast.error("Error Occured. Try Later", { autoClose: 3000, closeButton: true })
        $('#bidpro_closs').trigger("click");
        setTimeout(() => {
          history.push('/explore/All')
        }, 1500);
      }
    }
    else {
      BidCall()
    }
  }

  const NFTHookFun = async (Arg, Type, Wallet_Details) => {
    var ContractNFTBal = await useNFTBalCheck(Arg, Type, Wallet_Details)
    return ContractNFTBal
  }

  const NFTHookFun1 = async (Arg, Type, Wallet_Details) => {
    var ContractNFTBal = await useBulBalnceCheck(Arg, Type, Wallet_Details)
    console.log("asdasddasdasdas", ContractNFTBal);
    return ContractNFTBal
  }

  const BidCall = async () => {
    // alert("sf")
    setBidText("OnProgress")
    console.log("anckjdnjkcsdnsdms", CusTokenName, NFT);
    var Arg = { Coinname: CusTokenName, NFTOwner: NFT.CurrentOwner.tokenOwner, NFTId: NFT.NFTDetail.tokenID, PriceWithoutSerfee: Prices.PriceWithoutSerfee, PriceWithSerfee: Prices.PriceWithSerfee, Status: NFT.CurrentOwner.LazyStatus }
    // console.log("Arg sfdjlks", Arg)
    var Type = NFTDet.type
    var Provider = Wallet_Details

    if (localStorage.walletConnectType !== "LMWallet") {
      var Receipt = await ContactHookTokenApp(Arg, Type, Provider)
    } else {
      var Key = null
      var Argument = [Wallet_Details.networkConfiguration.TradeContract, Arg.PriceWithSerfee]
      var Receipt = await LMWalletCall(Arg, NFT.type, Wallet_Details, NFTDet, NFT, "Token", Wallet_Details.networkConfiguration.tokenAddr.CAKE, Wallet_Details.networkConfiguration.TradeContract, Argument, "approve", Key, false)
    }
    // console.log("sdc dsb jsmdssdfs", Receipt);
    if (Receipt.status) {
      // console.log("Receipt.transactionHash", Receipt.transactionHash)
      var BidAct = { Status: Receipt.status, Hash: Receipt.transactionHash, ConAddr: NFTDet.contractAddress, Quantity: NoofToken, tokenID: NFTDet.tokenID, Bidder: Wallet_Details.UserAccountAddr, BidAmt: TokenPrice, tokenName: NFTDet.tokenName, coinName: CusTokenName, QR_tokenID: NFT.NFTDetail.QR_tokenID }
      // console.log("data noofteooke", NoofToken)
      var Resp = await BidAction2DB(BidAct)
      setBidstate(true)
      setSignstate(false)
      setBidText("Done")
    } else {
      toast.error("Try it Again Later.Please Check Your Funds")
    }
  }

  const AccBalMintCheck = async () => {
    console.log("asdasdasdasdassaad", NFT);
    if (NFT.CurrentOwner.hashValue == "not yet minted" && NFT.CurrentOwner.LazyStatus == "pending") {
      // console.log("it comming in first");
      if (NFTDet.Bulkmint !== "Bulk") {
        AcceptBidMintCall();
      } else {
        ImageMergeProcess()
      }
    }
    else if (NFT.CurrentOwner.hashValue !== "not yet minted" && NFT.CurrentOwner.LazyStatus == "pending") {
      // console.log("it comming in Second");
      if (NFTDet.Bulkmint !== "Bulk") {
        AccBalCheck();
      } else {
        ImageMergeProcess()
      }
    }
    else if (NFT.CurrentOwner.hashValue !== "not yet minted" && NFT.CurrentOwner.LazyStatus == "completed") {
      // console.log("it comming in Third");
      if (NFTDet.Bulkmint !== "Bulk") {
        AccBalCheck();
      } else {
        ImageMergeProcess()
      }
    }

  }


  const AccBalCheck = async () => {
    // console.log("asdsafasfsad", NFT, NFTDet);
    var Arg = {
      NFTConAddress: NFTDet.contractAddress,
      NFTId: NFTDet.tokenID,
      NFTOwner: Wallet_Details.UserAccountAddr,
      Status: NFT.CurrentOwner.LazyStatus,
      BulK: NFTDet.Bulkmint
    }
    var Type = NFTDet.type
    // var ContractNFTBal = await useNFTBalCheck(Arg, Type, Wallet_Details)
    console.log("sdfwaewqedsadniuj", NFTDet.Bulkmint);
    if (NFTDet.Bulkmint !== "Bulk") {
      if (localStorage.walletConnectType !== "LMWallet") {
        var ContractNFTBal = await NFTHookFun(Arg, Type, Wallet_Details)
      } else {
        var key = null
        var Argument = Type == 721 ? [Arg.NFTId] : [Arg.NFTOwner, Arg.NFTId]
        var ContractNFTBal = await LMBalanceCheck(Arg, Type, Wallet_Details, Arg.NFTConAddress, Type == 721 ? "Single" : "Multiple", Arg.NFTConAddress, Type == 721 ? "ownerOf" : "balanceOf", Argument, key, Arg.NFTOwner)
      }
    } else {
      if (localStorage.walletConnectType !== "LMWallet") {
        var ContractNFTBal = await NFTHookFun1(Arg, Type, Wallet_Details)
      } else {
        var key = null
        var Argument = Type == 721 ? [Arg.NFTId] : [Arg.NFTOwner, Arg.NFTId]
        var ContractNFTBal = await LMBalanceCheck(Arg, Type, Wallet_Details, Arg.NFTConAddress, "Bulk", Arg.NFTConAddress, Type == 721 ? "ownerOf" : "balanceOf", Argument, key, Arg.NFTOwner)
      }
    }
    // console.log("asdafjsdlfjsldjfkls", ContractNFTBal);
    if (Number(ContractNFTBal) !== NFT.CurrentOwner.balance) {
      Arg.balance = Number(ContractNFTBal)
      var Updated = await v1_ConNFTBalCheck(Arg)
      // console.log("fjbdshfbsfhsr", Updated)
      if (Updated.data && Updated.data.Success) {
        toast.error("Owners NFT Balance Changed. Try Later", { autoClose: 4000, closeButton: true })
        $('#Accept_closs').trigger("click");
        setTimeout(() => {
          history.push('/explore/All')
        }, 1500);
      }
      else {
        toast.error("Error Occured. Try Later", { autoClose: 3000, closeButton: true })
        $('#Accept_closs').trigger("click");
        setTimeout(() => {
          history.push('/explore/All')
        }, 1500);
      }
    }
    else {
      AcceptBidCall()
    }
  }

  const AcceptBidCall = async () => {
    // alert("normal accept")
    setAccBidstate(true)
    setAccBidText("OnProgress")
    console.log("qwewqeasdzxcx comming accpect bid", NFT);
    var Arg = { Coinname: Bid.coinname, BidderAddress: Bid.tokenBidAddress, PriceWithoutSerfee: Prices.PriceWithoutSerfee, NFTId: Bid.tokenID, NoofNFTs: (NFT.CurrentOwner.balance > Bid.pending) ? Bid.pending : NFT.CurrentOwner.balance, Status: NFT.CurrentOwner.LazyStatus }
    var Calculation = await CharityAndRoyaltyCalculation(NFTDet, NFT)
    console.log("Calculation", Calculation);
    var Type = NFTDet.type
    var Provider = Wallet_Details
    if (localStorage.walletConnectType !== "LMWallet") {
      var Receipt = await ContactHookAcceptBid(Arg, Type, Provider)
    } else {
      var Key = null
      var Argument = [Arg.Coinname, Arg.BidderAddress, [Arg.NFTId, Arg.PriceWithoutSerfee, Arg.NoofNFTs, Type], Provider.networkConfiguration.multipleContract]
      var Receipt = await LMWalletCall(Arg, NFT.type, Wallet_Details, NFTDet, NFT, "Trade", Wallet_Details.networkConfiguration.TradeContract, Wallet_Details.networkConfiguration.TradeContract, Argument, "acceptBId", Key, false)
    }

    // console.log("andakncfkjdsnckdn", Receipt);

    if (Receipt.status) {
      var NewOwnerDetail = { Status: Receipt.status, Hash: Receipt.transactionHash, ConAddr: NFTDet.contractAddress, Owns: (NFT.CurrentOwner.balance > Bid.pending) ? Bid.pending : NFT.CurrentOwner.balance, OldOwner: Wallet_Details.UserAccountAddr, Action: "Accept", NewOwner: Bid.tokenBidAddress, NFTDetail: NFT.CurrentOwner, Bidder: Bid.tokenBidAddress, BidAmt: Bid.tokenBidAmt, coinname: NFT.coinname, LazyStatus: "completed", tokenName: NFTDet.tokenName, updateData: "", QR_tokenID: NFT.NFTDetail.QR_tokenID, ServiceFee: Wallet_Details.Service_Fee.Buyer, Fiat: NFT.Fiat, Charity: NFT.Charity, Calculation: Calculation, NFTPrice: Prices.PriceWithoutSerfee / 1e18, NFTPriceWithSerice: Prices.PriceWithSerfee / 1e18,SelectedNetwork:NFTDet.SelectedNetwork }  //updatedata added for lazy mint
      var Resp = await UpdateNFTOwners(NewOwnerDetail)
      if (Resp) {
        setAccBidstate(true)
        setAccBidText("Done")
        toast.success("Transaction Successfull")
        $('#Accept_closs').trigger("click");
        setTimeout(() => {
          history.push('/explore/All')
        }, 1000);
      }
    } else {
      toast.error("Try it Again Later.Please Check Your Funds")
    }
  }

  const AcceptBidMintCall = async () => {
    // alert("acceptmintbidcall")
    setAccBidstate(true)
    setAccBidText("OnProgress")
    console.log("sdnsdkfsdknmfk", NFT, NFTDet,Bid);
    var nounceArg = { RandomName: RandomName, NonceHash: NonceHash, SignatureHash: SignatureHash }
    var Arg = { Coinname: Bid.coinname, BidderAddress: Bid.tokenBidAddress, PriceWithoutSerfee: Prices.PriceWithoutSerfee, NFTId: Bid.tokenID, NoofNFTs: (NFT.CurrentOwner.balance > Bid.pending) ? Bid.pending : NFT.CurrentOwner.balance, TotalToken: NFT.CurrentOwner.quantity, Royaltity: Web3.utils.toWei(String(NFTDet?.tokenRoyality)), Status: NFT.CurrentOwner.LazyStatus, nounceArg: nounceArg }
    console.log("Arg for Accept mint call", Arg, RandomName, NonceHash, SignatureHash, Prices);
    var Type = NFTDet.type
    var Provider = Wallet_Details
    if (localStorage.walletConnectType !== "LMWallet") {
      console.log("nddakjnfaskjndas");
      var Receipt = await ContactHookPlaceandAccept(Arg, Type, Provider, NFTDet,NFT.CurrentOwner)
    } else {
      var Key = null
      var Argument = [[NFTDet.ipfsmeta, "Status", "lazy", NFTDet.RandomName, "other", ""], [Wallet_Details.UserAccountAddr, Arg.BidderAddress], [Arg.NoofNFTs, Type, NFTDet.tokenRoyality, Arg.PriceWithoutSerfee, Arg.NoofNFTs, NFTDet.NonceHash, "0"], Arg.Coinname, NFTDet.SignatureHash]
      var Receipt = await LMWalletCall(Arg, NFT.type, Wallet_Details, NFTDet, NFT, "Trade", Wallet_Details.networkConfiguration.TradeContract, Wallet_Details.networkConfiguration.TradeContract, Argument, "lazyMint", Key, false)
    }

    // console.log("receipt data returner", Receipt)

    if (Receipt.status) {
      var tokenID = null;

      if (Type == 721) {
        if (NFTDet.SelectedNetwork == config.MATIC_ID) {
          var tokenid = Receipt?.logs[3]?.topics[3];
          // console.log("asdsadadsad", tokenid);
          tokenID = Web3Utils.hexToNumber(tokenid);
          // console.log("tokenid from receiptansdlas", tokenID)
        } else if (NFTDet.SelectedNetwork == config.ETH_ID) {
          var tokenid = Receipt?.logs[3]?.topics[3];
          // console.log("asdsadadsad", tokenid);
          tokenID = Web3Utils.hexToNumber(tokenid);
          // console.log("tokenid from receiptansdlas", tokenID)
        } else if (NFTDet.SelectedNetwork == config.CAM_ID) {
          var tokenid = Receipt?.logs[2]?.topics[2];
          // console.log("asdsadadsad", tokenid);
          tokenID = Web3Utils.hexToNumber(tokenid);
          // console.log("tokenid from receiptansdlas", tokenID)
        }

      } else {
        if (NFTDet.SelectedNetwork == config.MATIC_ID) {
          var tokenid = Receipt.logs[3].topics[3];
          tokenID = Web3Utils.hexToNumber(tokenid);
          // console.log("tokenid from receipt", tokenID)
        } else if (NFTDet.SelectedNetwork == config.ETH_ID){
          var tokenid = Receipt.logs[3].topics[3];
          tokenID = Web3Utils.hexToNumber(tokenid);
          // console.log("tokenid from receipt", tokenID)
        } else if (NFTDet.SelectedNetwork == config.CAM_ID) {
          var tokenid = Receipt.logs[2].topics[2];
          tokenID = Web3Utils.hexToNumber(tokenid);
          // console.log("tokenid from receipt", tokenID)
        } 

      }

      var updateData = {
        hashValue: Receipt.transactionHash,
        tokenID: tokenID,
        status: Receipt.status,
        LazyStatus: NFT.CurrentOwner.balance == Bid.pending ? "completed" : "pending"
      }
      // console.log("fjsdfjsdnjsdnkds", NFTDet, NFT);
      var NewOwnerDetail = { Status: Receipt.status, Hash: Receipt.transactionHash, ConAddr: NFTDet.contractAddress, Owns: (NFT.CurrentOwner.balance > Bid.pending) ? Bid.pending : NFT.CurrentOwner.balance, OldOwner: Wallet_Details.UserAccountAddr, Action: "Accept", NewOwner: Bid.tokenBidAddress, NFTDetail: NFT.CurrentOwner, Bidder: Bid.tokenBidAddress, BidAmt: Bid.tokenBidAmt, coinname: NFT.coinname, LazyStatus: "completed", tokenName: NFTDet.tokenName, updateData: updateData, QR_tokenID: NFT.NFTDetail.QR_tokenID,SelectedNetwork:NFTDet.SelectedNetwork }
      var Resp = await UpdateNFTOwners(NewOwnerDetail)
      if (Resp) {
        setAccBidstate(true)
        setAccBidText("Done")
        toast.success("Transaction Successfull")
        $('#Accept_closs').trigger("click");
        setTimeout(() => {
          history.push('/explore/All')
        }, 1000);
      }
    } else {
      toast.error("Try it Again Later.Please Check Your Funds")
    }
  }

  const SignCall = async () => {
    if (localStorage.walletConnectType !== "LMWallet") {
      var resp = await useSignCall("Confirm Sign", Wallet_Details.UserAccountAddr, Wallet_Details)
      if (resp) {
        $('#bidpro_closs').trigger("click");
        setTimeout(() => {
          dispatch({
            type: Account_Connect,
            Account_Detail: {
              nftaction: "Bidded"
            }
          })
        }, 1000);
      }
    }
    else {
      $('#bidpro_closs').trigger("click");
      setTimeout(() => {
        dispatch({
          type: Account_Connect,
          Account_Detail: {
            nftaction: "Bidded"
          }
        })
        history.push('/explore/All')
      }, 1000);
    }


  }

  useEffect(() => {
    if (TokenPrice !== 0 && (TokenPrice !== prevTokenPrice || NoofToken !== prevNoofToken)) {
      // console.log("jdsnbfufnuewrgn", TokenPrice)
      var data = { Price: TokenPrice, Quantity: NoofToken, Serfee: Wallet_Details.Service_Fee, Decimal: NewTokenDecimal }
      var Data = PurchaseCalculation(data)
      setPrices(Data)
    }
  }, [NoofToken, TokenPrice])


  useEffect(() => {
    const tokenID = Web3Utils.hexToNumber("0x0000000000000000000000000000000000000000000000000000000062f383b7");
    console.log("tokenId", tokenID)
  }, [])

  async function ApproveCall() {
    var receiptt = null;
    var handlee = null;
    // console.log("qwewqeweasdsasaxxz", NFT.type, NFT);
    if (localStorage.walletConnectType !== "LMWallet") {
      if (Wallet_Details.providerss == null) {

        toast.warning("OOPS!..connect Your Wallet");
        return false;
      }
      var web3 = new Web3(Wallet_Details.Web3Pro);
      var currAddr = Wallet_Details.UserAccountAddr
      var MultiContract = new web3.eth.Contract(
        (NFTDet.type === 721 ? Abi721 : Abi1155),
        (NFTDet.contractAddress)
      );
      var chain = await web3.eth.getChainId()
      var encoded = await MultiContract.methods.setApprovalForAll(Wallet_Details.networkConfiguration.TradeContract, true).encodeABI();
      console.log("asdklasmda", encoded);
      const Transcation = {
        from: Wallet_Details.UserAccountAddr,
        to: NFTDet.contractAddress,
        data: encoded,
      };
      console.log("mdkasmkdlas", Transcation, Wallet_Details.Web3Pro);
      var gasPrice = await Wallet_Details.Web3Pro.eth.getGasPrice();
      var gasLimit = await Wallet_Details.Web3Pro.eth.estimateGas(Transcation);
      console.log("Adasdasdasdas", gasLimit);
      Transcation["gas"] = gasLimit;
      if (currAddr === "") {
        toast.warning("OOPS!..connect Your Wallet");
        return false;
      }
      setApprovalCallStatus('processing');
      try {
        if (chain == config.MATIC_ID) {
          await MultiContract.methods.setApprovalForAll(
            Wallet_Details.networkConfiguration.TradeContract,
            true
          ).send({
            from: Wallet_Details.UserAccountAddr, gasLimit: parseInt(gasLimit * 1.5, 10), gasPrice: gasPrice
          }).on('transactionHash', async (transactionHash) => {
            if (transactionHash !== "") {
              handlee = setInterval(async () => {
                receiptt = await web3.eth.getTransactionReceipt(transactionHash);
                clr();
              }, 8000)
            }
          })
        } else {
          await MultiContract.methods.setApprovalForAll(
            Wallet_Details.networkConfiguration.TradeContract,
            true
          ).send({
            from: Wallet_Details.UserAccountAddr,
          }).on('transactionHash', async (transactionHash) => {
            if (transactionHash !== "") {
              handlee = setInterval(async () => {
                receiptt = await web3.eth.getTransactionReceipt(transactionHash);
                clr();
              }, 8000)
            }
          })
        }

      }
      catch (error) {
        // console.log("zndksjfnksdjcnsc", error);
        toast.error("Approve failed");
        setApprovalCallStatus('try Again');
      }
    } else {
      var Arg = {
        Method: "setApprovalForAll",
        Data: [Wallet_Details.networkConfiguration.TradeContract, true],
        Mail: localStorage.loggedinaddress,
        LoginAddress: localStorage.userAddress,
        ContactAddress: Wallet_Details.networkConfiguration.TradeContract,
        Type: NFTDet.type == 721 ? "721" : "1155",
        Chain: String(NFTDet.SelectedNetwork),
        IntractContract: NFTDet.contractAddress
      }
      var receipt = await LMContactCalls(Arg)
      console.log("sajdsakadsda", receipt);
      // receipt = receipt.data.receipt
      if (receipt.data.receipt) {
        toast.success("Approve Successfully");
        setApprovalCallStatus('done');
      }
    }
    async function clr() {
      if (receiptt != null) {
        clearInterval(handlee)
        toast.success("Approve Successfully");
        setApprovalCallStatus('done');
      }
    }
  }



  async function orderApprovecheck(item, NFT) {
    try {
      if (localStorage.walletConnectType !== "LMWallet") {
        // console.log("OrderApprove Check", NFT1);
        if (Wallet_Details.providerss == null) {
          toast.warning("OOPS!..connect Your Wallet");
          return false;
        }
        var web3 = new Web3(Wallet_Details.providerss);
        var MultiContract = new web3.eth.Contract(
          (NFTDet.type === 721 ? Abi721 : Abi1155),
          NFTDet.type === 721 ? Wallet_Details.networkConfiguration.singleContract : Wallet_Details.networkConfiguration.multipleContract
        );
        if (Wallet_Details.UserAccountAddr &&
          Wallet_Details.networkConfiguration.TradeContract) {
          var status = await MultiContract.methods.isApprovedForAll(
            Wallet_Details.UserAccountAddr,
            Wallet_Details.networkConfiguration.TradeContract
          ).call();
        }
        // console.log("adjfnmjkwdnfjnd", status);
        return status;
      } else {
        console.log("Comming on elsee bavkend calll", Formdata.NetworkName);
        var Arg = {
          Method: "isApprovedForAll",
          Data: [Wallet_Details.UserAccountAddr, Wallet_Details.networkConfiguration.TradeContract],
          Mail: localStorage.loggedinaddress,
          LoginAddress: localStorage.userAddress,
          ContactAddress: NFTDet.contractAddress,
          Type: NFTDet.type ? "721" : "1155",
          Chain: NFTDet.SelectedNetwork,
          IntractContract: NFTDet.contractAddress,
          ContactType: NFTDet.type ? "Single" : "Multiple",
          Key: null,
        }
        var Balance = await LMApproveCheck(Arg)
        console.log("Balance", Balance);
        return Balance.data.receipt
      }
    }
    catch (e) {
      // console.log("OrderApprove Check", e);
      return false

    }

  }


  const selectChange = async (e) => {
    console.log("Accepttttttt", e);
    var NumDigitOnly = /[^0-9\.]/g
    var NumANdDotOnly12 = e.replace(NumDigitOnly, '')
    console.log("(e).length", (e).length, (e).length <= 12);
    if ((e).length <= 12) {

      console.log("dafdeeetdfsdfsdf", NumANdDotOnly12);
      setTokenPrice(NumANdDotOnly12)
    } else {
      setTokenPrice(0)
    }

  }

  const NumANdDotOnly = async (data) => {
    console.log("asdafafadfadf", data);
    var data = data.toString()
    var NumDigitOnly = /[^0-9\.]/g
    var str = data ? data.includes('.') ? data.split('.').length >= 3 ? (data.split('.')[0] + '.' + data.split('.')[1]).toString() : data : data : data
    console.log("asdadasdasdzxczxcxz", str);
    return str.toString().replace(NumDigitOnly, '')
  }

  async function signcall() {
    try {
      var generator = require('generate-password');
      var randomNum = randomInteger(10000000, 100000000);
      var password = generator.generate({
        length: 10,
        numbers: true
      });
      var web3RpcPro = new Web3(Wallet_Details.networkConfiguration.RPC_URL)
      var chain = NFTDet.SelectedNetwork
      const to = Wallet_Details.UserAccountAddr
      const _amount = 0;
      const _nonce = Date.now();
      // setNonceHash(_nonce);
      setRandomName(password)
      var tot = _nonce + Number(randomNum);
      setNonceHash(tot);
      if (localStorage.walletConnectType !== "LMWallet") {
        if (Wallet_Details.Web3Pro) {
          var web3Rpc = new Web3(Wallet_Details.providerss)
          if (web3Rpc) {
            SetSignTxt('On progress')

            var web3RpcPro = new Web3(Wallet_Details.providerss);
            console.log("dwqdwqewqewqeqw", to, _amount, password, tot);
            const result = web3RpcPro.utils.soliditySha3(to, _amount, password, tot);
            const signhash = await Wallet_Details.Web3Pro.eth.personal.sign(result, to);
            if (signhash) {
              SetSignTxt('done')
              setSignatureHash(signhash);
              toast.success("Verified Successfully")
            } else {
              toast.error("Signature Failed")
            }
          }
        }
      }
      else {
        console.log("Comming On Lm Wallet");
        var Arg = {
          to: to,
          _amount: _amount,
          password: password,
          tot: tot,
          Type: String(NFTDet.type),
          Chain: String(chain),
          Mail: localStorage.loggedinaddress,
          LoginAddress: localStorage.userAddress,
          Key: null
        }
        var receipt = await LMSignCall(Arg)
        console.log("sajdsakadsda", receipt.data);

        if (receipt.data.Status) {
          SetSignTxt('done')
          setSignatureHash(receipt.data.signedTx);
          toast.success("Verified Successfully")
        } else {
          toast.error("Signature Failed")
        }
      }
    } catch (e) {
      console.log("sdasdasdasd", e);
      SetSignTxt('Try again')
      toast.error("Signature Failed")
    }
  }


  const ImageMergeProcess = async () => {
    setAccBidstate(true)
    setAccBidText("OnProgress")
    const id = toast.loading("Image Generating", { closeButton: true, theme: "dark" });
    console.log("asdasdsadsadas NFT", NFT, Bid, NFTDet);
    var timestamp = Date.now()
    console.log("iuoiuiyuittr", timestamp)

    var updateData = {
      hashValue: NFT.NFTDetail.hashValue,
      tokenID: timestamp,
      status: NFT.NFTDetail.status,
      LazyStatus: NFT.CurrentOwner.balance == Bid.pending ? "completed" : "pending"
    }
    NFT.NFTDetail.ScannerTokenID = timestamp
    NFTDet.ScannerTokenID = timestamp
    // console.log("fjsdfjsdnjsdnkds", NFTDet, NFT);
    var NewOwnerDetail = { Status: NFT.NFTDetail.status, Hash: NFT.NFTDetail.hashValue, ConAddr: NFTDet.contractAddress, Owns: (NFT.CurrentOwner.balance > Bid.pending) ? Bid.pending : NFT.CurrentOwner.balance, OldOwner: Wallet_Details.UserAccountAddr, Action: "Accept", NewOwner: Bid.tokenBidAddress, NFTDetail: NFT.CurrentOwner, Bidder: Bid.tokenBidAddress, BidAmt: Bid.tokenBidAmt, coinname: NFT.coinname, LazyStatus: "completed", tokenName: NFTDet.tokenName, updateData: updateData, QR_tokenID: NFT.NFTDetail.QR_tokenID, Bulkmint: NFT.NFTDetail.Bulkmint, NFTTokens: NFT.NFTDetail, ScannerTokenID: timestamp }
    var Resp11 = await imageImger(NewOwnerDetail)
    console.log("sdasdasdasdasdadqeq", Resp11.data);
    if (Resp11.Success == "true" && Resp11.data.NFTDetails.type) {
      toast.update(id, {
        render: "Image Generated Succesfully",
        type: "success",
        isLoading: false,
        autoClose: 2500,
        closeButton: true,
        theme: "dark"
      });
      console.log("Sdfdaasdasdadad", timestamp);
      BulkMintLazyCall(Resp11.data.NFTDetails, timestamp)
    } else {
      setAccBidstate(false)
      toast.update(id, {
        render: "Error in Image Generating. Please tryAgain",
        type: "error",
        isLoading: false,
        autoClose: 2500,
        closeButton: true,
        theme: "dark"
      });
    }
  }

  const BulkMintLazyCall = async (Datas, timestamp) => {
    // alert("acceptmintbidcall")
    setAccBidstate(true)
    setAccBidText("OnProgress")
    console.log("sdnsdkfsdknmfk", NFT, NFTDet);

    NFT.NFTDetail.ScannerTokenID = timestamp
    NFTDet.ScannerTokenID = timestamp
    var nounceArg = { RandomName: RandomName, NonceHash: NonceHash, SignatureHash: SignatureHash }
    var Arg = { Coinname: Bid.coinname, BidderAddress: Bid.tokenBidAddress, PriceWithoutSerfee: Prices.PriceWithoutSerfee,PriceWithSerfee: Prices.PriceWithSerfee, NFTId: Bid.tokenID, NoofNFTs: (NFT.CurrentOwner.balance > Bid.pending) ? Bid.pending : NFT.CurrentOwner.balance, TotalToken: NFT.CurrentOwner.quantity, Royaltity: Web3.utils.toWei(String(NFTDet?.tokenRoyality)), Status: NFT.CurrentOwner.LazyStatus, nounceArg: nounceArg, Datas: Datas }
    // console.log("Arg for Accept mint call", Arg);
    var Type = NFTDet.type
    var Provider = Wallet_Details
    if (localStorage.walletConnectType !== "LMWallet") {
      var Receipt = await ContactHookTicketsBid(Arg, Type, Provider, NFTDet,NFT.CurrentOwner)
    } else {
      var NewTokenIds = NFTDet.tokenID == NFTDet.QR_tokenID ? Number(0) : Number(NFTDet.tokenID)
      var Key = null
      var Argument = [[Arg.Datas.ipfsmeta, "Status", "lazy", Arg.nounceArg.RandomName, "tickets",""], [Wallet_Details.UserAccountAddr, Arg.BidderAddress, NFTDet.tokenCreator], [Arg.NoofNFTs, "1155", Arg.Royaltity, Arg.PriceWithoutSerfee, Arg.TotalToken, Arg.nounceArg.NonceHash, "0", NewTokenIds,""], Arg.Coinname, Arg.nounceArg.SignatureHash]
      var Receipt = await LMWalletCall(Arg, NFT.type, Wallet_Details, NFTDet, NFT, "Trade", Wallet_Details.networkConfiguration.TradeContract, Wallet_Details.networkConfiguration.TradeContract, Argument, "ticketMinting", Key, false, Arg.PriceWithSerfee)
    }
    // console.log("receipt data returner", Receipt)

    if (Receipt.status) {
      var tokenID = null;

      if (Type == 721) {
        if (NFTDet.SelectedNetwork == config.MATIC_ID) {
          var tokenid = Receipt?.logs[3]?.topics[3];
          // console.log("asdsadadsad", tokenid);
          tokenID = Web3Utils.hexToNumber(tokenid);
          // console.log("tokenid from receiptansdlas", tokenID)
        } else if (NFTDet.SelectedNetwork == config.ETH_ID){
          var tokenid = Receipt?.logs[3]?.topics[3];
          // console.log("asdsadadsad", tokenid);
          tokenID = Web3Utils.hexToNumber(tokenid);
          // console.log("tokenid from receiptansdlas", tokenID)
        } else if (NFTDet.SelectedNetwork == config.CAM_ID) {
          var tokenid = Receipt?.logs[2]?.topics[2];
          // console.log("asdsadadsad", tokenid);
          tokenID = Web3Utils.hexToNumber(tokenid);
          // console.log("tokenid from receiptansdlas", tokenID)
        }

      } else {
        if (NFTDet.SelectedNetwork == config.MATIC_ID) {
          var tokenid = Receipt.logs[3].topics[3];
          tokenID = Web3Utils.hexToNumber(tokenid);
          // console.log("tokenid from receipt", tokenID)
        } else if (NFTDet.SelectedNetwork == config.ETH_ID) {
          var tokenid = Receipt.logs[3].topics[3];
          tokenID = Web3Utils.hexToNumber(tokenid);
          // console.log("tokenid from receipt", tokenID)
        } else if (NFTDet.SelectedNetwork == config.CAM_ID) {
          var tokenid = Receipt.logs[2].topics[2];
          tokenID = Web3Utils.hexToNumber(tokenid);
          // console.log("tokenid from receipt", tokenID)
        }

      }

      var updateData = {
        hashValue: Receipt.transactionHash,
        tokenID: tokenID,
        status: Receipt.status,
        LazyStatus: NFT.CurrentOwner.balance == Bid.pending ? "completed" : "pending"
      }

      var NewOwnerDetail1 = { Status: Receipt.status, Hash: Receipt.transactionHash, ConAddr: NFTDet.contractAddress, Owns: (NFT.CurrentOwner.balance > Bid.pending) ? Bid.pending : NFT.CurrentOwner.balance, OldOwner: Wallet_Details.UserAccountAddr, Action: "Accept", NewOwner: Bid.tokenBidAddress, NFTDetail: NFT.CurrentOwner, Bidder: Bid.tokenBidAddress, BidAmt: Bid.tokenBidAmt, coinname: NFT.coinname, LazyStatus: "completed", tokenName: NFTDet.tokenName, updateData: updateData, QR_tokenID: NFT.NFTDetail.QR_tokenID, Bulkmint: NFTDet.Bulkmint, NFTTokens: Datas, ScannerTokenID: Datas.ScannerTokenID,SelectedNetwork:NFTDet.SelectedNetwork }
      var Resp = await UpdateNFTOwnersBulk(NewOwnerDetail1)
      if (Resp) {
        setAccBidstate(true)
        setAccBidText("Done")
        toast.success("Transaction Successfull")
        $('#Accept_closs').trigger("click");
        setTimeout(() => {
          history.push('/explore/All')
        }, 1000);
      }

    } else {
      toast.error("Try it Again Later.Please Check Your Funds")
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };


  const ContactHookAcceptBid = async (Arg, Type, Provider) => {
    var Receipt = await useAcceptBidReceipt(Arg, Type, Provider)
    return Receipt
  }

  const ContactHookPlaceandAccept = async (Arg, Type, Provider, NFTDet,CurrentOwner) => {
    var Receipt = await useMintAcceptBidReceipt(Arg, Type, Provider, NFTDet,CurrentOwner)
    return Receipt
  }

  const ContactHookTicketsBid = async (Arg, Type, Provider, NFTDet,CurrentOwner) => {
    var Receipt = await usePurchaseTicketsBid(Arg, Type, Provider, NFTDet,CurrentOwner)
    return Receipt
  }

  const ContactHookTokenApp = async (Arg, Type, Provider) => {
    var Receipt = await useTokenAppReceipt(Arg, Type, Provider)
    return Receipt
  }

  const LMWalletCall = async (Arg, type, Wallet_Details, tokDetails, NFT, NameUse, IntractContact, Contract, data, method, Key, Status) => {
    var receipt = null
    console.log("Comming Inside the LmWallet", Arg, type, Wallet_Details, tokDetails, NFT, NameUse, IntractContact, Contract, data, method, Key);
    var Arg = {
      Method: method,
      Data: data,
      Mail: localStorage.loggedinaddress,
      LoginAddress: localStorage.userAddress,
      ContactAddress: Contract,
      Type: String(type),
      Chain: String(tokDetails.SelectedNetwork),
      IntractContract: IntractContact,
      ContactType: NameUse,
      value: Prices.PriceWithSerfee,
      Key: Key,
      Status: Status
    }
    var Receiptfun = await ContactMint(Arg)

    console.log("ArgReceipt", Receiptfun.data.receipt);
    return Receiptfun.data.receipt
  }

  const LMBalanceCheck = async (Arg, Type, Wallet_Details, IntractContact, NameUse, Contract, method, data, Key, tokenOwner) => {
    console.log("LMBalanceCheck", Arg, Type, Wallet_Details, IntractContact, NameUse, Contract, method, data, Key);
    var Arg = {
      Method: method,
      Data: data,
      Mail: localStorage.loggedinaddress,
      LoginAddress: localStorage.userAddress,
      ContactAddress: Contract,
      Type: String(Type),
      Chain: String(NFTDet.SelectedNetwork),
      IntractContract: IntractContact,
      ContactType: NameUse,
      value: Prices.PriceWithSerfee,
      Key: Key,
      tokenOwner: tokenOwner
    }
    var Balance = await LMBalanceOf(Arg)
    console.log("Balance", Balance);
    return Balance.data.receipt
  }

  const selectChange1 = async (e) => {
console.log("knadkjsdfnskjdfs",e);
var hookTokBalDecimal = await HookbalCheck(e.symbol, Wallet_Details, e.Network)
setCusTokenAddress(e.contractAddress)
  }
  return (
    <>
      {/* place_bid multiple */}
      <div
        className="modal fade primary_modal"
        id="place_bid_multiple_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="place_bid_multiple_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="place_bid_multiple_modalLabel">
                Place a bid
              </h5>
              <p className="text-center place_bit_desc">
                You are about to {NFT.Mybid && NFT.Mybid.length !== 0 ? "Edit Your" : "Place a"} bid for
              </p>
              <p className="place_bit_desc_2">
                {console.log("SDfsdfsdfsdfs", NFT)}
                <span className="text_red mr-2">{NFTDet?.tokenName?.slice(0, 10)}</span>by
                <span className="text_red ml-2 text-truncate">{(NFT.CurrentOwner.CurrentOwner && NFT.CurrentOwner.CurrentOwner.name) ? NFT.CurrentOwner.CurrentOwner.name : NFT.CurrentOwner.CurrentOwner && (NFT.CurrentOwner.CurrentOwner.curraddress).slice(0, 10)}</span>
              </p>
              {NFT.Mybid && NFT.Mybid.length !== 0 ?
                <p className="text-center place_bit_desc">
                  Your Previous Bid - {NFT.Mybid[0] && NFT.Mybid[0].tokenBidAmt} {config.tokenSymbol} for Each
                </p> : ("")
              }
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-0 pt-0">
              {!(Prices.Display) ?
                <><TailSpin wrapperClass="reactloader mt-0 mb-3" color="#00BFFF" height={100} width={70} /></>
                :
                <>
                  <form className="px-4 bid_form">
                    {/* <label htmlFor="bid">Your bid</label>
                <div className="input-group mb-3 input_grp_style_1">
                  <input
                    type="text"
                    id="bid"
                    className="form-control"
                    placeholder="Enter your bid"
                    aria-label="bid"
                    aria-describedby="basic-addon2"
                  />
                  <div className="input-group-append">
                    <span className="input-group-text pl-0" id="basic-addon2">
                      USD
                    </span>
                  </div>
                </div> */}

                    <div className="input-group mb-3 position input_grp_style_1">
                      <input
                        type="number"
                        min="1"
                        max="12"
                        value={TokenPrice}
                        onChange={(e) => { selectChange(e.target.value) }}
                        id="bid"
                        className="form-control modal_inp_white"
                        placeholder="Enter your bid"
                        aria-label="bid"
                        aria-describedby="basic-addon2"
                        onKeyDown={handleKeyDown}
                        onWheel={(e) => e.target.blur()}
                        // autoComplete='off'
                      />
                      {/* <div className="input-group-append">
                        <span className="input-group-text pl-0" id="basic-addon2">
                          {config.tokenSymbol}
                        </span>
                      </div> */}
                      
<Select
  className="selectright"
  onChange={(e) => selectChange1(e)}
  options={TokenListsnet}
  label="Single select"
  isSearchable={false}
  styles={customStyles}

  classNamePrefix="react-select"
  formControlProps={{
    fullWidth: true,
  }}
/>
{/* {ValidationError && ValidationError.NetworkName &&
  <p className="Warning_label">{ValidationError && ValidationError.NetworkName}</p>
} */}

                    </div>
                    {(NFTDet.type === 1155) &&
                      <>
                        <label htmlFor="qty">
                          Enter quantity{" "}
                          <span className="label_muted pl-2">({NFTDet.balance} available in MarketPlace)</span>
                        </label>
                        <div className="mb-3 input_grp_style_1">
                          <input
                            type="number"
                            id="qty"
                            onChange={(e) => { setNoofToken(e.target.value) }}
                            className="form-control modal_inp_white"
                            placeholder="quantity"
                            onKeyDown={handleKeyDown}
                            onWheel={(e) => e.target.blur()}
                          />
                        </div>
                      </>
                    }
                    <div className="row pb-3">
                      <div className="col-12 col-sm-6">
                        <p className="buy_desc_sm">Your balance</p>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-right">
                        <p className="buy_desc_sm_bold">{Wallet_Details.UserAccountBal} {config.currencySymbol}</p>
                      </div>
                    </div>
                    <div className="row pb-3">
                      <div className="col-12 col-sm-6">
                        <p className="buy_desc_sm">Your Bidding balance</p>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-right">
                        <p className="buy_desc_sm_bold">{BiddingBalance / 10 ** NewTokenDecimal} {CusTokenName || ""}</p>
                      </div>
                    </div>
                    <div className="row pb-3">
                      <div className="col-12 col-sm-6">
                        <p className="buy_desc_sm">Service fee</p>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-right">
                        <p className="buy_desc_sm_bold">{Wallet_Details.Service_Fee.Buyer}%</p>
                      </div>
                    </div>
                    <div className="row pb-3">
                      <div className="col-12 col-sm-6">
                        <p className="buy_desc_sm">You will pay</p>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-right">
                        <p className="buy_desc_sm_bold">{Prices.Display} {CusTokenName}</p>
                      </div>
                    </div>

                    <div className="text-center">
                      <Button
                        className="d-none"
                        id="hiddenbutton"
                        data-dismiss="modal"
                        aria-label="Close"
                        data-toggle="modal"
                        data-target="#proceed_bid_modal"
                      >
                        Hidden Button for validation
                      </Button>
                      <Button
                        className="create_btn btn-block"
                        onClick={() => ValidCheck()}
                      >
                        Place Bid
                      </Button>
                    </div>
                  </form>
                </>
              }
            </div>
          </div>
        </div>
      </div>
      {/* end place_bid modal multiple */}

      {/* proceed_bid Modal */}
      <div
        className="modal fade primary_modal"
        id="proceed_bid_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="proceed_bid_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="proceed_bid_modalLabel">
                Follow Steps
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="bidpro_closs"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                {/* {console.log("sknvfdvnkdfkndfvkndf", approvecheck)} */}
                {!approvecheck &&
                  <div className="media approve_media">
                    <i
                      className="fas fa-check mr-3 pro_complete"
                      aria-hidden="true"
                    ></i>
                    {/* <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i> */}

                    <div className="media-body">
                      <p className="mt-0 approve_text">Get Approve</p>
                      {/* <p className="mt-0 approve_desc">
                      
                    </p> */}
                    </div>
                  </div>
                }
                {!approvecheck &&
                  <div className="text-center my-3">
                    <Button
                      className="create_btn btn-block"
                      disabled={(ApprovalCallStatus === 'processing' || ApprovalCallStatus === 'done')}
                      // onClick={()=>{BalCheck()}}
                      onClick={() => { ApproveCall() }}

                    >  {ApprovalCallStatus === "OnProgress" && (
                      <i
                        class="fa fa-spinner mr-3 spinner_icon"
                        aria-hidden="true"
                      ></i>
                    )}{ApprovalCallStatus}</Button>
                  </div>
                }

                <div className="media approve_media">
                  <i
                    className="fas fa-check mr-3 pro_complete"
                    aria-hidden="true"
                  ></i>
                  {/* <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i> */}

                  <div className="media-body">
                    <p className="mt-0 approve_text">Approve</p>
                    <p className="mt-0 approve_desc">
                      Checking balance and approving
                    </p>
                  </div>
                </div>
                <div className="text-center my-3">
                  {console.log("dfedadadqeqwe", Bidstate, ApprovalCallStatus, approvecheck, BidText)}
                  <Button
                    className="create_btn btn-block"
                    disabled={Bidstate && (ApprovalCallStatus !== 'done' || !approvecheck === false)}
                    // onClick={()=>{BalCheck()}}
                    onClick={() => { Bidcallfunction() }}

                  >  {BidText === "OnProgress" && (
                    <i
                      class="fa fa-spinner mr-3 spinner_icon"
                      aria-hidden="true"
                    ></i>
                  )}{BidText}</Button>
                </div>
                <div className="media approve_media">
                  <i className="fas fa-check mr-3" aria-hidden="true"></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Signature</p>
                    <p className="mt-0 approve_desc">
                      Create a signatute to place a bid
                    </p>
                  </div>
                </div>
                <div className="text-center mt-3">
                  <Button className="create_btn btn-block" disabled={Signstate} onClick={() => { SignCall() }}>
                    Start
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end proceed_bid modal */}

      {/* accept bid Modal */}
      {Bid &&
        <div
          className="modal fade primary_modal"
          id="accept_modal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="accept_modalCenteredLabel"
          aria-hidden="true"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-sm"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header text-center">
                <h5 className="modal-title" id="accept_modalLabel">
                  Accept bid
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="Accept_closs"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {!(Prices.PriceWithSerfee) ?
                <><TailSpin wrapperClass="reactloader mt-0 mb-3" color="#00BFFF" height={100} width={70} /></>
                :
                <>
                  <div className="modal-body px-0">
                    <div className="change_price_img_div text-center">
                      <img
                        src={NFTDet.additionalImage != "" ? `${config.Back_URL}/nftImg/${NFTDet.tokenCreator}/${NFTDet.image}` : `${config.Back_URL}/nftImg/${NFTDet.tokenCreator}/${NFTDet.image}`}
                        alt="Collections"
                        className="img-fluid"
                      />
                    </div>
                    <p className="text-center accept_desc">
                      <span className="buy_desc_sm">
                        You are about to accept bid for
                      </span>
                      <span className="buy_desc_sm_bold pl-2">{NFTDet?.tokenName?.slice(0, 15)}</span>
                      <span className="buy_desc_sm pl-2">from</span>
                      <span className="buy_desc_sm_bold pl-2">
                        {(Bid.Bidderdet && Bid.Bidderdet.name === "") ? Bid.tokenBidAddress.slice(0, 20) : Bid.Bidderdet && Bid.Bidderdet.name}
                      </span>
                    </p>
                    <p className="info_title text-center">
                      {Bid.tokenBidAmt} {Bid.coinname} for {Bid.pending} edition(s)
                    </p>

                    <div className="row mx-0 pb-3 mt-4">
                      <div className="col-12 col-sm-6 px-4">
                        <p className="buy_desc_sm">Service fee</p>
                      </div>
                      <div className="col-12 col-sm-6 px-4 text-sm-right">
                        <p className="buy_desc_sm_bold">{Wallet_Details.Service_Fee.Seller}%</p>
                      </div>
                    </div>
                    <div className="row mx-0 pb-3">
                      <div className="col-12 col-sm-6 px-4">
                        <p className="buy_desc_sm">You will get</p>
                      </div>
                      <div className="col-12 col-sm-6 px-4 text-sm-right">
                        <p className="buy_desc_sm_bold">{Prices.PriceWithSerfee} {CusTokenName}</p>
                      </div>
                    </div>
                    <form className="px-4">
                      {/* {console.log("sknvfdvnkdfkndfvkndf", approvecheck)} */}
                      {/* {!approvecheck &&
                        <div className="media approve_media">
                          <i
                            className="fas fa-check mr-3 pro_complete"
                            aria-hidden="true"
                          ></i>
                          <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>

                          <div className="media-body">
                            <p className="mt-0 approve_text">Get Approve</p>
                            <p className="mt-0 approve_desc">
                      
                    </p>
                          </div>
                        </div>
                      } */}
                      {!approvecheck &&
                        <div className="text-center my-3">
                          <Button
                            className="create_btn btn-block"
                            disabled={(ApprovalCallStatus === 'processing' || ApprovalCallStatus === 'done')}
                            // onClick={()=>{BalCheck()}}
                            onClick={() => { ApproveCall() }}

                          >  {ApprovalCallStatus === "OnProgress" && (
                            <i
                              class="fa fa-spinner mr-3 spinner_icon"
                              aria-hidden="true"
                            ></i>
                          )}{ApprovalCallStatus}</Button>
                        </div>
                      }
                      {console.log("sdasdasdasdasd", NFT)}
                      {NFT.NFTDetail.Bulkmint == 'Bulk' &&
                        <Button className="create_btn btn-block" disabled={AccBidstate} onClick={() => { signcall() }}>


                          {SignTxt == "progress" && (
                            <i
                              class="fa fa-spinner mr-3 spinner_icon"
                              aria-hidden="true"
                            ></i>
                          )}{(SignTxt == "Done") ? "Done" : "sign"}
                        </Button>
                      }

                      <div className="text-center">
                        <Button
                          className="create_btn btn-block"
                          disabled={AccBidstate}
                          // onClick={()=>{AccBalCheck()}}
                          onClick={() => { AccBalMintCheck() }}    // lazy minter functionality

                        >
                          {AccBidText === "OnProgress" && (
                            <i
                              class="fa fa-spinner mr-3 spinner_icon"
                              aria-hidden="true"
                            ></i>
                          )}{AccBidText}
                        </Button>

                      </div>
                    </form>
                  </div>
                </>}
            </div>
          </div>
        </div>
      }
      {/* end accept bid modal */}
    </>
  )
})