import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import config from "../../lib/config"
import { v1_Social } from "actions/v1/user";
import { Account_disConnect, Account_Connect } from "../../actions/redux/action";
import { useSelector, useDispatch } from "react-redux";
function SubDomainFooter(props) {

    const Wallet_Details = useSelector((state) => state.wallet_connect_context);
    const [social, Setsocial] = useState()
    console.log("asdasjodlkmalkdmwdasd", Wallet_Details);
    var Sateeee = props.Item
    useEffect(() => {
        SocialLinks()
    }, [])
    const SocialLinks = async () => {
        var data = await v1_Social()
        console.log("datadata", data.userValue[0].social);
        Setsocial(data.userValue[0].social)
    }

    return (
        <>
            <div className='home_header home header_bar_align subdf_bg'>
                <div className='container  py-5'>
                    <div className='row'>
                        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
                            <div className='subd_mobfootlogo'>
                                {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                    // <img className='footer_subd_logo' src={require('../../assets/images/faces/sublogo.png')} />
                                    <div class="header_logo_align"></div>
                                    :
                                    <img className='footer_subd_logo' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />

                                }

                                <p className='subdf_hint'>Join Us For This Fun and Thrilling Event</p>
                            </div>
                        </div>
                        <div className='col-xxl-8 col-cl-8 col-lg-8 col-md-12 col-sm-12 col-12'>
                            <div className='row mt-sm-4 mt-lg-0'>
                                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6 subdf_dtls'>
                                    <p>Categories</p>
                                    <ul>
                                        <li><a href='https://lazyminter.com/tickets' target="_blank">Utilities</a></li>
                                        <li><a href='https://lazyminter.com/collections/Collection/'>Collections</a></li>
                                        <li><a href='https://lazyminter.com/explore/By%20Brand'>By Brand</a></li>
                                        <li><a href='https://lazyminter.com/explore/By%20Reward%20Types'>By Reward Type</a></li>
                                    </ul>
                                </div>
                                <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-6 subdf_dtls'>
                                    <p>Resources</p>
                                    <ul>
                                        <li><a href='https://lazyminter.com/about'>About</a></li>
                                        <li><a href='https://lazyminter.com/how-it-works'>Faq</a></li>
                                        <li><a href='https://lazyminter.com/Contact'>Contact</a></li>
                                        <li><a href='https://lazyminter.com/privacy-policy'>Privacy policy</a></li>
                                    </ul>
                                </div>
                                <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-4 col-sm-4 col-12 subdf_dtls'>
                                    <p>Get in touch</p>
                                    <address>
                                        Singapore<br />
                                        {/* 51 Changi Business Park Central 2,
                                        The Signature, #04-05. Singapore 486066 */}
                                        1 Paya Lebar Link,
                                        #04-01 Paya Lebar Quarter,
                                        Singapore 408533
                                    </address>
                                    <div className="subdf_roundwhole">
                                        {social &&
                                            social.length > 0 &&
                                            social.map((Link) => {
                                                // { console.log("qwerttttcmsnewww", Link) }
                                                return (

                                                    <>
                                                        {Link.Facebook && (
                                                            <a href={Link.Facebook} target="_blank">
                                                                <div className='subdf_round subdf_fbround'>
                                                                    <i class="fa-brands fa-facebook-f"></i>
                                                                </div>
                                                            </a>
                                                        )}
                                                            {Link.Instagram && (
                                                            <a href={Link.Instagram} target="_blank">
                                                                <div className='subdf_round subdf_instaround'>
                                                                    <i class="fa-brands fa-instagram"></i>
                                                                </div>
                                                            </a>
                                                        )}
                                                        {Link.Twitter && (
                                                            <a href={Link.Twitter} target="_blank">
                                                                <div className='subdf_round'>
                                                                <i class="fa-brands fa-x-twitter"></i>
                                                                </div>
                                                            </a>
                                                        )}
                                                    



                                                    </>
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SubDomainFooter