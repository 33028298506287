import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Route, Switch, Redirect } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Home from "views/Home.js";
import Create from "views/Create.js";
import CreateSingle from "views/Create-Standard";
import Bulkminting from "views/Bulkminting.js";
import SolBound from "views/SolBound";
import HowItWorks from "views/how-it-works.js";
import EditProfile from "views/edit-profile.js";
import Myitems from "views/my-items.js";
import Explore from "views/Explore.js";
import Tickets from "views/tickets.js";
import Collections from "views/collections";
import Collectioncard from "views/collectioncard";
import Following from "views/following.js";
import Privacypolicy from "views/Privacypolicy.js";
import Terms from "views/Termsofservice.js";
import Info from "views/info.js";
import SoulInfo from "views/SoulBoundInfo";
import Search from "views/search.js";
import ConnectWallet from "views/connect-wallet.js";
import comingsoon from "views/comingsoon.js";
import Vault from "views/Vault";
import Activity from "views/newmyitems.js";
import Rankings from "views/Rankings";
import SoulCard from "views/card_soul";
import TestPage from "views/separate/Test";
import PricingPage from "views/PricingPage";
import PricingHome from "views/PricingHome";
import UserDashboard from "views/UserDashboard";
import QrCodeScan from "views/QrCodeScan";
import UserProfile from "views/UserProfile";
import userDashBoard from "views/UserCommonDashboard";
import NewSubDomain from "views/NewSubDomain";
import Preview from "views/Preview";
import ChooseNft from "views/ChooseNft";
import Web3Ids from "views/Web3Ids";
import Templatenew from "views/Templatenew";
import LMwallet from "views/LMwallet";
import FractionalMint from "views/Fractions/FractionalCreate";
import LoyaltyNew from "views/LoyaltyNew";
import FractionExplore from "views/Fractions/FractionExplore";
import FractionalInfo from "views/Fractions/Fractional_Info";
import CreateVote from "../views/CreateVote"
import config from "lib/config";
import { toast } from "react-toastify";
import Votedetails from "../views/votedetails";



toast.configure();
var toasterOption = config.toasterOption;

export const RoutesMain = (props) => {
  const location = useLocation;
  console.log("Base Route");
  return (
    <>
      <Switch>
        <Route path="/home" component={Home} />
        <Route path="/create" component={Create} />
        <Route path="/LMwallet" component={LMwallet} />
        <Route path="/create-single" component={CreateSingle} />
        <Route path="/create-multiple" component={CreateSingle} />
        <Route path="/Bulkminting" component={Bulkminting} />
        <Route path="/FractionalNFT" component={FractionalMint} />
        <Route path="/SolBound" component={SolBound} />
        <Route path="/edit-profile" component={EditProfile} />
        <Route path="/explore/:cat" component={Explore} />
        <Route path="/Vault" component={Vault} />
        <Route path="/tickets" component={Tickets} />
        <Route path="/Web3Ids" component={Web3Ids} />
        <Route path="/my-items/user/:userurl" component={Myitems} />
        <Route path="/my-items/:useradd" component={Myitems} />
        <Route path="/FractionDetails/:conaddr/:owneraddr/:tokenID" component={FractionalInfo} />
        <Route path="/Info/:conaddr/:owneraddr/:tokenID" component={Info} />
        <Route path="/my-items" component={Myitems} />
        <Route path="/search/query/:q" component={Search} />
        <Route path="/Following" component={Following} />
        <Route path="/connect-wallet/verifymail/:token" component={ConnectWallet} />
        <Route path="/connect-wallet" component={ConnectWallet} />
        <Route path="/activity" component={Activity} />
        <Route path="/comingsoon" component={comingsoon} />
        <Route path="/privacy-policy" component={Privacypolicy} />
        <Route path="/terms-and-conditions" component={Terms} />
        <Route path="/how-it-works" component={HowItWorks} />
        <Route path="/test" component={TestPage} />
        <Route path="/votedetail" component={Votedetails} />
        <Route path="/Rankings" component={Rankings} />
        <Route path="/CreateVote" component={CreateVote} />
        <Route path="/pricing" component={PricingPage} />
        <Route path="/qrcodescan" component={QrCodeScan} />
        <Route path="/userprofile" component={UserProfile} />
        <Route path="/userdashboard" component={UserDashboard} />
        <Route path="/usercommondashboard" component={userDashBoard} />
        <Route path="/ChooseNft" component={ChooseNft} />
        <Route path="/about" component={PricingHome} />
        <Route path="/Preview" component={Preview} />
        <Route path="/Templatenew" component={NewSubDomain} />
        <Route path="/loyalty" component={LoyaltyNew} />
        <Route path="/collections/:Collection/:Category" component={Collections} />
        <Route path="/collections/Soulbound/:Category" component={Collections} />
        <Route path="/certificate/SBT/:Collection/:Category" component={Collections} />
        <Route path="/collections/:Collection" component={Collections} />
        <Route path="/collectioncard/:Address/:CollectionName" component={Collectioncard} />
        <Route path="/certificate/:Address/:CollectionName" component={SoulCard} />
        <Route path="/SoulInfo/:conaddr/:owneraddr/:tokenID" component={SoulInfo} />
        <Route path="/:conaddr/:owneraddr/:tokenID" component={SoulInfo} />
        <Route path="/Fraction/:Category" component={FractionExplore} />
        <Route exact path="/*" component={Home}>
          <Redirect to="/home" />
        </Route>
      </Switch>
    </>
  );
};

export const SubRoutes = (props) => {
  const history = useHistory();
  useEffect(() => {
    RoutesChange();
  }, []);
  const RoutesChange = () => {
    var arr = window.location.href;
    var split = arr.split("/");
    history.push("/");
  };

  return (
    <>
      <Switch>
        <Route path="/" component={NewSubDomain} />
        <Route exact path="/*" component={NewSubDomain}>
          <Redirect to="/" />
        </Route>
      </Switch>
    </>
  );
};

export const TemplateRoutes = (props) => {
  const history = useHistory();
  useEffect(() => {
    RoutesChange();
  }, []);
  const RoutesChange = () => {
    var arr = window.location.href;
    var split = arr.split("/");
    history.push("/");
  };

  return (
    <>
      <Switch>
        <Route path="/" component={Templatenew} />
        <Route exact path="/*" component={Templatenew}>
          <Redirect to="/" />
        </Route>
      </Switch>
    </>
  );
};

export const LoyalRoutes = (props) => {
  const history = useHistory();
  useEffect(() => {
    RoutesChange();
  }, []);
  const RoutesChange = () => {
    var arr = window.location.href;
    var split = arr.split("/");
    history.push("/");
  };

  return (
    <>
      <Switch>
        <Route path="/" component={LoyaltyNew} />
        <Route exact path="/*" component={LoyaltyNew}>
          <Redirect to="/" />
        </Route>
      </Switch>
    </>
  );
};
