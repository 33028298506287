import React, {  useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import fox from '../../assets/images/lazymintingnew/metamask.png';
import Venly from '../../assets/images/torus.png'
import $ from 'jquery';
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import loginlogo from "../../assets/images/lazymintingnew/lazyminter_whitetheme.png";
import {
  LoginSocialGoogle,
  LoginSocialFacebook,
  LoginSocialTwitter
} from 'reactjs-social-login';
import config from '../../lib/config';
import { connectWallet, getServiceFees } from '../../hooks/useWallet';
import { AddressUserDetails_GetOrSave_Action, userget, usergetUpdateCountry } from 'actions/v1/user';
// import { LoginRegisterFind } from 'actions/v1/token'
import { useSelector, useDispatch } from 'react-redux';
import { Account_Connect, Account_disConnect } from "actions/redux/action";
import {
  FacebookLoginButton,
  GoogleLoginButton,
  TwitterLoginButton
} from 'react-social-login-buttons'
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
// core components
import styles from "assets/jss/material-kit-react/components/headerStyle.js";
import isEmpty from '../../lib/isEmpty.js';
import { network } from "../../views/network";
// import {signinuser,loginuser} from "../../actions/v1/user.js"
import { signinuser, loginuser, getGoogleAccountDetails, signinAuth, forgotPassswordfn, passWordChange } from "../../actions/v1/user.js"
import { FreePackSubScribe } from 'actions/v1/token'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import Cookies from 'universal-cookie';

const useStyles = makeStyles(styles);

export default function Header(props) {
  const [theme, setTheme] = useState(false);
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const dispatch = useDispatch();
  const history = useHistory();
  const cookies = new Cookies();
  const classes = useStyles();
  const [recoveryMail, setRecoveryMail] = useState("")
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");
  const [passwordType2, setPasswordType2] = useState("password");
  const [DisBtn, setDisBtn] = useState(false);
  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };

  });

  const hideMainModal = () => {
    window.$("#register_modal").modal("hide");
  }

  const hideMainModal12 = () => {
    window.$("#closeregisterMail").click();
  }
  const HomeRedirect = (data) => {
    window.$('#' + data).click();
    window.$('#register_modal').modal('show');
  }

  $(window).scroll(function () {
    if ($(this).scrollTop()) {
      $('header').addClass('fixedTop');
    } else {
      $('header').removeClass('fixedTop');
    }
  });

  const handleDrawerToggle = () => {
    // alert(1)
    console.log("mobile");
    setMobileOpen(!mobileOpen);

    var roottheme = document.getElementById("root");
    if (roottheme.classList.contains("dark_theme")) {
      localStorage.setItem("theme", 'dark_theme');
    }
    else {
      localStorage.setItem("theme", 'light_theme');
    }
    if (localStorage.getItem("theme") == "light_theme") {
      setTimeout(function () {
        var usebody = document.getElementsByClassName("mobile_nav");
        for (var j = 0; j < usebody.length; j++) {
          usebody[j].classList.remove('dark_theme')
        }
      }, 100);
    }
    else {
      setTimeout(function () {
        var usebody = document.getElementsByClassName("mobile_nav");
        for (var j = 0; j < usebody.length; j++) {
          usebody[j].classList.add('dark_theme')
        }
      }, 100);
    }
  };
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  };
  const { color, rightLinks, leftLinks, brand, fixed, absolute } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });
  const brandComponent = <Button className={classes.title}>{brand}</Button>;

  ///sign up functionality

  const initialValue = {
    "signemail": "",
    "signpassword": "",
    "signgame": "",
    "loginemail": "",
    "loginpassword": "",
    "checkbox": "",
  }

  const initialConfrim = {
    "OTPass": "",
    "PasswordOne": "",
    "ConfrimPassword": "",
    "EmailAddress": ""
  }
  const [formValue, setFormValue] = useState(initialValue);
  const [Confrimpass, setConfrimpass] = useState(initialConfrim);
  const [validErrors, setValidErrors] = useState("");
  const [checkState, setcheckState] = useState(false);
 
  const handleSubmit = async () => {
    formValue.checkbox = checkState
    formValue.address = Wallet_Details.UserAccountAddr

    var resp = await formvalidation(formValue, "signin");
    // console.log("erro resp", resp)

    if (resp) {
      setValidErrors(resp)
    }

    if (!isEmpty(resp)) {
      toast.error("Fix all Form Errors")
    }
    else {


      // console.log("payload to backenc", formValue)
      var resp = await signinuser(formValue)
      // console.log("dcsdhcjhdsgcjsd", resp);
      if (resp?.status) {
        // window.$("#closeregister").click();
        localStorage.setItem("loggedinaddress", resp.logaddress)
        localStorage.setItem("homepopblock", false)
        toast.success(resp.msg)
        GetCountry()
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
        dispatch({
          type: Account_Connect,
          Account_Detail: {
            LoginAddresss: Wallet_Details.UserAccountAddr,
            LoginMail: resp.logaddress
          }
        })
      }
      else
        toast.error(resp.msg)

    }


  }


  const loginsubmit = async () => {
    formValue.address = Wallet_Details.UserAccountAddr
    formValue.checkbox = checkState
    var resp = await formvalidation(formValue, "signin");
    // console.log("erro resp", resp, formValue)

    if (resp) {
      setValidErrors(resp)
    }

    if (!isEmpty(resp)) {
      toast.error("Fix all Form Errors")
    }
    else {


      // console.log("payload to backenc", formValue)
      var resp = await loginuser(formValue)
      // console.log("dfadfdafdfsdfsdf", resp);
      if (resp?.status) {
        // window.$("#closelogin").click();
        if (resp?.logaddress)
          localStorage.setItem("loggedinaddress", resp.logaddress)
        toast.success(resp.msg)
        GetCountry()
        // setTimeout(() => {
        //   window.location.reload()
        // }, 1000);
        dispatch({
          type: Account_Connect,
          Account_Detail: {
            LoginAddresss: Wallet_Details.UserAccountAddr,
            LoginMail: resp.logaddress
          }
        })
        // if(Wallet_Details.UserAccountAddr == localStorage.getItem('userAddress')){
        //   // window.$("#closeregister").click();
        // }



      }
      else {
        toast.error(resp.msg)
        // if (resp.msg == "register to login") {
        //   // window.$("#closelogin").click();
        //   window.$("#triggerregister").click();

        // }


      }

    }
  }


  const handlechange = (e) => {

    const { id, value } = e.target;
    setFormValue({ ...formValue, [id]: value })
    // console.log("formdata", formValue);
  }

  const handlechange1 = (e) => {
    console.log("qwqweqweqweqwe", e.target.checked);
    const { id, value } = e.target;
    setFormValue({ ...formValue, [id]: value })
    console.log("formdata", formValue);
    // formValue.checkbox = e.target.checked
  }
  const handlechangeCheckBox = (e) => {
    console.log("qwqweqweqweqwe", e.target.checked);
    setcheckState(e.target.checked)
    // const { id, value } = e.target;
    // setFormValue({ ...formValue, [id]: value })
    // console.log("formdata", formValue);
    // formValue.checkbox = e.target.checked
  }
  const EnterValue = async (e) => {
    console.log("Enter value For Change password", e.target.value);
    const { id, value } = e.target;
    console.log("id,valuse passs chang", id, value);
    setConfrimpass({ ...Confrimpass, [id]: value })
  }

  const SubmitPass = async () => {
    formValue.address = Wallet_Details.UserAccountAddr
    console.log("Confrimpass", Confrimpass, recoveryMail);
    var resp = await ValidatePassAndconfrim(Confrimpass);
    console.log("erro resp", resp, Confrimpass)

    if (resp) {
      setValidErrors(resp)
    }

    if (!isEmpty(resp)) {
      toast.error("Fix all Form Errors")
    }
    else {
      Confrimpass.EmailAddress = localStorage.ForgetMail.toLowerCase()

      console.log("payload to backenc", Confrimpass)
      var resp = await passWordChange(Confrimpass)
      console.log("dfadfdafdfsdfsdf", resp);
      if (resp?.status) {
        toast.success(resp.msg)
        window.$("#closeregisterChange").click();
        window.$('#congradulationModal').modal('show');
        setTimeout(() => {
          window.$("#closeregisterSucess").click();
          window.$("#triggerregister").click();
        }, 1500);

        localStorage.removeItem("ForgetMail")
      }
      else {
        toast.error(resp.msg)
      }

    }
  }

  const ValidatePassAndconfrim = async (data) => {
    console.log("i/p for validaion change pass", data, data.OTPass.length)
    var validationErr = {};
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;

    // if (data.EmailAddress == "") { 
    //   validationErr.EmailAddress = "Email cannot be empty" 
    // }
    // else if (data.EmailAddress != "") {
    //   if (!emailRegex.test(data.EmailAddress)) { validationErr.EmailAddress = "Enter valid email" }
    // }

    if (!data.OTPass) {
      validationErr.OTPass = "OTP cannot be empty"
    }
    else if (data.OTPass.length !== 8) {
      validationErr.OTPass = "Invalid OTP"
    }
    if (!data.PasswordOne) {
      validationErr.PasswordOne = "password cannot be empty"
    }
    if (!data.ConfrimPassword) {
      validationErr.ConfrimPassword = "Conform password cannot be empty"
    }
    else if (data.PasswordOne !== data.ConfrimPassword) {
      validationErr.ConfrimPassword = "Password and Conform password Must be same"
    }
    return validationErr;
  }

  const formvalidation = async (data, filter) => {
    console.log("i/p for validaion", data, filter)

    var validationErr = {};
    let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-zA-Z\-0-9]+\.)+[A-Za-zA-Z]{2,}))$/;

    if (filter == "signin") {
      if (data.signemail == "") { validationErr.email = "Email cannot be empty" }
      else if (data.signemail != "") {
        if (!emailRegex.test(data.signemail)) { validationErr.email = "Enter valid email" }
      }

      if (!data.signpassword) { validationErr.password = "password cannot be empty" }

      if (data.checkbox == false) { validationErr.checkbox = "Checkbox cannot be empty" }
      // console.log("validation object", validationErr)
      return validationErr;
    } else {
      if (data.loginemail == "") { validationErr.loginemail = "Email cannot be empty" }
      else if (data.loginemail != "") {
        if (!emailRegex.test(data.loginemail)) { validationErr.loginemail = "Enter valid email" }
      }

      if (!data.loginpassword) { validationErr.loginpassword = "password cannot be empty" }

      if (data.checkbox == false) { validationErr.checkbox = "Checkbox cannot be empty" }
      // console.log("validation object", validationErr)
      return validationErr;
    }



  }




  // const responseFacebook = async(response)=>{

  //   console.log("response",response)
  //   if(response?.email){
  //     console.log("emial resp",response?.email)


  //   }else{

  //   }
  // }


  // function for get google user details 

  const getGoogleUserDetails = async (token, addr) => {

    // call for google Api 
    var data = await getGoogleAccountDetails(token)

    if (data) {
      // console.log("wallet address", Wallet_Details.UserAccountAddr)
      var googledata = {
        googlename: data.name,
        googleid: data.id,
        googlemail: data?.email.toLowerCase(),
        filter: "google",
        useraddress: addr
      }
      // console.log("payload google dtat", googledata)

      var resp = await signinAuth(googledata);
      if (resp?.status) {
        localStorage.setItem("loggedinaddress", resp.logaddress)
        toast.success(resp.msg)
        // window.$("#closeregister").click();
        // window.$("#closelogin").click();
        GetCountry()

        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
        dispatch({
          type: Account_Connect,
          Account_Detail: {
            LoginAddresss: Wallet_Details.UserAccountAddr,
            LoginMail: resp.logaddress
          }
        })

      }
      else {
        toast.error(resp.msg)
        // window.$("#closeregister").click();
        // window.$("#closelogin").click();


      }



    } else return toast.error("oops ! cannot log in .")



  }

  // function for get FB user details 
  const getfbUserDetails = async (data) => {
    // console.log("google access_token", data)

    // setgooglelogin(false);
    // setfblogin(false);

    var fbdata = {
      googlename: data.name,
      googleid: data.id,
      googlemail: data?.email.toLowerCase(),
      filter: "fb",
      useraddress: Wallet_Details.UserAccountAddr
    }
    // console.log("payload fb dtat", fbdata)
    var resp = await signinAuth(fbdata);
    if (resp?.status) {
      localStorage.setItem("loggedinaddress", resp.logaddress)
      toast.success(resp.msg)
      // window.$("#closeregister").click();
      // window.$("#closelogin").click();
      GetCountry()

      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
      dispatch({
        type: Account_Connect,
        Account_Detail: {
          LoginAddresss: Wallet_Details.UserAccountAddr,
          LoginMail: resp.logaddress
        }
      })

    }
    else {
      toast.error(resp.msg)
      // window.$("#closeregister").click();
      // window.$("#closelogin").click();


    }

  }


  const getTwitterUserdetails = async (data) => {
    // console.log("google access_token", data)

    // setgooglelogin(false);
    // setfblogin(false);

    var fbdata = {
      fbname: data.name,
      fbid: data.id,
      fbemail: data?.email.toLowerCase(),
      filter: "Tw",
      useraddress: Wallet_Details.UserAccountAddr
    }
    // console.log("payload fb dtat", fbdata)
    var resp = await signinAuth(fbdata);
    if (resp?.status) {
      localStorage.setItem("loggedinaddress", resp.logaddress)
      toast.success(resp.msg)
      // window.$("#closeregister").click();
      // window.$("#closelogin").click();


      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
      dispatch({
        type: Account_Connect,
        Account_Detail: {
          LoginAddresss: Wallet_Details.UserAccountAddr,
          LoginMail: resp.logaddress
        }
      })

    }
    else {
      toast.error(resp.msg)
      // window.$("#closeregister").click();
      // window.$("#closelogin").click();


    }

  }

  const forgotpasswordcall = async () => {
    // console.log("mailrecover", recoveryMail)

    var errors = {};
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;


    setDisBtn(true)
    if (isEmpty(recoveryMail)) {
      return toast.error("Email field is required");
      setDisBtn(false)
    } else if (!(emailRegex.test(recoveryMail))) {
      return toast.error("Email is invalid");
      setDisBtn(false)
    }

    var payload = {
      mail: recoveryMail.toLowerCase(),
      address: Wallet_Details.UserAccountAddr
    }
    localStorage.setItem("ForgetMail", recoveryMail.toLowerCase());
    var resp = await forgotPassswordfn(payload);
    // console.log("response frontend", resp)
    if (resp?.status) {
      setDisBtn(true)
      toast.success(resp.msg)
      window.$("#closeregisterForget").click();
      window.$('#mailSentModal').modal('show');
      // setTimeout(() => {
      //   window.$("#closeregisterMail").click();
      //   window.$('#insertingPwmodalnew').modal('show');
      // }, 10000);
      dispatch({
        type: Account_Connect,
        Account_Detail: {
          LoginAddresss: Wallet_Details.UserAccountAddr,

        }
      })

    } else {
      toast.error(resp.msg)
      setDisBtn(false)
    }



  }


  const ResendOTP = async () => {

    var Forgetmail = localStorage.getItem("ForgetMail");
    console.log("ForgetmailForgetmail", Forgetmail);
    var payload = {
      mail: Forgetmail.toLowerCase(),
      address: Wallet_Details.UserAccountAddr
    }
    localStorage.setItem("ForgetMail", recoveryMail.toLowerCase());
    var resp = await forgotPassswordfn(payload);
    // console.log("response frontend", resp)
    if (resp?.status) {
      toast.success(resp.msg)
      // window.$("#closeregisterForget").click();
      // window.$('#mailSentModal').modal('show');
      // setTimeout(() => {
      //   window.$("#closeregisterMail").click();
      //   window.$('#insertingPwmodalnew').modal('show');
      // }, 10000);
      dispatch({
        type: Account_Connect,
        Account_Detail: {
          LoginAddresss: Wallet_Details.UserAccountAddr,

        }
      })

    } else {
      toast.error(resp.msg)

    }
  }

  const initialConnectWallet = async (type, register) => {
    if (localStorage.loggedinaddress == "" || localStorage.loggedinaddress == undefined) {
      toast.error("please login")
      history.push('/')
    } else {
      var toasttxt = null
      if (type === 'mt') {
        toasttxt = "Unlock Metamask & Refresh Page"
      }
      else {

        toasttxt = "Wallet Connect Checking"
      }
      var networkConfiguration = {}
      const id = toast.loading(`${toasttxt}`, { closeButton: true, theme: "dark" });
      console.log("connecttype............", type, config.Chainid)
      var accountDetails = await connectWallet(type, config.Chainid, register)
      console.log("accountDetails......................", accountDetails?.accountAddress, accountDetails?.coinBalance, accountDetails?.tokenBalance, accountDetails)
      if (accountDetails?.LMWallet) {
        localStorage.setItem("ChainId", config.Chainid)
        dispatch({
          type: Account_Connect,
          Account_Detail: {
            LMWallet: accountDetails?.LMWallet,
          }
        })
      }
      if (accountDetails.accountAddress !== undefined && accountDetails.accountAddress !== "undefined" && accountDetails.accountAddress !== null) {
        var data1 = {
          curradd: accountDetails.accountAddress, email: localStorage.loggedinaddress
        }
        var users = await userget(data1)
        console.log("ajksdkjahfdjklakfad", users);
        var alreadyuser = users?.data?.data?.users
        if (users?.data?.data?.message == "Allow") {
          if (alreadyuser !== null && (alreadyuser?.curraddress.length > 0 || alreadyuser?.curraddress.length == 0)) {
            if (String(localStorage?.loggedinaddress) === String(alreadyuser?.signemail) || localStorage?.loggedinaddress === undefined) {
              var data = {
                addr: String(accountDetails.accountAddress).toLowerCase(),
                from: register ? register : '',
                email: localStorage.loggedinaddress
              }
              console.log("asdsadasfdasfdf", data);

              var Resp = await AddressUserDetails_GetOrSave_Action(data);

              console.log("Resp in headerLinks", Resp);
              if (Resp.data.data.RetData.Message === "already registered") {
                // console.log("fnmfghasdasmfhjm", Resp);
                // toast.error(`Email invalid "you already registered with ${Resp.data.data.RetData.User.signemail}"`)
                toast.update(id, {
                  render: `Email invalid "you already registered with ${Resp.data.data.RetData.User.signemail}"`,
                  type: "error",
                  isLoading: false,
                  autoClose: 2500,
                  closeButton: true,
                  theme: "dark"
                });
                setTimeout(() => {
                  logoutfunction()
                }, 500);
              }
              else if (Resp.data.data.RetData.Message === "not registered") {
                // console.log("asdasdsadasczxczdasdas", Resp);
                //toast.error(`please register to login`)
                toast.update(id, {
                  render: "please register to login",
                  type: "error",
                  isLoading: false,
                  autoClose: 2500,
                  closeButton: true,
                  theme: "dark"
                });
                setTimeout(() => {
                  logoutfunction()
                }, 500);
              }
              else {
                if (Resp?.data?.data?.RetData?.User?.curraddress) {
                  localStorage.setItem("userAddress", String(accountDetails.accountAddress).toLowerCase())
                } else {
                  localStorage.clear()
                  //toast.error("Please Login Again.Error in Getting Address")
                  toast.update(id, {
                    render: "Please Login Again.Error in Getting Address",
                    type: "error",
                    isLoading: false,
                    autoClose: 2500,
                    closeButton: true,
                    theme: "dark"
                  });
                  setTimeout(() => {
                    logoutfunction()
                  }, 500);
                }
                console.log("sdfsdfsfsdfdsfsd", Resp?.data?.data?.RetData?.User?.curraddress);
              }
              if (Resp && Resp.data && Resp.data.data && Resp.data.data.RetData.User) {
                // console.log("sdfasfsdfsdfsdfsdf");
                if (accountDetails?.accountAddress !== '' || Resp.data.data.RetData.User.LocalAddr) {
                  var sendData = accountDetails?.accountAddress == null || accountDetails?.accountAddress == "" || accountDetails?.accountAddress == undefined ? Resp.data.data.RetData.User.LocalAddr : accountDetails?.accountAddress
                  var AddressUserDetails = await FreePackSubScribe({ Address: sendData });
                  console.log("asdasdsadasdasd", AddressUserDetails);
                }
                if (accountDetails != '') {
                  console.log("Wallet Connrgbgtyhbyhnhyyygtgt5hy6huh", accountDetails);
                  const IDcheck = await accountDetails?.web3?.eth?.getChainId();
                  // console.log("cnzlmc.,zmmcx.zxc", IDcheck);

                  Serfee(Number(IDcheck));
                  if (IDcheck === network.MATIC.Chainid) {
                    networkConfiguration = network.MATIC
                  }
                  else if (IDcheck === network.ETH.Chainid) {
                    networkConfiguration = network.ETH
                  }
                  else if (IDcheck === network.CAM.Chainid) {
                    networkConfiguration = network.CAM
                  }
                  Serfee(String(config.Chainid));
                  dispatch({
                    type: Account_Connect,
                    Account_Detail: {
                      UserAccountAddr: accountDetails.accountAddress,
                      UserAccountBal: accountDetails.coinBalance,
                      WalletConnected: "true",
                      Wen_Bln: accountDetails.tokenBalance,
                      Accounts: accountDetails.accountAddress,
                      Web3Pro: accountDetails?.web3,
                      providerss: accountDetails?.web3?._provider,
                      web3auth: accountDetails?.web3auth,
                      networkConfiguration: networkConfiguration
                      // AddressUserDetails: AddressUserDetails,

                    }
                  })

                }
                if (accountDetails.accountAddress) {
                  cookies.set('LoginAddress', accountDetails.accountAddress, { path: '/', domain: '.lazyminter.com' });
                  cookies.set('LoginAddress1', accountDetails.accountAddress);
                  cookies.set('LoginMail', localStorage.loggedinaddress, { path: '/', domain: '.lazyminter.com' });
                  // localStorage.setItem('user_token', Resp?.data?.token);
                  cookies.set('LoginMail1', localStorage.loggedinaddress);
                  toast.update(id, {
                    render: "Wallet Connected Succesfully",
                    type: "success",
                    isLoading: false,
                    autoClose: 2500,
                    closeButton: true,
                    theme: "dark"
                  });
                  window.$("#closeregister").click();
                }

                return Resp.data.data.RetData.User
              } else {
                // console.log("else return null");
                return null
              }
              // }
            }
            else {
              // console.log("asdasdasdsadasd");
              //toast.error("please register to login")
              toast.update(id, {
                render: "please register to login",
                type: "error",
                isLoading: false,
                autoClose: 2500,
                closeButton: true,
                theme: "dark"
              });

              setTimeout(() => {
                logoutfunction()
              }, 500);

            }

          }
          else if (alreadyuser === null || alreadyuser?.curraddress !== accountDetails?.accountAddress) {
            // console.log("gfnsfgnsghf");
            // toast.error("email or address mismatch please login with correct credentials")
            // window.$("#triggerlogin").click();
            toast.update(id, {
              render: "email or address mismatch please login with correct credentials",
              type: "error",
              isLoading: false,
              autoClose: 2500,
              closeButton: true,
              theme: "dark"
            });
            setTimeout(() => {
              logoutfunction()
            }, 500);
          }
          else {

          }
        } else {
          // toast.error("Your are restricted by admin. Contact admin for further deatils")
          toast.update(id, {
            render: "Your are restricted by admin. Contact admin for further deatils",
            type: "error",
            isLoading: false,
            autoClose: 2500,
            closeButton: true,
            theme: "dark"
          });
          setTimeout(() => {
            logoutfunction()
          }, 4000);
        }


      } else {
        toast.update(id, {
          render: "We Unable to Get Provide.Please Try it Again",
          type: "error",
          isLoading: false,
          autoClose: 2500,
          closeButton: true,
          theme: "dark"
        });
        setTimeout(() => {
          logoutfunction()
        }, 1000);
      }
    }

  }

  useEffect(() => {
    console.log("Wallet_Details.UserAccousjkdntAddr", Wallet_Details, localStorage.loggedinaddress);
    if(localStorage.userAddress){
    cookies.set('ClaimerCookie', localStorage.userAddress, { path: '/', domain: '.lazyminter.com',    sameSite: 'None', secure: true  });
    cookies.set('LoginMail', localStorage.loggedinaddress, { path: '/', domain: '.lazyminter.com',    sameSite: 'None', 
    secure: true  });

    // localStorage.setItem('user_token', Resp?.data?.token);
    cookies.set('LoginMail1', Wallet_Details.LoginAddresss);
  }
  },[Wallet_Details])
  console.log("Wallet_Details",Wallet_Details);

  const Serfee = async (Address) => {
    var Fee = await getServiceFees(Address)
    console.log("ndkdsnfkdsn", Fee);
    dispatch({
      type: Account_Connect,
      Account_Detail: {
        Service_Fee: Fee?.Serfee,
        Service_Fee_Fraction: Fee?.SerfeeFraction
      }
    })
  }

  const Disconnect = async (a) => {
    dispatch({
      type: Account_disConnect,
      Account_Disconnect: {
        UserAccountAddr: "",
        UserAccountBal: 0,
        providerss: null,
        Service_Fee: 0,
        Wen_Bln: 0,
        Accounts: "",
        WalletConnected: "",
        AddressUserDetails: null,
        tokenAddress: null,
        swapFee: 0,
      },
    });
    if (a === "click")
      toast.success("Wallet Disconnected", { autoClose: 3000, theme: "dark" });
    if (
      localStorage.walletconnect != null &&
      localStorage.walletConnectType == "wc"
    ) {
      await Wallet_Details.providerss.disconnect();
      localStorage.removeItem("walletconnect");
    }
    localStorage.removeItem("walletConnectType");
    localStorage.removeItem("accountInfo");
    localStorage.removeItem("user_token")
    // localStorage.removeItem("loggedinaddress");
    // localStorage.removeItem("homepopblock");
  };





  const logoutfunction = () => {
    console.log("eqweqweqw");
    localStorage.removeItem("loggedinaddress")
    localStorage.removeItem("homepopblock")
    localStorage.removeItem("userAddress")
    localStorage.removeItem("user_token")
    localStorage.clear()
    toast.success("Sucessfully logout")
    // setTimeout(() => {
    // window.location.reload()

    Disconnect()
    history.push('/')
    setTimeout(() => {
      window.location.reload()
    }, 500);
    // }, 1000);
    window.$("#triggerlogin").click();
  }


  //fuction to get geo Location 
  const GetCountry = async () => {

    var County = await axios.get(config.GeoLocation)

    var data1 = {
      email: localStorage.loggedinaddress,
      Country: County.data.country_name,
      country_code: County.data.country_code
    }
    console.log("27.03.2023 - 28.03.2023", data1);
    var UserCountryExist = await usergetUpdateCountry(data1)

  }
  const termsModalHide = () => {
    document.getElementById("closeregister").click();
  }


  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  const togglePassword1 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text")
      return;
    }
    setPasswordType1("password")
  }

  const togglePassword2 = () => {
    if (passwordType2 === "password") {
      setPasswordType2("text")
      return;
    }
    setPasswordType2("password")
  }



  return (
    <AppBar className={appBarClasses}>
      <Button className="create_btn btn-block w-25 d-none" data-toggle="modal" data-target="#register_modal" id="triggerregister" ></Button>
      <Toolbar className="container-fluid">
        {leftLinks !== undefined ? brandComponent : null}
        <div className={classes.flex}>
          {leftLinks !== undefined ? (
            <Hidden mdDown implementation="css">
              {leftLinks}
            </Hidden>
          ) : (
            brandComponent
          )}
        </div>

        <Hidden mdDown implementation="css">
          {rightLinks}
        </Hidden>
        <Hidden lgUp>
          <IconButton
            className="hamburger_icon"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden lgUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper + " mobile_nav"
          }}
          onClose={handleDrawerToggle}
        >
          <div className={classes.appResponsive}>
            {leftLinks}
            {rightLinks}
          </div>
        </Drawer>
      </Hidden>

      <div className="modal fade primary_modal" id="register_modal" tabIndex="-1" role="dialog" aria-labelledby="register_modalCenteredLabel" aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false">

        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content" >

            <div className="modal-body" id="home_main_modal">
              <div className="row main_modal_row">
                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-0 col-0 whole_left_modal">

                  <div className="left_modal_content">
                    <h2><span className="italic_text">Take</span> a <br /> 5 mins<br />  tour</h2>
                    <p className="left_modal_clarifytext">Before your proceed, we would recommend <br /> you to take a quick tour to understand our<br /> easy onboarding process and some<br /> features on your platform.</p>

                    <Link type="button" onClick={() => window.open("https://scribehow.com/page-embed/LazyMinter_Onboarding_Manual__9nYdqH49Q0C3iX-bWOPiww")} className="quicktour_btn mt-4">QUICK TOUR</Link>
                    <div className="modal_left_ul mt-5">
                      <ul>
                        <li><Link className="main_modal_li_links" onClick={() => HomeRedirect("closeregister")}>Home</Link></li>
                        <li><Link className="main_modal_li_links" onClick={() => window.open("https://scribehow.com/page-embed/LazyMinter_Onboarding_Manual__9nYdqH49Q0C3iX-bWOPiww")}>Tour</Link></li>
                        <li><Link className="main_modal_li_links" to='/explore/All'>Browse</Link></li>
                        <li><Link className="main_modal_li_links" to='/how-it-works'>About</Link></li>
                      </ul>
                    </div>
                  </div>


                </div>
                <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12  whole_right_modal">

                  <div className="right_modal_content">
                    <div className="top_btn_dtl mb-3">
                      <Link type="button" onClick={() => window.open("https://scribehow.com/page-embed/LazyMinter_Onboarding_Manual__9nYdqH49Q0C3iX-bWOPiww")} className="top_quicktour_btn">QUICK TOUR</Link>
                      <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close" id="closeregister">
                        <span className="main_modal_close_btn" >X</span>
                      </button>
                      <div className="whiteline_div"></div>

                    </div>
                    {Wallet_Details.LoginMail == "" ?
                      <>
                        <h6 className="right_modal_clarifytext"><span className="welcome_text">Welcome</span> to LazyMinter, a gateway<br /> for you to onboard Web3 seamelessly.</h6>
                        <LoginSocialGoogle
                          isOnlyGetToken className="googlelogin mt-2"
                          client_id={"356687184598-fohltmg0n45fchsn5qbaovbrm6j651s1.apps.googleusercontent.com"}

                          scope="openid profile email"
                          onResolve={({ provider, data }) => {



                            if (data?.access_token) {
                              getGoogleUserDetails(data.access_token, Wallet_Details.UserAccountAddr)
                            }
                          }}
                          onReject={(err) => {

                            return toast.error("oops ! cannot sign in.")
                          }}
                        >
                          <GoogleLoginButton />
                        </LoginSocialGoogle>

                        <LoginSocialFacebook

                          appId={"542877597559611"}
                          className="fblogin mt-2"
                          fieldsProfile={
                            'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                          }

                          onResolve={({ provider, data }) => {


                            console.log("response facebook data", data)
                            getfbUserDetails(data);


                          }}
                          onReject={err => {

                            return toast.error("oops! cannot sign in .")
                          }}
                        >
                          <FacebookLoginButton />
                        </LoginSocialFacebook>


                        <center className="mt-2"><span>or</span></center>
                        <div className="input-group mt-2 mb-2 main_modal_inputgrp" >
                          <div className="input-group-prepend main_modal_preprend">
                            <span className="input-group-text" id="basic-addon1"><i className="fa fa-envelope" aria-hidden="true"></i></span>
                          </div>
                          <input type="text" id="signemail" className="form-control main_modal_input" placeholder="email" aria-label="Username" value={formValue.signemail} onChange={(e) => handlechange1(e)} autoComplete="off" aria-describedby="basic-addon1" />
                        </div>
                        <span className="error_display_text">{validErrors.email}</span>

                        <div className="input-group mb-3 main_modal_inputgrp" >
                          <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1"><i className="fa fa-lock" aria-hidden="true"></i></span>
                          </div>
                          <input type={passwordType} id="signpassword" className="form-control" placeholder="Password" value={formValue.signpassword} onChange={(e) => handlechange1(e)} aria-label="password" aria-describedby="basic-addon1" />
                          <span className="pwd_eye" onClick={togglePassword}>
                            {passwordType === "password" ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}</span>
                        </div>
                        <span className="error_display_text">{validErrors.password}</span><br />


                        <input type="checkbox" id="checkbox" value="checkbox" onChange={(e) => handlechangeCheckBox(e)} />


                        <label className="text-white mb-0 pb-0" for="statement"> I agree to all statements included in </label>


                        <Link className="terms_link" to='/terms-and-conditions' onClick={termsModalHide}>Terms of Conditions and Use</Link><br />

                        <span className="error_display_text">{validErrors.checkbox}</span>
                        <div className="d-flex justify-content-between align-items-center">
                          <button className="main_modal_login" onClick={() => loginsubmit()}>LOGIN</button>
                          <button className="main_modal_login" onClick={(() => handleSubmit())}>REGISTER</button>
                        </div>
                        <center>
                          <span className="help_text_dtl mt-3">Never received or forgot your passowrd?</span><br />
                          <Link type="button" className="resetpw_link" data-toggle="modal" data-target="#resetpwmodal" onClick={() => hideMainModal()}>Reset Password</Link><br />
                       
                        </center>
                      </>
                      :
                      <>
                        <h6 className="fox_valey_dtl_toptxt">Next,<br /><span> you will need <br />to create or link<br /> a wallet </span></h6>
                        <div className='row mt-3'>
                          <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12'>
                            <div className='venly_side mb-3'>
                              <h5>LM wallet</h5>
                              <span>(Custodial)</span>
                              <div className='venly_img'
                                onClick={() => initialConnectWallet("LMWallet", 'register')}
                              // onClick={() => TorusWalletConnect("Venly", 'register')}
                              >
                                <img src={loginlogo} />
                              </div>

                            </div>
                            <div className='venly_side mb-3'>
                              <h5>Torus</h5>
                              <span>(Custodial)</span>
                              <div className='venly_img'
                                onClick={() => initialConnectWallet("TorusWallet", 'register')}
                              // onClick={() => TorusWalletConnect("Venly", 'register')}
                              >
                                <img src={Venly} />
                              </div>
                            </div>
                          </div>
                          <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12'>
                            <div className='metamask_side mb-3'>
                              <h5>Metamask</h5>
                              <span>(Non-Custodial)</span>
                              {console.log("adsadasdasdasd", window.ethereum, window.web3)}
                              {window.ethereum !== undefined && window.web3 !== undefined ?
                                <div className='image_fox' onClick={() => initialConnectWallet("MetaMask", 'register')}>
                                  <img src={fox} />
                                </div>
                                :
                                <div className='image_fox'>
                                  <a href="https://metamask.app.link/dapp/lazyminter.com/home">
                                    <img src={fox} />
                                  </a>
                                </div>
                              }
                            </div>
                            <div className='metamask_side mb-2'>
                              <h5>Connectwallet</h5>
                              <span>(Custodial)</span>
                              <div className='image_fox' onClick={() => initialConnectWallet("WalletConnect", "register")}>
                                <img src={require("../../assets/images/wallet_03.png")} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Reset password or forgot password modal details */}
      <div className="modal fade primary_modal" id="resetpwmodal" tabIndex="-1" role="dialog" aria-labelledby="register_modalCenteredLabel" aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false">

        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content" >

            <div className="modal-body" id="home_main_modal">
              <div className="row main_modal_row">
                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-0 col-0 whole_left_modal">

                  <div className="left_modal_content">
                    <h2><span className="italic_text">Take</span> a <br /> 5 mins<br />  tour</h2>
                    <p className="left_modal_clarifytext">Before your proceed, we would recommend <br /> you to take a quick tour to understand our<br /> easy onboarding process and some<br /> features on your platform.</p>
                    <Link type="button" onClick={() => window.open("https://scribehow.com/page-embed/LazyMinter_Onboarding_Manual__9nYdqH49Q0C3iX-bWOPiww")} className="quicktour_btn mt-4">QUICK TOUR</Link>
                    <div className="modal_left_ul mt-5">
                      <ul>
                        <li><Link className="main_modal_li_links" onClick={() => HomeRedirect("closeregisterForget")}>Home</Link></li>
                        <li><Link className="main_modal_li_links" onClick={() => window.open("https://scribehow.com/page-embed/LazyMinter_Onboarding_Manual__9nYdqH49Q0C3iX-bWOPiww")}>Tour</Link></li>
                        <li><Link className="main_modal_li_links" to='/explore/All'>Browse</Link></li>
                        <li><Link className="main_modal_li_links" to='/how-it-works'>About</Link></li>
                      </ul>
                    </div>
                  </div>


                </div>
                <div className="col-xl-5 col-lg-5  col-sm-12 col-12  whole_right_modal">

                  <div className="right_modal_content">
                    <div className="top_btn_dtl mb-3">
                      <Link type="button" onClick={() => window.open("https://scribehow.com/page-embed/LazyMinter_Onboarding_Manual__9nYdqH49Q0C3iX-bWOPiww")} className="top_quicktour_btn">QUICK TOUR</Link>
                      <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close" id="closeregisterForget">
                        <span className="main_modal_close_btn" >X</span>
                      </button>
                      <div className="whiteline_div"></div>

                    </div>
                    <h6 className="resetpw_modal_clarifytext">We'll send you an mail with One Time Password(OTP)</h6>

                    <div class="input-group mt-2 mb-2 main_modal_inputgrp" >
                      <div class="input-group-prepend main_modal_preprend">
                        <span class="input-group-text" id="basic-addon1"><i class="fa fa-envelope" aria-hidden="true"></i></span>
                      </div>
                      <input type="text" class="form-control main_modal_input" id="email_username" placeholder="email" value={recoveryMail} onChange={(e) => { setRecoveryMail(e.target.value) }} />
                    </div>
                    <div className="my-3">
                      <button type="button" className="top_quicktour_btn main_password_submit_btn w-100" disabled={DisBtn} onClick={() => forgotpasswordcall()} >SUBMIT</button>
                    </div>
                  </div>



                </div>
              </div>
            </div>
          </div>



        </div>

      </div>
      {/* end of Reset password or forgot password modal details */}
      {/* Inserting password Modal */}
      <div class="modal fade" id="insertingPwmodalnew" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm">
          <div class="modal-content">


            <div className="modal-body" id="home_main_modal">
              <div className="row main_modal_row">
                <div className="col-xl-7 col-lg-7 col-md-0 col-sm-0 col-0 whole_left_modal">

                  <div className="left_modal_content">
                    <h2><span className="italic_text">Take</span> a <br /> 5 mins<br />  tour</h2>
                    <p className="left_modal_clarifytext">Before your proceed, we would recommend <br /> you to take a quick tour to understand our<br /> easy onboarding process and some<br /> features on your platform.</p>
                    <Link type="button" onClick={() => window.open("https://scribehow.com/page-embed/LazyMinter_Onboarding_Manual__9nYdqH49Q0C3iX-bWOPiww")} className="quicktour_btn mt-4">QUICK TOUR</Link>
                    <div className="modal_left_ul mt-5">
                      <ul>
                        <li><Link className="main_modal_li_links" onClick={() => HomeRedirect("closeregisterChange")}>Home</Link></li>
                        <li><Link className="main_modal_li_links" onClick={() => window.open("https://scribehow.com/page-embed/LazyMinter_Onboarding_Manual__9nYdqH49Q0C3iX-bWOPiww")}>Tour</Link></li>
                        <li><Link className="main_modal_li_links" to='/explore/All'>Browse</Link></li>
                        <li><Link className="main_modal_li_links" to='/how-it-works'>About</Link></li>
                      </ul>
                    </div>
                  </div>


                </div>
                <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12  whole_right_modal">

                  <div className="right_modal_content">
                    <div className="top_btn_dtl mb-3">
                      <Link type="button" onClick={() => window.open("https://scribehow.com/page-embed/LazyMinter_Onboarding_Manual__9nYdqH49Q0C3iX-bWOPiww")} className="top_quicktour_btn">QUICK TOUR</Link>
                      <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close" id="closeregisterChange">
                        <span className="main_modal_close_btn" >X</span>
                      </button>
                      <div className="whiteline_div"></div>

                    </div>
                    <p className="right_modal_clarifytext">Change your password</p>

                    <div className="input-group mt-2 mb-2 main_modal_inputgrp" >
                      <div className="input-group-prepend main_modal_preprend">
                        <span className="input-group-text" id="basic-addon1">
                          {/* <i className="fa fa-envelope" aria-hidden="true"></i> */}
                          <i class="fa-solid fa-star-of-life" aria-hidden="true"></i>
                        </span>
                      </div>
                      <input type="number" id="OTPass" className="form-control main_modal_input" onChange={(e) => EnterValue(e)} placeholder="Enter the OTP form our email" aria-label="Username" aria-describedby="basic-addon1" autoComplete="off" />
                    </div>
                    <span className="error_display_text">{validErrors.OTPass}</span>

                    <div className="input-group mt-2 mb-2 main_modal_inputgrp" >
                      <div className="input-group-prepend main_modal_preprend">
                        <span className="input-group-text" id="basic-addon1">
                          <i class="fa-solid fa-lock" aria-hidden="true"></i>
                          {/* <i className="fa fa-envelope" aria-hidden="true"></i> */}
                        </span>
                      </div>
                      <input type={passwordType1} id="PasswordOne" className="form-control main_modal_input" placeholder="Enter New Password (6 to 60 characters)" aria-label="Username" aria-describedby="basic-addon1" value={Confrimpass.PasswordOne} onChange={(e) => EnterValue(e)} autoComplete="off" />
                      <span className="pwd_eye" onClick={togglePassword1}>
                        {passwordType1 === "password" ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}</span>
                    </div>
                    <span className="error_display_text">{validErrors.PasswordOne}</span>

                    <div className="input-group mt-2 mb-2 main_modal_inputgrp" >
                      <div className="input-group-prepend main_modal_preprend">
                        <span className="input-group-text" id="basic-addon1">
                          {/* <i className="fa fa-envelope" aria-hidden="true"></i> */}
                          <i class="fa-solid fa-lock" aria-hidden="true"></i>
                        </span>
                      </div>
                      <input type={passwordType2} id="ConfrimPassword" className="form-control main_modal_input" placeholder="Re-enter New Password" aria-label="Username" aria-describedby="basic-addon1" value={Confrimpass.ConfrimPassword} onChange={(e) => EnterValue(e)} autoComplete="off" />
                      <span className="pwd_eye" onClick={togglePassword2}>
                        {passwordType2 === "password" ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}</span>
                    </div>
                    <span className="error_display_text">{validErrors.ConfrimPassword}</span>

                    {/* <Link type="button" to='/tickets' className="top_quicktour_btn main_modal_submit_btn mt-4 mb-3 w-100 text-center" >SUBMIT</Link> */}
                    <button className="top_quicktour_btn main_modal_confirm_btn mt-4 mb-3 w-100 text-center" onClick={() => SubmitPass()}>Confirm</button>

                    <button className="top_quicktour_btn main_modal_confirm_btn mt-4 mb-3 w-100 text-center" onClick={() => ResendOTP()}>Resend(OTP)</button>
                  </div>

                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      {/* End of Inserting password Modal */}
      {/* Select wallet Modal */}
      <div className="modal fade" id="selectWalletModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content" >
            <div className="modal-body" id="home_main_modal">
              <div className="row main_modal_row">
                <div className="col-xl-7 col-lg-7 col-md-0 col-sm-0 col-0 whole_left_modal">
                  <div className="left_modal_content">
                    <h2><span className="italic_text">Take</span> a <br /> 5 mins<br />  tour</h2>
                    <p className="left_modal_clarifytext">Before your proceed, we would recommend <br /> you to take a quick tour to understand our<br /> easy onboarding process and some<br /> features on your platform.</p>
                    <Link type="button" onClick={() => window.open("https://scribehow.com/page-embed/LazyMinter_Onboarding_Manual__9nYdqH49Q0C3iX-bWOPiww")} className="quicktour_btn mt-4">QUICK TOUR</Link>
                    <div className="modal_left_ul mt-5">
                      <ul>
                        <li><Link className="main_modal_li_links" onClick={() => HomeRedirect("closeregister")}>Home</Link></li>
                        <li><Link className="main_modal_li_links" onClick={() => window.open("https://scribehow.com/page-embed/LazyMinter_Onboarding_Manual__9nYdqH49Q0C3iX-bWOPiww")}>Tour</Link></li>
                        <li><Link className="main_modal_li_links" to='/explore/All'>Browse</Link></li>
                        <li><Link className="main_modal_li_links" to='/how-it-works'>About</Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12  whole_right_modal">
                  <div className="right_modal_content">
                    <div className="top_btn_dtl mb-3">
                      <Link type="button" onClick={() => window.open("https://scribehow.com/page-embed/LazyMinter_Onboarding_Manual__9nYdqH49Q0C3iX-bWOPiww")} className="top_quicktour_btn">QUICK TOUR</Link>
                      <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close" id="closeregister">
                        <span className="main_modal_close_btn" >X</span>
                      </button>
                      <div className="whiteline_div"></div>
                    </div>
                    <h6 className="fox_valey_dtl_toptxt">Next,<br /><span> you will need <br />to create or link<br /> a wallet </span></h6>
                    <div className='row mt-3'>
                      <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12'>
                        <div className='metamask_side'>
                          <h5>Metamask</h5>
                          <span>(Non-Custodial)</span>
                          <div className='image_fox'>
                            <img src={fox} />
                          </div>
                        </div>
                      </div>
                      <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12'>
                        <div className='venly_side mb-3'>
                          <h5>Venly</h5>
                          <span>(Custodial)</span>
                          <div className='venly_img'>
                            <img src={Venly} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end of select wallet modal */}
      {/* Congradulations modal */}
      <div className="modal fade" id="congradulationModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content" >

            <div className="modal-body" id="home_main_modal">
              <div className="row main_modal_row">
                <div className="col-xl-7 col-lg-7 col-md-0 col-sm-0 col-0 whole_left_modal">

                  <div className="left_modal_content">
                    <h2><span className="italic_text">Take</span> a <br /> 5 mins<br />  tour</h2>
                    <p className="left_modal_clarifytext">Before your proceed, we would recommend <br /> you to take a quick tour to understand our<br /> easy onboarding process and some<br /> features on your platform.</p>
                    <Link type="button" onClick={() => window.open("https://scribehow.com/page-embed/LazyMinter_Onboarding_Manual__9nYdqH49Q0C3iX-bWOPiww")} className="quicktour_btn mt-4">QUICK TOUR</Link>
                    <div className="modal_left_ul mt-5">
                      <ul>
                        <li><Link className="main_modal_li_links" onClick={() => HomeRedirect("closeregisterSucess")}>Home</Link></li>
                        <li><Link className="main_modal_li_links" onClick={() => window.open("https://scribehow.com/page-embed/LazyMinter_Onboarding_Manual__9nYdqH49Q0C3iX-bWOPiww")}>Tour</Link></li>
                        <li><Link className="main_modal_li_links" to='/explore/All'>Browse</Link></li>
                        <li><Link className="main_modal_li_links" to='/how-it-works'>About</Link></li>
                      </ul>
                    </div>
                  </div>


                </div>
                <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12  whole_right_modal">

                  <div className="right_modal_content">
                    <div className="top_btn_dtl mb-3">
                      <Link type="button" onClick={() => window.open("https://scribehow.com/page-embed/LazyMinter_Onboarding_Manual__9nYdqH49Q0C3iX-bWOPiww")} className="top_quicktour_btn">QUICK TOUR</Link>
                      <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close" id="closeregisterSucess">
                        <span className="main_modal_close_btn" >X</span>
                      </button>
                      <div className="whiteline_div"></div>

                    </div>
                    <h6 className="congrats_txt_main_modal mt-5">Congratulations, you have changed your password successfully.</h6>

                    <span className="main_modal_rdrct_text mb-3 mt-5">Redirect to Login</span>










                  </div>

                </div>
              </div>
            </div>
          </div> */
        </div>
      </div>
      {/* end of congradulations modal */}
      {/* email sent modal */}
      <div className="modal fade" id="mailSentModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content" >
            <div className="modal-body" id="home_main_modal">
              <div className="row main_modal_row">
                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-0 col-0 whole_left_modal">
                  <div className="left_modal_content">
                    <h2><span className="italic_text">Take</span> a <br /> 5 mins<br />  tour</h2>
                    <p className="left_modal_clarifytext">Before your proceed, we would recommend <br /> you to take a quick tour to understand our<br /> easy onboarding process and some<br /> features on your platform.</p>
                    <Link type="button" onClick={() => window.open("https://scribehow.com/page-embed/LazyMinter_Onboarding_Manual__9nYdqH49Q0C3iX-bWOPiww")} className="quicktour_btn mt-4">QUICK TOUR</Link>
                    <div className="modal_left_ul mt-5">
                      <ul>
                        <li><Link className="main_modal_li_links" onClick={() => HomeRedirect("closeregisterMail")}>Home</Link></li>
                        <li><Link className="main_modal_li_links" onClick={() => window.open("https://scribehow.com/page-embed/LazyMinter_Onboarding_Manual__9nYdqH49Q0C3iX-bWOPiww")}>Tour</Link></li>
                        <li><Link className="main_modal_li_links" to='/explore/All'>Browse</Link></li>
                        <li><Link className="main_modal_li_links" to='/how-it-works'>About</Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12  whole_right_modal">
                  <div className="right_modal_content">
                    <div className="top_btn_dtl mb-3">
                      <Link type="button" onClick={() => window.open("https://scribehow.com/page-embed/LazyMinter_Onboarding_Manual__9nYdqH49Q0C3iX-bWOPiww")} className="top_quicktour_btn">QUICK TOUR</Link>
                      <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close" id="closeregisterMail">
                        <span className="main_modal_close_btn" >X</span>
                      </button>
                      <div className="whiteline_div"></div>
                    </div>
                    <h5 className='email_sent_text'>Email Sent </h5>
                    <h6 className="email_sent_text_dtl mt-3">{`An email with instructions on how to reset your password has been sent to ${localStorage.ForgetMail}. Check your spam or junk before if you dont see the email in your inbox.`}</h6>
                    <h6 className='subPara_text mt-4'>If you no longer have access to this email account, please <span className='contactus_textin_modal'>contact us.</span></h6>
                    <center>
                    </center>
                    <center>
                      <Button type="button" className="resetpw_link  main_modal_nxt_btn" data-toggle="modal" data-target="#insertingPwmodalnew" onClick={() => hideMainModal12()}>Next</Button>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end email sent modal */}
      <div className="modal fade primary_modal" id="pwd_modal" tabIndex="-1" role="dialog" aria-labelledby="pwd_modalCenteredLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="pwd_modalLabel">Forgot Password</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="close_pwd_model">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p className="forgot_desc">Enter your username / email address, and we'll send you a password reset email.</p>
              <form className="login_form">
                <label htmlFor="email_username">Username / Email Address</label>
                <div className="mb-3">
                  <input type="text" id="email_username" className="form-control" placeholder="Enter username / email" value={recoveryMail} onChange={(e) => { setRecoveryMail(e.target.value) }} />
                </div>

                <div className="text-center mt-3">
                  <Button className="create_btn btn-block" onClick={() => forgotpasswordcall()}>Submit</Button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </AppBar>

  );
}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.object,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired



  })


};
