import React,{ useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { useMintReceipt, useTokenAppReceipt, usePurchaseReceipt, useAcceptBidReceipt, useBurnReceipt, useCancelOrderReceipt, usePutonSaleReceipt } from "hooks/useReceipts";
import { useAllowance, useNFTBalCheck, useSignCall } from "hooks/useMethods";
import config from 'lib/config'
import { useCancelOrder } from "hooks/useMethods";
export default function TestPage(props) {
    const Wallet_Details = useSelector((state) => state.wallet_connect_context);
    const [alll, setalll] = useState(0) 
    useEffect(()=>{
        console.log("Initial Errr",Wallet_Details)
    },[Wallet_Details])

    var MintArg = {TokenPrice:"0.01",Ipfs:"dbsfkjsnbkn",quantity:"1",royalties:"2",name:"useMintTest",Type:1155}
    var BurnArg = {NFTId:1643364826,NFTOwner:("0x025c1667471685c323808647299e5DbF9d6AdcC9").toLowerCase(),Type:721,NoofNFTs:1}
    var PurArg = {NFTOwner:("0x0E594130ee702Ff9668061B6694C6683665E8188").toLowerCase(),NFTId:1643385256,NoofNFTs:1,PriceWithoutSerfee:"10000000000000000",PriceWithSerfee:"10250000000000000",Type:1155,Coinname:config.tokenSymbol}
    var TokAppArg = {PriceWithoutSerfee:"10000000000000000",PriceWithSerfee:"10250000000000000",Type:1155,Coinname:config.tokenSymbol}
    var AllArg = {Type:721,Coinname:config.tokenSymbol}
    var BalArg = {NFTId:1643385256,Type:1155,NFTConAddress:config.multipleContract ,NFTOwner:("0x025c1667471685c323808647299e5DbF9d6AdcC9").toLowerCase()}
    var AccArg = {Coinname:config.tokenSymbol,BidderAddress:("0xce608edc38d4D175FeEf81c00157a85Ca8D5Da13").toLowerCase(),PriceWithoutSerfee:"10000000000000000",NFTId:1643369917,Type:721,NoofNFTs:1}
    var CanArg = {Type:1155,NFTId:1643385256}
    var PutArg = {Type:1155,NFTId:1643385256,PriceWithoutSerfee:"10000000000000000"}
    const UseMint = async() =>{
        var resp = await useMintReceipt(MintArg,MintArg.Type,Wallet_Details)
        // console.log("Respfrom Hook",resp)
    }
    const UseToken = async() =>{
        var resp = await useTokenAppReceipt(TokAppArg,TokAppArg.Type,Wallet_Details)
        // console.log("Respfrom Hook",resp)
    }
    const UsePur = async() =>{
        var resp = await usePurchaseReceipt(PurArg,PurArg.Type,Wallet_Details,false)
        // console.log("Respfrom Hook",resp)
    }
    const UseAcc = async() =>{
        var resp = await useAcceptBidReceipt(AccArg,AccArg.Type,Wallet_Details)
        // console.log("Respfrom Hook",resp)
    }
    const UseBu = async() =>{
        var resp = await useBurnReceipt(BurnArg,BurnArg.Type,Wallet_Details)
        // console.log("Respfrom Hook",resp)
    }
    const UseAll = async() =>{
        var resp = await useAllowance(AllArg,AllArg.Type,Wallet_Details)
        // console.log("Respfrom Hook",resp)
    }
    const UseNFTBal = async() =>{
        var resp = await useNFTBalCheck(BalArg,BalArg.Type)
        // console.log("Respfrom Hook",resp)
    }
    const UseSign = async() =>{
        // console.log("Respbefore Hook",Wallet_Details.UserAccountAddr)
        var resp = await useSignCall("Test",Wallet_Details.UserAccountAddr,Wallet_Details.providerss)
        // console.log("Respfrom Hook",resp,Wallet_Details.UserAccountAddr)
    }
    const UseCancel = async() =>{
        var resp = await useCancelOrderReceipt(CanArg,CanArg.Type,Wallet_Details)
        // console.log("Respfrom Hook",resp)
    }
    const UsePut = async() =>{
        var resp = await usePutonSaleReceipt(PutArg,PutArg.Type,Wallet_Details)
        // console.log("Respfrom Hook",resp)
    }

    return(
        <>
          <button onClick={()=>UseMint()}>
              Mint
            </button >
            <button onClick={()=>UseToken()}>
              app
            </button>
            <button onClick={()=>UsePur()}>
              buy
            </button>
            <button onClick={()=>UseAcc()}>
              acce
            </button>
            <button onClick={()=>UseBu()}>
              burn
            </button>
            <button onClick={()=>UseAll()}>
              {alll}
            </button>
            <button onClick={()=>UseNFTBal()}>
              NftBal
            </button>
            <button onClick={()=>UseSign()}>
              sign
            </button>
            <button onClick={()=>UseCancel()}>
              canc
            </button>
            <button onClick={()=>UsePut()}>
              put
            </button>
        </>
      )
}
