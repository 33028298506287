import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styled from "../../node_modules/styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link, useParams } from "react-router-dom";
import { ExplorePage } from "actions/v1/token";
import { useSelector } from "react-redux";
import Ticketcard from "../views/separate/ticketcard.js"
import { toast } from "react-toastify";
import {
  HomeCategory
} from '../actions/v1/token';
const Icon = styled((props) => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #3d2524;
    font-size: 24px;
  }
  & > .minus {
    display: none;
    color: #3d2524;
    font-size: 24px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Tickets(props) {
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const classes = useStyles();
  const { ...rest } = props;
  const { cat } = useParams()
  const [ExploreCardNFTs, setExploreCardNFTs] = useState({ 'loader': false, 'Web3 IDs': { page: 1, list: [], onmore: true, Vault: "Show", VaultStatus: "Explore" } });
  const [ExploreViralNFTs, setExploreViralNFTs] = useState([]);
  const [CatName, setCatName] = useState("Web3 IDs")
  const [ShowModal, setShowModal] = useState(false)
  const [ShowLoader, setShowLoader] = useState(false)
  const [HomeCategoryData, SetHomeCategory] = React.useState({})
  const [Timedata, setTime] = useState('');
  const [theme, setTheme] = useState("#000");

  useEffect(() => {

    if (document.getElementById("root").classList.contains("light_theme")) {
      setTheme("#FFF")
    }
    else {
      setTheme("#000")
    }
  }, []);

  useEffect(() => {
    setShowLoader(true)
    ExploreCardNFT()
    Timefun();
  }, [CatName, Wallet_Details.UserAccountAddr, Timedata])

  useEffect(() => {
    setShowModal(true)
    ExploreViralNFT()
  }, [Wallet_Details.UserAccountAddr])

  useEffect(() => {
    catChange(cat)
  }, [cat])

  const ExploreViralNFT = async () => {
    var Payload = { curraddress: Wallet_Details.UserAccountAddr, Category: "", Page: 1, limit: 5, Card: "viral", Vault: "Show", VaultStatus: "Explore" }
    var NFT = await ExplorePage(Payload);
    if (NFT.data.Success) {
      if (NFT.data.records.length > 0) {
        setExploreViralNFTs([])
        setExploreViralNFTs(NFT.data.records)
      }
    }
  }

  const ExploreCardNFT = async (Catpage) => {
    var Payload = { curraddress: Wallet_Details.UserAccountAddr, Category: CatName, Page: Catpage || 1, limit: 1000, Time: Timedata, Vault: "Show", VaultStatus: "Explore" }
    var NFT = await ExplorePage(Payload);
    if (NFT?.data?.Success) {
      setExploreCardNFTs(NFT?.data?.records);
    }
    else {
      toast.error("Oops..! Error Detected Refresh Page")
    }
  };

  const catChange = (Cat) => {
    if (Cat) {
      if (!ExploreCardNFTs[Cat]) {
        ExploreCardNFTs[Cat] = { page: 1, list: [], onmore: true };
        setExploreCardNFTs(ExploreCardNFTs);
      }
      setCatName(Cat)
    }
  }

  useEffect(() => {
    HomeCategoryList();
  }, [])

  const HomeCategoryList = async () => {
    var HomeCategoryData = await HomeCategory()
    var FinalData = HomeCategoryData?.data?.data?.HomeCategory
    let dataAArray = FinalData.filter(items => (items.name == "Web3 IDs"));
    SetHomeCategory(dataAArray)
  }

  async function Timefun(timefunn) {
    if (timefunn > 0) {
      setTime(timefunn)
    }
  }

  const BrowseMarketPlace = () => {
    return (
      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div className="text-center py-5">
          <p className="not_found_text">No items found</p>
          <p className="not_found_text_sub">
            Come back soon! Or try to browse something for you on our marketplace
          </p>
          <div className="mt-3">
            <Link to="/explore/All">
              <Button className="browsematdk">Browse Marketplace</Button>
            </Link>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="home_header explore">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={
          <Link to="/home">
            <div className="header_logo_align">


            </div>
          </Link>
        }
        leftLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 20,
          color: "white",
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <section className="tickets mt-5 mb-5" id="tickets">
        <div className="container-fluid custom-container">
          <div className="ticketsflesd">
            <h5>Web3 IDs</h5>
          </div>
          <div className="tickets_collection">
            {ExploreCardNFTs.length > 0 ?
              <div className="row">
                {ExploreCardNFTs.length > 0 &&
                  ExploreCardNFTs.map((Item, index) => {
                    console.log("Ticketcategory for ticket componrnt", Item);
                    return (
                      <Ticketcard
                        item={Item}

                      />
                    )
                  })}
              </div>
              :
              <BrowseMarketPlace />
            }
          </div>
        </div>

      </section>
      <Footer />
    </div>
  );
}
