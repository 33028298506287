import React, { useState, useEffect } from 'react'
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import QrReader from 'react-qr-reader'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import Chart from "react-apexcharts";
import EditProfCont from './EditProfCont';
import Events from './Events';
import FractionsDash from './FractionsDash';
import FiatRevenueDetails from './FiatRevenueDetails'
import CopyToClipboard from "react-copy-to-clipboard";
import { Grid } from '@material-ui/core';
import Exportexcel from './separate/ExportExcel'
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import config from "../lib/config"
import { getAllCounts, UserpanelSub, chartdataUserPannel, UserActivityDashBoard, RouterChecker, TicketScan, ScannedStatus, RejectedStatusChange, scannedtokenlist, AuthenticatedTokenlist, RejecctedToken, TokenReendem, TotalTicketsSold, progressbarpercent, ScannedEventStatus } from "../actions/v1/token"
import { toast, Flip } from "react-toastify";
import { Account_Connect } from "actions/redux/action";
toast.configure();

export default function UserCommonDashboard(props) {
  const dispatch = useDispatch();
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { ...rest } = props;
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const [theme, setTheme] = useState('dark');
  const [SubList, SetSubList] = useState([])
  const [catagory, SetcatChange] = useState("Year")
  const [Xaxis, setXaxis] = useState([])
  const [Yaxis, setYaxis] = useState([])
  const [properties, setProperties] = useState([])
  const [Datess, SetDatess] = useState([])
  const [yearr, Setyear] = useState([])
  const [Monthsss, SetMonthss] = useState([])
  const [resentActivity, SetRecentActivity] = useState([])
  const [counts, Setcounts] = useState([])
  const [Routeee, SetRouteeee] = useState("Not Allowed")
  const [sidebarShrink, setSidebarShrink] = useState(true);
  const [userdashb, setUserDashb] = useState(true);
  const [userprfl, setUserPrfl] = useState(false);
  const [userqrc, setUserQrc] = useState(false);
  const [campaignTool, setCampaignTool] = useState(false);
  const [RevenueTool, setRevenueTool] = useState(false);
  const [Fractions, setFractions] = useState(false);
  const [selected, setSelected] = useState("environment");
  const [startScan, setStartScan] = useState(false);
  const [loadingScan, setLoadingScan] = useState(false);
  const [AuthenticatedToken, SetAuthenticatedToken] = useState("")
  const [ApproveStatus, SetApproveStatus] = useState("");
  const [TicketDetails, SetTicketDeatils] = useState({})
  const [ErrorStatus, SetErrorStatus] = useState(false);
  const [data1, setData] = useState("");
  const [ScannedToken, SetScannedToken] = useState("")
  const [RejectedToken, SetRejecctedToken] = useState("")
  const [ReemdemToken, SetReemdemToken] = useState("")
  const [TicketsSold, SetTicketsSold] = useState("")
  const [links, SetLinks] = useState({})
  const [Bulkpercentage, setBulkpercentage] = useState("")
  const [SolBoundPercentage, SetSolBoundPercentage] = useState("")
  const [TableData, SetTableData] = useState([])
  const [exportdata, setExportdata] = useState([])
  // headerlinks consts


  const rows = TableData?.length > 0 && TableData?.map((item, index) => {
    return ({ id: index + 1, Name: item?.OwnerDetails?.name, TokenID: item.tokenID, Address: item.tokenOwner, verified: item.Rejected == "Rejected" ? "Rejected" : item.TicketStatus == "true" ? "Verified" : "Rejected", Mail: item.OwnerDetails?.mail, Bio: item.OwnerDetails?.bio })
  })

  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (

      <Pagination
        color="primary"
        shape="rounded"
        page={page + 1}
        count={pageCount}
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }

  function customCheckbox(theme) {
    return {
      '& .MuiCheckbox-root svg': {
        width: 16,
        height: 16,
        backgroundColor: 'transparent',
        border: `1px solid ${theme.palette.mode === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'
          }`,
        borderRadius: 2,
      },
      '& .MuiCheckbox-root svg path': {
        display: 'none',
      },
      '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
        backgroundColor: '#e50914',
        borderColor: '#e50914',
      },
      '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
        position: 'absolute',
        display: 'table',
        border: '2px solid #fff',
        borderTop: 0,
        borderLeft: 0,
        transform: 'rotate(45deg) translate(-50%,-50%)',
        opacity: 1,
        transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
        content: '""',
        top: '50%',
        left: '39%',
        width: 5.71428571,
        height: 9.14285714,
      },
      '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
        width: 8,
        height: 8,
        backgroundColor: '#62626214',
        transform: 'none',
        top: '39%',
        border: 0,
      },

    };
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 10 },
    {
      field: 'Name',
      headerName: 'Name',
      width: 250,
      editable: false,
      sortable: false,
    },
    {
      field: 'Mail',
      headerName: 'Mail',
      width: 250,
      editable: false,
      sortable: false,
    },
    {
      field: 'Bio',
      headerName: 'Bio',
      width: 250,
      editable: false,
      sortable: false,
    },
    {
      field: 'TokenID',
      headerName: 'TokenID',
      width: 150,
      editable: false,
      sortable: false,
    },
    {
      field: 'Address',
      headerName: 'Address',
      width: 340,
      editable: false,
      sortable: false,
    },
    {
      field: 'verified',
      headerName: 'Status',
      width: 100,
      editable: false,
      sortable: false,
    },

  ];

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: 0,
    color:
      theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    WebkitFontSmoothing: 'auto',
    letterSpacing: 'normal',
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
      borderRight: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
        }`,
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
      borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
        }`,
    },
    '& .MuiDataGrid-cell': {
      color:
        theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
    },
    '& .MuiPaginationItem-root': {
      borderRadius: 0,
    },
    '& .MuiDataGrid-footerContainer': {
      display: "flex",
      justifyContent: "center"
    },

    ...customCheckbox(theme),
  }));

  const dashboardRoutes = [];

  const showfunc = (value) => {

    if (value == "dash") {
      setUserDashb(true);
      setUserPrfl(false);
      setUserQrc(false);
      setCampaignTool(false);
      setRevenueTool(false)
      setFractions(false)
    } else if (value == "profile") {
      setUserPrfl(true);
      setUserDashb(false);
      setUserQrc(false);
      setRevenueTool(false)
      setCampaignTool(false);
      setFractions(false)
    } else if (value == "qr") {
      setUserQrc(true);
      setUserDashb(false);
      setUserPrfl(false);
      setRevenueTool(false)
      setCampaignTool(false);
      setFractions(false)
    }
    else if (value == "campaign") {
      setUserQrc(false);
      setUserDashb(false);
      setUserPrfl(false);
      setRevenueTool(false)
      setCampaignTool(true);
      setFractions(false)
    }
    else if (value == "Revenue") {
      setUserQrc(false);
      setUserDashb(false);
      setUserPrfl(false);
      setCampaignTool(false);
      setRevenueTool(true)
      setFractions(false)
    }
    else if (value == "Fractions") {
      setUserQrc(false);
      setUserDashb(false);
      setUserPrfl(false);
      setCampaignTool(false);
      setRevenueTool(false)
      setFractions(true)
    }
  }

  const data = {
    options: {
      chart: {
        id: "basic-bar",
        foreColor: "#ccc",
        toolbar: {
          autoSelected: "pan",
          show: false
        }
      },
      colors: ["#2769ba"],
      stroke: {
        width: 3
      },
      grid: {
        borderColor: "#555",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: false
          },
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.75,
          opacityTo: 0
        }
      },
      markers: {
        size: 2,
        colors: ["#2769ba"],
        strokeColor: "#2769ba",
        strokeWidth: 3
      },
      tooltip: {
        theme: "dark"
      },
      xaxis: {
        categories: Yaxis
        //[1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
      }
    },
    series: [
      {
        name: "series-1",
        data: Xaxis
        // [30, 40, 45, 50, 49, 60, 70, 91]
      },

    ]
  };
  useEffect(() => {
    Route()
  }, [Wallet_Details.UserAccountAddr])

  useEffect(() => {
    SubcriberList()
    ChartData()
    RecentActivity()
  }, [Wallet_Details.UserAccountAddr, catagory])

  const Route = async () => {
    if (Wallet_Details.UserAccountAddr) {
      var Addresss = {
        Address: Wallet_Details.UserAccountAddr
      }
      var SubscriberList = await RouterChecker(Addresss)
      if (SubscriberList?.data?.data?.Message == "Allowed") {
        SetRouteeee(SubscriberList?.data?.data?.UserBalance)
      }
    }
  }
  const SubcriberList = async () => {
    if (Wallet_Details.UserAccountAddr !== "") {
      var Addresss = {
        Address: Wallet_Details.UserAccountAddr
      }
      var SubscriberList = await UserpanelSub(Addresss)
      if (SubscriberList?.data?.data?.Message == "success") {
        SetSubList(SubscriberList?.data?.data?.userValue)
      }
    }
  }

  const ChartData = async () => {
    var SendAddress = {
      Address: Wallet_Details?.UserAccountAddr
    }
    if (Wallet_Details?.UserAccountAddr !== "") {
      var Datass = await chartdataUserPannel(SendAddress)
      var yearArr = []
      var dateArr = []
      var MonthArr = []
      var map = Datass.data.data.userValue.map((item, index) => {
        setProperties(item)
        dateArr.push(item.day)
        yearArr.push(item.year)
        MonthArr.push(item.month)
      })
      Setyear(yearArr)
      SetDatess(dateArr)
      SetMonthss(MonthArr)
      if (catagory == "Year") {
        const toFindDuplicates = yearArr => yearArr.filter((item, index) => yearArr.indexOf(item) == index)
        const duplicateElementa = toFindDuplicates(yearArr);
        var elementCounts = yearArr.reduce((count, item) => (count[item] = count[item] + 1 || 1, count), {})
        var Keyss = Object.values(elementCounts)
        var valuess = Object.keys(elementCounts)
        setXaxis(Keyss)
        setYaxis(valuess)
      }
      else if (catagory == "Month") {
        const toFindDuplicates = MonthArr => MonthArr.filter((item, index) => MonthArr.indexOf(item) == index)
        const duplicateElementa = toFindDuplicates(MonthArr);
        var elementCounts = MonthArr.reduce((count, item) => (count[item] = count[item] + 1 || 1, count), {})
        var Keyss = Object.values(elementCounts)
        var valuess = Object.keys(elementCounts)
        setXaxis(Keyss)
        setYaxis(valuess)
      }
      else if (catagory == "Day") {
        const toFindDuplicates = dateArr => dateArr.filter((item, index) => dateArr.indexOf(item) == index)
        const duplicateElementa = toFindDuplicates(dateArr);
        var elementCounts = dateArr.reduce((count, item) => (count[item] = count[item] + 1 || 1, count), {})
        var Keyss = Object.values(elementCounts)
        var valuess = Object.keys(elementCounts)
        setXaxis(Keyss)
        setYaxis(valuess)
      }
    }
  }

  const RecentActivity = async (name) => {
    var resentt = {
      Address: Wallet_Details.UserAccountAddr
    }
    if (Wallet_Details.UserAccountAddr !== "") {
      var Recent = await UserActivityDashBoard(resentt)
      if (Recent.data.data?.Message == "success") {
        SetRecentActivity(Recent?.data?.data?.userValue)
      }
    }
  }

  var CreatedTime = (Actdate) => {
    var today = new Date();
    var Christmas = new Date(Actdate);
    var diffMs = today - Christmas; // milliseconds between now & Christmas
    var diffDays = Math?.floor(diffMs / 86400000); // days
    var diffHrs = Math?.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math?.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    return diffHrs === 0 && diffDays === 0
      ? `${diffMins} minutes ago`
      : diffDays === 0
        ? `${diffHrs} hours ago`
        : `${diffDays} days ago`;
  };

  const setSidebarWidth = () => {
    setSidebarShrink(!sidebarShrink);
    if (sidebarShrink) {
      document.body.classList.remove('sidebar_shr');
    }
    else {
      document.body.classList.add('sidebar_shr');

    }
  }

  //   userprofile consts

  useEffect(() => {
    getCounts()
  }, [Wallet_Details.UserAccountAddr])


  const getCounts = async () => {
    if (Wallet_Details.UserAccountAddr) {
      var data = {
        curradd: Wallet_Details.UserAccountAddr
      }
      var counts = await getAllCounts(data)

      if (counts) {
        Setcounts(counts.data.data)
      }
    }
  }


  useEffect(() => {
    SubcriberList()
    progressbarpercentage();
  }, [Wallet_Details.UserAccountAddr])

  //   qrcode page consts

  const handleScan = async (scanData) => {
    if (scanData !== null) {
      setLoadingScan(true);
      setData(scanData);
      setStartScan(false);
      setLoadingScan(false);
      if (scanData !== null) {
        SetLinks(scanData)
        scannedtokenChange(scanData)
        dispatch({
          type: Account_Connect,
          Account_Detail: {
            ScanLinks: scanData,
          }
        })
      }
    }
  };

  const handleError = (err) => {
    console.error("Errorrrrr in scanerrrrr", err);
  };


  const TicketVerify = async (add) => {
    var slice = Wallet_Details?.ScanLinks?.split("/")
    var sendData = {
      TokenOwner: slice[5],
      tokenID: slice[6]
    }
    if (sendData.TokenOwner !== undefined) {
      var TicketStatus = await TicketScan(sendData)
      if (TicketStatus.data.success == true) {
        SetApproveStatus("Approved")
        SetLinks({})
        SetScannedToken("")
        SetErrorStatus(false)
        SetTicketDeatils({})
        TokenReendemList()
        ScanNext()
        dispatch({
          type: Account_Connect,
          Account_Detail: {
            ScanLinks: null,
          }
        })
      }
      dispatch({
        type: Account_Connect,
        Account_Detail: {
          ScanLinks: null,
        }
      })

    }

  }

  const ScanNext = async () => {
    setStartScan(true)
    SetApproveStatus("")
    SetScannedToken("")
    SetTicketDeatils("")
    SetApproveStatus("")
    SetErrorStatus(false)
    dispatch({
      type: Account_Connect,
      Account_Detail: {
        ScanLinks: null,
      }
    })

  }

  const scannedtokenChange = async (add) => {
    var slicedata = add?.split("/")
    if (slicedata && add !== null) {
      var sendData = {
        TokenOwner: slicedata[5],
        tokenID: slicedata[6],
        Creator: Wallet_Details.UserAccountAddr
      }
      if (Wallet_Details.UserAccountAddr !== "") {
        var scannedtoken = await ScannedStatus(sendData)
        if (scannedtoken.data.success == true) {
          if (scannedtoken.data.UpdateDetails.TicketStatus == "false" && scannedtoken.data.UpdateDetails.Rejected == "false") {
            SetApproveStatus("Approved")
            SetErrorStatus(true)
            SetTicketDeatils(scannedtoken.data.UpdateDetails)
          }
          else if (scannedtoken.data.UpdateDetails.TicketStatus == "true") {
            SetErrorStatus(false)
            SetApproveStatus("Invalid Ticket")
            SetTicketDeatils(scannedtoken.data.UpdateDetails)
          }
          else if (scannedtoken.data.UpdateDetails.Rejected == "Rejected") {
            SetErrorStatus(false)
            SetApproveStatus("Invalid Ticket")
            SetTicketDeatils(scannedtoken.data.UpdateDetails)
          }
          else if (scannedtoken.data.UpdateDetails.Rejected == "false" && scannedtoken.data.UpdateDetails.TicketStatus == "true") {
            SetApproveStatus("Approved")
            SetErrorStatus(true)
            SetTicketDeatils(scannedtoken.data.UpdateDetails)
          }
        }
        else if (scannedtoken.data.success == "Soul") {
          SetApproveStatus("Approved")
          SetErrorStatus(true)
          SetTicketDeatils(scannedtoken.data.UpdateDetails)
        }
        else {
          SetErrorStatus(scannedtoken.data.success)
          SetApproveStatus(scannedtoken.data.message)
        }
        ScannedEventStatusData()
      }
    }
  }

  const RejectTickets = async () => {
    var slice = Wallet_Details?.ScanLinks?.split("/")
    var sendData = {
      TokenOwner: slice[5],
      tokenID: slice[6]
    }
    var RejectStatus = await RejectedStatusChange(sendData);
    if (RejectStatus.Status == "Success") {
      toast.success("Token Rejected")
      ScanNext()
    }
    else {
      toast.error("Try it Again Later")
    }
  }

  useEffect(() => {
    ScannedEventStatusData()
  }, [Wallet_Details.ScanLinks])

  useEffect(() => {
    scannedtoken();
    AuthenticatedTokenList();
    RejectedTokenList();
    TokenReendemList();
    TokenticketsSoldList();
    ScannedEventStatusData();
  }, [ApproveStatus])

  const scannedtoken = async () => {
    var Addresss = {
      Address: Wallet_Details.UserAccountAddr
    }
    if (Wallet_Details.UserAccountAddr !== "") {
      var scannedtoken = await scannedtokenlist(Addresss)
      SetScannedToken(scannedtoken);
    }
  }

  const ScannedEventStatusData = async () => {
    var Addresss = {
      Address: Wallet_Details.UserAccountAddr
    }
    if (Wallet_Details.UserAccountAddr !== "") {
      var scannedtoken = await ScannedEventStatus(Addresss)
      SetTableData(scannedtoken?.data?.HomeCategory);
      var ddd = scannedtoken?.data?.HomeCategory.map((item, index) => {
        var dataa = { id: index + 1, Name: item?.OwnerDetails?.name, TokenID: item.tokenID, Address: item.tokenOwner, verified: item.Rejected == "Rejected" ? "Rejected" : item.TicketStatus == "true" ? "Verified" : "Rejected", Mail: item.OwnerDetails?.mail, Bio: item.OwnerDetails?.bio }
        return dataa
      })
      setExportdata(ddd)
    }
  }

  const progressbarpercentage = async () => {
    var Addresss = {
      Address: Wallet_Details.UserAccountAddr
    }
    if (Wallet_Details.UserAccountAddr !== "") {
      var progresBar = await progressbarpercent(Addresss);
      setBulkpercentage(progresBar?.Bulkpercentage)
      SetSolBoundPercentage(progresBar?.Solpercentage)
    }
  }

  const AuthenticatedTokenList = async () => {
    var Addresss = {
      Address: Wallet_Details.UserAccountAddr
    }
    if (Wallet_Details.UserAccountAddr) {
      var Authenticated = await AuthenticatedTokenlist(Addresss);
      SetAuthenticatedToken(Authenticated);
    }
  }

  const RejectedTokenList = async () => {
    var Addresss = {
      Address: Wallet_Details.UserAccountAddr
    }
    if (Wallet_Details.UserAccountAddr !== "") {
      var RejectedToken = await RejecctedToken(Addresss);
      SetRejecctedToken(RejectedToken);
    }
  }

  const TokenReendemList = async () => {
    var Addresss = {
      Address: Wallet_Details.UserAccountAddr
    }
    if (Wallet_Details.UserAccountAddr !== "") {
      var RejectedToken = await TokenReendem(Addresss);
      SetReemdemToken(RejectedToken);
    }
  }

  const TokenticketsSoldList = async () => {
    var Addresss = {
      Address: Wallet_Details.UserAccountAddr
    }
    if (Wallet_Details.UserAccountAddr !== "") {
      var RejectedToken = await TotalTicketsSold(Addresss);
      SetTicketsSold(RejectedToken);
    }
  }

  return (
    <>
      <div className='home_header home'>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand={<Link to="/home">
            <div className="header_logo_align">
            </div></Link>}
          leftLinks={<HeaderLinks />}
          changeColorOnScroll={{
            height: 20,
            color: "white"
          }}
          {...rest}
        />
        <div className={Wallet_Details.PreviewContentShow == "false" ? "container-fluid home_container fluid_padding_align frr d-flex": "container-fluid home_container fluid_padding_align frr"}>
          <div className='home_header home'>
            {localStorage.getItem("StatusShow") == "Show" &&
              <div id="sidebar" className={sidebarShrink ? "side_shrink" : ""}>

                <button className="get-started-btn rounded_btn_wal shrink_side_btn"
                  onClick={() => setSidebarWidth()}
                >
                  <i class="fa fa-chevron-right" aria-hidden="true"></i>
                </button>
                <div className='sidebar_btwn_dtls_align'>
                  <div>
                    <div className='sidebar_whole_img_align'>

                    </div>
                    <ul className='sidebar_ul'>
                      <Link
                        onClick={() => showfunc("dash")}
                      // to='/userdashboard'
                      ><li className='sidebar_list_align mob_top_align'><i class="fa-solid fa-chart-line"></i><span className='sidebar_hiding_txt'>Dashboard</span></li></Link>
                      {Routeee.TokenScanner == "true" &&
                        <Link
                          onClick={() => showfunc("campaign")}
                        ><li className='sidebar_list_align mob_top_align'>
                            <i class="fa-regular fa-bookmark"></i><span className='sidebar_hiding_txt'>Campaign Tools</span></li></Link>
                      }
                      <Link
                        // to='/userprofile'
                        onClick={() => showfunc("profile")}
                      ><li className='sidebar_list_align' ><i class="fa-solid fa-user"></i><span className='sidebar_hiding_txt'>Profile</span></li></Link>
                      {Routeee.TokenScanner == "true" &&

                        <Link
                          // to='/qrcodescan'
                          onClick={() => showfunc("qr")}
                        >

                          <li className='sidebar_list_align'><i class="fa-solid fa-qrcode"></i><span className='sidebar_hiding_txt'>Token Scanner</span></li></Link>
                      }
                      <Link
                        onClick={() => showfunc("Revenue")}
                      ><li className='sidebar_list_align mob_top_align'>
                          <i class="fa fa-coins"></i><span className='sidebar_hiding_txt'>Revenue</span></li></Link>
                      <Link
                        onClick={() => showfunc("Fractions")}
                      ><li className='sidebar_list_align mob_top_align'>
                          <i class="fa-solid fa-ticket"></i><span className='sidebar_hiding_txt'>Fractions</span></li></Link>
                    </ul>
                  </div>
                </div>


              </div>
            }

          </div>

          {userdashb == true &&
            <div className='userdashboard_whole'>
              <div className='scrolling_move_sec_align'>
                <div className="topcardsecs">

                  <div className='card  blackcard_style mb-1'>
                    <div className='firstrow'>
                      <div><i class="fa fa-circle admint_nrm_round admin_green_round mb-2" /></div>
                      <div className='div_text'>Total Tokens</div>
                    </div>

                    <div className='graph_align'>
                      <div className='count_dtls'>
                        <span className='count_txt'>{counts.totaltokens ? counts.totaltokens : 0}</span><br />
                      </div>

                    </div>
                  </div>


                  <div className='card five_div_card blackcard_style mb-1'>
                    <div className='firstrow'>
                      <div><i class="fa fa-circle admint_nrm_round admin_red_round mb-2"></i></div>
                      <div className='div_text'>Used Tokens</div>
                    </div>

                    <div className='graph_align'>
                      <div className='count_dtls'>
                        <span className='count_txt'>{counts.usedTokens ? counts.usedTokens : 0}</span><br />
                      </div>
                    </div>
                  </div>


                  <div className='card five_div_card blackcard_style mb-1'>
                    <div className='firstrow'>
                      <div><i class="fa fa-circle admint_nrm_round admin_sandal_round mb-2"></i></div>
                      <div className='div_text'>Available Tokens</div>
                    </div>
                    <div className='graph_align'>
                      <div className='count_dtls'>
                        <span className='count_txt'>{counts.availableTokens ? counts.availableTokens : 0}</span><br />

                      </div>
                    </div>
                  </div>


                  <div className='card five_div_card blackcard_style mb-1'>
                    <div className='firstrow'>
                      <div><i class="fa fa-circle admint_nrm_round admin_violet_round mb-2"></i></div>
                      <div className='div_text'>Total SBT available</div>
                    </div>

                    <div className='graph_align'>
                      <div className='count_dtls'>
                        <span className='count_txt'>{counts.totsoulavailable ? counts.totsoulavailable : 0}</span><br />

                      </div>
                    </div>
                  </div>


                  <div className='card five_div_card blackcard_style mb-1'>
                    <div className='firstrow'>
                      <div><i class="fa fa-circle admint_nrm_round admin_sandal_round mb-2"></i></div>
                      <div className='div_text'>Total SBT Sent</div>
                    </div>

                    <div className='graph_align'>
                      <div className='count_dtls'>
                        <span className='count_txt'>{counts.SBTsend ? counts.SBTsend : 0}</span><br />
                      </div>

                    </div>
                  </div>
                </div>


                <div className='row mt-2'>
                  <div className='col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 mb-3'>

                    <div className='admin_chart_align w-100'>
                      <div className='chart_options_whole'>
                        <div className='row w-100'  >
                          <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12' >
                            <p className='admin_new_subscribers'>Number of Subscribers</p>
                          </div>
                          <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 graph_drpdwn_align' >


                            <div class="dropdown">
                              <button class="btn dropdown-toggle dropdown_togle_align dropdown_togle_align_graph" type="button" data-toggle="dropdown" aria-expanded="false">
                                Month
                              </button>

                              <div class="dropdown-menu">
                                <span class="dropdown-item" onClick={() =>
                                  SetcatChange("Day")}>Day</span>
                                <span class="dropdown-item" onClick={() =>
                                  SetcatChange("Month")}>Month</span>
                                <span class="dropdown-item" onClick={() =>
                                  SetcatChange("Year")}>Year</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Chart options={data?.options} className="tradechart"
                        series={data?.series}
                        type="bar" height="165" width="100%" />
                    </div>

                  </div>
                  <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3'>
                    <div className='subscriber_whole_dtls_bgalign'>
                      <div className='subscriber_whole_dtls_align d-flex justify-content-between w-100'>
                        <p className='admin_new_subscribers'>New Followers</p>

                      </div>
                      {SubList.length > 0 ?
                        <>
                          {SubList && SubList.map((item, index) => {
                            return (
                              <div className='admin_subscriber_dtls_align w-100'>

                                {item.image == "" || item.image ?
                                  <img className='admin_dropdown_img_align' src={require('../assets/images/faces/marc.jpg')} />
                                  :
                                  <img className='admin_dropdown_img_align' src={`${config.Back_URL}/Users/${item.curraddress}/profileImg/${item?.image}`} />
                                }
                                <div>
                                  <p className='subscribers_align_dtls'>{item?.name == undefined ? item?.userAddress?.slice(0, 15) : item?.name}<span className='admin_badge_icon_align'><i class="fa-solid fa-certificate admin_certificate_icon" /><i class="fa-solid fa-check admin_tick_icon" /></span></p>


                                  <span className='follwers_count_align'>22k Followers</span>
                                </div>
                              </div>
                            )
                          })}
                        </>
                        : <h6 className='no_fol_found'>No followers found</h6>}




                    </div>

                  </div>

                </div>

                <div className='row '>
                  <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-2'>
                    <div className='subscriber_whole_dtls_bgalign'>
                      <div className='subscriber_whole_dtls_align'>
                        <div className=' d-flex justify-content-between w-100'>
                          <div>
                            <p className='admin_new_subscribers'>Last Activity</p>
                            <small>Recently for this month</small>
                          </div>
                        </div>
                        {resentActivity?.length > 0 ?
                          <>
                            {resentActivity && resentActivity.map((item, index) => {
                              return (


                                < div className='last_activity_sub_align row'>
                                  <div className='col-xl-4 xol-lg-4 col-md-4 col-sm-12 col-12 mb-3'>
                                    <div className='sub_img_dtls_align'>
                                      {Wallet_Details?.AddressUserDetails?.image == "" || null ?
                                        <img className='sub_dtls_img' src={require('../assets/images/faces/marc.jpg')} />
                                        :
                                        <img className='sub_dtls_img' src={`${config.Back_URL}/Users/${Wallet_Details.UserAccountAddr}/profileImg/${Wallet_Details?.AddressUserDetails?.image}`} />}

                                      <div>
                                        <p className='sub_dtls_txt_align'>{item?.action}</p>

                                      </div>
                                    </div>
                                  </div>
                                  <div className='col-xl-4 xol-lg-4 col-md-4 col-sm-12 col-12 mb-3'>
                                    <div className='progress_bar_div_align'>
                                      <div className='d-flex'>
                                        <p className='sub_dtls_txt_align mr-2'>{`${item?.action == "Bulk Collection" ? Bulkpercentage : SolBoundPercentage}%`}</p>
                                        <small>Targeted</small>
                                      </div>
                                      <div class="progress qrcode_progress_bar_align">

                                        <div class="progress-bar " role="progressbar" style={{ width: item?.action == "Bulk Collection" ? `${Bulkpercentage}%` : `${SolBoundPercentage}%` }} aria aria-valuenow={item?.action == "Bulk Collection" ? Bulkpercentage : SolBoundPercentage} aria-valuemin="0" aria-valuemax="100"></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='col-xl-4 xol-lg-4 col-md-4 col-sm-12 col-12 mb-3'>
                                    <div className='status_details_dtls_align'>
                                      <small>Created {CreatedTime(item.created)}</small><br />

                                    </div>
                                  </div>




                                </div>
                              )
                            })}
                          </>
                          :
                          <>
                            <h3 className='norecentact'>No Recent Activity Found</h3>
                          </>
                        }


                      </div>

                    </div>
                  </div>


                  <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-2 h-100'>
                    <div className='subscriber_whole_dtls_alignred'>
                      <div className='row'>
                        <div className='col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12'>
                          <p className='achieve_head_align'>New Achievement !</p>
                          <p className='congrats_bottom_txt_align'>Congradulations because you have became our member for 1 year. We hope you continue to stay with us.</p>
                          <button className='benefit_btn_align'>See Benefits</button>
                        </div>
                        <div className='col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 glazier_img_div_align'>

                          <img className='glazier_img_align' src={require('../assets/images/faces/mask_group.png')} alt="image" />

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>}


          {userprfl == true &&

            <div className='scrolling_move_sec_align'>

              <div className="topcardsecs">
                <div>
                  <div className='card blackcard_style mb-2'>
                    <div className='firstrow'>
                      <div><i class="fa fa-circle admint_nrm_round admin_green_round mb-2" /></div>
                      <div className='div_text'>Total Tokens</div>
                    </div>

                    <div className='graph_align'>
                      <div className='count_dtls'>
                        <span className='count_txt'>{counts.totaltokens ? counts.totaltokens : 0}</span><br />
                      </div>

                    </div>
                  </div>
                </div>
                <div>
                  <div className='card blackcard_style mb-2'>
                    <div className='firstrow'>
                      <div><i class="fa fa-circle admint_nrm_round admin_red_round mb-2"></i></div>
                      <div className='div_text'>Used Tokens</div>
                    </div>

                    <div className='graph_align'>
                      <div className='count_dtls'>
                        <span className='count_txt'>{counts.usedTokens ? counts.usedTokens : 0}</span><br />

                      </div>

                    </div>
                  </div>
                </div>
                <div>
                  <div className='card blackcard_style mb-2'>
                    <div className='firstrow'>
                      <div><i class="fa fa-circle admint_nrm_round admin_sandal_round mb-2"></i></div>
                      <div className='div_text'>Available Tokens</div>
                    </div>

                    <div className='graph_align'>
                      <div className='count_dtls'>
                        <span className='count_txt'>{counts.availableTokens ? counts.availableTokens : 0}</span><br />

                      </div>

                    </div>
                  </div>
                </div>
                <div>
                  <div className='card blackcard_style mb-2'>
                    <div className='firstrow'>
                      <div><i class="fa fa-circle admint_nrm_round admin_violet_round mb-2"></i></div>
                      <div className='div_text'>Total SBT available</div>
                    </div>

                    <div className='graph_align'>
                      <div className='count_dtls'>
                        <span className='count_txt'>{counts.totsoulavailable ? counts.totsoulavailable : 0}</span><br />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className='card blackcard_style mb-2'>
                    <div className='firstrow'>
                      <div><i class="fa fa-circle admint_nrm_round admin_sandal_round mb-2"></i></div>
                      <div className='div_text'>Total SBT Sent</div>
                    </div>

                    <div className='graph_align'>
                      <div className='count_dtls'>
                        <span className='count_txt'>{counts.SBTsend ? counts.SBTsend : 0}</span><br />
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div className='row mt-md-5 mt-3'>

                <div className='col-xl-8 col-lg-7 col-md-7 col-sm-12 col-12 mb-3'>
                  <EditProfCont />

                </div>


                <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3'>
                  <div className='subscriber_whole_dtls_bgalign'>
                    <div className='subscriber_whole_dtls_align d-flex justify-content-between w-100'>
                      <p className='admin_new_subscribers'>New Followers</p>

                    </div>
                    {SubList.length > 0 ?
                      <>
                        {SubList && SubList.map((item, index) => {
                          return (
                            <div className='admin_subscriber_dtls_align w-100'>

                              {item.image == null || undefined ?
                                <img className='admin_dropdown_img_align' src={require('../assets/images/faces/marc.jpg')} />
                                :
                                <img className='admin_dropdown_img_align' src={`${config.Back_URL}/Users/${item.PrimaryAddr}/profileImg/${item?.image}`} />
                              }
                              <div>
                                <p className='subscribers_align_dtls'>{item?.name == undefined ? item?.userAddress?.slice(0, 15) : item?.name}<span className='admin_badge_icon_align'><i class="fa-solid fa-certificate admin_certificate_icon" /><i class="fa-solid fa-check admin_tick_icon" /></span></p>


                                <span className='follwers_count_align'>22k Followers</span>
                              </div>
                            </div>
                          )
                        })}
                      </>
                      : <h6 className='no_fol_found'>No followers found</h6>}



                  </div>

                </div>

              </div>


            </div>
          }




          {userqrc == true &&

            <div className='scrolling_move_sec_align'>



              <div className="topcardsecs">
                <div>
                  <div className='card  blackcard_style mb-1'>
                    <div className='firstrow'>
                      <div><i class="fa fa-circle admint_nrm_round admin_green_round mb-2" /></div>
                      <div className='div_text'>Total Tokens Scanned</div>
                    </div>

                    <div className='graph_align'>
                      <div className='count_dtls'>

                        {ScannedToken == "" ? <><span className='count_txt'>0</span><br /></> : <><span className='count_txt'>{ScannedToken}</span><br /></>}


                      </div>

                    </div>
                  </div>
                </div>
                <div>
                  <div className='card five_div_card blackcard_style mb-1'>
                    <div className='firstrow'>
                      <div><i class="fa fa-circle admint_nrm_round admin_red_round mb-2"></i></div>
                      <div className='div_text'>Authenticated Tokens</div>
                    </div>

                    <div className='graph_align'>
                      <div className='count_dtls'>
                        {AuthenticatedToken == "" ? <><span className='count_txt'>0</span><br /></> : <><span className='count_txt'>{AuthenticatedToken}</span><br /></>}

                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className='card five_div_card blackcard_style mb-1'>
                    <div className='firstrow'>
                      <div><i class="fa fa-circle admint_nrm_round admin_sandal_round mb-2"></i></div>
                      <div className='div_text'>Rejected Tokens</div>
                    </div>
                    <div className='graph_align'>
                      <div className='count_dtls'>
                        {RejectedToken == "" ? <><span className='count_txt'>0</span><br /></> : <><span className='count_txt'>{RejectedToken}</span><br /></>}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className='card five_div_card blackcard_style mb-1'>
                    <div className='firstrow'>
                      <div><i class="fa fa-circle admint_nrm_round admin_violet_round mb-2"></i></div>
                      <div className='div_text'>Total Tokens Sold</div>
                    </div>

                    <div className='graph_align'>
                      <div className='count_dtls'>
                        {TicketsSold == "" ? <><span className='count_txt'>0</span><br /></> : <><span className='count_txt'>{TicketsSold}</span><br /></>}

                      </div>

                    </div>
                  </div>
                </div>
                <div>
                  <div className='card five_div_card blackcard_style mb-1'>
                    <div className='firstrow'>
                      <div><i class="fa fa-circle admint_nrm_round admin_sandal_round mb-2"></i></div>
                      <div className='div_text'>Total Tokens Redeemed</div>
                    </div>
                    <div className='graph_align'>
                      <div className='count_dtls'>

                        {ReemdemToken == "" ? <><span className='count_txt'>0</span><br /></> : <><span className='count_txt'>{ReemdemToken}</span><br /></>}
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <Grid container spacing={2} style={{ marginTop: 20, paddingBottom: 10 }}>
                <Grid item xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <div className='qrcode_whole_dtls_align'>
                    <div className='qrcode_address_dtls_align'>
                      <CopyToClipboard
                        text={Wallet_Details.UserAccountAddr}
                        onCopy={() =>
                          toast.success("Address Copied", {
                            closeButton: true,
                            autoClose: 1500,
                            transition: Flip,
                            theme: "dark",
                          })
                        }
                      >
                        <i class="fa-regular fa-copy" />
                      </CopyToClipboard>
                      <b>Wallet Address<span></span></b><br />
                      <small>{Wallet_Details.UserAccountAddr.slice(0, 20)}</small>
                    </div>
                    <div className='qrcode_img_align'>
                      <div className="App">
                        {startScan && (
                          <>
                            <select onChange={(e) => setSelected(e.target.value)}>
                              <option value={"environment"}>Back Camera</option>
                              <option value={"user"}>Front Camera</option>
                            </select>
                            <QrReader
                              facingMode={selected}
                              delay={1000}
                              onError={handleError}
                              onScan={handleScan}
                              // chooseDeviceId={()=>selected}
                              style={{ width: "300px" }}
                            />
                          </>
                        )}

                      </div>
                    </div>
                    {!startScan &&
                      <div className='qrcode_img_align'>
                        <img className='qrcode_img' src={require('../assets/images/faces/qrcode_invert.png')} alt='' />
                      </div>
                    }
                  </div>
                  <p className='qrcode_scan_txt_align'>SCANNING...</p>
                  <div className='text-center mt-2'>

                    <button className='start_scan_btn'
                      onClick={() => {
                        setStartScan(!startScan);
                      }}
                    >
                      {startScan ? "Stop Scan" : "Start Scan"}
                    </button>
                  </div>
                </Grid>
                {ApproveStatus !== "" &&
                  <>
                    {ErrorStatus == false ?
                      <Grid item xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className='qrcode_integ_whole_align'>
                          <div className='qrcode_integ_title_align'>
                            <p className='qrcode_event_text mb-2'>Rejected</p>

                          </div>
                          <div className='qrcode_description_dtls_align'>
                            <div className='qrcode_btn_align'>
                              <button className='qrcode_attendee_align'>Attendee</button>
                            </div>
                            <div className='p-4'>
                              <div className='qrcode_description_dtls_align'>
                                <div className='qrcode_btn_align'>
                                  <button className='qrcode_attendee_align mt-4'>{TicketDetails?.tokenOwner?.slice(0, 10)}</button>
                                </div>
                                <div className='p-4'>
                                  <h6 className='description_title_align'>Description</h6>
                                  <p className='definition_text_align'>{TicketDetails?.tokenDesc}</p>
                                  <div className='row'>
                                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'>
                                      <h6 className='description_title_align'>Network</h6>
                                      <p className='definition_text_align'>{TicketDetails?.SelectedNetwork == config.MATIC_ID ? config.Network_MAT : TicketDetails?.SelectedNetwork == config.CAM_ID ? config.Network_CAM : config.Network_ETH}</p>
                                    </div>
                                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'>
                                      <h6 className='description_title_align'>Name</h6>
                                      <p className='definition_text_align'>{TicketDetails?.tokenName}</p>


                                    </div>
                                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'>
                                      <h6 className='description_title_align'>Token ID</h6>
                                      <p className='definition_text_align'>{TicketDetails?.tokenID}</p>


                                    </div>

                                  </div>
                                  <h6 className='description_title_align'>Originator</h6>
                                  <p className='definition_text_align'>{TicketDetails?.tokenCreator}</p>

                                  <h6 className='description_title_align'>Owner Address</h6>
                                  <p className='definition_text_align'>{TicketDetails?.tokenOwner}</p>

                                  <h6 className='description_title_align'>Contact Address</h6>
                                  <p className='definition_text_align'>{TicketDetails?.contractAddress}</p>

                                  <h6 className='description_title_align'>TokenGating</h6>
                                  <p className='definition_text_align'>{TicketDetails?.TokenGating}</p>
                                </div>


                              </div>
                              <div className='qrcode_description_btn_align'>
                                {TicketDetails?.TicketStatus == "true" &&
                                  <button className='qrcode_red_btn_align'>User already verified</button>}
                              </div>
                            </div>


                          </div>
                        </div>
                        <div className='w-100 text-center mt-3'>
                          <button className='qrcode_verify_btn_align' onClick={() => {
                            ScanNext();
                          }} >SCAN NEXT</button>
                        </div>

                      </Grid>
                      :
                      <Grid item xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className='qrcode_integ_whole_align'>
                          <div className='qrcode_integ_title_green_align'>
                            <p className='qrcode_event_text mb-2'>{TicketDetails?.tokenName} <br />{TicketDetails?.startDateToken} to {TicketDetails?.endDateToken}  </p>

                          </div>
                          <div className='qrcode_description_dtls_align'>
                            <div className='qrcode_btn_align'>
                              <button className='qrcode_attendee_align'>{TicketDetails?.tokenOwner?.slice(0, 10)}</button>
                            </div>
                            <div className='p-4'>
                              <h6 className='description_title_align'>Description</h6>
                              <p className='definition_text_align'>{TicketDetails?.tokenDesc}</p>
                              <div className='row'>
                                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'>
                                  <h6 className='description_title_align'>Network</h6>
                                  <p className='definition_text_align'>{TicketDetails?.SelectedNetwork == config.MATIC_ID ? config.Network_MAT : TicketDetails?.SelectedNetwork == config.CAM_ID ? config.Network_CAM : config.Network_ETH}</p>


                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'>
                                  <h6 className='description_title_align'>Name</h6>
                                  <p className='definition_text_align'>{TicketDetails?.tokenName}</p>


                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'>
                                  <h6 className='description_title_align'>Token ID</h6>
                                  <p className='definition_text_align'>{TicketDetails?.tokenID}</p>

                                </div>

                              </div>

                              <h6 className='description_title_align'>Originator</h6>
                              <p className='definition_text_align'>{TicketDetails?.tokenCreator}</p>

                              <h6 className='description_title_align'>Owner Address</h6>
                              <p className='definition_text_align'>{TicketDetails?.tokenOwner}</p>

                              <h6 className='description_title_align'>Contact Address</h6>
                              <p className='definition_text_align'>{TicketDetails?.contractAddress}</p>

                              <h6 className='description_title_align'>TokenGating</h6>
                              <p className='definition_text_align'>{TicketDetails?.TokenGating}</p>
                              {(TicketDetails?.TicketStatus == "false" && TicketDetails?.Rejected == "false") ?
                                <>
                                  {TicketDetails.tokenCreator == Wallet_Details.UserAccountAddr &&
                                    <>
                                      <div className='qrcode_description_btn_align'>
                                        <button className='qrcode_verify_btn_align' onClick={() => {
                                          TicketVerify();
                                        }} >VERIFIED</button>
                                        <button className='qrcode_verify_btn_align' onClick={() => {
                                          RejectTickets();
                                        }}>Rejected</button>
                                      </div>
                                    </>
                                  }
                                </>
                                :
                                <>
                                  {TicketDetails?.status !== "Soulbound" ?
                                    <div className='verified_userbtn_cntr'>
                                      <button className='qrcode_verify_btn_align'>User Verified</button>
                                    </div> : ""
                                  }
                                </>
                              }
                            </div>
                          </div>
                        </div>
                        <div className='w-100 text-center mt-3'>
                          <button className='qrcode_verify_btn_align' onClick={() => {
                            ScanNext();
                          }} >SCAN NEXT</button>
                        </div>

                      </Grid>}
                  </>}
                <div className='px-3 pt-4' style={{ height: 300, width: '100%' }}>
                  <h3 className='mt-2 mb-2 arrival text-center'>ARRIVALS</h3>
                  <StyledDataGrid
                    // checkboxSelection
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    rows={rows}
                    columns={columns}
                    disableColumnMenu
                    components={{
                      Pagination: CustomPagination,
                    }}
                    className='campaign_muitbl'


                  />
                </div>
                <Exportexcel excelData={exportdata} fileName={'users'} />
              </Grid>
            </div>
          }
          {
            campaignTool == true &&
            <Events />
          }

          {
            RevenueTool == true &&
            <FiatRevenueDetails />
          }
          {
            Fractions == true &&
            <FractionsDash />
          }
        </div>
      </div>

    </>
  )
}

