import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import config from 'lib/config'
import { network } from "views/network";

export const useWeb3 = async (Wallet_Details) => {
  try {
    console.log("khdsfkjfdkdfkfsd", Wallet_Details);
    if (localStorage.walletConnectType == 'wc' && localStorage.walletconnect != null) {
      var provider = config.provider
      const web3 = new Web3(provider);
      return Wallet_Details;
    }
    else if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask') {
      var provider = window.ethereum;
      const web3 = new Web3(provider);
      console.log("ajsdnjkasndjlas", web3);
      return web3;

    }
    else if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'Venly') {
      // var provider = window.ethereum;
      // const web3 = new Web3(provider);
      // console.log("zxcm,,mcv,cv,mvccvmc,nvmc",Wallet_Details);
      return Wallet_Details;

    }
    else if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'Torus') {
      // var provider = window.ethereum;
      // const web3 = new Web3(provider);
      console.log("zxcm,,mcv,cv,mvccvmc,nvmc", Wallet_Details);
      return Wallet_Details;

    }
    else if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'TorusWallet') {
      // var provider = window.ethereum;
      // const web3 = new Web3(provider);
      console.log("zxcm,,mcv,cv,mvccvmc,nvmc", Wallet_Details);
      return Wallet_Details;

    }
    else if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'LMWallet') {
      // var provider = window.ethereum;
      // const web3 = new Web3(provider);
      console.log("zxcm,,mcv,cv,mvccvmc,nvmc", Wallet_Details);
      return Wallet_Details;

    }
  } catch (err) {
    console.log("sdfcbdsjnfkjasnflsda", err);
  }
}

export const useHTTPWeb3 = async (chainId) => {
  var rpc
  if (chainId) {
    if (Number(chainId) == network.MATIC.Chainid) rpc = network.MATIC.RPC_URL
    else if (Number(chainId) == network.ETH.Chainid) rpc = network.ETH.RPC_URL;
    else if (Number(chainId) == network.CAM.Chainid) rpc = network.CAM.RPC_URL;
  } else rpc = config.BNBPROVIDER
  console.log("rpc", rpc);
  const web3 = new Web3(rpc);
  return web3
}

export const getBalanceForWallet = async (chainId, walletaddress) => {
  try {
console.log("chainId, walletaddress",chainId, walletaddress);
    var web3 = await useHTTPWeb3(chainId);
    var coinBalance = await web3.eth.getBalance(walletaddress);
    var convertCoin = Number(web3.utils.fromWei(String(coinBalance)))
    return convertCoin
  } catch (error) {
    console.log("erro on rpcweb3", error);
  }
}
