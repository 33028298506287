import React, { useEffect, useState } from "react";
import config from 'lib/config'
import { toast } from "react-toastify";
import { GetDomainsAndDetails } from "../actions/v1/token"
import { RoutesMain, SubRoutes, TemplateRoutes, LoyalRoutes } from '../views/RoutesMain'

toast.configure()
var toasterOption = config.toasterOption;

export default function Routes(props) {

  const [Sateeee, SetStatewww] = useState(false)
  const [Template, SetTemplate] = useState(false)
  // console.log = () => { };
  // console.debug = () => { };
  // console.info = () => { };
  // console.warn = () => { };
  useEffect(() => {
    Backendcalll()

  }, [])

  const Backendcalll = async () => {
    const SubDomain = getSubDomain(window.location.hostname)
    if (SubDomain !== "") {
      var sendData = {
        domainname: SubDomain
      }
      var calllssss = await GetDomainsAndDetails(sendData)
      if (calllssss?.data?.Data == null) {
        // return Apps[0].app
        // window.location.href = `https://staging.lazyminter.com/`;
        // SetTemplate(calllssss?.data?.Data?.template)
        SetStatewww(false)
      } else {
        //return Apps[1].app
        SetTemplate(calllssss?.data?.Data?.template)
        SetStatewww(true)

      }
    } else {
      SetStatewww(false)
    }
  }

  const getSubDomain = (location) => {
    const locationParts = location.split(".")
    let slicefill = -2
    const isLocalhost = locationParts.slice(-1)[0] === "localhost";
    if (isLocalhost) {
      slicefill = -1
    }

    return locationParts.slice(0, slicefill).join("")
  }

  return (
    <>
    {console.log("Sateeee",Sateeee,Template)}
      {Sateeee == true ? Template == "Collectible NFTs Template" ? <SubRoutes /> : Template == "Fractional/Loyalty Page" ? <LoyalRoutes /> : <TemplateRoutes /> : <RoutesMain />}

    </>
  )
}