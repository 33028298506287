import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import $ from 'jquery'
import   prof  from 'assets/images/small-profile.png'
import   masonary1  from 'assets/images/masonary_04.png'

//Functionality Imports
import emojiRegex from "emoji-regex";
import "react-toastify/dist/ReactToastify.css";
import { toast,Slide } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { UserValidation, ImageValidation } from "actions/controller/validation";
import config from 'lib/config'
import isEmpty from "lib/isEmpty";
import { EditProfiledata, GetProfiledata } from 'actions/controller/userControl'
import { v1_verifyuserMail } from "actions/v1/user";
import EditProfCont from "./EditProfCont";
toast.configure();
//

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function EditProfile(props) {
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const classes = useStyles();
  const history = useHistory()
  const initialForm = {
    Bio:"",
    CustomUrl:"",
    Twitter:"",
    Instagram:"",
    Facebook:"",
    Youtube:"",
    Email:"",
    Name:"",
    Site:"",
  }
  const [Formdata, setFormdata] = useState({initialForm})
  const [valError, setvalError] = useState({})
  const { ...rest } = props;
  const emoji = emojiRegex()
  const [UpdateState,setUpdateState] = useState(false)
  const [Preview, setPreview] = useState('')

  useEffect(() =>{
    if(Wallet_Details.UserAccountAddr == ""){ 
        // toast.warning("Please Connect Your Wallet")
        setPreview(require("../assets/images/lazymintingnew/logodark.png"))
    }
    else
      GetProfile()
  },[Wallet_Details.UserAccountAddr])

  const GetProfile = async() =>{
    var Payload = {user:{curraddress:Wallet_Details.UserAccountAddr}}
    var Resp = await GetProfiledata(Payload)
    if(!isEmpty(Resp))
    {
    setFormdata(Resp)
    if(Resp.photo != "")
      setPreview(`${config.Back_URL}/Users/${Resp.PrimaryAddr}/profileImg/${Resp.photo}`)
    else  
      setPreview(require("../assets/images/lazymintingnew/logo.png"))
    }
  }

  const Handlefile = (e) => {
    if (e.target && e.target.files) {
      var file = e.target.files[0];
      var Resp = ImageValidation(file,"Edit")
      if(Resp.Success){
        Formdata.File = file
        setPreview(URL.createObjectURL(file));
      }
      else
        toast.error(Resp.Message)
    }
  };

  const HandleChange = (e) =>{
		e.preventDefault();
		const { id, value } = e.target;
		let formData = { ...Formdata, ...{ [id]: value } }
		setFormdata(formData)
  }

  const Submit = async() =>{
    setUpdateState(true)
    Formdata.curraddress = Wallet_Details.UserAccountAddr
    var check = UserValidation(Formdata)
    if(check === true && Formdata.curaddress != "")
    {
        const id = toast.loading("Updating Data Please Wait")
        var resp = await EditProfiledata(Formdata)
        // console.log("Editprofiledetail",resp)
        if(resp.Success)
        {
        setTimeout(() => {
          toast.update(id, {render: resp.Message,type: "success",isLoading: false,autoClose: 2000});
        }, 500);
        setUpdateState(false)
        if(Formdata.Email)
        {
          if(resp.Mail)
          {
          toast.success(`A verification Mail will be sent to ${Formdata.Email}`,{transition:Slide, theme:"dark",autoClose:false,closeButton:true,position:"bottom-right"})
          }
          else{
            setTimeout(()=>{
              history.push('/my-items')
            },2500)
          }
        }
      }
      else{
        setUpdateState(false)
        setTimeout(() => {
          toast.update(id, {render: resp.Message,type: "error",isLoading: false,autoClose: 2000});
        }, 500);
        $('html, body').animate({scrollTop:0}, 'slow');
      }
    }
    else{
        setUpdateState(false)
        setvalError(check)
        toast.error("Form validation failed",{autoClose:2500})
        $('html, body').animate({scrollTop:0}, 'slow');
    }
  }

  const SendVeriMail = async(e) =>{
    e.preventDefault()
    //alert("1")
    var Arg = {
      curraddress:Wallet_Details.UserAccountAddr,
      email:Formdata.Email
    }
    const mailid = toast.loading("Mail sending in Process..")
    var Resp = await v1_verifyuserMail(Arg)
    // console.log("bfkhbfdhgbdfbvhkdnf",Resp,Arg)
    if(Resp.Mail)
    {
      setTimeout(() => {
        toast.update(mailid, {render: `Verification Mail Sent to ${Formdata.Email}`,type: "success", isLoading: false, autoClose: 5000});
      }, 500);
    }
    else
    {
      toast.error("Error occured, Try again sometime later",{autoClose:5000,closeButton:false})
    }
  }

  return (
    <div className="home_header">
     <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home">
          <div className="header_logo_align">
            

            </div></Link>}
        leftLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
          <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader inner_pagh_bg_2"}>
        <div className={classes.container}>
          <div className="row">
            <div className="col-12 col-lg-9 mx-auto">
              <div className="d-flex align-items-center">
            <p className="heading_big_blk">Edit Profile</p>

              </div>
            </div>
          </div>
        </div>
     <EditProfCont />
     </div>
      <Footer/>
    </div>
  );
}
