import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Ticketicon from "../assets/images/lazymintingnew/ticket.svg"
import { Button, TextField } from "@material-ui/core";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Scrollbars } from "react-custom-scrollbars";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { NFTInfo } from "actions/controller/tokenControl";
import { useSelector } from "react-redux";
import config from "../lib/config";
import { PurchaseNow } from "./separate/PurchaseNow";
import { PlaceandCancel } from "./separate/PlaceandCancel";
import { BidActions } from "./separate/PlaceandAcceptBid";
import { BurnToken } from "./separate/BurnToken";
import { TransferNFT } from "./separate/TransferNFT"
import { Audio, TailSpin } from "react-loader-spinner";
import { toast, Flip } from "react-toastify";
import { v1_CancelBid, UserGetSoul, CreateSoul, UserGetSoulINFO } from "actions/v1/token";
import { Report } from "./separate/Report";
import { Share } from "./separate/ShareNow";
import { v1_MyComment } from "actions/v1/user";
import Modal from "react-modal";
import isEmpty from "lib/isEmpty";
import Countdown from "react-countdown";
import ReactMarkdown from 'react-markdown';
import { Satellite } from "@material-ui/icons";
//
const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Info(props) {
  const classes = useStyles();
  const { ...rest } = props;
  function hideDetail() {
    document.getElementById("image_div").classList.toggle("expand_img");
    document.getElementById("img_des").classList.toggle("show_des");
    document.getElementById("detai_div").classList.toggle("hide_detail");
    document.getElementById("arrow_icon").classList.toggle("fa-shrink");
  }

  function hideDetailowner() {
    document.getElementById("image_div_owner").classList.toggle("expand_img");
    document.getElementById("img_des_owner").classList.toggle("show_des");
    document.getElementById("detai_div_owner").classList.toggle("hide_detail");
    document.getElementById("arrow_icon_owner").classList.toggle("fa-shrink");
  }
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const { conaddr, owneraddr, tokenID } = useParams();
  const [NFT, setNFT] = useState({
    AllBids: [],
    CurrentOwner: { CurrentOwner: {} },
    History: [],
    NFTCreator: {},
    NFTDetail: {},
    NFTOwners: [],
    Mybid: [],
  });
  const renderer = ({
    days,
    Month,
    Year,
    hours,
    minutes,
    seconds,
    completed,
  }) => {
    if (completed) {
      return <span></span>;
    } else {
      return (
        <span>
          {days}d {hours}h {minutes}m {seconds}s left
        </span>
      );
    }
  };
  const location = useLocation();
  const history = useHistory();
  const [NFTImage, setNFTImage] = useState("");
  const [Comments, setComments] = useState("");
  const [localStorageState, setlocalStorageState] = useState("");
  const [AllComments, setAllComments] = useState([]);
  const PurchaseNowRef = useRef();
  const PlaceandCancelRef = useRef();
  const BidActionsRef = useRef();
  const BurnTokenRef = useRef();
  const TransferNFTRef = useRef();
  const ReportRef = useRef();
  const ShareNowRef = useRef();
  const [ShowModal, setShowModal] = useState(false);
  const [showingLoader, setshowingLoader] = React.useState(false);
  const [Creator, Setcreator] = useState({})
  const [Owner, Setowner] = useState({})
  const [datastate, SetDataSate] = useState({})
  let state = location.state;
  const [theme, setTheme] = useState(false);
  let locationpath = location.location

  const customStyles = {
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      // boxShadow: '0 27px 24px 0 rgb(0 0 0 / 20%), 0 40px 77px 0 rgb(0 0 0 / 22%)',
      borderRadius: "30px",
      border: "none !important",
    },
    overlay: {
      backgroundColor: "rgba(255,255,255,255)",
      zIndex: 2,
    },
  };
  useEffect(() => {
    ListSoulBound()
    ListSoulBoundINFO()

  }, [tokenID])
  const CancelBid = async (Biddet) => {
    const id = toast.loading("Bid Cancelling On Progress");
    var CanBiddet = {
      Biddet: Biddet,
      ActBody: {
        tokenName: NFT.NFTDetail.tokenName,
      },
    };
    var Resp = await v1_CancelBid(CanBiddet);
    if (Resp.data.Success) {
      toast.update(id, {
        render: Resp.data.Message,
        type: "success",
        isLoading: false,
        autoClose: 2500,
      });
      // setTimeout(() => {
      //   NFTInfoDetails();
      // }, 2500);
    } else {
      toast.update(id, {
        render: Resp.data.Message,
        type: "error",
        isLoading: false,
        autoClose: 2500,
      });
    }
  };

  const timediff = (a) => {
    var date1 = new Date(Date.now());
    var date2 = new Date(a);
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Math?.ceil(Difference_In_Days);
  };

  const UrComment = async (del) => {
    if (Wallet_Details.UserAccountAddr) {
      var Payload = {
        comments: {
          comment: Comments,
          curraddress: Wallet_Details.UserAccountAddr,
          tokenID: tokenID,
        },
        get: {
          tokenID: tokenID,
          get: Comments,
        },
      };
      Payload.delete = del ? del : {};
      var Resp = await v1_MyComment(Payload);
      if (Resp.data.Success) {
        if (!Comments) {
          setAllComments(Resp.data.records);
          if (Resp.data.Message) {
            toast.success(Resp.data.Message, {
              closeButton: true,
              autoClose: 1500,
              transition: Flip,
            });
          }
        } else {
          if (Resp.data.Message) {
            toast.success(Resp.data.Message, {
              closeButton: true,
              autoClose: 1500,
              transition: Flip,
            });
          }
          setComments("");
          setAllComments(Resp.data.records);
        }
      }
    }
  };

  const Ipfsimageerr = (e) => {
    if (datastate.ipfsimage)
      e.target.src = datastate.ipfsimage
  }

  const ListSoulBound = async () => {
    var reqdata = {
      Owner: (owneraddr).toLowerCase(),
    }
    var collectiongal = await UserGetSoul(reqdata);
    Setowner(collectiongal?.data?.data?.ListingCollection)
  }

  useEffect(() => {
    if (datastate.length > 0) {
      ListSoulBoundcreator(datastate)
    }
  }, [datastate])
  const ListSoulBoundcreator = async (datastate1) => {
    var reqdata = {
      Creator: (datastate?.tokenCreator)?.toLowerCase(),
    }
    var collectiongal = await CreateSoul(reqdata);
    Setcreator(collectiongal?.data?.data?.ListingCollection)
  }


  window.addEventListener('storage', async (event) => {
    console.log("event in SoulBound", event);
    console.log("Storage Testing", localStorage.getItem('userAddress'));
  })

  const ListSoulBoundINFO = async () => {
    var reqdata = {
      QR_tokenID: tokenID,
    }
    var collectiongal = await UserGetSoulINFO(reqdata);
    if (collectiongal?.data?.data?.Message == "success") {
      SetDataSate(collectiongal?.data?.data?.ListingCollection)
      ListSoulBoundcreator(collectiongal?.data?.data?.ListingCollection)
      ListSoulBound(collectiongal?.data?.data?.ListingCollection)
    } else {
      toast.error("Oops..! Error Detected Refresh Page")
      history.push("/")
    }
  }

  const getScannerURL = () => {
   
    switch (String(datastate.SelectedNetwork)) {
        case String(config.MATIC_ID):
            return `${config.ScannerPolygon}address/${datastate.contractAddress}`;
        case String(config.CAM_ID):
          console.log("configdatastate.SelectedNetwork",config.CAM_ID,datastate.SelectedNetwork,`${config.ScannerCamino}/address/${datastate.contractAddress}`);
            return `${config.ScannerCamino}address/${datastate.contractAddress}`;
        default:
            return `${config.ScannerBinance}address/${datastate.contractAddress}`;
    }
};

  

  return (

    <div className="home_header info">

      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={
          <Link to="/home">
            <div className="header_logo_align">


</div>
          </Link>
        }
        leftLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 20,
          color: "white",
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div
        className={
          classes.pageHeader + " inner_pageheader info_header inner_pagh_bg_2"
        }
      >
        <div className="container container_full mb-5">
          {/* info row */}
          <div className="row info_row mx-0 buyer_div">
            <div className="col-12 col-lg-6" id="image_div">
              <div className="">
                <div className="img_info_rad" data-toggle="modal" data-target="#infoimg_modal">
                  {datastate &&
                    datastate?.ref &&
                    (datastate?.ref?.split(".").pop() == "mp4" ? (
                      <video
                        id="my-video"
                        className="img-fluid"
                        autoPlay
                        playsInline
                        loop
                        muted
                        controls disablePictureInPicture controlsList="nodownload"
                        preload="auto"
                        onError={(e) => Ipfsimageerr(e)}
                      >
                        <source src={`${config.Back_URL + "/" + datastate?.FinalOutBulkPath}`} type="video/mp4" />
                      </video>
                    ) : (
                      <img
                        src={`${config.Back_URL + "/" + datastate?.FinalOutBulkPath}`}
                        onError={(e) => Ipfsimageerr(e)}
                        alt="Collections"
                      />
                    ))}
           
                </div>

                <div className="img_des" id="img_des">
                  <p className="info_title">{datastate?.NFTImageName}</p>
                  <h3 className="info_h3">
                    by
                    <span className="px-2">
                      {Owner &&
                        Owner?.name
                        ? Owner?.name
                        : Owner?.curraddress}
                    </span>
                    on
                    <span className="pl-2">Lazyminter</span>
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 bg_pink" id="detai_div">
              <div className="pt-4">
                <div className="d-flex justify-content-between align-items-center d-flex_blk_cu">
                  <div></div>
                  <div className="d-flex">

                    <button
                      className="create_btn btn_rect ml-2 btn_icon_only"
                      data-toggle="modal"
                      data-target="#share_modal"
                      onClick={() =>
                        ShareNowRef.current.ShareSocial_Click(
                          window.location.href,
                          datastate?.NFTImageName,
                          "info"
                        )
                      }
                    >
                      <i className="fas fa-upload pr-2"></i>
                      <span>Share</span>
                    </button>
                  </div>
                </div>
         
                     <div className="d-flex justify-content-between align-items-center pt-1 nft_title">
                  <div>
                    <span className="info_title">
                      {datastate?.NFTImageName}
                    </span>
                  </div>
                </div>
          
                <div className="ticketpricelist">
             
                </div>

                {datastate?.tokenOwner == Wallet_Details.UserAccountAddr && datastate?.unlockcontent &&
                  <p className="user_name_inf_big mt-4">
                    <ReactMarkdown children={datastate?.unlockcontent} />

                  </p>
                }
                <table class="table table-dark">
                  <tbody>
                    {datastate.tokenDesc &&
                      <tr>
                        <td>Description :</td>
                        <td>{datastate?.tokenDesc}</td>
                      </tr>
                    }
                    {datastate?.tokenProperty &&
                      <tr>
                        <td>Property :</td>
                        <td>{datastate?.tokenProperty}</td>
                      </tr>
                    }
                    {datastate?.Location &&
                      <tr>
                        <td>Location :</td>
                        <td>{datastate?.Location}</td>
                      </tr>
                    }
                    {datastate?.Return &&
                      <tr>
                        <td>Return :</td>
                        <td>{datastate?.Return}</td>
                      </tr>
                    }
                    {datastate?.Section &&
                      <tr>
                        <td>Section :</td>
                        <td>{datastate?.Section}</td>
                      </tr>
                    }
                    {datastate?.TokenGating &&
                      <tr>
                        <td>Gating Information :</td>
                        <td><a href={datastate?.TokenGating} target="_blank">{datastate?.TokenGating}</a></td>
                      </tr>
                    }
                    {datastate?.startDateToken &&
                      <tr>
                        <td>Start Date :</td>
                        <td>{datastate?.startDateToken}</td>
                      </tr>
                    }
                    {datastate?.endDateToken &&
                      <tr>
                        <td>End Date :</td>
                        <td>{datastate?.endDateToken}</td>
                      </tr>
                    }
                  </tbody>
                </table>

              </div>
            </div>

            <div className="col-12 col-lg-7 pr-lg-0 ">
              <nav className="masonry_tab_nav items_tab_outer info_tab_ouert mt-4 mb-3">
                <div
                  className="nav nav-tabs masonry_tab primary_tab items_tab d-block items_tab_new pb-0 pl-0"
                  id="nav-tab"
                  role="tablist"
                >
                  <a
                    className="nav-link active"
                    id="details-tab"
                    data-toggle="tab"
                    href="#details"
                    role="tab"
                    aria-controls="details"
                    aria-selected="true"
                  >
                    <div className="tab_head p-2">Details</div>
                  </a>
                  <a
                    className="nav-link"
                    id="owners-tab"
                    data-toggle="tab"
                    href="#owners"
                    role="tab"
                    aria-controls="active"
                    aria-selected="false"
                  >
                    <div className="tab_head p-2">Owners</div>
                  </a>
                </div>
              </nav>
              <div
                className="tab-content explore_tab_content mt-4 pr-lg-3 pb-5"
                id="nav-tabContent"
              >
                <div
                  className="tab-pane fade show active"
                  id="details"
                  role="tabpanel"
                  aria-labelledby="details-tab"
                >
                 
                  <div className="proposal_panel_overall">
                    <div className=" mt-0 mb-0">
                      <div className="card-body pr-3 pb-0">
                        <p className="text_sm_owner">Creator</p>
                        <Link
                          to={
                            !Creator?.customurl
                              ? `/my-items/${Creator?.curraddress}`
                              : `/my-items/user/${Creator?.customurl}`
                          }
                        >
                          <div className="media follow_media icon_img">
                            <div className="icon-img-div">
                              <div className="img_media_new  mr-3">
                                <img
                                  src={
                                    Creator?.image
                                      ? `${config.Back_URL}/Users/${Creator?.PrimaryAddr}/profileImg/${Creator?.image}`
                                      : require("../assets/images/lazymintingnew/logo.png")
                                  }
                                  alt="User"
                                  className="img-fluid"
                                />
                              
                              </div>
                            </div>
                            <div className="media-body flex_body">
                              <div>
                                <p className="media_num mb-0">
                                  <span className="media_text">
                                    {Creator?.name
                                      ? Creator?.name
                                      : Creator?.PrimaryAddr &&
                                      Creator?.PrimaryAddr
                                        .slice(0, 25)}
                                  </span>
                                </p>
                                <p className="media_num mb-0">
                                  @
                                  <span className="MYmedia_text">
                                    {Creator?.customurl
                                      ? Creator?.customurl
                                      : "LazyminterArtist"}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>
                        
                        <p className="text_big_owner mt-3">Year Created : <span>{new Date(datastate?.timestamp).toLocaleDateString(
                          "en-US",
                          {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          }
                        )}</span></p>
            
                        <p className="text_big_owner mt-3 contractadd">Contract Address : <span className="ml-2">
                          {/* {datastate.SelectedNetwork == config.MATIC_ID ? */}
                          <a className="text-white" target="_blank" href={getScannerURL()}>
    <p className="text-truncate">{datastate?.contractAddress}</p>
</a>
                            {/* :
                            <a className="text-white" target="_blank" href={`${config.ScannerBinance}address/${datastate?.contractAddress}`}> <p className="text-truncate">{datastate?.contractAddress}</p> </a> */}
                          {/* } */}


                        </span></p>
                        <p className="text_big_owner mt-3">Token ID : <span>{datastate?.tokenID}</span></p>
                        <p className="text_big_owner mt-3">Token Standard : <span>ERC-721</span></p>
                        <p className="text_big_owner mt-3">Blockchain : <span>{datastate?.SelectedNetwork == config.MATIC_ID ? config.Network_MAT_Cap + " mainnet" : datastate?.SelectedNetwork == config.CAM_ID ? config.Network_CAM_Cap + " mainnet" : config.Network_ETH_Cap + " mainnet"}</span></p>
                        <div className="contarct_details">
                          <div>
                            <p>
                              <a
                                href={`${datastate?.ipfsimage}`}
                                target="_blank"
                                className="social_a_icon"
                              >
                                <span className="pl-0">View on IPFS</span>
                                <img
                                  src={require("../assets/images/expand_icon.png")}
                                  className="img-fluid pl-2 expand_icon"
                                />
                              </a></p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="owners"
                  role="tabpanel"
                  aria-labelledby="owners-tab"
                >
                  <div className="proposal_panel_overall">
                    <div className="">
                      <Scrollbars
                        autoHeight
                        autoHeightMax={350}
                        renderThumbVertical={(props) => (
                          <div {...props} className="thumb-vertical d-none" />
                        )}
                        renderThumbHorizontal={(props) => (
                          <div {...props} className="thumb-horizontal d-none" />
                        )}
                      >
                        {console.log("qweqweqweqweqweeoio", Owner)}
                        <div className="card mt-0 mb-3 acti_card rounded-corners-gradient-borders">
                          <Link
                            to={
                              !Owner?.customurl
                                ? `/my-items/${Owner?.curraddress}`
                                : `/my-items/user/${Owner?.customurl}`
                            }
                          >
                            <div className="card-body px-3">
                              <div className="media follow_media icon_img">
                                <div className="icon-img-div">
                                  <div className="img_media_new  mr-3">
                                    <img
                                      src={
                                        Owner?.image
                                          ? `${config.Back_URL}/Users/${Owner?.PrimaryAddr}/profileImg/${Owner?.image}`
                                          : require("../assets/images/lazymintingnew/logo.png")
                                      }
                                      alt="User"
                                      className="img-fluid"
                                    />
                                  </div>
                                </div>
                                <div className="media-body flex_body flex_mob_blck">
                                  <div>
                                    <p className="media_num mt-0 mb-0">
                                      @
                                      <span className="media_text">
                                        {Owner?.name
                                          ? Owner?.name
                                          : Owner?.PrimaryAddr?.slice(0, 15)}
                                      </span>
                                    </p>
                                    <p className="media_num mb-0">
                                      @
                                      <span className="MYmedia_text">
                                        {Owner?.customurl
                                          ? Owner?.customurl
                                          : "LazyminterArt"}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Scrollbars>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-5 bor_top_blue">
              <p className="my_items_head">{AllComments.length} Comments</p>
              <Scrollbars
                autoHeight
                autoHeightMax={220}
                className="nto_scrol_div"
              >
                {AllComments &&
                  AllComments.length > 0 &&
                  AllComments.map((comm) => {
                    return (
                      <div className="comment_sec_inout">
                        <Link
                          to={
                            comm.commentors.customurl
                              ? `/my-items/user/${comm.commentors.customurl}`
                              : `/my-items/${comm.commentors.curraddress}`
                          }
                        >
                          <div className="img_media_new  mr-1">
                            <img
                              src={
                                comm.commentors && comm.commentors.image
                                  ? `${config.Back_URL}/Users/${comm.commentors.PrimaryAddr}/profileImg/${comm.commentors.image}`
                                  : require("../assets/images/user_comment.png")
                              }
                              alt="Collections"
                              className="img-fluid"
                            />
                          </div>
                        </Link>
                        <div className="w-100 ml-2">
                          <p className="my_items_head_nim">
                            {comm?.commentors && comm?.commentors?.name
                              ? comm?.commentors?.name
                              : comm?.commentors?.curraddress
                                ?.slice(0, 10)}
                          </p>
                          <div className="input-group mb-2 inut_gro_stke_1 inut_gro_stke_2">
                            <input
                              type="text"
                              disabled
                              className="form-control"
                              value={comm.comment}
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                            />
                            {Wallet_Details.UserAccountAddr ===
                              comm.commentors.curraddress && (
                                <div className="input-group-append p-2">
                                  <span
                                    className="input-group-text pr-2"
                                    id="basic-addon2"
                                  ></span>
                                  <span
                                    data-toggle="modal"
                                    data-target={(localStorage.userAddress == Wallet_Details.UserAccountAddr) ? "" : "#register_modal"}
                                    onClick={() => {
                                      if (!localStorage.userAddress || localStorage.userAddress !== Wallet_Details.UserAccountAddr) {
                                        window.$("div").removeClass('modal-backdrop fade show')

                                        return toast.error("Login with correct Wallet Address and credentials")

                                      } else { UrComment(comm._id) }
                                    }}>
                                    <i className="fas fa-trash"></i>
                                  </span>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Scrollbars>
              <div className="comment_sec_inout mt-3">
                <img
                  src={require("../assets/images/user_comment.png")}
                  alt="Collections"
                  className="img-fluid"
                />
                <div className="w-100 ml-2">
                  <div className="input-group mb-2 inut_gro_stke_1">
                    <input
                      onChange={(e) => setComments(e.target.value)}
                      value={Comments}
                      type="text"
                      className="form-control"
                      placeholder="Please Enter Your Comment"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                    />
                    <div className="input-group-append">
                      <span className="input-group-text pr-2" id="basic-addon2">
                        <span
                          onClick={() => UrComment()}
                          className="send_iocn_bg"
                        >
                          <i className="fas fa-paper-plane cur_pointer"></i>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end info row */}

          {/* info owner row */}
          {/* end info owner row*/}
        </div>
      </div>
      <Footer />
      <Report ref={ReportRef} />
      <Share ref={ShareNowRef} />
      <Modal
        isOpen={ShowModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {theme === "#000" ?
         <div className="modal-body text-center loading_bgcolor">
         <div className="laodingpart">
         <img src={require("../assets/images/lazymintingnew/logo.png")} alt="Profile" className="img-fluid items_profile smal_main_lm" />
 
         </div>
       </div> :

<div className="modal-body text-center loading_bgcolor">
<div className="laodingpart">
<img src={require("../assets/images/lazymintingnew/logodark.png")} alt="Profile" className="img-fluid items_profile smal_main_lm" />
  
</div>
</div>

        }
       
      </Modal>

      {/* {/ info_img Modal /} */}
      <div class="modal fade primary_modal infoimg_modal" id="infoimg_modal" tabindex="-1" role="dialog" aria-labelledby="infoimg_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">

          <div class="modal-content">
            <button type="button" class="close"
              data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body px-0">
              {datastate &&
                datastate.ref &&
                (datastate.ref.split(".").pop() == "mp4" ? (
                  <video
                    id="my-video"
                    className="img-fluid"
                    autoPlay
                    playsInline
                    loop
                    muted
                    controls
                    preload="auto"
                    onError={(e) => Ipfsimageerr(e)}
                  >
                    <source src={`${config.Back_URL + "/" + datastate?.FinalOutBulkPath}`} type="video/mp4" />
                  </video>
             
                ) : (
                  <img
                    src={`${config.Back_URL + "/" + datastate?.FinalOutBulkPath}`}
                    onError={(e) => Ipfsimageerr(e)}
                    alt="Collections"
                    className="img-fluid"
                  />

                ))}
            </div>
          </div>
        </div>
      </div>
      {/* {/ end info_img modal /} */}
      {/* login modal*/}
    </div >
  );
}
