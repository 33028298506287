import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { TicketScan, RouterChecker } from '../../actions/v1/token'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";
function SideBar(Props) {
  console.log("wdasdasdasdasd",Props);
  const [sidebarShrink, setSidebarShrink] = useState(false);
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const [theme, setTheme] = useState('dark');
  const [Routeee, SetRouteeee] = useState("Not Allowed")

  const history = useHistory();
  const setSidebarWidth = () => {
    setSidebarShrink(!sidebarShrink);
    if (sidebarShrink) {
      document.body.classList.remove('sidebar_shr');
    }
    else {
      document.body.classList.add('sidebar_shr');

    }
  }
  useEffect(() => {
    console.log("Props.item",Props.item);

      Route()
    
  }, [])

  const Route = async () => {
    console.log("aweqwedsdasdsadsa",Props.item);
    var Addresss = {
      Address: Props.item
    }
    console.log("qweqweqweqweoiio", Addresss);
    var SubscriberList = await RouterChecker(Addresss)
    console.log("qweqweqweSubscriberList", SubscriberList);
    if (SubscriberList?.data?.data?.UserBalance.TokenScanner == "true") {
      SetRouteeee(SubscriberList?.data?.data?.UserBalance)
    }
    else {
      // history.push("/userdashboard")
      // toast.error("Your Not Subcribed TokenScanner")
    }
  }

  return (
    <>

      <div className='home_header home'>
        <div id="sidebar" className={sidebarShrink ? "side_shrink" : ""}>

          <button className="get-started-btn rounded_btn_wal shrink_side_btn"
            onClick={() => setSidebarWidth()}
          >
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </button>
          <div className='sidebar_btwn_dtls_align'>
            <div>
              <div className='sidebar_whole_img_align'>
                
              </div>
              <ul className='sidebar_ul'>
                <Link 
                // to='/userdashboard'
                ><li className='sidebar_list_align'><i class="fa-solid fa-chart-line"></i><span className='sidebar_hiding_txt'>Dashboard</span></li></Link>
                <Link 
                // to='/userprofile'
                ><li className='sidebar_list_align'><i class="fa-solid fa-user"></i><span className='sidebar_hiding_txt'>Profile</span></li></Link>
                {console.log("asdasdsadasdasdas",Routeee)}
                {Routeee.TokenScanner == "true" &&
                <Link to='/qrcodescan'><li className='sidebar_list_align'><i class="fa-solid fa-qrcode"></i><span className='sidebar_hiding_txt'>Token Scanner</span></li></Link>}

              </ul>
            </div>
            {/* <div className='sidebar_tiffany_dtls'>
              <p className='tiffay_dtls_head'>Purchase Tokens</p>
              <p className='tiffay_dtls_hint'>Upgrade now for get <br /> benefits more.</p>
              <button className='upgrade_plan_btn'>Upgrade Plan</button>
            </div> */}
          </div>


        </div>

      </div>


    </>
  )
}

export default SideBar