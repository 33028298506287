import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import {
  Button,
} from "@material-ui/core";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";

import img2 from "assets/images/large-profile-tick.png";
import { v1_Activity } from "actions/v1/user";
import { toast } from "react-toastify";
import config from "lib/config";

import { usePrevious } from "hooks/useMethods";
import {  TailSpin } from "react-loader-spinner";
;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Myitems(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [sidebar, setSidebar] = useState(false);

  const [cngImage, setCngImage] = useState(false);
  const [Activity, setActivity] = useState([]);
  const [Filter, setFilter] = useState("BuySell");
  const [Min, setMin] = useState(0);
  const [Max, setMax] = useState(0);
  const [Clicked, setClicked] = useState(false);
  const [LoadMore, setLoadMore] = useState(true);
  const [Page, setPage] = useState(1);
  const prevPage = usePrevious(Page)
  const prevFilter = usePrevious(Filter)
  const [ ShowModal,setShowModal] = useState(false)
  const customStyles = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      // boxShadow: '0 27px 24px 0 rgb(0 0 0 / 20%), 0 40px 77px 0 rgb(0 0 0 / 22%)',
      borderRadius: '30px',
      border: 'none !important',
    },
    overlay: {
     backgroundColor:'rgba(255,255,255,255)',
     zIndex:2
    },
  };


  useEffect(() => {
  if(prevFilter !== Filter)
    setShowModal(true)
    GetActivity();
  }, [Clicked, Filter,Page]);

  const GetActivity = async () => {
    var Arg = {
      Filter: Filter,
      Price: { min: Min, max: Max },
      Page: (prevFilter !== Filter)?1:Page,
      limit: 50
    };
    
    var Resp = await v1_Activity(Arg);
    // console.log("RespActivity", Resp.records.length);
    if (Resp.Success) {
      if (Resp.records.length > 0) {
        if((prevPage !== Page && Page !== 1) && prevFilter === Filter)
        {
          // console.log("RespActivityww", prevFilter,Filter,Page,prevPage,Activity);
          var Act = Activity
          setActivity(Act.concat(Resp.records));
        }
        else{
          
          setActivity(Resp.records);
          if(prevFilter !== Filter)
            setPage(1)
        }
        setLoadMore(true);
      } else {
        if(prevPage === Page)
          setActivity(Resp.records);
        setLoadMore(false);
      }
      setTimeout(() => {
        setShowModal(false)
      }, 1000);
    } else {
      setActivity([]);
      toast.error("Error in Activity Fetch", { autoClose: 2000 });
    }
  };
  var CreatedTime = (Actdate) => {
    var today = new Date();
    var Christmas = new Date(Actdate);
    var diffMs = today - Christmas; // milliseconds between now & Christmas
    var diffDays = Math?.floor(diffMs / 86400000); // days
    var diffHrs = Math?.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math?.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    return diffHrs === 0 && diffDays === 0
      ? `${diffMins} minutes ago`
      : diffDays === 0
      ? `${diffHrs} hours ago`
      : `${diffDays} days ago`;
  };

  function func(e) {
    var l = document.getElementById("chainbtn");
    for (var i = 0; i <= l.lenght - 1; i++) {
      var elem = e.target.children(i);
      console.log(elem);

      toggleimage();
    }

    var elem = e.target.children[0];
    console.log(elem);
    toggleimage();
  }

  function changeImage(e) {
    console.log(e.target.id, document.getElementById(e.target.id + "_img").src);
    document.getElementById(e.target.id + "_img").src = img2;
  }
  function toggleimage() {
    setCngImage(!cngImage);
  }

  function setBody() {
    setSidebar(!sidebar);
    var bodyScroll = document.getElementsByTagName("html");
  
    for (var j = 0; j < bodyScroll.length; j++) {
      bodyScroll[j].classList.toggle("overflow_body");
    }
  }
  var imgeClass = sidebar
    ? " col-sm-6 col-md-4 col-lg-4"
    : " col-sm-6 col-md-4 col-lg-4";

  return (
    <div className="home_header newmyitems">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={
          <Link to="/home">
            <div className="header_logo_align">
            

            </div>
          </Link>
        }
        leftLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 20,
          color: "white",
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div
        className={
          classes.pageHeader + " inner_pageheader items_header inner_page_bg_1 activitysys"
        }
      >
      
        <div className="inner_pagh_bg_1">
      
          <button
            class="btn btn-link filter-icon px-4 filterbtn mobilebtn create_btn"
            onClick={setBody}
          >
            <span>
              <i class="fa fa-bars" aria-hidden="true"></i> Filter
            </span>
            <label className="mb-0">1</label>
          </button>

          <div className="newclass">
            <div className="filtersec d-flex">
              <div
                className={sidebar ? "sidehide  filter" : "sideshow  filter"}
              >
                <button
                  class="btn btn-link filter-icon px-4 filterbtn filter_btn_scroll_web"
                  onClick={() => setSidebar(!sidebar)}
                >
                  <span>
                    <i class="fa fa-bars mr-2" aria-hidden="true"></i> Filter
                  </span>
                  <i class="fas fa-arrow-left" id="filter_icon_right"></i>
                </button>
                <button
                  class="btn btn-link filter-icon px-4 filterbtn filter_btn_scroll"
                  onClick={setBody}
                >
                  <span>Filter</span>
                  <i class="fas fa-arrow-left" id="filter_icon_right"></i>
                </button>
                <div className="filteritems">
                  <div id="accordion">
                    <div class="card-header" id="headingOne">
                      <h5 class="mb-0">
                        <button
                          class="btn btn-link filter-icon"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          <span>
                            <i class="fa fa-filter mr-2" aria-hidden="true"></i>
                            Status
                          </span>
                          <i class="fas fa-chevron-down"></i>
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapseOne"
                      class="collapse"
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <div className="stabtn">
                          <button className="stabtn_mainbtn"
                            variant="outlined"
                            onClick={() => setFilter("BuySell")}
                          >
                            <i className="fa fa-shopping-cart mr-2 stabtn_icons"></i><span className="stabtn_text">Sales</span> 
                          </button>
                          <button className="stabtn_mainbtn"
                            variant="outlined"
                            onClick={() => setFilter("Bid")}
                          >
                            <i class="fa fa-hand-paper-o mr-2 stabtn_icons"></i><span className="stabtn_text">Offers</span> 
                          </button>
                          <div className="text-center">
                            <button className="stabtn_mainbtn"
                              variant="outlined"
                              onClick={() => setFilter("Mint")}
                            >
                              <i class="fa fa-cart-plus mr-2 stabtn_icons"></i><span className="stabtn_text">Mints</span> 
                            </button>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="accordion">
                    <div class="card-header" id="headingTwo">
                      <h5 class="mb-0">
                        <button
                          class="btn btn-link filter-icon"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="true"
                          aria-controls="collapseTwo"
                        >
                          <span>$ Price</span>
                          <i class="fas fa-chevron-down"></i>
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseTwo"
                      class="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <div className="pricefil">
                          <div className="d-flex align-items-center justify-content-between gap-1 minmax">
                            <div className="input-group sideinput">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Min"
                                onChange={(e) => {
                                  setMin(e.target.value);
                                }}
                              />
                            </div>
                            to
                            <div className="input-group sideinput">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Max"
                                onChange={(e) => {
                                  setMax(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <button 
                            variant="outlined"
                            className="mt-3 create_btn btn_rect stabtn_applybtn"
                            onClick={() => {
                              setClicked(!Clicked);
                              (Filter !== "BuySell" || Filter !== "Mint") &&
                                setFilter("Mint");
                            }}
                          >
                            Apply{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={sidebar ? "expand filete1" : "shrink filete1" + " w-full"}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                
                    <div
                      className="tab-content explore_tab_content"
                      id="nav-tabContent"
                    >
       
                      <div
                        className="tab-pane fade show active"
                        id="activity"
                        role="tabpanel"
                        aria-labelledby="activity-tab"
                      >
                        <div className="proposal_panel_overall">
                        {ShowModal ? 
                            <>
                              <div className="text-center py-5">
                                <TailSpin
                                  wrapperClass="searreactloader"
                                  color="#00BFFF"
                                  height={100}
                                  width={70}
                                />
                              </div>
                            </>
                            :
                            <>
                      
                          <div className="followers_overall py-1">
                         
                            <div className="row masonry m-0 ma_no_gap card_main_iner">
                              <div className="col-12 col-md-12  mb-4 p-0">
                                <div className="table-responsive">
                                  <table class="table activtab">
                                    <thead>
                                      <tr>
                                        <th scope="col">Actions</th>
                                        <th scope="col">Items</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Quantity</th>
                                        <th scope="col">From</th>
                                        <th scope="col">To</th>
                                        <th scope="col">Time</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {Activity &&
                                        Activity.length > 0 &&
                                        Activity.map((Act) => {
                                          // {console.log("asdasdasdActivity",Act);}
                                          return (
                                            <tr>
                                              <th scope="row">
                                                <span className="">
                                                  {(Act.action === "BuySell" ||
                                                    Act.ifBid === "Accept") && (
                                                    <>
                                                      <i className="fa fa-shopping-cart mr-2"></i>{" "}
                                                      Sale
                                                    </>
                                                  )}
                                                  {Act.action === "Bid" &&
                                                    (Act.ifBid == "Bid" ||
                                                      Act.ifBid == "Edit") && (
                                                      <>
                                                        <i class="fa fa-hand-paper-o mr-2"></i>{" "}
                                                        Offer
                                                      </>
                                                    )}
                                                  {Act.action === "Onsale" && (
                                                    <>
                                                      <i class="fa fa-tag mr-2"></i>{" "}
                                                      List
                                                    </>
                                                  )}
                                                  {Act.action === "Mint" && (
                                                    <>
                                                      <i class="fa fa-cart-plus mr-2"></i>{" "}
                                                      Mint
                                                    </>
                                                  )}
                                                </span>
                                              </th>
                                              <td>
                                                <Link
                                                  to={
                                                    Act.to
                                                      ? `/info/${Act.Token.Con}/${Act.to}/${Act.Token.ID}`
                                                      : Act.action === "Mint"
                                                      ? `/info/${Act.Token.Con}/${Act.from}/${Act.Token.ID}`
                                                      : Act.action === "Bid"
                                                      ? Act.From && Act.From.Url
                                                        ? `/my-items/user/${Act.From.Url}`
                                                        : `/my-items/${Act.From.Address}`
                                                      : `/info/${Act.Token.Con}/${Act.to}/${Act.Token.ID}`
                                                  }
                                                >
                                                  <div className="d-flex tableprofimg">
                                                    {Act.Token &&
                                                    Act.Token.image
                                                      .split(".")
                                                      .pop() == "mp4" ? (
                                                      <video
                                                        id="my-video"
                                                        class="img-fluid"
                                                        autoPlay
                                                        playsInline
                                                        loop
                                                        muted
                                                        preload="auto"
                                                      >
                                                        <source
                                                          src={
                                                            Act.Token.Thumbnail
                                                              ? `${config.Back_URL}/compressedImage/${Act.Token.Source}/${Act.Token.Thumbnail}`
                                                              : `${config.Back_URL}/nftImg/${Act.Token.Source}/${Act.Token.image}`
                                                          }
                                                          type="video/mp4"
                                                        />
                                                      </video>
                                                    ) : (
                                                      <img
                                                        src={
                                                          Act.Token.Thumbnail
                                                            ? `${config.Back_URL}/compressedImage/${Act.Token.Source}/${Act.Token.Thumbnail}`
                                                            : `${config.Back_URL}/nftImg/${Act.Token.Source}/${Act.Token.image}`
                                                        }
                                                        alt="User"
                                                        className="img-fluid"
                                                      />
                                                    )}
                                                    <p className="mb-2 media_text">
                                                      {Act.Token.tokenName?.slice(0,20)}
                                                    </p>
                                                  </div>
                                                </Link>
                                              </td>
                                              <td>
                                                <div className="price-tab">
                                                  {Act.tokenPrice ? (
                                                     <img className="mr-1"
                                                     src={require("../assets/images/binance-coin-bnb-logo-freelogovectors.net_ (1).png")}
                                                   />
                                                  ) : (
                                                    ""
                                                  )}
                                                  <span>
                                                    {!Act.tokenPrice
                                                      ? "---"
                                                      : `${Act.tokenPrice} ${Act.istoken}`}
                                                  </span>
                                                  {Act.tokenPrice ? (
                                                    <p></p>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              </td>
                                              <td>{Act.NoofNFT}</td>
                                              <td>
                                                <Link
                                                  to={
                                                    Act.From && Act.From.Url
                                                      ? `/my-items/user/${Act.From.Url}`
                                                      : `/my-items/${Act.From.Address}`
                                                  }
                                                >
                                                  <a href="#">
                                                    {Act.From && Act.From.Name
                                                      ? Act.From.Name
                                                      : Act.from
                                                          .slice(0, 10)
                                                          }
                                                  </a>
                                                </Link>
                                              </td>

                                              <td>
                                                {Act.To.Url || Act.To.Address == "" ? "...":
                                                <Link
                                                  to={
                                                    Act.To && Act.To.Url
                                                      ? `/my-items/user/${Act.To.Url}`
                                                      : `/my-items/${Act.To.Address}`
                                                  }
                                                >
                                                  <a href="#">
                                                    {Act.To && Act.To.Name
                                                      ? Act.To.Name
                                                      : Act.to
                                                          .slice(0, 10)
                                                          }
                                                  </a>
                                                </Link>}
                                              </td>
                                              <td>
                                            
                                                  {CreatedTime(Act.created)}
                                               
                                              </td>
                                            </tr>
                                          );
                                        })}
                                    </tbody>
                                  </table>
                                </div>
                            
                              </div>
                            </div>
                            {Activity && Activity.length === 0 && (
                            <div className="text-center py-5">
                              <p className="not_found_text">No items found</p>
                              <p className="not_found_text_sub">
                                Come back soon! Or try to browse something for
                                you on our marketplace
                              </p>
                              <div className="mt-3">
                                <Link to="/explore/All">
                                <Button className="browsematdk">
                                  Browse Marketplace
                                </Button>
                                </Link>
                              </div>
                            </div>
                          )}
                          {LoadMore && (
                            <div className="pb-5 text-center pt-4">
                              <button
                                className="create_btn"
                                onClick={() => setPage(Page + 1)}
                              >
                                Load more
                              </button>
                            </div>
                          )}
                          </div>
                          </>
                          }
                        </div>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {/* edit_cover Modal */}
      <div
        class="modal fade primary_modal"
        id="edit_cover_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="edit_cover_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="edit_cover_modalLabel_1">
                Update cover
              </h5>
              <h5 class="modal-title d-none" id="edit_cover_modalLabel_2">
                Follow Steps
              </h5>

              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="update_cover_div_1" id="update_cover_div_1">
                <p className="mt-0 approve_desc text-center mb-4">
                  Upload new cover for your profile page. We recommended to
                  upload images in 1140×260 resolution
                </p>
                <form>
                  <div className="file_btn btn primary_btn">
                    Choose image
                    <input className="inp_file" type="file" name="file" />
                  </div>
                </form>
              </div>
              <div
                className="update_cover_div_2 d-none"
                id="update_cover_div_2"
              >
                <div className="media approve_media">
                  {/* <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i> */}
                  <i
                    class="fa fa-spinner mr-3 spinner_icon"
                    aria-hidden="true"
                  ></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Preferences</p>
                    <p className="mt-0 approve_desc">Updating cover</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="btn_outline_red btn-block">
                    Inprogress
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end edit_cover modal */}
      {/* SHARE Modal */}
      <div
        className="modal fade primary_modal"
        id="share_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="share_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="share_modalLabel">
                Share this NFT
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-0">
              <div className="row justify-content-center mx-0">
                <div className="col-3 col-sm-3 col-lg-3 px-1">
                  <div className="text-center icon_div">
                    <i className="fab fa-telegram-plane"></i>
                    <p>Telegram</p>
                  </div>
                </div>
                <div className="col-3 col-sm-3 col-lg-3 px-1">
                  <div className="text-center icon_div">
                    <i className="fab fa-twitter"></i>
                    <p>Twitter</p>
                  </div>
                </div>
                <div className="col-3 col-sm-3 col-lg-3 px-1">
                  <div className="text-center icon_div">
                    <i className="fab fa-facebook-f"></i>
                    <p>Facebook</p>
                  </div>
                </div>
                <div className="col-3 col-sm-3 col-lg-3 px-1">
                  <div className="text-center icon_div">
                    <i className="fab fa-whatsapp"></i>
                    <p>Whatsapp</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end SHARE modal */}
      {/* report Modal */}
      <div
        className="modal fade primary_modal"
        id="report_page_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="report_page_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="report_page_modalLabel">
                Report This Profile?
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <p className="font_14 font_600 line_20 mb-1 text_drk_bl">
                  Tell us how this user violates the rules of the site
                </p>
                <div className="form-row mt-3">
                  <div className="form-group col-md-12">
                    <label className="primary_label" for="name">
                      Message
                    </label>
                    <textarea
                      type="text"
                      className="form-control primary_inp"
                      id="name"
                      rows="3"
                      placeholder="Tell us some details"
                    />
                  </div>
                </div>
                <div className="text-center mt-2">
                  <button className="create_btn btn-block w-100" type="button">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end report modal */}
    </div>
  );
}
