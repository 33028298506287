import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import $ from 'jquery'
import Web3Utils from 'web3-utils'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { PurchaseCalculation } from 'actions/controller/PriceCalculate';
import { usePrevious } from 'hooks/useMethods';
import { ReciptCommon } from "hooks/useReceipts";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Web3 from 'web3';
import { Audio, TailSpin } from 'react-loader-spinner'
import { NightsStay } from '@material-ui/icons';
import { UpdateNFTFractions } from 'actions/controller/tokenControl';
import { PopupValidation } from 'actions/controller/validation';
import config from "lib/config"
import { toast } from 'react-toastify';
import { useNFTBalCheckToken } from 'hooks/useMethods';
import { ContactMintFraction, LMBalanceOfFraction } from 'actions/v1/token';
import { v1_ConNFTBalCheck } from 'actions/v1/Fractional'
import isEmpty from 'lib/isEmpty';
import { connectWallet, getServiceFees } from '../../hooks/useWallet';
import { Account_Connect } from "actions/redux/action";
import { network } from "../../views/network"
import randomInteger from 'random-int';
import Cookies from 'universal-cookie';
// import { socket } from '../../index'
export const PurchaseNow = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  // console.log("sdvsnvkfdnvkdfnvk", Wallet_Details);
  const [NoofToken, setNoofToken] = useState(1)
  const [NewTokenDecimal, setNewTokenDecimal] = useState('')
  const [Prices, setPrices] = useState({ Youwillpay: 0, PriceWithSerfee: "", ApprovePriceWithSerfee: "", PriceWithoutSerfee: "" })
  const [NFT, setNFT] = useState({ CurrentOwner: {} })
  const [Buystate, setBuystate] = useState(false)
  const [tokName, settokName] = useState("")
  const [SignTxt, SetSignTxt] = useState("Start");
  const prevNoofToken = usePrevious(NoofToken)
  const history = useHistory()

  // nft details set for lazyminting
  const [tokDetails, setTokDetails] = useState("")       // nft detaisl in token collection
  const [Fiat, setFiat] = useState(false);
  const [App_Btn, SetApp_Btn] = useState("Start")
  const [Transcation, SetTransCation] = useState({})
  useImperativeHandle(
    ref,
    () => ({
      async PurchaseNowClick(ClickedNFT, tokenName, tokenDetails) {
        settokName(tokenName)
        setNFT(ClickedNFT)
        setTokDetails(tokenDetails)   //added for lazymint
        setFiat(ClickedNFT.Fiat)
        console.log("token details", ClickedNFT, tokenName, tokenDetails)

        var networkConfiguration = {}
        var chainid = tokenDetails?.SelectedNetwork
        const chainId = tokenDetails?.SelectedNetwork
        //await Wallet_Details.Web3Pro.eth.getChainId();
        console.log("fgndfgn", chainid, Wallet_Details.networkConfiguration);
        var accountDetailsss = ''
        console.log("dfbhsfdbn", ClickedNFT, Wallet_Details.networkConfiguration.Chainid, tokenDetails?.SelectedNetwork);
        Serfee(Number(chainid));
        if (Wallet_Details.networkConfiguration.Chainid == tokenDetails?.SelectedNetwork) {
          console.log("dknvjksdnkfsnjkfd", Wallet_Details.networkConfiguration.Chainid == tokenDetails?.SelectedNetwork);
          accountDetailsss = true
        }
        else {
          console.log("it comming in else switch", networkConfiguration);
          console.log("sdfsfdfsdfsdfs", Number(chainid), network.MATIC.Chainid);
          if (Number(chainid) === network.MATIC.Chainid) {
            console.log("netter eorrrrkkk maticz", network.MATIC);
            networkConfiguration = network.MATIC
          }
          else if (Number(chainid) === network.ETH.Chainid) {
            console.log("netter eorrrrkkk", network.ETH);
            networkConfiguration = network.ETH
          }
          else if (Number(chainid) === network.CAM.Chainid) {
            console.log("netter eorrrrkkk", network.CAM);
            networkConfiguration = network.CAM
          }
          console.log("qeweqweqweasdas", chainId, networkConfiguration.chainid);
          if (Number(networkConfiguration.chainid) !== Number(chainId)) {
            var accountDetailsss = await switchNetwork(chainid, networkConfiguration)
          }
        }

        console.log("stwerwerwer", Wallet_Details);
        console.log("cdsncsdlcslmclds", chainid === String(Wallet_Details.networkConfiguration.Chainid), chainid, String(Wallet_Details?.networkConfiguration?.Chainid));
        console.log("it comming in else", Number(chainid) === Number(networkConfiguration?.Chainid));
        console.log("smdnvsdnkfsnd", accountDetailsss, Wallet_Details, Number(chainid), Number(networkConfiguration?.Chainid));
        if (Number(chainid) === Number(chainId)) {
          console.log("dnkdnfksdnfkjsdnf");
          window.$('#buy_modal').modal('show');
          console.log("gerkmgoemfgfdmve", Wallet_Details);
          var data = { Price: ClickedNFT.tokenPrice, Quantity: NoofToken, Serfee: Wallet_Details.Service_Fee_Fraction, Decimal: 18 }
          console.log("data for purchase calculation", data);
          var Data = PurchaseCalculation(data)
          console.log("dsnkjdnkdnkdacnd ncjask", Data);
          setPrices(Data)
          // console.log("jdsnbfufnuewrgn", Data)

        }
      }
    }))

  const Serfee = async (Address) => {
    console.log("khlhujnoasdasdasdikljiuio", Address);
    var Fee = await getServiceFees(Address)
    console.log("ndkdsnfkdsn", Fee);
    dispatch({
      type: Account_Connect,
      Account_Detail: {
        Service_Fee: Fee?.Serfee,
        Service_Fee_Fraction: Fee?.SerfeeFraction
      }
    })
  }

  async function switchNetwork(configdata, networkConfiguration) {
    var type = ""
    var networkConfiguration = {}
    const chainId = await Wallet_Details.Web3Pro.eth.getChainId();
    console.log("adsadasdasd", configdata, chainId, networkConfiguration);
    // if(configdata ==)
    console.log("dfadsaddasdadas", Wallet_Details, localStorage.walletConnectType);
    if (localStorage.walletConnectType !== "Torus") {
      if (Number(configdata) !== Number(chainId)) {
        const id = toast.loading("Switching Network", { closeButton: true, theme: "dark" });
        // console.log("sdnfvnfkvnfs", configdata);
        if (configdata) {
          if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask') {

            type = "MetaMask"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'WalletConnect' && localStorage.walletConnectType != null) {
            type = "WalletConnect"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'Venly' && localStorage.walletConnectType != null) {
            type = "Venly"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'Torus' && localStorage.walletConnectType != null) {
            type = "Torus"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'TorusWallet' && localStorage.walletConnectType != null) {
            type = "TorusWallet"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'LMWallet' && localStorage.walletConnectType != null) {
            console.log("adasd comming on torus");
            type = "LMWallet"
            localStorage.setItem("ChainId", tokDetails.SelectedNetwork)
          }
          // console.log("connecttype............", type, configdata)
          var accountDetails = await connectWallet(type, Number(configdata), localStorage.walletConnectType == "LMWallet" ? "register" : "Create", Wallet_Details, "Tor")
          // console.log("accountDetailsin create page......................", accountDetails)

          // var web3 = new Web3(window.ethereum);
          // console.log("dfghrtfh", web3);
          // if (window.ethereum.isMetaMask == true) {

          const chainId = await accountDetails?.web3?.eth?.getChainId();
          // console.log("fghdtgj", chainId);

          if (Number(configdata) === network.MATIC.Chainid) {
            networkConfiguration = network.MATIC
          }
          else if (Number(configdata) === network.ETH.Chainid) {
            networkConfiguration = network.ETH
          }
          else if (Number(configdata) === network.CAM.Chainid) {
            networkConfiguration = network.CAM
          }


          // }
          // setPriceoption()
          console.log("dsnsdkngksfnvkfdn", networkConfiguration);
          if (accountDetails != '' && accountDetails?.web3?._provider != '') {
            // console.log("Wallet Connecting...increate", accountDetails.web3._provider);
            dispatch({
              type: Account_Connect,
              Account_Detail: {
                UserAccountAddr: accountDetails.accountAddress,
                UserAccountBal: accountDetails.coinBalance,
                WalletConnected: "true",
                Wen_Bln: accountDetails.tokenBalance,
                Accounts: accountDetails.accountAddress,
                Web3Pro: accountDetails.web3,
                providerss: accountDetails.web3._provider,
                networkConfiguration: networkConfiguration
              }
            })
            Serfee(networkConfiguration.Chainid);
          }
          if (networkConfiguration.currencySymbol) {
            toast.update(id, {
              render: "Network switched Succesfully",
              type: "success",
              isLoading: false,
              autoClose: 2500,
              closeButton: true,
              theme: "dark"
            });
          }
        }



        //   var ReqData = {
        //     addr: String(accountDetails.accountAddress).toLowerCase()
        // }
        // var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
        // if (Resp && Resp.data && Resp.data.data && Resp.data.data.User) {
        //     return Resp.data.data.User
        // } else {
        //     return null
        // }





        return accountDetails
      } else {
        dispatch({
          type: Account_Connect,
          Account_Detail: {
            UserAccountAddr: Wallet_Details?.UserAccountAddr,
            UserAccountBal: Wallet_Details?.UserAccountBal,
            WalletConnected: "true",

            Wen_Bln: Wallet_Details?.Wen_Bln,
            Accounts: Wallet_Details?.Accounts,

            Web3Pro: Wallet_Details?.Web3Pro,
            providerss: Wallet_Details?.providerss,
            networkConfiguration: networkConfiguration
          }
        })
        Serfee(networkConfiguration.Chainid);
        return Wallet_Details.Web3Pro
      }
    } else {
      try {
        const id = toast.loading("Switching Network", { closeButton: true, theme: "dark" });
        var accountDetails = await connectWallet(localStorage.walletConnectType, Number(configdata), "Create", Wallet_Details, "Torus")
        // if (Number(networkNUM) == 97) {
        //   console.log("Comming on last else for switchCahin", Wallet_Details.web3auth, Wallet_Details?.web3);
        //   var addingChain = await Wallet_Details.web3auth.addChain({
        //     chainId: "0x61",
        //     displayName: "binance",
        //     chainNamespace: CHAIN_NAMESPACES.EIP155,
        //     tickerName: "BNB Smart Chain Testnet",
        //     ticker: "BNB",
        //     decimals: 18,
        //     rpcTarget: "https://data-seed-prebsc-1-s1.binance.org:8545/",
        //     blockExplorer: "https://testnet.bscscan.com/",
        //   });
        //   console.log("addingChain Web3Auth", addingChain);
        //   var switched = await Wallet_Details.web3auth.switchChain({ chainId: "0x61" });
        //   console.log("switched in create page uh", switched);
        // }else{
        //   console.log("Comming on last else for switchCahin", Wallet_Details.web3auth, Wallet_Details?.web3);
        //   var addingChain = await Wallet_Details.web3auth.addChain({
        //     chainId: "0x13881",
        //     displayName: "Mumbai",
        //     chainNamespace: CHAIN_NAMESPACES.EIP155,
        //     tickerName: "Mumbai",
        //     ticker: "MATIC",
        //     decimals: 18,
        //     rpcTarget: "https://matic-mumbai.chainstacklabs.com",
        //     blockExplorer: "https://mumbai.polygonscan.com/",
        //   });
        //   console.log("addingChain Web3Auth", addingChain);
        //   var switched = await Wallet_Details.web3auth.switchChain({ chainId: "0x13881" });
        //   console.log("switched in create page uh", switched);
        // }
        console.log("asdasdasdasdas", accountDetails);
        const chainId = await accountDetails?.web3?.eth?.getChainId();
        console.log("fghdtgj", configdata, network, chainId);

        if (Number(configdata) === network.MATIC.Chainid) {
          networkConfiguration = network.MATIC
        }
        else if (Number(configdata) === network.ETH.Chainid) {
          networkConfiguration = network.ETH
        }
        else if (Number(configdata) === network.CAM.Chainid) {
          networkConfiguration = network.CAM
        }
        console.log("huiyooijmoijolmj", networkConfiguration);
        if (accountDetails != '') {
          dispatch({
            type: Account_Connect,
            Account_Detail: {
              UserAccountAddr: accountDetails?.accountAddress,
              UserAccountBal: accountDetails?.coinBalance,
              WalletConnected: "true",

              Wen_Bln: accountDetails?.tokenBalance,
              Accounts: accountDetails?.accountAddress,

              Web3Pro: accountDetails?.web3,
              providerss: accountDetails?.web3?._provider,
              networkConfiguration: networkConfiguration
            }
          })
        }
        var functiobn = await NefunctionTriger(Wallet_Details)
        console.log("Asdasdaeqweqwe", networkConfiguration.currencySymbol);
        if (networkConfiguration.currencySymbol) {
          toast.update(id, {
            render: "Network switched Succesfully",
            type: "success",
            isLoading: false,
            autoClose: 2500,
            closeButton: true,
            theme: "dark"
          });
        }
      } catch (e) {
        console.log("adqeqweqweqweqweqweqwe", e);
      }
    }

  }

  const NefunctionTriger = async (Wallet_Details1) => {
    console.log("Wallet_Details1", Wallet_Details1);
    const chainIdSwitched = await Wallet_Details1?.Web3Pro?.eth?.getChainId();
    var balance = await Wallet_Details1?.Web3Pro?.eth.getBalance(Wallet_Details1.UserAccountAddr);
    console.log("chainIdSwitched", chainIdSwitched, balance);
    return chainIdSwitched
  }

  useEffect(() => {
    if (NoofToken !== 1 && prevNoofToken !== NoofToken) {
      var data = { Price: NFT.tokenPrice, Quantity: NoofToken, Serfee: Wallet_Details.Service_Fee_Fraction, Decimal: NewTokenDecimal }
      var Data = PurchaseCalculation(data)
      setPrices(Data)
    }
  }, [NoofToken])

  const ValidCheck = () => {
    var ValChk = { TokenPrice: Prices.Youwillpay, NoofToken: NoofToken, type: NFT.type, MaxToken: NFT.balance }
    var Valid = PopupValidation(ValChk)
    if (Valid.Success) {
      var Check = (Wallet_Details.UserAccountBal < Prices.Youwillpay)
      if (Check) {
        toast.error("Insufficient Balance", { theme: "dark" })
      }
      else {
        console.log("Commmmmmmmm");
        BalCheck()
      }

    }
    else
      toast.error(Valid.Message, { theme: "dark" })
  }

  const BalCheck = async () => {
    const id = toast.loading("Checking Balance & Validating", { closeButton: true });
    var chainid = tokDetails?.SelectedNetwork
    var networkConfiguration = {}
    console.log("sdfsfdfsdfsdfs", Number(chainid), network.MATIC.Chainid);
    if (Number(chainid) === network.MATIC.Chainid) {
      console.log("netter eorrrrkkk maticz", network.MATIC);
      networkConfiguration = network.MATIC
    }
    else if (Number(chainid) === network.ETH.Chainid) {
      console.log("netter eorrrrkkk", network.ETH);
      networkConfiguration = network.ETH
    }
    else if (Number(chainid) === network.CAM.Chainid) {
      console.log("netter eorrrrkkk", network.CAM);
      networkConfiguration = network.CAM
    }
    Wallet_Details.networkConfiguration = networkConfiguration
    var Arg = {
      NFTConAddress: NFT.ERC20_Address,
      NFTId: NFT.tokenID,
      NFTOwner: NFT.tokenOwner,
      Status: NFT.LazyStatus,
      BulK: tokDetails.Bulkmint
    }
    var Type = NFT.type
    // var ContractNFTBal = await useNFTBalCheck(Arg, Type, Wallet_Details)
    console.log("adasdsadasdasd", tokDetails.Bulkmint);
    console.log("else iffffff");
    if (localStorage.walletConnectType !== "LMWallet") {
      var ContractNFTBal = await NFTHookFun(Arg, Wallet_Details)
      console.log("sdqeqwwqeqwqweqw", ContractNFTBal);
    } else {
      var key = null

      var Argument = [Arg.NFTOwner]
      var ContractNFTBal = await LMBalanceCheck(Arg, Type, Wallet_Details, Arg.NFTConAddress, Type == 721 ? "Single" : "Multiple", Arg.NFTConAddress, "balanceOf", Argument, key, Arg.NFTOwner)
    }

    console.log("ContractNFTBal", ContractNFTBal)
    if (Number(ContractNFTBal) !== NFT.balance) {
      Arg.balance = Number(ContractNFTBal)
      var Updated = await v1_ConNFTBalCheck(Arg)
      // console.log("fjbdshfbsfhsr", Updated)
      if (Updated.data && Updated.data.Success) {
        // toast.error("Owners NFT Balance Changed. Try Later", { autoClose: 4000, closeButton: true })
        toast.update(id, { render: "Owners NFT Balance Changed. Try Later", type: "error", isLoading: false, autoClose: 2500, });
        setTimeout(() => {
          history.push('/explore/All')
        }, 1500);
      }
      else {
        //toast.error("Error Occured. Try Later", { autoClose: 3000, closeButton: true })
        toast.update(id, { render: "Error Occured. Try Later", type: "error", isLoading: false, autoClose: 2500, });
        setTimeout(() => {
          history.push('/explore/All')
        }, 1500);
      }
    }
    else {
      // alert("domaru")
      // console.log("buycalsds")
      // PurchaseMint()
      toast.update(id, { render: "Validation Success", type: "success", isLoading: false, autoClose: 2500, });
      $('#hiddenbutton').trigger("click");
    }
  }

  const NFTHookFun = async (Arg, Wallet_Details) => {
    var ContractNFTBal = await useNFTBalCheckToken(Arg, Wallet_Details)
    return ContractNFTBal
  }

  const LMBalanceCheck = async (Arg, Type, Wallet_Details, IntractContact, NameUse, Contract, method, data, Key, tokenOwner) => {
    console.log("LMBalanceCheck", Arg, Type, Wallet_Details, IntractContact, NameUse, Contract, method, data, Key);
    var Arg = {
      Method: method,
      Data: data,
      Mail: localStorage.loggedinaddress,
      LoginAddress: localStorage.userAddress,
      ContactAddress: Contract,
      Type: String(Type),
      Chain: String(tokDetails.SelectedNetwork),
      IntractContract: IntractContact,
      ContactType: NameUse,
      value: Prices.PriceWithSerfee,
      Key: Key,
      tokenOwner: tokenOwner
    }
    var Balance = await LMBalanceOfFraction(Arg)
    console.log("Balance", Balance);
    return Balance.data.receipt
  }
  const PurchaseMint = async () => {
    console.log("nft hash status", NFT, tokDetails)
    PruchaseCall();
  }

  // completed
  const PruchaseCall = async () => {
    setBuystate(true)
    const id = toast.loading("Token Purchase Initiated", { closeButton: true });
    console.log('nft token detial', tokDetails, NFT, Wallet_Details)
    var chainid = tokDetails?.SelectedNetwork
    var networkConfiguration = {}
    if (Number(chainid) === network.MATIC.Chainid) {
      networkConfiguration = network.MATIC
    }
    else if (Number(chainid) === network.ETH.Chainid) {
      networkConfiguration = network.ETH
    }
    else if (Number(chainid) === network.CAM.Chainid) {
      networkConfiguration = network.CAM
    }
    console.log("Prices.PriceWithSerfee", Prices);
    var Royaltity = Web3.utils.toWei(String(tokDetails?.tokenRoyality))
    var tokendbPrice = tokDetails?.tokenPrice == 0 ? 0 : Web3.utils.toWei(String(tokDetails?.tokenPrice))

    Wallet_Details.networkConfiguration = networkConfiguration
    var Arg = { NFTOwner: NFT.tokenOwner, NFTId: NFT.tokenID, PriceWithoutSerfee: Prices.PriceWithoutSerfee, PriceWithSerfee: Prices.PriceWithSerfee, NoofNFTs: NoofToken, isToken: false, TotalToken: NFT.quantity, Royaltity: Royaltity, tokendbPrice: tokendbPrice }
    if (localStorage.walletConnectType !== "LMWallet") {
      var Argument = [NFT.tokenCreator, [NFT.tokenID, Arg.PriceWithoutSerfee, Arg.NoofNFTs], "Coin"]
      var Receipt = await ReciptCommon(Argument, Wallet_Details, "saleToken", true, Prices.PriceWithSerfee)
    } else {
      var Key = null
      var AdAdd = null
      var Argument = [NFT.tokenCreator, [NFT.tokenID, Arg.PriceWithoutSerfee, Arg.NoofNFTs], "Coin"]
      var Receipt = await LMWalletCall(Argument, Wallet_Details, "saleToken", "Mint", Key, null, true)
    }
    console.log("returner repsonse receipt", Receipt)
    if (Receipt.status) {
      var updateData = {
        hashValue: Receipt.transactionHash,
        tokenID: String(NFT.tokenID),
        status: Receipt?.status,
      }
      var NewOwnerDetail = { Status: Receipt?.status, Hash: Receipt.transactionHash, ConAddr: NFT.contractAddress, Owns: NoofToken, NFTDetail: NFT, Action: "Purchase", NewOwner: Wallet_Details.UserAccountAddr, OldOwner: NFT.tokenOwner, coinname: NFT.coinname, tokenName: tokName, Collection: tokDetails.Collection, updateData: updateData, LazyStatus: "completed", SelectedNetwork: tokDetails.SelectedNetwork, QR_tokenID: NFT.QR_tokenID, ServiceFee: Wallet_Details.Service_Fee_Fraction.Buyer, Fiat: false, Charity: NFT.Charity, NFTPrice: Prices.PriceWithoutSerfee / 1e18, NFTPriceWithSerice: Prices.PriceWithSerfee / 1e18, ERC20_Address: NFT.ERC20_Address, ERCTrade: NFT.ERC20_Address }
      // console.log("newowner deetail", NewOwnerDetail, updateData,)
      var Resp = await UpdateNFTFractions(NewOwnerDetail)
      if (Resp) {
        $('#buy_closs').trigger("click");
        toast.update(id, { render: "Token Purchased Successfully", type: "success", isLoading: false, autoClose: 2500, });
        setTimeout(() => {
          history.push(`/my-items/${Wallet_Details.UserAccountAddr}`)
        }, 1000);
      }
    }
    else {
      toast.update(id, { render: "Try it Again Later.Please Check Your Funds", type: "error", isLoading: false, autoClose: 2500, });

      // toast.error("Try it Again Later.Please Check Your Funds")
    }
    setBuystate(false)



  }


  const selectChange = async (e) => {
    // console.log("fkdjdsfsdgjfsldfg", e);
    if ((e).length <= 12) {
      setNoofToken(e)
    } else {
      setNoofToken(0)
    }

  }

  //completed
  const FiatPurchase = async (FiatBuy) => {
    console.log("FiatBuy", FiatBuy);
    const id = toast.loading("Token Purchase Initiated", { closeButton: true });
    setBuystate(true)
    console.log('nft token detial', tokDetails, NFT, Wallet_Details)
    var chainid = tokDetails?.SelectedNetwork
    var networkConfiguration = {}
    console.log("sdfsfdfsdfsdfs", Number(chainid), network.MATIC.Chainid);
    if (Number(chainid) === network.MATIC.Chainid) {
      console.log("netter eorrrrkkk maticz", network.MATIC);
      networkConfiguration = network.MATIC
    }
    else if (Number(chainid) === network.ETH.Chainid) {
      console.log("netter eorrrrkkk", network.ETH);
      networkConfiguration = network.ETH
    }
    else if (Number(chainid) === network.CAM.Chainid) {
      console.log("netter eorrrrkkk", network.CAM);
      networkConfiguration = network.CAM
    }

    console.log("Prices.PriceWithSerfee", Prices);
    var tokendbPrice = tokDetails?.tokenPrice == 0 ? 0 : Web3.utils.toWei(String(tokDetails?.tokenPrice))

    if (localStorage.walletConnectType !== "LMWallet") {
      var Argument = [NFT.tokenCreator, [NFT.tokenID, tokendbPrice, NoofToken, tokDetails?.NonceHash, tokendbPrice], tokDetails.RandomName, tokDetails?.SignatureHash]
      var Receipt = await ReciptCommon(Argument, Wallet_Details, "saleWithFiat", true, 0)
    } else {
      var Key = null
      var AdAdd = null
      var Argument = [NFT.tokenCreator, [NFT.tokenID, tokendbPrice, NoofToken, tokDetails?.NonceHash, tokendbPrice], tokDetails.RandomName, tokDetails?.SignatureHash]
      var Receipt = await LMWalletCall(Argument, Wallet_Details, "saleWithFiat", "Mint", Key, null, false)
    }

    console.log("Receipt", Receipt);
    if (Receipt.status) {
      // console.log("gyugduasguisgcshg", NFT, tokDetails);
      var updateData = {
        hashValue: Receipt.transactionHash,
        tokenID: String(NFT.tokenID),
        status: Receipt?.status
      }
      var NewOwnerDetail = { Status: Receipt?.status, Hash: Receipt.transactionHash, ConAddr: NFT.contractAddress, Owns: NoofToken, NFTDetail: NFT, Action: "Purchase", NewOwner: Wallet_Details.UserAccountAddr, OldOwner: NFT.tokenOwner, coinname: NFT.coinname, tokenName: tokName, Collection: tokDetails.Collection, updateData: updateData, LazyStatus: "completed", SelectedNetwork: tokDetails.SelectedNetwork, QR_tokenID: NFT.QR_tokenID, ServiceFee: Wallet_Details.Service_Fee.Buyer, FiatBuy: FiatBuy, Fiat: false, Charity: NFT.Charity, NFTPrice: Prices.PriceWithoutSerfee / 1e18, NFTPriceWithSerice: Prices.PriceWithSerfee / 1e18, ERC20_Address: NFT.ERC20_Address, ERCTrade: NFT.ERC20_Address }
      console.log("newowner deetail", NewOwnerDetail, updateData, FiatBuy, Wallet_Details.Service_Fee_Fraction.Buyer)
      var Resp = await UpdateNFTFractions(NewOwnerDetail)
      console.log("RespResp", Resp);
      if (Resp) {
        toast.update(id, { render: "Token Purchased Successfully", type: "success", isLoading: false, autoClose: 2500, });
        $('#buy_closs').trigger("click");
        setTimeout(() => {
          history.push(`/my-items/${Wallet_Details.UserAccountAddr}`)
        }, 1000);
      }
    }
    else {
      toast.update(id, { render: "Try it Again Later.Please Check Your Funds", type: "error", isLoading: false, autoClose: 2500, });

    }
    setBuystate(false)

  }

  const LMWalletCall = async (Arg, Wallet_Details, method, NameUse, Key, TokenAddress, status) => {
    var receipt = null
    console.log("Comming Inside the LmWallet", Arg, Wallet_Details, method, NameUse, Key, TokenAddress, tokDetails);
    var Arg = {
      Method: method,
      Data: Arg,
      Mail: localStorage.loggedinaddress,
      LoginAddress: localStorage.userAddress,
      ContactAddress: Wallet_Details.networkConfiguration.FractionTradeContract,
      Chain: String(tokDetails.SelectedNetwork),
      ContactType: NameUse,
      IntractContract: Wallet_Details.networkConfiguration.FractionTradeContract,
      Key: Key,
      Status: status,
      TokenAddress: TokenAddress,
      value: Prices.PriceWithSerfee
    }
    var Receiptfun = await ContactMintFraction(Arg)

    console.log("ArgReceipt", Receiptfun.data.receipt);
    return Receiptfun.data.receipt
  }


  return (
    <>
      {/* buy Modal */}
      <div
        className="modal fade primary_modal"
        id="buy_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="buy_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >

          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="buy_modalLabel">
                Checkout
              </h5>
              <button
                id="buy_closs"
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-0">
              {/* {console.log("price fee condition", Prices.PriceWithSerfee)} */}
              {!(Prices.PriceWithSerfee) ?
                <><TailSpin wrapperClass="reactloader mt-0 mb-3" color="#00BFFF" height={100} width={70} /></>
                :
                <>
                  {!Fiat ?
                    <>
                      <div className="row mx-0">
                        <div className="col-12 col-md-6 px-4">
                          <p className="buy_title_sm">Seller</p>
                          <p className="buy_title_md">{(NFT.CurrentOwner.name) ? NFT?.CurrentOwner?.name?.slice(0, 10) : (NFT.CurrentOwner.curraddress).slice(0, 10)}</p>
                        </div>
                        <div className="col-12 col-md-6 px-4">
                          <p className="buy_title_sm text-md-right">Buyer</p>
                          <p className="buy_title_md text-md-right">{(Wallet_Details.UserAccountAddr).slice(0, 10)}</p>
                        </div>
                      </div>
                      {/* {console.log("sdfsdfsdfsdfsd", NFT)} */}
                      <div className="">
                        <p className="buy_title_md px-4 py-3 text-center mb-0">
                          {Prices.Youwillpay} {NFT.coinname}
                        </p>
                      </div>
                      {(NFT.type === 721) &&
                        <div className="mx-3">
                          <label htmlFor="qty" className="buy_desc_sm">
                            Enter quantity{" "}
                            <span className="label_muted pl-2">({NFT.balance} available)</span>
                          </label>
                          <div className="mb-3 input_grp_style_1">
                            <input
                              type="text"
                              id="qty"
                              className="form-control"
                              placeholder="1"
                              value={NoofToken}
                              onChange={(e) => { selectChange(e.target.value) }}
                            />
                          </div>
                        </div>
                      }

                      {/* {console.log("zdafasdafzdczc", NFT)} */}
                      <div className="row mx-0 pb-3">
                        <div className="col-12 col-sm-6 px-4">
                          <p className="buy_desc_sm">Your balance</p>
                        </div>
                        <div className="col-12 col-sm-6 px-4 text-sm-right">
                          <p className="buy_desc_sm_bold">{(Wallet_Details?.UserAccountBal)?.toFixed(5)} {NFT.coinname}</p>
                        </div>
                      </div>
                      <div className="row mx-0 pb-3">
                        <div className="col-12 col-sm-6 px-4">
                          <p className="buy_desc_sm">Service fee</p>
                        </div>
                        <div className="col-12 col-sm-6 px-4 text-sm-right">
                          <p className="buy_desc_sm_bold">{Wallet_Details.Service_Fee_Fraction.Buyer}%</p>
                        </div>
                      </div>
                      <div className="row mx-0 pb-3">
                        <div className="col-12 col-sm-6 px-4">
                          <p className="buy_desc_sm">Price of the NFT</p>
                        </div>
                        <div className="col-12 col-sm-6 px-4 text-sm-right">
                          <p className="buy_desc_sm_bold">{NFT.tokenPrice} {config.currencySymbol}</p>
                        </div>
                      </div>
                      <form className="px-4">
                        <div className="text-center">
                          <Button
                            className="d-none"
                            id="hiddenbutton"
                            data-dismiss="modal"
                            aria-label="Close"
                            data-toggle="modal"
                            data-target="#proceed_modal"
                          >
                            {"Proceed to Payment"}
                          </Button>
                          <Button
                            className="create_btn btn-block"
                            onClick={() => ValidCheck()}
                          >
                            Proceed to Payment
                          </Button>
                          <Button
                            className="btn_outline_red btn-block"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            Cancel
                          </Button>
                        </div>
                      </form>
                    </>
                    :
                    <>
                      <div className="row mx-0">
                        <div className="col-12 col-md-6 px-4">
                          <p className="buy_title_sm">Seller</p>
                          <p className="buy_title_md">{(NFT.CurrentOwner.name) ? NFT?.CurrentOwner?.name?.slice(0, 10) : (NFT.CurrentOwner.curraddress).slice(0, 10)}</p>
                        </div>
                        <div className="col-12 col-md-6 px-4">
                          <p className="buy_title_sm text-md-right">Buyer</p>
                          <p className="buy_title_md text-md-right">{(Wallet_Details.UserAccountAddr).slice(0, 10)}</p>
                        </div>
                      </div>
                      {/* {console.log("sdfsdfsdfsdfsd", NFT)} */}
                      <div className="">
                        <p className="buy_title_md px-4 py-3 text-center mb-0">
                          {Math.round((Prices.Youwillpay + Number.EPSILON) * 100) / 100} {NFT.coinname}
                        </p>
                      </div>
                      {(NFT.type === 721) &&
                        <div className="mx-3">
                          <label htmlFor="qty" className="buy_desc_sm">
                            Enter quantity{" "}
                            <span className="label_muted pl-2">({NFT.balance} available)</span>
                          </label>
                          <div className="mb-3 input_grp_style_1">
                            <input
                              type="text"
                              id="qty"
                              className="form-control"
                              placeholder="1"
                              value={NoofToken}
                              onChange={(e) => { selectChange(e.target.value) }}
                            />
                          </div>
                        </div>
                      }

                      {/* {console.log("zdafasdafzdczc", NFT)} */}
                      <div className="row mx-0 pb-3">
                        <div className="col-12 col-sm-6 px-4">
                          <p className="buy_desc_sm">Service fee</p>
                        </div>
                        <div className="col-12 col-sm-6 px-4 text-sm-right">
                          <p className="buy_desc_sm_bold">{Wallet_Details.Service_Fee_Fraction.Buyer}%</p>
                        </div>
                      </div>
                      <div className="row mx-0 pb-3">
                        <div className="col-12 col-sm-6 px-4">
                          <p className="buy_desc_sm">Price of the NFT</p>
                        </div>
                        <div className="col-12 col-sm-6 px-4 text-sm-right">
                          <p className="buy_desc_sm_bold">{NFT.tokenPrice} {config.currencySymbol}</p>
                        </div>
                      </div>
                      <form className="px-4">
                        {console.log("anxjchdsfask", Math.round((Prices.Youwillpay + Number.EPSILON) * 100) / 100, Prices, config.PayPalKey)}
                        <PayPalScriptProvider options={{
                          "client-id": config.PayPalKey
                        }}>
                          {/* {console.log("iooioiwq", config.PayPalKey, UpfeePayPal)} */}
                          <PayPalButtons
                            style={{ layout: "vertical" }}
                            disabled={App_Btn == "Done" ? true : false}
                            forceReRender={[Prices]}

                            createOrder={(data, actions) => {
                              SetApp_Btn("process")
                              return actions.order
                                .create({
                                  purchase_units: [
                                    {
                                      amount: {
                                        currency_code: "USD",
                                        value: Math.round((Prices.Youwillpay + Number.EPSILON) * 100) / 100

                                        //Prices.Youwillpay,
                                      },
                                    },
                                  ],
                                })
                                .then((orderId) => {
                                  console.log("qewweqweqwqe", orderId);
                                  return orderId;
                                });
                            }}
                            onApprove={(data, actions) => {
                              return actions.order.capture().then((details) => {
                                console.log("asdasdasdas", details);
                                const Data = {
                                  Buyername: details.payer.name.given_name,
                                  Surname: details.payer.name.surname,
                                  id: details.purchase_units[0].payments.captures[0].id,
                                  TranscationId: details.id,
                                  email_address: details.payer.email_address,
                                  payer_id: details.payer.payer_id,
                                  status: details.status,
                                  Address: details.payer.address.country_code
                                }
                                SetApp_Btn("Done")
                                SetTransCation(Data)
                                console.log("qqweweoioiio", Data.Buyername);
                                // if(Data.TranscationId !== null || undefined){
                                console.log("NFT.SaleStatus", NFT.SaleStatus, tokDetails.Bulkmint, NFT.hashValue);
                                // if ((NFT.SaleStatus == "Create") && (tokDetails.Bulkmint == "NFT") && (NFT.hashValue == "not yet minted")) {
                                FiatPurchase(Data)
                                // } else if ((NFT.SaleStatus == "Resale") && (tokDetails.Bulkmint == "NFT") && (NFT.hashValue !== "not yet minted")) {
                                //   TransferCall(Data)
                                // } else if ((NFT.SaleStatus == "Create") && (tokDetails.Bulkmint == "NFT") && (NFT.hashValue !== "not yet minted")) {
                                //   TransferCall(Data)
                                // } else if (tokDetails.Bulkmint !== "NFT") {
                                //   console.log("Comming inside the Bulkmint");
                                //   ImageMergeForFiat(Data)
                                // }
                                // }
                                // toast.success("Payment Successfully Done", { autoClose: 1000 });

                              });
                            }}
                            onError={(err) => {
                              console.log("qweqeqwe", err);
                              SetApp_Btn("Try Again")
                              toast.error(err, { autoClose: 1000 });
                            }}

                          />
                        </PayPalScriptProvider>
                      </form>
                    </>
                  }
                </>
              }
            </div>
          </div>
        </div>
      </div>
      {/* end buy modal */}

      {/* proceed Modal */}
      <div
        className="modal fade primary_modal"
        id="proceed_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="proceed_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="proceed_modalLabel">
                Follow Steps
              </h5>
              <button
                id="buy_closs"
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                {false &&
                  <>
                    <p className="mt-0 purchase_text text-center">Approve</p>
                    <p className="mt-0 purchase_desc text-center">
                      Approve transaction with your wallet
                    </p>

                    <div className="text-center my-3">
                      <Button className="btn_outline_red btn-block">
                        Inprogress
                      </Button>
                    </div>
                  </>
                }
                <p className="mt-0 purchase_text text-center">Purchase</p>
                <p className="mt-0 purchase_desc text-center">
                  Send transaction with your wallet
                </p>

                <div className="text-center my-3">
                  {/* Newly Added */}
                  {console.log("qweqweqweqpopopopopo", Buystate, SignTxt)}
                  <Button className="create_btn btn-block" disabled={SignTxt == "progress" ? true : false} onClick={() => { PurchaseMint() }}>
                    {Buystate && (
                      <i
                        class="fa fa-spinner mr-3 spinner_icon"
                        aria-hidden="true"
                      ></i>
                    )}{(Buystate) ? "Inprogress" : "Proceed"}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end proceed modal */}


      {/* Claim Model */}
      <div
        className="modal fade primary_modal"
        id="Claim_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="proceed_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="proceed_modalLabel">
                Follow Steps
              </h5>
              <button
                id="buy_closs"
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>

                <p className="mt-0 purchase_text text-center">Claim NFT</p>
                <p className="mt-0 purchase_desc text-center">
                  Send transaction with your wallet
                </p>

                <div className="text-center my-3">
                  {/* <Button className="create_btn btn-block" disabled={Buystate} onClick={()=>{BalCheck()}}> */}

                  {/* Existing functionalitt */}
                  {/* <Button className="create_btn btn-block" disabled={Buystate} onClick={()=>{BalCheck()}}> */}
                  {/* {tokDetails.Bulkmint == 'Bulk' &&
                    <Button className="create_btn btn-block" disabled={SignTxt == "progress" || SignTxt == "done" ? true : false} onClick={() => { signcall() }}>


                      {SignTxt == "progress" && (
                        <i
                          class="fa fa-spinner mr-3 spinner_icon"
                          aria-hidden="true"
                        ></i>
                      )}{(SignTxt == "done") ? "done" : "sign"}
                    </Button>
                  } */}

                  {/* Newly Added */}
                  {console.log("qweqweqweqpopopopopo", Buystate, SignTxt)}
                  <Button className="create_btn btn-block" disabled={SignTxt == "progress" ? true : false} onClick={() => { PurchaseMint() }}>


                    {Buystate && (
                      <i
                        class="fa fa-spinner mr-3 spinner_icon"
                        aria-hidden="true"
                      ></i>
                    )}{(Buystate) ? "Inprogress" : "Proceed"}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>

  )
})