import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Button, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import $ from 'jquery'
import Web3Utils from 'web3-utils'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { PurchaseCalculation, CharityAndRoyaltyCalculation } from 'actions/controller/PriceCalculate';
import { useTokenDecimal, usePrevious } from 'hooks/useMethods';
import { useMintReceipt, usePurchaseMint, usePruchaseTransferReceipt, usePurchaseTickets, useClaimNftsReceipt, usePassportReceipt, useTransferReceiptTrade, useFiatTransferReciptReceipt, usePurchaseTicketsFiat, usePurchaseMintCharity } from "hooks/useReceipts";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import Web3 from 'web3';
// import Abi721 from '../ABI/721.json'
// import Abi1155 from '../ABI/1155.json' 
// import AbiTOKEN from '../ABI/token.json'
import { Audio, TailSpin } from 'react-loader-spinner'
import { NightsStay } from '@material-ui/icons';
import { usePurchaseReceipt, useTransferReceipt } from 'hooks/useReceipts';
import { UpdateNFTOwners, UpdateNFTOwnersBulk, imageImger } from 'actions/controller/tokenControl';
import { PopupValidation } from 'actions/controller/validation';
import { PaypalJS } from "./PaypalBuy"
import config from "lib/config"
import { toast } from 'react-toastify';
import { useNFTBalCheck, useBulBalnceCheck } from 'hooks/useMethods';
import { v1_ConNFTBalCheck, AlreadyClaimNft, v1_TransferToken, LMSignCall, ContactMint, LMBalanceOf } from 'actions/v1/token';
import isEmpty from 'lib/isEmpty';
import { connectWallet, WalletConnect, getServiceFees } from '../../hooks/useWallet';
import { Account_Connect, Account_disConnect } from "actions/redux/action";
import { network } from "../../views/network"
import randomInteger from 'random-int';
import Cookies from 'universal-cookie';
// import { socket } from '../../index'
export const PurchaseNow = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  // console.log("sdvsnvkfdnvkdfnvk", Wallet_Details);
  const [NoofToken, setNoofToken] = useState(1)
  const [NewTokenDecimal, setNewTokenDecimal] = useState('')
  const [Prices, setPrices] = useState({ Youwillpay: 0, PriceWithSerfee: "", ApprovePriceWithSerfee: "", PriceWithoutSerfee: "" })
  const [lazyMintPrices, setLazyMintPrices] = useState({ Youwillpay: 0, PriceWithSerfee: "", ApprovePriceWithSerfee: "", PriceWithoutSerfee: "" })

  const [NFT, setNFT] = useState({ CurrentOwner: {} })
  const [Buystate, setBuystate] = useState(false)
  const [tokName, settokName] = useState("")
  const [ApproveTrade, setApproveTrade] = useState('Start');
  const [SignatureHash, setSignatureHash] = useState('');
  const [NonceHash, setNonceHash] = useState(0);
  const [PurchaseCallStatus, setPurchaseCallStatus] = useState('')
  const [RandomName, setRandomName] = useState('')
  const [SignTxt, SetSignTxt] = useState("Start");
  const prevNoofToken = usePrevious(NoofToken)
  const history = useHistory()

  // nft details set for lazyminting
  const [tokDetails, setTokDetails] = useState("")       // nft detaisl in token collection
  const [Fiat, setFiat] = useState(false);
  const [App_Btn, SetApp_Btn] = useState("Start")
  const [Transcation, SetTransCation] = useState({})
  useImperativeHandle(
    ref,
    () => ({
      async PurchaseNowClick(ClickedNFT, tokenName, tokenDetails) {
        settokName(tokenName)
        setNFT(ClickedNFT)
        setTokDetails(tokenDetails)   //added for lazymint
        setFiat(ClickedNFT.Fiat)
        console.log("token details", ClickedNFT, tokenName, tokenDetails)
        var SendingData = {
          claimer: Wallet_Details.UserAccountAddr,
          QR_tokenID: tokenDetails.tokenID
        }
        var AlreadyClaimCheck = await AlreadyClaimNft(SendingData)
        console.log("dafasddasdsadsa", AlreadyClaimCheck);
        var networkConfiguration = {}
        var chainid = tokenDetails?.SelectedNetwork
        const chainId = await Wallet_Details.Web3Pro.eth.getChainId();
        console.log("fgndfgn", chainid, Wallet_Details.networkConfiguration);
        var accountDetailsss = ''
        console.log("dfbhsfdbn", ClickedNFT, Wallet_Details.networkConfiguration.Chainid, tokenDetails?.SelectedNetwork);
        Serfee(Number(chainid));

        // var provider = window.ethereum;
        // const web3 = new Web3(provider);
        // const contract = new web3.eth.Contract(Abi721, config.singleContract);
        // const TokenContract = await contract(721,{ Coin: 'CAKE' })
        // const Decimal = await TokenContract
        // .methods
        // .decimals()
        // .call()
        // var TokenDecimal = await useTokenDecimal({ Coin: 'CAKE' }, 721)
        // console.log("bffsvkfsdbvkfsdbv",TokenDecimal);
        // setNewTokenDecimal(TokenDecimal)
        // console.log("sdasdsadasffdfdsf", Wallet_Details.networkConfiguration.Chainid, tokenDetails?.SelectedNetwork);


        if (Wallet_Details.networkConfiguration.Chainid == tokenDetails?.SelectedNetwork) {
          console.log("dknvjksdnkfsnjkfd", Wallet_Details.networkConfiguration.Chainid == tokenDetails?.SelectedNetwork);
          accountDetailsss = true
        }
        else {
          console.log("it comming in else switch", networkConfiguration);
          console.log("sdfsfdfsdfsdfs", Number(chainid), network.MATIC.Chainid);
          if (Number(chainid) === network.MATIC.Chainid) {
            console.log("netter eorrrrkkk maticz", network.MATIC);
            networkConfiguration = network.MATIC
          }
          else if (Number(chainid) === network.ETH.Chainid) {
            console.log("netter eorrrrkkk", network.ETH);
            networkConfiguration = network.ETH
          }
          else if (Number(chainid) === network.CAM.Chainid) {
            console.log("netter eorrrrkkk", network.CAM);
            networkConfiguration = network.CAM
          }

          console.log("qeweqweqweasdas", chainId, networkConfiguration.chainid);
          if (Number(networkConfiguration.chainid) !== Number(chainId)) {
            var accountDetailsss = await switchNetwork(chainid, networkConfiguration)
          }
        }

        console.log("stwerwerwer", Wallet_Details);
        console.log("cdsncsdlcslmclds", chainid === String(Wallet_Details.networkConfiguration.Chainid), chainid, String(Wallet_Details?.networkConfiguration?.Chainid));
        console.log("it comming in else", Number(chainid) === Number(networkConfiguration?.Chainid));
        console.log("smdnvsdnkfsnd", accountDetailsss, Wallet_Details, Number(chainid), Number(networkConfiguration?.Chainid));


        if (Number(chainid) === Number(chainId)) {
          console.log("dnkdnfksdnfkjsdnf");
          // if (ClickedNFT.OwnerDetail) {
          //   ClickedNFT.CurrentOwner = ClickedNFT.OwnerDetail
          // }
          console.log("jdsbnfshjfbrbhjtn", ClickedNFT)
          if (tokenDetails.PublishStatus == false) {
            if (tokenDetails.tokenCategory !== "Web3 IDs") {
              window.$('#buy_modal').modal('show');

            } else {
              var ClaimPassPort = await EventPassPortCalim(ClickedNFT, tokenDetails)
            }
          } else {
            if (AlreadyClaimCheck.data.sucess) {
              return toast.warning("You Already Claimed this NFT")
            } else {
              console.log("Commmingggggg on else");
              var mintCalim = await PurchaseMintClaim(ClickedNFT, tokenDetails)
              //window.$('#Claim_modal').modal('show');
            }
          }
          console.log("condition detials", Wallet_Details.UserAccountAddr && ClickedNFT && ClickedNFT.hashValue == "not yet minted" && ClickedNFT.type == 1155)
          if (Wallet_Details.UserAccountAddr && ClickedNFT && ClickedNFT.hashValue == "not yet minted" && ClickedNFT.type == 1155) {

            var data = { Price: ClickedNFT.tokenPrice, Quantity: tokenDetails.PublishStatus == false ? ClickedNFT.quantity : 1, Serfee: Wallet_Details.Service_Fee, Decimal: 18, tokenRoyality: tokenDetails.tokenRoyality }
            var Data = PurchaseCalculation(data)
            setPrices(Data)
            setNoofToken(tokenDetails.EventName == "NoEvent" && tokenDetails.PublishStatus == false ? ClickedNFT.quantity : 1)
            // console.log("multiple price", Data, ClickedNFT.tokenPrice)
          }
          else if (Wallet_Details.UserAccountAddr) {
            console.log("gerkmgoemfgfdmve", Wallet_Details);
            var data = { Price: ClickedNFT.tokenPrice, Quantity: NoofToken, Serfee: Wallet_Details.Service_Fee, Decimal: 18 }
            console.log("data for purchase calculation", data);
            var Data = PurchaseCalculation(data)
            // console.log("dsnkjdnkdnkdacnd ncjask", Data);
            setPrices(Data)
            // console.log("jdsnbfufnuewrgn", Data)
          }
        }
      }
    }))

  const Serfee = async (Address) => {
    console.log("khlhujnoasdasdasdikljiuio", Address);
    var Fee = await getServiceFees(Address)
    console.log("ndkdsnfkdsn", Fee);
    dispatch({
      type: Account_Connect,
      Account_Detail: {
        Service_Fee: Fee?.Serfee,
        Service_Fee_Fraction:Fee?.SerfeeFraction
      }
    })
  }

  async function switchNetwork(configdata, networkConfiguration) {
    var type = ""
    var networkConfiguration = {}
    const chainId = await Wallet_Details.Web3Pro.eth.getChainId();
    console.log("adsadasdasd", configdata, chainId, networkConfiguration);
    // if(configdata ==)
    console.log("dfadsaddasdadas", Wallet_Details, localStorage.walletConnectType);
    if (localStorage.walletConnectType !== "Torus") {
      if (Number(configdata) !== Number(chainId)) {
        const id = toast.loading("Switching Network", { closeButton: true, theme: "dark" });
        // console.log("sdnfvnfkvnfs", configdata);
        if (configdata) {
          if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask') {

            type = "MetaMask"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'WalletConnect' && localStorage.walletConnectType != null) {
            type = "WalletConnect"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'Venly' && localStorage.walletConnectType != null) {
            type = "Venly"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'Torus' && localStorage.walletConnectType != null) {
            type = "Torus"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'TorusWallet' && localStorage.walletConnectType != null) {
            type = "TorusWallet"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'LMWallet' && localStorage.walletConnectType != null) {
            console.log("adasd comming on torus");
            type = "LMWallet"
            localStorage.setItem("ChainId", tokDetails.SelectedNetwork)
          }
          // console.log("connecttype............", type, configdata)
          var accountDetails = await connectWallet(type, Number(configdata), localStorage.walletConnectType == "LMWallet" ? "register" : "Create", Wallet_Details, "Tor")
          // console.log("accountDetailsin create page......................", accountDetails)

          // var web3 = new Web3(window.ethereum);
          // console.log("dfghrtfh", web3);
          // if (window.ethereum.isMetaMask == true) {

          const chainId = await accountDetails?.web3?.eth?.getChainId();
          // console.log("fghdtgj", chainId);

          if (Number(configdata) === network.MATIC.Chainid) {
            networkConfiguration = network.MATIC
          }
          else if (Number(configdata) === network.ETH.Chainid) {
            networkConfiguration = network.ETH
          }
          else if (Number(configdata) === network.CAM.Chainid) {
            networkConfiguration = network.CAM
          }


          // }
          // setPriceoption()
          console.log("dsnsdkngksfnvkfdn", networkConfiguration);
          if (accountDetails != '' && accountDetails?.web3?._provider != '') {
            // console.log("Wallet Connecting...increate", accountDetails.web3._provider);
            dispatch({
              type: Account_Connect,
              Account_Detail: {
                UserAccountAddr: accountDetails.accountAddress,
                UserAccountBal: accountDetails.coinBalance,
                WalletConnected: "true",
                Wen_Bln: accountDetails.tokenBalance,
                Accounts: accountDetails.accountAddress,
                Web3Pro: accountDetails.web3,
                providerss: accountDetails.web3._provider,
                networkConfiguration: networkConfiguration
              }
            })
            Serfee(networkConfiguration.Chainid);
          }
          if (networkConfiguration.currencySymbol) {
            toast.update(id, {
              render: "Network switched Succesfully",
              type: "success",
              isLoading: false,
              autoClose: 2500,
              closeButton: true,
              theme: "dark"
            });
          }
        }



        //   var ReqData = {
        //     addr: String(accountDetails.accountAddress).toLowerCase()
        // }
        // var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
        // if (Resp && Resp.data && Resp.data.data && Resp.data.data.User) {
        //     return Resp.data.data.User
        // } else {
        //     return null
        // }





        return accountDetails
      } else {
        dispatch({
          type: Account_Connect,
          Account_Detail: {
            UserAccountAddr: Wallet_Details?.UserAccountAddr,
            UserAccountBal: Wallet_Details?.UserAccountBal,
            WalletConnected: "true",

            Wen_Bln: Wallet_Details?.Wen_Bln,
            Accounts: Wallet_Details?.Accounts,

            Web3Pro: Wallet_Details?.Web3Pro,
            providerss: Wallet_Details?.providerss,
            networkConfiguration: networkConfiguration
          }
        })
        Serfee(networkConfiguration.Chainid);
        return Wallet_Details.Web3Pro
      }
    } else {
      try {
        const id = toast.loading("Switching Network", { closeButton: true, theme: "dark" });
        var accountDetails = await connectWallet(localStorage.walletConnectType, Number(configdata), "Create", Wallet_Details, "Torus")
        // if (Number(networkNUM) == 97) {
        //   console.log("Comming on last else for switchCahin", Wallet_Details.web3auth, Wallet_Details?.web3);
        //   var addingChain = await Wallet_Details.web3auth.addChain({
        //     chainId: "0x61",
        //     displayName: "binance",
        //     chainNamespace: CHAIN_NAMESPACES.EIP155,
        //     tickerName: "BNB Smart Chain Testnet",
        //     ticker: "BNB",
        //     decimals: 18,
        //     rpcTarget: "https://data-seed-prebsc-1-s1.binance.org:8545/",
        //     blockExplorer: "https://testnet.bscscan.com/",
        //   });
        //   console.log("addingChain Web3Auth", addingChain);
        //   var switched = await Wallet_Details.web3auth.switchChain({ chainId: "0x61" });
        //   console.log("switched in create page uh", switched);
        // }else{
        //   console.log("Comming on last else for switchCahin", Wallet_Details.web3auth, Wallet_Details?.web3);
        //   var addingChain = await Wallet_Details.web3auth.addChain({
        //     chainId: "0x13881",
        //     displayName: "Mumbai",
        //     chainNamespace: CHAIN_NAMESPACES.EIP155,
        //     tickerName: "Mumbai",
        //     ticker: "MATIC",
        //     decimals: 18,
        //     rpcTarget: "https://matic-mumbai.chainstacklabs.com",
        //     blockExplorer: "https://mumbai.polygonscan.com/",
        //   });
        //   console.log("addingChain Web3Auth", addingChain);
        //   var switched = await Wallet_Details.web3auth.switchChain({ chainId: "0x13881" });
        //   console.log("switched in create page uh", switched);
        // }
        console.log("asdasdasdasdas", accountDetails);
        const chainId = await accountDetails?.web3?.eth?.getChainId();
        console.log("fghdtgj", configdata, network, chainId);

        if (Number(configdata) === network.MATIC.Chainid) {
          networkConfiguration = network.MATIC
        }
        else if (Number(configdata) === network.ETH.Chainid) {
          networkConfiguration = network.ETH
        }
        else if (Number(configdata) === network.CAM.Chainid) {
          networkConfiguration = network.CAM
        }
        console.log("huiyooijmoijolmj", networkConfiguration);
        if (accountDetails != '') {
          dispatch({
            type: Account_Connect,
            Account_Detail: {
              UserAccountAddr: accountDetails?.accountAddress,
              UserAccountBal: accountDetails?.coinBalance,
              WalletConnected: "true",

              Wen_Bln: accountDetails?.tokenBalance,
              Accounts: accountDetails?.accountAddress,

              Web3Pro: accountDetails?.web3,
              providerss: accountDetails?.web3?._provider,
              networkConfiguration: networkConfiguration
            }
          })
        }
        var functiobn = await NefunctionTriger(Wallet_Details)
        console.log("Asdasdaeqweqwe", networkConfiguration.currencySymbol);
        if (networkConfiguration.currencySymbol) {
          toast.update(id, {
            render: "Network switched Succesfully",
            type: "success",
            isLoading: false,
            autoClose: 2500,
            closeButton: true,
            theme: "dark"
          });
        }
      } catch (e) {
        console.log("adqeqweqweqweqweqweqwe", e);
      }
    }

  }

  const NefunctionTriger = async (Wallet_Details1) => {
    console.log("Wallet_Details1", Wallet_Details1);
    const chainIdSwitched = await Wallet_Details1?.Web3Pro?.eth?.getChainId();
    var balance = await Wallet_Details1?.Web3Pro?.eth.getBalance(Wallet_Details1.UserAccountAddr);
    console.log("chainIdSwitched", chainIdSwitched, balance);
    return chainIdSwitched
  }

  useEffect(() => {
    if (NoofToken !== 1 && prevNoofToken !== NoofToken) {
      var data = { Price: NFT.tokenPrice, Quantity: NoofToken, Serfee: Wallet_Details.Service_Fee, Decimal: NewTokenDecimal }
      var Data = PurchaseCalculation(data)
      setPrices(Data)
    }
  }, [NoofToken])

  const ValidCheck = () => {
    var ValChk = { TokenPrice: Prices.Youwillpay, NoofToken: NoofToken, type: NFT.type, MaxToken: NFT.balance }
    var Valid = PopupValidation(ValChk)
    if (Valid.Success) {
      var Check = (Wallet_Details.UserAccountBal < Prices.Youwillpay)
      if (Check) {
        toast.error("Insufficient Balance", { theme: "dark" })
      }
      else
        $('#hiddenbutton').trigger("click");
    }
    else
      toast.error(Valid.Message, { theme: "dark" })
  }

  const PurchaseMint = async () => {
    console.log("nft hash status", NFT, tokDetails)
    if (NFT.hashValue == "not yet minted" && NFT.LazyStatus == "pending") {
      console.log("it comming in first");
      if (tokDetails.Bulkmint !== "Bulk") {
        if (NFT.Charity) {
          LazyMintCallCharity()
        } else {
          LazyMintCall();
        }

      } else {
        if (Fiat) {
          ImageMergeForFiat()
        } else {
          ImageMergeProcess()
        }
      }
    }
    else if (NFT.hashValue !== "not yet minted" && NFT.LazyStatus == "pending") {
      console.log("it comming in Second");
      if (tokDetails.Bulkmint !== "Bulk") {
        BalCheck();
      } else {
        if (Fiat) {
          ImageMergeForFiat()
        } else {
          ImageMergeProcess()
        }
      }

    }
    else if (NFT.hashValue !== "not yet minted" && NFT.LazyStatus == "completed") {
      console.log("it comming in Third");
      if (tokDetails.Bulkmint !== "Bulk") {
        BalCheck();
      } else {
        if (Fiat) {
          ImageMergeForFiat()
        } else {
          ImageMergeProcess()
        }
      }

    }

  }

  const PurchaseMintClaim = async (NFT1, tokDetails1) => {
    console.log("nft hash status", NFT1, tokDetails1)
    if (NFT1.hashValue == "not yet minted" && NFT1.LazyStatus == "pending") {
      console.log("it comming in first");
      if (tokDetails1.Bulkmint !== "Bulk") {
        LazyMintCall();
      } else {
        ImageMergeProcessClaim(NFT1, tokDetails1)
      }
    }
    else if (NFT1.hashValue !== "not yet minted" && NFT1.LazyStatus == "pending") {
      console.log("it comming in Second");
      if (tokDetails1.Bulkmint !== "Bulk") {
        BalCheck();
      } else {
        ImageMergeProcessClaim(NFT1, tokDetails1)
      }

    }
    else if (NFT1.hashValue !== "not yet minted" && NFT1.LazyStatus == "completed") {
      console.log("it comming in Third");
      if (tokDetails1.Bulkmint !== "Bulk") {
        BalCheck();
      } else {
        ImageMergeProcessClaim(NFT1, tokDetails1)
      }

    }

  }

  const NFTHookFun = async (Arg, Type, Wallet_Details) => {
    var ContractNFTBal = await useNFTBalCheck(Arg, Type, Wallet_Details)
    return ContractNFTBal
  }

  const NFTHookFun1 = async (Arg, Type, Wallet_Details) => {
    var ContractNFTBal = await useBulBalnceCheck(Arg, Type, Wallet_Details)
    return ContractNFTBal
  }

  // Not Used
  const AcceptBidTransferCall = async () => {
    // alert("normal accept")
    setBuystate(true)
    // console.log("dfnsdknfksndfksdNFT here it comming", NFT);
    var Arg = { NFTOwner: NFT.tokenOwner, NFTId: NFT.tokenID, PriceWithoutSerfee: Prices.PriceWithoutSerfee, PriceWithSerfee: Prices.PriceWithSerfee, NoofNFTs: NoofToken, ContractAddress: NFT.contractAddress, Coinname: NFT.coinname }
    var Type = NFT.type
    var Provider = Wallet_Details
    var isToken = false
    // console.log("sdfsdnskfvdvmdfkv", Arg);
    var Receipt = await usePruchaseTransferReceipt(Arg, Type, Provider, isToken)
    // console.log("receit response", Receipt, NFT.balance, NoofToken)
    var updateData = {
      LazyStatus: NFT.balance == NoofToken ? "completed" : "pending",
      tokenID: String(NFT.tokenID),
    }
    if (Receipt.status) {
      var NewOwnerDetail = { Status: Receipt.status, Hash: Receipt.transactionHash, ConAddr: NFT.contractAddress, Owns: NoofToken, NFTDetail: NFT, Action: "Purchase", NewOwner: Wallet_Details.UserAccountAddr, OldOwner: NFT.tokenOwner, coinname: NFT.coinname, tokenName: tokName, updateData: updateData, LazyStatus: "completed", QR_tokenID: NFT.QR_tokenID,SelectedNetwork:tokDetails.SelectedNetwork }
      // console.log("newowner deetail Buycall", NewOwnerDetail)
      var Resp = await UpdateNFTOwners(NewOwnerDetail)

      if (Resp) {
        $('#buy_closs').trigger("click");
        setTimeout(() => {
          history.push(`/my-items/${Wallet_Details.UserAccountAddr}`)
        }, 1000);
      }
    }
    else {
      toast.error("Try it Again Later.Please Check Your Funds")
    }
    setBuystate(false)
  }
  // completed
  const LazyMintCall = async () => {
    // alert("lazymintcall")
    setBuystate(true)
    console.log('nft token detial', tokDetails, NFT, Wallet_Details)
    var chainid = tokDetails?.SelectedNetwork
    var networkConfiguration = {}
    console.log("sdfsfdfsdfsdfs", Number(chainid), network.MATIC.Chainid);
    if (Number(chainid) === network.MATIC.Chainid) {
      console.log("netter eorrrrkkk maticz", network.MATIC);
      networkConfiguration = network.MATIC
    }
    else if (Number(chainid) === network.ETH.Chainid) {
      console.log("netter eorrrrkkk", network.ETH);
      networkConfiguration = network.ETH
    }
    else if (Number(chainid) === network.CAM.Chainid) {
      console.log("netter eorrrrkkk", network.CAM);
      networkConfiguration = network.CAM
    }
    console.log("Prices.PriceWithSerfee", Prices);
    // console.log("fwkjdfndkfjnsdnsdkf", Web3.utils.toWei(String(tokDetails?.tokenRoyality)));
    var Royaltity = Web3.utils.toWei(String(tokDetails?.tokenRoyality))
    var tokendbPrice = tokDetails?.tokenPrice == 0 ? 0 : Web3.utils.toWei(String(tokDetails?.tokenPrice))
    // console.log("qweqweqwreqrq", Royaltity, tokendbPrice);
    var nounceArg = { RandomName: RandomName, NonceHash: NonceHash, SignatureHash: SignatureHash }
    Wallet_Details.networkConfiguration = networkConfiguration
    var Arg = { NFTOwner: NFT.tokenOwner, NFTId: NFT.tokenID, PriceWithoutSerfee: Prices.PriceWithoutSerfee, PriceWithSerfee: Prices.PriceWithSerfee, NoofNFTs: NoofToken, isToken: false, TotalToken: NFT.quantity, Royaltity: Royaltity, tokendbPrice: tokendbPrice, nounceArg: nounceArg }
    var Calculation = await CharityAndRoyaltyCalculation(tokDetails, NFT)
    console.log("Calculation", Calculation);
    // console.log("data arg or contract", Arg, Wallet_Details)
    if (localStorage.walletConnectType !== "LMWallet") {
      var Receipt = await ContactHook(Arg, NFT.type, Wallet_Details, tokDetails, NFT)
    } else {
      var nounce1 = tokDetails.Bulkmint == "Bulk" ? Arg?.nounceArg?.NonceHash : tokDetails.NonceHash;
      var RandomName1 = tokDetails.Bulkmint == "Bulk" ? Arg?.nounceArg?.RandomName : tokDetails.RandomName;
      var SignatureHash1 = tokDetails.Bulkmint == "Bulk" ? Arg?.nounceArg?.SignatureHash : tokDetails.SignatureHash
      var Key = null
      var AdAdd = null
      var Argument = [[tokDetails.ipfsmeta, "Status", "lazy", RandomName1, "other", ""], [NFT.tokenCreator, Wallet_Details.UserAccountAddr], [NFT.quantity, "721", Arg.Royaltity, Arg.PriceWithoutSerfee, NFT.quantity, nounce1, Arg.tokendbPrice], "Coin", SignatureHash1]
      var Receipt = await LMWalletCall(Arg, NFT.type, Wallet_Details, tokDetails, NFT, "Trade", Wallet_Details.networkConfiguration.TradeContract, Wallet_Details.networkConfiguration.TradeContract, Argument, "lazyMint", Key, true, Arg.PriceWithSerfee, AdAdd)
    }

    console.log("returner repsonse receipt", Receipt)
    if (Receipt.status) {
      if (tokDetails.SelectedNetwork == config.MATIC_ID) {
        // console.log("sfvfjnlkmkls", tokDetails.SelectedNetwork);
        var tokenid = tokDetails.type == 721 ? Receipt?.logs[4]?.topics[3] : Receipt?.logs[4]?.topics[3];
      } else if (tokDetails.SelectedNetwork == config.ETH_ID) {
        // console.log("binanceeeeeee");
        var tokenid = tokDetails.type == 721 ? Receipt?.logs[3]?.topics[3] : Receipt?.logs[3]?.topics[3];
      } else  if (tokDetails.SelectedNetwork == config.CAM_ID) {
        // console.log("sfvfjnlkmkls", tokDetails.SelectedNetwork);
        var tokenid = tokDetails.type == 721 ? Receipt?.logs[2]?.topics[2] : Receipt?.logs[2]?.topics[2];
      }

      const tokenID = Web3Utils.hexToNumber(tokenid);
      // console.log("tokenid from receipt", tokenID, NFT)
      // console.log("gyugduasguisgcshg", NFT, tokDetails);
      var updateData = {
        hashValue: Receipt.transactionHash,
        tokenID: String(tokenID),
        status: Receipt?.status,
        BulkMint: tokDetails.BulkMint == 'false' ? 'true' : 'true',
        LazyStatus: NFT.balance == NoofToken ? "completed" : "pending",

      }
      var NewOwnerDetail = { Status: Receipt?.status, Hash: Receipt.transactionHash, ConAddr: NFT.contractAddress, Owns: NoofToken, NFTDetail: NFT, Action: "Purchase", NewOwner: Wallet_Details.UserAccountAddr, OldOwner: NFT.tokenOwner, coinname: NFT.coinname, tokenName: tokName, Collection: tokDetails.Collection, updateData: updateData, LazyStatus: "completed", SelectedNetwork: tokDetails.SelectedNetwork, QR_tokenID: NFT.QR_tokenID, ServiceFee: Wallet_Details.Service_Fee.Buyer, Fiat: NFT.Fiat, Charity: NFT.Charity, Calculation: Calculation, NFTPrice: Prices.PriceWithoutSerfee / 1e18, NFTPriceWithSerice: Prices.PriceWithSerfee / 1e18 }
      // console.log("newowner deetail", NewOwnerDetail, updateData,)
      var Resp = await UpdateNFTOwners(NewOwnerDetail)

      if (Resp) {
        $('#buy_closs').trigger("click");
        toast.success("Token Purchased Successfully")
        setTimeout(() => {
          history.push(`/my-items/${Wallet_Details.UserAccountAddr}`)
        }, 1000);
      }
    }
    else {
      toast.error("Try it Again Later.Please Check Your Funds")
    }
    setBuystate(false)



  }

  //Lazymint Charity 

  const LazyMintCallCharity = async () => {
    // alert("lazymintcall")
    setBuystate(true)
    console.log('nft token detial', tokDetails, NFT, Wallet_Details)
    var chainid = tokDetails?.SelectedNetwork
    var networkConfiguration = {}
    console.log("sdfsfdfsdfsdfs", Number(chainid), network.MATIC.Chainid);
    if (Number(chainid) === network.MATIC.Chainid) {
      console.log("netter eorrrrkkk maticz", network.MATIC);
      networkConfiguration = network.MATIC
    }
    else if (Number(chainid) === network.ETH.Chainid) {
      console.log("netter eorrrrkkk", network.ETH);
      networkConfiguration = network.ETH
    }
    else if (Number(chainid) === network.CAM.Chainid) {
      console.log("netter eorrrrkkk", network.CAM);
      networkConfiguration = network.CAM
    }
    console.log("Prices.PriceWithSerfee", Prices);
    // console.log("fwkjdfndkfjnsdnsdkf", Web3.utils.toWei(String(tokDetails?.tokenRoyality)));
    var Royaltity = Web3.utils.toWei(String(tokDetails?.tokenRoyality))
    var tokendbPrice = tokDetails?.tokenPrice == 0 ? 0 : Web3.utils.toWei(String(tokDetails?.tokenPrice))
    // console.log("qweqweqwreqrq", Royaltity, tokendbPrice);
    var nounceArg = { RandomName: RandomName, NonceHash: NonceHash, SignatureHash: SignatureHash }
    Wallet_Details.networkConfiguration = networkConfiguration
    var Arg = { NFTOwner: NFT.tokenOwner, NFTId: NFT.tokenID, PriceWithoutSerfee: Prices.PriceWithoutSerfee, PriceWithSerfee: Prices.PriceWithSerfee, NoofNFTs: NoofToken, isToken: false, TotalToken: NFT.quantity, Royaltity: Royaltity, tokendbPrice: tokendbPrice, nounceArg: nounceArg }
    // var Calculation = await CharityAndRoyaltyCalculation(tokDetails, NFT)
    // console.log("Calculation", Calculation);
    // console.log("data arg or contract", Arg, Wallet_Details)
    var createrList = []
    var OwnerList = []
    var CreatorPer = []
    var OwnerPer = []
    var mapData = tokDetails.CharityDetails.map((item, index) => {
      if (item.Royality > 0) {
        createrList.push(item.Address)
        CreatorPer.push(Web3.utils.toWei(item.Royality))
      }
      if (item.Share > 0) {
        OwnerList.push(item.Address)
        OwnerPer.push(Web3.utils.toWei(String(item.Share)))
      }
    })
    var AmountShare = tokDetails.CharityDetails.map((item, index) => { return tokDetails.CharityDetails[index]["SplitAmount"] = Web3.utils.toWei(String(item.Share)) })
    var AddressSend = tokDetails.CharityDetails.map((item, index) => { return tokDetails.CharityDetails[index]["Address"] = item.Address })
    var RoyalitySend = tokDetails.CharityDetails.map((item, index) => { return tokDetails.CharityDetails[index]["Royality"] = Web3.utils.toWei(item.Royality) })
    var Creatorshares = tokDetails.CharityDetails.map((item, index) => { return item.Share }).reduce((partialSum, a) => Number(partialSum) + Number(a), 0)
    var CreatorRoyalties = tokDetails.CharityDetails.map((item, index) => { return item.Royality }).reduce((partialSum, a) => Number(partialSum) + Number(a), 0)
    console.log("Creatorshares", Creatorshares, CreatorRoyalties);

    var vartotroyal = (CreatorRoyalties / 1e18) + Number(tokDetails?.tokenRoyality)
    console.log("vartotroyalvartotroyal", vartotroyal);
    var FinalRoyalss = tokDetails.CharityDetails.map((item, index) => {
      console.log("qwejoqwodqewiwq", item, vartotroyal);
      console.log("sedwjiowjiwo", (100 * Number(Number(item.Royality) / 1e18)) / vartotroyal);
      return Math.round((100 * Number(Number(item.Royality) / 1e18)) / vartotroyal)
    })
    console.log("tokDetails?.tokenRoyality", tokDetails?.tokenRoyality);
    var creatoeee = Math.round((100 * Number(tokDetails?.tokenRoyality)) / vartotroyal)
    console.log("creatoeeecreatoeee", creatoeee);
    var pushtheRoyalaaa = FinalRoyalss.push(creatoeee)
    console.log("FinalRoyalssFinalRoyalss", FinalRoyalss);
    var toweee = FinalRoyalss.map((item, index) => { return Web3.utils.toWei(String(item)) })
    console.log("toweeetoweeetoweee", toweee);
    var CreaterSharePer = 100 - Number(Creatorshares)
    console.log("CreaterSharePer", CreaterSharePer);
    var FinalShares = OwnerPer.push(Web3.utils.toWei(String(CreaterSharePer)))
    var FinalAddress = createrList.push(tokDetails.tokenOwner)
    var FinalAddressCre = OwnerList.push(tokDetails.tokenOwner)
    var FinalRoyality = CreatorPer.push(Royaltity)
    console.log("AddressSend", AddressSend, AmountShare, RoyalitySend);
    console.log("sdasdasdasasdasdas", createrList, OwnerList, CreatorPer, OwnerPer);
    if (localStorage.walletConnectType !== "LMWallet") {
      var Receipt = await ContactHookCharity(Arg, NFT.type, Wallet_Details, tokDetails, NFT, createrList, OwnerList, OwnerPer, toweee)
    } else {
      var nounce1 = tokDetails.Bulkmint == "Bulk" ? Arg?.nounceArg?.NonceHash : tokDetails.NonceHash;
      var RandomName1 = tokDetails.Bulkmint == "Bulk" ? Arg?.nounceArg?.RandomName : tokDetails.RandomName;
      var SignatureHash1 = tokDetails.Bulkmint == "Bulk" ? Arg?.nounceArg?.SignatureHash : tokDetails.SignatureHash
      var Key = null
      var AdAdd = null
      var Argument = [[tokDetails.ipfsmeta, "Status", "lazy", RandomName1, "other", ""], [NFT.tokenCreator, Wallet_Details.UserAccountAddr], createrList, OwnerList, OwnerPer, toweee, [NFT.quantity, "721", Arg.Royaltity, Arg.PriceWithoutSerfee, NFT.quantity, nounce1, Arg.tokendbPrice], "Coin", SignatureHash1]
      var Receipt = await LMWalletCall(Arg, NFT.type, Wallet_Details, tokDetails, NFT, "Trade", Wallet_Details.networkConfiguration.TradeContract, Wallet_Details.networkConfiguration.TradeContract, Argument, "lazyMint", Key, true, Arg.PriceWithSerfee, AdAdd)
    }

    console.log("returner repsonse receipt", Receipt)
    if (Receipt.status) {
      if (tokDetails.SelectedNetwork == config.MATIC_ID) {
        // console.log("sfvfjnlkmkls", tokDetails.SelectedNetwork);
        var tokenid = tokDetails.type == 721 ? Receipt?.logs[4]?.topics[3] : Receipt?.logs[4]?.topics[3];
      } else if (tokDetails.SelectedNetwork == config.ETH_ID) {
        // console.log("binanceeeeeee");
        var tokenid = tokDetails.type == 721 ? Receipt?.logs[3]?.topics[3] : Receipt?.logs[3]?.topics[3];
      } else if (tokDetails.SelectedNetwork == config.CAM_ID) {
        // console.log("sfvfjnlkmkls", tokDetails.SelectedNetwork);
        var tokenid = tokDetails.type == 721 ? Receipt?.logs[2]?.topics[2] : Receipt?.logs[2]?.topics[2];
      }

      const tokenID = Web3Utils.hexToNumber(tokenid);
      // console.log("tokenid from receipt", tokenID, NFT)
      // console.log("gyugduasguisgcshg", NFT, tokDetails);
      var updateData = {
        hashValue: Receipt.transactionHash,
        tokenID: String(tokenID),
        status: Receipt?.status,
        BulkMint: tokDetails.BulkMint == 'false' ? 'true' : 'true',
        LazyStatus: NFT.balance == NoofToken ? "completed" : "pending",

      }
      var NewOwnerDetail = { Status: Receipt?.status, Hash: Receipt.transactionHash, ConAddr: NFT.contractAddress, Owns: NoofToken, NFTDetail: NFT, Action: "Purchase", NewOwner: Wallet_Details.UserAccountAddr, OldOwner: NFT.tokenOwner, coinname: NFT.coinname, tokenName: tokName, Collection: tokDetails.Collection, updateData: updateData, LazyStatus: "completed", SelectedNetwork: tokDetails.SelectedNetwork, QR_tokenID: NFT.QR_tokenID, ServiceFee: Wallet_Details.Service_Fee.Buyer, Fiat: NFT.Fiat, Charity: NFT.Charity, NFTPrice: Prices.PriceWithoutSerfee / 1e18, NFTPriceWithSerice: Prices.PriceWithSerfee / 1e18 }
      // console.log("newowner deetail", NewOwnerDetail, updateData,)
      var Resp = await UpdateNFTOwners(NewOwnerDetail)

      if (Resp) {
        $('#buy_closs').trigger("click");
        toast.success("Token Purchased Successfully")
        setTimeout(() => {
          history.push(`/my-items/${Wallet_Details.UserAccountAddr}`)
        }, 1000);
      }
    }
    else {
      toast.error("Try it Again Later.Please Check Your Funds")
    }
    setBuystate(false)



  }

  const BalCheck = async () => {
    // alert("22")
    // console.log("dnjsnkfnsdkfnksd its comming here", NFT);
    var chainid = tokDetails?.SelectedNetwork
    var networkConfiguration = {}
    console.log("sdfsfdfsdfsdfs", Number(chainid), network.MATIC.Chainid);
    if (Number(chainid) === network.MATIC.Chainid) {
      console.log("netter eorrrrkkk maticz", network.MATIC);
      networkConfiguration = network.MATIC
    }
    else if (Number(chainid) === network.ETH.Chainid) {
      console.log("netter eorrrrkkk", network.ETH);
      networkConfiguration = network.ETH
    }
    else if (Number(chainid) === network.CAM.Chainid) {
      console.log("netter eorrrrkkk", network.CAM);
      networkConfiguration = network.CAM
    }
    Wallet_Details.networkConfiguration = networkConfiguration
    var Arg = {
      NFTConAddress: NFT.contractAddress,
      NFTId: NFT.tokenID,
      NFTOwner: NFT.tokenOwner,
      Status: NFT.LazyStatus,
      BulK: tokDetails.Bulkmint
    }
    var Type = NFT.type
    // var ContractNFTBal = await useNFTBalCheck(Arg, Type, Wallet_Details)
    console.log("adasdsadasdasd", tokDetails.Bulkmint);
    if (tokDetails.Bulkmint !== "Bulk") {
      console.log("else iffffff");
      if (localStorage.walletConnectType !== "LMWallet") {
        var ContractNFTBal = await NFTHookFun(Arg, Type, Wallet_Details)
        console.log("sdqeqwwqeqwqweqw", ContractNFTBal);
      } else {
        var key = null

        var Argument = Type == 721 ? [Arg.NFTId] : [Arg.NFTOwner, Arg.NFTId]
        var ContractNFTBal = await LMBalanceCheck(Arg, Type, Wallet_Details, Arg.NFTConAddress, Type == 721 ? "Single" : "Multiple", Arg.NFTConAddress, Type == 721 ? "ownerOf" : "balanceOf", Argument, key, Arg.NFTOwner)
      }
    } else {
      console.log("Bull else",);
      if (localStorage.walletConnectType !== "LMWallet") {
        var ContractNFTBal = await NFTHookFun1(Arg, Type, Wallet_Details)
        console.log("adsasdasdasdasdad", ContractNFTBal);
      } else {
        var key = null
        var Argument = Type == 721 ? [Arg.NFTId] : [Arg.NFTOwner, Arg.NFTId]
        var ContractNFTBal = await LMBalanceCheck(Arg, Type, Wallet_Details, Arg.NFTConAddress, "Bulk", Arg.NFTConAddress, Type == 721 ? "ownerOf" : "balanceOf", Argument, key, Arg.NFTOwner)
      }
    }
    console.log("ContractNFTBal", ContractNFTBal)
    if (Number(ContractNFTBal) !== NFT.balance) {
      Arg.balance = Number(ContractNFTBal)
      var Updated = await v1_ConNFTBalCheck(Arg)
      // console.log("fjbdshfbsfhsr", Updated)
      if (Updated.data && Updated.data.Success) {
        toast.error("Owners NFT Balance Changed. Try Later", { autoClose: 4000, closeButton: true })
        setTimeout(() => {
          history.push('/explore/All')
        }, 1500);
      }
      else {
        toast.error("Error Occured. Try Later", { autoClose: 3000, closeButton: true })
        setTimeout(() => {
          history.push('/explore/All')
        }, 1500);
      }
    }
    else {
      // alert("domaru")
      // console.log("buycalsds")
      BuyCall()
    }
  }

  const BuyCall = async () => {
    setBuystate(true)
    console.log("dfnsdknfksndfksdNFT here it comming", NFT);
    var chainid = tokDetails?.SelectedNetwork
    var networkConfiguration = {}
    console.log("sdfsfdfsdfsdfs", Number(chainid), network.MATIC.Chainid);
    if (Number(chainid) === network.MATIC.Chainid) {
      console.log("netter eorrrrkkk maticz", network.MATIC);
      networkConfiguration = network.MATIC
    }
    else if (Number(chainid) === network.ETH.Chainid) {
      console.log("netter eorrrrkkk", network.ETH);
      networkConfiguration = network.ETH
    }
    else if (Number(chainid) === network.CAM.Chainid) {
      console.log("netter eorrrrkkk", network.CAM);
      networkConfiguration = network.CAM
    }

    var Calculation = await CharityAndRoyaltyCalculation(tokDetails, NFT)
    console.log("Calculation", Calculation);

    console.log("Prices.PriceWithSerfee", Prices);
    Wallet_Details.networkConfiguration = networkConfiguration
    var Arg = { NFTOwner: NFT.tokenOwner, NFTId: NFT.tokenID, PriceWithoutSerfee: Prices.PriceWithoutSerfee, PriceWithSerfee: Prices.PriceWithSerfee, NoofNFTs: NoofToken, ContractAddress: NFT.contractAddress, Coinname: NFT.coinname, Status: NFT.LazyStatus }
    var Type = NFT.type
    var Provider = Wallet_Details
    var isToken = false
    if (localStorage.walletConnectType !== "LMWallet") {
      var Receipt = await ContactHookPurchaseReceipt(Arg, Type, Provider, isToken)
    } else {
      var Key = null
      var AdAdd = null
      var Argument = [Arg.NFTOwner, [Arg.NFTId, Arg.PriceWithoutSerfee, Arg.NoofNFTs, Type], Arg.ContractAddress]
      var Receipt = await LMWalletCall(Arg, Type, Wallet_Details, tokDetails, NFT, "Trade", Wallet_Details.networkConfiguration.TradeContract, Wallet_Details.networkConfiguration.TradeContract, Argument, "saleToken", Key, true, Arg.PriceWithSerfee, AdAdd)
    }
    console.log("receit response Buy call", Receipt)
    var updateData = {
      LazyStatus: NFT.balance == NoofToken ? "completed" : "pending",
      tokenID: String(NFT.CurrentOwner.tokenID),
    }
    if (Receipt.status) {
      var NewOwnerDetail = { Status: Receipt.status, Hash: Receipt.transactionHash, ConAddr: NFT.contractAddress, Owns: NoofToken, NFTDetail: NFT, Action: "Purchase", NewOwner: Wallet_Details.UserAccountAddr, OldOwner: NFT.tokenOwner, coinname: NFT.coinname, tokenName: tokName, updateData: "", LazyStatus: "completed", SelectedNetwork: tokDetails.SelectedNetwork, QR_tokenID: NFT.QR_tokenID, ServiceFee: Wallet_Details.Service_Fee.Buyer, Fiat: NFT.Fiat, Charity: NFT.Charity, Calculation: Calculation, NFTPrice: Prices.PriceWithoutSerfee / 1e18, NFTPriceWithSerice: Prices.PriceWithSerfee / 1e18 }
      // console.log("newowner deetail Buycall", NewOwnerDetail)
      var Resp = await UpdateNFTOwners(NewOwnerDetail)

      if (Resp) {
        toast.success("Purchased Sucessfully")
        $('#buy_closs').trigger("click");
        setTimeout(() => {
          history.push(`/my-items/${Wallet_Details.UserAccountAddr}`)
        }, 1000);
      }
    } else {
      toast.error("Try it Again Later.Please Check Your Funds")
    }
    setBuystate(false)
  }

  async function signcall() {
    try {
      var chainid = tokDetails?.SelectedNetwork
      var networkConfiguration = {}
      console.log("sdfsfdfsdfsdfs", Number(chainid), network.MATIC.Chainid);
      if (Number(chainid) === network.MATIC.Chainid) {
        console.log("netter eorrrrkkk maticz", network.MATIC);
        networkConfiguration = network.MATIC
      }
      else if (Number(chainid) === network.ETH.Chainid) {
        console.log("netter eorrrrkkk", network.ETH);
        networkConfiguration = network.ETH
      }
      else if (Number(chainid) === network.CAM.Chainid) {
        console.log("netter eorrrrkkk", network.CAM);
        networkConfiguration = network.CAM
      }
      Wallet_Details.networkConfiguration = networkConfiguration
      var generator = require('generate-password');
      var randomNum = randomInteger(10000000, 100000000);
      var password = generator.generate({
        length: 10,
        numbers: true
      });
      var web3RpcPro = new Web3(Wallet_Details.networkConfiguration.RPC_URL)
      var chain = tokDetails?.SelectedNetwork
      const to = Wallet_Details.UserAccountAddr
      const _amount = tokDetails.tokenPrice == "" || tokDetails.tokenPrice == undefined || tokDetails?.PublishStatus == true ? 0 : web3RpcPro.utils.toWei(String(tokDetails.tokenPrice));
      console.log("_amount", _amount);
      const _nonce = Date.now();
      // setNonceHash(_nonce);
      setRandomName(password)
      var tot = _nonce + Number(randomNum);
      setNonceHash(tot);
      if (localStorage.walletConnectType !== "LMWallet") {
        if (Wallet_Details.Web3Pro) {
          var web3Rpc = new Web3(Wallet_Details.providerss)
          if (web3Rpc) {
            SetSignTxt('On progress')

            var web3RpcPro = new Web3(Wallet_Details.providerss);
            console.log("dwqdwqewqewqeqw", to, _amount, password, tot);
            const result = web3RpcPro.utils.soliditySha3(to, _amount, password, tot);
            const signhash = await Wallet_Details.Web3Pro.eth.personal.sign(result, to);
            if (signhash) {
              SetSignTxt('done')
              setSignatureHash(signhash);
              toast.success("Verified Successfully")
            } else {
              toast.error("Signature Failed")
            }
          }
        }
      }
      else {
        console.log("Comming On Lm Wallet");
        var Arg = {
          to: to,
          _amount: _amount,
          password: password,
          tot: tot,
          Type: String(NFT.type),
          Chain: String(chain),
          Mail: localStorage.loggedinaddress,
          LoginAddress: localStorage.userAddress,
          Key: null
        }
        var receipt = await LMSignCall(Arg)
        console.log("sajdsakadsda", receipt.data);

        if (receipt.data.Status) {
          SetSignTxt('done')
          setSignatureHash(receipt.data.signedTx);
          toast.success("Verified Successfully")
        } else {
          toast.error("Signature Failed")
        }
      }
    } catch (e) {
      console.log("sdasdasdasd", e);
      SetSignTxt('Try again')
      toast.error("Signature Failed")
    }
  }

  const selectChange = async (e) => {
    // console.log("fkdjdsfsdgjfsldfg", e);
    if ((e).length <= 12) {
      setNoofToken(e)
    } else {
      setNoofToken(0)
    }

  }

  //Bulkminting Calls

  const ImageMergeProcess = async () => {
    console.log("sadasdasdasda");
    setBuystate(true)
    const id = toast.loading("Image Generating", { closeButton: true, theme: "dark" });
    console.log("asdasdsadsadas NFT", NFT, tokDetails);
    var timestamp = Date.now()
    var updateData = {
      hashValue: NFT.hashValue,
      tokenID: timestamp,
      status: NFT?.status,
      BulkMint: tokDetails.BulkMint == 'false' ? 'true' : 'true',
      LazyStatus: NFT.balance == NoofToken ? "completed" : "pending",
    }
    console.log("iuoiuiyuittr", timestamp)
    NFT.ScannerTokenID = timestamp
    tokDetails.ScannerTokenID = timestamp
    var NewOwnerDetail = { Status: NFT?.status, Hash: NFT.hashValue, ConAddr: NFT.contractAddress, Owns: NoofToken, NFTDetail: NFT, Action: "Purchase", NewOwner: Wallet_Details.UserAccountAddr, OldOwner: NFT.tokenOwner, coinname: NFT.coinname, tokenName: tokName, Collection: tokDetails.Collection, updateData: updateData, LazyStatus: "completed", SelectedNetwork: tokDetails.SelectedNetwork, QR_tokenID: NFT.QR_tokenID, Bulkmint: tokDetails.Bulkmint, NFTTokens: tokDetails, ScannerTokenID: timestamp }
    console.log("newowner deetail", NewOwnerDetail, updateData,)
    var Resp11 = await imageImger(NewOwnerDetail)
    console.log("sdasdasdasdasdadqeq", Resp11.data);
    if (Resp11.Success == "true" && Resp11.data.NFTDetails.type) {
      toast.update(id, {
        render: "Image Generated Succesfully",
        type: "success",
        isLoading: false,
        autoClose: 2500,
        closeButton: true,
        theme: "dark"
      });
      if (tokDetails.PublishStatus == false) {
        BulkMintLazyCall(Resp11.data.NFTDetails, timestamp)
      } else {
        BulkMintLazyCallClaimNfts(Resp11.data.NFTDetails, timestamp)
      }
    } else {
      setBuystate(false)
      toast.update(id, {
        render: "Error in Image Generating. Please tryAgain",
        type: "error",
        isLoading: false,
        autoClose: 2500,
        closeButton: true,
        theme: "dark"
      });
    }
  }

  const BulkMintLazyCall = async (Datas, timestamp) => {
    setBuystate(true)
    var chainid = tokDetails?.SelectedNetwork
    var networkConfiguration = {}
    console.log("sdfsfdfsdfsdfs", Number(chainid), network.MATIC.Chainid);
    if (Number(chainid) === network.MATIC.Chainid) {
      console.log("netter eorrrrkkk maticz", network.MATIC);
      networkConfiguration = network.MATIC
    }
    else if (Number(chainid) === network.ETH.Chainid) {
      console.log("netter eorrrrkkk", network.ETH);
      networkConfiguration = network.ETH
    }
    else if (Number(chainid) === network.CAM.Chainid) {
      console.log("netter eorrrrkkk", network.CAM);
      networkConfiguration = network.CAM
    }

    console.log('nft token detial', tokDetails, NFT, Datas)
    // console.log("fwkjdfndkfjnsdnsdkf", Web3.utils.toWei(String(tokDetails?.tokenRoyality)));
    NFT.ScannerTokenID = timestamp
    tokDetails.ScannerTokenID = timestamp
    var Royaltity = Web3.utils.toWei(String(tokDetails?.tokenRoyality))
    var tokendbPrice = tokDetails?.tokenPrice == 0 ? 0 : Web3.utils.toWei(String(tokDetails?.tokenPrice))
    // console.log("qweqweqwreqrq", Royaltity, tokendbPrice);
    var nounceArg = { RandomName: RandomName, NonceHash: NonceHash, SignatureHash: SignatureHash }
    Wallet_Details.networkConfiguration = networkConfiguration
    var Arg = { NFTOwner: NFT.tokenOwner, NFTId: NFT.tokenID, PriceWithoutSerfee: Prices.PriceWithoutSerfee, PriceWithSerfee: Prices.PriceWithSerfee, NoofNFTs: tokDetails.EventName == "NoEvent" && tokDetails.PublishStatus == false ? NoofToken : 1, isToken: false, TotalToken: NFT.quantity, Royaltity: Royaltity, tokendbPrice: tokendbPrice, nounceArg: nounceArg, Datas: Datas, ContractStatus: "tickets" }
    console.log("data arg or contract", Arg, Wallet_Details)
    if (localStorage.walletConnectType !== "LMWallet") {
      var Receipt = await ContactHootBulkMint(Arg, NFT.type, Wallet_Details, tokDetails, NFT)
    } else {
      const nounce1 = tokDetails.Bulkmint == "Bulk" ? Arg?.nounceArg?.NonceHash : tokDetails.NonceHash;
      const RandomName1 = tokDetails.Bulkmint == "Bulk" ? Arg?.nounceArg?.RandomName : tokDetails.RandomName;
      const SignatureHash1 = tokDetails.Bulkmint == "Bulk" ? Arg?.nounceArg?.SignatureHash : tokDetails.SignatureHash
      const NewTokenIds1 = tokDetails.tokenID == tokDetails.QR_tokenID ? Number(0) : Number(tokDetails.tokenID)
      console.log("adsuhiuhdqkew", SignatureHash);
      var Key = null
      var AdAdd = null
      var Argument = [[Arg.Datas.ipfsmeta, "Status", "lazy", RandomName1, Arg.ContractStatus,"LM"], [tokDetails.tokenOwner, Wallet_Details.UserAccountAddr, tokDetails.tokenCreator], [Arg.NoofNFTs, "1155", Arg.Royaltity, Arg.PriceWithoutSerfee, Arg.TotalToken, nounce1, Arg.tokendbPrice, NewTokenIds1,"1000000000000000000"], "Coin", SignatureHash1]

      var Receipt = await LMWalletCall(Arg, NFT.type, Wallet_Details, tokDetails, NFT, "Trade", Wallet_Details.networkConfiguration.TradeContract, Wallet_Details.networkConfiguration.TradeContract, Argument, "ticketMinting", Key, true, Arg.PriceWithSerfee, AdAdd)
    }

    console.log("returner repsonse receipt", Receipt)
    if (Receipt.status) {
      if (tokDetails.SelectedNetwork == config.MATIC_ID) {
        // console.log("sfvfjnlkmkls", tokDetails.SelectedNetwork);
        var tokenid = tokDetails.type == 721 ? Receipt?.logs[4]?.topics[3] : Receipt?.logs[4]?.topics[3];

      } else if (tokDetails.SelectedNetwork == config.ETH_ID) {
        console.log("binanceeeeeee");

        var tokenid = tokDetails.type == 721 ? Receipt?.logs[3]?.topics[3] : Receipt?.logs[3]?.topics[3];

      } else  if (tokDetails.SelectedNetwork == config.CAM_ID) {
        // console.log("sfvfjnlkmkls", tokDetails.SelectedNetwork);
        var tokenid = tokDetails.type == 721 ? Receipt?.logs[2]?.topics[2] : Receipt?.logs[2]?.topics[2];

      }

      const tokenID = Web3Utils.hexToNumber(Number(tokenid));
      // console.log("tokenid from receipt", tokenID, NFT)
      // console.log("gyugduasguisgcshg", NFT, tokDetails);
      var updateData = {
        hashValue: Receipt.transactionHash,
        tokenID: String(tokenID),
        status: Receipt?.status,
        BulkMint: tokDetails.BulkMint == 'false' ? 'true' : 'true',
        LazyStatus: NFT.balance == NoofToken ? "completed" : "pending",

      }
      // console.log("qwennmbbczxczxX", tokDetails);
      // var NewOwnerDetail = { Status: Receipt?.status, Hash: Receipt.transactionHash, ConAddr: NFT.contractAddress, Owns: NoofToken, NFTDetail: NFT, Action: "Purchase", NewOwner: Wallet_Details.UserAccountAddr, OldOwner: NFT.tokenOwner, coinname: NFT.coinname, tokenName: tokName, Collection: tokDetails.Collection, updateData: updateData, LazyStatus: "completed", SelectedNetwork: tokDetails.SelectedNetwork, QR_tokenID: NFT.QR_tokenID, Bulkmint: tokDetails.Bulkmint, NFTTokens: tokDetails }
      // console.log("newowner deetail", NewOwnerDetail, updateData,)
      // var Resp11 = await imageImger(NewOwnerDetail)
      // console.log("sdasdasdasdasdadqeq", Resp11.data);
      //if (Resp11.Success == "true" && Resp11.data.NFTDetails.type) {
      var NewOwnerDetail1 = { Status: Receipt?.status, Hash: Receipt.transactionHash, ConAddr: NFT.contractAddress, Owns: tokDetails.EventName == "NoEvent" && tokDetails.PublishStatus == false ? NoofToken : 1, NFTDetail: NFT, Action: "Purchase", NewOwner: Wallet_Details.UserAccountAddr, OldOwner: NFT.tokenOwner, coinname: NFT.coinname, tokenName: tokName, Collection: tokDetails.Collection, updateData: updateData, LazyStatus: "completed", SelectedNetwork: tokDetails.SelectedNetwork, QR_tokenID: NFT.QR_tokenID, Bulkmint: tokDetails.Bulkmint, NFTTokens: Datas, ScannerTokenID: Datas.ScannerTokenID }
      console.log("Comming Inside");
      var Resp = await UpdateNFTOwnersBulk(NewOwnerDetail1)

      if (Resp) {
        $('#buy_closs').trigger("click");
        toast.success("Purchased Sucessfully")
        if (tokDetails.EventName == "NoEvent" && tokDetails.PublishStatus == false) {
          setTimeout(() => {
            history.push(`/my-items/${Wallet_Details.UserAccountAddr}`)
          }, 1000);
        } else {
          $('#Claim_modal').trigger("click");
          var DetailEvent = {
            Address: Wallet_Details.UserAccountAddr,
            EventCreator: NFT.tokenCreator,
            tokenID: String(tokenID),
            QR_tokenID: NFT.QR_tokenID
          }
          dispatch({
            type: Account_Connect,
            Account_Detail: {
              DetailEvent: DetailEvent,
            }
          })
          cookies.set('ClaimerCookie', Wallet_Details.UserAccountAddr, { path: '/', domain: 'lazyminter.com' });
          cookies.set('TokenIDSCookie', String(tokenID), { path: '/', domain: 'lazyminter.com' });
          console.log("asdsadasdas", cookies.get('ClaimerCookie'));
          setTimeout(() => {
            // history.push(`"http://"+${tokDetails.EventName}+"."+"localhost:3000"`)
            history.push(`/my-items/${Wallet_Details.UserAccountAddr}`)
          }, 1000);
        }
      }
      // }
    }
    else {
      toast.error("Try it Again Later.Please Check Your Funds")
    }
    setBuystate(false)



  }

  const ImageMergeProcessClaim = async (NFT, tokDetails) => {
    console.log("sadasdasdasda");
    setBuystate(true)
    const id = toast.loading("Transferring the token to your wallet", { closeButton: true, theme: "dark" });
    console.log("asdasdsadsadas NFT", NFT, tokDetails, NoofToken);
    var timestamp = Date.now()
    var updateData = {
      hashValue: NFT.hashValue,
      tokenID: timestamp,
      status: NFT?.status,
      BulkMint: tokDetails.BulkMint == 'false' ? 'true' : 'true',
      LazyStatus: NFT.balance == NoofToken ? "completed" : "pending",
    }
    console.log("iuoiuiyuittr", timestamp)
    NFT.ScannerTokenID = timestamp
    tokDetails.ScannerTokenID = timestamp
    var NewOwnerDetail = { Status: NFT?.status, Hash: NFT.hashValue, ConAddr: NFT.contractAddress, Owns: NoofToken, NFTDetail: NFT, Action: "Purchase", NewOwner: Wallet_Details.UserAccountAddr, OldOwner: NFT.tokenOwner, coinname: NFT.coinname, tokenName: tokName, Collection: tokDetails.Collection, updateData: updateData, LazyStatus: "completed", SelectedNetwork: tokDetails.SelectedNetwork, QR_tokenID: NFT.QR_tokenID, Bulkmint: tokDetails.Bulkmint, NFTTokens: tokDetails, ScannerTokenID: timestamp }
    console.log("newowner deetail", NewOwnerDetail, updateData,)
    var Resp11 = await imageImger(NewOwnerDetail)
    console.log("sdasdasdasdasdadqeq", Resp11.data);
    if (Resp11.Success == "true" && Resp11.data.NFTDetails.type) {
      // toast.update(id, {
      //   render: "Image Generated Succesfully",
      //   type: "success",
      //   isLoading: false,
      //   autoClose: 2500,
      //   closeButton: true,
      //   theme: "dark"
      // });
      if (tokDetails.PublishStatus == false) {
        BulkMintLazyCall(Resp11.data.NFTDetails, timestamp)
      } else {
        BulkMintLazyCallClaimNfts(NFT, tokDetails, Resp11.data.NFTDetails, timestamp, id)
      }
    } else {
      setBuystate(false)
      toast.update(id, {
        render: "Error in Image Generating. Please tryAgain",
        type: "error",
        isLoading: false,
        autoClose: 2500,
        closeButton: true,
        theme: "dark"
      });
    }
  }

  const BulkMintLazyCallClaimNfts = async (NFT, tokDetails, Datas, timestamp, id) => {
    setBuystate(true)
    var chainid = tokDetails?.SelectedNetwork
    var networkConfiguration = {}
    console.log("sdfsfdfsdfsdfs", Number(chainid), network.MATIC.Chainid);
    if (Number(chainid) === network.MATIC.Chainid) {
      console.log("netter eorrrrkkk maticz", network.MATIC);
      networkConfiguration = network.MATIC
    }
    else if (Number(chainid) === network.ETH.Chainid) {
      console.log("netter eorrrrkkk", network.ETH);
      networkConfiguration = network.ETH
    }
    else if (Number(chainid) === network.CAM.Chainid) {
      console.log("netter eorrrrkkk", network.CAM);
      networkConfiguration = network.CAM
    }

    console.log('nft token detial', tokDetails, NFT, Datas)
    // console.log("fwkjdfndkfjnsdnsdkf", Web3.utils.toWei(String(tokDetails?.tokenRoyality)));
    NFT.ScannerTokenID = timestamp
    tokDetails.ScannerTokenID = timestamp
    var Royaltity = Web3.utils.toWei(String(tokDetails?.tokenRoyality))
    var tokendbPrice = tokDetails?.tokenPrice == 0 ? 0 : Web3.utils.toWei(String(tokDetails?.tokenPrice))
    // console.log("qweqweqwreqrq", Royaltity, tokendbPrice);
    var nounceArg = { RandomName: "", NonceHash: "", SignatureHash: "0x0" }
    Wallet_Details.networkConfiguration = networkConfiguration
    var Arg = { NFTOwner: NFT.tokenOwner, NFTId: NFT.tokenID, PriceWithoutSerfee: 0, PriceWithSerfee: 0, NoofNFTs: 1, isToken: false, TotalToken: NFT.quantity, Royaltity: Royaltity, tokendbPrice: tokendbPrice, nounceArg: nounceArg, Datas: Datas }
    console.log("data arg or contract", Arg, Wallet_Details)
    if (localStorage.walletConnectType !== "LMWallet") {
      var Receipt = await ContactHootClaimNfts(Arg, NFT.type, Wallet_Details, tokDetails, NFT)
    } else {
      var nounce1 = tokDetails.Bulkmint == "Bulk" ? Arg?.nounceArg?.NonceHash : tokDetails.NonceHash;
      var RandomName1 = tokDetails.Bulkmint == "Bulk" ? Arg?.nounceArg?.RandomName : tokDetails.RandomName;
      var SignatureHash1 = tokDetails.Bulkmint == "Bulk" ? Arg?.nounceArg?.SignatureHash : tokDetails.SignatureHash
      var NewTokenIds = tokDetails.tokenID == tokDetails.QR_tokenID ? Number(0) : Number(tokDetails.tokenID)
      var Key = config.PrivateKey
      var AdAdd = config.AdminAddress
      var Argument = [[Arg.Datas.ipfsmeta, "Status", "lazy", RandomName1, "claim","LM"], [tokDetails.tokenOwner, Wallet_Details.UserAccountAddr, tokDetails.tokenCreator], [Arg.NoofNFTs, "1155", Arg.Royaltity, Arg.PriceWithoutSerfee, Arg.TotalToken, "0", "0", NewTokenIds,"1000000000000000000"], "Coin", SignatureHash1]
      var Receipt = await LMWalletCall(Arg, NFT.type, Wallet_Details, tokDetails, NFT, "Trade", Wallet_Details.networkConfiguration.TradeContract, Wallet_Details.networkConfiguration.TradeContract, Argument, "ticketMinting", Key, true, Arg.PriceWithSerfee, AdAdd)
    }

    console.log("returner repsonse receipt", Receipt)
    console.log("dfnsdnfsdfnmsdfs", Wallet_Details);
    var gassLimit = await Wallet_Details.Web3Pro.eth.getTransaction(Receipt.transactionHash)
    console.log("aadasdasdas", gassLimit);
    var GasPricee = gassLimit.gasPrice * gassLimit.gas
    console.log("asdfhasdasa", GasPricee);
    var final = GasPricee / 1e18
    console.log("ssdsadadwqe", final);

    var GasPrice = Receipt
    if (Receipt.status) {
      if (tokDetails.SelectedNetwork == config.MATIC_ID) {
        var tokenid = tokDetails.type == 721 ? Receipt?.logs[3]?.topics[3] : Receipt?.logs[3]?.topics[3];
      } else if (tokDetails.SelectedNetwork == config.ETH_ID) {
        var tokenid = tokDetails.type == 721 ? Receipt?.logs[3]?.topics[3] : Receipt?.logs[3]?.topics[3];
      } else if (tokDetails.SelectedNetwork == config.CAM_ID) {
        var tokenid = tokDetails.type == 721 ? Receipt?.logs[2]?.topics[2] : Receipt?.logs[2]?.topics[2];
      }

      const tokenID = Web3Utils.hexToNumber(Number(tokenid));
      console.log("tokenid from receipt", tokenID, NFT)
      console.log("gyugduasguisgcshg", NFT, tokDetails);
      var updateData = {
        hashValue: Receipt.transactionHash,
        tokenID: String(tokenID),
        status: Receipt?.status,
        BulkMint: tokDetails.BulkMint == 'false' ? 'true' : 'true',
        LazyStatus: NFT.balance == NoofToken ? "completed" : "pending",

      }
      var NewOwnerDetail1 = { Status: Receipt?.status, Hash: Receipt.transactionHash, ConAddr: NFT.contractAddress, Owns: tokDetails.EventName == "NoEvent" && tokDetails.PublishStatus == false ? NoofToken : 1, NFTDetail: NFT, Action: "Purchase", NewOwner: Wallet_Details.UserAccountAddr, OldOwner: NFT.tokenOwner, coinname: NFT.coinname, tokenName: tokName, Collection: tokDetails.Collection, updateData: updateData, LazyStatus: "completed", SelectedNetwork: tokDetails.SelectedNetwork, QR_tokenID: NFT.QR_tokenID, Bulkmint: tokDetails.Bulkmint, NFTTokens: Datas, ScannerTokenID: Datas.ScannerTokenID, GasAmount: final, Pointss: Number(tokDetails.Points) }
      console.log("Comming Inside");
      var Resp = await UpdateNFTOwnersBulk(NewOwnerDetail1)
      if (Resp) {
        $('#buy_closs').trigger("click");
        // toast.success("Purchased Sucessfully")

        if (tokDetails.EventName == "NoEvent" && tokDetails.PublishStatus == false) {
          toast.update(id, {
            render: "Token transfered to your wallet successfully",
            type: "success",
            isLoading: false,
            autoClose: 2500,
            closeButton: true,
            theme: "dark"
          });
          setTimeout(() => {
            history.push(`/my-items/${Wallet_Details.UserAccountAddr}`)
          }, 1000);
        } else {
          $('#Claim_modal').trigger("click");
          var DetailEvent = {
            Address: Wallet_Details.UserAccountAddr,
            EventCreator: NFT.tokenCreator,
            tokenID: String(tokenID),
            QR_tokenID: NFT.QR_tokenID
          }
          dispatch({
            type: Account_Connect,
            Account_Detail: {
              DetailEvent: DetailEvent,
            }
          })
          cookies.set('ClaimerCookie', Wallet_Details.UserAccountAddr, { path: '/', domain: 'lazyminter.com' });
          cookies.set('TokenIDSCookie', String(tokenID), { path: '/', domain: 'lazyminter.com' });
          console.log("asdsadasdas", cookies.get('ClaimerCookie'));
          // socket.emit('deletee', String(tokenID))
          // socket.on('deletee', (data) => {
          //     console.log("deletee", data)
          // })
          console.log("ashfdisfjcsad", `https://${tokDetails.EventName}.lazyminter.com/`, window.location.href);
          setTimeout(() => {
            toast.update(id, {
              render: "Token transfered to your wallet successfully",
              type: "success",
              isLoading: false,
              autoClose: 2500,
              closeButton: true,
              theme: "dark"
            });
            window.location.href = `https://${tokDetails.EventName}.lazyminter.com/`;
            // history.push(`http://${tokDetails.EventName}.localhost:3000/`)
            // history.push(`/my-items/${Wallet_Details.UserAccountAddr}`)
          }, 8000);
        }
      }
      // }
    }
    else {
      toast.error("Try it Again Later.Please Check Your Funds")
    }
    setBuystate(false)



  }

  //completed
  const EventPassPortCalim = async (NFT, tokDetails) => {
    // alert("97")
    const id = toast.loading("Token Claim Initiated", { closeButton: true });
    var signCAllFun = await signcallClaim()
    console.log("signCAllFun", signCAllFun);
    if (signCAllFun.Hash) {
      setBuystate(true)
      console.log("RandomName", RandomName, NonceHash);
      tokDetails.SignatureHash = signCAllFun.Hash
      tokDetails.NonceHash = signCAllFun.Nonce
      tokDetails.RandomName = signCAllFun.RandomName
      console.log("dfnsdknfksndfksdNFT here it comming", NFT, tokDetails);
      var chainid = tokDetails?.SelectedNetwork
      var networkConfiguration = {}
      console.log("sdfsfdfsdfsdfs", Number(chainid), network.MATIC.Chainid);
      if (Number(chainid) === network.MATIC.Chainid) {
        console.log("netter eorrrrkkk maticz", network.MATIC);
        networkConfiguration = network.MATIC
      }
      else if (Number(chainid) === network.ETH.Chainid) {
        console.log("netter eorrrrkkk", network.ETH);
        networkConfiguration = network.ETH
      }
      else if (Number(chainid) === network.CAM.Chainid) {
        console.log("netter eorrrrkkk", network.CAM);
        networkConfiguration = network.CAM
      }
      var tokendbPrice = tokDetails?.tokenPrice == 0 ? 0 : Web3.utils.toWei(String(tokDetails?.tokenPrice))
      Wallet_Details.networkConfiguration = networkConfiguration
      var Arg = { NFTOwner: NFT.tokenOwner, NFTId: NFT.tokenID, PriceWithoutSerfee: Prices.PriceWithoutSerfee, PriceWithSerfee: Prices.PriceWithSerfee, NoofNFTs: NoofToken, ContractAddress: NFT.contractAddress, Coinname: NFT.coinname, Status: NFT.LazyStatus, Hash: tokDetails, TotalToken: NFT.quantity, tokendbPrice: tokendbPrice, AdminAddre: config.AdminAddress, }
      var Type = NFT.type
      var Provider = Wallet_Details
      var isToken = false
      if (localStorage.walletConnectType !== "LMWallet") {
        var Receipt = await ContactHootPassPort(Arg, Type, Provider, tokDetails, NFT, isToken)
      } else {
        // var nounce = Arg.Hash.NonceHash;
        // var RandomName = Arg.Hash.RandomName;
        // var SignatureHash = Arg.Hash.SignatureHash
        var Key = null
        var AdAdd = null
        var Argument = [[tokDetails.ipfsmeta, "Status", "lazy", Arg.Hash.RandomName, "other", "claim721"], [tokDetails.tokenCreator, Wallet_Details.UserAccountAddr, config.AdminAddress], [Arg.NoofNFTs, Type, 0, 0, Arg.TotalToken, Arg.Hash.NonceHash, Arg.tokendbPrice], "Coin", Arg.Hash.SignatureHash]
        var Receipt = await LMWalletCall(Arg, NFT.type, Wallet_Details, tokDetails, NFT, "Trade", Wallet_Details.networkConfiguration.TradeContract, Wallet_Details.networkConfiguration.TradeContract, Argument, "lazyMint", Key, false, Arg.PriceWithSerfee, AdAdd)
      }

      console.log("receit response", Receipt)


      if (Receipt.status) {
        if (tokDetails.SelectedNetwork == config.MATIC_ID) {
          // console.log("sfvfjnlkmkls", tokDetails.SelectedNetwork);
          var tokenid = tokDetails.type == 721 ? Receipt?.logs[2]?.topics[2] : Receipt?.logs[2]?.topics[2];
        } else if (tokDetails.SelectedNetwork == config.ETH_ID) {
          // console.log("binanceeeeeee");
          var tokenid = tokDetails.type == 721 ? Receipt?.logs[3]?.topics[3] : Receipt?.logs[3]?.topics[3];
        } else if (tokDetails.SelectedNetwork == config.CAM_ID) {
          // console.log("sfvfjnlkmkls", tokDetails.SelectedNetwork);
          var tokenid = tokDetails.type == 721 ? Receipt?.logs[2]?.topics[2] : Receipt?.logs[2]?.topics[2];
        }

        const tokenID = Web3Utils.hexToNumber(tokenid);
        // console.log("tokenid from receipt", tokenID, NFT)
        // console.log("gyugduasguisgcshg", NFT, tokDetails);
        var updateData = {
          hashValue: Receipt.transactionHash,
          tokenID: String(tokenID),
          status: Receipt?.status,
          // BulkMint: tokDetails.BulkMint == 'false' ? 'true' : 'true',
          LazyStatus: NFT.balance == NoofToken ? "completed" : "pending",

        }
        var NewOwnerDetail = { Status: Receipt?.status, Hash: Receipt.transactionHash, ConAddr: NFT.contractAddress, Owns: NoofToken, NFTDetail: NFT, Action: "Purchase", NewOwner: Wallet_Details.UserAccountAddr, OldOwner: NFT.tokenOwner, coinname: NFT.coinname, tokenName: tokName, Collection: tokDetails.Collection, updateData: updateData, LazyStatus: "completed", SelectedNetwork: tokDetails.SelectedNetwork, QR_tokenID: NFT.QR_tokenID, HashDb: signCAllFun, Pointss: tokDetails?.Points }
        console.log("newowner deetail Buycall", NewOwnerDetail)
        var Resp = await UpdateNFTOwners(NewOwnerDetail)

        if (Resp) {
          toast.update(id, { render: "Sucessfully Claimed Your Token", type: "success", isLoading: false, autoClose: 2500, });
          // toast.success("Sucessfully Claimed Your Token")
          // $('#buy_closs').trigger("click");
          setTimeout(() => {
            history.push(`/my-items/${Wallet_Details.UserAccountAddr}`)
          }, 1000);
        }
      } else {
        toast.update(id, { render: "Try it Again Later.Please Check Your Funds", type: "error", isLoading: false, autoClose: 2500, });
        // toast.error("Please Try it Again Later.Network Error")
      }
      setBuystate(false)
    }
  }

  async function signcallClaim() {
    try {
      var chainid = tokDetails?.SelectedNetwork
      var networkConfiguration = {}
      console.log("sdfsfdfsdfsdfs", Number(chainid), network.MATIC.Chainid);
      if (Number(chainid) === network.MATIC.Chainid) {
        console.log("netter eorrrrkkk maticz", network.MATIC);
        networkConfiguration = network.MATIC
      }
      else if (Number(chainid) === network.ETH.Chainid) {
        console.log("netter eorrrrkkk", network.ETH);
        networkConfiguration = network.ETH
      }
      else if (Number(chainid) === network.CAM.Chainid) {
        console.log("netter eorrrrkkk", network.CAM);
        networkConfiguration = network.CAM
      }
      Wallet_Details.networkConfiguration = networkConfiguration
      var Hash = {
        Hash: null,
        Nonce: null,
        RandomName: null
      }
      var generator = require('generate-password');
      var randomNum = randomInteger(10000000, 100000000);
      var password = generator.generate({
        length: 10,
        numbers: true
      });
      var web3RpcPro = new Web3(Wallet_Details.networkConfiguration.RPC_URL)
      var chain = tokDetails?.SelectedNetwork
      const to = config.AdminAddress
      const _amount = tokDetails.tokenPrice == "" || tokDetails.tokenPrice == undefined || tokDetails?.PublishStatus == true ? 0 : web3RpcPro.utils.toWei(String(tokDetails.tokenPrice));
      const _nonce = Date.now();
      // setNonceHash(_nonce);
      setRandomName(password)
      var tot = _nonce + Number(randomNum);
      setNonceHash(tot);
      if (localStorage.walletConnectType !== "LMWallet") {
        if (Wallet_Details.Web3Pro) {
          var web3Rpc = new Web3(Wallet_Details.providerss)
          if (web3Rpc) {
            SetSignTxt('On progress')

            var web3RpcPro = new Web3(Wallet_Details.providerss);
            console.log("dwqdwqewqewqeqw", to, _amount, password, tot);
            const result = web3RpcPro.utils.soliditySha3(to, _amount, password, tot);
            console.log("asdsadasfdafaf", result);
            const signedTx = await Wallet_Details.Web3Pro.eth.accounts.sign(result, config.PrivateKey);
            console.log("jnsadnaskjdasdsada", signedTx);
            Hash.Nonce = tot
            Hash.RandomName = password
            Hash.Hash = signedTx.signature
            if (Hash.Hash) {
              SetSignTxt('done')
              setSignatureHash(signedTx.signature);
              toast.success("Verified Successfully")
            } else {
              toast.error("Signature Failed")
            }
          }
        }
      }
      else {
        console.log("Comming On Lm Wallet");
        var Arg = {
          to: to,
          _amount: _amount,
          password: password,
          tot: tot,
          Type: String(NFT.type),
          Chain: String(chain),
          Mail: localStorage.loggedinaddress,
          LoginAddress: localStorage.userAddress,
          Key: config.PrivateKey
        }
        var receipt = await LMSignCall(Arg)
        console.log("sajdsakadsda", receipt.data);
        Hash.Nonce = tot
        Hash.RandomName = password
        Hash.Hash = receipt.data.signedTx
        if (receipt.data.Status) {
          SetSignTxt('done')
          setSignatureHash(receipt.data.signedTx);
          toast.success("Verified Successfully")
        } else {
          toast.error("Signature Failed")
        }
      }
      return Hash
    } catch (e) {
      console.log("sdasdasdasd", e);
      SetSignTxt('Try again')
      toast.error("Signature Failed")
    }
  }

  //completed
  const FiatPurchase = async (FiatBuy) => {
    console.log("FiatBuy", FiatBuy);
    // alert("lazymintcall")
    const id = toast.loading("Token Purchase Initiated", { closeButton: true });
    setBuystate(true)
    console.log('nft token detial', tokDetails, NFT, Wallet_Details)
    var chainid = tokDetails?.SelectedNetwork
    var networkConfiguration = {}
    console.log("sdfsfdfsdfsdfs", Number(chainid), network.MATIC.Chainid);
    if (Number(chainid) === network.MATIC.Chainid) {
      console.log("netter eorrrrkkk maticz", network.MATIC);
      networkConfiguration = network.MATIC
    }
    else if (Number(chainid) === network.ETH.Chainid) {
      console.log("netter eorrrrkkk", network.ETH);
      networkConfiguration = network.ETH
    }
    else if (Number(chainid) === network.CAM.Chainid) {
      console.log("netter eorrrrkkk", network.CAM);
      networkConfiguration = network.CAM
    }

    var Calculation = await CharityAndRoyaltyCalculation(tokDetails, NFT)
    console.log("Calculation", Calculation);

    console.log("Prices.PriceWithSerfee", Prices);
    var Royaltity = Web3.utils.toWei(String(tokDetails?.tokenRoyality))
    var tokendbPrice = tokDetails?.tokenPrice == 0 ? 0 : Web3.utils.toWei(String(tokDetails?.tokenPrice))
    var nounceArg = { RandomName: RandomName, NonceHash: NonceHash, SignatureHash: SignatureHash }
    Wallet_Details.networkConfiguration = networkConfiguration
    var Arg = { NFTOwner: NFT.tokenOwner, NFTId: NFT.tokenID, PriceWithoutSerfee: Prices.PriceWithoutSerfee, PriceWithSerfee: Prices.PriceWithSerfee, NoofNFTs: NoofToken, isToken: false, TotalToken: NFT.quantity, Royaltity: Royaltity, tokendbPrice: tokendbPrice, nounceArg: nounceArg, Hash: tokDetails }

    if (localStorage.walletConnectType !== "LMWallet") {
      var Receipt = await ContactHookFiatTransfer(Arg, NFT.type, Wallet_Details, tokDetails, NFT)
    } else {
      // var nounce = Arg.Hash.NonceHash;
      // var RandomName = Arg.Hash.RandomName;
      // var SignatureHash = Arg.Hash.SignatureHash
      var Key = null
      var AdAdd = null
      var Argument = [[tokDetails.ipfsmeta, "Status", "lazy", Arg.Hash.RandomName, "other", "fiat"], [NFT.tokenCreator, Wallet_Details.UserAccountAddr], [Arg.NoofNFTs, NFT.type, Arg.Royaltity, 0, Arg.TotalToken, Arg.Hash.NonceHash, Arg.tokendbPrice], "Coin", Arg.Hash.SignatureHash]
      var Receipt = await LMWalletCall(Arg, NFT.type, Wallet_Details, tokDetails, NFT, "Trade", Wallet_Details.networkConfiguration.TradeContract, Wallet_Details.networkConfiguration.TradeContract, Argument, "lazyMint", Key, false, Arg.PriceWithSerfee, AdAdd)
    }

    console.log("Receipt", Receipt);
    if (Receipt.status) {
      if (tokDetails.SelectedNetwork == config.MATIC_ID) {
        // console.log("sfvfjnlkmkls", tokDetails.SelectedNetwork);
        var tokenid = tokDetails.type == 721 ? Receipt?.logs[3]?.topics[3] : Receipt?.logs[3]?.topics[3];
      } else if (tokDetails.SelectedNetwork == config.ETH_ID) {
        // console.log("binanceeeeeee");
        var tokenid = tokDetails.type == 721 ? Receipt?.logs[3]?.topics[3] : Receipt?.logs[3]?.topics[3];
      } else if (tokDetails.SelectedNetwork == config.CAM_ID) {
        // console.log("sfvfjnlkmkls", tokDetails.SelectedNetwork);
        var tokenid = tokDetails.type == 721 ? Receipt?.logs[2]?.topics[2] : Receipt?.logs[2]?.topics[2];
      }

      const tokenID = Web3Utils.hexToNumber(tokenid);
      // console.log("tokenid from receipt", tokenID, NFT)
      // console.log("gyugduasguisgcshg", NFT, tokDetails);
      var updateData = {
        hashValue: Receipt.transactionHash,
        tokenID: String(tokenID),
        status: Receipt?.status,
        BulkMint: tokDetails.BulkMint == 'false' ? 'true' : 'true',
        LazyStatus: NFT.balance == NoofToken ? "completed" : "pending",

      }
      var NewOwnerDetail = { Status: Receipt?.status, Hash: Receipt.transactionHash, ConAddr: NFT.contractAddress, Owns: NoofToken, NFTDetail: NFT, Action: "Purchase", NewOwner: Wallet_Details.UserAccountAddr, OldOwner: NFT.tokenOwner, coinname: NFT.coinname, tokenName: tokName, Collection: tokDetails.Collection, updateData: updateData, LazyStatus: "completed", SelectedNetwork: tokDetails.SelectedNetwork, QR_tokenID: NFT.QR_tokenID, ServiceFee: Wallet_Details.Service_Fee.Buyer, FiatBuy: FiatBuy, Fiat: NFT.Fiat, Charity: NFT.Charity, Calculation: Calculation, NFTPrice: Prices.PriceWithoutSerfee / 1e18, NFTPriceWithSerice: Prices.PriceWithSerfee / 1e18}
      console.log("newowner deetail", NewOwnerDetail, updateData, FiatBuy, Wallet_Details.Service_Fee.Buyer)
      var Resp = await UpdateNFTOwners(NewOwnerDetail)
      console.log("RespResp", Resp);
      if (Resp) {
        toast.update(id, { render: "Token Purchased Successfully", type: "success", isLoading: false, autoClose: 2500, });
        $('#buy_closs').trigger("click");
        setTimeout(() => {
          history.push(`/my-items/${Wallet_Details.UserAccountAddr}`)
        }, 1000);
      }
    }
    else {
      toast.update(id, { render: "Try it Again Later.Please Check Your Funds", type: "error", isLoading: false, autoClose: 2500, });
    }
    setBuystate(false)

  }
  //completed
  const TransferCall = async (FiatBuy) => {
    console.log("asdasdasdasdsa", NFT, FiatBuy);
    const id = toast.loading("Token Purchase Initiated", { closeButton: true });
    var Calculation = await CharityAndRoyaltyCalculation(tokDetails, NFT)
    console.log("Calculation", Calculation);
    var tokendbPrice = tokDetails?.tokenPrice == 0 ? 0 : Web3.utils.toWei(String(tokDetails?.tokenPrice))
    var Arg = {
      NFTOwner: NFT.tokenOwner,
      NFTId: NFT.tokenID,
      NoofNFTs: NoofToken,
      ToAddress: Wallet_Details.UserAccountAddr,
      LazyStatus: tokDetails.LazyStatus,
      tokendbPrice: tokendbPrice
    };

    var Type = NFT.type;
    var Provider = Wallet_Details;
    if (localStorage.walletConnectType !== "LMWallet") {
      var Receipt = await ContactHookFiatTransferAfterBuy(Arg, Type, Provider, NFT, tokDetails)
    } else {
      var Key = null
      var AdAdd = null
      var Argument = [NFT.RandomName, [Arg.NFTOwner, Arg.ToAddress], [Arg.NFTId, Arg.NoofNFTs, Type, NFT.NonceHash, Arg.tokendbPrice], NFT.contractAddress, NFT.SignatureHash]
      var Receipt = await LMWalletCall(Arg, NFT.type, Wallet_Details, tokDetails, NFT, "Trade", Wallet_Details.networkConfiguration.TradeContract, Wallet_Details.networkConfiguration.TradeContract, Argument, "nftTransfer", Key, false, Arg.PriceWithSerfee, AdAdd)
    }
    console.log("Transfer Recipt", Receipt)
    if (Receipt) {
      var Burn = { Type: NFT.type, Creater: NFT.tokenOwner, Owner: Wallet_Details.UserAccountAddr, TokeninitialCreator: NFT.tokenCreator, tokenName: tokDetails.tokenName, id: NFT.tokenID, ConAddr: NFT.contractAddress, NoOfToken: NoofToken, Status: 'true', Hash: Receipt.transactionHash, Network: tokDetails.SelectedNetwork, QR_tokenID: NFT.QR_tokenID, ServiceFee: Wallet_Details.Service_Fee.Buyer, FiatBuy: FiatBuy, Fiat: NFT.Fiat, Charity: NFT.Charity, Calculation: Calculation, NFTPrice: Prices.PriceWithoutSerfee / 1e18, NFTPriceWithSerice: Prices.PriceWithSerfee / 1e18 }
      // console.log("burn token payload", Burn)
      var Resp = await v1_TransferToken(Burn)
      console.log("burnreceiot", Resp)
      if (Resp.data) {
        toast.update(id, { render: "Token Purchased Successfully", type: "success", isLoading: false, autoClose: 2500, });
        $('#buy_closs').trigger("click");

        setTimeout(() => {
          history.push(`/my-items/${Wallet_Details.UserAccountAddr}`)
        }, 1000);
      } else {
        // setBurnState(false)
        toast.update(id, {
          render: "Token Tranfer Failed",
          type: "error",
          isLoading: false,
          autoClose: 2500,
        });
      }
    }
    else {
      toast.error("Try it Again Later.Please Check Your Funds")
    }
  };

  const ImageMergeForFiat = async (Data) => {
    console.log("sadasdasdasda");
    setBuystate(true)
    const id = toast.loading("Image Generating", { closeButton: true, theme: "dark" });
    console.log("asdasdsadsadas NFT", NFT, tokDetails);
    var timestamp = Date.now()
    var updateData = {
      hashValue: NFT.hashValue,
      tokenID: timestamp,
      status: NFT?.status,
      BulkMint: tokDetails.BulkMint == 'false' ? 'true' : 'true',
      LazyStatus: NFT.balance == NoofToken ? "completed" : "pending",
    }
    console.log("iuoiuiyuittr", timestamp)
    NFT.ScannerTokenID = timestamp
    tokDetails.ScannerTokenID = timestamp
    var NewOwnerDetail = { Status: NFT?.status, Hash: NFT.hashValue, ConAddr: NFT.contractAddress, Owns: NoofToken, NFTDetail: NFT, Action: "Purchase", NewOwner: Wallet_Details.UserAccountAddr, OldOwner: NFT.tokenOwner, coinname: NFT.coinname, tokenName: tokName, Collection: tokDetails.Collection, updateData: updateData, LazyStatus: "completed", SelectedNetwork: tokDetails.SelectedNetwork, QR_tokenID: NFT.QR_tokenID, Bulkmint: tokDetails.Bulkmint, NFTTokens: tokDetails, ScannerTokenID: timestamp}
    console.log("newowner deetail", NewOwnerDetail, updateData,)
    var Resp11 = await imageImger(NewOwnerDetail)
    console.log("sdasdasdasdasdadqeq", Resp11.data);
    if (Resp11.Success == "true" && Resp11.data.NFTDetails.type) {
      toast.update(id, {
        render: "Image Generated Succesfully",
        type: "success",
        isLoading: false,
        autoClose: 2500,
        closeButton: true,
        theme: "dark"
      });
      BulkMintLazyCallFiat(Resp11.data.NFTDetails, timestamp, Data)
    } else {
      setBuystate(false)
      toast.update(id, {
        render: "Error in Image Generating. Please tryAgain",
        type: "error",
        isLoading: false,
        autoClose: 2500,
        closeButton: true,
        theme: "dark"
      });
    }
  }

  //completed
  const BulkMintLazyCallFiat = async (Datas, timestamp, Data) => {
    setBuystate(true)
    var chainid = tokDetails?.SelectedNetwork
    var networkConfiguration = {}
    console.log("sdfsfdfsdfsdfs", Number(chainid), network.MATIC.Chainid);
    if (Number(chainid) === network.MATIC.Chainid) {
      console.log("netter eorrrrkkk maticz", network.MATIC);
      networkConfiguration = network.MATIC
    }
    else if (Number(chainid) === network.ETH.Chainid) {
      console.log("netter eorrrrkkk", network.ETH);
      networkConfiguration = network.ETH
    }
    else if (Number(chainid) === network.CAM.Chainid) {
      console.log("netter eorrrrkkk", network.CAM);
      networkConfiguration = network.CAM
    }
    var Calculation = await CharityAndRoyaltyCalculation(tokDetails, NFT)
    console.log("Calculation", Calculation);

    console.log('nft token detial', tokDetails, NFT, Datas)
    // console.log("fwkjdfndkfjnsdnsdkf", Web3.utils.toWei(String(tokDetails?.tokenRoyality)));
    NFT.ScannerTokenID = timestamp
    tokDetails.ScannerTokenID = timestamp
    var Royaltity = Web3.utils.toWei(String(tokDetails?.tokenRoyality))
    var tokendbPrice = tokDetails?.tokenPrice == 0 ? 0 : Web3.utils.toWei(String(tokDetails?.tokenPrice))
    // console.log("qweqweqwreqrq", Royaltity, tokendbPrice);
    var nounceArg = { RandomName: RandomName, NonceHash: NonceHash, SignatureHash: SignatureHash }
    Wallet_Details.networkConfiguration = networkConfiguration
    var Arg = { NFTOwner: NFT.tokenOwner, NFTId: NFT.tokenID, PriceWithoutSerfee: Prices.PriceWithoutSerfee, PriceWithSerfee: Prices.PriceWithSerfee, NoofNFTs: tokDetails.EventName == "NoEvent" && tokDetails.PublishStatus == false ? NoofToken : 1, isToken: false, TotalToken: NFT.quantity, Royaltity: Royaltity, tokendbPrice: tokendbPrice, nounceArg: nounceArg, Datas: Datas, ContractStatus: "fiat" }
    console.log("data arg or contract", Arg, Wallet_Details)
    if (localStorage.walletConnectType !== "LMWallet") {
      console.log("Comming on meta if");
      var Receipt = await ContactHootTicketsFiat(Arg, NFT.type, Wallet_Details, tokDetails, NFT)
    } else {
      console.log("Comming on else");
      var nounce1 = NFT.NonceHash;
      var RandomName1 = NFT.RandomName;
      var SignatureHash1 = NFT.SignatureHash
      var NewTokenIds1 = tokDetails.tokenID == tokDetails.QR_tokenID ? Number(0) : Number(tokDetails.tokenID)
      var Key = null
      var AdAdd = null
      var Argument = [[Arg.Datas.ipfsmeta, "Status", "lazy", RandomName1, Arg.ContractStatus,""], [tokDetails.tokenOwner, Wallet_Details.UserAccountAddr, tokDetails.tokenCreator], [Arg.NoofNFTs, "1155", Arg.Royaltity, '0', Arg.TotalToken, nounce1, Arg.tokendbPrice, NewTokenIds1,""], "Coin", SignatureHash1]
      var Receipt = await LMWalletCall(Arg, NFT.type, Wallet_Details, tokDetails, NFT, "Trade", Wallet_Details.networkConfiguration.TradeContract, Wallet_Details.networkConfiguration.TradeContract, Argument, "ticketMinting", Key, false, Arg.PriceWithSerfee, AdAdd)
    }

    // console.log("returner repsonse receipt", Receipt)
    if (Receipt.status) {
      if (tokDetails.SelectedNetwork == config.MATIC_ID) {
        // console.log("sfvfjnlkmkls", tokDetails.SelectedNetwork);
        var tokenid = tokDetails.type == 721 ? Receipt?.logs[3]?.topics[3] : Receipt?.logs[3]?.topics[3];

      } else if (tokDetails.SelectedNetwork == config.ETH_ID) {
        console.log("binanceeeeeee");

        var tokenid = tokDetails.type == 721 ? Receipt?.logs[3]?.topics[3] : Receipt?.logs[3]?.topics[3];

      } else if (tokDetails.SelectedNetwork == config.CAM_ID) {
        // console.log("sfvfjnlkmkls", tokDetails.SelectedNetwork);
        var tokenid = tokDetails.type == 721 ? Receipt?.logs[2]?.topics[2] : Receipt?.logs[2]?.topics[2];

      }

      const tokenID = Web3Utils.hexToNumber(Number(tokenid));
      // console.log("tokenid from receipt", tokenID, NFT)
      // console.log("gyugduasguisgcshg", NFT, tokDetails);
      var updateData = {
        hashValue: Receipt.transactionHash,
        tokenID: String(tokenID),
        status: Receipt?.status,
        BulkMint: tokDetails.BulkMint == 'false' ? 'true' : 'true',
        LazyStatus: NFT.balance == NoofToken ? "completed" : "pending",

      }

      var NewOwnerDetail1 = { Status: Receipt?.status, Hash: Receipt.transactionHash, ConAddr: NFT.contractAddress, Owns: tokDetails.EventName == "NoEvent" && tokDetails.PublishStatus == false ? NoofToken : 1, NFTDetail: NFT, Action: "Purchase", NewOwner: Wallet_Details.UserAccountAddr, OldOwner: NFT.tokenOwner, coinname: NFT.coinname, tokenName: tokName, Collection: tokDetails.Collection, updateData: updateData, LazyStatus: "completed", SelectedNetwork: tokDetails.SelectedNetwork, QR_tokenID: NFT.QR_tokenID, Bulkmint: tokDetails.Bulkmint, NFTTokens: Datas, ScannerTokenID: Datas.ScannerTokenID, ServiceFee: Wallet_Details.Service_Fee.Buyer, FiatBuy: Data, Fiat: NFT.Fiat, Charity: NFT.Charity, Calculation: Calculation, NFTPrice: Prices.PriceWithoutSerfee / 1e18, NFTPriceWithSerice: Prices.PriceWithSerfee / 1e18 }
      console.log("Comming Inside");
      var Resp = await UpdateNFTOwnersBulk(NewOwnerDetail1)

      if (Resp) {
        $('#buy_closs').trigger("click");
        toast.success("Purchased Sucessfully")
        if (tokDetails.EventName == "NoEvent" && tokDetails.PublishStatus == false) {
          setTimeout(() => {
            history.push(`/my-items/${Wallet_Details.UserAccountAddr}`)
          }, 1000);
        } else {
          $('#Claim_modal').trigger("click");
          var DetailEvent = {
            Address: Wallet_Details.UserAccountAddr,
            EventCreator: NFT.tokenCreator,
            tokenID: String(tokenID),
            QR_tokenID: NFT.QR_tokenID
          }
          dispatch({
            type: Account_Connect,
            Account_Detail: {
              DetailEvent: DetailEvent,
            }
          })
          cookies.set('ClaimerCookie', Wallet_Details.UserAccountAddr, { path: '/', domain: 'lazyminter.com' });
          cookies.set('TokenIDSCookie', String(tokenID), { path: '/', domain: 'lazyminter.com' });
          console.log("asdsadasdas", cookies.get('ClaimerCookie'));
          setTimeout(() => {
            // history.push(`"http://"+${tokDetails.EventName}+"."+"localhost:3000"`)
            history.push(`/my-items/${Wallet_Details.UserAccountAddr}`)
          }, 1000);
        }
      }
      // }
    }
    else {
      toast.error("Try it Again Later.Please Check Your Funds")
    }
    setBuystate(false)



  }

  const ContactHook = async (Arg, type, Wallet_Details, tokDetails, NFT) => {
    console.log("asdasdasdasdas");
    var Receipt = await usePurchaseMint(Arg, type, Wallet_Details, tokDetails, NFT);
    return Receipt
  }
  const ContactHookCharity = async (Arg, type, Wallet_Details, tokDetails, NFT, createrList, OwnerList, CreatorPer, OwnerPer) => {
    var Receipt = await usePurchaseMintCharity(Arg, type, Wallet_Details, tokDetails, NFT, createrList, OwnerList, CreatorPer, OwnerPer);
    return Receipt
  }

  const LMWalletCall = async (Arg, type, Wallet_Details, tokDetails, NFT, NameUse, IntractContact, Contract, data, method, Key, Status, Price, AdAdd) => {
    var receipt = null
    console.log("Comming Inside the LmWallet", Arg, type, Wallet_Details, tokDetails, NFT, NameUse, IntractContact, Contract, data, method, Key);
    var Arg = {
      Method: method,
      Data: data,
      Mail: localStorage.loggedinaddress,
      LoginAddress: localStorage.userAddress,
      ContactAddress: Contract,
      Type: String(type),
      Chain: String(tokDetails.SelectedNetwork),
      IntractContract: IntractContact,
      ContactType: NameUse,
      value: Price,
      Key: Key,
      Status: Status,
      AdAdd: AdAdd
    }
    var Receiptfun = await ContactMint(Arg)

    console.log("ArgReceipt", Receiptfun.data.receipt);
    return Receiptfun.data.receipt
  }

  const LMBalanceCheck = async (Arg, Type, Wallet_Details, IntractContact, NameUse, Contract, method, data, Key, tokenOwner) => {
    console.log("LMBalanceCheck", Arg, Type, Wallet_Details, IntractContact, NameUse, Contract, method, data, Key);
    var Arg = {
      Method: method,
      Data: data,
      Mail: localStorage.loggedinaddress,
      LoginAddress: localStorage.userAddress,
      ContactAddress: Contract,
      Type: String(Type),
      Chain: String(tokDetails.SelectedNetwork),
      IntractContract: IntractContact,
      ContactType: NameUse,
      value: Prices.PriceWithSerfee,
      Key: Key,
      tokenOwner: tokenOwner
    }
    var Balance = await LMBalanceOf(Arg)
    console.log("Balance", Balance);
    return Balance.data.receipt
  }

  const ContactHookPurchaseReceipt = async (Arg, Type, Provider, isToken) => {
    var Receipt = await usePurchaseReceipt(Arg, Type, Provider, isToken)
    return Receipt
  }

  const ContactHookFiatTransfer = async (Arg, Type, Wallet_Details, tokDetails, NFT) => {
    var Receipt = await useFiatTransferReciptReceipt(Arg, Type, Wallet_Details, tokDetails, NFT);
    return Receipt
  }

  const ContactHookFiatTransferAfterBuy = async (Arg, Type, Provider, NFT, tokDetails) => {
    var Receipt = await useTransferReceiptTrade(Arg, Type, Provider, NFT, tokDetails)
    return Receipt
  }

  const ContactHootTicketsFiat = async (Arg, Type, Wallet_Details, tokDetails, NFT) => {
    var Receipt = await usePurchaseTicketsFiat(Arg, Type, Wallet_Details, tokDetails, NFT);
    return Receipt
  }

  const ContactHootPassPort = async (Arg, Type, Provider, tokDetails, NFT, isToken) => {
    var Receipt = await usePassportReceipt(Arg, Type, Provider, tokDetails, NFT, isToken)
    return Receipt
  }

  const ContactHootClaimNfts = async (Arg, Type, Wallet_Details, tokDetails, NFT) => {
    var Receipt = await useClaimNftsReceipt(Arg, Type, Wallet_Details, tokDetails, NFT);
    return Receipt
  }

  const ContactHootBulkMint = async (Arg, Type, Wallet_Details, tokDetails, NFT) => {
    var Receipt = await usePurchaseTickets(Arg, Type, Wallet_Details, tokDetails, NFT);
    return Receipt
  }

  return (
    <>
      {/* buy Modal */}
      <div
        className="modal fade primary_modal"
        id="buy_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="buy_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >

          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="buy_modalLabel">
                Checkout
              </h5>
              <button
                id="buy_closs"
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-0">
              {/* {console.log("price fee condition", Prices.PriceWithSerfee)} */}
              {!(Prices.PriceWithSerfee) ?
                <><TailSpin wrapperClass="reactloader mt-0 mb-3" color="#00BFFF" height={100} width={70} /></>
                :
                <>
                  {!Fiat ?
                    <>
                      <div className="row mx-0">
                        <div className="col-12 col-md-6 px-4">
                          <p className="buy_title_sm">Seller</p>
                          <p className="buy_title_md">{(NFT.CurrentOwner.name) ? NFT?.CurrentOwner?.name?.slice(0, 10) : (NFT.CurrentOwner.curraddress).slice(0, 10)}</p>
                        </div>
                        <div className="col-12 col-md-6 px-4">
                          <p className="buy_title_sm text-md-right">Buyer</p>
                          <p className="buy_title_md text-md-right">{(Wallet_Details.UserAccountAddr).slice(0, 10)}</p>
                        </div>
                      </div>
                      {/* {console.log("sdfsdfsdfsdfsd", NFT)} */}
                      <div className="">
                        <p className="buy_title_md px-4 py-3 text-center mb-0">
                          {Prices.Youwillpay} {NFT.coinname}
                        </p>
                      </div>
                      {(NFT.type === 1155) &&
                        <div className="mx-3">
                          <label htmlFor="qty" className="buy_desc_sm">
                            Enter quantity{" "}
                            <span className="label_muted pl-2">({NFT.balance} available)</span>
                          </label>
                          <div className="mb-3 input_grp_style_1">
                            <input
                              type="text"
                              id="qty"
                              className="form-control"
                              placeholder="1"
                              value={NoofToken}
                              onChange={(e) => { selectChange(e.target.value) }}
                            />
                          </div>
                        </div>
                      }

                      {/* {console.log("zdafasdafzdczc", NFT)} */}
                      <div className="row mx-0 pb-3">
                        <div className="col-12 col-sm-6 px-4">
                          <p className="buy_desc_sm">Your balance</p>
                        </div>
                        <div className="col-12 col-sm-6 px-4 text-sm-right">
                          <p className="buy_desc_sm_bold">{(Wallet_Details.UserAccountBal).toFixed(5)} {NFT.coinname}</p>
                        </div>
                      </div>
                      <div className="row mx-0 pb-3">
                        <div className="col-12 col-sm-6 px-4">
                          <p className="buy_desc_sm">Service fee</p>
                        </div>
                        <div className="col-12 col-sm-6 px-4 text-sm-right">
                          <p className="buy_desc_sm_bold">{Wallet_Details.Service_Fee.Buyer}%</p>
                        </div>
                      </div>
                      <div className="row mx-0 pb-3">
                        <div className="col-12 col-sm-6 px-4">
                          <p className="buy_desc_sm">Price of the NFT</p>
                        </div>
                        <div className="col-12 col-sm-6 px-4 text-sm-right">
                          <p className="buy_desc_sm_bold">{NFT.tokenPrice} {config.currencySymbol}</p>
                        </div>
                      </div>
                      <form className="px-4">
                        <div className="text-center">
                          <Button
                            className="d-none"
                            id="hiddenbutton"
                            data-dismiss="modal"
                            aria-label="Close"
                            data-toggle="modal"
                            data-target="#proceed_modal"
                          >
                            {"Proceed to Payment"}
                          </Button>
                          <Button
                            className="create_btn btn-block"
                            onClick={() => ValidCheck()}
                          >
                            Proceed to Payment
                          </Button>
                          <Button
                            className="btn_outline_red btn-block"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            Cancel
                          </Button>
                        </div>
                      </form>
                    </>
                    :
                    <>
                      <div className="row mx-0">
                        <div className="col-12 col-md-6 px-4">
                          <p className="buy_title_sm">Seller</p>
                          <p className="buy_title_md">{(NFT.CurrentOwner.name) ? NFT?.CurrentOwner?.name?.slice(0, 10) : (NFT.CurrentOwner.curraddress).slice(0, 10)}</p>
                        </div>
                        <div className="col-12 col-md-6 px-4">
                          <p className="buy_title_sm text-md-right">Buyer</p>
                          <p className="buy_title_md text-md-right">{(Wallet_Details.UserAccountAddr).slice(0, 10)}</p>
                        </div>
                      </div>
                      {/* {console.log("sdfsdfsdfsdfsd", NFT)} */}
                      <div className="">
                        <p className="buy_title_md px-4 py-3 text-center mb-0">
                          {Math.round((Prices.Youwillpay + Number.EPSILON) * 100) / 100} {NFT.coinname}
                        </p>
                      </div>
                      {(NFT.type === 1155) &&
                        <div className="mx-3">
                          <label htmlFor="qty" className="buy_desc_sm">
                            Enter quantity{" "}
                            <span className="label_muted pl-2">({NFT.balance} available)</span>
                          </label>
                          <div className="mb-3 input_grp_style_1">
                            <input
                              type="text"
                              id="qty"
                              className="form-control"
                              placeholder="1"
                              value={NoofToken}
                              onChange={(e) => { selectChange(e.target.value) }}
                            />
                          </div>
                        </div>
                      }

                      {/* {console.log("zdafasdafzdczc", NFT)} */}
                      <div className="row mx-0 pb-3">
                        <div className="col-12 col-sm-6 px-4">
                          <p className="buy_desc_sm">Service fee</p>
                        </div>
                        <div className="col-12 col-sm-6 px-4 text-sm-right">
                          <p className="buy_desc_sm_bold">{Wallet_Details.Service_Fee.Buyer}%</p>
                        </div>
                      </div>
                      <div className="row mx-0 pb-3">
                        <div className="col-12 col-sm-6 px-4">
                          <p className="buy_desc_sm">Price of the NFT</p>
                        </div>
                        <div className="col-12 col-sm-6 px-4 text-sm-right">
                          <p className="buy_desc_sm_bold">{NFT.tokenPrice} {config.currencySymbol}</p>
                        </div>
                      </div>
                      <form className="px-4">
                        {console.log("anxjchdsfask", Math.round((Prices.Youwillpay + Number.EPSILON) * 100) / 100, Prices)}
                        <PayPalScriptProvider options={{
                          "client-id": config.PayPalKey
                        }}>
                          {/* {console.log("iooioiwq", config.PayPalKey, UpfeePayPal)} */}
                          <PayPalButtons
                            style={{ layout: "vertical" }}
                            disabled={App_Btn == "Done" ? true : false}
                            forceReRender={[Prices]}

                            createOrder={(data, actions) => {
                              SetApp_Btn("process")
                              return actions.order
                                .create({
                                  purchase_units: [
                                    {
                                      amount: {
                                        currency_code: "USD",
                                        value: Math.round((Prices.Youwillpay + Number.EPSILON) * 100) / 100

                                        //Prices.Youwillpay,
                                      },
                                    },
                                  ],
                                })
                                .then((orderId) => {
                                  console.log("qewweqweqwqe", orderId);
                                  return orderId;
                                });
                            }}
                            onApprove={(data, actions) => {
                              return actions.order.capture().then((details) => {
                                console.log("asdasdasdas", details);
                                const Data = {
                                  Buyername: details.payer.name.given_name,
                                  Surname: details.payer.name.surname,
                                  id: details.purchase_units[0].payments.captures[0].id,
                                  TranscationId: details.id,
                                  email_address: details.payer.email_address,
                                  payer_id: details.payer.payer_id,
                                  status: details.status,
                                  Address: details.payer.address.country_code
                                }
                                SetApp_Btn("Done")
                                SetTransCation(Data)
                                console.log("qqweweoioiio", Data.Buyername);
                                // if(Data.TranscationId !== null || undefined){
                                console.log("NFT.SaleStatus", NFT.SaleStatus, tokDetails.Bulkmint, NFT.hashValue);
                                if ((NFT.SaleStatus == "Create") && (tokDetails.Bulkmint == "NFT") && (NFT.hashValue == "not yet minted")) {
                                  FiatPurchase(Data)
                                } else if ((NFT.SaleStatus == "Resale") && (tokDetails.Bulkmint == "NFT") && (NFT.hashValue !== "not yet minted")) {
                                  TransferCall(Data)
                                } else if ((NFT.SaleStatus == "Create") && (tokDetails.Bulkmint == "NFT") && (NFT.hashValue !== "not yet minted")) {
                                  TransferCall(Data)
                                } else if (tokDetails.Bulkmint !== "NFT") {
                                  console.log("Comming inside the Bulkmint");
                                  ImageMergeForFiat(Data)
                                }
                                // }
                                // toast.success("Payment Successfully Done", { autoClose: 1000 });

                              });
                            }}
                            onError={(err) => {
                              console.log("qweqeqwe", err);
                              SetApp_Btn("Try Again")
                              toast.error(err, { autoClose: 1000 });
                            }}

                          />
                        </PayPalScriptProvider>
                      </form>
                    </>
                  }
                </>
              }
            </div>
          </div>
        </div>
      </div>
      {/* end buy modal */}

      {/* proceed Modal */}
      <div
        className="modal fade primary_modal"
        id="proceed_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="proceed_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="proceed_modalLabel">
                Follow Steps
              </h5>
              <button
                id="buy_closs"
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                {false &&
                  <>
                    <p className="mt-0 purchase_text text-center">Approve</p>
                    <p className="mt-0 purchase_desc text-center">
                      Approve transaction with your wallet
                    </p>

                    <div className="text-center my-3">
                      <Button className="btn_outline_red btn-block">
                        Inprogress
                      </Button>
                    </div>
                  </>
                }
                <p className="mt-0 purchase_text text-center">Purchase</p>
                <p className="mt-0 purchase_desc text-center">
                  Send transaction with your wallet
                </p>

                <div className="text-center my-3">
                  {/* <Button className="create_btn btn-block" disabled={Buystate} onClick={()=>{BalCheck()}}> */}

                  {/* Existing functionalitt */}
                  {/* <Button className="create_btn btn-block" disabled={Buystate} onClick={()=>{BalCheck()}}> */}
                  {tokDetails.Bulkmint == 'Bulk' &&
                    <Button className="create_btn btn-block" disabled={SignTxt == "progress" || SignTxt == "done" ? true : false} onClick={() => { signcall() }}>


                      {SignTxt == "progress" && (
                        <i
                          class="fa fa-spinner mr-3 spinner_icon"
                          aria-hidden="true"
                        ></i>
                      )}{(SignTxt == "done") ? "done" : "sign"}
                    </Button>
                  }

                  {/* Newly Added */}
                  {console.log("qweqweqweqpopopopopo", Buystate, SignTxt)}
                  <Button className="create_btn btn-block" disabled={SignTxt == "progress" ? true : false} onClick={() => { PurchaseMint() }}>


                    {Buystate && (
                      <i
                        class="fa fa-spinner mr-3 spinner_icon"
                        aria-hidden="true"
                      ></i>
                    )}{(Buystate) ? "Inprogress" : "Proceed"}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end proceed modal */}


      {/* Claim Model */}
      <div
        className="modal fade primary_modal"
        id="Claim_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="proceed_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="proceed_modalLabel">
                Follow Steps
              </h5>
              <button
                id="buy_closs"
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>

                <p className="mt-0 purchase_text text-center">Claim NFT</p>
                <p className="mt-0 purchase_desc text-center">
                  Send transaction with your wallet
                </p>

                <div className="text-center my-3">
                  {/* <Button className="create_btn btn-block" disabled={Buystate} onClick={()=>{BalCheck()}}> */}

                  {/* Existing functionalitt */}
                  {/* <Button className="create_btn btn-block" disabled={Buystate} onClick={()=>{BalCheck()}}> */}
                  {/* {tokDetails.Bulkmint == 'Bulk' &&
                    <Button className="create_btn btn-block" disabled={SignTxt == "progress" || SignTxt == "done" ? true : false} onClick={() => { signcall() }}>


                      {SignTxt == "progress" && (
                        <i
                          class="fa fa-spinner mr-3 spinner_icon"
                          aria-hidden="true"
                        ></i>
                      )}{(SignTxt == "done") ? "done" : "sign"}
                    </Button>
                  } */}

                  {/* Newly Added */}
                  {console.log("qweqweqweqpopopopopo", Buystate, SignTxt)}
                  <Button className="create_btn btn-block" disabled={SignTxt == "progress" ? true : false} onClick={() => { PurchaseMint() }}>


                    {Buystate && (
                      <i
                        class="fa fa-spinner mr-3 spinner_icon"
                        aria-hidden="true"
                      ></i>
                    )}{(Buystate) ? "Inprogress" : "Proceed"}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>

  )
})