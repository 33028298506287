import React, { useEffect, useState } from "react";


import Tickets1 from "../../assets/images/lazymintingnew/tickets.jpg";
import user from "../../assets/images/lazymintingnew/user.png"
import Polygon from "../../assets/images/lazymintingnew/polygon-matic-icon.svg"
import polygon_violet_lighttheme from '../../assets/images/lazymintingnew/polygon_violet_lighttheme.png'
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import ETH from "../../assets/images/lazymintingnew/eth_logo.png"
import {
    HomeCategory
} from '../../actions/v1/token';
import config from "lib/config";

export default function Ticketcard(props) {



    var { item } = props
    console.log("itemitemitemmmm", props.item);
    var location = useLocation();
    // console.log("location", location.pathname, location)
    const Wallet_Details = useSelector((state) => state.wallet_connect_context);
    const [HomeCategoryData, SetHomeCategory] = React.useState({})
    useEffect(() => {
        HomeCategoryList();

    }, [])

    const HomeCategoryList = async () => {
        var HomeCategoryData = await HomeCategory()
        // console.log("HomeCategory",HomeCategoryData.data.data.HomeCategory);
        var FinalData = HomeCategoryData?.data?.data?.HomeCategory
        let dataAArray = FinalData.filter(items => (items.name == "Ticket"));
        // console.log("testeddatat for dataAArray", dataAArray);
        // console.log("For Ticket card", FinalData);
        SetHomeCategory(FinalData)
    }


    return (


        <>
            {
                (location.pathname != "/home") &&
                <>
                    {props.item.tokenCategory !== "Web3 IDs" ?
                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 col-12">
                            <div className="style_prevu_ki1t yellowheight mb-4" id="ticketscards">

                                <div className="trendingban trendingbans">

                                    <Link to={`/info/${props.item.contractAddress}/${props?.item?.tokenOwnerInfo?.curraddress}/${props.item.tokenID}`}>

                                        {
                                            props &&
                                            props.item.additionalImage &&
                                            (props.item.additionalImage.split(".").pop() == "mp4" ? (
                                                <video
                                                    id="my-video"
                                                    className="img-fluid"
                                                    autoPlay
                                                    playsInline
                                                    loop
                                                    muted
                                                    preload="auto"
                                                    controls disablePictureInPicture controlsList="nodownload"
                                                >
                                                    <source src={props.item.additionalImage !== "" ? `${config.Back_URL}/nftImg/${props.item.tokenCreator}/${props.item.additionalImage}` : `${config.IPFS_IMG}/${props.item.ipfsimage}`} type="video/mp4" />
                                                </video>

                                            ) : (
                                                <img
                                                    src={props.item.additionalImage !== "" ? `${config.Back_URL}/nftImg/${props.item.tokenCreator}/${props.item.additionalImage}` : `${config.IPFS_IMG}/${props.item.ipfsimage}`}
                                                    alt="Collections"
                                                    className="img-fluid"
                                                />

                                            ))}
                                    </Link>
                                </div>
                                <div className="content">
                                    <div className="header">
                                        <div className="left-main">
                                            <label className="market-label">Limited Edition</label>
                                            <h1 className="titlegenereal">{props?.item?.tokenName?.slice(0, 10)}</h1>
                                            <div className="brand">
                                                {
                                                    props.item.tokenOwnerInfo.image === "" &&
                                                    <Link to={`/my-items/${props.item.tokenowners_current.tokenOwner}`}>
                                                        <span> <img src={user} className="profilebamds" /></span>
                                                    </Link>
                                                }
                                                {
                                                    props.item.tokenOwnerInfo.image !== "" &&
                                                    <Link to={`/my-items/${props.item.tokenowners_current.tokenOwner}`}>
                                                        <img src={`${config.Back_URL}/Users/${props.item.tokenOwnerInfo.PrimaryAddr}/profileImg/${props.item.tokenOwnerInfo.image}`} className="profilebamds" />
                                                    </Link>
                                                }
                                                <Link to={`/my-items/${props.item.tokenowners_current.tokenOwner}`}>
                                                    <p>{props.item.tokenOwnerInfo.name == '' ? props?.item?.tokenOwnerInfo?.curraddress?.slice(0, 10)
                                                        :
                                                        props?.item?.tokenOwnerInfo?.name?.slice(0, 15)}</p>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="time-stamp-container">
                                            <div className="left">
                                                <div className="left__date-month-wrapper">
                                                    <div className="left__date-month-wrapper-inner">
                                                        {/* <label>Nov</label> */}
                                                        <h2>{props.item.startDateToken}-{props.item.endDateToken}</h2>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="right">
                                                <div className="left__date-month-wrapper">
                                                    <div className="left__date-month-wrapper-inner">
                                                        <label>Section</label>
                                                        <h2>{props.item.Section.slice(0, 3)}</h2>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line"></div>
                                    <div className="price-container">
                                        <div className="left w-50">

                                            <div className="bottom">
                                                <span className="primary-price">
                                                    <span>
                                                        {props.item.tokenowners_current.tokenPrice == '' ? '' : `Price : ${props.item.tokenowners_current.tokenPrice}`} {props.item.tokenowners_current.tokenPrice == '' || props.item.tokenowners_current.tokenPrice == null ? "" : props.item.tokenowners_current.coinname}
                                                    </span>
                                                </span>
                                                <br />
                                                <span className="secondary-price">
                                                    {console.log("dasdasdasdasdasd11111", props.item.tokenowners_current.balance)}
                                                    <span>
                                                        In Stock :  {props.item.tokenowners_current.balance}
                                                    </span>

                                                </span>
                                            </div>
                                        </div>
                                        <div className="right w-50">
                                            <div className="top">
                                                Location
                                            </div>
                                            <div className="bottom loc">
                                                <span className="primary-price">
                                                    <span>
                                                        {props.item.Location.slice(0, 80)}
                                                    </span>
                                                </span>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-button-container">
                                        <div className="icon">
                                            {props.item.tokenowners_current.coinname == "ETH" ? <img src={ETH} width={35} height={35} className="img-fluid" />
                                                :
                                                <img src={polygon_violet_lighttheme} width={35} height={35} className="img-fluid" />
                                            }

                                        </div>
                                        <div className="button">
                                            <Link to={`/info/${props.item.contractAddress}/${props?.item?.tokenOwnerInfo?.curraddress}/${props.item.tokenID}`}>

                                                {
                                                    (props?.item?.tokenOwnerInfo?.curraddress === Wallet_Details.UserAccountAddr) ?
                                                        <button>You Own This</button>
                                                        :
                                                        <button>Buy Now</button>
                                                }
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 col-12">
                            <div className="style_prevu_ki1t yellowheight mb-4" id="ticketscards">

                                <div className="trendingban trendingbans">

                                    <Link to={`/info/${props.item.contractAddress}/${props?.item?.tokenOwnerInfo?.curraddress}/${props.item.tokenID}`}>

                                        {
                                            props &&
                                            props.item.additionalImage &&
                                            (props.item.additionalImage.split(".").pop() == "mp4" ? (
                                                <video
                                                    id="my-video"
                                                    className="img-fluid"
                                                    autoPlay
                                                    playsInline
                                                    loop
                                                    muted
                                                    preload="auto"
                                                    controls disablePictureInPicture controlsList="nodownload"
                                                >
                                                    <source src={props.item.image !== "" ? `${config.Back_URL}/nftImg/${props.item.tokenCreator}/${props.item.image}` : `${config.IPFS_IMG}/${props.item.ipfsimage}`} type="video/mp4" />
                                                </video>

                                            ) : (
                                                <img
                                                    src={props.item.image !== "" ? `${config.Back_URL}/nftImg/${props.item.tokenCreator}/${props.item.image}` : `${config.IPFS_IMG}/${props.item.ipfsimage}`}
                                                    alt="Collections"
                                                    className="img-fluid"
                                                />

                                            ))}
                                    </Link>
                                </div>
                                <div className="content">
                                    <div className="header">
                                        <div className="left-main">
                                            <label className="market-label">Limited Edition</label>
                                            <h1 className="titlegenereal">{props?.item?.tokenName?.slice(0, 10)}</h1>
                                            <div className="brand">
                                                {
                                                    props.item.tokenOwnerInfo.image === "" &&
                                                    <Link to={`/my-items/${props.item.tokenowners_current.tokenOwner}`}>
                                                        <span> <img src={user} className="profilebamds" /></span>
                                                    </Link>
                                                }
                                                {
                                                    props.item.tokenOwnerInfo.image !== "" &&
                                                    <Link to={`/my-items/${props.item.tokenowners_current.tokenOwner}`}>
                                                        <img src={`${config.Back_URL}/Users/${props.item.tokenOwnerInfo.PrimaryAddr}/profileImg/${props.item.tokenOwnerInfo.image}`} className="profilebamds" />
                                                    </Link>
                                                }
                                                <Link to={`/my-items/${props.item.tokenowners_current.tokenOwner}`}>
                                                    <p>{props.item.tokenOwnerInfo.name == '' ? props?.item?.tokenOwnerInfo?.curraddress?.slice(0, 10)
                                                        :
                                                        props?.item?.tokenOwnerInfo?.name?.slice(0, 15)}</p>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="time-stamp-container">
                                            <div className="left">
                                                <div className="left__date-month-wrapper">
                                                    <div className="left__date-month-wrapper-inner">
                                                        {/* <label>Nov</label> */}
                                                        <h2>{props.item.ID.slice(0,8)}</h2>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="right">
                                                <div className="left__date-month-wrapper">
                                                    <div className="left__date-month-wrapper-inner">
                                                        <label>Section</label>
                                                        {/* <h2>{props.item.ID.slice(0,8)}</h2> */}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line"></div>
                                    <div className="price-container">
                                        <div className="left w-50">

                                            <div className="bottom">
                                                {/* <span className="primary-price">
                                                    <span>
                                                        {props.item.tokenowners_current.tokenPrice == '' ? '' : `Price : ${props.item.tokenowners_current.tokenPrice}`} {props.item.tokenowners_current.tokenPrice == '' || props.item.tokenowners_current.tokenPrice == null ? "" : props.item.tokenowners_current.coinname}
                                                    </span>
                                                </span> */}
                                                <br />
                                                <span className="secondary-price">
                                                    {console.log("dasdasdasdasdasd11111", props.item.tokenowners_current.balance)}
                                                    <span>
                                                        In Stock :  {props.item.tokenowners_current.balance}
                                                    </span>

                                                </span>
                                            </div>
                                        </div>
                                        <div className="right w-50">
                                            <div className="top">
                                                Discription
                                            </div>
                                            <div className="bottom loc">
                                                <span className="primary-price">
                                                    <span>
                                                        {props.item.tokenDesc.slice(0, 80)}
                                                    </span>
                                                </span>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-button-container">
                                        <div className="icon">
                                            {props.item.tokenowners_current.coinname == "ETH" ? <img src={ETH} width={35} height={35} className="img-fluid" />
                                                :
                                                <img src={polygon_violet_lighttheme} width={35} height={35} className="img-fluid" />
                                            }

                                        </div>
                                        <div className="button">
                                            <Link to={`/info/${props.item.contractAddress}/${props?.item?.tokenOwnerInfo?.curraddress}/${props.item.tokenID}`}>

                                                {
                                                    (props?.item?.tokenOwnerInfo?.curraddress === Wallet_Details.UserAccountAddr) ?
                                                        <button>{props.item.tokenCategory}</button>
                                                        :
                                                        <button>{props.item.tokenCategory}</button>
                                                }
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </>
            }
            {
                (location.pathname == "/home") &&
                <>

                    <div className="style_prevu_ki1t yellowheight card_details_alignable" id="ticketscards">

                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 px-0">

                            {console.log("qweqwzxcxzcxczcx", props.item)}
                            <div className="trendingban trendingbans  trendingbans_bgcolor">

                                <Link to={`/info/${props.item.contractAddress}/${props?.item?.tokenOwnerInfo?.curraddress}/${props.item.tokenID}`}>

                                    {
                                        props &&
                                        props.item.additionalImage &&
                                        (props.item.additionalImage.split(".").pop() == "mp4" ? (
                                            <video
                                                id="my-video"
                                                className="img-fluid"
                                                autoPlay
                                                playsInline
                                                loop
                                                muted
                                                preload="auto"
                                                controls disablePictureInPicture controlsList="nodownload"
                                            >
                                                <source src={props.item.additionalImage !== "" ? `${config.Back_URL}/nftImg/${props.item.tokenCreator}/${props.item.additionalImage}` : `${config.IPFS_IMG}/${props.item.ipfsimage}`} type="video/mp4" />
                                            </video>

                                        ) : (
                                            <img
                                                src={props.item.additionalImage !== "" ? `${config.Back_URL}/nftImg/${props.item.tokenCreator}/${props.item.additionalImage}` : `${config.IPFS_IMG}/${props.item.ipfsimage}`}
                                                alt="Collections"
                                                className="img-fluid"
                                            />

                                        ))}
                                </Link>
                            </div>
                            <div className="content">
                                <div className="header">
                                    <div className="left-main">
                                        <label className="market-label">Limited Edition</label>
                                        <h1 className="titlegenereal">{props?.item?.tokenName?.slice(0, 10)}</h1>
                                        <div className="brand">
                                            {
                                                props.item.tokenOwnerInfo.image === "" &&
                                                <Link to={`/my-items/${props.item.tokenowners_current.tokenOwner}`}>
                                                    <span> <img src={user} className="profilebamds" /></span>
                                                </Link>
                                            }
                                            {
                                                props.item.tokenOwnerInfo.image !== "" &&
                                                <Link to={`/my-items/${props.item.tokenowners_current.tokenOwner}`}>
                                                    <img src={`${config.Back_URL}/Users/${props.item.tokenOwnerInfo.PrimaryAddr}/profileImg/${props.item.tokenOwnerInfo.image}`} className="profilebamds" />
                                                </Link>
                                            }
                                            <Link to={`/my-items/${props.item.tokenowners_current.tokenOwner}`}>
                                                <p>{props.item.tokenOwnerInfo.name == '' ? props?.item?.tokenOwnerInfo?.curraddress?.slice(0, 10)
                                                    :
                                                    props?.item?.tokenOwnerInfo?.name?.slice(0, 15)}</p>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="time-stamp-container">
                                        <div className="left">
                                            <div className="left__date-month-wrapper">
                                                <div className="left__date-month-wrapper-inner">
                                                    {/* <label>Nov</label> */}
                                                    <h2>{props.item.startDateToken}-{props.item.endDateToken}</h2>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="right">
                                            <div className="left__date-month-wrapper">
                                                <div className="left__date-month-wrapper-inner">
                                                    <label>Section</label>
                                                    <h2>{props.item.Section.slice(0, 3)}</h2>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="line"></div>
                                <div className="price-container">
                                    <div className="left w-50">

                                        <div className="bottom">
                                            <span className="primary-price">
                                                <span>
                                                    {props.item.tokenowners_current.tokenPrice == '' ? '' : `Price : ${props.item.tokenowners_current.tokenPrice}`} {props.item.tokenowners_current.tokenPrice == '' || props.item.tokenowners_current.tokenPrice == null ? "" : props.item.tokenowners_current.coinname}
                                                </span>
                                            </span>
                                            <br />
                                            <span className="secondary-price">
                                                {console.log("dasdasdasdasdasd", props.item.tokenowners_current.balance)}
                                                <span>
                                                    In Stock :  {props.item.tokenowners_current.balance}
                                                </span>

                                            </span>
                                        </div>
                                    </div>
                                    <div className="right w-50">
                                        <div className="top">
                                            Location
                                        </div>
                                        <div className="bottom loc">
                                            <span className="primary-price">
                                                <span>
                                                    {props.item.Location.slice(0, 80)}
                                                </span>
                                            </span>

                                        </div>
                                    </div>
                                </div>
                                <div className="card-button-container">
                                    <div className="icon">
                                        {props.item.tokenowners_current.coinname == "ETH" ? <img src={ETH} width={35} height={35} className="img-fluid" />
                                            :
                                            <img src={polygon_violet_lighttheme} width={35} height={35} className="img-fluid" />
                                        }

                                    </div>
                                    <div className="button">
                                        <Link to={`/info/${props.item.contractAddress}/${props?.item?.tokenOwnerInfo?.curraddress}/${props.item.tokenID}`}>

                                            {
                                                (props?.item?.tokenOwnerInfo?.curraddress === Wallet_Details.UserAccountAddr) ?
                                                    <button>You Own This</button>
                                                    :
                                                    <button>Buy Now</button>
                                            }
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </>
            }

        </>

    );
}
