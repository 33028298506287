import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
// core components
import $ from 'jquery'
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styled from "styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link, useParams } from "react-router-dom";
import { MyItemSoulBoundOwn, VaultApi, SendMailNftsVault } from "actions/v1/token";
import { ExplorePage, GrantUserSendMail } from "actions/v1/token";
import { useSelector, useDispatch } from "react-redux";
import { usePrevious } from "hooks/useMethods";
import { TailSpin } from "react-loader-spinner";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import VaultCollCard from "./separate/VaultCollCard"
import VaultCard from "./separate/VaultCard";
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';

const Icon = styled((props) => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #3d2524;
    font-size: 24px;
  }
  & > .minus {
    display: none;
    color: #3d2524;
    font-size: 24px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Explore(props) {

  const [emails, setEmails] = useState([]);
  const [focused, setFocused] = useState(false);


  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const classes = useStyles();
  const { ...rest } = props;
  const { cat } = useParams()
  const dispatch = useDispatch();
  const [ExploreCardNFTs, setExploreCardNFTs] = useState({ 'loader': false, 'All': { page: 1, list: [], onmore: true, Vault: "Hide", VaultStatus: "Vault" } });
  const [ExploreViralNFTs, setExploreViralNFTs] = useState([]);
  const [CatName, setCatName] = useState("All")
  const prevCat = usePrevious(ExploreCardNFTs)
  const [ShowModal, setShowModal] = useState(false)
  const [ShowLoader, setShowLoader] = useState(false)
  const [categorysate, Setcategorysate] = useState({})
  const [theme, setTheme] = useState("#000");
  const [SoulListOwn, SetSoulListOwn] = useState({});
  useEffect(() => {
    if (document.getElementById("root").classList.contains("light_theme")) {
      setTheme("#FFF")
    }
    else {
      setTheme("#000")
    }
  }, []);
  const customStyles = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      // boxShadow: '0 27px 24px 0 rgb(0 0 0 / 20%), 0 40px 77px 0 rgb(0 0 0 / 22%)',
      borderRadius: '30px',
      border: 'none !important',
    },
    overlay: {
      backgroundColor: theme,
      zIndex: 2
    },
  };

  useEffect(() => {
    setShowLoader(true)
    ExploreCardNFT()
  }, [CatName, Wallet_Details.UserAccountAddr])

  useEffect(() => {
    setShowModal(true)
    ExploreViralNFT()
  }, [Wallet_Details.UserAccountAddr])

  useEffect(() => {
    catChange(cat)
  }, [cat])

  const ExploreViralNFT = async () => {
    let Payload = { curraddress: Wallet_Details.UserAccountAddr, Category: "", Page: 1, limit: 5, Card: "viral", Vault: "Hide", VaultStatus: "Vault" }
    let NFT = await ExplorePage(Payload);
    if (NFT.data.Success) {
      if (NFT.data.records.length > 0) {
        setExploreViralNFTs([])
        setExploreViralNFTs(NFT.data.records)
      }
      setTimeout(() => {
        setShowModal(false)
      }, 1000);
    }
  }

  const ExploreCardNFT = async (Catpage) => {

    let Payload = { curraddress: Wallet_Details.UserAccountAddr, Category: CatName, Page: Catpage || 1, limit: 16, Vault: "Hide", VaultStatus: "Vault" }
    let NFT = await ExplorePage(Payload);
    if (NFT?.data?.Success) {
      if (NFT?.data?.records?.length > 0) {
        if (((ExploreCardNFTs[CatName].list.length !== 0) && (CatName === NFT?.data?.cat) && ((Catpage ? Catpage : ExploreCardNFTs[CatName].page) !== ExploreCardNFTs[CatName].page))) {
          ExploreCardNFTs[CatName].onmore = true
          ExploreCardNFTs[CatName].page = NFT?.data?.page
          ExploreCardNFTs[CatName].list = ExploreCardNFTs[CatName].list.concat(NFT?.data?.records)
          setExploreCardNFTs([])
          setExploreCardNFTs(ExploreCardNFTs);
        }
        else if (ExploreCardNFTs[CatName].list.length === 0) {
          ExploreCardNFTs[CatName].onmore = true
          ExploreCardNFTs[CatName].list = NFT?.data?.records
          setExploreCardNFTs([])
          setExploreCardNFTs(ExploreCardNFTs);
        }
      }
      if (NFT.data.records.length === 0) {
        ExploreCardNFTs[CatName].onmore = false
        setExploreCardNFTs([])
        setExploreCardNFTs(ExploreCardNFTs)
      }
      setTimeout(() => {
        setShowLoader(false)
      }, 1000);
    }
    else {
      toast.error("Oops..! Error Detected Refresh Page")
    }
  };

  const catChange = (Cat) => {
    if (Cat !== "SoulBound") {
      if (!ExploreCardNFTs[Cat]) {
        ExploreCardNFTs[Cat] = { page: 1, list: [], onmore: true };
        setExploreCardNFTs(ExploreCardNFTs);
      }
      setCatName(Cat)
    } else {
      SoulBoundOwnerFun(Wallet_Details.UserAccountAddr)
      setCatName(Cat)
    }
  }

  const SoulBoundOwnerFun = async (data) => {
    let reqData = {
      Address: data ? Wallet_Details.UserAccountAddr : data,
      VaultStatus: "Hide"
    }
    let collectiongal = await MyItemSoulBoundOwn(reqData);
    SetSoulListOwn(collectiongal.data.data.ListingCollection)
  }

  const LoadMore = () => {
    let Catpage = ExploreCardNFTs[CatName].page + 1
    ExploreCardNFT(Catpage)
  }

  const BrowseMaketPlace = () => {
    return (
      <div className="text-center py-5">
        <p className="not_found_text">No items found</p>
        <p className="not_found_text_sub">
          Come back soon! Or try to browse something for you on our marketplace
        </p>
        <div className="mt-3">
          <Button className="browsematdk">Browse Marketplace</Button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (Wallet_Details?.Category) {
      let Categorydata = Wallet_Details?.Category.filter(Cat => (Cat.label != 'Ticket'))
      if (Categorydata) {
        Setcategorysate(Categorydata)
      }
    }
  }, [Wallet_Details?.Category]);


  const Reloadble = () => {
    return (
      <>
        {

          ExploreCardNFTs[CatName] &&
          ExploreCardNFTs[CatName].list &&
          ExploreCardNFTs[CatName].list.length > 0 &&
          ExploreCardNFTs[CatName].list.map((NFT) => {
            return <VaultCard
              Cardclass={
                "item col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3"
              }
              NFTDetails={NFT}
            />
          })}
      </>
    )
  }

  const Ticketss = () => {
    return (
      <>
        {
          ExploreCardNFTs['Ticket'] &&
          ExploreCardNFTs['Ticket'].list &&
          ExploreCardNFTs['Ticket'].list.length > 0 &&
          ExploreCardNFTs['Ticket'].list.map((NFT) => {
            return (
              <VaultCard
                Cardclass={
                  "item col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3"
                }
                NFTDetails={NFT}
              />
            )
          })}
      </>
    )
  };

  const ConfromProcess = async () => {
    let sendData = {
      User: emails.map(v => v.toLowerCase()),
      NFT: Wallet_Details.VaultNFts
    }
    if (Wallet_Details.UserAccountAddr) {
      let VaultDataApi = await GrantUserSendMail(sendData)
      if (VaultDataApi.data.sucess) {
        let SendInMail = await SendMailNftsVault(sendData)
        if (SendInMail.data.sucess) {
          toast.success(VaultDataApi.data.Message)
          $('#hiddenbutton').trigger("click");
          setEmails([])
          ExploreCardNFT()
          dispatch({
            type: Account_Connect,
            Account_Detail: {
              VaultNFts: []
            }
          })

        } else {
          toast.error(SendInMail.data.Message)
        }

      } else {
        toast.error(VaultDataApi.data.Message)
      }
    } else {
      toast.error("Refreash. And try it Again")
    }
  }

  const VaultNFTAction = async () => {
    let Arg = {
      NFT: Wallet_Details.VaultNFts,
      curraddress: Wallet_Details.UserAccountAddr,
      Status: "Show"
    }
    if (Wallet_Details.UserAccountAddr) {
      let VaultDataApi = await VaultApi(Arg)
      if (VaultDataApi.data.sucess) {
        toast.success(VaultDataApi.data.Message)
        ExploreCardNFT()
        dispatch({
          type: Account_Connect,
          Account_Detail: {
            VaultNFts: []
          }
        })
      } else {
        toast.error(VaultDataApi.data.Message)
      }
    } else {
      toast.error("Refreash. And try it Again")
    }
  }

  return (
    <div className="home_header explore">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={
          <Link to="/home">
            <div className="header_logo_align">


            </div>
          </Link>
        }
        leftLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 20,
          color: "white",
        }}
        {...rest}
      />
      <ScrollToTopOnMount />

      <div className={classes.pageHeader + " inner_pageheader inner_page_bg_2"}>
        <div className="explroeapge">
          <div className="container-fluid custom-container">
            <p className="heading_sm_blk p-b-sec heading_marketplace"><i class="fa-solid fa-shield pr-2"></i> Vault</p>
            <div className="vaultend pb-4">
              <Button className="btn browsematdk mt-2  mr-2 mb-2 fontz" onClick={() => {
                VaultNFTAction()
              }} >Move Out of Vault</Button>
              <Button className="btn browsematdk fontz" data-toggle="modal" data-target="#exampleModalLong">Allow User</Button>

            </div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <nav className="masonry_tab_nav pt-0 items_tab_outer">
                  <div
                    className="nav nav-tabs masonry_tab primary_tab explore_tab_full items_tab justify-content-center items_tab_new pb-0 pl-0"
                    id="nav-tab"
                    role="tablist"
                  >
                    {console.log(CatName, "hhh")}
                    <a
                      className={(CatName === 'All') || (CatName === undefined) ? "nav-link active" : "nav-link"}
                      id="all-tab"
                      data-toggle="tab"
                      href="#all"
                      role="tab"
                      aria-controls="onsale"
                      aria-selected="true"
                      onClick={() => catChange('All')}
                    ><div class="tab_head">All</div>
                    </a>
                    {Wallet_Details.Category && Wallet_Details.Category.length > 0 &&
                      Wallet_Details.Category.map((item) => {
                        return (
                          (item.name !== "Ticket" &&
                            <a className={(CatName == item.name) ? "nav-link active" : "nav-link"} onClick={() => catChange(item.name)} data-tabName={item.name} id="all-tab" data-toggle="tab" href="#all" role="tab" aria-controls="all" aria-selected="false"><div class="tab_head">{item.name}</div></a>
                          )

                        )
                      })
                    }
                    <a
                      className={(CatName === 'Ticket') ? "nav-link active" : "nav-link"}
                      id="all-tab"
                      data-toggle="tab"
                      href="#all"
                      role="tab"
                      aria-controls="onsale"
                      aria-selected="true"
                      onClick={() => catChange('Ticket')}
                    ><div class="tab_head">Ticket</div>
                    </a>
                    <a
                      className={(CatName === 'SoulBound') ? "nav-link active" : "nav-link"}
                      id="all-tab"
                      data-toggle="tab"
                      href="#all"
                      role="tab"
                      aria-controls="onsale"
                      aria-selected="true"
                      onClick={() => {
                        catChange('SoulBound')
                        SoulBoundOwnerFun(Wallet_Details.UserAccountAddr);
                      }}
                    ><div class="tab_head">Non-Transferrable Collection</div>
                    </a>
                  </div>
                </nav>
                <div
                  className="tab-content explore_tab_content mt-0 p-t-sec p-b-sec"
                  id="nav-tabContent"
                >
                  <div
                    className="tab-pane fade show active"
                    id="all"
                    role="tabpanel"
                    aria-labelledby="all-tab"
                  >
                    <div className="proposal_panel_overall">
                      {ExploreCardNFTs[CatName] &&
                        ExploreCardNFTs[CatName].list &&
                        ExploreCardNFTs[CatName].list.length === 0 ?
                        <>
                          {
                            ShowLoader ?
                              <div className="text-center py-5">
                                <TailSpin
                                  wrapperClass="searreactloader"
                                  color="#00BFFF"
                                  height={100}
                                  width={70}
                                />
                              </div>
                              :
                              <BrowseMaketPlace />
                          }
                        </>
                        :

                        <>
                          {CatName !== "SoulBound" ?
                            <>
                              {
                                ShowLoader ?
                                  <div className="text-center py-5">
                                    <TailSpin
                                      wrapperClass="searreactloader"
                                      color="#00BFFF"
                                      height={100}
                                      width={70}
                                    />
                                  </div>
                                  :

                                  <div className="row masonry m-0 ma_no_gap card_main_iner tickets ticketesexplorenpage">
                                    {CatName && CatName !== 'Ticket' ?
                                      <Reloadble />
                                      :

                                      <Ticketss />

                                    }

                                  </div>
                              }
                              <div className="col-12 text-center pt-4">
                                {ExploreCardNFTs[CatName] && ExploreCardNFTs[CatName].onmore &&
                                  <button className="create_btn" onClick={() => { LoadMore() }}>Load More</button>
                                }
                              </div>
                            </>
                            :
                            <>

                              <div className="container-fluid custom-container mb-5 mt-5">
                                <div className="tickets_collection">
                                  <div className="row">

                                    {SoulListOwn && SoulListOwn.length > 0 &&
                                      SoulListOwn.map((Item, index) => {
                                        return (
                                          <VaultCollCard
                                            Item={Item} />
                                        )
                                      })}
                                  </div>
                                </div>
                              </div>
                            </>

                          }
                        </>
                      }
                    </div>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>

      <Footer />
      <Modal
        isOpen={ShowModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {theme === "#000" ?
          <div className="modal-body text-center loading_bgcolor">
            <div className="laodingpart">
              <img src={require("../assets/images/lazymintingnew/logo.png")} alt="Profile" className="img-fluid items_profile smal_main_lm" />
            </div>
          </div> :

          <div className="modal-body text-center loading_bgcolor">
            <div className="laodingpart">
              <img src={require("../assets/images/lazymintingnew/logodark.png")} alt="Profile" className="img-fluid items_profile smal_main_lm" />
            </div>
          </div>

        }

      </Modal>

      {/*Allow User Modal */}
      <div
        className="modal fade primary_modal"
        id="exampleModalLong"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="change_price_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <>
              <div>
                <div className="modal-header text-center">
                  <h3>   Allow User</h3>
                  <h6 className="modal-title mb-3" id="change_price_modalLabel">
                    Allow User to See our Personal Nfts
                  </h6>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" id="hiddenbutton">×</span></button>

                  <form>

                    <ReactMultiEmail
                      placeholder='Enter Email'
                      emails={emails}
                      onChange={(_emails) => {
                        setEmails(_emails);
                      }}
                      autoFocus={true}
                      onFocus={() => setFocused(true)}
                      onBlur={() => setFocused(false)}
                      getLabel={(email, index, removeEmail) => {
                        return (
                          <div data-tag key={index}>
                            <div data-tag-item>{email}</div>
                            <span data-tag-handle onClick={() => removeEmail(index)}>
                              ×
                            </span>
                          </div>
                        );
                      }}
                    />
                  </form>

                  <Button className="btn mt-3 mb-3 browsematdk" onClick={() => { ConfromProcess() }} >Confirm</Button>

                </div>

              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  );
}
