
import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Button, TextField } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Account_Connect, Account_disConnect } from "actions/redux/action";
import $ from 'jquery';
import Web3Utils from 'web3-utils'
import Select from "react-select";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { PurchaseCalculation, YouwillGetCalculation } from 'actions/controller/PriceCalculate';
import { useTokenDecimal,usePrevious, useUserTokenBalance, useNFTBalCheckToken, useNFTBalCheck, useBulBalnceCheck } from 'hooks/useMethods';
import { Audio, TailSpin } from 'react-loader-spinner'
import { NightsStay } from '@material-ui/icons';
import { useTokenAppReceiptFract, ReciptCommon, useTokenFractionApprove } from 'hooks/useReceipts';
import { BidAction2DB, UpdateNFTFractions } from 'actions/controller/tokenControl';
import { v1_ConNFTBalCheck, ContactMint, ContactMintFraction, LMBalanceOf, LMBalanceOfFraction } from 'actions/v1/token';
import config from 'lib/config'
import { toast } from 'react-toastify';
import { PopupValidation } from 'actions/controller/validation';
import { network } from "../../views/network"
import { connectWallet, getServiceFees } from '../../hooks/useWallet';
import Web3 from 'web3';
import Abi721 from '../../ABI/721.json'
import Abi1155 from '../../ABI/1155.json'

import randomInteger from 'random-int';

export const BidActions = forwardRef((props, ref) => {
    const Wallet_Details = useSelector((state) => state.wallet_connect_context);
    const [NoofToken, setNoofToken] = useState(1)
    const [TokenPrice, setTokenPrice] = useState(0)
    const [NewTokenDecimal, setNewTokenDecimal] = useState('')
    const [BiddingBalance, setBiddingBalance] = useState('')
    const [Prices, setPrices] = useState({ Youwillpay: 0, PriceWithSerfee: "", ApprovePriceWithSerfee: "", PriceWithoutSerfee: "", Display: "" })
    const [NFT, setNFT] = useState({ CurrentOwner: {}, Mybid: {} })
    const [NFTDet, setNFTDet] = useState({})
    const [Bid, setBid] = useState({})
    const [Bidstate, setBidstate] = useState(false)
    const [Signstate, setSignstate] = useState(true)
    const [BidText, setBidText] = useState("Start")
    const [AccBidstate, setAccBidstate] = useState(false)
    const [AccBidText, setAccBidText] = useState("Accept Bid")
    const [ApprovalCallStatus, setApprovalCallStatus] = React.useState('Approve');
    const [approvecheck, setapprovecheck] = useState(false)
    const prevNoofToken = usePrevious(NoofToken)
    const prevTokenPrice = usePrevious(TokenPrice)
    const [Approve, SetAprrove] = useState("Start");
    const [TokenListsnet, setTokenListsnet ] = useState([])
  const [CusTokenName, setCustomTokenName] = useState("")
  const [CusTokAddress,setCusTokenAddress] = useState("")
    const history = useHistory()
    const dispatch = useDispatch()

    useImperativeHandle(
        ref,
        () => ({
            async PlaceandAcceptBidClick(ClickedNFT, NFT, Bids, status) {
                console.log("asdasdasdasdasd", ClickedNFT);
                var chainid = NFT.SelectedNetwork
                Serfee(Number(chainid));
                var networkConfiguration = {}
                const chainId = await Wallet_Details.Web3Pro.eth.getChainId();
                console.log("fgndfgn", chainid, Wallet_Details.networkConfiguration);
                if (Number(chainid) === network.MATIC.Chainid) {
                    console.log("netter eorrrrkkk maticz", network.MATIC);
                    networkConfiguration = network.MATIC
                }
                else if (Number(chainid) === network.ETH.Chainid) {
                    console.log("netter eorrrrkkk", network.ETH);
                    networkConfiguration = network.ETH
                }
                else if (Number(chainid) === network.CAM.Chainid) {
                    console.log("netter eorrrrkkk", network.CAM);
                    networkConfiguration = network.CAM
                }
                console.log("jdsnbfufnuewrgn", Bids)
                console.log("overall nft , nftdetail,bids", NFT)
                var approvedata = {
                    curradd: Wallet_Details.UserAccountAddr,
                    tradeadd: networkConfiguration.TradeContract
                }

                Wallet_Details.networkConfiguration = networkConfiguration
                var chainid = NFT.SelectedNetwork
                var accountDetailsss = ''
                setBid(Bids)
                setNFTDet(NFT)
                setNFT(ClickedNFT)
                setNoofToken(NFT.balance);
                var chainid = NFT?.SelectedNetwork
                // console.log("Putonsale wallet connect", chainid, Wallet_Details.networkConfiguration);
                var accountDetailsss = ''
                if (Wallet_Details.networkConfiguration.Chainid == NFT?.SelectedNetwork) {
                    // console.log("dknvjksdnkfsnjkfd", Wallet_Details.networkConfiguration.Chainid == NFT?.SelectedNetwork);
                    accountDetailsss = true
                }
                else {
                    // console.log("it comming in else");
                    console.log("it comming in else switch", networkConfiguration);
                    console.log("sdfsfdfsdfsdfs", Number(chainid), network.MATIC.Chainid);
                    if (Number(chainid) === network.MATIC.Chainid) {
                        console.log("netter eorrrrkkk maticz", network.MATIC);
                        networkConfiguration = network.MATIC
                    }
                    else if (Number(chainid) === network.ETH.Chainid) {
                        console.log("netter eorrrrkkk", network.ETH);
                        networkConfiguration = network.ETH
                    }
                    else if (Number(chainid) === network.CAM.Chainid) {
                        console.log("netter eorrrrkkk", network.CAM);
                        networkConfiguration = network.CAM
                    }

                    console.log("qeweqweqweasdas", chainId, networkConfiguration.chainid);
                    if (Number(chainid) !== Number(chainId)) {
                        var accountDetailsss = await switchNetwork(chainid, networkConfiguration)
                    }
                }

                // console.log("sgfsdfgdsgdfg", hookTokBalDecimal);

                // console.log("no of tokenstate", NoofToken, NFT.balance)
                if (chainId == NFT.SelectedNetwork) {
                    accountDetailsss = true
                    let FilterCurrTokList = Wallet_Details.TokenLists.filter((item)=> String(item.Network) == String(NFT.SelectedNetwork))
                    setTokenListsnet(FilterCurrTokList)
                }
                else {
                    // console.log("zsdbgdzfhbdf", NFT.SelectedNetwork);
                    if (NFT?.SelectedNetwork) {
                        chainid = NFT.SelectedNetwork
                    }
                    // console.log("sbhgsdrbhg", chainid);
                    var accountDetailsss = await switchNetwork(chainid)
                }
                if (Number(chainid) === Number(chainId)) {
                    var hookTokBalDecimal = await HookbalCheck(Wallet_Details.networkConfiguration.tokenSymbol, Wallet_Details, chainid)
                    if (Wallet_Details.UserAccountAddr) {

                        if (status == 'accept') {
                            window.$('#accept_modal').modal('show');
                        }
                        else {
                            window.$('#place_bid_multiple_modal').modal('show');
                        }





                        var data = { Price: TokenPrice, Quantity: NFT.balance, Serfee: Wallet_Details.Service_Fee_Fraction, Decimal: 18 }
                        // console.log("data condole,data", data)
                        var Data = PurchaseCalculation(data)
                        console.log("DAtaaaaaaaaa ", Bids)
                        if (Bids) {
                            console.log("sfdsfjslfklsflks")
                            var Data = YouwillGetCalculation({ Price: Bids.tokenBidAmt, Serfee: Wallet_Details.Service_Fee, Quantity: (Bids.pending > ClickedNFT.CurrentOwner.balance) ? ClickedNFT.CurrentOwner.balance : Bids.pending })
                            console.log("bid data", Data)
                        }
                        console.log("DataDataData", Data);
                        setPrices(Data)
                        // console.log("jdsnbfufnuewrgn", ClickedNFT, NFT, Data, data, hookTokBalDecimal, hookTokBalDecimal)
                    }
                }
            }
        }))

    const Serfee = async (Address) => {
        console.log("Asdasdasdasdas", Address);
        var Fee = await getServiceFees(Address)
        // console.log("ndkdsnfkdsn", Fee);
        dispatch({
            type: Account_Connect,
            Account_Detail: {
                Service_Fee: Fee?.Serfee,
                Service_Fee_Fraction: Fee?.SerfeeFraction
            }
        })
    }

    async function switchNetwork(configdata) {
        var type = ""
        var networkConfiguration = {}
        const chainId = await Wallet_Details.Web3Pro.eth.getChainId();
        console.log("adsadasdasd", configdata, chainId, networkConfiguration);
        // console.log("sdnfvnfkvnfs", configdata);
        if (localStorage.walletConnectType !== "Torus") {
            if (Number(configdata) !== Number(chainId)) {
                const id = toast.loading("Switching Network", { closeButton: true, theme: "dark" });
                if (configdata) {
                    if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask') {

                        type = "MetaMask"
                    }
                    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'WalletConnect' && localStorage.walletConnectType != null) {
                        type = "WalletConnect"
                    }
                    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'Venly' && localStorage.walletConnectType != null) {
                        type = "Venly"
                    }
                    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'Torus' && localStorage.walletConnectType != null) {
                        type = "Torus"
                    }
                    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'TorusWallet' && localStorage.walletConnectType != null) {
                        type = "TorusWallet"
                    }
                    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'LMWallet' && localStorage.walletConnectType != null) {
                        console.log("adasd comming on torus");
                        type = "LMWallet"
                        localStorage.setItem("ChainId", NFTDet.SelectedNetwork)
                    }
                    // console.log("connecttype............", type, configdata)
                    var accountDetails = await connectWallet(type, Number(configdata), localStorage.walletConnectType == "LMWallet" ? "register" : "Create", Wallet_Details, "Tor")
                    // console.log("accountDetailsin create page......................", accountDetails)

                    // var web3 = new Web3(window.ethereum);
                    // console.log("dfghrtfh", web3);
                    // if (window.ethereum.isMetaMask == true) {

                    const chainId = await accountDetails?.web3?.eth?.getChainId();
                    // console.log("fghdtgj", chainId);

                    if (Number(configdata) === network.MATIC.Chainid) {
                        networkConfiguration = network.MATIC
                    }
                    else if (Number(configdata) === network.ETH.Chainid) {
                        networkConfiguration = network.ETH
                    }
                    else if (Number(configdata) === network.CAM.Chainid) {
                        networkConfiguration = network.CAM
                    }


                    // }
                    // setPriceoption()
                    // console.log("dsnsdkngksfnvkfdn", networkConfiguration);
                    if (accountDetails != '') {
                        // console.log("Wallet Connecting...increate", accountDetails?.web3?._provider);
                        dispatch({
                            type: Account_Connect,
                            Account_Detail: {
                                UserAccountAddr: accountDetails.accountAddress,
                                UserAccountBal: accountDetails.coinBalance,
                                WalletConnected: "true",
                                Wen_Bln: accountDetails.tokenBalance,
                                Accounts: accountDetails.accountAddress,
                                Web3Pro: accountDetails?.web3,
                                providerss: accountDetails?.web3?._provider,
                                networkConfiguration: networkConfiguration
                            }
                        })
                        Serfee(networkConfiguration.Chainid);
                    }
                    if (networkConfiguration.currencySymbol) {
                        toast.update(id, {
                            render: "Network switched Succesfully",
                            type: "success",
                            isLoading: false,
                            autoClose: 2500,
                            closeButton: true,
                            theme: "dark"
                        });
                    }
                }



                //   var ReqData = {
                //     addr: String(accountDetails.accountAddress).toLowerCase()
                // }
                // var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
                // if (Resp && Resp.data && Resp.data.data && Resp.data.data.User) {
                //     return Resp.data.data.User
                // } else {
                //     return null
                // }





                return accountDetails
            } else {
                dispatch({
                    type: Account_Connect,
                    Account_Detail: {
                        UserAccountAddr: Wallet_Details?.UserAccountAddr,
                        UserAccountBal: Wallet_Details?.UserAccountBal,
                        WalletConnected: "true",

                        Wen_Bln: Wallet_Details?.Wen_Bln,
                        Accounts: Wallet_Details?.Accounts,

                        Web3Pro: Wallet_Details?.Web3Pro,
                        providerss: Wallet_Details?.providerss,
                        networkConfiguration: networkConfiguration
                    }
                })
                Serfee(networkConfiguration.Chainid);
                return Wallet_Details.Web3Pro
            }
        } else {
            try {
                const id = toast.loading("Switching Network", { closeButton: true, theme: "dark" });
                var accountDetails = await connectWallet(localStorage.walletConnectType, Number(configdata), "Create", Wallet_Details, "Torus")
                // if (Number(networkNUM) == 97) {
                //   console.log("Comming on last else for switchCahin", Wallet_Details.web3auth, Wallet_Details?.web3);
                //   var addingChain = await Wallet_Details.web3auth.addChain({
                //     chainId: "0x61",
                //     displayName: "binance",
                //     chainNamespace: CHAIN_NAMESPACES.EIP155,
                //     tickerName: "BNB Smart Chain Testnet",
                //     ticker: "BNB",
                //     decimals: 18,
                //     rpcTarget: "https://data-seed-prebsc-1-s1.binance.org:8545/",
                //     blockExplorer: "https://testnet.bscscan.com/",
                //   });
                //   console.log("addingChain Web3Auth", addingChain);
                //   var switched = await Wallet_Details.web3auth.switchChain({ chainId: "0x61" });
                //   console.log("switched in create page uh", switched);
                // }else{
                //   console.log("Comming on last else for switchCahin", Wallet_Details.web3auth, Wallet_Details?.web3);
                //   var addingChain = await Wallet_Details.web3auth.addChain({
                //     chainId: "0x13881",
                //     displayName: "Mumbai",
                //     chainNamespace: CHAIN_NAMESPACES.EIP155,
                //     tickerName: "Mumbai",
                //     ticker: "MATIC",
                //     decimals: 18,
                //     rpcTarget: "https://matic-mumbai.chainstacklabs.com",
                //     blockExplorer: "https://mumbai.polygonscan.com/",
                //   });
                //   console.log("addingChain Web3Auth", addingChain);
                //   var switched = await Wallet_Details.web3auth.switchChain({ chainId: "0x13881" });
                //   console.log("switched in create page uh", switched);
                // }
                console.log("asdasdasdasdas", accountDetails);
                const chainId = await accountDetails?.web3?.eth?.getChainId();
                console.log("fghdtgj", configdata, network, chainId);

                if (Number(configdata) === network.MATIC.Chainid) {
                    networkConfiguration = network.MATIC
                }
                else if (Number(configdata) === network.ETH.Chainid) {
                    networkConfiguration = network.ETH
                }
                else if (Number(configdata) === network.CAM.Chainid) {
                    networkConfiguration = network.CAM
                }
                console.log("huiyooijmoijolmj", networkConfiguration);
                if (accountDetails != '') {
                    dispatch({
                        type: Account_Connect,
                        Account_Detail: {
                            UserAccountAddr: accountDetails?.accountAddress,
                            UserAccountBal: accountDetails?.coinBalance,
                            WalletConnected: "true",

                            Wen_Bln: accountDetails?.tokenBalance,
                            Accounts: accountDetails?.accountAddress,

                            Web3Pro: accountDetails?.web3,
                            providerss: accountDetails?.web3?._provider,
                            networkConfiguration: networkConfiguration
                        }
                    })
                }
                var functiobn = await NefunctionTriger(Wallet_Details)
                console.log("Asdasdaeqweqwe", networkConfiguration.currencySymbol);
                if (networkConfiguration.currencySymbol) {
                    toast.update(id, {
                        render: "Network switched Succesfully",
                        type: "success",
                        isLoading: false,
                        autoClose: 2500,
                        closeButton: true,
                        theme: "dark"
                    });
                }
            } catch (e) {
                console.log("adqeqweqweqweqweqweqwe", e);
            }
        }
    }

    const NefunctionTriger = async (Wallet_Details1) => {
        console.log("Wallet_Details1", Wallet_Details1);
        const chainIdSwitched = await Wallet_Details1?.Web3Pro?.eth?.getChainId();
        var balance = await Wallet_Details1?.Web3Pro?.eth.getBalance(Wallet_Details1.UserAccountAddr);
        console.log("chainIdSwitched", chainIdSwitched, balance);
        return chainIdSwitched
    }

    const HookbalCheck = async (data, walletdetail) => {
        // console.log("vfdkjnvfdvmklfdj", data, Wallet_Details,);
        // var TokenDecimal = await useTokenDecimal({ Coin: data }, 721, Wallet_Details)
        var BiddingBal = await useUserTokenBalance({ Coin: data }, 721, Wallet_Details)
        console.log("token name ,biddiin balance", BiddingBal)
        const tokdecimaldata = await GetDecimal({ Coin: data }, 721, Wallet_Details)
    console.log("tokdecimaldata",tokdecimaldata);
    setNewTokenDecimal(tokdecimaldata)
    setBiddingBalance(BiddingBal)
    setCustomTokenName(data)
    }

    const GetDecimal = async (data,wall,datass) =>{
        const Decimalss = await useTokenDecimal(data,wall,datass)
        return Decimalss
      }
      const customStyles = {
        menu: (provided, state) => ({
          ...provided,
          backgroundColor: "#fff",
          position: "relative",
          top: "3px",
          left: "0px",
          width: "100%",
          maxWidth: "100%",
          color: "#000"
        }),
        option: (provided, state) => ({
          ...provided,
          marginBottom: "5px",
          backgroundColor: "transparent",
          maxWidth: "356px",
          color: "#fff",
          borderBottom: "#fff",
          "&:hover": {
            transition: "all 0.5s ease",
            color: "#e50914"
          },
          "&:active": {
            transition: "all 0.5s ease",
            color: "#000"
          }
        }),
        control: (provided, state) => ({
          ...provided,
          border: "1px solid transparent",
          boxShadow: "0px 0px",
          "&:hover": {
            border: "1px solid transparent",
            boxShadow: "0px 0px",
          },
          "&:focus": {
            border: "1px solid transparent"
          }
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          border: "1px solid transparent"
        })
      }

    const ValidCheck = () => {
        var ValChk = { TokenPrice: TokenPrice, NoofToken: NoofToken, type: NFTDet.type, MaxToken: NFTDet.tokenQuantity, NFT: NFTDet }
        var Valid = PopupValidation(ValChk)
        if (Valid.Success) {
            var Check = (BiddingBalance / 10 ** NewTokenDecimal < Prices.PriceWithSerfee / 10 ** NewTokenDecimal)
            if(!CusTokenName){
               return toast.error("Choose Bidding Token", { theme: "dark" })
              }
              if(!CusTokAddress){
               return toast.error("Choose Bidding Token", { theme: "dark" })
              }
            if (Check) {
               return toast.error("Insufficient Bidding Balance", { theme: "dark" })
            }
            else{
                $('#hiddenbutton').trigger("click");
            }
        }
        else{
           return toast.error(Valid.Message, { theme: "dark" })
        }
    }

    // newly added for lazyminter

    const Bidcallfunction = async () => {
        console.log("sdfsdfksdvksv", NFTDet, NFT);
        // if (NFT.CurrentOwner.hashValue == "not yet minted" && NFT.CurrentOwner.LazyStatus == "pending") {
        //   console.log("sdfsdfsdjfsdsdfds");
        BidCall();
        // }
        // else if (NFT.CurrentOwner.hashValue !== "not yet minted" && NFT.CurrentOwner.LazyStatus == "pending") {
        //   console.log("dfdsknfdsnfksjdnvksf");
        //   BidCall();
        // }
        // else if (NFT.CurrentOwner.hashValue !== "not yet minted" && NFT.CurrentOwner.LazyStatus == "completed") {
        //   console.log("dcfsdnvkjnsfjkv");
        //   BalCheck();
        // }

    }

    const BalCheck = async () => {
        // alert("notml placebid")
        var Arg = {
            NFTConAddress: NFTDet.contractAddress,
            NFTId: NFTDet.tokenID,
            NFTOwner: NFT.CurrentOwner.tokenOwner,
            Status: NFT.CurrentOwner.LazyStatus
        }
        var Type = NFTDet.type
        console.log("fjbdshfbsfhsr", Arg, Type)
        var Provider = Wallet_Details
        var ContractNFTBal = await useNFTBalCheck(Arg, Type, Provider)
        // console.log("sldnsdjnkjsd", ContractNFTBal);
        if (Number(ContractNFTBal) !== NFT.CurrentOwner.balance) {
            Arg.balance = Number(ContractNFTBal)
            var Updated = await v1_ConNFTBalCheck(Arg)
            // console.log("fjbdshfbsfhsr", Updated)
            if (Updated.data && Updated.data.Success) {
                toast.error("Owners NFT Balance Changed. Try Later", { autoClose: 4000, closeButton: true })
                $('#bidpro_closs').trigger("click");
                setTimeout(() => {
                    history.push('/explore/All')
                }, 1500);
            }
            else {
                toast.error("Error Occured. Try Later", { autoClose: 3000, closeButton: true })
                $('#bidpro_closs').trigger("click");
                setTimeout(() => {
                    history.push('/explore/All')
                }, 1500);
            }
        }
        else {
            BidCall()
        }
    }

    const NFTHookFun = async (Arg, Wallet_Details) => {
        var ContractNFTBal = await useNFTBalCheckToken(Arg, Wallet_Details)
        return ContractNFTBal
    }

    const NFTHookFun1 = async (Arg, Type, Wallet_Details) => {
        var ContractNFTBal = await useBulBalnceCheck(Arg, Type, Wallet_Details)
        console.log("asdasddasdasdas", ContractNFTBal);
        return ContractNFTBal
    }

    const BidCall = async () => {
        // alert("sf")
        setBidText("OnProgress")
        console.log("anckjdnjkcsdnsdms", CusTokenName, NFT);
        var Arg = { Coinname: CusTokenName, NFTOwner: NFT.CurrentOwner.tokenOwner, NFTId: NFT.NFTDetail.tokenID, PriceWithoutSerfee: Prices.PriceWithoutSerfee, PriceWithSerfee: Prices.PriceWithSerfee, Status: NFT.CurrentOwner.LazyStatus }
        // console.log("Arg sfdjlks", Arg)
        var Type = NFTDet.type
        var Provider = Wallet_Details

        if (localStorage.walletConnectType !== "LMWallet") {
            var Argument = [Wallet_Details.networkConfiguration.TradeContract, Arg.PriceWithSerfee]
            //   var Receipt = await ReciptCommon(Argument, Provider, "orderPlace")
            var Receipt = await ContactHookTokenApp(Arg, Type, Provider)
        } else {
            var Key = null
            var Argument = [Wallet_Details.networkConfiguration.TradeContract, Arg.PriceWithSerfee]
            var Receipt = await LMWalletCallNormalBid(Arg, NFT.type, Wallet_Details, NFTDet, NFT, "Token", Wallet_Details.networkConfiguration.tokenAddr.CAKE, Wallet_Details.networkConfiguration.TradeContract, Argument, "approve", Key, false)
        }
        // console.log("sdc dsb jsmdssdfs", Receipt);
        if (Receipt.status) {
            console.log("Receipt.transactionHash", Receipt.transactionHash)
            var BidAct = { Status: Receipt.status, Hash: Receipt.transactionHash, ConAddr: NFTDet.contractAddress, Quantity: NoofToken, tokenID: NFTDet.tokenID, Bidder: Wallet_Details.UserAccountAddr, BidAmt: TokenPrice, tokenName: NFTDet.tokenName, coinName: CusTokenName, QR_tokenID: NFT.NFTDetail.QR_tokenID }
            // console.log("data noofteooke", NoofToken)
            var Resp = await BidAction2DB(BidAct)
            if (Resp.Success) {
                toast.success(Resp.Message)
                $('#bidpro_closs').trigger("click");
                console.log("dsadasdasdasdas", Resp);
                setBidstate(true)
                setSignstate(false)
                setBidText("Done")

            } else {
                toast.error(Resp.Message)
            }
        } else {
            toast.error("Try it Again Later.Please Check Your Funds")
        }
    }

    const AccBalMintCheck = async () => {
        console.log("asdasdasdasdassaad", NFT);
        // if (NFT.CurrentOwner.hashValue == "not yet minted" && NFT.CurrentOwner.LazyStatus == "pending") {
        //     // console.log("it comming in first");
        //     if (NFTDet.Bulkmint !== "Bulk") {
        //         AcceptBidMintCall();
        //     } else {
        //         ImageMergeProcess()
        //     }
        // }
        // else if (NFT.CurrentOwner.hashValue !== "not yet minted" && NFT.CurrentOwner.LazyStatus == "pending") {
        //     // console.log("it comming in Second");
        //     if (NFTDet.Bulkmint !== "Bulk") {
        //         AccBalCheck();
        //     } else {
        //         ImageMergeProcess()
        //     }
        // }
        // else if (NFT.CurrentOwner.hashValue !== "not yet minted" && NFT.CurrentOwner.LazyStatus == "completed") {
        //     // console.log("it comming in Third");
        //     if (NFTDet.Bulkmint !== "Bulk") {
        //         AccBalCheck();
        //     } else {
        //         ImageMergeProcess()
        //     }
        // }
        AccBalCheck();

    }


    const AccBalCheck = async () => {
        console.log("asdsafasfsad", NFT, NFTDet);
        var Arg = {
            NFTConAddress: NFTDet.ERC20_Address,
            NFTId: NFTDet.tokenID,
            NFTOwner: Wallet_Details.UserAccountAddr,
            Status: NFT.CurrentOwner.LazyStatus,
            BulK: NFTDet.Bulkmint
        }
        var Type = NFTDet.type
        // var ContractNFTBal = await useNFTBalCheck(Arg, Type, Wallet_Details)
        console.log("sdfwaewqedsadniuj", NFTDet.Bulkmint);
        if (NFTDet.Bulkmint !== "Bulk") {
            if (localStorage.walletConnectType !== "LMWallet") {
                var ContractNFTBal = await NFTHookFun(Arg, Wallet_Details)
            } else {
                var key = null
                var Argument = [Arg.NFTOwner]
                var ContractNFTBal = await LMBalanceCheckFraction(Arg, Type, Wallet_Details, Arg.NFTConAddress, Type == 721 ? "Single" : "Multiple", Arg.NFTConAddress, "balanceOf", Argument, key, Arg.NFTOwner)
            }
        } else {
            if (localStorage.walletConnectType !== "LMWallet") {
                var ContractNFTBal = await NFTHookFun1(Arg, Type, Wallet_Details)
            } else {
                var key = null
                var Argument = Type == 721 ? [Arg.NFTId] : [Arg.NFTOwner, Arg.NFTId]
                var ContractNFTBal = await LMBalanceCheck(Arg, Type, Wallet_Details, Arg.NFTConAddress, "Bulk", Arg.NFTConAddress, Type == 721 ? "ownerOf" : "balanceOf", Argument, key, Arg.NFTOwner)
            }
        }
        console.log("asdafjsdlfjsldjfkls", ContractNFTBal);
        if (Number(ContractNFTBal) !== NFT.CurrentOwner.balance) {
            Arg.balance = Number(ContractNFTBal)
            var Updated = await v1_ConNFTBalCheck(Arg)
            // console.log("fjbdshfbsfhsr", Updated)
            if (Updated.data && Updated.data.Success) {
                toast.error("Owners NFT Balance Changed. Try Later", { autoClose: 4000, closeButton: true })
                $('#Accept_closs').trigger("click");
                setTimeout(() => {
                    history.push('/explore/All')
                }, 1500);
            }
            else {
                toast.error("Error Occured. Try Later", { autoClose: 3000, closeButton: true })
                $('#Accept_closs').trigger("click");
                setTimeout(() => {
                    history.push('/explore/All')
                }, 1500);
            }
        }
        else {
            AcceptBidCall()
        }
    }

    const AcceptBidCall = async () => {
        // alert("normal accept")
        setAccBidstate(true)
        setAccBidText("OnProgress")
        console.log("qwewqeasdzxcx comming accpect bid", NFT,Bid);
        var Arg = { Coinname: Bid.coinname, BidderAddress: Bid.tokenBidAddress, PriceWithoutSerfee: Prices.PriceWithoutSerfee, NFTId: Bid.tokenID, NoofNFTs: (NFT.CurrentOwner.balance > Bid.pending) ? Bid.pending : NFT.CurrentOwner.balance, Status: NFT.CurrentOwner.LazyStatus }
        // var Calculation = await CharityAndRoyaltyCalculation(NFTDet, NFT)
        // console.log("Calculation", Calculation);
        var Type = NFTDet.type
        var Provider = Wallet_Details
        if (localStorage.walletConnectType !== "LMWallet") {
            var Argument = [Arg.Coinname, [Arg.BidderAddress, NFTDet.ERC20_Address], [Arg.NFTId, Arg.PriceWithoutSerfee, Arg.NoofNFTs]]
            var Receipt = await ReciptCommon(Argument, Wallet_Details, "acceptBId")
        } else {
            var Key = null
            var Argument = [Arg.Coinname, [Arg.BidderAddress, NFTDet.ERC20_Address], [Arg.NFTId, Arg.PriceWithoutSerfee, Arg.NoofNFTs]]
            var Receipt = await LMWalletCall(Argument, Provider, "acceptBId", "Mint", Key)
        }

        // console.log("andakncfkjdsnckdn", Receipt);

        if (Receipt.status) {
            var NewOwnerDetail = { Status: Receipt.status, Hash: Receipt.transactionHash, ConAddr: NFTDet.contractAddress, Owns: (NFT.CurrentOwner.balance > Bid.pending) ? Bid.pending : NFT.CurrentOwner.balance, OldOwner: Wallet_Details.UserAccountAddr, Action: "Accept", NewOwner: Bid.tokenBidAddress, NFTDetail: NFT.CurrentOwner, Bidder: Bid.tokenBidAddress, BidAmt: Bid.tokenBidAmt, coinname: NFT.coinname, LazyStatus: "completed", tokenName: NFTDet.tokenName, updateData: "", QR_tokenID: NFT.NFTDetail.QR_tokenID, ServiceFee: Wallet_Details.Service_Fee.Buyer, Fiat: NFT.Fiat, Charity: NFT.Charity, NFTPrice: Prices.PriceWithoutSerfee / 1e18, NFTPriceWithSerice: Prices.PriceWithSerfee / 1e18, ERC20_Address: NFTDet.ERC20_Address, ERCTrade: NFTDet.ERC20_Address,SelectedNetwork:NFTDet.SelectedNetwork }  //updatedata added for lazy mint
            var Resp = await UpdateNFTFractions(NewOwnerDetail)
            if (Resp) {
                setAccBidstate(true)
                setAccBidText("Done")
                toast.success("Transaction Successfull")
                $('#Accept_closs').trigger("click");
                setTimeout(() => {
                    history.push('/explore/All')
                }, 1000);
            }
        } else {
            toast.error("Try it Again Later.Please Check Your Funds")
        }
    }

    useEffect(() => {
        if (TokenPrice !== 0 && (TokenPrice !== prevTokenPrice || NoofToken !== prevNoofToken)) {
            console.log("jdsnbfufnuewrgn", TokenPrice, NoofToken)
            var data = { Price: TokenPrice, Quantity: NoofToken, Serfee: Wallet_Details.Service_Fee_Fraction, Decimal: NewTokenDecimal }
            var Data = PurchaseCalculation(data)
            setPrices(Data)
        }
    }, [NoofToken, TokenPrice])


    const selectChange = async (e) => {
        console.log("Accepttttttt", e);
        var NumDigitOnly = /[^0-9\.]/g
        var NumANdDotOnly12 = e.replace(NumDigitOnly, '')
        console.log("(e).length", (e).length, (e).length <= 12);
        if ((e).length <= 12) {

            console.log("dafdeeetdfsdfsdf", NumANdDotOnly12);
            setTokenPrice(NumANdDotOnly12)
        } else {
            setTokenPrice(0)
        }

    }


    const handleKeyDown = (e) => {
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            e.preventDefault();
        }
    };


    const ContactHookTokenApp = async (Arg, Type, Provider) => {
        var Receipt = await useTokenAppReceiptFract(Arg, Type, Provider)
        return Receipt
    }

    const LMWalletCall = async (Arg, Wallet_Details, method, NameUse, Key, TokenAddress) => {
        var receipt = null
        console.log("Comming Inside the LmWallet", Arg, Wallet_Details, method, NameUse, Key, TokenAddress);
        var Arg = {
            Method: method,
            Data: Arg,
            Mail: localStorage.loggedinaddress,
            LoginAddress: localStorage.userAddress,
            ContactAddress: Wallet_Details.networkConfiguration.FractionTradeContract,
            Chain: String(NFT.NetworkName),
            ContactType: NameUse,
            IntractContract: Wallet_Details.networkConfiguration.FractionTradeContract,
            Key: Key,
            Status: false,
            TokenAddress: TokenAddress
        }
        var Receiptfun = await ContactMintFraction(Arg)

        console.log("ArgReceipt", Receiptfun.data.receipt);
        return Receiptfun.data.receipt
    }



    const LMWalletCallNormalBid = async (Arg, type, Wallet_Details, tokDetails, NFT, NameUse, IntractContact, Contract, data, method, Key, Status) => {
        var receipt = null
        console.log("Comming Inside the LmWallet", Arg, type, Wallet_Details, tokDetails, NFT, NameUse, IntractContact, Contract, data, method, Key);
        var Arg = {
            Method: method,
            Data: data,
            Mail: localStorage.loggedinaddress,
            LoginAddress: localStorage.userAddress,
            ContactAddress: Contract,
            Type: String(type),
            Chain: String(NFTDet.SelectedNetwork),
            IntractContract: IntractContact,
            ContactType: NameUse,
            value: Prices.PriceWithSerfee,
            Key: Key,
            Status: Status
        }
        var Receiptfun = await ContactMint(Arg)

        console.log("ArgReceipt", Receiptfun.data.receipt);
        return Receiptfun.data.receipt
    }

    const LMBalanceCheck = async (Arg, Type, Wallet_Details, IntractContact, NameUse, Contract, method, data, Key, tokenOwner) => {
        console.log("LMBalanceCheck", Arg, Type, Wallet_Details, IntractContact, NameUse, Contract, method, data, Key);
        var Arg = {
            Method: method,
            Data: data,
            Mail: localStorage.loggedinaddress,
            LoginAddress: localStorage.userAddress,
            ContactAddress: Contract,
            Type: String(Type),
            Chain: String(NFTDet.SelectedNetwork),
            IntractContract: IntractContact,
            ContactType: NameUse,
            value: Prices.PriceWithSerfee,
            Key: Key,
            tokenOwner: tokenOwner
        }
        var Balance = await LMBalanceOf(Arg)
        console.log("Balance", Balance);
        return Balance.data.receipt
    }

    const LMBalanceCheckFraction = async (Arg, Type, Wallet_Details, IntractContact, NameUse, Contract, method, data, Key, tokenOwner) => {
        console.log("LMBalanceCheck", Arg, Type, Wallet_Details, IntractContact, NameUse, Contract, method, data, Key);
        var Arg = {
            Method: method,
            Data: data,
            Mail: localStorage.loggedinaddress,
            LoginAddress: localStorage.userAddress,
            ContactAddress: Contract,
            Type: String(Type),
            Chain: String(NFTDet.SelectedNetwork),
            IntractContract: IntractContact,
            ContactType: NameUse,
            value: Prices.PriceWithSerfee,
            Key: Key,
            tokenOwner: tokenOwner
        }
        var Balance = await LMBalanceOfFraction(Arg)
        console.log("Balance", Balance);
        return Balance.data.receipt
    }

    const ApproveTokenCall = async () => {
        console.log("NFTDetails.ERC20", NFT);
        SetAprrove("In Process")
        const id = toast.loading("Awaiting Response");
        var Arg = [NFTDet.ERCTrade, NFT.CurrentOwner.balance]
        var Provider = Wallet_Details
        if (localStorage.walletConnectType !== "LMWallet") {
            var Receipt = await ContactHookTokenAppAccept(Arg, Provider, "approve", NFTDet.ERC20_Address)
        } else {
            var Key = null
            var Receipt = await LMWalletCall(Arg, Provider, "approve", "Token", Key, NFTDet.ERC20_Address)
        }
        if (Receipt.status) {
            toast.update(id, { render: "Approved Successfully", type: "success", isLoading: false, autoClose: 2000 });
            SetAprrove("Success")
        } else {
            // toast.error("Try it Again Later.Please Check Your Funds")
            toast.update(id, { render: "Failed to Approve.", type: "error", isLoading: false, autoClose: 2000 });
            SetAprrove("Try Again")
        }
    }

    const ContactHookTokenAppAccept = async (Arg, Provider, Method, TokenAddress) => {
        var Receipt = await useTokenFractionApprove(Arg, Provider, Method, TokenAddress)
        return Receipt
    }

    const selectChange1 = async (e) => {
        console.log("knadkjsdfnskjdfs",e);
        var hookTokBalDecimal = await HookbalCheck(e.symbol, Wallet_Details, e.Network)
        setCusTokenAddress(e.contractAddress)
          }

    return (
        <>
            {/* place_bid multiple */}
            <div
                className="modal fade primary_modal"
                id="place_bid_multiple_modal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="place_bid_multiple_modalCenteredLabel"
                aria-hidden="true"
                data-backdrop="static"
                data-keyboard="false"
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-sm"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id="place_bid_multiple_modalLabel">
                                Place a bid
                            </h5>
                            <p className="text-center place_bit_desc">
                                You are about to {NFT.Mybid && NFT.Mybid.length !== 0 ? "Edit Your" : "Place a"} bid for
                            </p>
                            <p className="place_bit_desc_2">
                                {console.log("SDfsdfsdfsdfs", NFT)}
                                <span className="text_red mr-2">{NFTDet?.tokenName?.slice(0, 10)}</span>by
                                <span className="text_red ml-2 text-truncate">{(NFT.CurrentOwner.CurrentOwner && NFT.CurrentOwner.CurrentOwner.name) ? NFT.CurrentOwner.CurrentOwner.name : NFT.CurrentOwner.CurrentOwner && (NFT.CurrentOwner.CurrentOwner.curraddress).slice(0, 10)}</span>
                            </p>
                            {NFT.Mybid && NFT.Mybid.length !== 0 ?
                                <p className="text-center place_bit_desc">
                                    Your Previous Bid - {NFT.Mybid[0] && NFT.Mybid[0].tokenBidAmt} {config.tokenSymbol} for Each
                                </p> : ("")
                            }
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"

                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body px-0 pt-0">
                            {!(Prices.Display) ?
                                <><TailSpin wrapperClass="reactloader mt-0 mb-3" color="#00BFFF" height={100} width={70} /></>
                                :
                                <>
                                    <form className="px-4 bid_form">
                                        {/* <label htmlFor="bid">Your bid</label>
                <div className="input-group mb-3 input_grp_style_1">
                  <input
                    type="text"
                    id="bid"
                    className="form-control"
                    placeholder="Enter your bid"
                    aria-label="bid"
                    aria-describedby="basic-addon2"
                  />
                  <div className="input-group-append">
                    <span className="input-group-text pl-0" id="basic-addon2">
                      USD
                    </span>
                  </div>
                </div> */}

                                        <div className="input-group mb-3 position input_grp_style_1">
                                            <input
                                                type="number"
                                                min="1"
                                                max="12"
                                                value={TokenPrice}
                                                onChange={(e) => { selectChange(e.target.value) }}
                                                id="bid"
                                                className="form-control modal_inp_white"
                                                placeholder="Enter your bid"
                                                aria-label="bid"
                                                aria-describedby="basic-addon2"
                                                onKeyDown={handleKeyDown}
                                                onWheel={(e) => e.target.blur()}
                                            />
                                            {/* <div className="input-group-append">
                                                <span className="input-group-text pl-0" id="basic-addon2">
                                                    {config.tokenSymbol}
                                                </span>
                                            </div> */}
                                            <Select
  className="selectright"
  onChange={(e) => selectChange1(e)}
  options={TokenListsnet}
  label="Single select"
  isSearchable={false}
  styles={customStyles}

  classNamePrefix="react-select"
  formControlProps={{
    fullWidth: true,
  }}
/>
                                        </div>
                                        {(NFTDet.type !== 1155) &&
                                            <>
                                                <label htmlFor="qty">
                                                    Enter quantity{" "}
                                                    <span className="label_muted pl-2">({NFTDet.balance} available in MarketPlace)</span>
                                                </label>
                                                <div className="mb-3 input_grp_style_1">
                                                    <input
                                                        type="number"
                                                        id="qty"
                                                        onChange={(e) => { setNoofToken(e.target.value) }}
                                                        className="form-control modal_inp_white"
                                                        placeholder="quantity"
                                                        onKeyDown={handleKeyDown}
                                                        onWheel={(e) => e.target.blur()}
                                                    />
                                                </div>
                                            </>
                                        }
                                        <div className="row pb-3">
                                            <div className="col-12 col-sm-6">
                                                <p className="buy_desc_sm">Your balance</p>
                                            </div>
                                            <div className="col-12 col-sm-6 text-sm-right">
                                                <p className="buy_desc_sm_bold">{Wallet_Details.UserAccountBal} {config.currencySymbol}</p>
                                            </div>
                                        </div>
                                        <div className="row pb-3">
                                            <div className="col-12 col-sm-6">
                                                <p className="buy_desc_sm">Your Bidding balance</p>
                                            </div>
                                            <div className="col-12 col-sm-6 text-sm-right">
                                                <p className="buy_desc_sm_bold">{BiddingBalance / 10 ** NewTokenDecimal} {CusTokenName}</p>
                                            </div>
                                        </div>
                                        <div className="row pb-3">
                                            <div className="col-12 col-sm-6">
                                                <p className="buy_desc_sm">Service fee</p>
                                            </div>
                                            <div className="col-12 col-sm-6 text-sm-right">
                                                <p className="buy_desc_sm_bold">{Wallet_Details.Service_Fee.Buyer}%</p>
                                            </div>
                                        </div>
                                        <div className="row pb-3">
                                            <div className="col-12 col-sm-6">
                                                <p className="buy_desc_sm">You will pay</p>
                                            </div>
                                            <div className="col-12 col-sm-6 text-sm-right">
                                                {console.log("Prices", Prices)}
                                                <p className="buy_desc_sm_bold">{Prices.Display} {CusTokenName}</p>
                                            </div>
                                        </div>

                                        <div className="text-center">
                                            <Button
                                                className="d-none"
                                                id="hiddenbutton"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                data-toggle="modal"
                                                data-target="#proceed_bid_modal"
                                            >
                                                Hidden Button for validation
                                            </Button>
                                            <Button
                                                className="create_btn btn-block"
                                                onClick={() => ValidCheck()}
                                            >
                                                Place Bid
                                            </Button>
                                        </div>
                                    </form>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* end place_bid modal multiple */}

            {/* proceed_bid Modal */}
            <div
                className="modal fade primary_modal"
                id="proceed_bid_modal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="proceed_bid_modalCenteredLabel"
                aria-hidden="true"
                data-backdrop="static"
                data-keyboard="false"
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-sm"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id="proceed_bid_modalLabel">
                                Follow Steps
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                id="bidpro_closs"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                {/* {console.log("sknvfdvnkdfkndfvkndf", approvecheck)} */}
                                <div className="media approve_media">
                                    <i
                                        className="fas fa-check mr-3 pro_complete"
                                        aria-hidden="true"
                                    ></i>
                                    {/* <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i> */}

                                    <div className="media-body">
                                        <p className="mt-0 approve_text">Approve</p>
                                        <p className="mt-0 approve_desc">
                                            Checking balance and approving
                                        </p>
                                    </div>
                                </div>
                                <div className="text-center my-3">
                                    {console.log("dfedadadqeqwe", Bidstate, ApprovalCallStatus, approvecheck, BidText)}
                                    <Button
                                        className="create_btn btn-block"
                                        disabled={Bidstate && (ApprovalCallStatus !== 'done' || !approvecheck === false)}
                                        // onClick={()=>{BalCheck()}}
                                        onClick={() => { Bidcallfunction() }}

                                    >  {BidText === "OnProgress" && (
                                        <i
                                            class="fa fa-spinner mr-3 spinner_icon"
                                            aria-hidden="true"
                                        ></i>
                                    )}{BidText}</Button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* end proceed_bid modal */}

            {/* accept bid Modal */}
            {Bid &&
                <div
                    className="modal fade primary_modal"
                    id="accept_modal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="accept_modalCenteredLabel"
                    aria-hidden="true"
                    data-backdrop="static"
                    data-keyboard="false"
                >
                    <div
                        className="modal-dialog modal-dialog-centered modal-sm"
                        role="document"
                    >
                        <div className="modal-content">
                            <div className="modal-header text-center">
                                <h5 className="modal-title" id="accept_modalLabel">
                                    Accept bid
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    id="Accept_closs"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            {!(Prices.PriceWithSerfee) ?
                                <><TailSpin wrapperClass="reactloader mt-0 mb-3" color="#00BFFF" height={100} width={70} /></>
                                :
                                <>
                                    <div className="modal-body px-0">
                                        <div className="change_price_img_div text-center">
                                            <img
                                                src={NFTDet.additionalImage != "" ? `${config.Back_URL}/nftImg/${NFTDet.tokenCreator}/${NFTDet.image}` : `${config.Back_URL}/nftImg/${NFTDet.tokenCreator}/${NFTDet.image}`}
                                                alt="Collections"
                                                className="img-fluid"
                                            />
                                        </div>
                                        <p className="text-center accept_desc">
                                            <span className="buy_desc_sm">
                                                You are about to accept bid for
                                            </span>
                                            <span className="buy_desc_sm_bold pl-2">{NFTDet?.tokenName?.slice(0, 15)}</span>
                                            <span className="buy_desc_sm pl-2">from</span>
                                            <span className="buy_desc_sm_bold pl-2">
                                                {(Bid.Bidderdet && Bid.Bidderdet.name === "") ? Bid.tokenBidAddress.slice(0, 20) : Bid.Bidderdet && Bid.Bidderdet.name}
                                            </span>
                                        </p>
                                        <p className="info_title text-center">
                                            {Bid.tokenBidAmt} {Bid.coinname} for {Bid.pending} edition(s)
                                        </p>

                                        <div className="row mx-0 pb-3 mt-4">
                                            <div className="col-12 col-sm-6 px-4">
                                                <p className="buy_desc_sm">Service fee</p>
                                            </div>
                                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                                                <p className="buy_desc_sm_bold">{Wallet_Details.Service_Fee_Fraction.Seller}%</p>
                                            </div>
                                        </div>
                                        <div className="row mx-0 pb-3">
                                            <div className="col-12 col-sm-6 px-4">
                                                <p className="buy_desc_sm">You will get</p>
                                            </div>
                                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                                                <p className="buy_desc_sm_bold">{Prices.PriceWithSerfee} {Bid.coinname}</p>
                                            </div>
                                        </div>
                                        <form className="px-4">
                                            {/* {console.log("sknvfdvnkdfkndfvkndf", approvecheck)} */}
                                            {/* {!approvecheck &&
                        <div className="media approve_media">
                          <i
                            className="fas fa-check mr-3 pro_complete"
                            aria-hidden="true"
                          ></i>
                          <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>

                          <div className="media-body">
                            <p className="mt-0 approve_text">Get Approve</p>
                            <p className="mt-0 approve_desc">
                      
                    </p>
                          </div>
                        </div>
                      } */}
                                            {console.log("sdasdasdasdasd", NFT)}
                                            <div className="text-center">
                                                <Button
                                                    className="create_btn btn-block"
                                                    disabled={Approve == "In Process" || Approve == "Success"}
                                                    // onClick={()=>{AccBalCheck()}}
                                                    onClick={() => { ApproveTokenCall() }}    // lazy minter functionality

                                                >
                                                    {Approve === "OnProgress" && (
                                                        <i
                                                            class="fa fa-spinner mr-3 spinner_icon"
                                                            aria-hidden="true"
                                                        ></i>
                                                    )}{Approve}
                                                </Button>
                                            </div>
                                            <div className="text-center">
                                                <Button
                                                    className="create_btn btn-block"
                                                    disabled={AccBidstate}
                                                    // onClick={()=>{AccBalCheck()}}
                                                    onClick={() => { AccBalMintCheck() }}    // lazy minter functionality

                                                >
                                                    {AccBidText === "OnProgress" && (
                                                        <i
                                                            class="fa fa-spinner mr-3 spinner_icon"
                                                            aria-hidden="true"
                                                        ></i>
                                                    )}{AccBidText}
                                                </Button>

                                            </div>
                                        </form>
                                    </div>
                                </>}
                        </div>
                    </div>
                </div>
            }
            {/* end accept bid modal */}
        </>
    )
})