import React, { lazy, useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styled from "../../node_modules/styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import ReactHtmlParser from 'react-html-parser';
import { Link } from "react-router-dom";
import { v1_Faq } from "actions/v1/user";
import { toast } from "react-toastify";
const Icon = styled(props => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #1c7fc0;
    font-size: 24px;
  }
  & > .minus {
    display: none;
    color: #1c7fc0;
    font-size: 24px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function HowItWorks(props) {
  const [modalshow,setModalshow] =useState(false)
  const classes = useStyles();
  const { ...rest } = props;
  const [expanded, setExpanded] = React.useState('panel1');
  const [Faqlist,setFaqlist]=useState([])
  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const GetFaq = async()=>{
    var Resp = await v1_Faq()
    if(Resp.Success){
      // console.log("how ir worm",Resp)
        setFaqlist(Resp.userValue)}
    else
      toast.error("Try it Again Later",{autoClose:2000})
  }
  
  useEffect(()=>{
    GetFaq()
  },[])

  return (
    <div className="home_header">
       <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home">
          <div className="header_logo_align">
            

            </div></Link>}
        leftLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader inner_page_bg_1"}>
        <div className={classes.container}>
          <GridContainer className="justify-content-center">
            <GridItem xs={12} sm={12} md={9}>

            <p className="heading_big_blk">How It Works</p>
            
            </GridItem>
          </GridContainer>
        </div>
        <div className="container mt-3 pb-5">
          <GridContainer className="justify-content-center">
            <GridItem xs={12} sm={12} md={9}>
              <div className="faq_panel">
                {Faqlist.length > 0 && Faqlist.map((faq,i)=>{
                  return(
                <Accordion expanded={expanded === `panel${i+1}`} onChange={handleChangeFaq(`panel${i+1}`)} className="mt-5">
                  <AccordionSummary expandIcon={<Icon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <div className="accordian_head"><h2>{faq.question}</h2></div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="accordian_para">
                      {ReactHtmlParser(faq.answer)}
                    </div>
                  </AccordionDetails>
                </Accordion>
                )})}
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
