import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import QrReader from 'react-qr-reader'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import CopyToClipboard from "react-copy-to-clipboard";
import { Grid } from '@material-ui/core';
import config from "../lib/config"
import { TicketScan, ScannedStatus, RejectedStatusChange, scannedtokenlist, AuthenticatedTokenlist, RejecctedToken, TokenReendem, TotalTicketsSold, progressbarpercent } from "../actions/v1/token"
import { toast, Flip } from "react-toastify";
import { Account_Connect } from "actions/redux/action";
toast.configure();


export default function QrScanner(props) {
    const dispatch = useDispatch();
    const useStyles = makeStyles(styles);
    const classes = useStyles();
    const history = useHistory();
    const { ...rest } = props;
    const Wallet_Details = useSelector((state) => state.wallet_connect_context);
    const [selected, setSelected] = useState("environment");
    const [startScan, setStartScan] = useState(false);
    const [loadingScan, setLoadingScan] = useState(false);
    const [AuthenticatedToken, SetAuthenticatedToken] = useState("")
    const [ApproveStatus, SetApproveStatus] = useState("");
    const [TicketDetails, SetTicketDeatils] = useState({})
    const [ErrorStatus, SetErrorStatus] = useState(false);
    const [data1, setData] = useState("");
    const [ScannedToken, SetScannedToken] = useState("")
    const [RejectedToken, SetRejecctedToken] = useState("")
    const [ReemdemToken, SetReemdemToken] = useState("")
    const [TicketsSold, SetTicketsSold] = useState("")
    const [links, SetLinks] = useState({})
    const handleScan = async (scanData) => {
        setLoadingScan(true);
        if (scanData && scanData !== null) {
            setData(scanData);
            setStartScan(false);
            setLoadingScan(false);
            if (scanData !== null) {
                SetLinks(scanData)
                scannedtokenChange(scanData)
                dispatch({
                    type: Account_Connect,
                    Account_Detail: {
                        ScanLinks: scanData,
                    }
                })
            }
        }
    };

    const handleError = (err) => {
        console.error("Errorrrrr in scanerrrrr", err);
    };

    const TicketVerify = async (add) => { 
        var slice = Wallet_Details?.ScanLinks?.split("/")
        var sendData = {
            TokenOwner: slice[5],
            tokenID: slice[6]
        }
        if (sendData.TokenOwner !== undefined) {
            var TicketStatus = await TicketScan(sendData)
            if (TicketStatus.data.success == true) {
                SetApproveStatus("Approved")
                SetLinks({})
                SetScannedToken("")
                SetErrorStatus(false)
                SetTicketDeatils({})
                TokenReendemList()
                ScanNext()
                dispatch({
                    type: Account_Connect,
                    Account_Detail: {
                        ScanLinks: null,
                    }
                })
            }
            dispatch({
                type: Account_Connect,
                Account_Detail: {
                    ScanLinks: null,
                }
            })
        }

    }
    const ScanNext = async () => {
        setStartScan(true)
        SetApproveStatus("")
        SetScannedToken("")
        SetTicketDeatils("")
        SetApproveStatus("")
        SetErrorStatus(false)
        dispatch({
            type: Account_Connect,
            Account_Detail: {
                ScanLinks: null,
            }
        })

    }


    const scannedtokenChange = async (add) => {
        var slicedata = add?.split("/")
        if (slicedata && add !== null) {
            var sendData = {
                TokenOwner: slicedata[5],
                tokenID: slicedata[6],
                Creator: Wallet_Details.UserAccountAddr
            }
            if (Wallet_Details.UserAccountAddr !== "") {
                var scannedtoken = await ScannedStatus(sendData)
                if (scannedtoken.data.success == true) {
                    if (scannedtoken.data.UpdateDetails.TicketStatus == "false" && scannedtoken.data.UpdateDetails.Rejected == "false") {
                        SetApproveStatus("Approved")
                        SetErrorStatus(true)
                        SetTicketDeatils(scannedtoken.data.UpdateDetails)
                    }
                    else if (scannedtoken.data.UpdateDetails.TicketStatus == "true") {
                        SetErrorStatus(false)
                        SetApproveStatus("Invalid Ticket")
                        SetTicketDeatils(scannedtoken.data.UpdateDetails)
                    }
                    else if (scannedtoken.data.UpdateDetails.Rejected == "Rejected") {
                        console.log("qweweqweqwe Second");
                        SetErrorStatus(false)
                        SetApproveStatus("Invalid Ticket")
                        SetTicketDeatils(scannedtoken.data.UpdateDetails)
                    }
                    else if (scannedtoken.data.UpdateDetails.Rejected == "false" && scannedtoken.data.UpdateDetails.TicketStatus == "true") {
                        SetApproveStatus("Approved")
                        SetErrorStatus(true)
                        SetTicketDeatils(scannedtoken.data.UpdateDetails)
                    }
                }
                else if (scannedtoken.data.success == "Soul") {
                    SetApproveStatus("Approved")
                    SetErrorStatus(true)
                    SetTicketDeatils(scannedtoken.data.UpdateDetails)
                }
                else {
                    SetErrorStatus(scannedtoken.data.success)
                    SetApproveStatus(scannedtoken.data.message)
                }
            }
        }
    }

    const RejectTickets = async () => {
        var slice = Wallet_Details?.ScanLinks?.split("/")
        var sendData = {
            TokenOwner: slice[5],
            tokenID: slice[6]
        }
        var RejectStatus = await RejectedStatusChange(sendData);
        if (RejectStatus.Status == "Success") {
            toast.success("Token Rejected")
            ScanNext()
        }
        else {
            toast.error("Try it Again Later")
        }
    }

    useEffect(() => {
        scannedtoken();
        AuthenticatedTokenList();
        RejectedTokenList();
        TokenReendemList();
        TokenticketsSoldList();
    }, [ApproveStatus])

    const scannedtoken = async () => {
        var Addresss = {
            Address: Wallet_Details.UserAccountAddr
        }
        if (Wallet_Details.UserAccountAddr !== "") {
            var scannedtoken = await scannedtokenlist(Addresss)
            SetScannedToken(scannedtoken);
        }
    }

    const AuthenticatedTokenList = async () => {
        var Addresss = {
            Address: Wallet_Details.UserAccountAddr
        }
        if (Wallet_Details.UserAccountAddr) {
            var Authenticated = await AuthenticatedTokenlist(Addresss);
            SetAuthenticatedToken(Authenticated);
        }
    }

    const RejectedTokenList = async () => {
        var Addresss = {
            Address: Wallet_Details.UserAccountAddr
        }
        if (Wallet_Details.UserAccountAddr !== "") {
            var RejectedToken = await RejecctedToken(Addresss);
            SetRejecctedToken(RejectedToken);
        }
    }

    const TokenReendemList = async () => {
        var Addresss = {
            Address: Wallet_Details.UserAccountAddr
        }
        if (Wallet_Details.UserAccountAddr !== "") {
            var RejectedToken = await TokenReendem(Addresss);
            SetReemdemToken(RejectedToken);
        }
    }

    const TokenticketsSoldList = async () => {
        var Addresss = {
            Address: Wallet_Details.UserAccountAddr
        }
        if (Wallet_Details.UserAccountAddr !== "") {
            var RejectedToken = await TotalTicketsSold(Addresss);
            SetTicketsSold(RejectedToken);
        }
    }
    return (
        <>
            <div className='scrolling_move_sec_align'>
                <Grid container spacing={2} style={{ marginTop: 20, paddingBottom: 10 }}>
                    <Grid item xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className='qrcode_whole_dtls_align'>
                            <div className='qrcode_address_dtls_align'>
                                <CopyToClipboard
                                    text={Wallet_Details.UserAccountAddr}
                                    onCopy={() =>
                                        toast.success("Address Copied", {
                                            closeButton: true,
                                            autoClose: 1500,
                                            transition: Flip,
                                            theme: "dark",
                                        })
                                    }
                                >
                                    <i class="fa-regular fa-copy" />
                                </CopyToClipboard>
                                <b>Wallet Address<span></span></b><br />
                                <small>{Wallet_Details.UserAccountAddr.slice(0, 20)}</small>
                            </div>
                            <div className='qrcode_img_align'>
                                <div className="App">
                                    {startScan && (
                                        <>
                                            <select onChange={(e) => setSelected(e.target.value)}>
                                                <option value={"environment"}>Back Camera</option>
                                                <option value={"user"}>Front Camera</option>
                                            </select>
                                            <QrReader
                                                facingMode={selected}
                                                delay={1000}
                                                onError={handleError}
                                                onScan={handleScan}
                                                // chooseDeviceId={()=>selected}
                                                style={{ width: "300px" }}
                                            />
                                        </>
                                    )}
                                
                                </div>
                            </div>
                            {!startScan &&
                                <div className='qrcode_img_align'>
                                    <img className='qrcode_img' src={require('../assets/images/faces/qrcode_invert.png')} alt='' />
                                </div>
                            }
                        </div>
                        <p className='qrcode_scan_txt_align'>SCANNING...</p>
                        <div className='text-center mt-2'>

                            <button className='start_scan_btn'
                                onClick={() => {
                                    setStartScan(!startScan);
                                }}
                            >
                                {startScan ? "Stop Scan" : "Start Scan"}
                            </button>
                        </div>
                    </Grid>
                    {ApproveStatus !== "" &&
                        <>
                            {ErrorStatus == false ?
                                <Grid item xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <div className='qrcode_integ_whole_align'>
                                        <div className='qrcode_integ_title_align'>
                                            <p className='qrcode_event_text'>Rejected</p>

                                        </div>
                                        <div className='qrcode_description_dtls_align'>
                                            <div className='qrcode_btn_align'>
                                                <button className='qrcode_attendee_align'>Attendee</button>
                                            </div>
                                            <div className='p-4'>
                                                <div className='qrcode_description_dtls_align'>
                                                    <div className='qrcode_btn_align'>
                                                        <button className='qrcode_attendee_align'>{TicketDetails?.tokenOwner?.slice(0, 10)}</button>
                                                    </div>
                                                    <div className='p-4'>
                                                        <h6 className='description_title_align'>Description</h6>
                                                        <p className='definition_text_align'>{TicketDetails?.tokenDesc}</p>
                                                        <div className='row'>
                                                            <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'>
                                                                <h6 className='description_title_align'>Network</h6>
                                                                <p className='definition_text_align'>{TicketDetails?.SelectedNetwork == config.MATIC_ID ?  config.Network_MAT : TicketDetails?.SelectedNetwork == config.CAM_ID ?  config.Network_CAM : config.Network_ETH}</p>
                                                            </div>
                                                            <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'>
                                                                <h6 className='description_title_align'>Name</h6>
                                                                <p className='definition_text_align'>{TicketDetails?.tokenName}</p>


                                                            </div>
                                                            <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'>
                                                                <h6 className='description_title_align'>Token ID</h6>
                                                                <p className='definition_text_align'>{TicketDetails?.tokenID}</p>


                                                            </div>

                                                        </div>



                                                        <h6 className='description_title_align'>Originator</h6>
                                                        <p className='definition_text_align'>{TicketDetails?.tokenCreator}</p>

                                                        <h6 className='description_title_align'>Owner Address</h6>
                                                        <p className='definition_text_align'>{TicketDetails?.tokenOwner}</p>

                                                        <h6 className='description_title_align'>Contact Address</h6>
                                                        <p className='definition_text_align'>{TicketDetails?.contractAddress}</p>

                                                        <h6 className='description_title_align'>TokenGating</h6>
                                                        <p className='definition_text_align'>{TicketDetails?.TokenGating}</p>
                                                    </div>


                                                </div>
                                                <div className='qrcode_description_btn_align'>
                                                    {TicketDetails?.TicketStatus == "true" &&
                                                        <button className='qrcode_red_btn_align'>User already verified</button>}
                                                   
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div className='w-100 text-center mt-3'>
                                        <button className='qrcode_verify_btn_align' onClick={() => {
                                            ScanNext();
                                        }} >SCAN NEXT</button>
                                    </div>

                                </Grid>
                                :
                                <Grid item xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                    {console.log("qweqweqweqweqwe", TicketDetails)}
                                    <div className='qrcode_integ_whole_align'>
                                        <div className='qrcode_integ_title_green_align'>
                                            <p className='qrcode_event_text'>{TicketDetails?.tokenName} <br />{TicketDetails?.startDateToken} to {TicketDetails?.endDateToken}  </p>

                                        </div>
                                        <div className='qrcode_description_dtls_align'>
                                            <div className='qrcode_btn_align'>
                                                <button className='qrcode_attendee_align'>{TicketDetails?.tokenOwner?.slice(0, 10)}</button>
                                            </div>
                                            <div className='p-4'>
                                                <h6 className='description_title_align'>Description</h6>
                                                <p className='definition_text_align'>{TicketDetails?.tokenDesc}</p>
                                                <div className='row'>
                                                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'>
                                                        <h6 className='description_title_align'>Network</h6>
                                                        <p className='definition_text_align'>{TicketDetails?.SelectedNetwork == config.MATIC_ID ? config.Network_MAT : TicketDetails?.SelectedNetwork == config.CAM_ID ? config.Network_CAM : config.Network_MAT}</p>


                                                    </div>
                                                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'>
                                                        <h6 className='description_title_align'>Name</h6>
                                                        <p className='definition_text_align'>{TicketDetails?.tokenName}</p>


                                                    </div>
                                                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'>
                                                        <h6 className='description_title_align'>Token ID</h6>
                                                        <p className='definition_text_align'>{TicketDetails?.tokenID}</p>


                                                    </div>

                                                </div>



                                                <h6 className='description_title_align'>Originator</h6>
                                                <p className='definition_text_align'>{TicketDetails?.tokenCreator}</p>

                                                <h6 className='description_title_align'>Owner Address</h6>
                                                <p className='definition_text_align'>{TicketDetails?.tokenOwner}</p>

                                                <h6 className='description_title_align'>Contact Address</h6>
                                                <p className='definition_text_align'>{TicketDetails?.contractAddress}</p>

                                                <h6 className='description_title_align'>TokenGating</h6>
                                                <p className='definition_text_align'>{TicketDetails?.TokenGating}</p>
                                                {(TicketDetails?.TicketStatus == "false" && TicketDetails?.Rejected == "false") ?

                                                    <>
                                                        {TicketDetails.tokenCreator == Wallet_Details.UserAccountAddr &&
                                                            <>
                                                                <div className='qrcode_description_btn_align'>
                                                                    <button className='qrcode_verify_btn_align' onClick={() => {
                                                                        TicketVerify();
                                                                    }} >VERIFIED</button>
                                                                    <button className='qrcode_verify_btn_align' onClick={() => {
                                                                        RejectTickets();
                                                                    }}>Rejected</button>



                                                                </div>
                                                            </>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {TicketDetails?.status !== "Soulbound" ?
                                                            <div className='verified_userbtn_cntr'>
                                                                <button className='qrcode_verify_btn_align'>User Verified</button>
                                                            </div> : ""
                                                        }
                                                    </>


                                                }
                                            </div>


                                        </div>

                                    </div>
                                    <div className='w-100 text-center mt-3'>
                                        <button className='qrcode_verify_btn_align' onClick={() => {
                                            ScanNext();
                                        }} >SCAN NEXT</button>
                                    </div>

                                </Grid>}
                        </>}

                </Grid>
            </div>
        </>
    )
}

