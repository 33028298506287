import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Abi721 from '../ABI/721.json'
import Abi1155 from '../ABI/1155.json'
import AbiTOKEN from '../ABI/token.json'
import TradeAbi from '../ABI/market.json'
import SoulAbi from '../ABI/SoulBound.json'
import BulkAbi from '../ABI/BulkMint.json'
import FractionalTrade from '../ABI/FractionTrade.json'
import Deployer from '../ABI/TokenDeployer.json'
import { useWeb3, useHTTPWeb3 } from "./useWeb3";
import config from '../lib/config'
import { updateLanguageServiceSourceFile } from "typescript";

export const useContract = async (Type, Provider) => {
    try {
        console.log("useContract First call", Provider, Type, Provider.networkConfiguration.TradeContract);
        const web3 = await useWeb3(Provider.Web3Pro)
        console.log("Eweb3 Calls", web3);
        if (Type === 721) {
            const contract = new web3.eth.Contract(TradeAbi, Provider.networkConfiguration.TradeContract);
            console.log("contract After Intraction", contract);
            return contract;
        }
        if (Type === 1155) {
            console.log("sdnvjsfnvjs");
            const contract = new web3.eth.Contract(TradeAbi, Provider.networkConfiguration.TradeContract);
            return contract;
        }
    } catch (e) {
        console.log("Catch error Contact error", e);
    }
}

export const useContract7211155 = async (Type, Provider) => {
    // console.log("sdcndssdksbkfbnskcsdflkdsdcksdfkd",Provider,Type,Provider.networkConfiguration.TradeContract);
    const web3 = await useWeb3(Provider.Web3Pro)
    // console.log("dnksdnksd",web3);
    if (Type === 721) {
        const contract = new web3.eth.Contract(TradeAbi, Provider.networkConfiguration.singleContract);
        // console.log("bdhshfjsxcxcdbhjsd",contract);
        return contract;
    }
    if (Type === 1155) {
        // console.log("sdnvjadsasasfnvjs");
        const contract = new web3.eth.Contract(TradeAbi, Provider.networkConfiguration.multipleContract);
        return contract;
    }
}

export const useContractBurn = async (Type, Provider) => {
    // console.log("sdcndssdksbkfbnksdfkd",Provider,Type);
    const web3 = await useWeb3(Provider.Web3Pro)
    // console.log("dnksdnksd",web3);
    if (Type === 721) {
        const contract = new web3.eth.Contract(FractionalTrade, Provider.networkConfiguration.FractionTradeContract);
        // console.log("bdhshfjsdbhjsd",contract);
        return contract;
    }
    if (Type === 1155) {
        const contract = new web3.eth.Contract(FractionalTrade, Provider.networkConfiguration.FractionTradeContract);
        return contract;
    }
}

export const useContractBurnFraction = async (Type, Provider) => {
    // console.log("sdcndssdksbkfbnksdfkd",Provider,Type);
    const web3 = await useWeb3(Provider.Web3Pro)
    // console.log("dnksdnksd",web3);
    if (Type === 721) {
        const contract = new web3.eth.Contract(FractionalTrade, Provider.networkConfiguration.FractionTradeContract);
        // console.log("bdhshfjsdbhjsd",contract);
        return contract;
    }
    if (Type === 1155) {
        const contract = new web3.eth.Contract(FractionalTrade, Provider.networkConfiguration.FractionTradeContract);
        return contract;
    }
}

export const useContractTranfer = async (Type, Provider) => {
    // console.log("Tranfer token useContract",Provider,Type);
    const web3 = await useWeb3(Provider.Web3Pro)
    // console.log("dnksdnksd",web3);
    if (Type === 721) {
        const contract = new web3.eth.Contract(Abi721, Provider.networkConfiguration.singleContract);
        // console.log("721 Transferrrrrrr",contract);
        return contract;
    }
    if (Type === 1155) {
        const contract = new web3.eth.Contract(Abi1155, Provider.networkConfiguration.multipleContract);
        // console.log("1155 Transfer contect");
        return contract;
    }
}

export const useContractTranferFraction = async (Type, Provider) => {
    // console.log("Tranfer token useContract",Provider,Type);
    const web3 = await useWeb3(Provider.Web3Pro)
    // console.log("dnksdnksd",web3);
        const contract = new web3.eth.Contract(Abi721, Provider.networkConfiguration.Fraction721Contract);
        // console.log("721 Transferrrrrrr",contract);
        return contract;

}

export const useNFTContract = async (Type, NFTConAddress, Provider) => {
    // console.log("adfsdkmsdkvmkfslj",Provider);
    const web3 = await useWeb3(Provider.Web3Pro)
    // console.log("dcksdnfkjdankjdnc",Type, NFTConAddress);
    if (Type === 721) {
        const contract = new web3.eth.Contract(Abi721, NFTConAddress);
        // console.log("wfwknfksd nknsfddf",contract);
        return contract;
    }
    if (Type === 1155) {
        console.log("1155 commm", Abi1155, NFTConAddress);
        const contract = new web3.eth.Contract(Abi1155, NFTConAddress);
        return contract;
    }
}

export const useTokenContract = async (Type, TokenName, Provider) => {
    console.log("sdfnsndnkdvmdkfj",Provider,Type, TokenName);
    const web3 = await useWeb3(Provider.Web3Pro)
    const OwnContract = await useContract(Type, Provider)
    const TokenAddress = await OwnContract.methods.getTokenAddress(TokenName).call()
    console.log("sdhgcjhsdcjsdbjc",TokenAddress);
    if (Type === 721) {
        const contract = new web3.eth.Contract(AbiTOKEN, TokenAddress);
        return contract;
    }
    if (Type === 1155) {
        const contract = new web3.eth.Contract(AbiTOKEN, TokenAddress);
        return contract;
    } else {
        const contract = new web3.eth.Contract(AbiTOKEN, TokenAddress);
        return contract;
    }
}

export const useServiceFee = async (Address) => {
    const web3 = await useHTTPWeb3()
    const contract = new web3.eth.Contract(Abi721, Address);
    const Fee = await contract
        .methods
        .getServiceFee()
        .call()
    var Serfee = { Buyer: Number(Web3.utils.fromWei(Fee[0])), Seller: Number(Web3.utils.fromWei(Fee[1])) }
    return Serfee;
}


export const useTradeTranfer = async (Type, Provider) => {
    // console.log("useTradeTranfer",Provider,Type,Provider.networkConfiguration.TradeContract);
    const web3 = await useWeb3(Provider.Web3Pro)
    // console.log("dnksdnksd",web3);
    if (Type === 721) {
        const contract = new web3.eth.Contract(TradeAbi, Provider.networkConfiguration.TradeContract);
        // console.log("bdhshfjsdbhjsdjqwdjfeaofdjo",contract);
        return contract;
    }
    if (Type === 1155) {
        // console.log("sdnvjsfnaksjdklasjdlkvjs");
        const contract = new web3.eth.Contract(TradeAbi, Provider.networkConfiguration.TradeContract);
        return contract;
    }
}

export const SoulBoundContract = async (Provider) => {
    console.log("SoulBoundContract", Provider, Provider.networkConfiguration.SoulBoundContract);
    const web3 = await useWeb3(Provider.Web3Pro)
    console.log("Web3 Using SoulBound", web3);
    const contract = new web3.eth.Contract(SoulAbi, Provider.networkConfiguration.SoulBoundContract);
    console.log("Return contract SoulBound", contract);
    return contract;
}

export const useContractBulk = async (Type, Addd, Provider) => {
    console.log("Bulkkkkkkkkkkkkkkkk", Type, Addd, Provider);
    const web3 = await useWeb3(Provider.Web3Pro)
    console.log("dnksdnksd", web3);
    if (Type === 721) {
        const contract = new web3.eth.Contract(BulkAbi, Addd);
        console.log("bdhshfjasdasdbhjsd", contract);
        return contract;
    }
    if (Type === 1155) {
        console.log("Bulkkkkkkkkkkkkkuuuu", Addd);
        const contract = new web3.eth.Contract(BulkAbi, Addd);
        return contract;
    }
}

export const useContactClaim = async (Type, Provider, HDProvider) => {
    console.log("sdcndssdksbkfbnksdfkd", Provider, Type, Provider.networkConfiguration.TradeContract);
    const web3 = await useWeb3(HDProvider)
    console.log("dnksdnksd", web3);
    if (Type === 721) {
        const contract = new web3.eth.Contract(TradeAbi, Provider.networkConfiguration.TradeContract);
        console.log("bdhshfjsdbhjsd", contract);
        return contract;
    }
    if (Type === 1155) {
        console.log("sdnvjsfnvjs");
        const contract = new web3.eth.Contract(TradeAbi, Provider.networkConfiguration.TradeContract);
        return contract;
    }
}

export const Walllet = async (Provider, key) => {
    const providerHDWalllet = new HDWalletProvider({
        privateKeys: [key],
        providerOrUrl: Provider.networkConfiguration.BNBPROVIDER,
        pollingInterval: 300000, // Increase the polling interval to 5 minutes (300,000 milliseconds)
        timeout: 120000, // Increase the timeout to 2 minutes (120,000 milliseconds)
    });
    return providerHDWalllet
}

export const useContractFraction = async (Provider) => {
    const web3 = await useWeb3(Provider.Web3Pro)
    const contract = new web3.eth.Contract(FractionalTrade, Provider.networkConfiguration.FractionTradeContract);
    return contract;
}

export const useTokenContractFraction = async (TokenAddress, Provider) => {
    const web3 = await useWeb3(Provider.Web3Pro)
    const contract = new web3.eth.Contract(AbiTOKEN, TokenAddress);
    return contract;
}

export const useDeployer = async (Provider,DeployerAdd) => {
    console.log("sdfnsndnkdvmdkfj",Provider,DeployerAdd);
    const web3 = await useWeb3(Provider.Web3Pro)
    const contract = new web3.eth.Contract(Deployer,DeployerAdd );
    return contract;
}

export const useDeployer1 = async (Provider,DeployerAdd) => {
    console.log("sdfnsndnkdvmdkfj",Provider,DeployerAdd);
    const web3 = await useWeb3(Provider.Web3Pro)
    const contract = new web3.eth.Contract(Deployer,DeployerAdd);
    return contract;
}

