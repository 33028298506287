import React, { useEffect, useState, useRef } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

import Connect from './separate/connect';


const useStyles = makeStyles(styles);




// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}



export default function ConnectWallet(props) {
  const classes = useStyles();
  const { ...rest } = props;
  
  return (
    <Connect></Connect>
  );
}
