import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from "@material-ui/core";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link, useLocation, useHistory } from "react-router-dom";
import Countdown from "react-countdown";
import Web3 from 'web3';
import Select from "react-select";
import chargeSample from '../assets/images/lazymintingnew/Untitled_spreadsheet.xlsx';
import Profile from "../assets/images/lazymintingnew/footer_logo.png"
//Functionality Components
import MintingCard from "./separate/Minting";
import Calender from "../components/Calender/Calender";
import axios from "axios";
import * as XLSX from 'xlsx';
import $ from "jquery";
import { NFTValidation, BulkValidation, ImageValidation, ImageValidationExcel } from "actions/controller/validation";
import { BulkmintingNFT, UserPackCheck, RouterChecker, BulkImgGen_Db, BulkImgGen_Db_IPFS } from '../actions/v1/token';
import configImp from "../lib/config";
import "react-toastify/dist/ReactToastify.css";
import { toast, Slide, Zoom, Flip, Bounce } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { Account_Connect, Account_disConnect } from "actions/redux/action";
import { connectWallet, WalletConnect } from '../hooks/useWallet';
import { network } from "./network";

toast.configure();

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return null;
}

export default function CreateSingle(props) {
    //Internal Functions
    //Last Update : 24.01.2022
    //Current Update : 24.01.2022
    //Functions : BNBprice, OnChangehandle, PriceCalculate
    const dispatch = useDispatch();
    const classes = useStyles();
    const { ...rest } = props;
    const history = useHistory();
    const options = [
        { value: "Art", label: "Art" },
        { value: "Collectibles", label: "Collectibles" },
        { value: "Domain Names", label: "Domain Names" },
        { value: "Music", label: "Music" },
        { value: "Photography", label: "Photography" },
        { value: "Sports", label: "Sports" },
        { value: "Trading Cards", label: "Trading Cards" },
        { value: "Utility", label: "Utility" },
        { value: "Virtual Worlds", label: "Virtual Worlds" },
    ];
    const Wallet_Details = useSelector((state) => state.wallet_connect_context);
    // console.log("Wallet_DetailsWallet_Details", Wallet_Details.networkConfiguration.multipleContract);
    const CreatePage = useLocation();
    const Bulkminting = CreatePage.pathname === "/Bulkminting";
    // console.log("loctionfor page path", CreatePage);
    const InitialForm = {
        NFTCategory: "",
        type: 1155,
        contractaddress: "",
        CollectionName: "",
        NetworkName: "",
        Coinname: "",
        NFTSubCategory: ""
    };
    const [Formdata, setFormdata] = useState(InitialForm);
    const [PutonSale, setPutonSale] = useState(false);
    const [FixedPrice, setFixedPrice] = useState(false);
    const [Auction, setAuction] = useState(false);
    const [USDPrice, setUSDPrice] = useState(0);
    const [NFTFile, setNFTFile] = useState(null);
    const [NFTFileExcel, setNFTFileExcel] = useState(null);
    const [NFTFilename, setNFTFilename] = useState(null);
    const [Coinname, setCoinname] = useState('')
    const [categorysate, Setcategorysate] = useState({})
    const [Subcategorysate, SubSetcategorysate] = useState({})
    const [Preview, setPreview] = useState(

        require("../assets/images/lazymintingnew/footer_logo.png")
    );
    const [NFTFile1, setNFTFile1] = useState(null);
    const [NFTFile2, setNFTFile2] = useState(null);
    const [Preview1, setPreview1] = useState(

        require("../assets/images/lazymintingnew/logo.png")
    );
    const [Preview2, setPreview2] = useState(
        require("../assets/images/lazymintingnew/logo.png")
    );
    const [NFTFilename1, setNFTFilename1] = useState(null);
    const [NFTFilename2, setNFTFilename2] = useState(null);

    const [put, setPut] = useState("");
    const [category, Setcategory] = useState("")
    const [ValidationError, setValidationError] = useState(null)

    const [MinUSD, setMinUSD] = useState(0);
    const [networkoption, setnetworkoption] = React.useState([]);
    const [config, setconfig] = React.useState(InitialForm);
    const [Routeee, SetRouteeee] = useState("Not Allowed")
    const [CloseStatus, SetCloseStatus] = useState("Start");
    const [IpfsStatusTxt, setIpfsStatusTxt] = useState("Start");
    const [SoulBoundIPFS, setSoulBoundIPFS] = useState([]);
    const [IpfsStatusTxt1, setIpfsStatusTxt1] = useState("Start");
    const [IpfsStatusTxtFinal, setIpfsStatusTxtFinal] = useState("Start");
    const [ImageGen, setImageGen] = useState();

    const [theme, setTheme] = useState("#000");


    // useEffect(() => {
    //     console.log("localStorage.setItem",localStorage.setItem("theme1"))

    // }, []);

    useEffect(() => {
        if (document.getElementById("root").classList.contains("light_theme")) {
            setTheme("#FFF")
        }
        else {
            setTheme("#000")
        }
    }, []);

    const handleChange = () => {
        // this.setState({ selectedOption });
        // console.log(`Option selected:`, selectedOption);
    };
    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#fff",
            position: "relative",
            top: "3px",
            left: "0px",
            width: "100%",
            maxWidth: "100%",
            color: "#000"
        }),
        option: (provided, state) => ({
            ...provided,
            marginBottom: "5px",
            backgroundColor: "transparent",
            maxWidth: "356px",
            color: "#fff",
            borderBottom: "#fff",
            "&:hover": {
                transition: "all 0.5s ease",
                color: "#e50914"
            },
            "&:active": {
                transition: "all 0.5s ease",
                color: "#000"
            }
        }),
        control: (provided, state) => ({
            ...provided,
            border: "1px solid transparent",
            boxShadow: "0px 0px",
            "&:hover": {
                border: "1px solid transparent",
                boxShadow: "0px 0px",
            },
            "&:focus": {
                border: "1px solid transparent"
            }
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            border: "1px solid transparent"
        })
    }
    const renderer = ({
        days,
        Month,
        Year,
        hours,
        minutes,
        seconds,
        completed,
    }) => {
        if (completed) {
            return <span></span>;
        } else {
            return (
                <span>
                    {days}d {hours}h {minutes}m {seconds}s left
                </span>
            );
        }
    };

    useEffect(() => {
        BNBprice();
    }, []);

    useEffect(() => {
        console.log("rtetyrrrrewyrteyt", Wallet_Details?.Category);
        if (Wallet_Details?.Category) {
            var Categorydata = Wallet_Details?.Category.filter(Cat => (Cat.BulkStatus == 'true'))
            // console.log("categoryydatatatata", Categorydata);
            if (Categorydata) {
                Setcategorysate(Categorydata)
            }
        }
        if (Wallet_Details && Wallet_Details.networkoption.length > 0) {
            // console.log("skdvnskdnvjksdnvksdn", Wallet_Details.networkoption);
            setnetworkoption(Wallet_Details.networkoption)
        }


    }, [Wallet_Details?.Category]);

    const BNBprice = () => {
        axios
            .get(
                "https://api.pancakeswap.info/api/v2/tokens/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
            )
            .then((result) => {
                // console.log("Api Success", result.data.data.price);
                if (result.data && result.data.data && result.data.data.price) {
                    var Price = result.data.data.price;
                    setUSDPrice(Number(Price));
                    var minusd = 0.0002 * Number(Price)
                    setMinUSD(minusd)
                }
            })
            .catch((error) => {
                console.log("Api Overloaded", error);
            });
    };

    const OnChangehandle = (e) => {
        e.preventDefault();
        // console.log("event.tareget.e", e.target);
        const { id, value } = e.target;
        let Form = Formdata;
        Form = { ...Form, ...{ [id]: value } };

        setFormdata(Form);

    };



    const Handlefile1 = (e) => {
        // const id2 = toast.loading("Validating Details", { transition: Slide, theme: "dark" });
        if (e.target && e.target.files) {
            // console.log("e.target.files", e.target.files);
            var file = e.target.files[0];
            var Resp = ImageValidationExcel(file, "Edit")
            if (Resp.Success) {
                setNFTFileExcel(file)
                toast.success("Excel File upload Success")
            } else {
                toast.error('upload `.xlsx` Excel File')
            }
            // toast.update(id2, { render: "Ecxel File upload Sucess", type: "success", isLoading: false, autoClose: 2500 });

        }
    };

    const Handlefile = (data) => {
        // const id2 = toast.loading("Validating Details", { transition: Slide, theme: "dark" });
        console.log("qdwqeeqweweqwe", data);
        // console.log("HandleFileHandleFile", data.target.files);
        var file = data.target.files;
        console.log("fileeeeeeeee", file);
        if (file) {

            setNFTFile(file)
            // toast.update(id2, { render: "File upload Sucess", type: "success", isLoading: false, autoClose: 2500 });
            toast.success("File upload Success")
        }
        else {
            toast.error("Error in File Upload Please ReUpload")
        }

    }

    const HandlefileTicket = (e) => {
        // const id2 = toast.loading("Validating Details", { transition: Slide, theme: "dark" });
        console.log("HandleFileHandleFile", e.target.files);
        var FileArr = []
        if (e.target && e.target.files) {
            var file = e.target.files[0];
            // var fileName = file.name;
            // setNFTFilename1(fileName);
            // Formdata.ImageName = fileName;
            FileArr.push(file)
            var Resp = ImageValidation(file, "Edit")
            console.log("Respppppppp", Resp);
            if (Resp.Success) {
                setNFTFile(FileArr);
                // setPreview1(URL.createObjectURL(file));
                toast.success("File upload Success")
            }
            else
                toast.error("Error in File Upload Please ReUpload")
        }

    }

    const Handlefile2 = (e) => {
        if (e.target && e.target.files) {
            var file = e.target.files[0];
            var fileName = file.name;
            setNFTFilename1(fileName);
            Formdata.ImageName = fileName;
            var Resp = ImageValidation(file, "Edit")
            if (Resp.Success) {
                setNFTFile1(file);
                setPreview1(URL.createObjectURL(file));

            }
            else
                toast.error("Error in File Upload Please ReUpload")
        }
    };

    const Handlefile3 = (e) => {
        if (e.target && e.target.files) {
            var file = e.target.files[0];
            var fileName = file.name;
            setNFTFilename2(fileName);
            Formdata.ImageName = fileName;
            var Resp = ImageValidation(file, "Edit")
            if (Resp.Success) {
                setNFTFile2(file);
                setPreview2(URL.createObjectURL(file));
            }
            else
                toast.error("Error in File Upload Please ReUpload")
        }
    };


    async function switchNetwork(configdata) {
        var type = ""
        var networkConfiguration = {}
        const chainId = await Wallet_Details.Web3Pro.eth.getChainId();
        console.log("adsadasdasd", configdata.Chainid, chainId);
        console.log("dfadsaddasdadas", Wallet_Details, localStorage.walletConnectType);
        if (localStorage.walletConnectType !== "Torus") {
            if (Number(configdata.Chainid) !== Number(chainId)) {
                const id = toast.loading("Switching Network", { closeButton: true, theme: "dark" });
                if (configdata) {
                    if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask') {

                        type = "MetaMask"
                    }
                    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'WalletConnect' && localStorage.walletConnectType != null) {
                        type = "WalletConnect"
                    }
                    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'Venly' && localStorage.walletConnectType != null) {
                        type = "Venly"
                    }
                    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'Trous' && localStorage.walletConnectType != null) {
                        type = "Trous"
                    }
                    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'TorusWallet' && localStorage.walletConnectType != null) {
                        type = "TorusWallet"
                    }
                    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'LMWallet' && localStorage.walletConnectType != null) {
                        console.log("adasd comming on torus");
                        type = "LMWallet"
                        console.log("InitialForm.NetworkName", configdata.Chainid);
                        localStorage.setItem("ChainId", configdata.Chainid)
                    }

                    // console.log("dthstrhrtjhsrt",type,config.Chainid);

                    //     var accountDetails = await connectWallet(type,config.Chainid)

                    // console.log("accountDetailscreateeeeeeeeeee",accountDetails);

                    window.$('#connect_modal').modal('hide');
                    // console.log("connecttype............", type,configdata.Chainid)
                    var accountDetails = await connectWallet(type, configdata.Chainid, localStorage.walletConnectType == "LMWallet" ? "register" : "Create", Wallet_Details, "Tor")
                    // console.log("accountDetailsin create page......................", accountDetails)
                    //  const id=toast.loading("Wallet Connecting...")

                    //  var web3 = new Web3(window.ethereum);
                    //   console.log("dfghrtfh",web3);
                    //   if(window.ethereum.isMetaMask == true){

                    const chainId = await accountDetails?.web3?.eth?.getChainId();
                    // console.log("fghdtgj",chainId);

                    if (chainId === network.MATIC.Chainid) {
                        networkConfiguration = network.MATIC
                    }
                    else if (chainId === network.ETH.Chainid) {
                        networkConfiguration = network.ETH
                    }
                    else if (chainId === network.CAM.Chainid) {
                        networkConfiguration = network.CAM
                    }


                    //   }
                    //setPriceoption()

                    if (accountDetails != '' && accountDetails?.web3?._provider != '') {
                        dispatch({
                            type: Account_Connect,
                            Account_Detail: {
                                UserAccountAddr: accountDetails.accountAddress,
                                UserAccountBal: accountDetails.coinBalance,
                                WalletConnected: "true",
                                Wen_Bln: accountDetails.tokenBalance,
                                Accounts: accountDetails.accountAddress,
                                Web3Pro: accountDetails.web3,
                                providerss: accountDetails?.web3?._provider,
                                networkConfiguration: networkConfiguration
                            }
                        })
                    }

                    if (networkConfiguration.currencySymbol) {
                        toast.update(id, {
                            render: "Network switched Succesfully",
                            type: "success",
                            isLoading: false,
                            autoClose: 2500,
                            closeButton: true,
                            theme: "dark"
                        });
                    }

                }



                //   var ReqData = {
                //     addr: String(accountDetails.accountAddress).toLowerCase()
                // }
                // var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
                // if (Resp && Resp.data && Resp.data.data && Resp.data.data.User) {
                //     return Resp.data.data.User
                // } else {
                //     return null
                // }





                return accountDetails
            }
            else {
                dispatch({
                    type: Account_Connect,
                    Account_Detail: {
                        UserAccountAddr: Wallet_Details?.UserAccountAddr,
                        UserAccountBal: Wallet_Details?.UserAccountBal,
                        WalletConnected: "true",

                        Wen_Bln: Wallet_Details?.Wen_Bln,
                        Accounts: Wallet_Details?.Accounts,

                        Web3Pro: Wallet_Details?.Web3Pro,
                        providerss: Wallet_Details?.providerss,
                        networkConfiguration: configdata
                    }
                })
                return Wallet_Details.Web3Pro
            }
        } else {
            try {
                const id = toast.loading("Switching Network", { closeButton: true, theme: "dark" });
                var accountDetails = await connectWallet(localStorage.walletConnectType, configdata.Chainid, "Create", Wallet_Details, "Torus")
                // if (Number(networkNUM) == 97) {
                //   console.log("Comming on last else for switchCahin", Wallet_Details.web3auth, Wallet_Details?.web3);
                //   var addingChain = await Wallet_Details.web3auth.addChain({
                //     chainId: "0x61",
                //     displayName: "binance",
                //     chainNamespace: CHAIN_NAMESPACES.EIP155,
                //     tickerName: "BNB Smart Chain Testnet",
                //     ticker: "BNB",
                //     decimals: 18,
                //     rpcTarget: "https://data-seed-prebsc-1-s1.binance.org:8545/",
                //     blockExplorer: "https://testnet.bscscan.com/",
                //   });
                //   console.log("addingChain Web3Auth", addingChain);
                //   var switched = await Wallet_Details.web3auth.switchChain({ chainId: "0x61" });
                //   console.log("switched in create page uh", switched);
                // }else{
                //   console.log("Comming on last else for switchCahin", Wallet_Details.web3auth, Wallet_Details?.web3);
                //   var addingChain = await Wallet_Details.web3auth.addChain({
                //     chainId: "0x13881",
                //     displayName: "Mumbai",
                //     chainNamespace: CHAIN_NAMESPACES.EIP155,
                //     tickerName: "Mumbai",
                //     ticker: "MATIC",
                //     decimals: 18,
                //     rpcTarget: "https://matic-mumbai.chainstacklabs.com",
                //     blockExplorer: "https://mumbai.polygonscan.com/",
                //   });
                //   console.log("addingChain Web3Auth", addingChain);
                //   var switched = await Wallet_Details.web3auth.switchChain({ chainId: "0x13881" });
                //   console.log("switched in create page uh", switched);
                // }
                console.log("asdasdasdasdas", accountDetails);
                const chainId = await accountDetails?.web3?.eth?.getChainId();
                console.log("fghdtgj", configdata, network, chainId);

                if (configdata.Chainid === network.MATIC.Chainid) {
                    networkConfiguration = network.MATIC
                }
                else if (configdata.Chainid === network.ETH.Chainid) {
                    networkConfiguration = network.ETH
                }
                else if (configdata.Chainid === network.CAM.Chainid) {
                    networkConfiguration = network.CAM
                }
                if (accountDetails != '') {
                    dispatch({
                        type: Account_Connect,
                        Account_Detail: {
                            UserAccountAddr: accountDetails?.accountAddress,
                            UserAccountBal: accountDetails?.coinBalance,
                            WalletConnected: "true",

                            Wen_Bln: accountDetails?.tokenBalance,
                            Accounts: accountDetails?.accountAddress,

                            Web3Pro: accountDetails?.web3,
                            providerss: accountDetails?.web3?._provider,
                            networkConfiguration: networkConfiguration
                        }
                    })
                }
                var functiobn = await NefunctionTriger(Wallet_Details)

                if (networkConfiguration.currencySymbol) {
                    toast.update(id, {
                        render: "Network switched Succesfully",
                        type: "success",
                        isLoading: false,
                        autoClose: 2500,
                        closeButton: true,
                        theme: "dark"
                    });
                }
            } catch (e) {
                console.log("adqeqweqweqweqweqweqwe", e);
            }
        }
    }

    const NefunctionTriger = async (Wallet_Details1) => {
        console.log("Wallet_Details1", Wallet_Details1);
        const chainIdSwitched = await Wallet_Details1?.Web3Pro?.eth?.getChainId();
        var balance = await Wallet_Details1?.Web3Pro?.eth.getBalance(Wallet_Details1.UserAccountAddr);
        console.log("chainIdSwitched", chainIdSwitched, balance);
        return chainIdSwitched
    }


    const Validate = async () => {

let dataExceldata = null
        if (!localStorage.userAddress || localStorage.userAddress !== Wallet_Details.UserAccountAddr) {
            window.$("#triggerlogin").click();
            window.$("div").removeClass('modal-backdrop fade show')
            return toast.error("Login with correct Wallet Address and credentials")
        }
        if (NFTFileExcel) {
            // Create a new FileReader instance
            const reader = new FileReader();

            // Define the onload event for the reader
            reader.onload = (e) => {
                    // Get the file's content as an ArrayBuffer
                    const ab = e.target.result;
                    
                    // Parse the file's content
                    const workbook = XLSX.read(ab, { type: 'array' });
                    
                    // Extract the first sheet
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    
                    // Convert the sheet to JSON
                    dataExceldata = XLSX.utils.sheet_to_json(sheet, { raw: false, cellDates: true, cellStyles: true, });
                    
                    // Update state with the extracted data
                    console.log('Error reaaskdding file:', dataExceldata);
            };

            // Handle file read errors
            reader.onerror = () => {
                console.log("Sdfadsfafasdasd");
            };

            // Read the file as an ArrayBuffer
            reader.readAsArrayBuffer(NFTFileExcel);
        }

        var SendDatass = {
            Address: Wallet_Details.UserAccountAddr
        }

        var createLimit = await UserPackCheck(SendDatass)
        console.log("asdasdasdasd", createLimit, createLimit.data.data.Message,NFTFileExcel);
      
        console.log("dataExceldatadataExceldata",dataExceldata);
        const currentTotal = await dataExceldata.reduce((sum, item) => sum + Number(item.TokenQuantity), 0);
        console.log("asdasdasd",currentTotal);
        if (createLimit.data.data.Message == "success") {
            console.log("asdsaddasdasd", createLimit.data.data.Message);
            var BulkmintingLimit = createLimit?.data?.data?.UserBalance
            // var MultipleLimit = createLimit?.data?.data?.UserBalance?.MultipleMintLint
            // var checkverify = CreateMultiple ? SingleLimit : MultipleLimit
            console.log("asdssadasdasdasd", BulkmintingLimit);
            console.log("qweeqweqoiiooiio", NFTFile?.length);
            var mintbal = createLimit?.data?.data?.UserBalance
            console.log("mintbalmintbal", mintbal);
            if (BulkmintingLimit.PurchaseDetails.length > 0) {

                var EmptyArr = []

                var mapdata = mintbal.PurchaseDetails.map(async (item, index) => {
                    console.log("qewweqwewqeqweqwe", item.CommonCount);

                    EmptyArr.push(Number(item.CommonCount))


                })
                if (EmptyArr.length > 0) {
                    var sum = Object.values(EmptyArr).reduce((a, b) => a + b, 0)
                    console.log("EmptyArrEmptyArr", sum, typeof (sum), Number(currentTotal), sum > Number(currentTotal));
                    if (sum < Number(currentTotal)) {
                        return toast.error("Minting Limit is Low.Please purchase pack")
                    }
                }
                else if (EmptyArr.length == 0) {
                    return toast.error("Purchase pack to Mint SBT")
                }
            } else {
                return toast.error("Purchase pack to Mint")
            }
        } else {
            return toast.error("purchase plan to mint")
        }

        // if(Formdata.NetworkName == '97' || Formdata.NetworkName == '4' ){
        //     if (Formdata.NetworkName == '97') {
        //         var configdata = network.BSC
        //         var accountDetails =await  switchNetwork(configdata)
        //         setconfig(configdata)
        //       }
        //       else if (Formdata.NetworkName == '4' ) {
        //         var configdata = network.ETH
        //         var accountDetails =await  switchNetwork(configdata)
        //         setconfig(configdata)
        //       }
        // }


        const id2 = toast.loading("Validating Details", { transition: Slide, theme: "dark" });
        var SaleType = (PutonSale) ? (FixedPrice) ? "Fixed" : (Auction) ? "Auction" : "Unlimited" : PutonSale
        Formdata.File = NFTFile
        Formdata.Profile = NFTFile2
        Formdata.Coverimg = NFTFile1
        Formdata.Excel = NFTFileExcel
        // console.log("validate", Formdata, NFTFile, NFTFileExcel, Formdata, NFTFile2, NFTFile2)
        var result = await BulkValidation(Formdata, NFTFile, NFTFileExcel, Formdata, NFTFile2, NFTFile1)
        setTimeout(() => {
            if (result.Success) {
                toast.update(id2, { render: "Validation Success", type: "success", isLoading: false, autoClose: 2000 });
                // console.log("resultttttt errorrr validaterrrrrr", result);
                setValidationError(result)
                // onSubmit()
                $('#BulkmintClick').trigger("click");
            }
            else {
                toast.update(id2, { render: "Validation Failed", type: "error", isLoading: false, autoClose: 2500 });
                setValidationError(result)
            }
        }, 1000);
    }
    const onSubmit = async (e) => {
        // var statuserr = accepvalidation()
        // console.log("BulkmintingExcelFileformdata", NFTFileExcel, NFTFile, Formdata);
        // if (statuserr == true) {
        SetCloseStatus("On progress")
        setIpfsStatusTxt("On progress")
        Formdata.contractaddress = Wallet_Details.networkConfiguration.BulKMintContract
        console.log("Adasdasdasdadasd", Formdata);
        if (Wallet_Details.UserAccountAddr && Wallet_Details.networkConfiguration.multipleContract) {
            var reqdata = {
                BulkImage: NFTFile,
                ExcelFile: NFTFileExcel,
                Category: Formdata,
                Curraddressurr: Wallet_Details.UserAccountAddr,
                Profileimg: NFTFile2,
                Coverimg: NFTFile1
            }
        }
        const id2 = toast.loading("Uploading in Server", { transition: Slide, theme: "dark" });
        // console.log("reqdata is comming", reqdata);
        var addcolldata = await BulkmintingNFT(reqdata);
        console.log("addcolldataaddcolldata", addcolldata);
        // console.log("sdvgsfgsgs", addcolldata.message.data.Message, addcolldata.message.data.data);
        if (addcolldata.message.data.Message == 'Fail') {
            // toast.error(addcolldata.message.data.data)
            toast.update(id2, { render: addcolldata.message.data.data, type: "error", isLoading: false, autoClose: 2500 });
            setIpfsStatusTxt("Try again")
        }
        else {
            toast.update(id2, { render: "Uploaded successfully", type: "success", isLoading: false, autoClose: 2500 });
            // toast.success("Collection added successfully")
            if (Formdata.NFTCategory !== "Ticket") {
                setSoulBoundIPFS(addcolldata?.message?.data?.newCollectiondata)
                setIpfsStatusTxt("Done")
            } else {
                setSoulBoundIPFS(addcolldata?.message?.data?.newCollectiondata)
                setIpfsStatusTxt("Done")
            }
        }
    };

    const IPFS_And_Db = async (e) => {
        setIpfsStatusTxt1("On progress")
        var SendData = {
            SoulBoundIPFS: SoulBoundIPFS,
            Category: Formdata,
            Curraddressurr: Wallet_Details.UserAccountAddr,
        }
        const id2 = toast.loading("Image Generating", { transition: Slide, theme: "dark" });
        console.log("reqdata is comming", SendData);
        var addcolldata = await BulkImgGen_Db(SendData);
        console.log("kdsnkasjfdanfj", addcolldata);
        if (addcolldata?.data?.Message == 'Fail') {
            // toast.error(addcolldata.message.data.data)
            toast.update(id2, { render: addcolldata?.data?.data, type: "error", isLoading: false, autoClose: 2500 });
            setIpfsStatusTxt1("Try again")
        }
        else {
            setTimeout(() => {
                toast.update(id2, { render: "Image Generated successfully", type: "success", isLoading: false, autoClose: 2500 });
                setImageGen(addcolldata?.data?.data)
                setIpfsStatusTxt1("Done")
                // toast.success("Collection added successfully")
                // if (Formdata.NFTCategory !== "Ticket") {
                //     $('#ClosingBulk').trigger("click");
                //     history.push({
                //         pathname: `/collections/Collection/${Formdata.NFTCategory}`,
                //     })
                //     setIpfsStatusTxt("Done")

                // } else {
                //     $('#ClosingBulk').trigger("click");
                //     history.push({
                //         pathname: "/",
                //     })
                //     setIpfsStatusTxt("Done")

                // }
            }, 26000);
        }
    }

    const IPFS_And_Db_Final = async () => {
        setIpfsStatusTxtFinal("On progress")
        var SendData = {
            SoulBoundIPFS: ImageGen.Image,
            Category: Formdata,
            Curraddressurr: Wallet_Details.UserAccountAddr,
        }
        const id2 = toast.loading("Uploading IPFS", { transition: Slide, theme: "dark" });
        // console.log("reqdata is comming", reqdata);
        var addcolldata = await BulkImgGen_Db_IPFS(SendData);
        console.log("kdsnkaswkjdnfwkjfjfdanfj", addcolldata);
        if (addcolldata?.data?.Message == 'Fail') {
            // toast.error(addcolldata.message.data.data)
            toast.update(id2, { render: addcolldata?.data?.data, type: "error", isLoading: false, autoClose: 2500 });
            setIpfsStatusTxtFinal("Try again")
        }
        else {
            setTimeout(() => {
                toast.update(id2, { render: addcolldata?.data?.data, type: "success", isLoading: false, autoClose: 2500 });
                setImageGen(addcolldata?.data?.data)
                // toast.success("Collection added successfully")
                if (Formdata.NFTCategory !== "Ticket") {
                    $('#ClosingBulk').trigger("click");
                    history.push({
                        pathname: `/collections/Collection/${Formdata.NFTCategory}`,
                    })
                    setIpfsStatusTxtFinal("Done")

                } else {
                    $('#ClosingBulk').trigger("click");
                    history.push({
                        pathname: "/",
                    })
                    setIpfsStatusTxtFinal("Done")

                }
            }, 6000);
        }
    }

    const selectChange = async (e) => {



        console.log("sdgsdrh", e.value, e.name);

        Formdata.NetworkName = e.value == configImp.Network_ETH ? Number(configImp.ETH_ID) : e.value == configImp.Network_CAM ? Number(configImp.CAM_ID) : Number(configImp.MATIC_ID)
        Formdata.Coinname = e.value == configImp.Network_ETH ? configImp.Currency_ETH : e.value == configImp.Network_CAM ? configImp.Currency_CAM : configImp.Currency_MATIC
        if (e.name == "networkname") {
            if (e.name == "networkname" && e.value == configImp.Network_ETH) {
                var configdata = network.ETH
                Formdata.contractaddress = network.ETH.BulKMintContract
                var accountDetails = await switchNetwork(configdata, Number(configImp.ETH_ID))
                setconfig(configdata)
            }
            else if (e.name == "networkname" && e.value == configImp.Network_MAT) {
                var configdata = network.MATIC
                var accountDetails = await switchNetwork(configdata, Number(configImp.MATIC_ID))
                setconfig(configdata)
                Formdata.contractaddress = network.MATIC.BulKMintContract
            }
            else if (e.name == "networkname" && e.value == configImp.Network_CAM) {
                var configdata = network.CAM
                var accountDetails = await switchNetwork(configdata, Number(configImp.CAM_ID))
                setconfig(configdata)
                Formdata.contractaddress = network.CAM.BulKMintContract
            }
        }
        // else{
        //   toast.warning("please connect your wallet")
        //  }

    }

    useEffect(() => {
        Route()
    }, [])
    const Route = async () => {
        var Addresss = {
            Address: Wallet_Details.UserAccountAddr
        }
        var SubscriberList = await RouterChecker(Addresss)
        console.log("qweqweqweSubscriberList", SubscriberList);
        if (SubscriberList?.data?.data?.Message == "Allowed") {
            SetRouteeee(SubscriberList?.data?.data?.userValue)
        }
        //  else {
        //     history.push("/")
        //     toast.error("Not Allowed")
        // }
    }
    const CategorySet = async (e) => {
        Formdata.NFTCategory = e
        var data = categorysate.filter((item) => item.name == e)
        console.log("sadjsabdja", data[0]);
        SubSetcategorysate(data[0].BulkSubCategory.filter(Cat => (Cat.show == 'false' || Cat.show == false)))
    }

    return (
        <div className="home_header create_single_mulitple">
            <Button className="
      d-none" data-toggle="modal" data-target="#register_modal" id="triggerlogin" ></Button>

            <Header
                color="transparent"
                routes={dashboardRoutes}
                brand={
                    <Link to="/home">
                        <div className="header_logo_align">


                        </div>
                    </Link>
                }
                leftLinks={<HeaderLinks />}
                changeColorOnScroll={{
                    height: 20,
                    color: "white",
                }}
                {...rest}
            />
            <ScrollToTopOnMount />
            <div className={classes.pageHeader + " inner_pageheader inner_pagh_bg_2"}>

                <div className={classes.container}>

                    <GridContainer>

                        <GridItem xs={12} sm={12} md={12}>

                            <p class="heading_big_blk mb-3"> <span><Link to="/create" className="mr-3"><i class="fas fa-long-arrow-alt-left" /></Link></span>Bulk minting</p>

                        </GridItem>
                        {/* <Link to={`${config.Back_URL}/public/LazyminterFinalExcel.xlsx`} target="_blank" download>Download</Link> */}
                        <Button className="fileindees"><p className="donwloadfiles">You can download your sample excel sheet format here</p>
                            <a href={chargeSample} download="SampleExcelSheet"><i class="fas fa-download"></i> </a></Button>
                    </GridContainer>
                </div>

                <div className="container">
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>

                            <p class="bulking_cover"> Cover Image (Image size must be 1480px * 280 px)</p>
                            <div className="bulkminting_profile">
                                {/* <img src={Profile} /> */}
                                <div className="item_inner_img">

                                    {NFTFilename &&
                                        NFTFilename.split(".").pop() == "mp4" ? (
                                        <video
                                            src={Preview1}
                                            alt="collections"
                                            autoPlay={true}
                                            muted={true}
                                            controls
                                        />
                                    ) : (
                                        // <div class="header_logo_align detaillogo"></div>                                       
                                        <img
                                            src={Preview1}
                                            alt="Collections"
                                            className="img-fluid lazy_logo_align"
                                        />
                                    )}
                                </div>
                                <input type="file" name="file" onChange={(e) => Handlefile2(e)} />

                                <i class="fas fa-pencil-alt"></i>
                                {ValidationError && ValidationError.Coverimg &&
                                    <p className="Warning_label">{ValidationError && ValidationError.Coverimg}</p>
                                }
                            </div>
                            <p class="bulking_cover mt-5"> Profile Image</p>
                            <div className="bulkminting_profileimg mb-2">
                                {/* <img src={Profile} /> */}
                                <div className="">

                                    {NFTFilename &&
                                        NFTFilename.split(".").pop() == "mp4" ? (
                                        <video
                                            src={Preview2}
                                            alt="collections"
                                            autoPlay={true}
                                            muted={true}
                                            controls
                                        />
                                    ) : (
                                        <img
                                            src={Preview2}
                                            alt="Collections"
                                            className="img-fluid lazy_logo_align"
                                        />
                                    )}
                                </div>
                                <input type="file" name="file" onChange={(e) => Handlefile3(e)} />

                                <i class="fas fa-pencil-alt"></i>

                            </div>
                            {ValidationError && ValidationError.Profile &&
                                <p className="Warning_label">{ValidationError && ValidationError.Profile}</p>
                            }
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <div className="form-group col-md-12 p-0">
                                <label className="primary_label" htmlFor="category">
                                    Select Network<span className="text-danger">*</span>
                                </label>
                                {/* { Wallet_Details?.Category.filter(items => (items.label != "Ticket"))} */}
                                {/* {console.log("qweqweqweqwwe", categorysate)} */}
                                <Select
                                    className="yes1 form-control primary_inp select1 selxet_app"
                                    onChange={(e) => selectChange(e)}
                                    options={networkoption}
                                    label="Single select"
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                    styles={customStyles}
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                />
                                {ValidationError && ValidationError.NetworkName &&
                                    <p className="Warning_label">{ValidationError && ValidationError.NetworkName}</p>
                                }
                            </div>
                            <div className="form-group col-md-12 p-0">
                                <label className="primary_label" htmlFor="category">
                                    Category<span className="text-danger">*</span>
                                </label>
                                {/* {console.log("qweqweqweqwwe", categorysate)} */}
                                <Select
                                    className="yes1 form-control primary_inp select1 selxet_app"
                                    onChange={(e) => CategorySet(e.name)}
                                    options={categorysate}
                                    // options={!Bulkminting ? Wallet_Details.Category : Wallet_Details.Category}
                                    label="Single select"
                                    isSearchable={false}
                                    classNamePrefix="react-select"
                                    styles={customStyles}
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                />
                                {ValidationError && ValidationError.NFTCategory &&
                                    <p className="Warning_label">{ValidationError && ValidationError.NFTCategory}</p>
                                }
                            </div>

                            <div className="form-group col-md-12 p-0">

                                <label className="primary_label" htmlFor="category">
                                    Sub Category<span className="text-danger">*</span>
                                </label>

                                {/* {console.log("qweqweqweqwwe", categorysate)} */}
                                <Select
                                    className="yes1 form-control primary_inp select1 selxet_app"
                                    onChange={(e) => Formdata.NFTSubCategory = e.name}
                                    options={Subcategorysate}
                                    // options={!Bulkminting ? Wallet_Details.Category : Wallet_Details.Category}
                                    label="Single select"
                                    isSearchable={false}
                                    classNamePrefix="react-select"
                                    styles={customStyles}
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                />
                                {ValidationError && ValidationError.NFTSubCategory &&
                                    <p className="Warning_label">{ValidationError && ValidationError.NFTSubCategory}</p>
                                }
                            </div>
                            <div className="form-group col-md-12 p-0">
                                <label className="primary_label" htmlFor="CollectionName">
                                    Collection Name<span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control primary_inp"
                                    id="CollectionName"
                                    autoComplete="off"
                                    onChange={(e) => OnChangehandle(e)}
                                    placeholder="e.g. Redeemable"
                                />
                                {ValidationError && ValidationError.name &&
                                    <p className="Warning_label">{ValidationError && ValidationError.name}</p>
                                }
                                {ValidationError && ValidationError.CollectionName &&
                                    <p className="Warning_label">{ValidationError && ValidationError.CollectionName}</p>
                                }
                            </div>
                            <form className="formCls pb-5">
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <div className="d-flex justify-content-between align-items-start">
                                            <div>
                                                <label className="primary_label" htmlFor="inputEmail4">
                                                    Upload Excel Sheet
                                                </label>
                                                <p className="sheetselected">
                                                    Upload .xlsx Format Only.
                                                </p>
                                                {ValidationError && ValidationError.Excel &&
                                                    <p className="Warning_label">{ValidationError && ValidationError.Excel}</p>
                                                }
                                                {/* {ValidationError && ValidationError.File &&
                                                    <p className="Warning_label">{ValidationError && ValidationError.File}</p>
                                                } */}
                                            </div>
                                            <div className="file_tn_classf">
                                                <div className="file_btn btn">
                                                    {NFTFileExcel && NFTFileExcel ? "Uploaded" : "Upload"}
                                                    <input
                                                        className="inp_file"
                                                        type="file"
                                                        name="file"
                                                        onChange={(e) => Handlefile1(e)}
                                                    />
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                {Formdata.NFTCategory !== 'Ticket' ?
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <div className="d-flex justify-content-between align-items-start">
                                                <div>
                                                    <label className="primary_label" htmlFor="inputEmail4">
                                                        Upload file
                                                    </label>
                                                    <p className="form_note">
                                                        PNG, WEBP, JPG. Max 30mb, Max 500 Images.
                                                    </p>

                                                    {ValidationError && ValidationError.File &&
                                                        <p className="Warning_label">{ValidationError && ValidationError.File}</p>
                                                    }

                                                </div>
                                                <div className="file_tn_classf">


                                                    <div className="file_btn btn">
                                                        {NFTFile && NFTFile ? "Uploaded" : "Upload"}
                                                        <input
                                                            className="inp_file"
                                                            type="file"
                                                            name="file"
                                                            webkitdirectory="true"
                                                            multiple
                                                            onChange={(e) => Handlefile(e)}
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    :
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <div className="d-flex justify-content-between align-items-start">
                                                <div>
                                                    <label className="primary_label" htmlFor="inputEmail4">
                                                        Upload file single
                                                    </label>
                                                    <p className="form_note">
                                                        PNG, WEBP, JPG. Max 30mb, Max 500 Images.
                                                    </p>

                                                    {ValidationError && ValidationError.File &&
                                                        <p className="Warning_label">{ValidationError && ValidationError.File}</p>
                                                    }

                                                </div>
                                                <div className="file_tn_classf">


                                                    <div className="file_btn btn">
                                                        {NFTFile && NFTFile ? "Uploaded" : "Upload"}
                                                        <input
                                                            className="inp_file"
                                                            type="file"
                                                            name="file"
                                                            autoComplete="off"
                                                            onChange={(e) => HandlefileTicket(e)}
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                }

                                <div>
                                    {(Wallet_Details.UserAccountAddr !== "") ?
                                        <Button
                                            className="submitbtng mt-3"
                                            onClick={() => Validate()}
                                        >
                                            Submit
                                        </Button>
                                        :
                                        <Link to="/connect-wallet">
                                            <Button className="submitbtng mt-3">Connect Wallet</Button>
                                        </Link>
                                    }
                                </div>
                            </form>
                        </GridItem>
                    </GridContainer>
                </div>
                <button id="BulkmintClick" className="d-none" data-target="#Bulkminting_item_modal" data-toggle="modal"></button>
            </div>

            <Footer />
            <div
                class="modal fade primary_modal"
                id="learn_modal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="learn_modalCenteredLabel"
                aria-hidden="true"
                data-backdrop="static"
                data-keyboard="false"
            >
                <div
                    class="modal-dialog modal-dialog-centered modal-sm"
                    role="document"
                >
                    <div class="modal-content">
                        <div class="modal-header text-center">
                            <h5 class="modal-title" id="learn_modalLabel">
                                How timed auction work
                            </h5>
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p>
                                When you put your item on timed auction, you choose currency,
                                minimum bid, starting and ending dates of your auction.
                            </p>

                            <p>
                                The bidder can only place a bid which satisfies the following
                                conditions:
                            </p>
                            <ol>
                                <li>It is at least minimum bid set for the auction</li>
                                <li>
                                    It is at least 5% higher than the current highest bid or it is
                                    at least 2.3B OPEN higher than the current highest bid
                                </li>
                            </ol>

                            <p>
                                Note that some bids may disappear with time if the bidder
                                withdraws their balance. At the same time, some bids may
                                reappear if the bidder has topped up their balance again.
                            </p>

                            <p>
                                Auction cannot be cancelled after any valid bid was made. Any
                                bid placed in the last 10 minutes extends the auction by 10
                                minutes.
                            </p>

                            <p>
                                In the 48 hours after the auction ends you will only be able to
                                accept the highest available bid placed during the auction. As
                                with regular bids, you will need to pay some gas to accept
                                it.Note that you can always decrease the price of your listing
                                for free, without making a transaction or paying gas. You can
                                view all your items here.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* end learn modal */}

            <div
                class="modal fade primary_modal"
                id="Bulkminting_item_modal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="create_item_modalCenteredLabel"
                aria-hidden="true"
                data-backdrop="static"
                data-keyboard="false"
            >
                <div
                    class="modal-dialog modal-dialog-centered modal-sm"
                    role="document"
                >
                    <div class="modal-content">
                        <div class="modal-header text-center">
                            <h5 class="modal-title" id="create_item_modalLabel">
                                Follow Steps
                            </h5>
                            {/* {IpfsStatusTxt == "Done" && */}
                            <button
                                type="button"
                                className={CloseStatus == "On progress" ? "close d-none" : "close"}
                                data-dismiss="modal"
                                id="ClosingBulk"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                            {/* } */}
                        </div>
                        <div class="modal-body">
                            <form>
                                <div className="media approve_media">
                                    {IpfsStatusTxt === "Try again" ? (
                                        <i
                                            className="fas fa-exclamation-triangle mr-3 text-danger"
                                            aria-hidden="true"
                                        ></i>
                                    ) : IpfsStatusTxt === "Done" ? (
                                        <i
                                            className="fas fa-check mr-3 pro_complete"
                                            aria-hidden="true"
                                        ></i>
                                    ) : (
                                        <i className="fas fa-check mr-3" aria-hidden="true"></i>
                                    )}
                                    <div className="media-body">
                                        <p className="mt-0 approve_text">Image Upload</p>
                                        <p className="mt-0 approve_desc">
                                            Image Uploading in Server
                                        </p>
                                    </div>
                                </div>
                                <div className="text-center my-3">
                                    <Button
                                        className="create_btn btn-block"
                                        onClick={() => onSubmit()}
                                        disabled={IpfsStatusTxt === "Done"}
                                    >
                                        {IpfsStatusTxt == "On progress" && (
                                            <i
                                                class="fa fa-spinner mr-3 spinner_icon"
                                                aria-hidden="true"
                                            ></i>
                                        )}
                                        {IpfsStatusTxt}
                                    </Button>
                                </div>
                                <div className="media approve_media">
                                    {IpfsStatusTxt1 === "Try again" ? (
                                        <i
                                            className="fas fa-exclamation-triangle mr-3 text-danger"
                                            aria-hidden="true"
                                        ></i>
                                    ) : IpfsStatusTxt1 === "Done" ? (
                                        <i
                                            className="fas fa-check mr-3 pro_complete"
                                            aria-hidden="true"
                                        ></i>
                                    ) : (
                                        <i className="fas fa-check mr-3" aria-hidden="true"></i>
                                    )}
                                    <div className="media-body">
                                        <p className="mt-0 approve_text">Image Generate</p>
                                        <p className="mt-0 approve_desc">
                                            Generates Image for Your NFT
                                        </p>
                                    </div>
                                </div>
                                {console.log("qweqweqweqweqweqwe", IpfsStatusTxt, IpfsStatusTxt1)}
                                <div className="text-center my-3">
                                    {/* {console.log("IpfsStatusTxt1IpfsStatusTxt1",IpfsStatusTxt1 === "Done" || "On progress"   IpfsStatusTxt === "On progress" || "Start")} */}
                                    {console.log("IpfsStatusTxt", IpfsStatusTxt)}
                                    <Button
                                        className="create_btn btn-block"
                                        onClick={() => IPFS_And_Db()}
                                        disabled={IpfsStatusTxt === "On progress" || IpfsStatusTxt === "Start" || IpfsStatusTxt1 == "Done"}
                                    >
                                        {IpfsStatusTxt1 == "On progress" && (
                                            <i
                                                class="fa fa-spinner mr-3 spinner_icon"
                                                aria-hidden="true"
                                            ></i>
                                        )}
                                        {IpfsStatusTxt1}
                                    </Button>
                                </div>


                                <div className="media approve_media">
                                    {IpfsStatusTxtFinal === "Try again" ? (
                                        <i
                                            className="fas fa-exclamation-triangle mr-3 text-danger"
                                            aria-hidden="true"
                                        ></i>
                                    ) : IpfsStatusTxtFinal === "Done" ? (
                                        <i
                                            className="fas fa-check mr-3 pro_complete"
                                            aria-hidden="true"
                                        ></i>
                                    ) : (
                                        <i className="fas fa-check mr-3" aria-hidden="true"></i>
                                    )}
                                    <div className="media-body">
                                        <p className="mt-0 approve_text">IPFS and Upload Files</p>
                                        <p className="mt-0 approve_desc">
                                            Generates IPFS Metadata for Your NFT
                                        </p>
                                    </div>
                                </div>
                                {console.log("qweqweqweqweqweqwe", IpfsStatusTxt, IpfsStatusTxt1)}
                                <div className="text-center my-3">
                                    {/* {console.log("IpfsStatusTxt1IpfsStatusTxt1",IpfsStatusTxt1 === "Done" || "On progress"   IpfsStatusTxt === "On progress" || "Start")} */}
                                    {console.log("IpfsStatusTxtFinal", IpfsStatusTxtFinal)}
                                    <Button
                                        className="create_btn btn-block"
                                        onClick={() => IPFS_And_Db_Final()}
                                        disabled={IpfsStatusTxtFinal == "On progress" || IpfsStatusTxt == "Start" || IpfsStatusTxt1 !== "Done"}
                                    >
                                        {IpfsStatusTxtFinal == "On progress" && (
                                            <i
                                                class="fa fa-spinner mr-3 spinner_icon"
                                                aria-hidden="true"
                                            ></i>
                                        )}
                                        {IpfsStatusTxtFinal}
                                    </Button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

