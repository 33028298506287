import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Ticketicon from "../../assets/images/lazymintingnew/ticket.svg"
import { Button } from "@material-ui/core";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Scrollbars } from "react-custom-scrollbars";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { FractionalNFTInfo } from "actions/controller/tokenControl";
import { VotingEntry } from "../../actions/v1/Fractional"
import { useSelector } from "react-redux";
import config from "lib/config";
import { PurchaseNow } from "../Fractions/BuyNow";
import { PlaceandCancel } from "../Fractions/PutOnSale";
import { BidActions } from "../Fractions/MakeOffer";
import { BurnToken } from "../Fractions/BurnToken";
import { TransferNFT } from "../Fractions/Transfer";
import { ClaimNfts } from "../separate/Claim"
import { Audio, TailSpin } from "react-loader-spinner";
import { toast, Flip } from "react-toastify";
import { v1_CancelBid, TokenAndTokenOwnerUpdate_Bulk_minting, LikeAction } from "actions/v1/token";
import { Report } from "../separate/Report";
import { Share } from "../separate/ShareNow";
import { v1_MyComment } from "actions/v1/user";
import Modal from "react-modal";
import isEmpty from "lib/isEmpty";
import Countdown from "react-countdown";
import ReactMarkdown from 'react-markdown';
import Cookies from 'universal-cookie';
import { AiOutlineLike } from "react-icons/ai";
import { AiOutlineDislike } from "react-icons/ai";
// import Countdown from 'react-countdown';
//
const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Info(props) {
  const classes = useStyles();
  const { ...rest } = props;
  function hideDetail() {
    document.getElementById("image_div").classList.toggle("expand_img");
    document.getElementById("img_des").classList.toggle("show_des");
    document.getElementById("detai_div").classList.toggle("hide_detail");
    document.getElementById("arrow_icon").classList.toggle("fa-shrink");
  }

  function hideDetailowner() {
    document.getElementById("image_div_owner").classList.toggle("expand_img");
    document.getElementById("img_des_owner").classList.toggle("show_des");
    document.getElementById("detai_div_owner").classList.toggle("hide_detail");
    document.getElementById("arrow_icon_owner").classList.toggle("fa-shrink");
  }
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  console.log("Wallet_DetailsWallet_Details", Wallet_Details);
  const { conaddr, owneraddr, tokenID } = useParams();
  // console.log("smkfmkdfmkmfsgs",conaddr, owneraddr, tokenID );
  const [NFT, setNFT] = useState({
    AllBids: [],
    CurrentOwner: { CurrentOwner: {} },
    History: [],
    NFTCreator: {},
    NFTDetail: {},
    NFTOwners: [],
    Mybid: [],
  });
  const renderer = ({
    days,
    Month,
    Year,
    hours,
    minutes,
    seconds,
    completed,
  }) => {
    if (completed) {
      return <span></span>;
    } else {
      return (
        <span>
          {days}d {hours}h {minutes}m {seconds}s left
        </span>
      );
    }
  };

  const cookies = new Cookies();
  const location = useLocation();
  const history = useHistory();
  const [NFTImage, setNFTImage] = useState("");
  const [Comments, setComments] = useState("");
  const [localStorageState, setlocalStorageState] = useState("");
  const [AllComments, setAllComments] = useState([]);
  const PurchaseNowRef = useRef();
  const PlaceandCancelRef = useRef();
  const BidActionsRef = useRef();
  const BurnTokenRef = useRef();
  const TransferNFTRef = useRef();
  const ReportRef = useRef();
  const ShareNowRef = useRef();
  const ClaimNftsRef = useRef();
  const [ShowModal, setShowModal] = useState(false);
  const [showingLoader, setshowingLoader] = React.useState(false);
  console.log("qdsjdalsdasd", NFT.NFTDetail.likecount);
  const [Templike, setTemplike] = useState(false)
  const [TempCount, setTempCount] = useState(0)
  let state = location.state;
  let locationpath = location.location
  const [theme, setTheme] = useState("#000");

  const renderers = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return "Waiting";
    } else {
      // Render a countdown
      return <span>{hours}:{minutes}:{seconds}</span>;
    }
  };

  useEffect(() => {

    if (document.getElementById("root").classList.contains("light_theme")) {
      setTheme("#FFF")
    }
    else {
      setTheme("#000")


    }
  }, []);
  // console.log("dcbsdhjhsdfhsd",location);
  // console.log("state data fromloaction", state)
  const customStyles = {
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      // boxShadow: '0 27px 24px 0 rgb(0 0 0 / 20%), 0 40px 77px 0 rgb(0 0 0 / 22%)',
      borderRadius: "30px",
      border: "none !important",
    },
    overlay: {
      backgroundColor: theme,
      zIndex: 2
    },
  };
  // useEffect(() =>{
  //   window.addEventListener('storage', () => {
  //     // When local storage changes, dump the list to
  //     // the console.
  //     console.log("asdasdasdasdas");
  //     console.log("xczxcxzcassadqwqwee",localStorage.getItem('loggedinaddress'));
  //   });
  // },[localStorage.loggedinaddress])
  const Cookiee = async () => {
    //document.cookie = "token" + "=" + "workkkkkk" + ";path=/" + "domain=lazyminter.com";
    cookies.set('myCat', 'Pacman', { path: '/', domain: 'lazyminter.com' });
    console.log(cookies.get('myCat'));
  }

  useEffect(() => {
    (async () => {
      var state = location.state
      var curAddr = Wallet_Details.UserAccountAddr
      console.log("dlkdsnfvkdnvkfd", state);
      const timestamp = Date.now();
      console.log("qweqwewqdasdaewqeqwqwe", Wallet_Details.UserAccountAddr, tokenID, owneraddr);
      var Payload = {
        curAddr: Wallet_Details.UserAccountAddr,
        tokenID: tokenID,
        queryaddress: owneraddr,
      };
      setshowingLoader(true)
      // if (Wallet_Details.UserAccountAddr !== "") {
      //   var tokenentry = await TokenAndTokenOwnerUpdate_Bulk_minting(Payload)
      //   // console.log("sdvfskmnvdkfnvkdf",tokenentry);
      //   if (tokenentry && tokenentry.data && tokenentry.data.RespType === 'success') {



      //   }
      // }
      console.log("wqqweqweqweqweqwe", Payload);

      await NFTInfoDetails(Payload);
      setshowingLoader(false)
    })();


    setShowModal(true);
    // NFTInfoDetails();
    UrComment();

  }, [Wallet_Details.UserAccountAddr, Wallet_Details.nftaction, tokenID, localStorage.loggedinaddress, localStorage.accountInfo]);

  const NFTInfoDetails = async (Payload) => {
    console.log("qweqweqweqw", Payload);
    var payloaddata = {
      curAddr: Wallet_Details.UserAccountAddr,
      tokenID: tokenID,
      queryaddress: owneraddr,
    }
    console.log("asdasdasdas", payloaddata);
    var Resp = await FractionalNFTInfo(Payload);
    console.log("REsp data", Resp)
    if (Resp.NFTOwners.length === 0 || isEmpty(Resp.NFTDetail)) {
      toast.error("NFT Not Found", { autoClose: 2500, transition: Flip });
      history.push("/explore/All");
      return
    }
    if (!Resp.CurrentOwner) {
      toast.error(`${(owneraddr).slice(0, 12)} does not own this NFT currently`, { autoClose: 3500, transition: Flip });
      history.push("/explore/All");
      return
    }
    if (Resp.CurrentOwner.VaultStatus == "Hide") {
      var found = Resp.CurrentOwner.AllowedUser.some((element) => element == localStorage.getItem("loggedinaddress"));
      console.log("dasdsda", found);
      if (found == false && Resp.CurrentOwner.tokenOwner !== Wallet_Details.UserAccountAddr) {

        toast.error(`You Dont Have Access to See this NFT.Please Contact NFT Owner`, { autoClose: 3500, transition: Flip });
        history.push("/explore/All");
        return
      }
    }
    setNFT(Resp);
    setTempCount(Resp.NFTDetail.likecount)
    console.log("Resp.NFTDetail.mylikes ? true : false", Resp.NFTDetail.mylikes ? true : false);
    setTemplike(Resp.NFTDetail.mylikes ? true : false)
    if (Resp.NFTDetail.image == "")
      setNFTImage(`${config.IPFS_IMG}/${Resp.NFTDetail.ipfsimage}`);
    else
      setNFTImage(`${config.Back_URL}/nftImg/${Resp.NFTDetail.tokenCreator}/${Resp.NFTDetail.image}`);
    setTimeout(() => {
      setShowModal(false);
    }, 1000);
  };

  const CancelBid = async (Biddet) => {
    const id = toast.loading("Bid Cancelling On Progress");
    var CanBiddet = {
      Biddet: Biddet,
      ActBody: {
        tokenName: NFT.NFTDetail.tokenName,
      },
    };
    var Resp = await v1_CancelBid(CanBiddet);
    if (Resp.data.Success) {
      toast.update(id, {
        render: Resp.data.Message,
        type: "success",
        isLoading: false,
        autoClose: 2500,
      });
      setTimeout(() => {
        NFTInfoDetails();
      }, 2000);
    } else {
      toast.update(id, {
        render: Resp.data.Message,
        type: "error",
        isLoading: false,
        autoClose: 2500,
      });
    }
  };

  const timediff = (a) => {
    var date1 = new Date(Date.now());
    var date2 = new Date(a);
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Math?.ceil(Difference_In_Days);
  };

  const UrComment = async (del) => {
    if (Wallet_Details.UserAccountAddr) {
      var Payload = {
        comments: {
          comment: Comments,
          curraddress: Wallet_Details.UserAccountAddr,
          tokenID: tokenID,
        },
        get: {
          tokenID: tokenID,
          get: Comments,
        },
      };
      Payload.delete = del ? del : {};
      var Resp = await v1_MyComment(Payload);
      console.log("asdasdasda", Resp);
      if (Resp.data.Success) {
        if (!Comments) {
          setAllComments(Resp.data.records);
          if (Resp.data.Message) {
            toast.success(Resp.data.Message, {
              closeButton: true,
              autoClose: 1500,
              transition: Flip,
            });
          }
        } else {
          if (Resp.data.Message) {
            toast.success(Resp.data.Message, {
              closeButton: true,
              autoClose: 1500,
              transition: Flip,
            });
          }
          setComments("");
          setAllComments(Resp.data.records);
        }
      }
    }
  };

  const Ipfsimageerr = (e) => {
    if (NFT.NFTDetail.ipfsimage)
      e.target.src = `${config.IPFS_IMG}/${NFT.NFTDetail.ipfsimage}`
  }
  window.addEventListener('storage', async (event) => {
    console.log("zxczxbnxxcbnzbxcbnc", event);
    console.log("Storage Testing", localStorage.getItem('userAddress'));
  })



  const LikeAct = async (data1, data2) => {
    if (Wallet_Details.UserAccountAddr !== "") {
      if (Templike) {

        toast.success("Removed to Liked", {
          closeButton: true,
          autoClose: 1500,
          transition: Flip,
        });
      }
      else {
        toast.success("Added from Liked", {
          closeButton: true,
          autoClose: 1500,
          transition: Flip,
        });
      }
      // console.log("dtatatatata", HomeCategoryData.dataA);
      var Payload = {
        tokenID: data1,
        tokenName: data2,
        curraddress: Wallet_Details.UserAccountAddr,
      };
      var resp = await LikeAction(Payload);
      console.log("as knaskjsak", resp);
      if (resp.data.Success) {
        if (resp.data.Message == "Removed from Liked") {
          setTempCount(TempCount - 1);
          setTemplike(false);
        } else {
          setTempCount(TempCount + 1);
          setTemplike(true);
        }
      } else
        toast.warning(resp.data.Message, {
          closeButton: true,
          autoClose: 1500,
          transition: Flip,
        });
    } else history.push("/connect-wallet");
  };

  const VotingEntryDetails = async (details) => {
    if (Wallet_Details.UserAccountAddr) {
      let SendData = {
        UniqueId: details.UniqueId,
        WalletAddress: Wallet_Details.UserAccountAddr
      }
      const ResponseData = await VotingEntry(SendData)
      console.log("ResponseData", ResponseData);
      if (ResponseData.data.Success) {
        toast.success(ResponseData.data.message)
        var Payload = {
          curAddr: Wallet_Details.UserAccountAddr,
          tokenID: tokenID,
          queryaddress: owneraddr,
        };
        setshowingLoader(true)
        console.log("wqqweqweqweqweqwe", Payload);
        await NFTInfoDetails(Payload);
        setshowingLoader(false)
      } else {
        toast.error(ResponseData.data.message)
      }
    }
  }



  return (

    <div className="home_header info">

      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={
          <Link to="/home">
            <div className="header_logo_align">


            </div>
          </Link>
        }
        leftLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 20,
          color: "white",
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div
        className={
          classes.pageHeader + " inner_pageheader info_header inner_pagh_bg_2"
        }
      >
        <div className="container container_full mb-5">
          {/* info row */}
          <div className="row info_row mx-0 buyer_div">
            <div className="col-12 col-lg-6" id="image_div">
              <div className="">
                <div className="img_info_rad" data-toggle="modal" data-target="#infoimg_modal">
                  {NFT &&
                    NFT.NFTDetail &&
                    NFT.NFTDetail.image &&
                    (NFT.NFTDetail.image.split(".").pop() == "mp4" ? (
                      <video
                        id="my-video"
                        className="img-fluid"
                        autoPlay
                        playsInline
                        loop
                        muted
                        controls disablePictureInPicture controlsList="nodownload"
                        preload="auto"
                        onError={(e) => Ipfsimageerr(e)}
                      >
                        <source src={NFTImage} type="video/mp4" />
                      </video>
                    ) : (
                      <img
                        src={NFTImage}
                        onError={(e) => Ipfsimageerr(e)}
                        alt="Collections"
                      />
                    ))}
                </div>
                <div className="img_des" id="img_des">
                  <p className="info_title">{NFT.NFTDetail.tokenName}</p>
                  <h3 className="info_h3">
                    by
                    <span className="px-2">
                      {NFT.CurrentOwner.CurrentOwner &&
                        NFT.CurrentOwner.CurrentOwner.name
                        ? NFT.CurrentOwner.CurrentOwner.name
                        : NFT.CurrentOwner.CurrentOwner.curraddress}
                    </span>
                    on
                    <span className="pl-2">Lazyminter</span>
                  </h3>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6 bg_pink" id="detai_div">
              <div className="pt-4">
                <div className="d-flex justify-content-between align-items-center d-flex_blk_cu">
                  <div>
                    <div className="faHeartflex infoheart">
                      <div className="d-flex align-items-center justify-content-center" onClick={() => {
                        LikeAct(NFT.NFTDetail.tokenID, NFT.NFTDetail.tokenName);
                      }}>
                        {Templike ? (
                          <i className="far fa-heart liked2"></i>
                        ) : (
                          <i className="far fa-heart liked"></i>

                        )}
                        {TempCount}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    {Wallet_Details.UserAccountAddr !==
                      NFT.CurrentOwner.CurrentOwner.curraddress && (
                        <button
                          className="create_btn btn_rect btn_icon_only"
                          data-toggle="modal"
                          data-target={(localStorage.userAddress == Wallet_Details.UserAccountAddr) ? "#report_page_modal" : "#register_modal"}
                          disabled={!Wallet_Details.UserAccountAddr}

                          onClick={() => {
                            if (!localStorage.userAddress || localStorage.userAddress !== Wallet_Details.UserAccountAddr) {
                              window.$("div").removeClass('modal-backdrop fade show')

                              return toast.error("Login with correct Wallet Address and credentials")

                            } else {
                              ReportRef.current.ReportClick(
                                NFT.CurrentOwner,
                                NFT.NFTDetail
                              )
                            }
                          }

                          }
                        >
                          <i className="fas fa-flag pr-2"></i>
                          <span>Report</span>
                        </button>
                      )}
                    {NFT?.NFTDetail?.PublishStatus !== true &&
                      <button
                        className="create_btn btn_rect ml-2 btn_icon_only"
                        data-toggle="modal"
                        data-target="#share_modal"
                        onClick={() =>
                          ShareNowRef.current.ShareSocial_Click(
                            window.location.href,
                            NFT.NFTDetail.tokenName,
                            "info"
                          )
                        }
                      >
                        <i className="fas fa-upload pr-2"></i>
                        <span>Share</span>
                      </button>
                    }
                    <span className="ml-2">
                      {(Wallet_Details.UserAccountAddr ===
                        NFT.CurrentOwner.CurrentOwner.curraddress) && (NFT.CurrentOwner.coinname !== 'CAKE') && NFT?.NFTDetail
                          ?.PublishStatus !== true && NFT.NFTDetail.tokenCategory !== "Web3 IDs" && (
                          <div className="dropdown">
                            <button
                              className="drop-down create_btn btn_rect btn_icon_only"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i
                                className="fa fa-ellipsis-h"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <div
                              className="dropdown-menu dropdown-menu-right menu_more_dd"
                              aria-labelledby="dropdownMenuButton"
                            >
                              {!(
                                new Date(NFT.NFTDetail.auctionstart).getTime() <
                                Date.now() &&
                                new Date(NFT.NFTDetail.auctionend).getTime() >
                                Date.now()
                              ) && (
                                  <div className="dropdown-item">
                                    <span
                                      data-toggle="modal"
                                      data-target={(localStorage.userAddress == Wallet_Details.UserAccountAddr) ? "" : "#register_modal"}
                                      onClick={() => {
                                        if (!localStorage.userAddress || localStorage.userAddress !== Wallet_Details.UserAccountAddr) {
                                          window.$("div").removeClass('modal-backdrop fade show')

                                          return toast.error("Login with correct Wallet Address and credentials")

                                        } else {
                                          PlaceandCancelRef.current.PlaceandCancelClick(
                                            NFT.CurrentOwner,
                                            NFTImage,
                                            NFT.NFTDetail
                                          )
                                        }
                                      }

                                      }
                                    >
                                      Lower Price
                                    </span>
                                  </div>
                                )}
                                {console.log("NFFFFFFfffff",NFT)}
                              {NFT?.CurrentOwner?.balance == NFT?.CurrentOwner.quantity && (NFT.CurrentOwner.tokenCreator == NFT.CurrentOwner.tokenOwner || NFT.CurrentOwner.RandomName == "New")  &&
                              <div className="dropdown-item">
                                <span
                                  data-toggle="modal"
                                  data-target={(localStorage.userAddress == Wallet_Details.UserAccountAddr) ? "" : "#register_modal"}
                                  onClick={() => {
                                    if (!localStorage.userAddress || localStorage.userAddress !== Wallet_Details.UserAccountAddr) {
                                      window.$("div").removeClass('modal-backdrop fade show')

                                      return toast.error("Login with correct Wallet Address and credentials")

                                    } else {
                                      BurnTokenRef.current.BurnTokenClick(
                                        NFT.CurrentOwner,
                                        NFTImage,
                                        NFT.NFTDetail

                                      )
                                    }
                                  }

                                  }
                                >
                                  Burn Token
                                </span>
                              </div> 
                                }

                              {
                                NFT && NFT.CurrentOwner.balance > 0 &&
                                // (((String(NFT.NFTDetail.contractAddress).toLowerCase()) === (String(Wallet_Details.networkConfiguration.singleContract).toLowerCase())) || ((String(NFT.NFTDetail.contractAddress).toLowerCase()) === (String(Wallet_Details.networkConfiguration.multipleContract).toLowerCase()))) &&
                                <div className="dropdown-item">
                                  <span
                                    data-toggle="modal"
                                    data-target={(localStorage.userAddress == Wallet_Details.UserAccountAddr) ? "" : "#register_modal"}
                                    onClick={() => {
                                      if (!localStorage.userAddress || localStorage.userAddress !== Wallet_Details.UserAccountAddr) {
                                        window.$("div").removeClass('modal-backdrop fade show')

                                        return toast.error("Login with correct Wallet Address and credentials")

                                      } else {
                                        TransferNFTRef.current.TransferNFTClick(
                                          NFT.CurrentOwner,
                                          NFTImage,
                                          NFT.NFTDetail
                                        )
                                      }
                                    }

                                    }
                                  >
                                    Transfer Token
                                  </span>
                                </div>
                              }
                            </div>
                          </div>
                        )}
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center pt-1 nft_title">
                  <div>
                    <span className="info_title">
                      {NFT.NFTDetail.tokenName}
                    </span>
                  </div>
                </div>
                <div className="ticketpricelist">
                  <div>
                    {NFT.CurrentOwner.tokenPrice > 0 &&
                      <>
                        <p>Price</p>
                        <h2> {NFT.CurrentOwner.coinname + " " + NFT.CurrentOwner.tokenPrice}</h2>
                      </>
                    }
                  </div>
                  <div>
                    <p>Fractions</p>
                    <h1 className="text-left">{NFT.CurrentOwner.balance}</h1>
                  </div>
                </div>

                {/* {NFT.NFTDetail.auctionend &&
                  new Date(NFT.NFTDetail.auctionstart).getTime() <= Date.now() &&
                  new Date(NFT.NFTDetail.auctionend).getTime() > Date.now() &&
                  <div className="timeleft pr-2 badge badge-outline-blue mt-1">
                    <span>
                      {" "}
                      <i class="far fa-clock mr-1"></i>
                    </span>
                    <span>
                      <Countdown
                        date={new Date(NFT.NFTDetail.auctionend).getTime()}
                        renderer={renderer}
                      ></Countdown>
                    </span>
                  </div>
                } */}
                {NFT?.CurrentOwner?.tokenOwner == Wallet_Details.UserAccountAddr && NFT.NFTDetail.unlockcontent &&
                  <p className="user_name_inf_big mt-4">
                    <ReactMarkdown children={NFT.NFTDetail.unlockcontent} />

                  </p>
                }
                <table class="table table-dark">
                  <tbody>
                    {NFT.NFTDetail.tokenDesc &&
                      <tr>
                        <td>Description :</td>
                        <td>{NFT.NFTDetail.tokenDesc}</td>
                      </tr>
                    }
                    {NFT?.NFTDetail?.tokenProperty &&
                      <tr>
                        <td>Property :</td>
                        <td>{NFT?.NFTDetail?.tokenProperty}</td>
                      </tr>
                    }
                    {NFT.NFTDetail.Location &&
                      <tr>
                        <td>Location :</td>
                        <td>{NFT.NFTDetail.Location}</td>
                      </tr>
                    }
                    {NFT.NFTDetail.Return &&
                      <tr>
                        <td>Return :</td>
                        <td>{NFT.NFTDetail.Return}</td>
                      </tr>
                    }
                    {NFT.NFTDetail.Section &&
                      <tr>
                        <td>Section :</td>
                        <td>{NFT.NFTDetail.Section}</td>
                      </tr>
                    }
                    {NFT.NFTDetail.TokenGating &&
                      <tr>
                        <td>Token Gating Information:</td>
                        <td>{NFT.NFTDetail.TokenGating}</td>
                      </tr>
                    }
                    {NFT.NFTDetail.UrlLink &&
                      <tr>
                        <td>UrlLink :</td>
                        <td><a href={NFT.NFTDetail.UrlLink} target="_blank">{NFT.NFTDetail.UrlLink}</a></td>
                      </tr>
                    }
                    {NFT.NFTDetail.startDateToken &&
                      <tr>
                        <td>Start Date :</td>
                        <td>{NFT.NFTDetail.startDateToken}</td>
                      </tr>
                    }
                    {NFT.NFTDetail.ContactNumber && NFT.NFTDetail.DateChoose !== "NoData" &&
                      <tr>
                        <td>ContactNumber :</td>
                        <td>{NFT.NFTDetail.ContactNumber}</td>
                      </tr>
                    }
                    {NFT.NFTDetail.DateChoose && NFT.NFTDetail.DateChoose !== "NoData" &&
                      <tr>
                        <td>Date :</td>
                        <td>{new Date(NFT.NFTDetail.DateChoose).toLocaleDateString(
                          "en-US",
                          {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          }
                        )}</td>
                      </tr>
                    }
                    {NFT.NFTDetail.ID && NFT.NFTDetail.DateChoose !== "NoData" &&
                      <tr>
                        <td>ID :</td>
                        <td>{NFT.NFTDetail.ID}</td>
                      </tr>
                    }
                    {NFT.NFTDetail.endDateToken &&
                      <tr>
                        <td>End Date :</td>
                        <td>{NFT.NFTDetail.endDateToken}</td>
                      </tr>
                    }

                  </tbody>
                </table>
                <div className="btn_div_info py-2">
                  {Wallet_Details.UserAccountAddr ? (
                    <>
                      {NFT.CurrentOwner.tokenOwner !==
                        Wallet_Details.UserAccountAddr ? (
                        NFT.CurrentOwner.Onsale && NFT?.NFTDetail
                          ?.PublishStatus !== true && NFT.NFTDetail.tokenCategory !== "Web3 IDs" ? (
                          <Button
                            className="create_btn ml-0 mb-2"
                            data-toggle="modal"
                            data-target={(localStorage.userAddress !== Wallet_Details.UserAccountAddr) ? "#register_modal" : ""}
                            onClick={() => {

                              if (!localStorage.userAddress || localStorage.userAddress !== Wallet_Details.UserAccountAddr) {
                                window.$("div").removeClass('modal-backdrop fade show')

                                return toast.error("Login with correct Wallet Address and credentials")

                              } else {
                                PurchaseNowRef.current.PurchaseNowClick(
                                  NFT.CurrentOwner,
                                  NFT.NFTDetail.tokenName,
                                  NFT.NFTDetail              // added for Lazymint

                                )
                              }

                            }

                            }
                          >
                            Buy Now
                          </Button>
                        ) : (
                          <>
                            {(NFT.Mybid && NFT.Mybid.length !== 0 && (NFT.NFTDetail.auctionstart && new Date(
                              NFT.NFTDetail.auctionend
                            ).getTime() < Date.now())) ? (
                              // <Button
                              //   className="create_btn ml-0 mb-2"
                              //   data-toggle="modal"
                              // //data-target="#place_bid_multiple_modal"


                              // >
                              //   Timed Auction Ended
                              // </Button>
                              <></>
                            ) :
                              (NFT.Mybid && NFT.Mybid.length !== 0) ? (
                                <Button
                                  className="create_btn ml-0 mb-2"
                                  data-toggle="modal"
                                  data-target={(localStorage.userAddress == Wallet_Details.UserAccountAddr) ? "#place_bid_multiple_modal" : "#register_modal"}
                                  onClick={() => {
                                    if (!localStorage.userAddress || localStorage.userAddress !== Wallet_Details.UserAccountAddr) {
                                      window.$("div").removeClass('modal-backdrop fade show')

                                      return toast.error("Login with correct Wallet Address and credentials")

                                    } else {
                                      BidActionsRef.current.PlaceandAcceptBidClick(
                                        NFT,
                                        NFT.NFTDetail,

                                      )
                                    }
                                  }

                                  }
                                >
                                  Edit Offer
                                </Button>
                                // <></>
                              ) : (
                                <>
                                  {new Date(
                                    NFT.NFTDetail.auctionstart
                                  ).getTime() > Date.now() ? (
                                    // <Button
                                    //   className="create_btn ml-0 mb-2"
                                    //   data-toggle="modal"
                                    //   disabled
                                    //   data-target="#place_bid_multiple_modal"
                                    // >{`Starts in ${timediff(
                                    //   NFT.NFTDetail.auctionstart
                                    // )} Days`}</Button>
                                    <></>
                                  ) :
                                    <>
                                      {(NFT.NFTDetail.auctionstart && new Date(
                                        NFT.NFTDetail.auctionend
                                      ).getTime() < Date.now()) ? (
                                        // <Button
                                        //   className="create_btn ml-0 mb-2"
                                        //   data-toggle="modal"
                                        // //data-target="#place_bid_multiple_modal"

                                        // >
                                        //   Timed Auction Ended
                                        // </Button>
                                        <></>
                                      ) : (NFT?.NFTDetail
                                        ?.PublishStatus !== true && NFT.NFTDetail.tokenCategory !== "Web3 IDs" &&
                                        <Button
                                          className="create_btn ml-0 mb-2"
                                          data-toggle="modal"
                                          data-target={(localStorage.userAddress == Wallet_Details.UserAccountAddr) ? "" : "#register_modal"}
                                          onClick={() => {
                                            if (!localStorage.userAddress || localStorage.userAddress !== Wallet_Details.UserAccountAddr) {
                                              window.$("div").removeClass('modal-backdrop fade show')

                                              return toast.error("Login with correct Wallet Address and credentials")

                                            } else {
                                              BidActionsRef.current.PlaceandAcceptBidClick(
                                                NFT,
                                                NFT.NFTDetail,
                                              )
                                            }
                                          }

                                          }
                                        >
                                          Make Offer
                                        </Button>
                                        // <></>
                                      )}
                                    </>

                                  }
                                </>
                              )}
                          </>
                        )
                      ) : NFT.CurrentOwner.Onsale ? (NFT?.NFTDetail
                        ?.PublishStatus !== true && NFT.NFTDetail.tokenCategory !== "Web3 IDs" &&
                        <Button
                          className="create_btn ml-0 mb-2"
                          data-toggle="modal"
                          data-target={(localStorage.userAddress == Wallet_Details.UserAccountAddr) ? "" : "#register_modal"}
                          onClick={() => {
                            if (!localStorage.userAddress || localStorage.userAddress !== Wallet_Details.UserAccountAddr) {
                              window.$("div").removeClass('modal-backdrop fade show')

                              return toast.error("Login with correct Wallet Address and credentials")

                            } else {
                              PlaceandCancelRef.current.PlaceandCancelClick(
                                NFT.CurrentOwner,
                                NFTImage,
                                NFT.NFTDetail, 'Cancel'
                              )
                            }
                          }

                          }
                        >
                          Cancel Order
                        </Button>
                      ) :
                        new Date(NFT.NFTDetail.auctionstart).getTime() <
                          Date.now() &&
                          new Date(NFT.NFTDetail.auctionend).getTime() >
                          Date.now() ? (
                          <Button
                            className="create_btn ml-0 mb-2"
                            data-toggle="modal"
                            disabled
                            data-target="#place_bid_multiple_modal"
                          >{`Auction Ends in ${timediff(
                            NFT.NFTDetail.auctionend
                          )} Days`}</Button>
                        ) :

                          (NFT?.NFTDetail
                            ?.PublishStatus !== true && NFT.NFTDetail.tokenCategory !== "Web3 IDs" &&

                            <Button
                              className="create_btn ml-0 mb-2"
                              data-toggle="modal"
                              data-target={(localStorage.userAddress == Wallet_Details.UserAccountAddr) ? "" : "#register_modal"}
                              onClick={() => {
                                if (!localStorage.userAddress || localStorage.userAddress !== Wallet_Details.UserAccountAddr) {
                                  window.$("div").removeClass('modal-backdrop fade show')

                                  return toast.error("Login with correct Wallet Address and credentials")

                                } else {
                                  PlaceandCancelRef.current.PlaceandCancelClick(
                                    NFT.CurrentOwner,
                                    NFTImage,
                                    NFT.NFTDetail
                                  )
                                }
                              }

                              }
                            >
                              Put On Sale
                            </Button>
                          )}
                    </>
                  ) : (
                    // <Link to="/connect-wallet">
                    //   <Button className="create_btn ml-0 mb-2">
                    //     Connect Wallet
                    //   </Button>
                    // </Link>
                    <></>
                  )}
                  {console.log("sdfdsffafa", NFT.NFTDetail, NFT.NFTDetail.PublishStatus)}
                  {NFT.CurrentOwner.tokenOwner !== Wallet_Details.UserAccountAddr && NFT.NFTDetail.PublishStatus == true && NFT.NFTDetail.tokenCategory !== "Web3 IDs" ?
                    // <Button
                    //   className="create_btn ml-0 mb-2"
                    //   data-toggle="modal"
                    //   data-target={(localStorage.userAddress == Wallet_Details.UserAccountAddr) ? "" : "#register_modal"}
                    //   onClick={() => {
                    //     if (!localStorage.userAddress || localStorage.userAddress !== Wallet_Details.UserAccountAddr) {
                    //       window.$("div").removeClass('modal-backdrop fade show')

                    //       return toast.error("Login with correct Wallet Address and credentials")

                    //     } else {
                    //       { console.log("Button Clicked") }
                    //       PurchaseNowRef.current.PurchaseNowClick(
                    //         NFT.CurrentOwner,
                    //         NFT.NFTDetail.tokenName,
                    //         NFT.NFTDetail,
                    //         NFT.NFTDetail.PublishStatus,
                    //         NFT.NFTDetail.EventName          // added for Lazymint
                    //       )
                    //     }
                    //   }

                    //   }
                    // >
                    //   Claim
                    // </Button>
                    <></>
                    :
                    <>
                      {NFT.NFTDetail.PublishStatus == true &&
                        // <Button
                        //   className="create_btn ml-0 mb-2"
                        //   data-toggle="modal"
                        //   data-target={(localStorage.userAddress == Wallet_Details.UserAccountAddr) ? "" : "#register_modal"}
                        //   onClick={() => { Cookiee() }}
                        // >
                        //   Listed For Event
                        // </Button>
                        <></>
                      }
                    </>
                  }
                  {console.log("adasasdasdasd", Wallet_Details, localStorage.loggedinaddress, Wallet_Details?.AddressUserDetails?.signemail, localStorage.loggedinaddress)}
                  {(NFT.NFTDetail.tokenCategory == "Web3 IDs") && (NFT.NFTDetail.hashValue == "not yet minted") && (NFT?.NFTDetail?.mail == localStorage.loggedinaddress) ?
                    // <Button
                    //   className="create_btn ml-0 mb-2"
                    //   data-toggle="modal"
                    //   data-target={(localStorage.userAddress == Wallet_Details.UserAccountAddr) ? "" : "#register_modal"}
                    //   onClick={() => {
                    //     if (!localStorage.userAddress || localStorage.userAddress !== Wallet_Details.UserAccountAddr) {
                    //       window.$("div").removeClass('modal-backdrop fade show')

                    //       return toast.error("Login with correct Wallet Address and credentials")

                    //     } else {
                    //       { console.log("Button Clicked") }
                    //       PurchaseNowRef.current.PurchaseNowClick(
                    //         NFT.CurrentOwner,
                    //         NFT.NFTDetail.tokenName,
                    //         NFT.NFTDetail,
                    //       )
                    //     }
                    //   }

                    //   }
                    // >
                    //   Claim
                    // </Button>
                    <></>
                    :
                    <>
                      {NFT.CurrentOwner.tokenOwner == Wallet_Details.UserAccountAddr && NFT.NFTDetail.tokenCategory == "Web3 IDs" && (NFT.NFTDetail.hashValue !== "not yet minted") &&
                        <Button
                          className="create_btn ml-0 mb-2"
                          data-toggle="modal"
                          data-target={(localStorage.userAddress == Wallet_Details.UserAccountAddr) ? "" : "#register_modal"}
                          onClick={() => {
                            if (!localStorage.userAddress || localStorage.userAddress !== Wallet_Details.UserAccountAddr) {
                              window.$("div").removeClass('modal-backdrop fade show')

                              return toast.error("Login with correct Wallet Address and credentials")

                            } else {
                              TransferNFTRef.current.TransferNFTClick(
                                NFT.CurrentOwner,
                                NFTImage,
                                NFT.NFTDetail
                              )
                            }
                          }

                          }
                        >
                          Tranfer
                        </Button>
                      }
                    </>
                  }
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-7 pr-lg-0 ">
              <nav className="masonry_tab_nav items_tab_outer info_tab_ouert mt-4 mb-3">
                <div
                  className="nav nav-tabs masonry_tab primary_tab items_tab d-block items_tab_new pb-0 pl-0"
                  id="nav-tab"
                  role="tablist"
                >
                  <a
                    className="nav-link active"
                    id="history-tab"
                    data-toggle="tab"
                    href="#history"
                    role="tab"
                    aria-controls="history"
                    aria-selected="false"
                  >
                    <div className="tab_head p-2">History</div>
                  </a>
                  {console.log("asddasasd", NFT)}
                  {NFT.NFTDetail.tokenCategory !== "Web3 IDs" &&
                    <a
                      className="nav-link"
                      id="bid-tab"
                      data-toggle="tab"
                      href="#bid"
                      role="tab"
                      aria-controls="bid"
                      aria-selected="false"
                    >
                      <div className="tab_head p-2">Offers</div>
                    </a>}
                  <a
                    className="nav-link"
                    id="details-tab"
                    data-toggle="tab"
                    href="#details"
                    role="tab"
                    aria-controls="details"
                    aria-selected="true"
                  >
                    <div className="tab_head p-2">Details</div>
                  </a>
                  <a
                    className="nav-link"
                    id="owners-tab"
                    data-toggle="tab"
                    href="#owners"
                    role="tab"
                    aria-controls="active"
                    aria-selected="false"
                  >
                    <div className="tab_head p-2">Owners</div>
                  </a>
                  <a
                    className="nav-link"
                    id="Vote-tab"
                    data-toggle="tab"
                    href="#Vote"
                    role="tab"
                    aria-controls="active"
                    aria-selected="false"
                  >
                    <div className="tab_head p-2">DAO Voting</div>
                  </a>
                </div>
              </nav>
              <div
                className="tab-content explore_tab_content mt-4 pr-lg-3 pb-5"
                id="nav-tabContent"
              >
                <div
                  className="tab-pane fade show active"
                  id="history"
                  role="tabpanel"
                  aria-labelledby="history-tab"
                >
                  <div className="proposal_panel_overall">
                    <div className="">
                      <Scrollbars
                        autoHeight
                        autoHeightMax={350}
                        renderThumbVertical={(props) => (
                          <div {...props} className="thumb-vertical d-none" />
                        )}
                        renderThumbHorizontal={(props) => (
                          <div {...props} className="thumb-horizontal d-none" />
                        )}
                      >
                        {NFT.History.length > 0 &&
                          NFT.History.map((His) => {
                            return (
                              <div className="card mt-0 mb-3 acti_card rounded-corners-gradient-borders">
                                <Link
                                  to={
                                    !His.HistoryOwners.customurl
                                      ? `/my-items/${His.HistoryOwners.curraddress}`
                                      : `/my-items/user/${His.HistoryOwners.customurl}`
                                  }
                                >
                                  <div className="card-body px-3">
                                    <div className="media follow_media icon_img">
                                      <div className="icon-img-div">
                                        <div className="img_media_new  mr-3">
                                          <img
                                            src={
                                              His.HistoryOwners.image
                                                ? `${config.Back_URL}/Users/${His.HistoryOwners.PrimaryAddr}/profileImg/${His.HistoryOwners.image}`
                                                : require("../../assets/images/lazymintingnew/logo.png")
                                            }
                                            alt="User"
                                            className="img-fluid"
                                          />
                                        </div>
                                      </div>
                                      <div className="media-body flex_body">
                                        <div>
                                          <p className="mb-2 media_num">
                                            {His.balance !== 0
                                              ? `${His.HistoryOwners.name
                                                ? His?.HistoryOwners?.name
                                                : His?.HistoryOwners?.curraddress
                                                  ?.slice(0, 15)
                                              }
                                  Owns ${His.balance} from ${new Date(
                                                His.timestamp
                                              ).toLocaleDateString("en-US", {
                                                day: "numeric",
                                                month: "long",
                                                year: "numeric",
                                              })}`
                                              : `${His?.HistoryOwners?.name
                                                ? His?.HistoryOwners?.name
                                                : His?.HistoryOwners?.curraddress
                                                  ?.slice(0, 15)
                                              }
                                  Owned ${His.quantity} on ${new Date(
                                                His.timestamp
                                              ).toLocaleDateString("en-US", {
                                                day: "numeric",
                                                month: "long",
                                                year: "numeric",
                                              })}`}
                                          </p>
                                          <p className="media_num mb-0">
                                            @
                                            <span className="media_text">
                                              {NFT?.NFTDetail?.tokenName?.slice(0, 15)}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            );
                          })}
                      </Scrollbars>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="bid"
                  role="tabpanel"
                  aria-labelledby="bid-tab"
                >
                  <div className="proposal_panel_overall">
                    <div className="">
                      <Scrollbars
                        autoHeight
                        autoHeightMax={350}
                        renderThumbVertical={(props) => (
                          <div {...props} className="thumb-vertical d-none" />
                        )}
                        renderThumbHorizontal={(props) => (
                          <div {...props} className="thumb-horizontal d-none" />
                        )}
                      >
                        {NFT.AllBids.length > 0 &&
                          NFT.AllBids.map((Bids, index) => {
                            return (
                              <div className="card mt-0 mb-3 acti_card rounded-corners-gradient-borders">
                                <div className="card-body px-3">
                                  <div className="media follow_media icon_img flex_mob_blck">
                                    <Link
                                      to={
                                        !Bids.Bidderdet.customurl
                                          ? `/my-items/${Bids.Bidderdet.curraddress}`
                                          : `/my-items/user/${Bids.Bidderdet.customurl}`
                                      }
                                    >
                                      <div className="icon-img-div">
                                        <div className="img_media_new  mr-3">
                                          <img
                                            src={
                                              Bids.Bidderdet.image
                                                ? `${config.Back_URL}/Users/${Bids.Bidderdet.PrimaryAddr}/profileImg/${Bids.Bidderdet.image}`
                                                : require("../../assets/images/lazymintingnew/logo.png")
                                            }
                                            alt="User"
                                            className="img-fluid"
                                          />
                                        </div>
                                      </div>
                                    </Link>
                                    <div className="media-body flex_body flex_mob_blck">
                                      <Link
                                        to={
                                          !Bids.Bidderdet.customurl
                                            ? `/my-items/${Bids.Bidderdet.curraddress}`
                                            : `/my-items/user/${Bids.Bidderdet.customurl}`
                                        }
                                      >
                                        <div>
                                          <p className="mb-2 media_num">
                                            Placed a Bid on {Bids.pending}{" "}
                                            edition Each for {Bids.tokenBidAmt}{" "}
                                            {Bids.coinname}
                                          </p>
                                          <p className="media_num mb-0">
                                            by @
                                            <span className="media_text">
                                              {Bids.Bidderdet.name
                                                ? Bids.Bidderdet.name
                                                : Bids.Bidderdet.curraddress
                                                  .slice(0, 15)}
                                            </span>
                                          </p>
                                        </div>
                                      </Link>
                                      <>
                                        {
                                          <div className="d-flex">
                                            {Wallet_Details.UserAccountAddr ===
                                              NFT.CurrentOwner.CurrentOwner
                                                .curraddress &&
                                              (NFT.NFTDetail.auctionend
                                                ? new Date(
                                                  NFT.NFTDetail.auctionend
                                                ).getTime() < Date.now()
                                                : true) && (
                                                <Button
                                                  className="create_btn mb-2 mr-2"
                                                  data-toggle="modal"
                                                  data-target={(localStorage.userAddress == Wallet_Details.UserAccountAddr) ? "" : "#register_modal"}
                                                  disabled={
                                                    !Wallet_Details.UserAccountAddr
                                                  }
                                                  onClick={() => {
                                                    if (!localStorage.userAddress || localStorage.userAddress !== Wallet_Details.UserAccountAddr) {
                                                      window.$("div").removeClass('modal-backdrop fade show')
                                                      return toast.error("Login with correct Wallet Address and credentials")
                                                    } else {
                                                      BidActionsRef.current.PlaceandAcceptBidClick(
                                                        NFT,
                                                        NFT.NFTDetail,
                                                        Bids,
                                                        'accept'
                                                      )
                                                    }
                                                  }

                                                  }
                                                >
                                                  Accept
                                                </Button>
                                              )}
                                            {NFT.Mybid.length > 0 &&
                                              Wallet_Details.UserAccountAddr ===
                                              Bids.Bidderdet
                                                .curraddress && (
                                                <Button
                                                  className="create_btn bg-white mb-2"
                                                  data-toggle="modal"
                                                  data-target={(localStorage.userAddress == Wallet_Details.UserAccountAddr) ? "" : "#register_modal"}
                                                  onClick={() => {
                                                    if (!localStorage.userAddress || localStorage.userAddress !== Wallet_Details.UserAccountAddr) {
                                                      window.$("div").removeClass('modal-backdrop fade show')

                                                      return toast.error("Login with correct Wallet Address and credentials")

                                                    } else { CancelBid(Bids); }

                                                  }}
                                                >
                                                  Cancel
                                                </Button>
                                              )}
                                          </div>
                                        }
                                      </>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </Scrollbars>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="details"
                  role="tabpanel"
                  aria-labelledby="details-tab"
                >
                  <div className="proposal_panel_overall">
                    <div className=" mt-0 mb-0">
                      <div className="card-body pr-3 pb-0">
                        <p className="text_sm_owner">Creator</p>
                        <Link
                          to={
                            !NFT.NFTCreator.customurl
                              ? `/my-items/${NFT.NFTCreator.curraddress}`
                              : `/my-items/user/${NFT.NFTCreator.customurl}`
                          }
                        >
                          <div className="media follow_media icon_img">
                            <div className="icon-img-div">
                              <div className="img_media_new  mr-3">
                                <img
                                  src={
                                    NFT.NFTCreator.image
                                      ? `${config.Back_URL}/Users/${NFT.NFTCreator.PrimaryAddr}/profileImg/${NFT.NFTCreator.image}`
                                      : require("../../assets/images/lazymintingnew/logo.png")
                                  }
                                  alt="User"
                                  className="img-fluid"
                                />
                                {/* <img
                                            src={require("../assets/images/notable_img.png")}
                                            alt="User"
                                            className="img-fluid"
                                          /> */}
                              </div>
                            </div>
                            <div className="media-body flex_body">
                              <div>
                                <p className="media_num mb-0">
                                  <span className="media_text">
                                    {NFT.NFTCreator.name
                                      ? NFT.NFTCreator.name
                                      : NFT.NFTCreator.curraddress &&
                                      NFT.NFTCreator.curraddress
                                        .slice(0, 25)}
                                  </span>
                                </p>
                                <p className="media_num mb-0">
                                  @
                                  <span className="MYmedia_text">
                                    {NFT.NFTCreator.customurl
                                      ? NFT.NFTCreator.customurl
                                      : "LazyminterArtist"}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>
                        <p className="MYtext_big_owner mt-0">
                          {NFT.NFTDetail.tokenRoyality}% - Royalty to Creator{" "}
                        </p>
                        <p className="text_big_owner mt-3">Year Created : <span>{new Date(NFT.NFTDetail.timestamp).toLocaleDateString(
                          "en-US",
                          {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          }
                        )}</span></p>
                        {/* {console.log("asdsadas", NFT.NFTDetail.SelectedNetwork)} */}
                        <p className="text_big_owner mt-3 contractadd">Contract Address : <span className="ml-2"><a className="text-white" target="_blank" href={String(NFT.NFTDetail.SelectedNetwork) == String(config.MATIC_ID) ? `${config.ScannerPolygon}address/${NFT.NFTDetail.contractAddress}` : String(NFT.NFTDetail.SelectedNetwork) == String(config.CAM_ID) ? `${config.ScannerCamino}address/${NFT.NFTDetail.contractAddress}` : `${config.ScannerBinance}address/${NFT.NFTDetail.contractAddress}`}
                        // {`${Wallet_Details.networkConfiguration.Bscscan}address/${NFT.NFTDetail.contractAddress}`}
                        >
                          <p className="text-truncate">{NFT.NFTDetail.contractAddress}</p>
                        </a></span></p>
                        <p className="text_big_owner mt-3 contractadd">ERC20 Address : <span className="ml-2"><a className="text-white" target="_blank" href={`${Wallet_Details.networkConfiguration.Bscscan}address/${NFT.NFTDetail.ERC20_Address}`}>
                          <p className="text-truncate">{NFT.NFTDetail.ERC20_Address}</p>
                        </a></span></p>
                        <p className="text_big_owner mt-3">Token ID : <span>{NFT.NFTDetail.tokenID}</span></p>
                        <p className="text_big_owner mt-3">Token Standard : <span>ERC-{NFT.NFTDetail.type}</span></p>
                        <p className="text_big_owner mt-3">Blockchain : <span>{String(NFT.NFTDetail.SelectedNetwork) == String(config.MATIC_ID) ? config.Network_MAT_Cap + " Mainnet" : String(NFT.NFTDetail.SelectedNetwork) == String(config.CAM_ID) ? config.Network_CAM_Cap : config.Network_ETH_Cap + " Mainnet"}</span></p>
                        <div className="contarct_details">


                          <div>
                            <p>
                              {/* {console.log("ndfwenfjwnefwd",Wallet_Details.networkConfiguration.Bscscan,NFT.NFTDetail.hashValue)} */}
                              {NFT.NFTDetail.hashValue !== 'not yet minted' && Wallet_Details.networkConfiguration.Bscscan &&
                                <a
                                  href={String(NFT.NFTDetail.SelectedNetwork) == String(config.MATIC_ID) ? `${config.ScannerPolygon}tx/${NFT.NFTDetail.hashValue}` : String(NFT.NFTDetail.SelectedNetwork) == String(config.CAM_ID) ? `${config.ScannerCamino}tx/${NFT.NFTDetail.hashValue}` : `${config.ScannerBinance}tx/${NFT.NFTDetail.hashValue}`}
                                  //{`${Wallet_Details.networkConfiguration.Bscscan}tx/${NFT.NFTDetail.hashValue}`}
                                  target="_blank"
                                  className="social_a_icon pb-3"
                                >
                                  <span className="pl-0">View on Etherscan</span>
                                  <img
                                    src={require("../../assets/images/expand_icon.png")}
                                    className="img-fluid pl-2 expand_icon"
                                  />
                                </a>}

                            </p>
                          </div>
                          <div>
                            <p>
                              <a
                                href={`${config.IPFS_IMG}/${NFT.NFTDetail.ipfsimage}`}
                                target="_blank"
                                className="social_a_icon"
                              >
                                <span className="pl-0">View on IPFS</span>
                                <img
                                  src={require("../../assets/images/expand_icon.png")}
                                  className="img-fluid pl-2 expand_icon"
                                />
                              </a></p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="owners"
                  role="tabpanel"
                  aria-labelledby="owners-tab"
                >
                  <div className="proposal_panel_overall">
                    <div className="">
                      <Scrollbars
                        autoHeight
                        autoHeightMax={350}
                        renderThumbVertical={(props) => (
                          <div {...props} className="thumb-vertical d-none" />
                        )}
                        renderThumbHorizontal={(props) => (
                          <div {...props} className="thumb-horizontal d-none" />
                        )}
                      >
                        {NFT.NFTOwners.length > 0 &&
                          NFT.NFTOwners.map((Owns) => {
                            return (
                              <div className="card mt-0 mb-3 acti_card rounded-corners-gradient-borders">
                                <Link
                                  to={
                                    !Owns.OwnerDetail.customurl
                                      ? `/my-items/${Owns.OwnerDetail.curraddress}`
                                      : `/my-items/user/${Owns.OwnerDetail.customurl}`
                                  }
                                >
                                  <div className="card-body px-3">
                                    <div className="media follow_media icon_img">
                                      <div className="icon-img-div">
                                        <div className="img_media_new  mr-3">
                                          <img
                                            src={
                                              Owns.OwnerDetail.image
                                                ? `${config.Back_URL}/Users/${Owns.OwnerDetail.PrimaryAddr}/profileImg/${Owns.OwnerDetail.image}`
                                                : require("../../assets/images/lazymintingnew/logo.png")
                                            }
                                            alt="User"
                                            className="img-fluid"
                                          />
                                          {/* <img
                                            src={require("../assets/images/notable_img.png")}
                                            alt="User"
                                            className="img-fluid"
                                          /> */}
                                        </div>
                                      </div>
                                      <div className="media-body flex_body flex_mob_blck">
                                        <div>
                                          {console.log("OwnsOwns",Owns)}
                                          <p className="mb-0 media_num">
                                            Owner of {Owns.balance} edition{" "}
                                            {Owns.tokenPrice > 0 &&
                                              ` Each OnSale for ${Owns.tokenPrice} ${NFT?.CurrentOwner?.coinname}`}
                                          </p>
                                          <p className="media_num mt-0 mb-0">
                                            @
                                            <span className="media_text">
                                              {Owns?.OwnerDetail?.name
                                                ? Owns?.OwnerDetail?.name
                                                : Owns?.OwnerDetail?.curraddress
                                                  ?.slice(0, 15)}
                                            </span>
                                          </p>
                                          <p className="media_num mb-0">
                                            @
                                            <span className="MYmedia_text">
                                              {Owns.OwnerDetail.customurl
                                                ? Owns.OwnerDetail.customurl
                                                : "LazyminterArt"}
                                            </span>
                                          </p>
                                        </div>
                                        {Owns.Onsale &&
                                          Owns.OwnerDetail.curraddress !==
                                          Wallet_Details.UserAccountAddr && (
                                            <Button
                                              className="create_btn ml-0 mb-2"
                                              data-toggle="modal"
                                              data-target={(localStorage.userAddress == Wallet_Details.UserAccountAddr) ? "" : "#register_modal"}
                                              onClick={() => {
                                                if (!localStorage.userAddress || localStorage.userAddress !== Wallet_Details.UserAccountAddr) {
                                                  window.$("div").removeClass('modal-backdrop fade show')

                                                  return toast.error("Login with correct Wallet Address and credentials")

                                                } else {
                                                  PurchaseNowRef.current.PurchaseNowClick(
                                                    Owns,
                                                    NFT.NFTDetail.tokenName,
                                                    NFT.NFTDetail              // added for Lazymint
                                                  )
                                                }
                                              }

                                              }
                                            >
                                              Buy Now
                                            </Button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            );
                          })}
                      </Scrollbars>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="Vote"
                  role="tabpanel"
                  aria-labelledby="Vote-tab"
                >
                  <div className="proposal_panel_overall">
                    <div className="w-100">
                      {/* <Scrollbars
                      className="w-100"
                      width={100}
                        autoHeight
                        autoHeightMax={350}
                        renderThumbVertical={(props) => (
                          <div {...props} className="thumb-vertical d-none" />
                        )}
                        renderThumbHorizontal={(props) => (
                          <div {...props} className="thumb-horizontal d-none" />
                        )}
                      > */}
                      {console.log("NFTNFTNFTNFTNFTNFTNFTNFT", NFT)}
                      {
                        //NFT.CurrentOwner.tokenOwner == Wallet_Details.UserAccountAddr &&
                        NFT?.VoteDetails?.length > 0 &&
                        NFT?.VoteDetails?.map((Data) => {
                          
                          return (
                            <>
                        {console.log("ASdasdasdasdasda",Data)}
                        <>
                           {((Data.VotingStartedDate && new Date(
                                    Data.VotingStartedDate 
                                  ).getTime() < Date.now())) && ((Data.VotingEndDate && new Date(
                                    Data.VotingEndDate 
                                  ).getTime() !== Date.now())) && Data.AdditionalStatus && 
                            (

                              <div className="card mt-0 mb-3 acti_card rounded-corners-gradient-borders">
                                <div className="card-body px-3">
                                  <div className="media follow_media icon_img">
                                    <div className="icon-img-div">
                                    </div>
                                    <div className="media-body flex_body flex_mob_blck">
                                      {/* <div className="d-flex"> */}

                                      <div className="ww_d">
                                        <h4 className="whtclrss mb-1">{Data?.ShortName.slice(0, 100)}</h4>
                                        {/* <h5>Description:</h5> */}
     
                                        <p className="vote_text whtclrss">{Data?.Description?.slice(0, 130)}</p>
                             
                  <div className="pr-2 badge badge-outline-blue mt-1">
                    <span>
                      {" "}
                      <i class="far fa-clock mr-1"></i>
                    </span>
                    <span>
                    <Countdown
                                       // className="whtclrss"
                                          date={new Date(Data.VotingEndDate).getTime()}
                                          renderer={renderers}
                                        />
          
                      {/* 03h 56mm 06s left */}
                    </span>
                  </div>
                                      <p className="whtclrss">{`Voter Count: ${Data.VoteAgreed}`}</p>

                                      </div>
                                      <div className="text-end mb-0">
                                        {/* <AiOutlineLike className="set_icon"/>
                                            <AiOutlineDislike className="set_icon"/> */}
                                        {Data?.VoterList?.length > 0 ?
                                          <>
                                            {Data?.VoterList.length > 0 && Data?.VoterList.map((ite) => {
                                              return (
                                                <>
                                                  {ite.WalletAddress == Wallet_Details.UserAccountAddr ?
                                                    <button className="btn create_btn mb_btn" onClick={() => { VotingEntryDetails(Data) }}>Reject</button>
                                                    :
                                                    <button className="btn create_btn mb_btn" onClick={() => { VotingEntryDetails(Data) }}>Accept</button>
                                                  }
                                                </>
                                              )
                                            }
                                            )}

                                          </>
                                          :
                                          <button className="btn create_btn" onClick={() => { VotingEntryDetails(Data) }}>Accept</button>
                                        }
                                        <Link to={{ pathname: "/votedetail", state: Data, location: NFT.NFTDetail.balance }}>
                                          <button className="btn create_btn ml-2">View</button>
                                        </Link>
                                      </div>
                                    </div>
                                    {/* </div> */}
                                  </div>
                                </div>
                              </div>
                            )}
                            </>
                            </>
                          );
                        })}
                      {/* </Scrollbars> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-5 bor_top_blue">
              <p className="my_items_head">{AllComments.length} Comments</p>
              <Scrollbars
                autoHeight
                autoHeightMax={220}
                className="nto_scrol_div"
              >
                {AllComments &&
                  AllComments.length > 0 &&
                  AllComments.map((comm) => {
                    return (
                      <div className="comment_sec_inout">
                        <Link
                          to={
                            comm.commentors.customurl
                              ? `/my-items/user/${comm.commentors.customurl}`
                              : `/my-items/${comm.commentors.curraddress}`
                          }
                        >
                          <div className="img_media_new  mr-1">
                            <img
                              src={
                                comm.commentors && comm.commentors.image
                                  ? `${config.Back_URL}/Users/${comm.commentors.PrimaryAddr}/profileImg/${comm.commentors.image}`
                                  : require("../../assets/images/user_comment.png")
                              }
                              alt="Collections"
                              className="img-fluid"
                            />
                          </div>
                        </Link>
                        <div className="w-100 ml-2">
                          <p className="my_items_head_nim">
                            {comm?.commentors && comm?.commentors?.name
                              ? comm?.commentors?.name
                              : comm?.commentors?.curraddress
                                ?.slice(0, 10)}
                          </p>
                          <div className="input-group mb-2 inut_gro_stke_1 inut_gro_stke_2">
                            <input
                              type="text"
                              disabled
                              className="form-control"
                              value={comm.comment}
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                            />
                            {Wallet_Details.UserAccountAddr ===
                              comm.commentors.curraddress && (
                                <div className="input-group-append p-2">
                                  <span
                                    className="input-group-text pr-2"
                                    id="basic-addon2"
                                  ></span>
                                  <span
                                    data-toggle="modal"
                                    data-target={(localStorage.userAddress == Wallet_Details.UserAccountAddr) ? "" : "#register_modal"}
                                    onClick={() => {
                                      if (!localStorage.userAddress || localStorage.userAddress !== Wallet_Details.UserAccountAddr) {
                                        window.$("div").removeClass('modal-backdrop fade show')

                                        return toast.error("Login with correct Wallet Address and credentials")

                                      } else { UrComment(comm._id) }
                                    }}>
                                    <i className="fas fa-trash"></i>
                                  </span>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Scrollbars>
              <div className="comment_sec_inout mt-3">
                <img
                  src={require("../../assets/images/user_comment.png")}
                  alt="Collections"
                  className="img-fluid"
                />
                <div className="w-100 ml-2">
                  <div className="input-group mb-2 inut_gro_stke_1">
                    <input
                      onChange={(e) => setComments(e.target.value)}
                      value={Comments}
                      type="text"
                      className="form-control"
                      placeholder="Please Enter Your Comment"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                    />
                    <div className="input-group-append">
                      <span className="input-group-text pr-2" id="basic-addon2">
                        <span
                          onClick={() => UrComment()}
                          className="send_iocn_bg"
                        >
                          <i className="fas fa-paper-plane cur_pointer"></i>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end info row */}

          {/* info owner row */}
          {/* end info owner row*/}
        </div>
      </div>
      <Footer />
      <PurchaseNow ref={PurchaseNowRef} />
      <PlaceandCancel ref={PlaceandCancelRef} />
      <BidActions ref={BidActionsRef} />
      <BurnToken ref={BurnTokenRef} />
      <TransferNFT ref={TransferNFTRef} />
      <Report ref={ReportRef} />
      <Share ref={ShareNowRef} />
      <ClaimNfts ref={ClaimNftsRef} />
      <Modal
        isOpen={ShowModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {theme === "#000" ?
          <div className="modal-body text-center loading_bgcolor">
            <div className="laodingpart">
              <img src={require("../../assets/images/lazymintingnew/logo.png")} alt="Profile" className="img-fluid items_profile smal_main_lm" />
              {/* {
             theme == "#FFF" ? 
             <p className="text-dark">Loading ....</p>
             :
             <p className="text-white">Loading ....</p>
           } */}

            </div>
          </div> :

          <div className="modal-body text-center loading_bgcolor">
            <div className="laodingpart">
              <img src={require("../../assets/images/lazymintingnew/logodark.png")} alt="Profile" className="img-fluid items_profile smal_main_lm" />
              {/* { 
    theme == "#FFF" ? 
    <p className="text-dark">Loading ....</p>
    :
    <p className="text-white">Loading ....</p>
  } */}

            </div>
          </div>

        }

      </Modal>

      {/* {/ info_img Modal /} */}
      <div class="modal fade primary_modal infoimg_modal" id="infoimg_modal" tabindex="-1" role="dialog" aria-labelledby="infoimg_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">

          <div class="modal-content">
            <button type="button" class="close"
              data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body px-0">
              {NFT &&
                NFT.NFTDetail &&
                NFT.NFTDetail.image &&
                (NFT.NFTDetail.image.split(".").pop() == "mp4" ? (
                  <video
                    id="my-video"
                    className="img-fluid"
                    autoPlay
                    playsInline
                    loop
                    muted
                    controls
                    preload="auto"
                    onError={(e) => Ipfsimageerr(e)}
                  >
                    <source src={NFTImage} type="video/mp4" />
                  </video>
                  // <img
                  //   src={require("../assets/images/img_info.png")}
                  //   // onError={(e)=>Ipfsimageerr(e)}
                  //   alt="Collections"
                  //   className="img-fluid"
                  // />
                ) : (
                  <img
                    src={NFTImage}
                    onError={(e) => Ipfsimageerr(e)}
                    alt="Collections"
                    className="img-fluid"
                  />
                  // <img
                  //   src={require("../assets/images/img_info.png")}
                  //   // onError={(e)=>Ipfsimageerr(e)}
                  //   alt="Collections"
                  //   className="img-fluid"
                  // />
                ))}
            </div>
          </div>
        </div>
      </div>
      {/* {/ end info_img modal /} */}
      {/* login modal*/}
    </div >
  );
}
