import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Button, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import $ from 'jquery'
import Web3Utils from 'web3-utils'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { PurchaseCalculation } from 'actions/controller/PriceCalculate';
import { useTokenDecimal, usePrevious } from 'hooks/useMethods';
import { useMintReceipt, usePurchaseMint, usePruchaseTransferReceipt, usePurchaseTickets } from "hooks/useReceipts";

import Web3 from 'web3';
// import Abi721 from '../ABI/721.json'
// import Abi1155 from '../ABI/1155.json' 
// import AbiTOKEN from '../ABI/token.json'
import { Audio, TailSpin } from 'react-loader-spinner'
import { NightsStay } from '@material-ui/icons';
import { usePurchaseReceipt } from 'hooks/useReceipts';
import { UpdateNFTOwners, UpdateNFTOwnersBulk, imageImger } from 'actions/controller/tokenControl';
import { PopupValidation } from 'actions/controller/validation';
import { PaypalJS } from "./PaypalBuy"
import config from "lib/config"
import { toast } from 'react-toastify';
import { useNFTBalCheck, useBulBalnceCheck } from 'hooks/useMethods';
import { v1_ConNFTBalCheck } from 'actions/v1/token';
import isEmpty from 'lib/isEmpty';
import { connectWallet, WalletConnect, getServiceFees } from '../../hooks/useWallet';
import { Account_Connect, Account_disConnect } from "actions/redux/action";
import { network } from "../../views/network"
import randomInteger from 'random-int';

export const ClaimNfts = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  // console.log("sdvsnvkfdnvkdfnvk", Wallet_Details);
  const [NoofToken, setNoofToken] = useState(1)
  const [NewTokenDecimal, setNewTokenDecimal] = useState('')
  const [Prices, setPrices] = useState({ Youwillpay: 0, PriceWithSerfee: "", ApprovePriceWithSerfee: "", PriceWithoutSerfee: "" })
  const [lazyMintPrices, setLazyMintPrices] = useState({ Youwillpay: 0, PriceWithSerfee: "", ApprovePriceWithSerfee: "", PriceWithoutSerfee: "" })

  const [NFT, setNFT] = useState({ CurrentOwner: {} })
  const [Buystate, setBuystate] = useState(false)
  const [tokName, settokName] = useState("")
  const [ApproveTrade, setApproveTrade] = useState('Start');
  const [SignatureHash, setSignatureHash] = useState('');
  const [NonceHash, setNonceHash] = useState(0);
  const [PurchaseCallStatus, setPurchaseCallStatus] = useState('')
  const [RandomName, setRandomName] = useState('')
  const [SignTxt, SetSignTxt] = useState("Start");
  const prevNoofToken = usePrevious(NoofToken)
  const history = useHistory()

  // nft details set for lazyminting
  const [tokDetails, setTokDetails] = useState("")       // nft detaisl in token collection
  // useEffect(() => {
  //   const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  // }, [])
  useImperativeHandle(
    ref,
    () => ({
      async ClaimClick(ClickedNFT, tokenName, tokenDetails) {
        // console.log("token details", ClickedNFT, tokenName, tokenDetails)
        var networkConfiguration = {}
        var chainid = tokenDetails?.SelectedNetwork
        const chainId = await Wallet_Details.Web3Pro.eth.getChainId();
        console.log("fgndfgn", chainid, Wallet_Details.networkConfiguration);
        var accountDetailsss = ''
        console.log("dfbhsfdbn", ClickedNFT, Wallet_Details.networkConfiguration.Chainid, tokenDetails?.SelectedNetwork);
        Serfee(Number(chainid));

        // var provider = window.ethereum;
        // const web3 = new Web3(provider);
        // const contract = new web3.eth.Contract(Abi721, config.singleContract);
        // const TokenContract = await contract(721,{ Coin: 'CAKE' })
        // const Decimal = await TokenContract
        // .methods
        // .decimals()
        // .call()
        // var TokenDecimal = await useTokenDecimal({ Coin: 'CAKE' }, 721)
        // console.log("bffsvkfsdbvkfsdbv",TokenDecimal);
        // setNewTokenDecimal(TokenDecimal)
        // console.log("sdasdsadasffdfdsf", Wallet_Details.networkConfiguration.Chainid, tokenDetails?.SelectedNetwork);


        if (Wallet_Details.networkConfiguration.Chainid == tokenDetails?.SelectedNetwork) {
          console.log("dknvjksdnkfsnjkfd", Wallet_Details.networkConfiguration.Chainid == tokenDetails?.SelectedNetwork);
          accountDetailsss = true
        }
        else {
          console.log("it comming in else switch", networkConfiguration);
          console.log("sdfsfdfsdfsdfs", Number(chainid), network.MATIC.Chainid);
          if (Number(chainid) === network.MATIC.Chainid) {
            console.log("netter eorrrrkkk maticz", network.MATIC);
            networkConfiguration = network.MATIC
          }
          else if (Number(chainid) === network.ETH.Chainid) {
            console.log("netter eorrrrkkk", network.ETH);
            networkConfiguration = network.ETH
          }
          else if (Number(chainid) === network.CAM.Chainid) {
            console.log("netter eorrrrkkk", network.CAM);
            networkConfiguration = network.CAM
          }

          console.log("qeweqweqweasdas", chainId, networkConfiguration.chainid);
          if (Number(networkConfiguration.chainid) !== Number(chainId)) {
            var accountDetailsss = await switchNetwork(chainid, networkConfiguration)
          }
        }

        console.log("stwerwerwer", Wallet_Details);
        console.log("cdsncsdlcslmclds", chainid === String(Wallet_Details.networkConfiguration.Chainid), chainid, String(Wallet_Details?.networkConfiguration?.Chainid));
        console.log("it comming in else", Number(chainid) === Number(networkConfiguration?.Chainid));
        console.log("smdnvsdnkfsnd", accountDetailsss, Wallet_Details, Number(chainid), Number(networkConfiguration?.Chainid));


        if (Number(chainid) === Number(chainId)) {
          console.log("dnkdnfksdnfkjsdnf");
          // if (ClickedNFT.OwnerDetail) {
          //   ClickedNFT.CurrentOwner = ClickedNFT.OwnerDetail
          // }

          window.$('#Claim_modal').modal('show');
          settokName(tokenName)
          setNFT(ClickedNFT)
          setTokDetails(tokenDetails)   //added for lazymint
          console.log("jdsbnfshjfbrbhjtn", ClickedNFT)

          console.log("condition detials", Wallet_Details.UserAccountAddr && ClickedNFT && ClickedNFT.hashValue == "not yet minted" && ClickedNFT.type == 1155)
          if (Wallet_Details.UserAccountAddr && ClickedNFT && ClickedNFT.hashValue == "not yet minted" && ClickedNFT.type == 1155) {

            var data = { Price: ClickedNFT.tokenPrice, Quantity: ClickedNFT.quantity, Serfee: Wallet_Details.Service_Fee, Decimal: 18, tokenRoyality: tokenDetails.tokenRoyality }
            var Data = PurchaseCalculation(data)
            setPrices(Data)
            setNoofToken(ClickedNFT.quantity)
            // console.log("multiple price", Data, ClickedNFT.tokenPrice)
          }
          else if (Wallet_Details.UserAccountAddr) {
            console.log("gerkmgoemfgfdmve", Wallet_Details);
            var data = { Price: ClickedNFT.tokenPrice, Quantity: NoofToken, Serfee: Wallet_Details.Service_Fee, Decimal: 18 }
            console.log("data for purchase calculation", data);
            var Data = PurchaseCalculation(data)
            // console.log("dsnkjdnkdnkdacnd ncjask", Data);
            setPrices(Data)
            // console.log("jdsnbfufnuewrgn", Data)
          }
        }
      }
    }))

  const Serfee = async (Address) => {
    console.log("khlhujnoasdasdasdikljiuio", Address);
    var Fee = await getServiceFees(Address)
    console.log("ndkdsnfkdsn", Fee);
    dispatch({
      type: Account_Connect,
      Account_Detail: {
        Service_Fee: Fee?.Serfee,
        Service_Fee_Fraction:Fee?.SerfeeFraction
      }
    })
  }


  async function switchNetwork(configdata, networkConfiguration) {
    var type = ""
    var networkConfiguration = {}
    const chainId = await Wallet_Details.Web3Pro.eth.getChainId();
    console.log("adsadasdasd", configdata, chainId, networkConfiguration);
    // if(configdata ==)
    console.log("dfadsaddasdadas", Wallet_Details, localStorage.walletConnectType);
    if (localStorage.walletConnectType !== "Torus") {
      if (Number(configdata) !== Number(chainId)) {
        const id = toast.loading("Switching Network", { closeButton: true, theme: "dark" });
        // console.log("sdnfvnfkvnfs", configdata);
        if (configdata) {
          if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask') {

            type = "MetaMask"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'WalletConnect' && localStorage.walletConnectType != null) {
            type = "WalletConnect"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'Venly' && localStorage.walletConnectType != null) {
            type = "Venly"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'Torus' && localStorage.walletConnectType != null) {
            type = "Torus"
          }
          // console.log("connecttype............", type, configdata)
          var accountDetails = await connectWallet(type, Number(configdata), "Create")
          // console.log("accountDetailsin create page......................", accountDetails)

          // var web3 = new Web3(window.ethereum);
          // console.log("dfghrtfh", web3);
          // if (window.ethereum.isMetaMask == true) {

          const chainId = await accountDetails?.web3?.eth?.getChainId();
          // console.log("fghdtgj", chainId);

          if (Number(configdata) === network.MATIC.Chainid) {
            networkConfiguration = network.MATIC
          }
          else if (Number(configdata) === network.ETH.Chainid) {
            networkConfiguration = network.ETH
          }
          else if (Number(configdata) === network.CAM.Chainid) {
            networkConfiguration = network.CAM
          }


          // }
          // setPriceoption()
          console.log("dsnsdkngksfnvkfdn", networkConfiguration);
          if (accountDetails != '' && accountDetails?.web3?._provider != '') {
            // console.log("Wallet Connecting...increate", accountDetails.web3._provider);
            dispatch({
              type: Account_Connect,
              Account_Detail: {
                UserAccountAddr: accountDetails.accountAddress,
                UserAccountBal: accountDetails.coinBalance,
                WalletConnected: "true",
                Wen_Bln: accountDetails.tokenBalance,
                Accounts: accountDetails.accountAddress,
                Web3Pro: accountDetails.web3,
                providerss: accountDetails.web3._provider,
                networkConfiguration: networkConfiguration
              }
            })
            Serfee(networkConfiguration.Chainid);
          }
          if (networkConfiguration.currencySymbol) {
            toast.update(id, {
              render: "Network switched Succesfully",
              type: "success",
              isLoading: false,
              autoClose: 2500,
              closeButton: true,
              theme: "dark"
            });
          }
        }



        //   var ReqData = {
        //     addr: String(accountDetails.accountAddress).toLowerCase()
        // }
        // var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
        // if (Resp && Resp.data && Resp.data.data && Resp.data.data.User) {
        //     return Resp.data.data.User
        // } else {
        //     return null
        // }





        return accountDetails
      } else {
        dispatch({
          type: Account_Connect,
          Account_Detail: {
            UserAccountAddr: Wallet_Details?.UserAccountAddr,
            UserAccountBal: Wallet_Details?.UserAccountBal,
            WalletConnected: "true",

            Wen_Bln: Wallet_Details?.Wen_Bln,
            Accounts: Wallet_Details?.Accounts,

            Web3Pro: Wallet_Details?.Web3Pro,
            providerss: Wallet_Details?.providerss,
            networkConfiguration: networkConfiguration
          }
        })
        Serfee(networkConfiguration.Chainid);
        return Wallet_Details.Web3Pro
      }
    } else {
      try {
        const id = toast.loading("Switching Network", { closeButton: true, theme: "dark" });
        var accountDetails = await connectWallet(localStorage.walletConnectType, Number(configdata), "Create", Wallet_Details, "Torus")
        // if (Number(networkNUM) == 97) {
        //   console.log("Comming on last else for switchCahin", Wallet_Details.web3auth, Wallet_Details?.web3);
        //   var addingChain = await Wallet_Details.web3auth.addChain({
        //     chainId: "0x61",
        //     displayName: "binance",
        //     chainNamespace: CHAIN_NAMESPACES.EIP155,
        //     tickerName: "BNB Smart Chain Testnet",
        //     ticker: "BNB",
        //     decimals: 18,
        //     rpcTarget: "https://data-seed-prebsc-1-s1.binance.org:8545/",
        //     blockExplorer: "https://testnet.bscscan.com/",
        //   });
        //   console.log("addingChain Web3Auth", addingChain);
        //   var switched = await Wallet_Details.web3auth.switchChain({ chainId: "0x61" });
        //   console.log("switched in create page uh", switched);
        // }else{
        //   console.log("Comming on last else for switchCahin", Wallet_Details.web3auth, Wallet_Details?.web3);
        //   var addingChain = await Wallet_Details.web3auth.addChain({
        //     chainId: "0x13881",
        //     displayName: "Mumbai",
        //     chainNamespace: CHAIN_NAMESPACES.EIP155,
        //     tickerName: "Mumbai",
        //     ticker: "MATIC",
        //     decimals: 18,
        //     rpcTarget: "https://matic-mumbai.chainstacklabs.com",
        //     blockExplorer: "https://mumbai.polygonscan.com/",
        //   });
        //   console.log("addingChain Web3Auth", addingChain);
        //   var switched = await Wallet_Details.web3auth.switchChain({ chainId: "0x13881" });
        //   console.log("switched in create page uh", switched);
        // }
        console.log("asdasdasdasdas", accountDetails);
        const chainId = await accountDetails?.web3?.eth?.getChainId();
        console.log("fghdtgj", configdata, network, chainId);

        if (Number(configdata) === network.MATIC.Chainid) {
          networkConfiguration = network.MATIC
        }
        else if (Number(configdata) === network.ETH.Chainid) {
          networkConfiguration = network.ETH
        }
        else if (Number(configdata) === network.CAM.Chainid) {
          networkConfiguration = network.CAM
        }
        console.log("huiyooijmoijolmj", networkConfiguration);
        if (accountDetails != '') {
          dispatch({
            type: Account_Connect,
            Account_Detail: {
              UserAccountAddr: accountDetails?.accountAddress,
              UserAccountBal: accountDetails?.coinBalance,
              WalletConnected: "true",

              Wen_Bln: accountDetails?.tokenBalance,
              Accounts: accountDetails?.accountAddress,

              Web3Pro: accountDetails?.web3,
              providerss: accountDetails?.web3?._provider,
              networkConfiguration: networkConfiguration
            }
          })
        }
        var functiobn = await NefunctionTriger(Wallet_Details)
        console.log("Asdasdaeqweqwe", networkConfiguration.currencySymbol);
        if (networkConfiguration.currencySymbol) {
          toast.update(id, {
            render: "Network switched Succesfully",
            type: "success",
            isLoading: false,
            autoClose: 2500,
            closeButton: true,
            theme: "dark"
          });
        }
      } catch (e) {
        console.log("adqeqweqweqweqweqweqwe", e);
      }
    }

  }

  const NefunctionTriger = async (Wallet_Details1) => {
    console.log("Wallet_Details1", Wallet_Details1);
    const chainIdSwitched = await Wallet_Details1?.Web3Pro?.eth?.getChainId();
    var balance = await Wallet_Details1?.Web3Pro?.eth.getBalance(Wallet_Details1.UserAccountAddr);
    console.log("chainIdSwitched", chainIdSwitched, balance);
    return chainIdSwitched
  }

  useEffect(() => {
    if (NoofToken !== 1 && prevNoofToken !== NoofToken) {
      var data = { Price: NFT.tokenPrice, Quantity: NoofToken, Serfee: Wallet_Details.Service_Fee, Decimal: NewTokenDecimal }
      var Data = PurchaseCalculation(data)
      setPrices(Data)
    }
  }, [NoofToken])

  const ValidCheck = () => {
    var ValChk = { TokenPrice: Prices.Youwillpay, NoofToken: NoofToken, type: NFT.type, MaxToken: NFT.balance }
    var Valid = PopupValidation(ValChk)
    if (Valid.Success) {
      var Check = (Wallet_Details.UserAccountBal < Prices.Youwillpay)
      if (Check) {
        toast.error("Insufficient Balance", { theme: "dark" })
      }
      else
        $('#hiddenbutton').trigger("click");
    }
    else
      toast.error(Valid.Message, { theme: "dark" })
  }


  const PurchaseMint = async () => {
    console.log("nft hash status", NFT, tokDetails)
    if (NFT.hashValue == "not yet minted" && NFT.LazyStatus == "pending") {
      console.log("it comming in first");
      if (tokDetails.Bulkmint !== "Bulk") {
        LazyMintCall();
      } else {
        ImageMergeProcess()
      }
    }
    else if (NFT.hashValue !== "not yet minted" && NFT.LazyStatus == "pending") {
      console.log("it comming in Second");
      if (tokDetails.Bulkmint !== "Bulk") {
        BalCheck();
      } else {
        ImageMergeProcess()
      }

    }
    else if (NFT.hashValue !== "not yet minted" && NFT.LazyStatus == "completed") {
      console.log("it comming in Third");
      if (tokDetails.Bulkmint !== "Bulk") {
        BalCheck();
      } else {
        ImageMergeProcess()
      }

    }

  }

  const NFTHookFun = async (Arg, Type, Wallet_Details) => {
    var ContractNFTBal = await useNFTBalCheck(Arg, Type, Wallet_Details)
    return ContractNFTBal
  }

  const NFTHookFun1 = async (Arg, Type, Wallet_Details) => {
    var ContractNFTBal = await useBulBalnceCheck(Arg, Type, Wallet_Details)
    console.log("asdasddasdasdas", NFTHookFun1);
    return ContractNFTBal
  }

  const AcceptBidTransferCall = async () => {
    // alert("normal accept")
    setBuystate(true)
    // console.log("dfnsdknfksndfksdNFT here it comming", NFT);
    var Arg = { NFTOwner: NFT.tokenOwner, NFTId: NFT.tokenID, PriceWithoutSerfee: Prices.PriceWithoutSerfee, PriceWithSerfee: Prices.PriceWithSerfee, NoofNFTs: NoofToken, ContractAddress: NFT.contractAddress, Coinname: NFT.coinname }
    var Type = NFT.type
    var Provider = Wallet_Details
    var isToken = false
    // console.log("sdfsdnskfvdvmdfkv", Arg);
    var Receipt = await usePruchaseTransferReceipt(Arg, Type, Provider, isToken)
    // console.log("receit response", Receipt, NFT.balance, NoofToken)
    var updateData = {
      LazyStatus: NFT.balance == NoofToken ? "completed" : "pending",
      tokenID: String(NFT.tokenID),
    }
    if (Receipt.status) {
      var NewOwnerDetail = { Status: Receipt.status, Hash: Receipt.transactionHash, ConAddr: NFT.contractAddress, Owns: NoofToken, NFTDetail: NFT, Action: "Purchase", NewOwner: Wallet_Details.UserAccountAddr, OldOwner: NFT.tokenOwner, coinname: NFT.coinname, tokenName: tokName, updateData: updateData, LazyStatus: "completed", QR_tokenID: NFT.QR_tokenID }
      // console.log("newowner deetail Buycall", NewOwnerDetail)
      var Resp = await UpdateNFTOwners(NewOwnerDetail)

      if (Resp) {
        $('#buy_closs').trigger("click");
        setTimeout(() => {
          history.push(`/my-items/${Wallet_Details.UserAccountAddr}`)
        }, 1000);
      }
    }else {
      toast.error("Try it Again Later.Please Check Your Funds")
    }
    setBuystate(false)
  }




  const LazyMintCall = async () => {
    // alert("lazymintcall")
    setBuystate(true)
    console.log('nft token detial', tokDetails, NFT, Wallet_Details)
    var chainid = tokDetails?.SelectedNetwork
    var networkConfiguration = {}
    console.log("sdfsfdfsdfsdfs", Number(chainid), network.MATIC.Chainid);
    if (Number(chainid) === network.MATIC.Chainid) {
      console.log("netter eorrrrkkk maticz", network.MATIC);
      networkConfiguration = network.MATIC
    }
    else if (Number(chainid) === network.ETH.Chainid) {
      console.log("netter eorrrrkkk", network.ETH);
      networkConfiguration = network.ETH
    }
    else if (Number(chainid) === network.CAM.Chainid) {
      console.log("netter eorrrrkkk", network.CAM);
      networkConfiguration = network.CAM

    }
    // console.log("fwkjdfndkfjnsdnsdkf", Web3.utils.toWei(String(tokDetails?.tokenRoyality)));
    var Royaltity = Web3.utils.toWei(String(tokDetails?.tokenRoyality))
    var tokendbPrice = tokDetails?.tokenPrice == 0 ? 0 : Web3.utils.toWei(String(tokDetails?.tokenPrice))
    // console.log("qweqweqwreqrq", Royaltity, tokendbPrice);
    var nounceArg = { RandomName: RandomName, NonceHash: NonceHash, SignatureHash: SignatureHash }
    Wallet_Details.networkConfiguration = networkConfiguration
    var Arg = { NFTOwner: NFT.tokenOwner, NFTId: NFT.tokenID, PriceWithoutSerfee: Prices.PriceWithoutSerfee, PriceWithSerfee: Prices.PriceWithSerfee, NoofNFTs: NoofToken, isToken: false, TotalToken: NFT.quantity, Royaltity: Royaltity, tokendbPrice: tokendbPrice, nounceArg: nounceArg }
    // console.log("data arg or contract", Arg, Wallet_Details)
    var Receipt = await usePurchaseMint(Arg, NFT.type, Wallet_Details, tokDetails, NFT);
    // console.log("returner repsonse receipt", Receipt)
    if (Receipt.status) {
      if (tokDetails.SelectedNetwork == config.MATIC_ID) {
        // console.log("sfvfjnlkmkls", tokDetails.SelectedNetwork);
        var tokenid = tokDetails.type == 721 ? Receipt?.logs[4]?.topics[3] : Receipt?.logs[4]?.topics[3];
      } else if (tokDetails.SelectedNetwork == config.ETH_ID) {
        // console.log("binanceeeeeee");
        var tokenid = tokDetails.type == 721 ? Receipt?.logs[3]?.topics[3] : Receipt?.logs[3]?.topics[3];
      } else if (tokDetails.SelectedNetwork == config.CAM_ID) {
        // console.log("sfvfjnlkmkls", tokDetails.SelectedNetwork);
        var tokenid = tokDetails.type == 721 ? Receipt?.logs[2]?.topics[2] : Receipt?.logs[2]?.topics[2];
      } 

      const tokenID = Web3Utils.hexToNumber(tokenid);
      // console.log("tokenid from receipt", tokenID, NFT)
      // console.log("gyugduasguisgcshg", NFT, tokDetails);
      var updateData = {
        hashValue: Receipt.transactionHash,
        tokenID: String(tokenID),
        status: Receipt?.status,
        BulkMint: tokDetails.BulkMint == 'false' ? 'true' : 'true',
        LazyStatus: NFT.balance == NoofToken ? "completed" : "pending",

      }
      var NewOwnerDetail = { Status: Receipt?.status, Hash: Receipt.transactionHash, ConAddr: NFT.contractAddress, Owns: NoofToken, NFTDetail: NFT, Action: "Purchase", NewOwner: Wallet_Details.UserAccountAddr, OldOwner: NFT.tokenOwner, coinname: NFT.coinname, tokenName: tokName, Collection: tokDetails.Collection, updateData: updateData, LazyStatus: "completed", SelectedNetwork: tokDetails.SelectedNetwork, QR_tokenID: NFT.QR_tokenID }
      // console.log("newowner deetail", NewOwnerDetail, updateData,)
      var Resp = await UpdateNFTOwners(NewOwnerDetail)

      if (Resp) {
        $('#buy_closs').trigger("click");
        setTimeout(() => {
          history.push(`/my-items/${Wallet_Details.UserAccountAddr}`)
        }, 1000);
      }
    }else {
      toast.error("Try it Again Later.Please Check Your Funds")
    }
    setBuystate(false)



  }




  const BalCheck = async () => {
    // alert("22")
    // console.log("dnjsnkfnsdkfnksd its comming here", NFT);
    var chainid = tokDetails?.SelectedNetwork
    var networkConfiguration = {}
    console.log("sdfsfdfsdfsdfs", Number(chainid), network.MATIC.Chainid);
    if (Number(chainid) === network.MATIC.Chainid) {
      console.log("netter eorrrrkkk maticz", network.MATIC);
      networkConfiguration = network.MATIC
    }
    else if (Number(chainid) === network.ETH.Chainid) {
      console.log("netter eorrrrkkk", network.ETH);
      networkConfiguration = network.ETH
    }
    else if (Number(chainid) === network.CAM.Chainid) {
      console.log("netter eorrrrkkk", network.CAM);
      networkConfiguration = network.CAM
    }
    Wallet_Details.networkConfiguration = networkConfiguration
    var Arg = {
      NFTConAddress: NFT.contractAddress,
      NFTId: NFT.tokenID,
      NFTOwner: NFT.tokenOwner,
      Status: NFT.LazyStatus,
      BulK: tokDetails.Bulkmint
    }
    var Type = NFT.type
    // var ContractNFTBal = await useNFTBalCheck(Arg, Type, Wallet_Details)
    console.log("adasdsadasdasd", tokDetails.Bulkmint);
    if (tokDetails.Bulkmint !== "Bulk") {
      console.log("else iffffff");
      var ContractNFTBal = await NFTHookFun(Arg, Type, Wallet_Details)
    } else {
      console.log("Bull else",);
      var ContractNFTBal = await NFTHookFun1(Arg, Type, Wallet_Details)
    }
    // console.log("ContractNFTBal", ContractNFTBal)
    if (Number(ContractNFTBal) !== NFT.balance) {
      Arg.balance = Number(ContractNFTBal)
      var Updated = await v1_ConNFTBalCheck(Arg)
      // console.log("fjbdshfbsfhsr", Updated)
      if (Updated.data && Updated.data.Success) {
        toast.error("Owners NFT Balance Changed. Try Later", { autoClose: 4000, closeButton: true })
        setTimeout(() => {
          history.push('/explore/All')
        }, 1500);
      }
      else {
        toast.error("Error Occured. Try Later", { autoClose: 3000, closeButton: true })
        setTimeout(() => {
          history.push('/explore/All')
        }, 1500);
      }
    }
    else {
      // alert("domaru")
      // console.log("buycalsds")
      BuyCall()
    }
  }

  const BuyCall = async () => {
    // alert("97")
    setBuystate(true)
    console.log("dfnsdknfksndfksdNFT here it comming", NFT);
    var chainid = tokDetails?.SelectedNetwork
    var networkConfiguration = {}
    console.log("sdfsfdfsdfsdfs", Number(chainid), network.MATIC.Chainid);
    if (Number(chainid) === network.MATIC.Chainid) {
      console.log("netter eorrrrkkk maticz", network.MATIC);
      networkConfiguration = network.MATIC
    }
    else if (Number(chainid) === network.ETH.Chainid) {
      console.log("netter eorrrrkkk", network.ETH);
      networkConfiguration = network.ETH
    }
    else if (Number(chainid) === network.CAM.Chainid) {
      console.log("netter eorrrrkkk", network.CAM);
      networkConfiguration = network.CAM
    }
    Wallet_Details.networkConfiguration = networkConfiguration
    var Arg = { NFTOwner: NFT.tokenOwner, NFTId: NFT.tokenID, PriceWithoutSerfee: Prices.PriceWithoutSerfee, PriceWithSerfee: Prices.PriceWithSerfee, NoofNFTs: NoofToken, ContractAddress: NFT.contractAddress, Coinname: NFT.coinname, Status: NFT.LazyStatus }
    var Type = NFT.type
    var Provider = Wallet_Details
    var isToken = false
    var Receipt = await usePurchaseReceipt(Arg, Type, Provider, isToken)
    // console.log("receit response", Receipt)
    var updateData = {
      LazyStatus: NFT.balance == NoofToken ? "completed" : "pending",
      tokenID: String(NFT.CurrentOwner.tokenID),
    }
    if (Receipt.status) {
      var NewOwnerDetail = { Status: Receipt.status, Hash: Receipt.transactionHash, ConAddr: NFT.contractAddress, Owns: NoofToken, NFTDetail: NFT, Action: "Purchase", NewOwner: Wallet_Details.UserAccountAddr, OldOwner: NFT.tokenOwner, coinname: NFT.coinname, tokenName: tokName, updateData: "", LazyStatus: "completed", SelectedNetwork: tokDetails.SelectedNetwork, QR_tokenID: NFT.QR_tokenID }
      // console.log("newowner deetail Buycall", NewOwnerDetail)
      var Resp = await UpdateNFTOwners(NewOwnerDetail)

      if (Resp) {
        toast.success("Purchased Sucessfully")
        $('#buy_closs').trigger("click");
        setTimeout(() => {
          history.push(`/my-items/${Wallet_Details.UserAccountAddr}`)
        }, 1000);
      }
    }else {
      toast.error("Try it Again Later.Please Check Your Funds")
    }
    setBuystate(false)
  }

  async function signcall() {
    var generator = require('generate-password');
    var chainid = tokDetails?.SelectedNetwork
    var networkConfiguration = {}
    console.log("sdfsfdfsdfsdfs", Number(chainid), network.MATIC.Chainid);
    if (Number(chainid) === network.MATIC.Chainid) {
      console.log("netter eorrrrkkk maticz", network.MATIC);
      networkConfiguration = network.MATIC
    }
    else if (Number(chainid) === network.ETH.Chainid) {
      console.log("netter eorrrrkkk", network.ETH);
      networkConfiguration = network.ETH
    }
    else if (Number(chainid) === network.CAM.Chainid) {
      console.log("netter eorrrrkkk", network.CAM);
      networkConfiguration = network.CAM
    }
    Wallet_Details.networkConfiguration = networkConfiguration
    // console.log("qweqweqwewqeqweqwezxccvc", Wallet_Details, tokDetails);
    if (Wallet_Details.Web3Pro) {
      var web3Rpc = new Web3(Wallet_Details.providerss)
      // console.log("qweqwewqewqewqewqqwvbv", web3Rpc);
      var randomNum = randomInteger(10000000, 100000000);
      // console.log("sdcfsdfsdfsdvsdfdsf", randomNum);
      var password = generator.generate({
        length: 10,
        numbers: true
      });
      // console.log("asdasdasdas", password);
      if (web3Rpc) {
        SetSignTxt('progress')
        // setApproveCallStatus('process')
        // console.log("checking window.ethereum", web3.givenProvider,Wallet_Details.providerss)
        var web3RpcPro = new Web3(Wallet_Details.providerss);
        // console.log("asddfsfsdvbvbvcb", web3RpcPro);
        const to = Wallet_Details.UserAccountAddr
        // var Price = web3RpcPro.utils.toWei(String(NFTDetails.TokenPrice));
        // console.log("qwewqewqreqwrqrq",Price);
        const _amount = tokDetails.tokenPrice == "" || tokDetails.tokenPrice == undefined ? 0 : web3RpcPro.utils.toWei(String(tokDetails.tokenPrice));
        // const _message = "Presale NFT";
        const _nonce = Date.now();
        // setNonceHash(_nonce);
        setRandomName(password)
        var tot = _nonce + Number(randomNum);
        setNonceHash(tot);
        // console.log("qwewqeqwewqeqweasdas", to, _amount, password, tot);
        const result = web3RpcPro.utils.soliditySha3(to, _amount, password, tot);
        // console.log("asdsadasfdafaf", result);
        const signhash = await Wallet_Details.Web3Pro.eth.personal.sign(result, to);
        // console.log("Signature=", result, signhash)
        if (signhash) {
          SetSignTxt('done')
          // setPurchaseCallStatus('init')
          setSignatureHash(signhash);
        }
      }
    }
  }

  const selectChange = async (e) => {
    // console.log("fkdjdsfsdgjfsldfg", e);
    if ((e).length <= 12) {
      setNoofToken(e)
    } else {
      setNoofToken(0)
    }

  }

  //Bulkminting Calls

  const ImageMergeProcess = async () => {
    setBuystate(true)

    const id = toast.loading("Image Generating", { closeButton: true, theme: "dark" });
    console.log("asdasdsadsadas NFT", NFT, tokDetails);
    var timestamp = Date.now()
    var updateData = {
      hashValue: NFT.hashValue,
      tokenID: timestamp,
      status: NFT?.status,
      BulkMint: tokDetails.BulkMint == 'false' ? 'true' : 'true',
      LazyStatus: NFT.balance == NoofToken ? "completed" : "pending",
    }
    console.log("iuoiuiyuittr", timestamp)
    NFT.ScannerTokenID = timestamp
    tokDetails.ScannerTokenID = timestamp
    var NewOwnerDetail = { Status: NFT?.status, Hash: NFT.hashValue, ConAddr: NFT.contractAddress, Owns: NoofToken, NFTDetail: NFT, Action: "Purchase", NewOwner: Wallet_Details.UserAccountAddr, OldOwner: NFT.tokenOwner, coinname: NFT.coinname, tokenName: tokName, Collection: tokDetails.Collection, updateData: updateData, LazyStatus: "completed", SelectedNetwork: tokDetails.SelectedNetwork, QR_tokenID: NFT.QR_tokenID, Bulkmint: tokDetails.Bulkmint, NFTTokens: tokDetails, ScannerTokenID: timestamp }
    console.log("newowner deetail", NewOwnerDetail, updateData,)
    var Resp11 = await imageImger(NewOwnerDetail)
    console.log("sdasdasdasdasdadqeq", Resp11.data);
    if (Resp11.Success == "true" && Resp11.data.NFTDetails.type) {
      toast.update(id, {
        render: "Image Generated Succesfully",
        type: "success",
        isLoading: false,
        autoClose: 2500,
        closeButton: true,
        theme: "dark"
      });
      BulkMintLazyCall(Resp11.data.NFTDetails, timestamp)
    } else {
      setBuystate(false)
      toast.update(id, {
        render: "Error in Image Generating. Please tryAgain",
        type: "error",
        isLoading: false,
        autoClose: 2500,
        closeButton: true,
        theme: "dark"
      });
    }
  }

  const BulkMintLazyCall = async (Datas, timestamp) => {
    setBuystate(true)
    var chainid = tokDetails?.SelectedNetwork
    var networkConfiguration = {}
    console.log("sdfsfdfsdfsdfs", Number(chainid), network.MATIC.Chainid);
    if (Number(chainid) === network.MATIC.Chainid) {
      console.log("netter eorrrrkkk maticz", network.MATIC);
      networkConfiguration = network.MATIC
    }
    else if (Number(chainid) === network.ETH.Chainid) {
      console.log("netter eorrrrkkk", network.ETH);
      networkConfiguration = network.ETH
    }
    else if (Number(chainid) === network.CAM.Chainid) {
      console.log("netter eorrrrkkk", network.CAM);
      networkConfiguration = network.CAM
    }

    console.log('nft token detial', tokDetails, NFT, Datas)
    // console.log("fwkjdfndkfjnsdnsdkf", Web3.utils.toWei(String(tokDetails?.tokenRoyality)));
    NFT.ScannerTokenID = timestamp
    tokDetails.ScannerTokenID = timestamp
    var Royaltity = Web3.utils.toWei(String(tokDetails?.tokenRoyality))
    var tokendbPrice = tokDetails?.tokenPrice == 0 ? 0 : Web3.utils.toWei(String(tokDetails?.tokenPrice))
    // console.log("qweqweqwreqrq", Royaltity, tokendbPrice);
    var nounceArg = { RandomName: RandomName, NonceHash: NonceHash, SignatureHash: SignatureHash }
    Wallet_Details.networkConfiguration = networkConfiguration
    var Arg = { NFTOwner: NFT.tokenOwner, NFTId: NFT.tokenID, PriceWithoutSerfee: Prices.PriceWithoutSerfee, PriceWithSerfee: Prices.PriceWithSerfee, NoofNFTs: NoofToken, isToken: false, TotalToken: NFT.quantity, Royaltity: Royaltity, tokendbPrice: tokendbPrice, nounceArg: nounceArg, Datas: Datas }
    // console.log("data arg or contract", Arg, Wallet_Details)
    var Receipt = await usePurchaseTickets(Arg, NFT.type, Wallet_Details, tokDetails, NFT);
    // console.log("returner repsonse receipt", Receipt)
    if (Receipt.status) {
      if (tokDetails.SelectedNetwork == config.MATIC_ID) {
        // console.log("sfvfjnlkmkls", tokDetails.SelectedNetwork);

        var ContractNFTBal = await NFTHookFun(Arg, tokDetails.type, Wallet_Details)

        if (ContractNFTBal > 0) {
          var tokenid = tokDetails.type == 721 ? Receipt?.logs[4]?.topics[3] : Receipt?.logs[4]?.topics[3];
        } else {
          var tokenid = tokDetails.type == 721 ? Receipt?.logs[4]?.topics[3] : Receipt?.logs[4]?.topics[3];
        }
      } else {
        console.log("binanceeeeeee");
        var ContractNFTBal = await NFTHookFun(Arg, tokDetails.type, Wallet_Details)
        if (ContractNFTBal > 0) {
          var tokenid = tokDetails.type == 721 ? Receipt?.logs[3]?.topics[3] : Receipt?.logs[3]?.topics[3];
        } else {
          var tokenid = tokDetails.type == 721 ? Receipt?.logs[2]?.topics[2] : Receipt?.logs[2]?.topics[2];
        }
      }

      const tokenID = Web3Utils.hexToNumber(Number(tokenid));
      // console.log("tokenid from receipt", tokenID, NFT)
      // console.log("gyugduasguisgcshg", NFT, tokDetails);
      var updateData = {
        hashValue: Receipt.transactionHash,
        tokenID: String(tokenID),
        status: Receipt?.status,
        BulkMint: tokDetails.BulkMint == 'false' ? 'true' : 'true',
        LazyStatus: NFT.balance == NoofToken ? "completed" : "pending",

      }
      // console.log("qwennmbbczxczxX", tokDetails);
      // var NewOwnerDetail = { Status: Receipt?.status, Hash: Receipt.transactionHash, ConAddr: NFT.contractAddress, Owns: NoofToken, NFTDetail: NFT, Action: "Purchase", NewOwner: Wallet_Details.UserAccountAddr, OldOwner: NFT.tokenOwner, coinname: NFT.coinname, tokenName: tokName, Collection: tokDetails.Collection, updateData: updateData, LazyStatus: "completed", SelectedNetwork: tokDetails.SelectedNetwork, QR_tokenID: NFT.QR_tokenID, Bulkmint: tokDetails.Bulkmint, NFTTokens: tokDetails }
      // console.log("newowner deetail", NewOwnerDetail, updateData,)
      // var Resp11 = await imageImger(NewOwnerDetail)
      // console.log("sdasdasdasdasdadqeq", Resp11.data);
      //if (Resp11.Success == "true" && Resp11.data.NFTDetails.type) {
      var NewOwnerDetail1 = { Status: Receipt?.status, Hash: Receipt.transactionHash, ConAddr: NFT.contractAddress, Owns: NoofToken, NFTDetail: NFT, Action: "Purchase", NewOwner: Wallet_Details.UserAccountAddr, OldOwner: NFT.tokenOwner, coinname: NFT.coinname, tokenName: tokName, Collection: tokDetails.Collection, updateData: updateData, LazyStatus: "completed", SelectedNetwork: tokDetails.SelectedNetwork, QR_tokenID: NFT.QR_tokenID, Bulkmint: tokDetails.Bulkmint, NFTTokens: Datas, ScannerTokenID: Datas.ScannerTokenID }
      console.log("Comming Inside");
      var Resp = await UpdateNFTOwnersBulk(NewOwnerDetail1)

      if (Resp) {
        $('#buy_closs').trigger("click");
        toast.success("Purchased Sucessfully")
        setTimeout(() => {
          history.push(`/my-items/${Wallet_Details.UserAccountAddr}`)
        }, 1000);
      }
      // }
    }else {
      toast.error("Try it Again Later.Please Check Your Funds")
    }
    setBuystate(false)



  }

  return (
    <>
      {/* buy Modal */}
      <div
        className="modal fade primary_modal"
        id="Claim_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="buy_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >

          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="buy_modalLabel">
                Claim NFT
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-0">
              {/* {console.log("price fee condition", Prices.PriceWithSerfee)} */}
              {!(Prices.PriceWithSerfee) ?
                <><TailSpin wrapperClass="reactloader mt-0 mb-3" color="#00BFFF" height={100} width={70} /></>
                :
                <>
                  <div className="row mx-0">
                    <div className="col-12 col-md-6 px-4">
                      <p className="buy_title_sm">Seller</p>
                      <p className="buy_title_md">{(NFT.CurrentOwner.name) ? NFT?.CurrentOwner?.name?.slice(0, 10) : (NFT.CurrentOwner.curraddress).slice(0, 10)}</p>
                    </div>
                    <div className="col-12 col-md-6 px-4">
                      <p className="buy_title_sm text-md-right">Buyer</p>
                      <p className="buy_title_md text-md-right">{(Wallet_Details.UserAccountAddr).slice(0, 10)}</p>
                    </div>
                  </div>
                  {/* {console.log("sdfsdfsdfsdfsd", NFT)} */}
                  {/* <div className="">
                    <p className="buy_title_md px-4 py-3 text-center mb-0">
                      {Prices.Youwillpay} {NFT.coinname}
                    </p>
                  </div> */}
                  {/* {(NFT.type === 1155) &&
                    <div className="mx-3">
                      <label htmlFor="qty" className="buy_desc_sm">
                        Enter quantity{" "}
                        <span className="label_muted pl-2">({NFT.balance} available)</span>
                      </label>
                      <div className="mb-3 input_grp_style_1">
                        <input
                          type="text"
                          id="qty"
                          className="form-control"
                          placeholder="1"
                          value={NoofToken}
                          onChange={(e) => { selectChange(e.target.value) }}
                        />
                      </div>
                    </div>
                  } */}

                  {/* {console.log("zdafasdafzdczc", NFT)} */}
                  {/* <div className="row mx-0 pb-3">
                    <div className="col-12 col-sm-6 px-4">
                      <p className="buy_desc_sm">Your balance</p>
                    </div>
                    <div className="col-12 col-sm-6 px-4 text-sm-right">
                      <p className="buy_desc_sm_bold">{(Wallet_Details.UserAccountBal).toFixed(5)} {NFT.coinname}</p>
                    </div>
                  </div>
                  <div className="row mx-0 pb-3">
                    <div className="col-12 col-sm-6 px-4">
                      <p className="buy_desc_sm">Service fee</p>
                    </div>
                    <div className="col-12 col-sm-6 px-4 text-sm-right">
                      <p className="buy_desc_sm_bold">{Wallet_Details.Service_Fee.Buyer}%</p>
                    </div>
                  </div>
                  <div className="row mx-0 pb-3">
                    <div className="col-12 col-sm-6 px-4">
                      <p className="buy_desc_sm">Price of the NFT</p>
                    </div>
                    <div className="col-12 col-sm-6 px-4 text-sm-right">
                      <p className="buy_desc_sm_bold">{NFT.tokenPrice} {config.currencySymbol}</p>
                    </div>
                  </div> */}
                  <form className="px-4">
                    <div className="text-center">
                      <Button
                        className="d-none"
                        id="hiddenbutton"
                        data-dismiss="modal"
                        aria-label="Close"
                        data-toggle="modal"
                        data-target="#proceed_modal"
                      >
                        {"Proceed to Claim"}
                      </Button>
                      <Button
                        className="create_btn btn-block"
                        onClick={() => ValidCheck()}
                      >
                        Proceed to Claim
                      </Button>
                      <Button
                        className="btn_outline_red btn-block"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>
                </>
              }
            </div>
          </div>
        </div>
      </div>
      {/* end buy modal */}

      {/* proceed Modal */}
      <div
        className="modal fade primary_modal"
        id="proceed_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="proceed_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="proceed_modalLabel">
                Follow Steps
              </h5>
              <button
                id="buy_closs"
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <p className="mt-0 purchase_text text-center">Claim</p>
                <p className="mt-0 purchase_desc text-center">
                  Send transaction with your wallet
                </p>

                <div className="text-center my-3">
                  {/* <Button className="create_btn btn-block" disabled={Buystate} onClick={()=>{BalCheck()}}> */}

                  {/* Existing functionalitt */}
                  {/* <Button className="create_btn btn-block" disabled={Buystate} onClick={()=>{BalCheck()}}> */}
                  {tokDetails.Bulkmint == 'Bulk' &&
                    <Button className="create_btn btn-block" disabled={SignTxt == "progress" || SignTxt == "done" ? true : false} onClick={() => { signcall() }}>


                      {SignTxt == "progress" && (
                        <i
                          class="fa fa-spinner mr-3 spinner_icon"
                          aria-hidden="true"
                        ></i>
                      )}{(SignTxt == "done") ? "done" : "sign"}
                    </Button>
                  }

                  {/* Newly Added */}
                  {console.log("qweqweqweqpopopopopo", Buystate, SignTxt)}
                  <Button className="create_btn btn-block" disabled={SignTxt == "progress" ? true : false} onClick={() => { PurchaseMint() }}>


                    {Buystate && (
                      <i
                        class="fa fa-spinner mr-3 spinner_icon"
                        aria-hidden="true"
                      ></i>
                    )}{(Buystate) ? "Inprogress" : "Proceed"}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end proceed modal */}
    </>

  )
})