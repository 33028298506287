import axios from "axios";
import config from '../../lib/config';
import Web3 from 'web3';

import { toast } from 'react-toastify';
import isEmpty from "lib/isEmpty";
import { decodedata, encodedata } from "../../hooks/useWallet";
toast.configure();
let toasterOption = config.toasterOption;


export const AddressUserDetails_GetOrSave_Action = async (Payload) => {
	try {
		let Resp = await axios({
			'method': 'post',
			'url': `${config.vUrl}/user/address/details/getorsave`,
			'data': Payload
		})
		console.log("user_token", Resp.data.token);
		localStorage.setItem('user_token', Resp.data.token);
		return {
			data: Resp
		}
	}
	catch (err) {
		console.log("Error in AddressUserDetails_GetOrSave_Action Function", err)
		return {
			// error: err.response.data
		}
	}
}

export const v1_GetProfile = async (Payload) => {
	try {
		let Resp = await axios({
			'method': 'get',
			'url': `${config.vUrl}/user/get/profile`,
			params: Payload
		})
		return {
			data: Resp.data
		}
	}
	catch (err) {
		return {
			data: err
		}
	}
}

export const v1_EditProfile = async (Payload) => {
	try {
		let Resp = await axios({
			'method': 'post',
			'url': `${config.vUrl}/user/edit/profile`,
			'data': Payload,
			'headers': {
				'Authorization': localStorage.user_token
			},
		})
		return {
			data: Resp.data
		}
	}
	catch (err) {
		return {
			data: err
		}
	}
}

export const v1_EditCover = async (Payload) => {
	try {
		let Resp = await axios({
			'method': 'post',
			'url': `${config.vUrl}/user/edit/coverphoto`,
			'data': Payload,
			'headers': {
				'Authorization': localStorage.user_token
			},
		})
		return {
			data: Resp.data
		}
	}
	catch (err) {
		return {
			data: err
		}
	}
}

export const FollowAction = async (payload) => {
	try {
		let resp = await axios({
			'method': 'post',
			'url': `${config.vUrl}/user/follow`,
			data: payload,
			'headers': {
				'Authorization': localStorage.user_token
			},
		});
		return {
			data: resp.data
		}
	}
	catch (err) {
		console.log("ErrorinFollowActionFunction", err)
	}
}

export const v1_MyUSerData = async (payload) => {
	try {
		let resp = await axios({
			'method': 'get',
			'url': `${config.vUrl}/user/myitem/data`,
			params: payload
		});
		return {
			data: resp.data
		}
	}
	catch (err) {
		console.log("Errorinv1_MyUSerDataFunction", err)
	}
}

export const v1_MyComment = async (payload) => {
	try {
		let resp = await axios({
			'method': 'post',
			'url': `${config.vUrl}/user/info/mycomment`,
			data: payload,
			'headers': {
				'Authorization': localStorage.user_token
			},
		});
		return {
			data: resp.data
		}
	}
	catch (err) {
		console.log("Errorinv1_MyCommentFunction", err)
	}
}

export const v1_Sub = async (payload) => {
	try {
		let resp = await axios({
			'method': 'post',
			'url': `${config.vUrl}/user/sub/newsletter`,
			data: payload,
			'headers': {
				'Authorization': localStorage.user_token
			},
		});
		return resp.data
	}
	catch (err) {
		console.log("Errorinv1_SubFunction", err)
	}
}

export const v1_Faq = async (payload) => {
	try {
		let resp = await axios({
			'method': 'get',
			'url': `${config.vUrl}/user/get/faq`,
		});
		return resp.data
	}
	catch (err) {
		console.log("Errorinv1_FaqFunction", err)
	}
}

export const v1_GetPrivacy = async (payload) => {
	try {
		let resp = await axios({
			'method': 'get',
			'url': `${config.vUrl}/user/get/privacy`,
		});
		return resp.data
	}
	catch (err) {
		console.log("Errorinv1_GetPrivacyFunction", err)
	}
}

export const v1_Terms = async (payload) => {
	try {
		let resp = await axios({
			'method': 'get',
			'url': `${config.vUrl}/user/get/Terms`,
		});
		return resp.data
	}
	catch (err) {
		console.log("Errorinv1_TermsFunction", err)
	}
}

export const v1_Social = async (payload) => {
	try {
		let resp = await axios({
			'method': 'get',
			'url': `${config.vUrl}/user/get/Social`,
		});
		return resp.data
	}
	catch (err) {
		console.log("Errorinv1_SocialFunction", err)
	}
}

export const v1_Category = async (payload) => {
	try {
		let resp = await axios({
			'method': 'get',
			'url': `${config.vUrl}/user/get/Category`,
		});
		return resp.data
	}
	catch (err) {
		console.log("Errorinv1_CategoryFunction", err)
	}
}

export const v1_CategorySbt = async (payload) => {
	try {
		let resp = await axios({
			'method': 'get',
			'url': `${config.vUrl}/user/get/SBTCategoryList`,
		});
		return resp.data
	}
	catch (err) {
		console.log("Errorinv1_CategoryFunction", err)
	}
}

export const v1_CategoryFractional = async (payload) => {
	try {
		let resp = await axios({
			'method': 'get',
			'url': `${config.vUrl}/user/get/FractionalCategory`,
		});
		return resp.data
	}
	catch (err) {
		console.log("Errorinv1_CategoryFunction", err)
	}
}

export const v1_GetCustomToken = async (payload) => {
	try {
		let resp = await axios({
			'method': 'get',
			'url': `${config.vUrl}/user/GetCustomToken`,
		});
		return resp.data
	}
	catch (err) {
		console.log("Errorinv1_CategoryFunction", err)
	}
}





export const v1_Ranks = async (Payload) => {
	try {
		let resp = await axios({
			'method': 'get',
			'url': `${config.vUrl}/user/get/Ranks`,
			'params': Payload
		});
		return resp.data
	}
	catch (err) {
		console.log("Errorinv1_RanksFunction", err)
	}
}

export const v1_Activity = async (Payload) => {
	try {
		let resp = await axios({
			'method': 'get',
			'url': `${config.vUrl}/user/get/Activity`,
			'params': Payload
		});
		return resp.data.Record
	}
	catch (err) {
		console.log("Errorinv1_ActivityFunction", err)
	}
}

export const v1_verifyMail = async (Payload) => {
	try {
		let resp = await axios({
			'method': 'post',
			'url': `${config.vUrl}/user/get/verfymail`,
			'data': Payload,
			'headers': {
				'Authorization': localStorage.user_token
			},
		});
		return resp.data
	}
	catch (err) {
		console.log("Errorinv1_verifyMailFunction", err)
	}
}

export const v1_verifyuserMail = async (Payload) => {
	try {
		let resp = await axios({
			'method': 'post',
			'url': `${config.vUrl}/user/user/verfymail`,
			'data': Payload,
			'headers': {
				'Authorization': localStorage.user_token
			},
		});
		return resp.data
	}
	catch (err) {
		console.log("Errorinv1_verifyuserMailFunction", err)
	}
}


//// sign in functionality


export const signinuser = async (Payload) => {
	try {
		let resp = await axios({
			'method': 'post',
			'url': `${config.vUrl}/user/signinuser`,
			'data': Payload,
			'headers': {
				'Authorization': localStorage.user_token
			},
		});
		return resp.data
	}
	catch (err) {
		console.log("Errorinv1_verifyuserMailFunction", err)
	}
}


export const loginuser = async (Payload) => {
	try {
		let resp = await axios({
			'method': 'post',
			'url': `${config.vUrl}/user/loginuser`,
			'data': Payload,
			'headers': {
				'Authorization': localStorage.user_token
			},
		});
		return resp.data
	}
	catch (err) {
		console.log("Errorinv1_verifyuserMailFunction", err)
	}

}




////------> for signup login with fb, google,twitter,telegram,instagram

export const signupfacebook = async (Payload) => {
	console.log("payload facebook", Payload)
	try {
		let resp = await axios({
			'method': 'post',
			'url': `${config.vUrl}/user/authsignin`,
			'data': Payload,
			'headers': {
				'Authorization': localStorage.user_token
			},
		});
		console.log("returned fb reps", resp.data)
		return resp.data
	}
	catch (err) {
		console.log("Errorinv1_verifyuserMailFunction", err)
	}

}


export const getGoogleAccountDetails = async (token) => {

	console.log("axios access_token", token)
	try {
		var resp = await axios({
			'method': 'get',
			'url': `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${token}`,
		})

		console.log("reps google", resp)
		return resp.data

	} catch (err) {
		console.log("error", err)
	}

}


export const signinAuth = async (Payload) => {
	console.log("data for signlog", Payload);
	try {
		var resp = await axios({
			'method': 'post',
			'url': `${config.vUrl}/user/authsigninlogin`,
			'data': Payload,
			'headers': {
				'Authorization': localStorage.user_token
			},
		})

		console.log("repsonse from siglogin", resp.data)
		return resp.data

	} catch (err) {
		console.log("error in signlog auth", err)
	}
}



export const forgotPassswordfn = async (Payload) => {
	console.log("password recovery mail", Payload);
	try {
		var resp = await axios({
			'method': 'post',
			'url': `${config.vUrl}/user/forgotpassword`,
			'data': Payload,
			'headers': {
				'Authorization': localStorage.user_token
			},
		})

		console.log("repsonse from siglogin", resp.data)
		return resp.data

	} catch (err) {
		console.log("error in signlog auth", err)
	}
}


export const passWordChange = async (Payload) => {
	console.log("Change password payload", Payload);
	try {
		var resp = await axios({
			'method': 'post',
			'url': `${config.vUrl}/user/ChangePass`,
			'data': Payload,
			'headers': {
				'Authorization': localStorage.user_token
			},
		})

		console.log("repsonse from Change Pass", resp.data)
		return resp.data

	} catch (err) {
		console.log("error in Change Passs", err)
	}
}


export const twitterfn = async (Payload) => {
	try {
		let resp = await axios({
			'method': 'post',
			'url': `${config.vUrl}/user/twittercheck`,
			'data': Payload,
			'headers': {
				'Authorization': localStorage.user_token
			},
		});
		return resp.data
	}
	catch (err) {
		console.log("Errorinv1_verifyMailFunction", err)
	}

}

export const userget = async (payload) => {
	try {
		let resp = await axios({
			'method': 'get',
			'url': `${config.vUrl}/user/userget`,
			params: payload
		});
		return {
			data: resp
		}
	}
	catch (err) {
	}
}

export const usergetUpdateCountry = async (payload) => {
	try {
		let resp = await axios({
			'method': 'get',
			'url': `${config.vUrl}/user/usergetUpdateCountry`,
			params: payload
		});
		return {
			data: resp
		}
	}
	catch (err) {
	}
}

export const LMWalletCreate = async (payload) => {
	try {
		let resp = await axios({
			'method': 'post',
			'url': `${config.vUrl}/user/seedvalidation`,
			data: payload
		});
		return resp.data
	}
	catch (err) {
	}
}

export const getAllNft = async () => {
	try {
		let resp = await axios({
			'method': 'post',
			'url': `${config.vUrl}/user/getAllNftMoralis`,
			data: payload,
			'headers': {
				'Authorization': localStorage.user_token
			},
		});

		return resp.data
	} catch (error) {
		console.log("errep on get alla nft", error);
	}
}

export const PrivateKeyimport = async (payload) => {
	try {
		let resp = await axios({
			'method': 'post',
			'url': `${config.vUrl}/user/seedimport_privatekey`,
			data: payload,
			'headers': {
				'Authorization': localStorage.user_token
			},
		});

		return resp.data
	}
	catch (err) {
	}
}

export const SelectLMWallet = async (payload) => {
	try {
		let resp = await axios({
			'method': 'post',
			'url': `${config.vUrl}/user/selectLmWallet`,
			data: payload,
			'headers': {
				'Authorization': localStorage.user_token
			},
		});
		return resp.data
	}
	catch (err) {
	}
}


export const GetNftMoralis = async (payload) => {
	try {
		payload = { encode: encodedata(payload) };
		let resp = await axios({
			'method': 'post',
			'url': `${config.vUrl}/LMwallet/getAllNftMoralis`,
			data: payload,
			'headers': {
				'Authorization': localStorage.user_token
			},
		});
		console.log("asnkasdkjsank",resp);
		resp.data = decodedata(resp.data);
		console.log("asdfjkdsnfkjadf",resp.data);
		return resp.data
	}
	catch (err) {
	}
}

export const LmWalletNameChange = async (payload) => {
	try {

		let resp = await axios({
			'method': 'post',
			'url': `${config.vUrl}/user/LmWalletNameChange`,
			data: payload,
			'headers': {
				'Authorization': localStorage.user_token
			},
		});

		return resp.data
	}
	catch (err) {
	}
}

export const sendNft = async (payload) => {
	try {
		
		let resp = await axios({
			'method': 'post',
			'url': `${config.vUrl}/LMwallet/sendNft`,
			data: payload,
			'headers': {
				'Authorization': localStorage.user_token
			},
		});
	
		return resp.data
	}
	catch (err) {
	}
}