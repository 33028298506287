import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import Web3 from 'web3';
import MintingCard from "../Fractions/MintingFractional";
import Calender from "../../components/Calender/Calender";
import axios from "axios";
import $ from "jquery";
import { FractionValidation, ImageValidation } from "actions/controller/validation";
import configImp from "../../lib/config";
import "react-toastify/dist/ReactToastify.css";
import { toast, Slide } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { Account_Connect } from "actions/redux/action";
import { connectWallet } from '../../hooks/useWallet';
import { network } from "../network"
import Abi721 from '../../ABI/721.json'
import Abi1155 from '../../ABI/1155.json'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
    getWhiteListUser, UserPackCheck, LMApproveCheck
} from '../../actions/v1/token';
toast.configure();

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return null;
}

export default function FractionalMint(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const { ...rest } = props;
    const Wallet_Details = useSelector((state) => state.wallet_connect_context);
    console.log("Wallet_DetailsWallet_Details", Wallet_Details);
    const CreatePage = useLocation();
    const CreateMultiple = CreatePage.pathname === "/create-single";
    const InitialForm = {
        name: "",
        description: "",
        quantity: 1,
        properties: "",
        royalties: "",
        curraddress: localStorage.userAddress,
        type: 721,
        contractaddress: '',
        NFTCategory: "",
        TokenPrice: "0",
        Date: '',
        Location: '',
        Section: '',
        NetworkName: '',
        check: '',
        Standard: '',
        TokenGat: '',
        TokenGating: '',
        Fiat: false,
        CharityDetails: [],
        Charity: false,
        BuyType: "Crypto",
        ERC20: "",
        ERCTrade: ""
    };
    const [Formdata, setFormdata] = useState(InitialForm);
    const [PutonSale, setPutonSale] = useState(true);
    const [FixedPrice, setFixedPrice] = useState(false);
    const [Auction, setAuction] = useState(false);
    const [Unlockable, setUnlockable] = useState(false);
    const [USDPrice, setUSDPrice] = useState(0);
    const [TokenPrice, setTokenPrice] = useState(0);
    const [USDTokenPrice, setUSDTokenPrice] = useState(0);
    const [AucCalendar, setAucCalender] = useState("Start");
    const [PicStartselected, setPicStartselected] = useState(false);
    const [AuctionStart, setAuctionStart] = useState(null);
    const [AuctionEnd, setAuctionEnd] = useState(null);
    const [NFTFile, setNFTFile] = useState(null);
    const [NFTFilename, setNFTFilename] = useState(null);
    const [Coinname, setCoinname] = useState('')
    const [config, setconfig] = React.useState(InitialForm);
    const [categorysate, Setcategorysate] = useState({})
    const [Preview, setPreview] = useState(
        require("../../assets/images/lazymintingnew/logo.png")
    );
    const [Standard, setselectStandard] = useState("")
    const [networkoption, setnetworkoption] = React.useState([]);
    const [CategoryOption, setCategoryOption] = useState([{ value: 'ERC721', label: 'ERC721' }, { value: 'Bulkminting', label: 'Bulkminting' }, { value: 'Fractional NFT', label: 'Fractional NFT' }]);
    const [put, setPut] = useState("");
    const [category, Setcategory] = useState("")
    const [ValidationError, setValidationError] = useState(null)
    const [userdet, setUser] = useState();
    const [MinUSD, setMinUSD] = useState(0);
    const [approvecheck, setapprovecheck] = useState(false)
    const [TokenGatingTog, setTokenGating] = useState(false);
    const [Charity, setCharity] = useState(false);
    const [Fiat, setFiat] = useState(false);

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#fff",
            position: "relative",
            top: "3px",
            left: "0px",
            width: "100%",
            maxWidth: "100%",
            color: "#000"
        }),
        option: (provided, state) => ({
            ...provided,
            marginBottom: "5px",
            backgroundColor: "transparent",
            maxWidth: "356px",
            color: "#fff",
            borderBottom: "#fff",
            "&:hover": {
                transition: "all 0.5s ease",
                color: "#e50914"
            },
            "&:active": {
                transition: "all 0.5s ease",
                color: "#000"
            }
        }),
        control: (provided, state) => ({
            ...provided,
            border: "1px solid transparent",
            boxShadow: "0px 0px",
            "&:hover": {
                border: "1px solid transparent",
                boxShadow: "0px 0px",
            },
            "&:focus": {
                border: "1px solid transparent"
            }
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            border: "1px solid transparent"
        })
    }

    useEffect(() => {
        BNBprice();
    }, [Wallet_Details]);

    useEffect(() => {
        // console.log("rtetyrrrrewyrteyt", Wallet_Details?.Category);
        if (Wallet_Details?.Category) {
            var Categorydata = Wallet_Details?.CategoryFractional.filter(Cat => (Cat.BulkStatus !== 'true'))
            // console.log("categoryydatatatata", Categorydata);
            if (Categorydata) {
                Setcategorysate(Categorydata)
            }
        }
        if (Wallet_Details && Wallet_Details.networkoption.length > 0) {
            // console.log("skdvnskdnvjksdnvksdn",Wallet_Details.networkoption);
            setnetworkoption(Wallet_Details.networkoption)
        }
        console.log("sfdweffdfsf", CreateMultiple, userdet);
        //userdet && userdet.length > 0 && userdet[0].Address ==
        if (CreateMultiple) {
            if (Wallet_Details.UserAccountAddr) {
                setCategoryOption([{ value: 'ERC721', label: 'ERC721' }, { value: 'Fractional NFT', label: 'Fractional NFT' }])
            } else {
                // setCategoryOption([{ value: 'ERC721', label: 'ERC721' }])
                setCategoryOption([{ value: 'ERC721', label: 'ERC721' }, { value: 'Fractional NFT', label: 'Fractional NFT' }])
            }
        } else {
            if (Wallet_Details.UserAccountAddr) {
                setCategoryOption([{ value: 'ERC1155', label: 'ERC1155' }, { value: 'Bulkminting', label: 'Bulkminting' }])
            } else {
                // setCategoryOption([{ value: 'ERC1155', label: 'ERC1155' }])
                setCategoryOption([{ value: 'ERC1155', label: 'ERC1155' }, { value: 'Bulkminting', label: 'Bulkminting' }])
            }
        }


    }, [Wallet_Details?.Category]);

    useEffect(() => {
        getWhiteList();
    }, [Wallet_Details.UserAccountAddr])

    const getWhiteList = async () => {
        var reqbody = {
            Address: Wallet_Details.UserAccountAddr
        }
        var test = await getWhiteListUser(reqbody);
        // console.log("mlsvkdnvdfkmnvd", test);
        if (test.Success) setUser(test.userValue);
    };

    const BNBprice = () => {
        var varname = Wallet_Details.networkConfiguration.currencySymbol
        // console.log("asdasdasdzxczxczxczasdasd",varname);
        axios
            .get(
                `https://min-api.cryptocompare.com/data/price?fsym=${varname}&tsyms=USD`
            )
            .then((result) => {
                // console.log("Api Success", result,Coinname);
                if (result.data && result.data.USD && result.data.USD) {
                    var Price = result.data.USD;
                    setUSDPrice(Number(Price));
                    var minusd = 0.0002 * Number(Price)
                    setMinUSD(minusd)
                }
            })
            .catch((error) => {
                console.log("Api Overloaded", error);
            });
    };

    const OnChangehandle = (e) => {
        e.preventDefault();
        const { id, value } = e.target;
        let Form = Formdata;
        Form = { ...Form, ...{ [id]: value } };

        setFormdata(Form);

    };

    const PriceCalculate = (e) => {
        e.preventDefault();
        const { value, id } = e.target;

        console.log("value,USDPrice", value, USDPrice, (1 * value) / USDPrice)
        if (id === "USDTokenPrice") {
            var tokPrice = (1 * value) / USDPrice;
            console.log("sdfsd", tokPrice, (tokPrice).toFixed(3), String((tokPrice).toFixed(3)))
            setUSDTokenPrice(value)
            var Temp = Number(tokPrice).toFixed(4)
            setTokenPrice(String(Temp));
            Formdata.TokenPrice = String((tokPrice).toFixed(3));
            var check = ["AuctionStart", "AuctionEnd", "MinimumBid"];
            check.forEach((e) => (e in Formdata ? delete Formdata[e] : false));
        } else if (id === "MinimumBid") {
            Formdata.MinimumBid = String(value);
            var check = ["TokenPrice"];
            check.forEach((e) => (e in Formdata ? delete Formdata[e] : false));
        } else if (id === "TokenPrice") {
            setTokenPrice(Number(value))
            console.log("token price id value", value, USDPrice)
            Formdata.TokenPrice = Number(value);
            var tokPrice = value * USDPrice;
            setUSDTokenPrice(tokPrice)
        }
    };

    const ChildReply = (ChildData, Calstate) => {
        if (Calstate === "Start") {
            setAuctionStart(ChildData)
            Formdata.AuctionStart = ChildData;
        }
        else {
            setAuctionEnd(ChildData);
            Formdata.AuctionEnd = ChildData;
        }
    };

    const Handlefile = (e) => {
        if (e.target && e.target.files) {
            console.log("dnjcsdanjkda", e.target.files[0]);
            var file = e.target.files[0];
            var fileName = file.name;


            var Resp = ImageValidation(file)
            if (Resp.Success) {
                setNFTFilename(fileName);
                Formdata.ImageName = fileName;
                setNFTFile(file);
                setPreview(URL.createObjectURL(file));
            }
            else
                toast.error(Resp.Message)
        }
    };

    const Validate = async () => {

        console.log("qzxcqwewqzxcxzc", Wallet_Details, localStorage.userAddress, Wallet_Details.UserAccountAddr);
        if (!localStorage.userAddress || localStorage.userAddress !== Wallet_Details.UserAccountAddr) {
            window.$("#triggerlogin").click();
            window.$("div").removeClass('modal-backdrop fade show')

            return toast.error("Login with correct Wallet Address and credentials")

        }
        var SendDatass = {
            Address: Wallet_Details.UserAccountAddr
        }
        var createLimit = await UserPackCheck(SendDatass)
        console.log("asdasdasdasd", createLimit, createLimit.data.data.Message);
        if (createLimit.data.data.Message == "success") {
            console.log("asdsaddasdasd", createLimit.data.data.Message);
            var SingleLimit = createLimit?.data?.data?.UserBalance?.SingleCount
            var MultipleLimit = createLimit?.data?.data?.UserBalance?.MultipleCount
            var checkverify = CreateMultiple ? SingleLimit : MultipleLimit
            console.log("asdssadasdasdasd", checkverify, typeof (checkverify));
            if (Number(checkverify) !== 0) {
                if (Number(checkverify) < 1) {
                    console.log("asdasdasdasdadsas", checkverify);
                    return toast.error("Minting Limit is Low.Please purchase pack")
                }
            } else {
                var mintbal = createLimit?.data?.data?.UserBalance
                if (mintbal.PurchaseDetails.length > 0) {

                    var EmptyArr = []

                    var mapdata = mintbal.PurchaseDetails.map(async (item, index) => {
                        console.log("qewweqwewqeqweqwe", item.CommonCount);

                        EmptyArr.push(Number(item.CommonCount))


                    })
                    if (EmptyArr.length > 0) {
                        var sum = Object.values(EmptyArr).reduce((a, b) => a + b, 0)
                        console.log("EmptyArrEmptyArr", sum, typeof (sum));
                        if (sum == 0) {
                            return toast.error("Minting Limit is Low.Please purchase pack")
                        }
                    }
                } else {
                    return toast.error("Purchase pack to Mint")
                }
                // if (mintbal < 1) {
                //   return toast.error("Minting Limit is Low.Please purchase pack")
                // }
            }
        } else {
            return toast.error("purchase plan to mint")
        }
        var approvedata = {
            curradd: Wallet_Details.UserAccountAddr,
            tradeadd: Wallet_Details.networkConfiguration.TradeContract
        }
        // var check = await orderApprovecheck(approvedata);
        // console.log("dnfksdjnfkjsdnfk", check);

        // setapprovecheck(check)

        // Formdata.check = check
        const id2 = toast.loading("Validating Details", { transition: Slide, theme: "dark" });
        var SaleType = (PutonSale) ? (FixedPrice) ? "Fixed" : (Auction) ? "Auction" : "Unlimited" : PutonSale
        Formdata.File = NFTFile
        Formdata.Standard = CreateMultiple ? "ERC721" : "ERC1155"
        Formdata.TokenGat = TokenGatingTog
        Formdata.Charity = Charity
        Formdata.TokenPrice = Fiat || PutonSale ? Formdata.TokenPrice : "0";
        Formdata.coin
        Formdata.CharityDetails = Charity ? [] : []
        Formdata.Fiat = Fiat
        Formdata.BuyType = Fiat ? SaleType = "Fiat" : "Crypto"
        console.log("validate", Formdata, Fiat)
        var result = await FractionValidation(Formdata, PutonSale, SaleType, Formdata.BuyType)
        console.log("qweertrtrtrrtr", result);
        // console.log("qwerrttttttwerweiofjowd", PutonSale);
        Formdata.PutonSale = PutonSale
        Formdata.SaleType = SaleType
        // console.log("m vmksfvkdsfkm",Coinname);
        Formdata.Coinname = Fiat ? SaleType = "USD" : Coinname

        console.log("nsfgkfngkfsj", Formdata);
        Formdata.contractaddress = Wallet_Details.networkConfiguration.Fraction721Contract
        Formdata.ERCTrade = Wallet_Details.networkConfiguration.FractionTradeContract
        setTimeout(() => {
            if (result.Success) {
                toast.update(id2, { render: "Validation Success", type: "success", isLoading: false, autoClose: 2500 });
                // console.log("resultttttt errorrr validaterrrrrr", result);
                setValidationError(result)
                $('#MintcardClick').trigger("click");
            }
            else {
                toast.update(id2, { render: "Validation Failed", type: "error", isLoading: false, autoClose: 2500 });
                setValidationError(result)
            }
        }, 1000);
    }

    const selectChange = async (e) => {
        Formdata.NetworkName = e.value == configImp.Network_ETH ? Number(configImp.ETH_ID) : e.value == configImp.Network_CAM ? Number(configImp.CAM_ID) : Number(configImp.MATIC_ID)
        // Formdata.Coinname = e.value == "binance" ? "ETH" : "MATIC"
        var coinnn = e.value == configImp.Network_ETH ? configImp.Currency_ETH : e.value == configImp.Network_CAM ? configImp.Currency_CAM :configImp.Currency_MATIC
        setCoinname(coinnn)
        if (e.name == "networkname") {
            if (e.name == "networkname" && e.value == configImp.Network_ETH) {
                console.log("comming on binance");
                var configdata = network.ETH
                Wallet_Details.networkConfiguration = network.ETH
                var accountDetails = await switchNetwork(configdata, Number(configImp.ETH_ID))
                setconfig(configdata)
            }
            else if (e.name == "networkname" && e.value == configImp.Network_MAT) {
                var configdata = network.MATIC
                Wallet_Details.networkConfiguration = network.MATIC
                var accountDetails = await switchNetwork(configdata, Number(configImp.MATIC_ID))
                setconfig(configdata)
            }
            else if (e.name == "networkname" && e.value == configImp.Network_CAM) {
                var configdata = network.CAM
                Wallet_Details.networkConfiguration = network.CAM
                var accountDetails = await switchNetwork(configdata, Number(configImp.CAM_ID))
                setconfig(configdata)
                setTimeout(() => {
                    console.log("hiuyiuyututry");
                    $('#Dummymodals').trigger("click");
                }, 800);
            }

        }
    }

    async function switchNetwork(configdata, networkNUM) {
        var type = ""
        var networkConfiguration = {}
        const chainId = await Wallet_Details.Web3Pro.eth.getChainId();
        console.log("adsadasdasd", configdata.Chainid, chainId);
        console.log("dfadsaddasdadas", Wallet_Details, localStorage.walletConnectType);
        if (localStorage.walletConnectType !== "Torus") {
            if (Number(configdata.Chainid) !== Number(chainId)) {
                const id = toast.loading("Switching Network", { closeButton: true, theme: "dark" });
                if (configdata) {
                    if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask') {

                        type = "MetaMask"
                    }
                    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'WalletConnect' && localStorage.walletConnectType != null) {
                        type = "WalletConnect"
                    }
                    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'Venly' && localStorage.walletConnectType != null) {
                        type = "Venly"
                    }
                    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'Torus' && localStorage.walletConnectType != null) {
                        console.log("adasd comming on torus");
                        type = "Torus"
                    }
                    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'TorusWallet' && localStorage.walletConnectType != null) {
                        console.log("adasd comming on torus");
                        type = "TorusWallet"
                    }
                    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'LMWallet' && localStorage.walletConnectType != null) {
                        console.log("adasd comming on torus");
                        type = "LMWallet"
                        localStorage.setItem("ChainId", networkNUM)
                    }
                    window.$('#connect_modal').modal('hide');
                    console.log("connecttype............", type, configdata.Chainid, networkNUM, localStorage.walletConnectType)

                    var accountDetails = await connectWallet(localStorage.walletConnectType, configdata.Chainid, "Create", Wallet_Details, "Tor")
                    console.log("accountDetailsin create page......................", accountDetails)

                    const chainId = await accountDetails?.web3?.eth?.getChainId();
                    console.log("fghdtgj", configdata, network, chainId);

                    if (configdata.Chainid === network.MATIC.Chainid) {
                        networkConfiguration = network.MATIC
                    }
                    else if (configdata.Chainid === network.ETH.Chainid) {
                        networkConfiguration = network.ETH
                    }
                    else if (configdata.Chainid === network.CAM.Chainid) {
                        networkConfiguration = network.CAM
                    }
                    if (accountDetails != '') {
                        dispatch({
                            type: Account_Connect,
                            Account_Detail: {
                                UserAccountAddr: accountDetails?.accountAddress,
                                UserAccountBal: accountDetails?.coinBalance,
                                WalletConnected: "true",

                                Wen_Bln: accountDetails?.tokenBalance,
                                Accounts: accountDetails?.accountAddress,

                                Web3Pro: accountDetails?.web3,
                                providerss: accountDetails?.web3?._provider,
                                networkConfiguration: networkConfiguration
                            }
                        })
                    }
                }
                if (networkConfiguration.currencySymbol) {
                    toast.update(id, {
                        render: "Network switched Succesfully",
                        type: "success",
                        isLoading: false,
                        autoClose: 2500,
                        closeButton: true,
                        theme: "dark"
                    });
                }
                return accountDetails
            }
            else {
                dispatch({
                    type: Account_Connect,
                    Account_Detail: {
                        UserAccountAddr: Wallet_Details?.UserAccountAddr,
                        UserAccountBal: Wallet_Details?.UserAccountBal,
                        WalletConnected: "true",

                        Wen_Bln: Wallet_Details?.Wen_Bln,
                        Accounts: Wallet_Details?.Accounts,

                        Web3Pro: Wallet_Details?.Web3Pro,
                        providerss: Wallet_Details?.providerss,
                        networkConfiguration: configdata
                    }
                })
                return Wallet_Details.Web3Pro
            }
        } else {
            try {
                const id = toast.loading("Switching Network", { closeButton: true, theme: "dark" });
                var accountDetails = await connectWallet(localStorage.walletConnectType, configdata.Chainid, "Create", Wallet_Details, "Torus")
                console.log("asdasdasdasdas", accountDetails);
                const chainId = await accountDetails?.web3?.eth?.getChainId();
                console.log("fghdtgj", configdata, network, chainId);

                if (configdata.Chainid === network.MATIC.Chainid) {
                    networkConfiguration = network.MATIC
                }
                else if (configdata.Chainid === network.ETH.Chainid) {
                    networkConfiguration = network.ETH
                }
                else if (configdata.Chainid === network.CAM.Chainid) {
                    networkConfiguration = network.CAM
                }
                if (accountDetails != '') {
                    dispatch({
                        type: Account_Connect,
                        Account_Detail: {
                            UserAccountAddr: accountDetails?.accountAddress,
                            UserAccountBal: accountDetails?.coinBalance,
                            WalletConnected: "true",

                            Wen_Bln: accountDetails?.tokenBalance,
                            Accounts: accountDetails?.accountAddress,

                            Web3Pro: accountDetails?.web3,
                            providerss: accountDetails?.web3?._provider,
                            networkConfiguration: networkConfiguration
                        }
                    })
                }
                var functiobn = await NefunctionTriger(Wallet_Details)

                if (networkConfiguration.currencySymbol) {
                    toast.update(id, {
                        render: "Network switched Succesfully",
                        type: "success",
                        isLoading: false,
                        autoClose: 2500,
                        closeButton: true,
                        theme: "dark"
                    });
                }
            } catch (e) {
                console.log("adqeqweqweqweqweqweqwe", e);
            }
        }



    }

    const NefunctionTriger = async (Wallet_Details1) => {
        console.log("Wallet_Details1", Wallet_Details1);
        const chainIdSwitched = await Wallet_Details1?.Web3Pro?.eth?.getChainId();
        var balance = await Wallet_Details1?.Web3Pro?.eth.getBalance(Wallet_Details1.UserAccountAddr);
        console.log("chainIdSwitched", chainIdSwitched, balance);
        return chainIdSwitched
    }

    // async function orderApprovecheck(item) {
    //     try {
    //         console.log("dcasdsaadadasdas");
    //         if (localStorage.walletConnectType !== "LMWallet") {
    //             if (Wallet_Details.providerss == null) {
    //                 toast.warning("OOPS!..connect Your Wallet");
    //                 return false;
    //             }
    //             // console.log("adndakmnka",CreateMultiple);
    //             var web3 = new Web3(Wallet_Details.Web3Pro);
    //             var MultiContract = new web3.eth.Contract(
    //                 (CreateMultiple ? Abi721 : Abi1155),
    //                 CreateMultiple ? Wallet_Details.networkConfiguration.singleContract : Wallet_Details.networkConfiguration.multipleContract
    //             )
    //             var status = await MultiContract.methods.isApprovedForAll(
    //                 Wallet_Details.UserAccountAddr,
    //                 Wallet_Details.networkConfiguration.TradeContract
    //             ).call();
    //             // console.log("OrderApprove Check", status);
    //             return status
    //         } else {
    //             console.log("Comming on elsee bavkend calll", Formdata.NetworkName);
    //             var Arg = {
    //                 Method: "isApprovedForAll",
    //                 Data: [Wallet_Details.UserAccountAddr, Wallet_Details.networkConfiguration.TradeContract],
    //                 Mail: localStorage.loggedinaddress,
    //                 LoginAddress: localStorage.userAddress,
    //                 ContactAddress: CreateMultiple ? Wallet_Details.networkConfiguration.singleContract : Wallet_Details.networkConfiguration.multipleContract,
    //                 Type: CreateMultiple ? "721" : "1155",
    //                 Chain: Formdata.NetworkName,
    //                 IntractContract: CreateMultiple ? Wallet_Details.networkConfiguration.singleContract : Wallet_Details.networkConfiguration.multipleContract,
    //                 ContactType: CreateMultiple ? "Single" : "Multiple",
    //                 Key: null,
    //             }
    //             console.log("ArgArgArg", Arg);
    //             var Balance = await LMApproveCheck(Arg)
    //             console.log("Balance", Balance);
    //             return Balance.data.receipt
    //         }
    //     }
    //     catch (e) {
    //         console.log("OrderApprove Check", e);

    //         return false

    //     }

    // }

    const handleKeyDown = (e) => {
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            e.preventDefault();
        }
    };

    return (

        <div className="home_header create_single_mulitple">
            <Button className="
      d-none" data-toggle="modal" data-target="#register_modal" id="triggerlogin" ></Button>

            <Header
                color="transparent"
                routes={dashboardRoutes}
                brand={
                    <Link to="/home">
                        <div className="header_logo_align">


                        </div>
                    </Link>
                }
                leftLinks={<HeaderLinks />}
                changeColorOnScroll={{
                    height: 20,
                    color: "white",
                }}
                {...rest}
            />
            <ScrollToTopOnMount />
            <div className={classes.pageHeader + " inner_pageheader inner_pagh_bg_2"}>

                <div className={classes.container}>

                    <GridContainer>

                        <GridItem xs={12} sm={12} md={12}>
                            <p class="heading_big_blk p-b-sec"> <span><Link to="/create" className="mr-3"><i class="fas fa-long-arrow-alt-left" /></Link></span>Create Fractional NFT</p>

                        </GridItem>

                    </GridContainer>
                </div>

                <div className="container">
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                            <label className="heading_sm_blk_new">Preview</label>
                            <div className="single_collectible masonry mx-0">
                                <div className="item">
                                    <div className="card_main_iner">
                                        <div className="card_inner_item pos-reltv">
                                            <div className="item_inner_img">
                                                <div className="like_div_round">
                                                    <i className="far fa-heart liked"></i>
                                                </div>
                                                {NFTFilename &&
                                                    NFTFilename.split(".").pop() == "mp4" ? (
                                                    <video
                                                        src={Preview}
                                                        alt="collections"
                                                        autoPlay={true}
                                                        muted={true}
                                                        controls disablePictureInPicture controlsList="nodownload"
                                                    />
                                                ) : (
                                                    <img
                                                        src={Preview}
                                                        alt="Collections"
                                                        className="img-fluid lazy_logo_align"
                                                    />
                                                )}
                                            </div>
                                            <div className="img_desc_nft px-3 py-3">
                                                <img
                                                    src={require("../../assets/images/lazymintingnew/footer_logo.png")}
                                                    alt="User"
                                                    className="img-fluid profiel_img_pos "
                                                />
                                                <img
                                                    src={require("../../assets/images/star.png")}
                                                    alt="Collections"
                                                    className="img-fluid star_img_blue"
                                                />

                                                <div className="d-flex justify-content-between mt-2">
                                                    <h2>
                                                        {Formdata.name == "" ? "TokenName" : (Formdata.name).toString().slice(0, 20) + "..."}
                                                    </h2>
                                                    <div>
                                                        <h3 className="my-2">
                                                            {FixedPrice ?
                                                                <span>{Number(TokenPrice).toFixed(4)} {Fiat ? "Fiat" : Coinname}</span>
                                                                :
                                                                <badge className="badge badge-dark badge-timer mb-1 make_offer_badge">Token Price</badge>
                                                            }
                                                        </h3>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={8}>
                            <form className="formCls pb-5">
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <div className="d-flex justify-content-between align-items-start">
                                            <div>
                                                <label className="primary_label" htmlFor="inputEmail4">
                                                    Upload file
                                                </label>
                                                <p className="form_note">
                                                    PNG, GIF, WEBP or MP4. Max 30mb.
                                                </p>
                                                {ValidationError && ValidationError.File &&
                                                    <p className="Warning_label">{ValidationError && ValidationError.File}</p>
                                                }
                                            </div>
                                            <div className="file_btn btn ">
                                                {NFTFile == null ? "Upload" : "Uploaded"}
                                                <input
                                                    className="inp_file"
                                                    type="file"
                                                    name="file"
                                                    autoComplete="off"
                                                    onChange={(e) => Handlefile(e)}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-12 p-0">

                                    <label className="primary_label" htmlFor="category">
                                        Select Network<span className="text-danger">*</span>
                                    </label>
                                    {/* { Wallet_Details?.Category.filter(items => (items.label != "Ticket"))} */}
                                    {/* {console.log("qweqweqweqwwe", categorysate)} */}
                                    <Select
                                        className="yes1 form-control primary_inp select1 selxet_app"
                                        onChange={(e) => selectChange(e)}
                                        options={networkoption}
                                        label="Single select"
                                        isSearchable={false}
                                        styles={customStyles}

                                        classNamePrefix="react-select"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                    />
                                    {ValidationError && ValidationError.NetworkName &&
                                        <p className="Warning_label">{ValidationError && ValidationError.NetworkName}</p>
                                    }
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <div className="d-flex justify-content-between align-items-start grid_toggle">
                                            <div>
                                                <label className="primary_label" htmlFor="inputEmail4">
                                                    Fiat
                                                </label>
                                                <p className="form_note">
                                                    You’ll receive Fiat Currency on this item
                                                </p>
                                            </div>
                                            <label className="switch toggle_custom">
                                                <input
                                                    type="checkbox"
                                                    onChange={() =>
                                                        setFiat((prevState) => !prevState)
                                                    }
                                                />
                                                <span className="slider"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mx-0 mt-3 fixed_price_sec">
                                    <label className="primary_label" htmlFor="price_new">
                                        Price<span className="text-danger">*</span>
                                    </label>
                                    <div className="form-row w-100">
                                        <div className="form-group col-md-6">
                                            <div className="input-group input_grp_style_1 min_h_45" onClick={() =>
                                                setFixedPrice(
                                                    (prevState) => !prevState,
                                                    setAuction(false),
                                                    setPut("fixed_price")
                                                )
                                            }
                                            >
                                                <input
                                                    type="number"
                                                    id="TokenPrice"
                                                    className="form-control"
                                                    onChange={(e) => PriceCalculate(e)}
                                                    // value={(Number(TokenPrice))}
                                                    placeholder="Enter your Price"
                                                    aria-label="TokenPrice"
                                                    aria-describedby="basic-addon2"
                                                    onKeyDown={handleKeyDown}
                                                    onWheel={(e) => e.target.blur()}
                                                />
                                                <div className="input-group-append">
                                                    <span
                                                        className="input-group-text pl-0 min_h_45_px tokenpeorcs"
                                                        id="basic-addon2"
                                                    >

                                                    </span>

                                                </div>
                                            </div>
                                            {ValidationError && ValidationError.TokenPrice &&
                                                <p className="Warning_label">{ValidationError && ValidationError.TokenPrice}</p>
                                            }
                                        </div>
                                    </div>

                                    {console.log("afdasdasdasdas", Wallet_Details)}
                                    <p className="form_note pb-2">
                                        Service fee{" "}
                                        <span className="font_we_700_note_txt">2.5%</span>
                                        {/* <br /> You will receive{" "}
                            <span className="font_we_700_note_txt">0B OPEN</span> */}
                                    </p>
                                </div>



                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <div className="d-flex justify-content-between align-items-start grid_toggle">
                                            <div>
                                                <label className="primary_label" htmlFor="inputEmail4">
                                                    Unlock Once Purchased
                                                </label>
                                                <p className="form_note">
                                                    Content will be unlocked after successful transaction
                                                </p>
                                            </div>
                                            <label className="switch toggle_custom">
                                                <input
                                                    type="checkbox"
                                                    onChange={() =>
                                                        setUnlockable((prevState) => !prevState)
                                                    }
                                                />
                                                <span className="slider"></span>
                                            </label>
                                        </div>
                                        {Unlockable && (
                                            <div className="form-group unlock_sec mt-4">
                                                <input
                                                    type="text"
                                                    className="form-control primary_inp mb-1"
                                                    id="digitalkey"
                                                    autoComplete="off"
                                                    onChange={(e) => {
                                                        Formdata.UnlockContent = e.target.value;
                                                    }}
                                                    placeholder="Digital key, code to redeem or link to a file.."
                                                />
                                                <p class="form_note mt-4">
                                                    Tip: Unlock Feature Promotes your sale
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label className="primary_label" htmlFor="name">
                                            Name<span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control primary_inp"
                                            id="name"
                                            autoComplete="off"
                                            onChange={(e) => OnChangehandle(e)}
                                            placeholder="e.g. Redeemable"
                                        />
                                        {ValidationError && ValidationError.name &&
                                            <p className="Warning_label">{ValidationError && ValidationError.name}</p>
                                        }
                                    </div>

                                    <div className="form-group col-md-6">
                                        <label className="primary_label" htmlFor="desccription">
                                            Description <span className="text-muted">(Optional)</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control primary_inp"
                                            id="description"
                                            autoComplete="off"
                                            onChange={(e) => OnChangehandle(e)}
                                            placeholder="e.g. After purchasing..."
                                        />
                                    </div>


                                </div>


                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label className="primary_label" htmlFor="royalties">
                                            Royalties <span className="text-muted">(Max:20%)</span><span className="text-danger">*</span>
                                        </label>
                                        <div className="inp_grp">
                                            <input
                                                type="number"
                                                className="form-control primary_inp"
                                                id="royalties"
                                                autoComplete="off"
                                                max={20}
                                                min={1}
                                                onChange={(e) => OnChangehandle(e)}
                                                placeholder="Eg. 5%, 10%, 15%,..20%"
                                                onKeyDown={handleKeyDown}
                                                onWheel={(e) => e.target.blur()}
                                            />
                                            <p className="inp_append">%</p>
                                        </div>
                                        {ValidationError && ValidationError.royalties &&
                                            <p className="Warning_label">{ValidationError && ValidationError.royalties}</p>
                                        }
                                    </div>
                                    <div className="form-group col-md-6">

                                        <label className="primary_label" htmlFor="category">
                                            Category<span className="text-danger">*</span>
                                        </label>
                                        <Select
                                            className="yes1 form-control primary_inp select1 selxet_app"
                                            onChange={(e) => Formdata.NFTCategory = e.name}
                                            options={categorysate}
                                            label="Single select"
                                            classNamePrefix="react-select"
                                            isSearchable={false}
                                            styles={customStyles}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                        {ValidationError && ValidationError.NFTCategory &&
                                            <p className="Warning_label">{ValidationError && ValidationError.NFTCategory}</p>
                                        }
                                    </div>

                                </div>
                                <div className="form-row">

                                    <div className="form-group col-md-6">
                                        <label className="primary_label" htmlFor="quantity">
                                            Fractionals <span className="text-muted">(Max:100)</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control primary_inp"
                                            id="quantity"
                                            autoComplete="off"
                                            onChange={(e) => OnChangehandle(e)}
                                            placeholder="Enter No of Fractionals"
                                        />
                                    </div>

                                    <div className="form-group col-md-6">
                                        <label className="primary_label" htmlFor="properties">
                                            Properties <span className="text-muted">(Optional)</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control primary_inp"
                                            id="properties"
                                            autoComplete="off"
                                            onChange={(e) => OnChangehandle(e)}
                                            placeholder="e.g. size"
                                        />
                                    </div>
                                </div>
                                <div>
                                    {(Wallet_Details.UserAccountAddr !== "") ?
                                        <Button
                                            className="create_btn mt-3"
                                            onClick={() => Validate()}
                                        >
                                            Create item
                                        </Button>
                                        :
                                        <Link to="/connect-wallet">
                                            <Button className="create_btn mt-3">Connect Wallet</Button>
                                        </Link>
                                    }
                                </div>
                            </form>
                        </GridItem>
                    </GridContainer>
                </div>
                <button id="MintcardClick" className="d-none" data-target="#create_item_modal" data-toggle="modal"></button>
                <button id="Dummymodals" className="d-none" data-target="#dummy_modal" data-toggle="modal"></button>

            </div>
            {/* {console.log("mdflsdmflsdlfmsd",Formdata,Wallet_Details)} */}
            <MintingCard NFTDetails={Formdata} />
            <Calender CalenderState={AucCalendar} ParentCall={ChildReply} />
            <Footer />
            {/* learn Modal */}
            <div
                class="modal fade primary_modal"
                id="learn_modal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="learn_modalCenteredLabel"
                aria-hidden="true"
                data-backdrop="static"
                data-keyboard="false"
            >
                <div
                    class="modal-dialog modal-dialog-centered modal-sm"
                    role="document"
                >
                    <div class="modal-content">
                        <div class="modal-header text-center">
                            <h5 class="modal-title" id="learn_modalLabel">
                                How timed auction work
                            </h5>
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p>
                                When you put your item on timed auction, you choose currency,
                                minimum bid, starting and ending dates of your auction.
                            </p>

                            <p>
                                The bidder can only place a bid which satisfies the following
                                conditions:
                            </p>
                            <ol>
                                <li>It is at least minimum bid set for the auction</li>
                                <li>
                                    It is at least 5% higher than the current highest bid or it is
                                    at least 2.3B OPEN higher than the current highest bid
                                </li>
                            </ol>

                            <p>
                                Note that some bids may disappear with time if the bidder
                                withdraws their balance. At the same time, some bids may
                                reappear if the bidder has topped up their balance again.
                            </p>

                            <p>
                                Auction cannot be cancelled after any valid bid was made. Any
                                bid placed in the last 10 minutes extends the auction by 10
                                minutes.
                            </p>

                            <p>
                                In the 48 hours after the auction ends you will only be able to
                                accept the highest available bid placed during the auction. As
                                with regular bids, you will need to pay some gas to accept
                                it.Note that you can always decrease the price of your listing
                                for free, without making a transaction or paying gas. You can
                                view all your items here.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* end learn modal */}


{/* dummy modal */}

<div
        class="modal fade primary_modal"
        id="dummy_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="dummy_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="dummy_modalLabel">
                Important Note:
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p className="mb-4">
              Steps to Mint Fractional NFTs on the Camino Network

Complete Camino KYC Verification
<br></br><br></br>
Step1 :  
Click here to access the Camino KYC verification page.
Follow the provided instructions to submit your identification documents and proof of address.
<br></br><br></br>
Step2 : 
Wait for your KYC verification to be approved by Camino.
Once KYC is Approved
<br></br><br></br>
Step3 : 
Import Verified Wallet

Once KYC is approved, ensure your wallet is connected to the Camino network.
Import the wallet that has been verified by Camino into our platform.
<br></br><br></br>
Step4 : 
Return to our platform to proceed with creating Fractional NFTs.
              </p>
              
              <div className="text-center mb-3 mt-3">
                <a href="https://suite.camino.network/login" target="_blank"><button type="button" className="create_btn">Create camino wallet</button></a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* dummy modal end */}

        </div>

    );
}
