import Loder from '../assets/images/loader.png'
import axios from 'axios';
import { network } from "../views/network"

let fee1 = '';
const singleType = 721;
const multipleType = 1155;
const maxLength = 6;
let WenlamboConvertVal = 0;
let PurchaseTransferType = 'currency';
let BNBPROVIDER = "";
let Front_URL = '';
let Back_URL = '';
let Users_URL = '';
let v1Url = '';
let limit = '8';
let Lod = Loder;
let limitMax = '3010';
let toFixed = 6;
let tokenFee = 0;
// let IPFS_IMG = "https://ipfs.io/ipfs";
let IPFS_IMG = "https://lazyminter.infura-ipfs.io/ipfs";
let IPFS = "https://lazyminter.infura-ipfs.io/ipfs/";
let GeoLocation = "https://geolocation-db.com/json/";
let PolygonbulkCon = "0x661a9a8c68e301ac1b0c4d005a5c1c748fb57b5c"
let ScannerPolygon = "";
let ScannerBinance = "";
let ScannerCamino = "";
let ETH_ID = "";
let MATIC_ID = "";
let CAM_ID = "";
let Network_MAT = "";
let Network_ETH = "";
let Network_CAM = "";
let Network_MAT_Cap = "";
let Network_ETH_Cap = "";
let Network_CAM_Cap = "";
let Currency_ETH = "";
let Currency_MATIC = "";
let Currency_CAM = "";
let web3AuthNetwork = "";
let PolygonChainIds = "";
let EthereumChainIds = "";
let CaminoChainIds = "";
let rpcTargetPolygon = "";
let rpcTargetEthereum = "";
let rpcTargetCamino = "";
let tickerNamePolygon = "";
let tickerNameEthereum = "";
let tickerNameCamino = "";

// var PrivateKey = "";
// var AdminAddress = "";
// let PayPalKey = ""
// let Web3AuthClientID = ""
var encodekey = "LMwallet@Lm"
var PrivateKey = process.env.REACT_APP_PRIVATEKEY
var AdminAddress = (process.env.REACT_APP_ADMIN_ADDRESS).toLowerCase();
let PayPalKey = process.env.REACT_APP_PAYPAL_KEY
//"AcyZ2tY8EqMexs82MUOhG4Io1-_GxOe2XlRgtabuAf8VoOtheXkM6pJBN4bu0iN7JgDAS_GycGfq4WPm";
let Web3AuthClientID = process.env.REACT_APP_WEB3_AUTH_CLIENTID
//"BJNPN4rwFBDyKHIA39E70tpzdc_LWOr69JM4Y_I9MFmhvyZ400du2rknToRZlpt5zu5q-xrnDTwa9p_OSiEqwcU"

let Bscscan = null;
var nameFormat = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

var shareTag = [];

var toasterOption = {
  position: "top-right",
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
  limit: 1
}
var providercon = null;
var buyerfee = 0;
var sellerfee = 0;
const exchangeAddress = "";
var Network = ''
let EnvName = 'production';
let fee = fee1;
var currAdrress = '';
if (EnvName === "production") {
  Front_URL = 'https://lazyminter.com';
  Back_URL = 'https://api.lazyminter.com';
  v1Url = 'https://api.lazyminter.com/v1';
  Users_URL = 'https://api.lazyminter.com/user';
  Network = network.MATIC
  ScannerPolygon = "https://polygonscan.com/";
  ScannerBinance = "https://mainnet.infura.io/v3/a6738b5a17514327ae8c9afc47b6179c/";
  ScannerCamino = "https://caminoscan.com/"
  ETH_ID = "0x1";
  MATIC_ID = "137";
  CAM_ID ="500"
  Network_MAT = "polygon";
  Network_ETH = "ethereum";
  Network_CAM = "camino";
  Network_MAT_Cap = "Polygon";
  Network_ETH_Cap = "Ethereum";
  Network_CAM_Cap = "Camino";
  Currency_ETH = "ETH";
  Currency_MATIC = "MATIC";
  Currency_CAM= "CAM"
  web3AuthNetwork = "mainnet";
  PolygonChainIds = "0x89";
  EthereumChainIds = "0x1";
  CaminoChainIds = "0x1f4"
  rpcTargetPolygon = "https://polygon-mainnet.infura.io/v3/a6738b5a17514327ae8c9afc47b6179c";
  rpcTargetEthereum = "https://api.camino.network/ext/bc/C/rpc";
  rpcTargetCamino = "https://polygon-mainnet.infura.io/v3/a6738b5a17514327ae8c9afc47b6179c";
  tickerNamePolygon = "Polygon Mainnet";
  tickerNameEthereum = "Ethereum mainnet"
  tickerNameCamino = "Camino C-Chain"
}
else if (EnvName === "demo") {
  Front_URL = 'https://staging.lazyminter.com';
  Users_URL = 'https://apistaging.lazyminter.com/user';
  Back_URL = 'https://apistaging.lazyminter.com';
  v1Url = 'https://apistaging.lazyminter.com/v1';
  Network = network.MATIC;
  ScannerPolygon = "https://www.oklink.com/amoy/";
  ScannerBinance = "https://sepolia.etherscan.io/";
  ScannerCamino = "https://suite.camino.network/explorer/"
  ETH_ID = "11155111";
  MATIC_ID = "80002";
  CAM_ID ="501"
  Network_MAT = "amoy";
  Network_ETH = "sepolia";
  Network_CAM = "camino";
  Network_MAT_Cap = "Amoy";
  Network_ETH_Cap = "Sepolia";
  Network_CAM_Cap = "Camino";
  Currency_ETH = "ETH";
  Currency_MATIC = "MATIC";
  Currency_CAM= "CAM"
  web3AuthNetwork = "testnet";
  PolygonChainIds = "0x13881";
  EthereumChainIds = "0xaa36a7";
  CaminoChainIds = "0x1f5"
  rpcTargetPolygon = "https://rpc-amoy.polygon.technology/";
  rpcTargetEthereum = "https://sepolia.infura.io/v3/b1b3f95471bb4a728aa9558e6f1ab569";
  rpcTargetCamino = "https://columbus.camino.network/ext/bc/C/rpc";
  tickerNamePolygon = "amoy";
  tickerNameEthereum = "sepolia"
  tickerNameCamino = "camino"
}
else if (EnvName === "demoVib") {
  Front_URL = 'http://nftdemo.bimaticz.com/Lazyminter';
  Users_URL = 'http://nftdemo.bimaticz.com:3006/user';
  Back_URL = 'http://nftdemo.bimaticz.com:3006';
  v1Url = 'http://nftdemo.bimaticz.com:3006/v1';
  Network = network.MATIC
  ScannerPolygon = "https://www.oklink.com/amoy/";
  ScannerBinance = "https://sepolia.etherscan.io/";
  ScannerCamino = "https://suite.camino.network/explorer/"
  ETH_ID = "11155111";
  MATIC_ID = "80002";
  CAM_ID ="501"
  Network_MAT = "amoy";
  Network_ETH = "sepolia";
  Network_CAM = "camino";
  Network_MAT_Cap = "Amoy";
  Network_ETH_Cap = "Sepolia";
  Network_CAM_Cap = "Camino";
  Currency_ETH = "ETH";
  Currency_MATIC = "MATIC";
  Currency_CAM= "CAM"
  web3AuthNetwork = "testnet";
  PolygonChainIds = "0x13881";
  EthereumChainIds = "0xaa36a7";
  CaminoChainIds = "0x1f5"
  rpcTargetPolygon = "https://rpc-amoy.polygon.technology/";
  rpcTargetEthereum = "https://sepolia.infura.io/v3/b1b3f95471bb4a728aa9558e6f1ab569";
  rpcTargetCamino = "https://columbus.camino.network/ext/bc/C/rpc";
  tickerNamePolygon = "amoy";
  tickerNameEthereum = "sepolia"
  tickerNameCamino = "camino"
}
else {
  Front_URL = 'http://localhost:3003';
  Back_URL = 'http://localhost:7001';
  v1Url = 'http://localhost:7001/v1';
  Users_URL = 'http://localhost:3010/user';
  Network = network.CAM
  ScannerPolygon = "https://www.oklink.com/amoy/";
  ScannerBinance = "https://sepolia.etherscan.io/";
  ScannerCamino = "https://columbus.caminoscan.com/"
  ETH_ID = "11155111";
  MATIC_ID = "80002";
  CAM_ID ="501"
  Network_MAT = "amoy";
  Network_ETH = "sepolia";
  Network_CAM = "camino";
  Network_MAT_Cap = "Amoy";
  Network_ETH_Cap = "Sepolia";
  Network_CAM_Cap = "Camino";
  Currency_ETH = "ETH";
  Currency_MATIC = "MATIC";
  Currency_CAM= "CAM"
  web3AuthNetwork = "testnet";
  PolygonChainIds = "0x13881";
  EthereumChainIds = "0xaa36a7";
  CaminoChainIds = "0x1f5"
  rpcTargetPolygon = "https://rpc-amoy.polygon.technology/";
  rpcTargetEthereum = "https://sepolia.infura.io/v3/b1b3f95471bb4a728aa9558e6f1ab569";
  rpcTargetCamino = "https://columbus.camino.network/ext/bc/C/rpc";
  tickerNamePolygon = "amoy";
  tickerNameEthereum = "sepolia"
  tickerNameCamino = "Camino C-Chain"
  // ScannerPolygon = "https://polygonscan.com/";
  // ScannerBinance = "https://mainnet.infura.io/v3/a6738b5a17514327ae8c9afc47b6179c/";
  // ScannerCamino = "https://caminoscan.com/"
  // ETH_ID = "0x1";
  // MATIC_ID = "137";
  // CAM_ID ="500"
  // Network_MAT = "polygon";
  // Network_ETH = "ethereum";
  // Network_CAM = "camino";
  // Network_MAT_Cap = "Polygon";
  // Network_ETH_Cap = "Ethereum";
  // Network_CAM_Cap = "Camino";
  // Currency_ETH = "ETH";
  // Currency_MATIC = "MATIC";
  // Currency_CAM= "CAM"
  // web3AuthNetwork = "mainnet";
  // PolygonChainIds = "0x89";
  // EthereumChainIds = "0x1";
  // CaminoChainIds = "0x1f4"
  // rpcTargetPolygon = "https://polygon-mainnet.infura.io/v3/a6738b5a17514327ae8c9afc47b6179c";
  // rpcTargetEthereum = "https://api.camino.network/ext/bc/C/rpc";
  // rpcTargetCamino = "https://polygon-mainnet.infura.io/v3/a6738b5a17514327ae8c9afc47b6179c";
  // tickerNamePolygon = "Polygon Mainnet";
  // tickerNameEthereum = "Ethereum mainnet"
  // tickerNameCamino = "Camino C-Chain"
}
// async function tag() {
//     var id = "0000";
//     await axios.get(v1Url + '/admin/panel/getnfttag/' + id)
//     .then((data) => {
//         if (data && data.data && data.data.userValue) {
//             (data.data.userValue).map((item) => {
//                 (shareTag).push(item.nfttag)
//                 return item
//             })
//         }
//     })
// }
// tag();

let key = {
  Front_URL: Front_URL,
  Back_URL: Back_URL,
  v1Url: v1Url,
  vUrl: v1Url,
  fee: fee,
  toFixed: toFixed,
  toasterOption: toasterOption,
  limit: limit,
  limitMax: limitMax,
  tokenAddr: Network.tokenAddr,
  singleType: singleType,
  multipleType: multipleType,
  IPFS_IMG: IPFS_IMG,
  IPFS: IPFS,
  GeoLocation: GeoLocation,
  PayPalKey: PayPalKey,
  BNBPROVIDER: BNBPROVIDER,
  tokenABI: Network.tokenABI,
  PurchaseTransferType: PurchaseTransferType,
  maxLength: maxLength,
  Users_URL: Users_URL,
  Lod: Lod,
  chainId: Network.chainId,
  Chainid: Network.Chainid,
  WenlamboConvertVal: WenlamboConvertVal,
  currAdrress: currAdrress,
  tokenFee: tokenFee,
  sellerfee: sellerfee,
  buyerfee: buyerfee,
  shareTag: shareTag,
  providercon: providercon,
  nameFormat: nameFormat,
  ETH_ID: ETH_ID,
  MATIC_ID: MATIC_ID,
  ScannerPolygon: ScannerPolygon,
  ScannerBinance: ScannerBinance,
  ScannerCamino:ScannerCamino,
  Network_MAT: Network_MAT,
  Network_ETH: Network_ETH,
  Network_MAT_Cap: Network_MAT_Cap,
  Network_ETH_Cap: Network_ETH_Cap,
  Currency_ETH: Currency_ETH,
  Currency_MATIC: Currency_MATIC,
  Web3AuthClientID: Web3AuthClientID,
  web3AuthNetwork: web3AuthNetwork,
  PolygonChainIds: PolygonChainIds,
  EthereumChainIds: EthereumChainIds,
  rpcTargetPolygon: rpcTargetPolygon,
  rpcTargetEthereum: rpcTargetEthereum,
  tickerNamePolygon: tickerNamePolygon,
  tickerNameEthereum: tickerNameEthereum,
  PrivateKey: PrivateKey,
  AdminAddress: AdminAddress,
  encodekey: encodekey,
  CAM_ID:CAM_ID,
  Network_CAM:Network_CAM,
  Network_CAM_Cap:Network_CAM_Cap,
  Currency_CAM:Currency_CAM,
  CaminoChainIds:CaminoChainIds,
  rpcTargetCamino:rpcTargetCamino,
  PolygonbulkCon:PolygonbulkCon,
  tickerNameCamino:tickerNameCamino
};

export default key;