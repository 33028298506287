
import Web3Utils from 'web3-utils'
import { useWeb3 } from "./useWeb3";
import { useMint, useTokenApprove, usePurchase, useAcceptBid, useBurn, usePutonSale, useCancelOrder, useMintPurchase, useAcceptBidMint, useAcceptBidTransfer, usePruchaseTransfer, useTranfer, useTranferTrade, useSoulTransFerMint, useTicketsMint, useTicketsMintBid, useTicketsTansfer, useBurnBulk, useClaimNfts, usePassport, useFiatBuy, useTicketsMintFiat, useTransferMintCall, useTicketsTansferBulkAdmin, useMintPurchaseCharity, useCommonContractFunctions, useTokenApproveFraction, useTokenFraction, useDeployerConToken,useTranferFraction,useBurnFraction,useTokenBurnFrac } from "./useMethods";
import Web3 from 'web3';
export const useMintReceipt = async (Arg, Type, Provider) => {
    // console.log("nft,type,provider",Arg,Type,Provider)
    const web3 = await useWeb3()
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
    try {
        const Hash = await useMint(Arg, Type, Provider)
        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);
        return receipt;
    }
    catch (error) {
        // console.log("useMintReceipt-Hookerror",error)
        return false
    }
}

export const useTokenAppReceipt = async (Arg, Type, Provider) => {
    // console.log("dvnskdfvnkdfsd",Arg);
    const web3 = await useWeb3(Provider.Web3Pro)
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
    try {
        const Hash = await useTokenApprove(Arg, Type, Provider)
        // console.log("skjdnfksnkds",Hash);
        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);
        // console.log("xcudgdabcahcksajd",receipt);
        return receipt;
    }
    catch (error) {
        // console.log("useTokenAppReceipt-Hookerror",error)
        return false
    }
}

export const usePurchaseReceipt = async (Arg, Type, Provider, isToken) => {
    console.log("sdfsndkfksdnfk",Arg,Type,Provider,isToken);
    const web3 = await useWeb3(Provider)
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
    try {
        console.log("asdasasdasdas");
        const Hash = await usePurchase(Arg, Type, Provider, isToken)
        console.log("kfdslkjfdslkjf",Hash);
        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);
        
        
        return receipt;
    }
    catch (error) {
        console.log("usePurchaseReceipt-Hookerror", error)
        return false
    }
}

export const useAcceptBidReceipt = async (Arg, Type, Provider) => {
    // console.log("qwewqeqwewqeqwe",Arg,Type,Provider);
    const web3 = await useWeb3(Provider)
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
    try {
        const Hash = await useAcceptBid(Arg, Type, Provider)
        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);
        return receipt;
    }
    catch (error) {
        // console.log("useAuctionReceipt-Hookerror",error)
        return false
    }
}


export const useAcceptBidTransferReceipt = async (Arg, Type, Provider) => {
    // console.log("qwewqeqwewqeqwe",Arg,Type,Provider);
    const web3 = await useWeb3(Provider)
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
    try {
        const Hash = await useAcceptBidTransfer(Arg, Type, Provider)
        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);
        return receipt;
    }
    catch (error) {
        // console.log("useAuctionReceipt-Hookerror",error)
        return false
    }
}


export const usePruchaseTransferReceipt = async (Arg, Type, Provider) => {
    // console.log("qwewqeqwewqeqwe",Arg,Type,Provider);
    const web3 = await useWeb3(Provider)
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
    try {
        const Hash = await usePruchaseTransfer(Arg, Type, Provider)
        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);
        return receipt;
    }
    catch (error) {
        // console.log("useAuctionReceipt-Hookerror",error)
        return false
    }
}

export const useTransferReceipt = async (Arg, Type, Provider) => {
    const web3 = await useWeb3(Provider)
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
    try {
        const Hash = await useTranfer(Arg, Type, Provider)

        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);
        return receipt;
    }
    catch (error) {
        // console.log("useBurnReceipt-Hookerror",error)
        return false
    }
}


export const useTransferReceiptFraction = async (Arg, Type, Provider) => {
    const web3 = await useWeb3(Provider)
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
    try {
        const Hash = await useTranferFraction(Arg, Type, Provider)

        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);
        return receipt;
    }
    catch (error) {
        // console.log("useBurnReceipt-Hookerror",error)
        return false
    }
}


export const useTransferReceiptTrade = async (Arg, Type, Provider, NFT, tokDetails) => {
    const web3 = await useWeb3(Provider)
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
    try {
        const Hash = await useTranferTrade(Arg, Type, Provider, NFT, tokDetails)
        console.log("HashHash", Hash);
        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);
        return receipt;
    }
    catch (error) {
        console.log("useBurnReceiptTransferrr-Hookerror", error)
        return false
    }
}

export const useBurnReceipt = async (Arg, Type, Provider) => {
    const web3 = await useWeb3(Provider)
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
    try {
        const Hash = await useBurn(Arg, Type, Provider)
        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);
        return receipt;
    }
    catch (error) {
        // console.log("useBurnReceipt-Hookerror",error)
        return false
    }
}

export const useBurnReceiptFraction = async (Arg, Type, Provider) => {
    const web3 = await useWeb3(Provider)
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
    try {
        const Hash = await useBurnFraction(Arg, Type, Provider)
        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);
        return receipt;
    }
    catch (error) {
        // console.log("useBurnReceipt-Hookerror",error)
        return false
    }
}

export const useTokenReceiptFraction = async (Arg, Type, Provider) => {
    const web3 = await useWeb3(Provider)
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
    try {
        console.log("akfmadlkfmaklfma");
        const Hash = await useTokenBurnFrac(Arg, Type, Provider)
        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);
        return receipt;
    }
    catch (error) {
         console.log("useBurnRecamskdmasmd;aeipt-Hookerror",error)
        return false
    }
}


export const usePutonSaleReceipt = async (Arg, Type, Provider) => {
    // console.log("putonsale params",Arg,Type,Provider)
    const web3 = await useWeb3(Provider.Web3Pro)
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
    try {
        const Hash = await usePutonSale(Arg, Type, Provider)
        // console.log("asdfadfmdkfmd",Hash);
        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);
        return receipt;
    }
    catch (error) {
        // console.log("useBurnReceipt-Hookerror",error)
        return false
    }
}

export const useCancelOrderReceipt = async (Arg, Type, Provider) => {
    const web3 = await useWeb3()
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
    try {
        const Hash = await useCancelOrder(Arg, Type, Provider)
        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);
        return receipt;
    }
    catch (error) {
        // console.log("useBurnReceipt-Hookerror",error)
        return false
    }
}

/// Lazy mint functionality
// -- tokendetails(NFt from collection) , currenttoken (clicked infopage nft)


export const usePurchaseMint = async (Arg, Type, Provider, tokenDetails, currentToken) => {
    console.log("data args", Arg, Type, Provider, tokenDetails)

    const web3 = await useWeb3(Provider.Web3Pro)
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
     console.log("vjnvsvnkvfsdvkdfn",web3);

    try {
        const Hash = await useMintPurchase(Arg, Type, Provider, tokenDetails, currentToken)
        // console.log("dkfsdnvjsdnvjsd",Arg,Type,Provider,tokenDetails,currentToken);
        console.log("hash",Hash)
        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);
        console.log("receipt after lazymint",receipt)
        // var tokenid = receipt?.logs[1]?.topics[2] && receipt?.logs[1]?.topics[2];
        // const  tokenID= Web3Utils.hexToNumber(tokenid);
        // console.log("tokenid from receipt",tokenID)


        return receipt;
    }
    catch (error) {
        console.log("usePuschaseReceipt-Hookerror", error)
        return false
    }


}

export const useMintAcceptBidReceipt = async (Arg, Type, Provider, Nftdetail) => {
    const web3 = await useWeb3(Provider.Web3Pro)
    console.log("asdasdasdasdasd", Arg, Type, Provider, Nftdetail);
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
    try {

        const Hash = await useAcceptBidMint(Arg, Type, Provider, Nftdetail)
        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);
        console.log("receipt data", receipt)

        return receipt;
    }
    catch (error) {
        // console.log("useAuctionReceipt-Hookerror",error)
        return false
    }
}

export const useSoulBoundMintTransfer = async (IPFS, ToAddress, Provider) => {
    const web3 = await useWeb3(Provider.Web3Pro)
    console.log("qdsaadsadasd", IPFS, ToAddress, Provider);
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
    try {

        const Hash = await useSoulTransFerMint(IPFS, ToAddress, Provider)
        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);
        console.log("receipt useSoulBoundMintTransfer", receipt)

        return receipt;
    }
    catch (error) {
        console.log("useSoulBoundMintTransfer-Hookerror", error)
        return false
    }
}

export const usePurchaseTickets = async (Arg, Type, Provider, tokenDetails, currentToken) => {
    console.log("data args", Arg, Type, Provider, tokenDetails)

    const web3 = await useWeb3(Provider.Web3Pro)
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
    console.log("vjnvsvnkvfsdvkdfn", web3);

    try {
        const Hash = await useTicketsMint(Arg, Type, Provider, tokenDetails, currentToken)
        console.log("dkfsdnvjsdnvjsd", Arg, Type, Provider, tokenDetails, currentToken);
        console.log("hash", Hash)
        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);
        return receipt;
    }
    catch (error) {
        // console.log("usePuschaseReceipt-Hookerror",error)
        return false
    }

}

export const usePurchaseTicketsFiat = async (Arg, Type, Provider, tokenDetails, currentToken) => {
    console.log("data args", Arg, Type, Provider, tokenDetails)

    const web3 = await useWeb3(Provider.Web3Pro)
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
    console.log("vjnvsvnkvfsdvkdfn", web3);

    try {
        const Hash = await useTicketsMintFiat(Arg, Type, Provider, tokenDetails, currentToken)
        console.log("dkfsdnvjsdnvjsd", Arg, Type, Provider, tokenDetails, currentToken);
        console.log("hash", Hash)
        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);
        return receipt;
    }
    catch (error) {
        // console.log("usePuschaseReceipt-Hookerror",error)
        return false
    }

}


export const usePurchaseTicketsBid = async (Arg, Type, Provider, tokenDetails, currentToken) => {
    console.log("data args", Arg, Type, Provider, tokenDetails)

    const web3 = await useWeb3(Provider.Web3Pro)
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
    console.log("vjnvsvnkvfsdvkdfn", web3);

    try {
        const Hash = await useTicketsMintBid(Arg, Type, Provider, tokenDetails, currentToken)
        console.log("dkfsdnvjsdnvjsd", Arg, Type, Provider, tokenDetails, currentToken);
        console.log("hash", Hash)
        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);
        return receipt;
    }
    catch (error) {
        // console.log("usePuschaseReceipt-Hookerror",error)
        return false
    }

}

export const usePurchaseTicketsTransfer = async (Arg, Type, Provider, tokenDetails, currentToken) => {
    console.log("data args", Arg, Type, Provider, tokenDetails)

    const web3 = await useWeb3(Provider.Web3Pro)
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
    console.log("vjnvsvnkvfsdvkdfn", web3);

    try {
        const Hash = await useTicketsTansfer(Arg, Type, Provider, tokenDetails, currentToken)
        console.log("dkfsdnvjsdnvjsd", Arg, Type, Provider, tokenDetails, currentToken);
        console.log("hash", Hash)
        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);
        return receipt;
    }
    catch (error) {
        // console.log("usePuschaseReceipt-Hookerror",error)
        return false
    }

}

export const usePurchaseTicketsTransferBulk = async (Arg, Type, Provider, tokenDetails, currentToken) => {
    console.log("data args", Arg, Type, Provider, tokenDetails)

    const web3 = await useWeb3(Provider.Web3Pro)
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
    console.log("vjnvsvnkvfsdvkdfn", web3);

    try {
        const Hash = await useTicketsTansferBulkAdmin(Arg, Type, Provider, tokenDetails, currentToken)
        console.log("dkfsdnvjsdnvjsd", Arg, Type, Provider, tokenDetails, currentToken);
        console.log("hash", Hash)
        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);
        return receipt;
    }
    catch (error) {
        // console.log("usePuschaseReceipt-Hookerror",error)
        return false
    }

}

export const useBurnReceiptBulk = async (Arg, Type, Provider) => {
    console.log("asdasdasdasda", Arg, Type, Provider);
    const web3 = await useWeb3(Provider)
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
    try {
        const Hash = await useBurnBulk(Arg, Type, Provider)
        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);
        return receipt;
    }
    catch (error) {
        // console.log("useBurnReceipt-Hookerror",error)
        return false
    }
}

export const useClaimNftsReceipt = async (Arg, Type, Provider, tokenDetails, currentToken) => {
    console.log("data args", Arg, Type, Provider, tokenDetails)

    const web3 = await useWeb3(Provider.Web3Pro)
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
    console.log("vjnvsvnkvfsdvkdfn", web3);

    try {
        const Hash = await useClaimNfts(Arg, Type, Provider, tokenDetails, currentToken)
        console.log("dkfsdnvjsdnvjsd", Arg, Type, Provider, tokenDetails, currentToken);
        console.log("sdfsdfdffewwe", Hash.transactionHash)
        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash.transactionHash);

        return receipt;
    }
    catch (error) {
        console.log("usePuschaseResdklfsdlceipt-Hookerror", error)
        return false
    }

}

export const useTranferMintReceipt = async (Arg, Type, Provider, tokenDetails, currentToken) => {
    console.log("data args", Arg, Type, Provider, tokenDetails)

    const web3 = await useWeb3(Provider.Web3Pro)
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
    console.log("vjnvsvnkvfsdvkdfn", web3);

    try {
        const Hash = await useTransferMintCall(Arg, Type, Provider, tokenDetails, currentToken)
        console.log("dkfsdnvjsdnvjsd", Arg, Type, Provider, tokenDetails, currentToken);
        console.log("sdfsdfdffewwe", Hash)
        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);

        return receipt;
    }
    catch (error) {
        console.log("usePuschaseResdklfsdlceipt-Hookerror", error)
        return false
    }

}



export const usePassportReceipt = async (Arg, Type, Provider, data, Currenttoken, isToken) => {
    // console.log("sdfsndkfksdnfk",Arg,Type,Provider,isToken);
    const web3 = await useWeb3(Provider)
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
    try {
        const Hash = await usePassport(Arg, Type, Provider, data, Currenttoken, isToken)
        // console.log("kfdslkjfdslkjf",Hash);
        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);
        // console.log("hash,receipt",Hash,receipt)
        return receipt;
    }
    catch (error) {
        // console.log("usePurchaseReceipt-Hookerror",error)
        return false
    }
}

export const useFiatTransferReciptReceipt = async (Arg, Type, Provider, data, Currenttoken, isToken) => {
    // console.log("sdfsndkfksdnfk",Arg,Type,Provider,isToken);
    const web3 = await useWeb3(Provider)
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
    try {
        const Hash = await useFiatBuy(Arg, Type, Provider, data, Currenttoken, isToken)
        // console.log("kfdslkjfdslkjf",Hash);
        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);
        // console.log("hash,receipt",Hash,receipt)
        return receipt;
    }
    catch (error) {
        console.log("qwdqeqweqwe-Hookerror", error)
        return false
    }
}


export const usePurchaseMintCharity = async (Arg, Type, Provider, tokenDetails, currentToken, createrList, OwnerList, CreatorPer, OwnerPer) => {
    console.log("data args", Arg, Type, Provider, tokenDetails)

    const web3 = await useWeb3(Provider.Web3Pro)
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
    //  console.log("vjnvsvnkvfsdvkdfn",web3);

    try {
        const Hash = await useMintPurchaseCharity(Arg, Type, Provider, tokenDetails, currentToken, createrList, OwnerList, CreatorPer, OwnerPer)
        // console.log("dkfsdnvjsdnvjsd",Arg,Type,Provider,tokenDetails,currentToken);
        // console.log("hash",Hash)
        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);
        return receipt;
    }
    catch (error) {
        console.log("usePuschaseReceipt-Hookerror", error)
        return false
    }
}

export const ReciptCommon = async (Arg, Provider, Method, sendData, PriceWithSerfee) => {
    console.log("data args", Arg, Provider, Method, sendData, PriceWithSerfee)
    const web3 = await useWeb3(Provider.Web3Pro)
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
    try {
        const Hash = await useCommonContractFunctions(Arg, Provider, Method, sendData, PriceWithSerfee)
        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);
        return receipt;
    }
    catch (error) {
        return false
    }
}

export const useTokenFractionApprove = async (Arg, Provider, Method, TokenAddress) => {
    console.log("data args", Arg, Provider, Method)
    const web3 = await useWeb3(Provider.Web3Pro)
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
    try {
        const Hash = await useTokenApproveFraction(Arg, Provider, Method, TokenAddress)
        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);
        return receipt;
    }
    catch (error) {
        return false
    }
}



export const useTokenAppReceiptFract = async (Arg, Type, Provider) => {
    // console.log("dvnskdfvnkdfsd",Arg);
    const web3 = await useWeb3(Provider.Web3Pro)
    var web3InRPC = new Web3(Provider.networkConfiguration.BNBPROVIDER)
    try {
        const Hash = await useTokenFraction(Arg, Type, Provider)
        // console.log("skjdnfksnkds",Hash);
        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);
        // console.log("xcudgdabcahcksajd",receipt);
        return receipt;
    }
    catch (error) {
        // console.log("useTokenAppReceipt-Hookerror",error)
        return false
    }
}

export const useDeployerCon = async (Arg) => {
    console.log("dvnskdfvnkdfsd",Arg);
    
    // const web3 = await useWeb3(Arg?.Wallet_Details?.Web3Pro)
    var web3InRPC = new Web3(Arg?.Wallet_Details?.networkConfiguration?.BNBPROVIDER)
    try {
        const Hash = await useDeployerConToken(Arg,web3InRPC)
        // console.log("skjdnfksnkds",Hash);
        const receipt = await web3InRPC.eth.getTransactionReceipt(Hash);
        // console.log("xcudgdabcahcksajd",receipt);
        return receipt;
    }
    catch (error) {
        console.log("adasdasdas-Hookerror",error)
        return false
    }
}

