import React, { useEffect, useState, useRef } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link, useHistory, useParams } from "react-router-dom";
import config from '../../lib/config';
import SINGLE from '../../ABI/721.json'
import { useWeb3 } from "hooks/useWeb3";
import axios from "axios";
import Web3 from 'web3';
import { decode } from "jsonwebtoken";
import { useSelector, useDispatch } from 'react-redux';
import { Account_Connect, Account_disConnect } from "actions/redux/action";
import { toast } from "react-toastify";
import { v1_verifyMail } from "actions/v1/user";
import { connectWallet, getServiceFees } from '../../hooks/useWallet';
import { v1_Social } from "actions/v1/user";
import { v1_Category } from "actions/v1/user";
import { AddressUserDetails_GetOrSave_Action, userget } from 'actions/v1/user';
import { useServiceFee } from "hooks/useContract"
import { network } from "../../views/network";
import { FreePackSubScribe } from '../../actions/v1/token'
const dashboardRoutes = [];
var temp = 0
const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}



export default function Connect(props) {
  const { token } = useParams()
  // const birds = useSelector(state => state.wallet_connect_context);
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const history = useHistory()
  const classes = useStyles();
  const { ...rest } = props;
  const dispatch = useDispatch();
  const Wallet = (a) => {
    console.log("data set wall redux")
    dispatch({
      type: Account_Connect,
      Account_Detail: {
        Wall: a
      }
    })
    // added for avoiding verifymail function


    setTimeout(() => {
      history.goBack();
    }, 1000);

  }


  useEffect(() => {
    // console.log("wallet detail conenct", token, Wallet_Details.UserAccountAddr)
    console.log("decode token", decode("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiIweGZlZTdjYzhmMWIwMDA5NTVkOGY4YzJhMDRjNWM2Y2E2ZWI4ODZhNGQiLCJpYXQiOjE2NjExNjg0MTJ9.Oei2o3lS3v1kyovEbHfsDUxTgmdnwtxfPtszjnzZO1Y"))
    if (token && Wallet_Details.UserAccountAddr) {
      var tokdet = decode(token)
      if (Wallet_Details.UserAccountAddr !== tokdet._id)
        toast.error("Invalid Token", { autoClose: 2500, closeButton: true })
      else
        verifyMail(token)
    }
    else if (token && !localStorage.walletConnectType && Wallet_Details.UserAccountAddr) {
      toast.error('Connect Wallet to Verify', { autoClose: 2500, closeButton: true })
    }
  }, [Wallet_Details.UserAccountAddr])

  const verifyMail = async (load) => {
    var payload = { check: load, address: Wallet_Details.UserAccountAddr }
    var Resp = await v1_verifyMail(payload)
    if (Resp.Success) {
      toast.success(Resp.Message, { autoClose: 5000, closeButton: true })
      setTimeout(() => {
        history.push('/my-items')
      }, 2000);
    }
    else {
      toast.error(Resp.Message, { autoClose: 5000, closeButton: true })
    }
  }

  // const SoCat = async () => {
  //   var Social = await v1_Social()
  //   var Category = await v1_Category()
  //   var id = "0000"
  //   var shareTag = []
  //   if (Category.Success)
  //     Category = Category.userValue
  //   else
  //     Category = ''
  //   if (Social.Success)
  //     Social = Social.userValue[0].social
  //   else
  //     Social = ''
  //   axios.get(config.v1Url + '/admin/panel/getnfttag/' + id)
  //     .then((data) => {
  //       if (data && data.data && data.data.userValue) {
  //         (data.data.userValue).map((item) => {
  //           (shareTag).push(item.nfttag)
  //           console.log("routes category", Category)
  //           dispatch({
  //             type: Account_Connect,
  //             Account_Detail: {
  //               shareTag: shareTag,
  //               Social: Social,
  //               Category: Category
  //             }
  //           })
  //         })
  //       }
  //     })
  // }

  // const Serfee = async () => {
  //   var Fee = await useServiceFee()
  //   console.log("ndkdsnfkdsn",Fee);
  //   dispatch({
  //     type: Account_Connect,
  //     Account_Detail: {
  //       Service_Fee: Fee,
  //     }
  //   })
  // }
  // useEffect(() => {
  //   SoCat();
  //   Serfee();
  //   // if (localStorage.walletConnectType)
  //   //   getInit(localStorage.walletConnectType);
  // }, [Wallet_Details.Wall]);


  // useEffect(() => {
  //   (async () => {
  //     var web3 = new Web3(window.ethereum)
  //     console.log("window", web3)
  //     var bal = await web3.eth.getBalance("0xeAc09f4720BB8e36E351Dc8E30f22195bCD6c6b9")
  //     if (bal)
  //       console.log("baln in useeffect", bal)

  //   })();
  // }, []);

  // const initialConnectWallet = async (type, register) => {
  //   if (localStorage.loggedinaddress == "" || localStorage.loggedinaddress == undefined) {
  //     toast.error("please login")
  //     history.push('/')
  //   } else {
  //     var toasttxt = null
  //     if (type === 'mt')
  //       toasttxt = "Unlock Metamask & Refresh Page"
  //     else
  //       toasttxt = "Wallet Connect Checking"

  //     var networkConfiguration = {}
  //     const id = toast.loading(`${toasttxt}`, { closeButton: true, theme: "dark" });
  //     // console.log("connecttype............", type, config.Chainid)
  //     var accountDetails = await connectWallet(type, Number(config.MATIC_ID))
  //     console.log("accountDetails......................", accountDetails.accountAddress, accountDetails.coinBalance, accountDetails.tokenBalance)
  //     //  const id=toast.loading("Wallet Connecting...")
  //     if (accountDetails.accountAddress !== undefined) {
  //       var data = {
  //         addr: String(accountDetails.accountAddress).toLowerCase(),
  //         from: register ? register : '',
  //         email: localStorage.loggedinaddress
  //       }
  //       var AddressUserDetails = await AfterWalletConnected(data);

  //       console.log("Addefefrfsvrv", AddressUserDetails)
  //       const IDcheck = await accountDetails?.web3?.eth?.getChainId();
  //       // console.log("cnzlmc.,zmmcx.zxc", IDcheck);

  //       Serfee(Number(IDcheck));
  //       if (IDcheck === network.MATIC.Chainid) {
  //         networkConfiguration = network.MATIC
  //       }
  //       else if (IDcheck === network.ETH.Chainid) {
  //         networkConfiguration = network.ETH
  //       }
  //       Serfee(String(config.Chainid));

  //       if (accountDetails != '') {
  //         console.log("Wallet Connrgbgtyhbyhnhyyygtgt5hy6huh", accountDetails.accountAddress, accountDetails.coinBalance, accountDetails.tokenBalance);
  //         dispatch({
  //           type: Account_Connect,
  //           Account_Detail: {
  //             UserAccountAddr: accountDetails.accountAddress,
  //             UserAccountBal: accountDetails.coinBalance,
  //             WalletConnected: "true",
  //             Wen_Bln: accountDetails.tokenBalance,
  //             Accounts: accountDetails.accountAddress,
  //             Web3Pro: accountDetails?.web3,
  //             providerss: accountDetails?.web3?._provider,
  //             AddressUserDetails: AddressUserDetails,
  //             networkConfiguration: networkConfiguration,
  //             web3auth: accountDetails?.web3auth,
  //           }
  //         })
  //       }
  //       if (accountDetails.accountAddress) {
  //         console.log("asdasdasdasd", accountDetails.accountAddress);
  //         toast.update(id, {
  //           render: "Wallet Connected Succesfully",
  //           type: "success",
  //           isLoading: false,
  //           autoClose: 2500,
  //           closeButton: true,
  //           theme: "dark"
  //         });
  //       }
  //       Wallet(type)
  //     }
  //   }


  //   // async function AddressUserDetails_GetOrSave_Call(currAddr) {
  //   //   var ReqData = {
  //   //     addr: String(accountDetails.accountAddress).toLowerCase()
  //   //   }
  //   //   console.log("sdnvskdjvnkdjv",ReqData);
  //   //   if (accountDetails.accountAddress !== "" && accountDetails.accountAddress !== undefined) {


  //   //   var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
  //   //   if (Resp && Resp.data && Resp.data.data && Resp.data.data.RetData && Resp.data.data.RetData.found) {
  //   //     return Resp.data.data.RetData.User
  //   //   } else {
  //   //     return null
  //   //   }
  //   // }
  //   // }





  //   //     if(accountDetails){
  //   //           var resp = await isRegister('WalletConnect',accountDetails.accountAddress)
  //   //           console.log("resp",resp)
  //   //      toast.update(id,{render : resp.msg , type:resp.success,autoClose:1000, isLoading: false,})
  //   //          if(resp?.success == 'success'){
  //   //             console.log("all ok come pls")
  //   //                   dispatch({
  //   //                       type: Account_Section,
  //   //                       Account_Section: {AccountDetails:accountDetails}
  //   //                   })
  //   //             setOpen3(false)
  //   //   }   
  //   // }
  //   // else



  //   // toast.update(id,{render : "Connect Wallet" , type:'error',autoClose:1000, isLoading: false,})
  // }

  const initialConnectWallet = async (type, register) => {
    if (localStorage.loggedinaddress == "" || localStorage.loggedinaddress == undefined) {
      toast.error("please login")
      history.push('/')
    } else {
      var toasttxt = null
      if (type === 'mt') {
        toasttxt = "Unlock Metamask & Refresh Page"
      }
      else {

        toasttxt = "Wallet Connect Checking"
      }
      var networkConfiguration = {}
      const id = toast.loading(`${toasttxt}`, { closeButton: true, theme: "dark" });
      console.log("connecttype............", type, config.Chainid)
      var accountDetails = await connectWallet(type, config.Chainid, register)
      console.log("accountDetails......................", accountDetails?.accountAddress, accountDetails?.coinBalance, accountDetails?.tokenBalance, accountDetails)
      
      if (accountDetails?.LMWallet) {
        localStorage.setItem("ChainId", config.Chainid)
        dispatch({
          type: Account_Connect,
          Account_Detail: {
            LMWallet: accountDetails?.LMWallet,
          }
        })
      }
      
      //  const id=toast.loading("Wallet Connecting...")

      // var AddressUserDetails = await AfterWalletConnected(accountDetails.accountAddress);

      // console.log("Addefefrfsvrv", AddressUserDetails)
      if (accountDetails.accountAddress !== undefined) {
        var data1 = {
          curradd: accountDetails?.accountAddress, email: localStorage.loggedinaddress

        }
        // console.log("gfndsfgndghndgh", localStorage.loggedinaddress, data1);
        var users = await userget(data1)
        console.log("ajksdkjahfdjklakfad", users);
        var alreadyuser = users?.data?.data?.users
        // console.log("asdadasdasdas", alreadyuser, localStorage?.loggedinaddress);
        // console.log("dfbsfgnbdfgn", alreadyuser != null, alreadyuser?.curraddress === '', alreadyuser?.curraddress === accountDetails?.accountAddress, localStorage?.userAddress === accountDetails?.accountAddress);
        if (users?.data?.data?.message == "Allow") {
          if (alreadyuser !== null && (alreadyuser?.curraddress.length > 0 || alreadyuser?.curraddress?.length == 0)) {
            if (String(localStorage?.loggedinaddress) === String(alreadyuser?.signemail) || localStorage?.loggedinaddress === undefined) {
              var data = {
                addr: String(accountDetails?.accountAddress).toLowerCase(),
                from: register ? register : '',
                email: localStorage.loggedinaddress
              }
              // console.log("asdsadasfdasfdf", data);

              var Resp = await AddressUserDetails_GetOrSave_Action(data);
              console.log("dbsfgbsfdgnbdfg", Resp);
              if (Resp?.data?.data?.RetData?.Message === "already registered") {
                // console.log("fnmfghasdasmfhjm", Resp);
                toast.error(`Email invalid "you already registered with ${Resp?.data?.data?.RetData?.User?.signemail}"`)
                setTimeout(() => {
                  logoutfunction()
                }, 500);
              }
              else if (Resp?.data?.data?.RetData?.Message === "not registered") {
                // console.log("asdasdsadasczxczdasdas", Resp);
                toast.error(`please register to login`)
                setTimeout(() => {
                  logoutfunction()
                }, 500);
              }
              else {
                if (Resp?.data?.data?.RetData?.User?.curraddress) {
                  localStorage.setItem("userAddress", String(accountDetails?.accountAddress).toLowerCase())
                } else {
                  localStorage.clear()
                  toast.error("Please Login Again.Error in Getting Address")
                  window.location.reload()
                }
                console.log("sdfsdfsfsdfdsfsd", Resp?.data?.data?.RetData?.User?.curraddress);
              }
              if (Resp && Resp?.data && Resp?.data?.data && Resp?.data?.data?.RetData?.User) {
                // console.log("sdfasfsdfsdfsdfsdf");
                if (accountDetails?.accountAddress !== '' || Resp?.data?.data?.RetData?.User?.LocalAddr) {
                  var sendData = accountDetails?.accountAddress == null || accountDetails?.accountAddress == "" || accountDetails?.accountAddress == undefined ? Resp.data.data.RetData.User.LocalAddr : accountDetails?.accountAddress
                  var AddressUserDetails = await FreePackSubScribe({ Address: sendData });
                  console.log("asdasdsadasdasd", AddressUserDetails);
                }
                if (accountDetails != '') {
                  console.log("Wallet Connrgbgtyhbyhnhyyygtgt5hy6huh", accountDetails);
                  const IDcheck = await accountDetails?.web3?.eth?.getChainId();
                  // console.log("cnzlmc.,zmmcx.zxc", IDcheck);

                  Serfee(Number(IDcheck));
                  if (IDcheck === network.MATIC.Chainid) {
                    networkConfiguration = network.MATIC
                  }
                  else if (IDcheck === network.ETH.Chainid) {
                    networkConfiguration = network.ETH
                  }
                  else if (IDcheck === network.CAM.Chainid) {
                    networkConfiguration = network.CAM
                  }
                  Serfee(String(config.Chainid));
                  dispatch({
                    type: Account_Connect,
                    Account_Detail: {
                      UserAccountAddr: accountDetails.accountAddress,
                      UserAccountBal: accountDetails.coinBalance,
                      WalletConnected: "true",
                      Wen_Bln: accountDetails.tokenBalance,
                      Accounts: accountDetails.accountAddress,
                      Web3Pro: accountDetails?.web3,
                      providerss: accountDetails?.web3?._provider,
                      web3auth: accountDetails?.web3auth,
                      networkConfiguration: networkConfiguration
                      // AddressUserDetails: AddressUserDetails,

                    }
                  })
                  Wallet(type)
                }
                // localStorage.setItem('user_token', Resp?.data?.token);
                if (accountDetails?.accountAddress) {
                  toast.update(id, {
                    render: "Wallet Connected Succesfully",
                    type: "success",
                    isLoading: false,
                    autoClose: 2500,
                    closeButton: true,
                    theme: "dark"
                  });
                  window.$("#closeregister").click();
                }

                return Resp.data.data.RetData.User
              } else {
                // console.log("else return null");
                return null
              }
              // }
            }
            else {
              // console.log("asdasdasdsadasd");
              toast.error("please register to login")
              logoutfunction()

            }

          }
          else if (alreadyuser === null || alreadyuser?.curraddress !== accountDetails?.accountAddress) {
            // console.log("gfnsfgnsghf");
            toast.error("email or address mismatch please login with correct credentials")
            // window.$("#triggerlogin").click();
            logoutfunction()
          }
          else {

          }
        } else {
          // toast.error("Your are restricted by admin. Contact admin for further deatils")
          toast.update(id, {
            render: "Your are restricted by admin. Contact admin for further deatils",
            type: "error",
            isLoading: false,
            autoClose: 2500,
            closeButton: true,
            theme: "dark"
          });
          setTimeout(() => {
            logoutfunction()
          }, 4000);
        }

      }
    }

  }
  const logoutfunction = () => {
    console.log("eqweqweqw");
    localStorage.removeItem("loggedinaddress")
    localStorage.removeItem("homepopblock")
    localStorage.removeItem("userAddress")
    localStorage.removeItem("user_token")
    localStorage.clear()
    toast.success("Sucessfully logout")
    // setTimeout(() => {
    // window.location.reload()

    Disconnect()
    history.push('/')
    setTimeout(() => {
      window.location.reload()
    }, 500);
    // }, 1000);
    window.$("#triggerlogin").click();
  }


  const Disconnect = async (a) => {
    dispatch({
      type: Account_disConnect,
      Account_Disconnect: {
        UserAccountAddr: "",
        UserAccountBal: 0,
        providerss: null,
        Service_Fee: 0,
        Wen_Bln: 0,
        Accounts: "",
        WalletConnected: "",
        AddressUserDetails: null,
        tokenAddress: null,
        swapFee: 0,
      },
    });
    if (a === "click")
      toast.success("Wallet Disconnected", { autoClose: 3000, theme: "dark" });
    if (
      localStorage.walletconnect != null &&
      localStorage.walletConnectType == "wc"
    ) {
      await Wallet_Details.providerss.disconnect();
      localStorage.removeItem("walletconnect");
    }
    localStorage.removeItem("walletConnectType");
    localStorage.removeItem("accountInfo");
    localStorage.removeItem("user_token")
    // localStorage.removeItem("loggedinaddress");
    // localStorage.removeItem("homepopblock");
  };

  const AfterWalletConnected = async (currentAddress) => {
    //var sendaddr = config.currAddress
    var ReqData = {
      addr: currentAddress
    }
    if (currentAddress !== "" && currentAddress !== undefined) {
      var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
      //localStorage.setItem('user_token', Resp?.data?.token);
      if (Resp && Resp.data && Resp.data.data && Resp.data.data.RetData && Resp.data.data.RetData.found) {
        return Resp.data.data.RetData.User
      } else {
        return null
      }
    }
  }

  const Serfee = async (Address) => {
    var Fee = await getServiceFees(Address)
    // console.log("ndkdsnfkdsn", Fee);
    dispatch({
      type: Account_Connect,
      Account_Detail: {
        Service_Fee: Fee?.Serfee,
        Service_Fee_Fraction:Fee?.SerfeeFraction
      }
    })
  }
  return (
    <div className="home_header connect_wallet">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home">
          <div className="header_logo_align">


          </div></Link>}
        leftLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader inner_pagh_bg_1 pb-5 connectwallet1"}>
        <div className={classes.container}>
          <div className="row">
            <div className="col-12 col-lg-9 mx-auto">
              <div className="">
                <p className="heading_big_blk">Connect your wallet</p>
                <p className="create_para_big mt-3 p-b-sec text-center">Connect with one of available wallet providers or create a new wallet.</p>

              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-9 mx-auto">
              <ul className="noti_ul_dd">
                <li className="px-3">
                  <div className="media d-flex  align-items-center">
                    <img src={require("../../assets/images/wallet_01.png")} alt="User" className="img-fluid mr-2 img_user_noti align-self-center" />
                    {window.ethereum !== undefined && window.web3 !== undefined ?
                      <div className="media-body w-100 justify-content-between flex_body" onClick={() => initialConnectWallet("MetaMask", "register")}>
                        <div>
                          <p className="mt-0 banner_desc_user mb-0 font_16 not_banner_dessc">MetaMask</p>
                        </div>
                        <div>
                          <span className="conet_desc">Popular</span>
                        </div>
                      </div>
                      :
                      <>
                        <a className="d-flex justify-content-between w-100" href="https://metamask.app.link/dapp/lazyminter.com/home">
                          <div className="media-body w-100 justify-content-between flex_body" >
                            <div>
                              <p className="mt-0 banner_desc_user mb-0 font_16 not_banner_dessc">MetaMask</p>
                            </div>
                            <div>
                              <span className="conet_desc">Popular</span>
                            </div>
                          </div>
                        </a>
                      </>
                    }
                  </div>

                </li>
                {/*               
              <li className="px-3">
              <div className="media align-items-center">
              
                  <img src={require("../assets/images/wallet_02.png")} alt="User" className="img-fluid mr-2 img_user_noti align-self-center" />
                  <div className="media-body flex_body">
                    <div>
                    <p className="mt-0 banner_desc_user mb-0 font_16 not_banner_dessc">Binance Chain Wallet</p>
                    </div>
                    <div>
                      <span className="conet_desc"></span>
                    </div>
                  </div>
                </div>
               
               
              </li>
              */}
                <li className="px-3">
                  <div className="media d-flex align-items-center" onClick={() => initialConnectWallet("LMWallet", "register")}>

                    <img src={require("../../assets/images/lazymintingnew/logo.png")}
                      style={{ objectFit: "contain" }} alt="User"
                      className="img-fluid mr-2 img_user_noti align-self-center" />
                    <div className="media-body flex_body">
                      <div>
                        <p className="mt-0 banner_desc_user mb-0 font_16 not_banner_dessc"> LM wallet</p>
                      </div>
                      <div>
                        <span className="conet_desc">recommended</span>
                      </div>
                    </div>
                  </div>


                </li>
                <li className="px-3">
                  <div className="media d-flex align-items-center" onClick={() => initialConnectWallet("TorusWallet", "register")}>

                    <img src={require("../../assets/images/torus.png")} alt="User" className="img-fluid mr-2 img_user_noti align-self-center" />
                    <div className="media-body flex_body">
                      <div>
                        <p className="mt-0 banner_desc_user mb-0 font_16 not_banner_dessc"> Torus</p>
                      </div>
                      <div>
                        <span className="conet_desc">Custodial</span>
                      </div>
                    </div>
                  </div>


                </li>
                <li className="px-3">
                  <div className="media d-flex align-items-center" onClick={() => initialConnectWallet("WalletConnect", "register")}>

                    <img src={require("../../assets/images/wallet_03.png")} alt="User" className="img-fluid mr-2 img_user_noti align-self-center" />
                    <div className="media-body flex_body">
                      <div>
                        <p className="mt-0 banner_desc_user mb-0 font_16 not_banner_dessc">Connect Wallet</p>
                      </div>
                      <div>
                        <span className="conet_desc"></span>
                      </div>
                    </div>
                  </div>


                </li>

                {/*               
              <li className="px-3">
              <div className="media align-items-center">
              
              <img src={require("../assets/images/wallet_04.png")} alt="User" className="img-fluid mr-2 img_user_noti align-self-center" />
              <div className="media-body flex_body">
                <div>
                <p className="mt-0 banner_desc_user mb-0 font_16 not_banner_dessc">TrustWallet</p>
                </div>
                <div>
                  <span className="conet_desc"></span>
                </div>
              </div>
            </div>
               
              </li>
             */}
              </ul>
            </div>
          </div>

        </div>



      </div>
      <Footer />
    </div>
  );
}
