import React from 'react';
import { Rings } from "react-loader-spinner";

export default function LazyLoader() {
  return (
 
      <div class="lds-facebook">
        <div></div><div></div><div></div>
    {/* <Rings ariaLabel="loading-indicator" /> */}
     </div>
  );
}