import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { ImageValidation, EventsValidation } from "actions/controller/validation";
import config from 'lib/config'
import isEmpty from "lib/isEmpty";
import { getAllCounts, UserpanelSub, ClaimRequestFiat, CheckNfts } from "../actions/v1/token"
import { EditProfiledata, GetProfiledata } from 'actions/controller/userControl'
import { DomainNameValidate } from "actions/v1/token";
import { toast, Slide, Zoom, Flip, Bounce } from "react-toastify";
import {
    DataGrid,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

import { CKEditor } from "ckeditor4-react";
import ReactHTMLParser from "react-html-parser";
import Select from "react-select";
import { EventListNft } from "actions/controller/tokenControl";
import polygon_violet_lighttheme from '../assets/images/lazymintingnew/polygon_violet_lighttheme.png'
import { Account_Connect, Account_disConnect } from "actions/redux/action";
import { GetFiatRevenueData, Eventpublish, DeleteEvent, CampaignTopBox } from "../actions/v1/token"
import Preview from "./Preview"
import moment from "moment"
import dummy from "../assets/images/faces/sublogo.png"
import { FractionalDash, ChangeToVote } from "../actions/v1/Fractional"
import Calender from "../components/Calender/Calender"
import { Button } from "react-bootstrap";
import { network } from "../views/network";
import { useNFTBalCheckToken } from 'hooks/useMethods';
import { LMBalanceOfFraction } from 'actions/v1/token';
import Web3 from 'web3';
import CreateVote from "./CreateVote";
// import ReactSummernote from "react-summernote";
// import "react-summernote/dist/react-summernote.css"; // import styles
toast.configure();


// Scroll to Top
function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return null;
}

function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (

        <Pagination
            color="primary"
            shape="rounded"
            page={page + 1}
            count={pageCount}
            renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
        />
    );
}

function customCheckbox(theme) {
    return {
        '& .MuiCheckbox-root svg': {
            width: 16,
            height: 16,
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.mode === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'
                }`,
            borderRadius: 2,
        },
        '& .MuiCheckbox-root svg path': {
            display: 'none',
        },
        '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
            backgroundColor: '#e50914',
            borderColor: '#e50914',
        },
        '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
            position: 'absolute',
            display: 'table',
            border: '2px solid #fff',
            borderTop: 0,
            borderLeft: 0,
            transform: 'rotate(45deg) translate(-50%,-50%)',
            opacity: 1,
            transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
            content: '""',
            top: '50%',
            left: '39%',
            width: 5.71428571,
            height: 9.14285714,
        },
        '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
            width: 8,
            height: 8,
            backgroundColor: '#62626214',
            transform: 'none',
            top: '39%',
            border: 0,
        },

    };
}

const FractionsDash = (props) => {
    const Wallet_Details = useSelector((state) => state.wallet_connect_context);
    const dashboardRoutes = [];
    const dispatch = useDispatch();
    const useStyles = makeStyles(styles);
    const classes = useStyles();
    const history = useHistory()
    const InitialForm = {
        VotingCat: "",
        VotingEndDate: "",
        VotingStartedDate: "",
        Description: "",
        SellAmount: 0,
        ShortName: "",
        tokenID: "",
        TokenOwner: "",
        WalletAddress: Wallet_Details.UserAccountAddr,
        CreateStatus: "Create",
        promote: "",
        UniqueId: Date.now(),
        VoteCount: 0
    };
    const [Formdata, setFormdata] = useState(InitialForm);
    const [FaitRv, SetFiatRv] = useState([])
    const [ClaimList, SetClaimList] = useState([])
    const [YourRequest, SetYourRequest] = useState([])
    const [AucCalendar, setAucCalender] = useState("Start");
    const [PicStartselected, setPicStartselected] = useState(false);
    const [AuctionStart, setAuctionStart] = useState(null);
    const [AuctionEnd, setAuctionEnd] = useState(null);
    const [currentFractions, setcurrentFractions] = useState({})
    const [VotePer, setVotPer] = useState(0)
    const [HideShow, setHideShow] = useState(false)
    const [NewOwnerAddress, setNewOwnerAddress] = useState("")
    const [CreateComp, setCreateComp] = useState(false)

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#fff",
            position: "relative",
            top: "3px",
            left: "0px",
            width: "100%",
            maxWidth: "100%",
            color: "#000"
        }),
        option: (provided, state) => ({
            ...provided,
            marginBottom: "5px",
            backgroundColor: "transparent",
            maxWidth: "356px",
            color: "#fff",
            borderBottom: "#fff",
            "&:hover": {
                transition: "all 0.5s ease",
                color: "#e50914"
            },
            "&:active": {
                transition: "all 0.5s ease",
                color: "#000"
            }
        }),
        control: (provided, state) => ({
            ...provided,
            border: "1px solid transparent",
            boxShadow: "0px 0px",
            "&:hover": {
                border: "1px solid transparent",
                boxShadow: "0px 0px",
            },
            "&:focus": {
                border: "1px solid transparent"
            }
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            border: "1px solid transparent"
        })
    }

    const options = [
        { value: 'Selling Vote', label: 'Selling Vote' },
        { value: 'Community Vote', label: 'Community Vote' },
    ];

    const options2 = [
        { value: 'Community Vote', label: 'Community Vote' },
    ];

    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOption1, setSelectedOption1] = useState(null);

    const [options1, setoptions1] = useState([
        { value: 'No Data', label: 'No Data' },
    ]);

    const rows = FaitRv.length > 0 && FaitRv.map((item, index) => {
        console.log("dfasdadasdas", item);
        return ({
            id: index + 1, WalletAddress: item.tokenCreator, tokenID: item.tokenID, NFT_Name: item.NFTDetail[0].tokenName, Royalties: item.NFTDetail[0].tokenRoyality + `%`, Network: item.SelectedNetwork, Date: moment(item.tokenName).format('D MMMM YYYY'), Action: "",
            tokenOwner: item.tokenOwner, VoteDetails: item.VoteDetails, tokenCreator: item.tokenCreator, NFTDetailDet: item.NFTDetail[0],RandomName:item.RandomName
        })
    })

    const columns = [
        { field: 'id', headerName: 'ID', width: 10 },
        {
            field: 'WalletAddress',
            headerName: 'Wallet Address',
            width: 170,
            editable: false,
            sortable: false,
        },
        {
            field: 'tokenID',
            headerName: 'TokenID',
            width: 170,
            editable: false,
            sortable: false,
        },
        {
            field: 'NFT_Name',
            headerName: 'NFT Name',
            width: 240,
            editable: false,
            sortable: false,
        },
        {
            field: 'Royalties',
            headerName: 'Royalties',
            width: 150,
            editable: false,
            sortable: false,
        },
        {
            field: 'Network',
            headerName: 'Network',
            width: 200,
            editable: false,
            sortable: false,
        },
        {
            field: 'Action',
            headerName: 'Action',
            width: 230,
            editable: false,
            sortable: false,
            renderCell: (params) => (
                <strong>

                    <button
                        className="btn create_btn"
                        style={{ marginLeft: 0 }}
                        onClick={() => { ChangeStatus(params, "Create") }}
                    >
                        Create
                    </button>

                    <button
                        className="btn create_btn"
                        style={{ marginLeft: 5 }}
                        onClick={() => { ChangeStatus(params, "Edit") }}
                    >
                        View/Edit
                    </button>
                    {/* <button
                        className="btn"
                        style={{ marginLeft: 3 }}

                    >
                        Open
                    </button> */}
                </strong>
            ),
        },
    ];

    const rows1 = ClaimList.length > 0 && ClaimList.map((item, index) => {
        console.log("dfasdadasdas", item);
        return ({ id: index + 1, WalletAddress: item.tokenCreator, tokenID: item.tokenID, NFT_Name: item.NFTDetail[0].tokenName, Royalties: item.NFTDetail[0].tokenRoyality + `%`, Network: item.SelectedNetwork, Date: moment(item.tokenName).format('D MMMM YYYY'), Action: "", tokenOwner: item.tokenOwner, VoteDetails: item.VoteDetails, tokenCreator: item.tokenCreator, NFTDetailDet: item.NFTDetail[0] ,RandomName:item.RandomName})
    })

    const columns1 = [
        { field: 'id', headerName: 'ID', width: 10 },
        {
            field: 'WalletAddress',
            headerName: 'Wallet Address',
            width: 170,
            editable: false,
            sortable: false,
        },
        {
            field: 'tokenID',
            headerName: 'TokenID',
            width: 170,
            editable: false,
            sortable: false,
        },
        {
            field: 'NFT_Name',
            headerName: 'NFT Name',
            width: 240,
            editable: false,
            sortable: false,
        },
        {
            field: 'Royalties',
            headerName: 'Royalties',
            width: 150,
            editable: false,
            sortable: false,
        },
        {
            field: 'Network',
            headerName: 'Network',
            width: 200,
            editable: false,
            sortable: false,
        },
        {
            field: 'Action',
            headerName: 'Action',
            width: 200,
            editable: false,
            sortable: false,
            renderCell: (params) => (
                <strong>

                    <button
                        className="btn create_btn"
                        style={{ marginLeft: 0 }}
                        onClick={() => { ChangeStatus(params) }}
                    >
                        Create Voting
                    </button>
                    {/* <button
                        className="btn"
                        style={{ marginLeft: 3 }}

                    >
                        Open
                    </button> */}
                </strong>
            ),
        },
    ];

    const ChangeStatus = async (Data, Status) => {
        console.log("DataaaaDattttt", Data.row);
        // window.$('#createavote_modal').modal('show');
        setcurrentFractions(Data.row)
        setoptions1(Data.row.VoteDetails)
        setCreateComp(true)
        ShowModelssss(Status, Data.row)
    }

    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
        border: 0,
        color:
            theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        WebkitFontSmoothing: 'auto',
        letterSpacing: 'normal',
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
        },
        '& .MuiDataGrid-iconSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            borderRight: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
                }`,
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
                }`,
        },
        '& .MuiDataGrid-cell': {
            color:
                theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
        },
        '& .MuiPaginationItem-root': {
            borderRadius: 0,
        },
        '& .MuiDataGrid-footerContainer': {
            display: "flex",
            justifyContent: "center"
        },

        ...customCheckbox(theme),
    }));

    useEffect(() => {
        console.log("selectedOption", selectedOption1, currentFractions);
        if (selectedOption1 !== null && selectedOption1?.value !== "No Data") {
            console.log("kadsdnaskjndkas");
            let Form = Formdata;
            Form = { ...Form, ...selectedOption1 };
            console.log("FormFormFormFormForm", Form);
            // Formdata.ShortName = selectedOption1.ShortName
            // Formdata.VotingCat = selectedOption1.VotCategory
            // Formdata.VotingEndDate = selectedOption1.VotingEndDate
            // Formdata.VotingStartedDate = selectedOption1.VotingStartedDate
            // Formdata.VoteMsg = selectedOption1.VoteMsg
            let Percentagecal = ((selectedOption1?.VoteCount || 0) / (currentFractions?.NFTDetailDet?.tokenQuantity || 0))
            let Multiply = Percentagecal * 100
            console.log("PercentagecalPercentagecal", Multiply);
            setVotPer(Math.round(Multiply))
            setAuctionStart(selectedOption1.VotingStartedDate)
            setFormdata(Form)
            setAuctionEnd(selectedOption1.VotingEndDate);
            localStorage.setItem("CreateStatus", "Update");
        } else {
            localStorage.setItem("CreateStatus", "Create");
        }

    }, [selectedOption1])

    useEffect(() => {
        GetFaitReveueDetails()

    }, [Wallet_Details.UserAccountAddr, Wallet_Details.setshowvote])

    const GetFaitReveueDetails = async () => {
        console.log("Wallet_Details.GetFaitReveueDetails", Wallet_Details.UserAccountAddr);
        if (Wallet_Details.UserAccountAddr) {
            var SendData = {
                curraddress: Wallet_Details.UserAccountAddr
            }
            console.log("SendData", SendData);
            var Details = await FractionalDash(SendData)
            console.log("SendDataDetailsreturn", Details);
            if (Details?.data?.userValue?.length > 0) {
                SetFiatRv(Details?.data?.userValue)
            }
            localStorage.setItem("CancelShow", "Hide");
        }
    }

    const ChildReply = (ChildData, Calstate) => {
        if (Calstate === "Start") {
            setAuctionStart(ChildData)
            Formdata.VotingStartedDate = ChildData;
        }
        else {
            setAuctionEnd(ChildData);
            Formdata.VotingEndDate = ChildData;
        }
    };

    const OnChangehandle = (e) => {
        e.preventDefault();
        const { id, value } = e.target;
        let Form = Formdata;
        Form = { ...Form, ...{ [id]: value } };

        setFormdata(Form);

    };

    const OnSubmited = async (data, sat) => {
        console.log("Forrrrrrrrr", data, Formdata, selectedOption);
        Formdata.VotingCat = selectedOption
        Formdata.tokenID = data.tokenID
        Formdata.TokenOwner = data.tokenOwner
        Formdata.WalletAddress = Wallet_Details.UserAccountAddr
        Formdata.CreateStatus = sat ? "Cancel" : localStorage.getItem("CreateStatus")
        let VoteTochange = await ChangeToVote(Formdata)
        console.log("VoteTochange", VoteTochange);
        if (VoteTochange.data.Success) {
            toast.success(VoteTochange.data.message)
            window.$('#createavote_modal').modal('hide');
            setFormdata({
                VotingCat: "",
                VotingEndDate: "",
                VotingStartedDate: "",
                Description: "",
                SellAmount: 0,
                ShortName: "",
                promote: "",
                tokenID: "",
                TokenOwner: "",
                WalletAddress: Wallet_Details.UserAccountAddr,
                CreateStatus: "Create",
                UniqueId: Date.now()
            })
            GetFaitReveueDetails()
        } else {
            toast.error(VoteTochange.data.message)
            window.$('#createavote_modal').modal('hide');
            setFormdata({
                VotingCat: "",
                VotingEndDate: "",
                VotingStartedDate: "",
                Description: "",
                SellAmount: 0,
                ShortName: "",
                promote: "",
                tokenID: "",
                TokenOwner: "",
                WalletAddress: Wallet_Details.UserAccountAddr,
                CreateStatus: "Create",
                UniqueId: Date.now()
            })
            GetFaitReveueDetails()
        }
    }

    const handleChange = (selectedOption) => {
        setSelectedOption1(selectedOption);
        localStorage.setItem("CancelShow", "Show");
    };

    const BalCheck = async () => {
        var TransferAddress = NewOwnerAddress.toLowerCase();
        var web3 = new Web3()
        var validation = web3.utils.isAddress(TransferAddress)
        if (validation == true) {
            const id = toast.loading("Checking Balance & Validating", { closeButton: true });
            let chainid = currentFractions?.Network
            let networkConfiguration = {}
            console.log("sdfsfdfsdfsdfs", Number(chainid), network.MATIC.Chainid);
            if (Number(chainid) === network.MATIC.Chainid) {
                console.log("netter eorrrrkkk maticz", network.MATIC);
                networkConfiguration = network.MATIC
            }
            else if (Number(chainid) === network.ETH.Chainid) {
                console.log("netter eorrrrkkk", network.ETH);
                networkConfiguration = network.ETH
            }
            else if (Number(chainid) === network.CAM.Chainid) {
                console.log("netter eorrrrkkk", network.CAM);
                networkConfiguration = network.CAM
            }
            Wallet_Details.networkConfiguration = networkConfiguration
            let Arg = {
                NFTConAddress: networkConfiguration.NativeToken,
                NFTId: currentFractions.tokenID,
                NFTOwner: currentFractions.tokenOwner,
                Status: true,
                BulK: "Bulkmint"
            }
            let Type = 721;
            console.log("else iffffff");
            if (localStorage.walletConnectType !== "LMWallet") {
                var ContractNFTBal = await NFTHookFun(Arg, Wallet_Details)
                console.log("sdqeqwwqeqwqweqw", ContractNFTBal);
            } else {
                var key = null

                var Argument = [networkConfiguration.MATIC.NativeToken]
                var ContractNFTBal = await LMBalanceCheck(Arg, Type, Wallet_Details, Arg.NFTConAddress, Type == 721 ? "Single" : "Multiple", Arg.NFTConAddress, "balanceOf", Argument, key, Arg.NFTOwner)
            }

            console.log("ContractNFTBal", ContractNFTBal, selectedOption)
            if (Number(ContractNFTBal) >= Formdata.SellAmount) {
                toast.update(id, { render: "Validation Success", type: "success", isLoading: false, autoClose: 2500, });
                if (localStorage.walletConnectType !== "LMWallet") {
                    let SendData = {
                        Wallet_Details: Wallet_Details,
                        Arg:[],
                        Method:"fractionBulkReturn"
                    }
                    const SendLmTokenPullBack = await NFTTokTranfer()
             
                } else {
                    var key = null
    
                    var Argument = [networkConfiguration.MATIC.NativeToken]
                    var ContractNFTBal = await LMBalanceCheck(Arg, Type, Wallet_Details, Arg.NFTConAddress, Type == 721 ? "Single" : "Multiple", Arg.NFTConAddress, "balanceOf", Argument, key, Arg.NFTOwner)
                }
             
            }
            else {
                toast.update(id, { render: "Insufficient Token Balance", type: "error", isLoading: false, autoClose: 2500, });

            }
        } else {
            toast.error("Please Enter Valid Address(Metamask Address)")
        }
    }

    const NFTHookFun = async (Arg, Wallet_Details) => {
        var ContractNFTBal = await useNFTBalCheckToken(Arg, Wallet_Details)
        return ContractNFTBal
    }

    const LMBalanceCheck = async (Arg, Type, Wallet_Details, IntractContact, NameUse, Contract, method, data, Key, tokenOwner) => {
        console.log("LMBalanceCheck", Arg, Type, Wallet_Details, IntractContact, NameUse, Contract, method, data, Key);
        var Arg = {
            Method: method,
            Data: data,
            Mail: localStorage.loggedinaddress,
            LoginAddress: localStorage.userAddress,
            ContactAddress: Contract,
            Type: String(Type),
            Chain: String(tokDetails.SelectedNetwork),
            IntractContract: IntractContact,
            ContactType: NameUse,
            value: Prices.PriceWithSerfee,
            Key: Key,
            tokenOwner: tokenOwner
        }
        var Balance = await LMBalanceOfFraction(Arg)
        console.log("Balance", Balance);
        return Balance.data.receipt
    }

    const ShowModelssss = (Status, detail) => {
        localStorage.setItem("setshowvote", "Show")
        dispatch({
            type: Account_Connect,
            Account_Detail: {
                setshowvote: true,
                CreateOrEdit: Status,
                currentFractions: detail
            }
        })
    }
    return (

        <>
            {!Wallet_Details.setshowvote ?
                <>
                    {console.log("Adjasdcjkdsaca", Wallet_Details)}

                    <div className='scrolling_move_sec_align'>
                        <div className='row mt-3'>
                            <div className='col-xxl-12 col-xl-12 col-lg-8 col-md-12 col-sm-12 col-12'>
                                <h5 className="text-center mb-3" style={{ fontWeight: "700" }}>Fractional NFTs </h5>
                                {console.log("Details.data.Data", FaitRv)}
                                <div style={{ height: 300, width: '100%' }}>
                                    <StyledDataGrid
                                        // checkboxSelection
                                        pageSize={5}
                                        rowsPerPageOptions={[5]}
                                        rows={rows}
                                        columns={columns}
                                        disableColumnMenu
                                        components={{
                                            Pagination: CustomPagination,
                                        }}
                                        className='campaign_muitbl'


                                    />


                                </div>
                            </div>
                        </div>
                    </div>



                    <div
                        className="modal fade primary_modal"
                        id="createavote_modal"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="createavote_modalCenteredLabel"
                        aria-hidden="true"
                        data-backdrop="static"
                        data-keyboard="false"
                    >
                        <div
                            className="modal-dialog modal-dialog-centered modal-sm"
                            role="document"
                        >
                            {!HideShow ?
                                <div className="modal-content">
                                    <div className="modal-header text-center">
                                        <h5 className="modal-title" id="createavote_modalLabel">
                                            Create a Vote
                                        </h5>
                                        <button
                                            id="buy_closs"
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body px-0">
                                        {!selectedOption &&
                                            <div className="mb-3">
                                                <label htmlFor="qty" className="">Voting Pools</label>
                                                <Select
                                                    className="yes1 form-control primary_inp select1 selxet_app"
                                                    defaultValue={selectedOption1}
                                                    onChange={handleChange}
                                                    options={options1}
                                                    styles={customStyles}
                                                    classNamePrefix="react-select"/*  */
                                                />
                                            </div>
                                        }
                                        {console.log("FormdataFormdata", Formdata)}
                                        {!selectedOption1 &&
                                            <div className="mb-3">
                                                <label htmlFor="qty" className="">Voting type</label>
                                                <Select
                                                    className="yes1 form-control primary_inp select1 selxet_app"
                                                    defaultValue={selectedOption}
                                                    onChange={(e) => setSelectedOption(e.value)}
                                                    options={currentFractions.tokenOwner == currentFractions.tokenCreator ? options : options2}
                                                    // value={Formdata.VotingCat}
                                                    styles={customStyles}
                                                    classNamePrefix="react-select"/*  */
                                                />
                                            </div>
                                        }
                                        <div className="mb-3">
                                            <label htmlFor="qty" className="">Short Name</label>
                                            <div className="mb-3 input_grp_style_1">
                                                <input
                                                    type="text"
                                                    id="ShortName"
                                                    className="form-control"
                                                    placeholder="Short Name"
                                                    value={Formdata.ShortName}
                                                    onChange={(e) => OnChangehandle(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="qty" className="">Description</label>
                                            <div className="mb-3 input_grp_style_1">
                                                <textarea class="form-control textarea_des" id="Description" onChange={(e) => OnChangehandle(e)} value={Formdata.Description || Formdata.VoteMsg} rows="3"></textarea>
                                            </div>
                                        </div>
                                        {(selectedOption == "Selling Vote" || Formdata.VotCategory == "Selling Vote") &&
                                            <div className="mb-3">
                                                <label htmlFor="qty" className="">Enter Sell Price</label>
                                                <div className="mb-3 input_grp_style_1">
                                                    <input
                                                        type="number"
                                                        id="SellAmount"
                                                        className="form-control"
                                                        placeholder="Enter Selling Price"
                                                        value={Formdata.SellAmount}
                                                        onChange={(e) => OnChangehandle(e)}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        <div className="mb-3">
                                            <label htmlFor="qty" className="">Vote Percentage</label>
                                            <div className="mb-3 input_grp_style_1">
                                                <input
                                                    type="number"
                                                    id="promote"
                                                    className="form-control"
                                                    placeholder="Enter vote Percent"
                                                    value={Formdata.promote}
                                                    onChange={(e) => OnChangehandle(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="qty" className="">Start Date</label>
                                            <div className="mb-3 input_grp_style_1 pl-3 py-2">
                                                {AuctionStart ? (AuctionStart).toLocaleString() : "Starting Date"}
                                                <div
                                                    className="cursor"
                                                    data-toggle="modal"
                                                    data-target="#calendar_modal"
                                                    onClick={() => { setAucCalender("Start"); setPicStartselected(true) }}
                                                >
                                                    Pick specific date
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="EndDate" className="">End Date</label>
                                            <div className="mb-3 input_grp_style_1 pl-3 py-2">
                                                {AuctionEnd ? (AuctionEnd).toLocaleString() : "Expiration Date"}
                                                <div
                                                    className="cursor"
                                                    data-toggle="modal"
                                                    data-target="#calendar_modal"
                                                    onClick={() => setAucCalender("End")}
                                                >
                                                    Pick specific date
                                                </div>
                                            </div>
                                        </div>
                                        {console.log("Formdata?.VoteCount", Formdata?.VoteCount)}
                                        {Formdata?.VoteCount > 0 &&
                                            <div className="mb-2">
                                                <label htmlFor="qty" className="">Vote Count</label>
                                                <div className="mb-3 input_grp_style_1">
                                                    <input
                                                        type="number"
                                                        id="VoteCount"
                                                        className="form-control"
                                                        placeholder="VoteCount"
                                                        value={Formdata.VoteCount}
                                                        disabled
                                                    // onChange={(e) => OnChangehandle(e)}
                                                    />
                                                </div>
                                            </div>
                                            // : <></>
                                        }

                                        {VotePer > 0 && VotePer &&
                                            <div className="mb-2">
                                                <label htmlFor="qty" className="">Vote Percentage</label>
                                                <div className="mb-3 input_grp_style_1">
                                                    <input
                                                        type="number"
                                                        id="VotePer"
                                                        className="form-control"
                                                        placeholder="VotePer"
                                                        value={VotePer}
                                                        disabled
                                                    // onChange={(e) => OnChangehandle(e)}
                                                    />
                                                </div>
                                            </div>
                                            // : <></>
                                        }

                                        <div className="mb-2">
                                            <Button
                                                className="create_btn mt-1 mb-2"
                                                onClick={() => OnSubmited(currentFractions)}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                        {localStorage.getItem("CancelShow") == "Show" &&
                                            <div className="mb-2">
                                                <Button
                                                    className="create_btn mt-1 mb-2"
                                                    onClick={() => { OnSubmited(currentFractions, "Cancel") }}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        }

                                        {Formdata.promote <= VotePer &&
                                            <div className="mb-2">
                                                <Button
                                                    className="create_btn mt-1 mb-2"
                                                    onClick={() => setHideShow(true)}
                                                >
                                                    Claim NFTs
                                                </Button>
                                            </div>
                                        }
                                    </div>

                                </div>
                                :
                                <div className="modal-content">
                                    <div className="modal-header text-center">
                                        <h5 className="modal-title" id="createavote_modalLabel">
                                            GetBack NFTs & Transfer to New Owner
                                        </h5>
                                        <button
                                            id="buy_closs"
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body px-0">
                                        <div className="mb-3">
                                            <label htmlFor="qty" className="">New Owner Address</label>
                                            <div className="mb-3 input_grp_style_1">
                                                <input
                                                    type="text"
                                                    id="ShortName"
                                                    className="form-control"
                                                    placeholder="Short Name"
                                                    // value={Formdata.ShortName}
                                                    onChange={(e) => setNewOwnerAddress(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <Button
                                                className="create_btn mt-3"
                                                onClick={() => BalCheck(currentFractions)}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </div>

                                </div>
                            }

                        </div>
                    </div>
                    <Calender CalenderState={AucCalendar} ParentCall={ChildReply} />
                </>
                :
                <CreateVote data={currentFractions} />
            }
        </>

    )
}

export default FractionsDash

