import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core';
import SideBar from 'components/Sidebar/SideBar'
import Chart from "react-apexcharts";
import { Link } from 'react-router-dom'
import QrReader from 'react-qr-reader'
import { TicketScan, RouterChecker } from '../../src/actions/v1/token'
import { Apps, CloudDownload, WbSunny, Brightness3 } from "@material-ui/icons";
import { useSelector } from "react-redux";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import config  from '../lib/config'



function QrCodeScan(props) {

  const { ...rest } = props;
  const dashboardRoutes = [];

  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const [theme, setTheme] = useState('dark');
  const [Routeee, SetRouteeee] = useState("Not Allowed")
  const [Routeee1, SetRouteeee1] = useState("Not Allowed")

  const [sidebarShrink, setSidebarShrink] = useState(false);
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const history = useHistory();
  const toggleTheme = () => {
    console.log("theme", theme);
    let bodyDark = document.getElementById("root");
    if (theme === 'light') {
      bodyDark.classList.remove("light_theme");
      bodyDark.classList.add("dark_theme");
      setTheme("dark");

      setTimeout(function () {
        var usebody = document.getElementsByClassName("mobile_nav");
        for (var j = 0; j < usebody.length; j++) {
          usebody[j].classList.remove("light_theme");
          usebody[j].classList.add("dark_theme");
        }
      }, 100);


    } else {
      bodyDark.classList.remove("dark_theme");
      bodyDark.classList.add("light_theme");
      setTheme("light");
      setTimeout(function () {
        var usebody = document.getElementsByClassName("mobile_nav");
        for (var j = 0; j < usebody.length; j++) {
          usebody[j].classList.remove("dark_theme");
          usebody[j].classList.add("light_theme");
        }
      }, 100);

    }
  };

  const [selected, setSelected] = useState("environment");
  const [startScan, setStartScan] = useState(false);
  const [loadingScan, setLoadingScan] = useState(false);
  const [data, setData] = useState("");
  const [ApproveStatus, SetApproveStatus] = useState("");
  const [TicketDetails, SetTicketDeatils] = useState({})
  const [ErrorStatus, SetErrorStatus] = useState(false);
 
  const handleScan = async (scanData) => {
    setLoadingScan(true);
    if (scanData && scanData !== "") {
      setData(scanData);
      
      setStartScan(false);
      setLoadingScan(false);
  
      if (scanData !== null || scanData !== "") {
       
        TicketVerify(scanData);
      }
    }
  };
  const handleError = (err) => {
    console.error("Errorrrrr in scanerrrrr", err);
  };
 
  const TicketVerify = async (add) => {
    console.log("asdasdasdasd", add);
    // var newData = "http://localhost:3000/info/0xc19e67a363e63f062df4380cad8f7bfa35db54d9/0x7f7d098e71d2081cac9546e4bc1954ef6ac20bc9/TokenName6_popo_1"
    var slice = add.split("/")
    console.log("Splitdata is commingg", slice);
    var sendData = {
      TokenOwner: slice[5],
      QR_tokenID: slice[6]
    }
    
    if (sendData.TokenOwner !== undefined) {
      var TicketStatus = await TicketScan(sendData)
      
      if (TicketStatus.data.success == true) {
        SetApproveStatus("Approved")
        SetErrorStatus(TicketStatus.data.success)
        SetTicketDeatils(TicketStatus.data.UpdateStatus)
      }
      else {
        SetErrorStatus(TicketStatus.data.success)
        SetApproveStatus(TicketStatus.data.message)
      }
      
    }

  }
  const ScanNext = async () => {
    setStartScan(true)
    SetApproveStatus("")
  }
  useEffect(() => {
    Route()
  }, [])

  const Route = async () => {
    var Addresss = {
      Address: Wallet_Details.UserAccountAddr
    }
    
    var SubscriberList = await RouterChecker(Addresss)

    if (SubscriberList?.data?.data?.UserBalance.TokenScanner == "true") {
      SetRouteeee(SubscriberList?.data?.data?.userValue)
    }
  }


  const setSidebarWidth = () => {
    setSidebarShrink(!sidebarShrink);
    if (sidebarShrink) {
      document.body.classList.remove('sidebar_shr');
    }
    else {
      document.body.classList.add('sidebar_shr');

    }
  }

  useEffect(() => {
    Route1()
  }, [])

  const Route1 = async () => {
    var Addresss = {
      Address: Wallet_Details.UserAccountAddr
    }
    console.log("qweqweqweqweoiio", Addresss);
    var SubscriberList = await RouterChecker(Addresss)
    console.log("qweqweqweSubscriberList", SubscriberList);
    if (SubscriberList?.data?.data?.UserBalance.TokenScanner == "true") {
      SetRouteeee1(SubscriberList?.data?.data?.UserBalance)
    }
    else {
      // history.push("/userdashboard")
      // toast.error("Your Not Subcribed TokenScanner")
    }
  }





  return (
    <>
      <div className='home_header home'>

        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand={<Link to="/home">
            <div className="header_logo_align">


            </div></Link>}
          leftLinks={<HeaderLinks />}
          changeColorOnScroll={{
            height: 20,
            color: "white"
          }}
          {...rest}
        />
        <div className='container-fluid fluid_padding_align d-flex'>
          <SideBar />
         
         


          <div className='scrolling_move_sec_align'>



          <div className="topcardsecs">
                <div>
                <div className='card  blackcard_style mb-1'>
            <div className='firstrow'>
              <div><i class="fa fa-circle admint_nrm_round admin_green_round mb-2"/></div>
              <div className='div_text'>Total Tokens Scanned</div>
            </div>

            <div className='graph_align'>
              <div className='count_dtls'>
                <span className='count_txt'>1</span><br />

                {/* <small className='green_text'>+10.3% </small>
                <img src={require('../assets/images/faces/small_green_arrow.png')} /> */}

              </div>
              {/* <img className='admin_graph_img_align' src={require('../assets/images/faces/green_graph.png')} /> */}
            </div>
          </div>
                </div>
                <div>
                <div className='card five_div_card blackcard_style mb-1'>
            <div className='firstrow'>
              <div><i class="fa fa-circle admint_nrm_round admin_red_round mb-2"></i></div>
              <div className='div_text'>Authenticated Tokens</div>
            </div>

            <div className='graph_align'>
              <div className='count_dtls'>
                <span className='count_txt'>1</span><br />

                {/* <small className='green_text'>+3,14% </small>
                <img src={require('../assets/images/faces/small_green_arrow.png')} /> */}

              </div>
              {/* <img className='admin_graph_img_align' src={require('../assets/images/faces/blue_graph.png')} /> */}
            </div>
          </div>
                </div>
                <div>
                <div className='card five_div_card blackcard_style mb-1'>
            <div className='firstrow'>
              <div><i class="fa fa-circle admint_nrm_round admin_sandal_round mb-2"></i></div>
              <div className='div_text'>Rejected Tokens</div>
            </div>
 {/* {console.log("qweqweqweads",counts)} */}
            <div className='graph_align'>
              <div className='count_dtls'>
                <span className='count_txt'>1</span><br />

                {/* <small className='green_text'>+0%</small> */}
                

              </div>
              {/* <img className='admin_graph_img_align' src={require('../assets/images/faces/red_graph.png')} /> */}
            </div>
          </div>
                </div>
                <div>
                <div className='card five_div_card blackcard_style mb-1'>
            <div className='firstrow'>
              <div><i class="fa fa-circle admint_nrm_round admin_violet_round mb-2"></i></div>
              <div className='div_text'>Total Tickets Sold</div>
            </div>

            <div className='graph_align'>
              <div className='count_dtls'>
                <span className='count_txt'>1</span><br />

                {/* <small className='green_text'>+5%</small> */}
               

              </div>
              {/* <img className='admin_graph_img_align' src={require('../assets/images/faces/violet_graph.png')} /> */}
            </div>
          </div>
                </div>
                <div>
                <div className='card five_div_card blackcard_style mb-1'>
            <div className='firstrow'>
              <div><i class="fa fa-circle admint_nrm_round admin_sandal_round mb-2"></i></div>
              <div className='div_text'>Total Tickets Redeemed</div>
            </div>

            <div className='graph_align'>
              <div className='count_dtls'>
                <span className='count_txt'>1</span><br />

                {/* <small className='green_text'>+0%</small> */}
               
              </div>
              {/* <img className='admin_graph_img_align' src={require('../assets/images/faces/sandal_graph.png')} /> */}
            </div>
          </div>
                </div>
                </div>


            {/* <div className='row d-flex justify-content-between'>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                <div className='search_bar_align w-100'>
                  <input type="search" className='admin_searchbar_align' placeholder='Search...' id="gsearch" name="gsearch" />
                  <i class="fa-solid fa-magnifying-glass search_icons_admin"></i>

                </div>

              </div>
              <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12'>

                <div className='admin_profile_dtls_align w-100'>

                  <div className={classes.listItem + " create_btn mode theme_change_btn_align theme_change_btn"}>
                    <Button className={classes.navLink} onClick={toggleTheme}>
                      <p className="light"><Brightness3 /></p>
                      <p className="dark"><WbSunny /></p>
                    </Button>
                  </div>




                  <div class="dropdown user_header_drpdwn_align">
                    <button class="  dropdown-toggle dropdown_toggle_align" type="button" data-toggle="dropdown" aria-expanded="false">
                      <div className='admin_dropdown_align'>
                        <img className='admin_dropdown_img_align' src={require('../assets/images/faces/marc.jpg')} />
                        <div className='admin_dropdown_txt_align'>
                          <p className='owner_admin_text_head'>Dr.Patrick Chin</p>
                          <p className='owner_admin_text'>Owner Admin</p>
                        </div>
                      </div>
                    </button>

                    <div class="dropdown-menu">
                      <a class="dropdown-item" href="#">Action</a>
                      <a class="dropdown-item" href="#">Another action</a>
                      <a class="dropdown-item" href="#">Something else here</a>
                    </div>
                  </div>

                </div>
              </div>


            </div>

            </div> */}

            {/* {ApproveStatus == "false" ? */}
            <Grid container spacing={2} style={{ marginTop: 20, paddingBottom: 10 }}>
              <Grid item xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className='qrcode_whole_dtls_align'>
                  <div className='qrcode_address_dtls_align'>
                    <b>Wallet Address<span> <i class="fa-regular fa-copy" /></span></b><br />
                    <small>opwe79qr4iwhw9yfw9tr3q9t87</small>
                  </div>
                  <div className='qrcode_img_align'>
                    <div className="App">
                      {/* <h1>Hello CodeSandbox</h1>
                <h2>
                    Last Scan:
                    {selected}
                </h2> */}


                      {startScan && (
                        <>
                          <select onChange={(e) => setSelected(e.target.value)}>
                            <option value={"environment"}>Back Camera</option>
                            <option value={"user"}>Front Camera</option>
                          </select>
                          <QrReader
                            facingMode={selected}
                            delay={1000}
                            onError={handleError}
                            onScan={handleScan}
                            // chooseDeviceId={()=>selected}
                            style={{ width: "300px" }}
                          />
                        </>
                      )}
                      {/* {loadingScan && <p>Loading</p>}
                      {data !== "" && <p>{data}</p>} */}
                    </div>
                  </div>
                  {!startScan &&
                    <div className='qrcode_img_align'>
                      <img className='qrcode_img' src={require('../assets/images/faces/qrcode_invert.png')} alt='' />
                    </div>
                  }
                </div>
                <p className='qrcode_scan_txt_align'>SCANNING...</p>
                <div className='text-center mt-2'>

                  <button className='start_scan_btn'
                    onClick={() => {
                      setStartScan(!startScan);
                    }}
                  >
                    {startScan ? "Stop Scan" : "Start Scan"}
                  </button>
                </div>
              </Grid>
              {ApproveStatus !== "" &&
                <>
                  {ErrorStatus == false ?
                    <Grid item xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                      <div className='qrcode_integ_whole_align'>
                        <div className='qrcode_integ_title_align'>
                          <p className='qrcode_event_text'>InValid Ticket</p>

                        </div>
                        <div className='qrcode_description_dtls_align'>
                          <div className='qrcode_btn_align'>
                            <button className='qrcode_attendee_align'>Attendee</button>
                          </div>
                          <div className='p-4'>
                            {/* <h6 className='description_title_align'>Description</h6>
                        <p className='definition_text_align'>Lorem ipsum is adding dummy text in the content lajfo fhusfsa akjndfoashf asjfsai has been...</p>
                        <h6 className='description_title_align'>Network</h6>
                        <p className='definition_text_align'>Polygon</p>
                        <h6 className='description_title_align'>Contact Address</h6>
                        <p className='definition_text_align'>984ojp9w8t94tjwp9o8793t398t3</p>
                        <h6 className='description_title_align'>Owner Address</h6>
                        <p className='definition_text_align'>984ojp9w8t94tjwp9o8793t398t3</p> */}
                            <h2>{ApproveStatus}</h2>
                            <div className='qrcode_description_btn_align'>
                              <button className='qrcode_red_btn_align'>REJECTED</button>
                              <button className='qrcode_black_btn_align' onClick={() => {
                                ScanNext();
                              }}>SCAN NEXT</button>
                            </div>
                          </div>


                        </div>
                      </div>

                    </Grid>
                    :
                    <Grid item xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                      {console.log("qweqweqweqweqwe", TicketDetails)}
                      <div className='qrcode_integ_whole_align'>
                        <div className='qrcode_integ_title_green_align'>
                          <p className='qrcode_event_text'>{TicketDetails?.tokenName} <br />{TicketDetails?.startDateToken} to {TicketDetails?.endDateToken}  </p>

                        </div>
                        <div className='qrcode_description_dtls_align'>
                          <div className='qrcode_btn_align'>
                            <button className='qrcode_attendee_align'>{TicketDetails?.tokenOwner?.slice(0, 10)}</button>
                          </div>
                          <div className='p-4'>
                            <h6 className='description_title_align'>Description</h6>
                            <p className='definition_text_align'>{TicketDetails?.tokenDesc}</p>
                            <h6 className='description_title_align'>Network</h6>
                            <p className='definition_text_align'>{TicketDetails?.SelectedNetwork == config.MATIC_ID ? config.Network_MAT_Cap : TicketDetails?.SelectedNetwork == config.CAM_ID ? config.Network_CAM_Cap : config.Network_ETH_Cap}</p>
                            <h6 className='description_title_align'>Contact Address</h6>
                            <p className='definition_text_align'>{TicketDetails?.contractAddress}</p>
                            <h6 className='description_title_align'>Owner Address</h6>
                            <p className='definition_text_align'>{TicketDetails?.tokenOwner}</p>
                            <div className='qrcode_description_btn_align'>
                              <button className='qrcode_verify_btn_align'>VERIFIED</button>
                              <button className='qrcode_verify_btn_align' onClick={() => {
                                ScanNext();
                              }} >SCAN NEXT</button>

                            </div>
                          </div>


                        </div>
                      </div>

                    </Grid>}
                </>}

            </Grid>
            {/* // : */}
            {/* <Grid container spacing={2} style={{ marginTop: 20, paddingBottom: 10 }}>
              <Grid item xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className='qrcode_whole_dtls_align'>
                  <div className='qrcode_address_dtls_align'>
                    <b>Wallet Address<span> <i class="fa-regular fa-copy" /></span></b><br />
                    <small>opwe79qr4iwhw9yfw9tr3q9t87</small>
                  </div>
                  <div className='qrcode_img_align'>
                    <img className='qrcode_img' src={require('../assets/images/faces/qrcode_invert.png')} alt='' />
                  </div>

                </div>
                <p className='qrcode_scan_txt_align'>SCANNING...</p>

              </Grid>
              <Grid item xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className='qrcode_integ_whole_align'>
                  <div className='qrcode_integ_title_green_align'>
                    <p className='qrcode_event_text'>TEC FOOD FESTIVAL EVENT <br /> 5 - 7 May 2023</p>

                  </div>
                  <div className='qrcode_description_dtls_align'>
                    <div className='qrcode_btn_align'>
                      <button className='qrcode_attendee_align'>Attendee</button>
                    </div>
                    <div className='p-4'>
                      <h6 className='description_title_align'>Description</h6>
                      <p className='definition_text_align'>Lorem ipsum is adding dummy text in the content lajfo fhusfsa akjndfoashf asjfsai has been...</p>
                      <h6 className='description_title_align'>Network</h6>
                      <p className='definition_text_align'>Polygon</p>
                      <h6 className='description_title_align'>Contact Address</h6>
                      <p className='definition_text_align'>984ojp9w8t94tjwp9o8793t398t3</p>
                      <h6 className='description_title_align'>Owner Address</h6>
                      <p className='definition_text_align'>984ojp9w8t94tjwp9o8793t398t3</p>
                      <div className='qrcode_description_btn_align'>
                        <button className='qrcode_verify_btn_align'>VERIFIED</button>
                        <button className='qrcode_verify_btn_align'>SCAN NEXT</button>

                      </div>
                    </div>


                  </div>
                </div>

              </Grid>
            </Grid> */}
            {/* // } */}

            {/* <Grid container spacing={2} style={{ marginTop: 20, paddingBottom: 10 }}>
              <Grid item xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className='qrcode_whole_dtls_align'>
                  <div className='qrcode_address_dtls_align'>
                    <b>Wallet Address<span> <i class="fa-regular fa-copy" /></span></b><br />
                    <small>opwe79qr4iwhw9yfw9tr3q9t87</small>
                  </div>
                  <div className='qrcode_img_align'>
                    <img className='qrcode_img' src={require('../assets/images/faces/qrcode_invert.png')} alt='' />
                  </div>

                </div>
                <p className='qrcode_scan_txt_align'>SCANNING...</p>

              </Grid>
              <Grid item xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className='qrcode_integ_whole_align'>
                  <div className='qrcode_integ_title_green_align'>
                    <p className='qrcode_event_text'>TEC FOOD FESTIVAL EVENT <br /> 5 - 7 May 2023</p>

                  </div>
                  <div className='qrcode_description_dtls_align'>
                    <div className='qrcode_btn_align'>
                      <button className='qrcode_attendee_align'>Attendee</button>
                    </div>
                    <div className='p-4'>
                      <h6 className='description_title_align'>Description</h6>
                      <p className='definition_text_align'>Lorem ipsum is adding dummy text in the content lajfo fhusfsa akjndfoashf asjfsai has been...</p>
                      <h6 className='description_title_align'>Network</h6>
                      <p className='definition_text_align'>Polygon</p>
                      <h6 className='description_title_align'>Contact Address</h6>
                      <p className='definition_text_align'>984ojp9w8t94tjwp9o8793t398t3</p>
                      <h6 className='description_title_align'>Owner Address</h6>
                      <p className='definition_text_align'>984ojp9w8t94tjwp9o8793t398t3</p>
                      <div className='qrcode_description_btn_align'>
                        <button className='qrcode_scanning_btn_align'>Checking In...</button>

                      </div>
                    </div>


                  </div>
                </div>

              </Grid>
            </Grid> */}
            {/* {ApproveStatus == "Approved" &&
              } */}


          </div>




        </div>
      </div>
    </>
  )
}

export default QrCodeScan