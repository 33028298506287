import React, { useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  getWhiteListUser, getSoulBoundWhiteList
} from '../actions/v1/token';
import { useState } from "react";
const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}


export default function Create(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  // console.log("create wallet_Details", Wallet_Details);
  const [userdet, setUser] = useState();
  useEffect(() => {
    getWhiteList();
  }, [Wallet_Details.UserAccountAddr])
  const getWhiteList = async () => {
    var reqbody = {
      Address: Wallet_Details.UserAccountAddr
    }
    var test = await getSoulBoundWhiteList(reqbody);
    console.log("mlsvkdnvdfkmnvd", test);
    if (test.Success) setUser(test.userValue);
  };
  return (
    <div className="home_header create_page">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home">
          <div className="header_logo_align">


          </div></Link>}
        leftLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader inner_page_bg_1"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>

              <p className="heading_big_blk m-0">Create collectible</p>

            </GridItem>
          </GridContainer>
        </div>
        <div className="container mt-4">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <p className="heading_sm_blk_new p-b-sec text-center">Choose “Single” if you want your collectible to be one of a kind or “Multiple” if you want to sell one collectible multiple times</p>
              <div className="notable_sec mx-0 masonry">
                <div className="row w-100 create_row_img justify-content-center">
                  <div className="col-12 col-md-6 col-lg-4 mb-4 ">
                    <Link to={(Wallet_Details.UserAccountAddr !== "") ? "/create-single" : "/connect-wallet"} className="item shadow_card">
                      <div className="card_inner_item h-100">

                        <div className="item_inner_img">
                          <img src={require("../assets/images/create-single-icon.png")} alt="Collections" className="img-fluid" />
                        </div>
                        <div className="text-center card_user_div p-3 card_user_bg_1">
                          <h2 className="text-center">Lazymint Single</h2>
                          <p className="mb-4">Sell your Art, Music or any NFT</p>
                          {(Wallet_Details.UserAccountAddr !== "") ?
                            <Link to="/create-single" className="btn btn_purple_sm create_button create_pg_create_btn create_pg_create_btn_single">Create</Link>
                            :
                            <Link to="/connect-wallet" className="btn btn_purple_sm create_button create_pg_create_btn">Create</Link>
                          }
                        </div>




                      </div>
                    </Link>
                  </div>

                  <div className="col-12 col-md-6 col-lg-4 mb-4">
                    <Link to={(Wallet_Details.UserAccountAddr !== "") ? "/create-multiple" : "/connect-wallet"} className="item shadow_card">
                      <div className="card_inner_item h-100">

                        <div className="item_inner_img">
                          <img src={require("../assets/images/bulk.png")} alt="Collections" className="img-fluid" />
                        </div>
                        <div className="text-center card_user_div p-3 card_user_bg_2">
                          <h2 className="text-center">LazyMint Multiple</h2>
                          <p className="mb-4">Sell your Event Passes, Tickets or Programs</p>
                          {(Wallet_Details.UserAccountAddr !== "") ?
                            <Link to="/create-multiple" className="btn btn_purple_sm create_button create_pg_create_btn create_pg_create_btn_multiple">Create</Link>
                            :
                            <Link to="/connect-wallet" className="btn btn_purple_sm create_button create_pg_create_btn create_pg_create_btn">Create</Link>
                          }
                        </div>




                      </div>
                    </Link>
                  </div>
                  {console.log("sdhgchdsgcjhdgchjdsaj", userdet)}
                  {/* {userdet && userdet.length > 0 && userdet[0].Address && userdet[0].Address == Wallet_Details.UserAccountAddr && */}
                    <div className="col-12 col-md-6 col-lg-4 mb-4">
                      <Link to={(Wallet_Details.UserAccountAddr !== "") ? "/SolBound" : "/connect-wallet"} className="item">
                        <div className="card_inner_item h-100">

                          <div className="item_inner_img">
                            <img src={require("../assets/images/create-multiple-icon.png")} alt="Collections" className="img-fluid" />
                          </div>
                          <div className="text-center card_user_div p-3 card_user_bg_2">
                            <h2 className="text-center">Bulk LazyMinting</h2>
                            <p>(Non-Transferrable Collection)</p>
                            <p className="mb-4">Distribute certificates, authentications, and club memberships</p>
                            {(Wallet_Details.UserAccountAddr !== "") ?
                              <Link to="/SolBound" className="btn btn_purple_sm create_pg_create_btn">Create</Link>
                              :
                              <Link to="/connect-wallet" className="btn btn_purple_sm create_pg_create_btn">Create</Link>
                            }
                          </div>




                        </div>
                      </Link>
                    </div>
                  {/* } */}

                </div>


              </div>

              {/* <p className="create_para mt-3 text-center pb-5">We do not own your private keys and cannot access your funds without your confirmation</p> */}
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
