
import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";

// import fb from "../../assets/images/lazymintingnew/fb.png";
// import insta from "../../assets/images/lazymintingnew/insta.png";
// import utube from "../../assets/images/lazymintingnew/utube.png";
// import twitter from "../../assets/images/lazymintingnew/twitter.png";
// import FooterLogo from "../../assets/images/lazymintingnew/logo.png";

import { v1_Sub } from "actions/v1/user";
import { v1_Featured } from "actions/v1/token";
import { toast, Flip } from "react-toastify";
import { isEmail } from "actions/controller/validation";
import { useSelector } from "react-redux";
import ReactHtmlParser from 'react-html-parser';
import { Link } from "react-router-dom";



export default function Footer() {
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);

  const [EmailID, setEmailID] = useState("");
  const [FootCms, setFootCms] = useState()

  useEffect(() => {
    getFooterCMS()
  }, [])

  const getFooterCMS = async () => {
    var Resp = await v1_Featured()
    if (Resp?.data && Resp?.data?.Success) {
      try {
        var filterfooter = (Resp?.data?.Homecms).filter((cms) => cms.question === "Footer")
        // console.log("cvjdfngvjdfnjg", filterfooter[0])
        setFootCms(filterfooter[0])
      }
      catch (err) {
        // console.log("cvjdfngvjdfnjg", err)
      }
    }
  }

  const NewletterSub = async () => {
    if (isEmail(EmailID)) {
      var Resp = await v1_Sub({ data: EmailID });
      if (Resp.Success) {
        toast.success(Resp.Message, { autoClose: 1500, transition: Flip });
      } else {
        toast.error(Resp.Message, { autoClose: 1500, transition: Flip });
      }
    } else
      toast.error("Enter Valid Mail ID", { autoClose: 1500, transition: Flip });
  };





  return (
    <>
      <footer className="footerhead d-md-inline">
        <div className="container pt-5">
          <div className="row">
            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <div className="quicklinks">
                <h2>Stay in the loop</h2>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor .</p> */}
                <div className="footeremailadd">
                  <input type="email" onChange={(e) => setEmailID(e.target.value)} placeholder="Your Email Address" />
                  <a className="footsign" onClick={() => NewletterSub()}>Sign Up</a>
                </div>
              </div>
            </div>
            <div className="col-lg-1 col-md-12 col-sm-12 col-12"></div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="footerlogo_align">
              <div className="footerlogo">
                
              </div>
              </div>
              {/* {console.log("ewuyiueydiwe",FootCms)} */}
              {FootCms && FootCms ?
                <div className="footer_sm_text">
                  {ReactHtmlParser(FootCms.answer)}
                </div>
                :
                <p className="footer_sm_text">
                  The world’s first and largest digital marketplace for crypto
                  collectibles and non-fungible tokens (NFTs). Buy, sell and
                  discover exclusive digital items.
                </p>
              }


            </div>
      
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="joincommunity">
                <h2>Join the Community</h2>
                <div className="joinlinks">
                  {/* {console.log("fwjfirjfiwjfiuir", Wallet_Details.Social)} */}
                  {Wallet_Details.Social &&
                    Wallet_Details.Social.length > 0 &&
                    Wallet_Details.Social.length > 3 &&
                    Wallet_Details.Social.map((Link) => {
                      // { console.log("qwerttttcmsnewww", Link) }
                      return (

                        <div className="joinlinkss mr-3">
                          {Link.Facebook && (
                            <a href={Link.Facebook} target="_blank">
                              <i className="fab fa-facebook-f footer_social_icons" />
                            </a>
                          )}
                          {Link.Instagram && (
                            <a href={Link.Instagram} target="_blank">
                              <i className="fab fa-instagram footer_social_icons" />
                            </a>
                          )}
                          {Link.Youtube && (
                            <a href={Link.Youtube} target="_blank">
                              <i class="fab fa-youtube footer_social_icons" />
                            </a>
                          )}
                          {Link.Twitter && (
                            <a href={Link.Twitter} target="_blank">
                              <i class="fa-brands fa-x-twitter footer_social_icons" />
                            </a>
                          )}

                        </div>
                      );
                    })}

                </div>

              </div>
            </div>


          </div>
          <p className="copyrights mb-4 pb-2">
          Copyright © 2022. Lazyminter is a product incubated by Clairvoyant Lab. All Rights Reserved <Link className="privacy_policy_lnk" to='/privacy-policy'>Privacy Policy</Link> <Link className="privacy_policy_lnk" to='/terms-and-conditions'>Terms & Conditions</Link>
        </p>

        
        </div>
      
      </footer>

    </>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
