import React, {
  useEffect,
  useState,
  forwardRef,
} from 'react';
import Web3 from 'web3';
import config from '../../lib/config';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
toast.configure();
let toasterOption = config.toasterOption;

export const PaypalJS = ((props) => {
  console.log("propssssss", props);
  const history = useHistory();
  const Wallet_Details = useSelector(state => state.wallet_connect_context);
  const [Transcation ,SetTransCation]=useState({})
 
  // const initialvalue = {
  //     Token : props.NoOfToken,
  //     stateforitem : props.stateforitem,
  //     stateforPayPal : props.stateforPayPal[0]
  // }
  // const [PaypalState, set_PaypalState] = React.useState({});

  //     async function paypal1(){                                       
  //         props.a
  //   }
  // useEffect(() => {
  //     if(props.NoOfToken){
  //     const initialvalue = {
  //         Token : props.NoOfToken,
  //         stateforitem : props.stateforitem,
  //         stateforPayPal : props.stateforPayPal[0]
  //     }
  //     set_PaypalState(initialvalue);
  //     console.log("paypalstate",PaypalState.Token,"propssssss", props.NoOfToken);
  // }
  // }, [PaypalState]);

  async function paypal2() {

    //   console.log("actionsfor order.capture", actions);
    //   return actions.order.capture().then((details) => {
    //       if (details) {
    //           FormSubmit12()
    //       }
    //       const name = details.payer.name.given_name;
    //       alert(`Transaction completed by ${name}`);
    //       console.log("details for capture ", details);


    //   })
  }

  // async function FormSubmit12() {

  //     console.log("jhgfhjgfjhgjhuguyguy", Wallet_Details.UserAccountAddr, props.stateforPayPal[0], props.stateforitem);
  //     var receipt = null;
  //     var handle = null;
  //     var CoursetroContract = null;
  //     if (Wallet_Details.UserAccountAddr !== "") {
  //         var web3 = new Web3(Wallet_Details.providerss)
  //         if (web3) {

  //             try {
  //                 if (props.stateforPayPal[0].type === 721) {
  //                     console.log("xcvnlcdvnd", props.stateforPayPal[0].type);
  //                     CoursetroContract = new web3.eth.Contract(
  //                         Single,
  //                         props.stateforPayPal[0].contractAddress
  //                     );
  //                     await CoursetroContract.methods
  //                         .fiatTransfer(
  //                             props.stateforPayPal[0].tokenOwner,
  //                             props.stateforPayPal[0].tokenCounts,
  //                         )
  //                         .send({ from: Wallet_Details.UserAccountAddr })
  //                         .on('transactionHash', async (transactionHash) => {
  //                             handle = setInterval(async () => {
  //                                 receipt = await getReceipt(web3, transactionHash)
  //                                 clr1();
  //                             }, 8000)
  //                         })
  //                 }
  //                 else {
  //                     CoursetroContract = new web3.eth.Contract(
  //                         Multiple,
  //                         props.stateforPayPal[0].contractAddress
  //                     );
  //                     await CoursetroContract.methods
  //                         .fiatTransfer(
  //                             props.stateforPayPal[0].tokenOwner,
  //                             props.stateforPayPal[0].tokenCounts,
  //                             props.NoOfToken,
  //                         )
  //                         .send({ from: Wallet_Details.UserAccountAddr })
  //                         .on('transactionHash', async (transactionHash) => {
  //                             handle = setInterval(async () => {
  //                                 receipt = await getReceipt(web3, transactionHash)
  //                                 clr1();
  //                             }, 8000)
  //                         })
  //                 }

  //             }

  //             catch (e) {
  //                 console.log('error : ', e);
  //                 toast.error('Order not placed', toasterOption)
  //             }


  //             async function clr1() {

  //                 if (receipt !== null) {
  //                     clearInterval(handle);
  //                     if (receipt.status === true) {
  //                         var postData = {
  //                             tokenOwner: props.stateforPayPal[0].tokenOwner, // old owner
  //                             UserAccountAddr: Wallet_Details.UserAccountAddr, // new owner
  //                             tokenCounts: props.stateforPayPal[0].tokenCounts,
  //                             tokenType: props.stateforPayPal[0].type,
  //                             NoOfToken: props.NoOfToken,
  //                             transactionHash: receipt.transactionHash,
  //                             contractAddress: props.stateforPayPal[0].contractAddress,
  //                             tokenCreator: props.stateforPayPal[0].tokenCreator
  //                         }
  //                         var Resp = await Transfer_Complete_Action(postData);
  //                         if (Resp.data) {
  //                             toast.success("Transfred successfully", toasterOption)
  //                             window.$('#Transfer_modal').modal('hide');
  //                             setTimeout(() => { history.push("/my-items"); }, 8000);
  //                         }

  //                     }
  //                 }
  //             }
  //         }
  //     }
  // }

  // const createOrder = (data, actions) => {
  //     console.log("dgnkfjkjhdfskjhsdf", Tokenno);
  //     var ammounttuh = (props.stateforPayPal[0].tokenPrice * props.NoOfToken)

  //     return actions.order
  //         .create({
  //             purchase_units: [
  //                 {
  //                     amount: {
  //                         currency_code: "USD",
  //                         value: props.stateforPayPal[0].type == 721 ? props.stateforPayPal[0].tokenPrice : ammounttuh,
  //                     },
  //                     payee: {
  //                         email_address: props.stateforitem?.tokenCreatorInfo?.email[0]
  //                     }
  //                 },
  //             ],
  //         })
  // };
  return (
    <PayPalScriptProvider options={{
      "client-id": config.PayPalKey
    }}>
      {console.log("iooioiwq", config.PayPalKey)}
      <PayPalButtons
        style={{ layout: "vertical" }}

        forceReRender={[props.Item.Price]}
        createOrder={(data, actions) => {

          return actions.order
            .create({
              purchase_units: [
                {
                  amount: {
                    currency_code: "USD",
                    value: props.Item.Price,
                  },
                },
              ],
            })
            .then((orderId) => {
              console.log("qewweqweqwqe", orderId);
              return orderId;
            });
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then((details) => {
            console.log("asdasdasdas", details);
            const Data = {
              Buyername: details.payer.name.given_name,
              id: details.purchase_units[0].payments.captures[0].id,
              TranscationId: details.id
            }
            SetTransCation(Data)
            console.log("qqweweoioiio", Data);
          });
        }}
        onError={(err) => {
          console.log("qweqeqwe", err);
          toast.error(err, { autoClose: 1000 });
        }}

      />
    </PayPalScriptProvider>
  )
})

  // export default Paypalfunction