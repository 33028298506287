import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
//import Config from "../../lib/config";
import DETH_ABI from '../ABI/token.json'
import Torus from "@toruslabs/torus-embed";
import { TorusWalletAdapter } from "@web3auth/torus-evm-adapter";
import SINGLE from "../ABI/721.json"
import { network } from "views/network";
import { AES, enc } from 'crypto-js'
import Darklogo from "../assets/images/lazymintingnew/logodark.png"
import market from "../ABI/1155.json";
import TradeAbi from "../ABI/market.json"
import TradeFraction from "../ABI/FractionTrade.json"
import { Web3Auth } from "@web3auth/modal"
import { Web3AuthNoModal } from "@web3auth/no-modal";;
import {
  WALLET_ADAPTERS,
  CHAIN_NAMESPACES,
  SafeEventEmitterProvider,
} from "@web3auth/base";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";

import { GetJwtToken } from "../actions/v1/token"
import { TorusWalletConnectorPlugin } from "@web3auth/torus-wallet-connector-plugin";
import { EthereumProvider } from '@walletconnect/ethereum-provider';
import Configsfrom from "../lib/config"
import { LMWalletCreate } from "actions/v1/user";
// import { Venly } from "@venly/web3-provider";
import Config from "../lib/config"


export const connectWallet = async (type, configchainid, Create, Wallet_Details, Torus, register) => {
  console.log("dxngmdfngkfdngkdfnkdf", type, configchainid, Create, Wallet_Details, Torus, register);
  let Config = {}
  Config = await configChange(configchainid)
  console.log("adnfjksdnfksdjfnvjdkf", Config);
  console.log("dsbgdtneh", type, configchainid);
  var accountDetails = {}
  var web3Obj = {}

  if (type == "MetaMask" || type == 'BinanceWallet' || type == 'Coinbase') {
    // console.log("MetaMask fdtreyrytry",type,configchainid);
    web3Obj = await MetamaskWallet(type, configchainid)
    // console.log("ndbfcnkdsbfkds",web3Obj);
  }
  else if (type == 'WalletConnect') {
    console.log("sdfafasdasdwalletconnect");
    web3Obj = await WalletConnect(type, configchainid, Torus)
  }
  else if (type == "Venly") {
    // rpcUrl:configchainid !== 80001 ? "https://data-seed-prebsc-1-s1.binance.org:8545/" :"https://matic-mumbai.chainstacklabs.com",
    // environment: "staging", //optional, production by default
    // secretType: configchainid !== 80001 ? 'BSC' : 'MATIC' //optional, ETHEREUM by default
    // console.log("qwewqewqrerewrieur",configchainid !== '80001',configchainid,typeof(configchainid));
    const options = {
      clientId: "LazyMinter",
      rpcUrl: "https://polygon-rpc.com",
      environment: "staging", //optional, production by default
      secretType: 'MATIC' //optional, ETHEREUM by default
    };
    // console.log("sdjfknsdkjvnskfd",type,configchainid);
    if (Create == "Create") {
      var web3Obj = false
      //  await VenlyWalletChangeScrete(options,type,configchainid);
    }
    else {
      var web3Obj = false
      //  await VenlyWallet(options,type,configchainid);
    }
    // console.log("options",options)
  }
  else if (type == "Torus") {

    console.log("sdjfknsdkjvnskfd", type, Config.Chainid);
    // if (Create == "Create") {
    //   const options = {
    //     clientId: "LazyMinter",
    //     rpcUrl: "https://polygon-rpc.com",
    //     environment: "staging", //optional, production by default
    //     secretType: 'MATIC' //optional, ETHEREUM by default
    // };
    if (Torus == undefined) {
      var web3Obj = await TorusWalletConnect(type, Config.Chainid);
      console.log("web3Obj for Torus", web3Obj);
    } else {
      var web3Obj = await SwitchChainTorus(type, configchainid, Wallet_Details);
      console.log("web3Os binace switch", web3Obj);
    }
    // }
    // else {
    //   var web3Obj = false
    //   //  await VenlyWallet(options,type,configchainid);
    // }
  }
  else if (type == "TorusWallet") {
    if (Torus == undefined) {
      var web3Obj = await TorUs(type, configchainid, Wallet_Details);
      console.log("web3Os binace switch", web3Obj);
    } else {
      var web3Obj = await TorUsSwitch(type, configchainid, Wallet_Details);
      console.log("web3Os binace switch", web3Obj);
    }

  }

  else if (type == "LMWallet") {
    console.log("Config", Config);
    web3Obj = await LMWalletConnect(type, Config, Create)
  }
  console.log("web3Obj", web3Obj);
  if (web3Obj) {
    try {
      let accounts
      console.log("accounts try",configchainid, Config.Chainid);
      if(localStorage.walletConnectType == "LMWallet"){
         accounts = 0 
      }else{
          accounts = await web3Obj.web3.eth.getAccounts();
      }
     
      console.log("accounts",accounts, accounts.length);
      if ((accounts.length !== 0 && accounts.length !== undefined) || accounts !== 0 ){
        console.log("sdaskdmaslkmdlaks");
         accountDetails.accountAddress = accounts[0].toString().toLowerCase();
      }
      else {
        console.log("akmdaskmdlksa");
        accountDetails.accountAddress = web3Obj.accountAddress;
      }

      console.log("accountDetails.accountAddress", accountDetails.accountAddress);
      try {
        // console.log("accountDetails.accountAddress",web3Obj.eth,accountDetails.accountAddress);

        var coinBalance = await web3Obj.web3.eth.getBalance(accountDetails.accountAddress);
        accountDetails.coinBalance = Number(web3Obj.web3.utils.fromWei(String(coinBalance)))
        console.log("DETH_ABI, Config.tokenAddr", Config.tokenAddr[Config.tokenSymbol]);
        var conObj = new web3Obj.web3.eth.Contract(
          DETH_ABI, Config.tokenAddr[Config.tokenSymbol]
        )
        // console.log("sdflsdnflsdnfl",conObj);
        accountDetails.tokenBalance = await conObj.methods.balanceOf(accountDetails.accountAddress).call()
        // console.log("accountDetails.tokenBalance",accountDetails.tokenBalance);
      } catch (err) {
        console.log("sdfbgadsgf", err);
      }
      console.log("snvfkjnvgkfmkvfe", web3Obj);
      accountDetails.web3 = web3Obj.web3;
      accountDetails.web3auth = web3Obj?.web3auth
      accountDetails.LMWallet = web3Obj?.LMWallet
      console.log("accountDetails..fgdrtfhjutedyjedt........", accountDetails);
      return accountDetails;
    }
    catch (e) {
      console.log("errrrrrr", e);
      return accountDetails;
    }
  }

}
export const MetamaskWallet = async (type, configchainid, config) => {
  var web3
  var Config = config
  try {
    if (window.ethereum && type == 'MetaMask') {

      web3 = new Web3(window.ethereum);
      // console.log("dfghrtfh",web3);
      if (window.ethereum.isMetaMask == true) {
        const chainId = await web3.eth.getChainId();
        console.log("accountDetails type id@che", chainId, configchainid, parseInt(chainId), parseInt(configchainid))
        if (parseInt(chainId) != parseInt(configchainid)) {
          // console.log("asdasdasdasdazxczxc");
          chainIdCheck(configchainid)
          localStorage.setItem('walletConnectType', type)
          // console.log("ndcndklcnfsdlncflsd");
          var weboe = {
            web3: web3,
            web3auth: null
          }
          return weboe
        }
        await window.ethereum.enable().then(async () => {
          // User has allowed account access to DApp...
          const accounts = await web3.eth.getAccounts();
          // console.log("accountssss",accounts);
          const account = accounts[0].toString().toLowerCase();
          console.log("account",account);
          localStorage.setItem("accountInfo", account)
          localStorage.setItem('walletConnectType', type)
        });

        // window.ethereum.enable()
        // window.ethereum.request({
        //   method: "eth_requestAccounts",
        // })
      }
      else {
        alert("Please Uninstall CoinbaseWallet")
        return false;
      }
    }
    // Legacy DApp Browsers
    else if (window.web3) {
      // console.log("dacdcvxxzzxczxc");
      web3 = new Web3(window.web3.currentProvider);
      const chainId = await web3.eth.getChainId();
      if (parseInt(chainId) != parseInt(configchainid)) {
        chainIdCheck()
        localStorage.setItem('walletConnectType', type)
        return true
      }
      const accounts = await web3.eth.getAccounts();
      const account = accounts[0].toString().toLowerCase();
      localStorage.setItem("accountInfo", account)
      localStorage.setItem('walletConnectType', type)
    }
    // Non-DApp Browsers
    else {
      //alert('No Dapp Supported Wallet Found');
      ////console("No Dapp Supported Wallet Found")
    }
  } catch (e) {
    // console.log("accountDetails type id1 last",e)
  }
  console.log("web3................................", web3);
  var weboe = {
    web3: web3,
    web3auth: null
  }
  return weboe;

}

export const WalletConnect = async (type, configchainid, status) => {
  try {
    console.log("jhdjsdf", type, status, configchainid);
    const CHAIN_ID = configchainid;
    const id = "ceda0e402fa096c2645a342d4fd402f3"


    if (localStorage.getItem("accountInfo")) {
      //  const provider=await EthereumProvider.init({
      //   projectId: 'b8a1daa2dd22335a2fe1d2e139980ae0', // required
      //   chains: [CHAIN_ID], // required
      //   accounts:json.parse(localstorage.getitem("wc@2:client:0.3//session"))[json.parse(localstorage.getitem("wc[0],
      //   showQrModal: true 
      //  })


      if (status == 'Tor') {
        try {
          const hexString = configchainid.toString(16);

          console.log("vkfjkvfb", hexString, configchainid);

          const provider = await EthereumProvider.init({
            projectId: id, // required
            chains: [configchainid && configchainid], // required
            showQrModal: true // requires @walletconnect/modal
          })
          await provider.enable()
          console.log("flkkfbk", provider);
          // await provider.connect()


          var web3 = new Web3(provider);
          const accounts = await provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: "0x" + hexString }],
          });
          console.log("kjdfksk", accounts);
          // var account = accounts[0]?.toString();
          // localStorage.setItem("accountInfo", account)
          //   localStorage.setItem('walletConnectType', type)
          return web3;
        }
        catch (err) {
          console.log("ffddfbdfb", err);
        }
      }
      else {
        const provider = await EthereumProvider.init({
          projectId: id, // required
          chains: [CHAIN_ID], // required
          showQrModal: true // requires @walletconnect/modal
        })
        await provider.enable()








        // await provider.connect()

        var web3 = new Web3(provider);
        const accounts = await provider.request({
          method: 'eth_requestAccounts',
        });
        var account = accounts[0].toString().toLowerCase();
        localStorage.setItem("accountInfo", account)
        localStorage.setItem('walletConnectType', type)
        var weboe = {
          web3: web3,
          web3auth: null
        }
        return weboe;
      }
    }

    else {

      const provider = await EthereumProvider.init({
        projectId: 'b8a1daa2dd22335a2fe1d2e139980ae0', // required
        chains: [CHAIN_ID], // required
        showQrModal: true // requires @walletconnect/modal
      })
      await provider.connect()

      var web3 = new Web3(provider);
      const accounts = await provider.request({
        method: 'eth_requestAccounts',
      });
      var account = accounts[0].toString().toLowerCase();
      localStorage.setItem("accountInfo", account)
      localStorage.setItem('walletConnectType', type)
      var weboe = {
        web3: web3,
        web3auth: null
      }
      return weboe;
    }
  }
  catch (err) {
    console.log("Errorrrr", err);
    return err
  }
}


// async function connect_Wallet(type) {
//   if (type == 'wc') {
//     var provider = new WalletConnectProvider({
//       rpc: {
//         1: Config.BNBPROVIDER,
//         // 97:"https://data-seed-prebsc-1-s1.binance.org:8545/"
//       },
//       network: 'mainnet',
//       chainId: 1,
//       // chainId: 97,
//     }
//     );
//     return provider;
//   }

//   else if (type == 'mt' || type == 'math' && window.ethereum.providers) {
//     try{
//       //console("szderhgsydrh", window.ethereum.providers)
//       var provider =  window.ethereum.providers.find((provider) => provider.isMetaMask);
//       }
//       catch(eror)
//       {
//         //console("incatch",eror)
//         var provider =  window.ethereum
//       }
//       return provider;	
//   }

// }

const configChange = async (configchainid) => {
  console.log("dsnfkdsnsdns", configchainid);
  var Config = {}
  // console.log("dskndsknfkdsdncmndsnk",Number(configchainid) === network.ETH.Chainid,configchainid,network.MATIC.Chainid,typeof(network.ETH.Chainid),typeof(Number(configchainid)));
  if (configchainid === network.MATIC.Chainid) {
    // console.log("network.ETH.Chainid",configchainid,network.MATIC.Chainid)
    Config = network.MATIC
  }
  else if (Number(configchainid) === network.ETH.Chainid) {
    // console.log("configchang comming in else if",configchainid,network.ETH.Chainid);
    Config = network.ETH
  }
  else if (Number(configchainid) === network.CAM.Chainid) {
    // console.log("configchang comming in else if",configchainid,network.ETH.Chainid);
    Config = network.CAM
  }
  // console.log("asddmksandman",Config);
  return Config
}

const chainIdCheck = async (configchainid) => {
  // console.log("sjdhfjkdsfjkldsf",configchainid);
  var Config = await configChange(configchainid)
  try {
    // console.log("inside chainIdCheck",configchainid,typeof(configchainid));
    // Check if MetaMask is installed
    // MetaMask injects the global API into window.ethereum
    console.log("dfsbhfsgnj",configchainid);
    const ChainNameAdd = configchainid == Configsfrom.MATIC_ID ? Configsfrom.tickerNamePolygon : Configsfrom.tickerNameEthereum;
     console.log("dsasdafdfxcvncvnmxcnvxcn",ChainNameAdd);
    const hexString = configchainid && configchainid.toString(16);
   console.log("hexString","0x" + hexString,typeof("0x" + hexString));
    if (window.ethereum) {
      try {
        // check if the chain to connect to is installed
        // console.log("ifffffffffff");

        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: "0x" + hexString }], // chainId must be in hexadecimal numbers
        });
        return true;
      } catch (error) {
         console.log("error............",error);
        // This error code indicates that the chain has not been added to MetaMask
        // if it is not, then install it into the user MetaMask
        if (error.code === 4902) {
          try {

            // console.log("sdfsfsdfsdfsdgsdgfg",Config,Config.RPC_URL,hexString);
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: "0x" + hexString,
                  chainName: ChainNameAdd,
                  rpcUrls: [Config.RPC_URL],
                },
              ],
            });
            return true;
          } catch (addError) {
             console.log("err in nested catch",addError);
            console.error(addError);
          }
        }
        console.error(error);
      }
    } else {
      // if no window.ethereum then MetaMask is not installed
      //console('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html');
      return false;

    }
  } catch (e) {
    // console.log("sdjqkjdhksjkbkjwkewhdk",e);
  }
}

export const getServiceFees = async (configchainid) => {
  console.log("FDbdfb", configchainid);
  var ConfigData = await configChange(configchainid)
  console.log("Configgetservicefeee", ConfigData, ConfigData.BNBPROVIDER);
  var rpcObj = new Web3(ConfigData.BNBPROVIDER)


  if (rpcObj) {
    try {
      var marketObj = new rpcObj.eth.Contract(
        // TradeAbi,
        // ConfigData.TradeContract
        TradeFraction,
        ConfigData.FractionTradeContract
      );
      var servicefees = await marketObj.methods.getServiceFee().call()
      console.log("servicefee", servicefees, servicefees[0], servicefees[1]);

      var marketObjTrade = new rpcObj.eth.Contract(
        TradeFraction,
        ConfigData.FractionTradeContract
      );
      var servicefeesFraction = await marketObjTrade.methods.getServiceFee().call()
      console.log("marketObjTrade", servicefees, servicefees[0], servicefees[1]);
      var SerfeeValue = { Buyer: Number(Web3.utils.fromWei(servicefees[0])), Seller: Number(Web3.utils.fromWei(servicefees[1])) }
      var SerfeeFraction = { Buyer: Number(Web3.utils.fromWei(servicefeesFraction[0])), Seller: Number(Web3.utils.fromWei(servicefeesFraction[1])) }
      var Serfee = {
        Serfee: SerfeeValue,
        SerfeeFraction: SerfeeFraction
      }
      return Serfee;
    }
    catch (e) {
      console.log("service fees catch blok running", e)
    }
  }
}

export const VenlyWallet = async (options, type, configchainid) => {
  // console.log("sdncfsdmfckosdmckl",options.secretType,type,options)
  try {
    var provider = false
    //  await Venly.createProviderEngine(options)
    // console.log('Providervemlyyyy',provider);
    // Venly.createProviderEngine(options);
    // Venly.changeSecretType('BSC')
    // var provider = await Venly.createProviderEngine(options);
    if (provider) {
      var web3 = new Web3(provider);
      // const accounts = await web3.eth.getAccounts();
      // console.log("nadsmncsncfksdnml",web3);
      // var balance = await web3.eth.getBalance(accounts[0]);
      const accounts = await web3.eth.getAccounts();
      // console.log("accountssss",accounts);
      const account = accounts[0].toString().toLowerCase();
      // console.log("account",account);
      localStorage.setItem("accountInfo", account)
      localStorage.setItem('walletConnectType', type)
      return web3
      //  {
      //   accountAddress: accounts[0].toString().toLowerCase(),
      //   web3: web3,
      //   web3p: new Web3(options.rpcUrl),
      //   coinBalance: balance ?? "0",
      // };
    } else {
      return null;
    }
  } catch (error) {
    // console.log("eroor in venly",error)
    if (error.code === 4902) {
      try {
        await provider.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: web3.utils.toHex(configchainid).toString(),
              rpcUrl: options.rpcUrl,
            },
          ],
        });
        return true;
      } catch (addError) { }
    } else return null;
  }
};

export const TorusWalletConnect = async (type, configchainid) => {
  try {
    const web3auth = new Web3AuthNoModal({
      clientId: "BHaM1I-3TBoTK4nKhj0yr1c9WBaWWxkGoGg9C9lkl0tXa-NHqsDK0JkPudFe5u2ku3ex5JBYs3rsduqyvaG6jtE",
      web3AuthNetwork: "cyan",
      chainConfig: {
        chainNamespace: "eip155",
        chainId: "0x1",
        rpcTarget: "https://mainnet.infura.io/v3/a6738b5a17514327ae8c9afc47b6179c",
        displayName: "Ethereum Mainnet",
        blockExplorer: "https://etherscan.io",
        ticker: "ETH",
        tickerName: "Ethereum",
      },
    });
    console.log("initial Web3Auth", web3auth);
    if (Number(configchainid) == Number(137)) {
      console.log("comming in swich");
      await web3auth.switchChain({ chainId: "0x89" });
    }
    const openloginAdapter = new OpenloginAdapter({
      adapterSettings: {
        loginConfig: {
          jwt: {
            verifier: "Lazyminter_Live_3",
            typeOfLogin: "jwt",
            clientId: "BHaM1I-3TBoTK4nKhj0yr1c9WBaWWxkGoGg9C9lkl0tXa-NHqsDK0JkPudFe5u2ku3ex5JBYs3rsduqyvaG6jtE",
          },
        },
      },
    });
    console.log("openloginAdapteropenloginAdapter", openloginAdapter);
    if (web3auth) {
      console.log("configureAdapter");
      web3auth.configureAdapter(openloginAdapter);
    }
    await web3auth.init();
    if (!web3auth) {
      console.log("web3auth not initialized yet");
      return;
    }
    var loginMail = {
      Mail: localStorage.loggedinaddress
    }
    console.log("loginMail For TokenID", loginMail);
    var JwtSignTokenCall = await GetJwtToken(loginMail)
    if (JwtSignTokenCall.data.data.token !== undefined && web3auth) {
      console.log("GetJwtToken IS comming", JwtSignTokenCall.data.data.token);
      console.log("before connectTo", web3auth, WALLET_ADAPTERS.OPENLOGIN);
      const web3authProvider = await web3auth.connectTo(
        WALLET_ADAPTERS.OPENLOGIN,
        {
          loginProvider: "jwt",
          extraLoginOptions: {
            id_token: String(JwtSignTokenCall.data.data.token),
            verifierIdField: "email",
            // domain: "https://lazyminter.com/",
          },
        }
      );
      console.log("Const Web3AuthProvider", web3authProvider, web3auth);
      if (web3authProvider) {
        console.log("web3authProvider", web3authProvider);
        var web3 = new Web3(web3authProvider);
        console.log("web3web3 for Torus", web3);
        const accounts = await web3.eth.getAccounts();
        console.log("accountssss", accounts);
        const account = accounts[0].toString().toLowerCase();
        localStorage.setItem("Provider", web3)
        localStorage.setItem("accountInfo", account)
        localStorage.setItem('walletConnectType', type)
        var weboe = {
          web3: web3,
          web3auth: web3auth
        }
        return weboe
      } else {
        console.log("Comming is Error");
      }
    }
    console.log("WebAuth Final console", web3auth);
  } catch (error) {
    console.log("Catch error in Web3Auth", error);
  }
}

export const SwitchChainTorus = async (type, configchainid, Wallet_Details) => {
  try {
    console.log("asdasdasdsa", configchainid, Wallet_Details);
    if (Number(configchainid) == 1) {
      var addingChain = await Wallet_Details.web3auth.addChain({
        chainId: "0x1",
        displayName: "Ethereum Mainnet",
        chainNamespace: CHAIN_NAMESPACES.EIP155,
        tickerName: "Ethereum",
        ticker: "ETH",
        decimals: 18,
        rpcTarget: "https://mainnet.infura.io/v3/a6738b5a17514327ae8c9afc47b6179c",
        blockExplorer: "https://etherscan.io",
      });
      console.log("addingChain Web3Auth", addingChain);
      console.log("comming in swich", Wallet_Details);
      var switched = await Wallet_Details.web3auth.switchChain({ chainId: "0x1" });
      console.log("switched", switched);
    } else {
      console.log("Comming on last else for switchCahin", Wallet_Details.web3auth, Wallet_Details?.web3);
      var addingChain = await Wallet_Details.web3auth.addChain({
        chainId: "0x89",
        displayName: "Polygon Mainnet",
        chainNamespace: CHAIN_NAMESPACES.EIP155,
        tickerName: "Matic",
        ticker: "MATIC",
        decimals: 18,
        rpcTarget: "https://polygon-rpc.com",
        blockExplorer: "https://polygonscan.com",
      });
      console.log("addingChain Web3Auth", addingChain);
      var switched = await Wallet_Details.web3auth.switchChain({ chainId: "0x89" });
      console.log("switched in create page uh", switched);
    }
    // }
    var weboe = {
      web3: Wallet_Details.Web3Pro,
      web3auth: Wallet_Details.web3auth
    }
    return weboe
  } catch (e) {
    console.log("asdasqweqweqwe", e);
  }
}

export const TorUs = async (type, configchainid) => {
  try {
    console.log("type,configchainid", type, configchainid);
    const torus = new Torus();
    
    // Determine network configuration based on configchainid
    let network = {}
    if(String(configchainid) == Config.MATIC_ID){
      network = {
        host: Config.rpcTargetPolygon,
        networkName: Config.Network_MAT,
        chainId: Number(Config.MATIC_ID),
        blockExplorer: Config.ScannerPolygon,
        ticker: Config.Currency_MATIC,
        tickerName: Config.Currency_MATIC,
      }
    } else if (String(configchainid) == Config.ETH_ID){
      network = {
        host: Config.rpcTargetEthereum,
        networkName: Config.Network_ETH,
        chainId: Number(Config.ETH_ID),
        blockExplorer: Config.ScannerBinance,
        ticker: Config.Currency_ETH,
        tickerName: Config.Currency_ETH,
      }
    } else {
      network = {
        host: Config.rpcTargetCamino,
        networkName: Config.Network_CAM,
        chainId: Number(Config.CAM_ID),
        blockExplorer: Config.ScannerCamino,
        ticker: Config.Currency_CAM,
        tickerName: Config.Currency_CAM,
      }
    }
    
    console.log("networknetwork",network);
    
    // Initialize Torus with specified network configuration
    await torus.init({
      enableLogging: true,
      network: network,
      showTorusButton: true,
      logoDark: Darklogo,
      logoLight: Darklogo,
      topupHide: false,
      featuredBillboardHide: true,
      disclaimerHide: true,
    });
    
    // Login to Torus
    await torus.login();
    
    // If Torus is initialized successfully
    if (torus) {
      console.log("torus", torus);
      // Create a new Web3 instance using Torus provider
      var web3 = new Web3(torus.provider);
      console.log("torus for Torus", web3);
      
      // Get accounts and chain ID using Web3
      const accounts = await web3.eth.getAccounts();
      const ChainID = await web3.eth.getChainId();
      console.log("accountssss torus", accounts, ChainID);
      
      // Store provider and account information in localStorage
      const account = accounts[0].toString().toLowerCase();
      localStorage.setItem("Provider", web3);
      localStorage.setItem("accountInfo", account);
      localStorage.setItem('walletConnectType', type);
      
      // Return web3 and torus instance
      var weboe = {
        web3: web3,
        web3auth: torus
      }
      return weboe;
    }
  } catch (e) {
    console.log("Error in TorUs function", e);
    return false;
  }
}


export const TorUsSwitch = async (type, configchainid, Wallet_Details) => {
  try {
    console.log("adasdsdasdsadsad", type, typeof configchainid, Wallet_Details);
    if (String(configchainid) == Config.MATIC_ID) {
      var dataa = await Wallet_Details.web3auth.setProvider({
        // host: "https://polygon-mainnet.infura.io/v3/a6738b5a17514327ae8c9afc47b6179c", // mandatory
        // chainId: 137, // optional
        // networkName: "Polygon Mainnet", // optional
        // host: "https://polygon-mumbai.infura.io/v3/a6738b5a17514327ae8c9afc47b6179c", // mandatory
        // chainId: 80001, // optional
        // networkName: "Mumbai", // optional
        // host: "https://rpc-amoy.polygon.technology/", // mandatory
        // chainId: 80002, // optional
        // networkName: "Amoy", // optional
        host: Config.rpcTargetPolygon, // mandatory
        chainId: Number(Config.MATIC_ID), // optional
        networkName: Config.Network_MAT, // optional
      });
      console.log("Asdasdasdwq", dataa);
      var chainIddd = await Wallet_Details.Web3Pro.eth.getChainId()
      console.log("acnjkcsacas", chainIddd);
      var weboe = {
        web3: Wallet_Details.Web3Pro,
        web3auth: Wallet_Details.web3auth
      }
      return weboe
    } else if(String(configchainid) == Config.ETH_ID){
      var dataa = await Wallet_Details.web3auth.setProvider({
        // host: "https://mainnet.infura.io/v3/a6738b5a17514327ae8c9afc47b6179c", // mandatory
        // chainId: 1, // optional
        // networkName: "Ethereum Mainnet", // optional
        // host: "https://data-seed-prebsc-1-s1.binance.org:8545/", // mandatory
        // chainId: 97, // optional
        // networkName: "Binance testnet", // optional
        // host: "https://api.avax.network/ext/bc/C/rpc", // mandatory
        // chainId: 43114, // optional
        // networkName: "Avalanche C-Chain", // optional
        host: Config.rpcTargetEthereum, // mandatory
        chainId: Number(Config.ETH_ID), // optional
        networkName: Config.Network_ETH, // optional
      });
      console.log("Asdasdasdwq", dataa);
      var chainIddd = await Wallet_Details.Web3Pro.eth.getChainId()
      console.log("acnjkcsacas", chainIddd);
      var weboe = {
        web3: Wallet_Details.Web3Pro,
        web3auth: Wallet_Details.web3auth
      }
      return weboe
    }else {
      var dataa = await Wallet_Details.web3auth.setProvider({
        // host: "https://mainnet.infura.io/v3/a6738b5a17514327ae8c9afc47b6179c", // mandatory
        // chainId: 1, // optional
        // networkName: "Ethereum Mainnet", // optional
        // host: "https://data-seed-prebsc-1-s1.binance.org:8545/", // mandatory
        // chainId: 97, // optional
        // networkName: "Binance testnet", // optional
        // host: "https://api.avax.network/ext/bc/C/rpc", // mandatory
        // chainId: 43114, // optional
        // networkName: "Avalanche C-Chain", // optional
        host: Config.rpcTargetCamino, // mandatory
        chainId: Number(Config.CAM_ID), // optional
        networkName: Config.Network_CAM, // optional
      });
      console.log("Asdasdasdwq", dataa);
      var chainIddd = await Wallet_Details.Web3Pro.eth.getChainId()
      console.log("acnjkcsacas", chainIddd);
      var weboe = {
        web3: Wallet_Details.Web3Pro,
        web3auth: Wallet_Details.web3auth
      }
      return weboe
    }
  } catch (e) {
    console.log("dqweqwsdededewd", e);
    return false;
  }
}



export const LMWalletConnect = async (type, networkConfig, register) => {
  try {
    console.log("type, networkConfig, register", type, networkConfig, register);
    var web3 = new Web3(networkConfig.RPC_URL);

    var resp = await LMWalletCreate({ email: localStorage.loggedinaddress, from: register })
    console.log("resp---------->", resp);
    if (resp.success) {
      console.log("response of LMWalletConnect", register, resp);

      var account = resp.data.RecentActiveAddress.toLowerCase();

      var weboe = {
        web3: web3,
        web3auth: null,
        LMWallet: resp.data ?? {},
        accountAddress: account
      }

      localStorage.setItem("accountInfo", account)
      localStorage.setItem('walletConnectType', type)

      return weboe

    }
  } catch (error) {
    console.log("erreo on LMWalletConnect", error);
  }
}

export const encodedata = (data) => {
  console.log("ahsdhasidasdasd", Configsfrom.encodekey);
  let encrpteddata = AES.encrypt(JSON.stringify(data), Configsfrom.encodekey).toString()
  return encrpteddata;

}

export const decodedata = (data) => {
  let dataaaa = AES.decrypt(data, Configsfrom.encodekey);
  console.log("JSON.parse(dataaaa.toString(enc.Utf8))", dataaaa, JSON.parse(dataaaa.toString(enc.Utf8)));
  return JSON.parse(dataaaa.toString(enc.Utf8))

};