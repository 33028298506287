import React, { useEffect, useState, useRef } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from "@material-ui/core";
import Bannerimga from "../assets/images/neko.png"
import Profile from "../assets/images/info_img.png"
import Banner from "../assets/images/lazymintingnew/myitemsprofile.jpg"
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
//Nimal
import { GetProfiledata, EditCoverPhoto } from "actions/controller/userControl";
import { ImageValidation } from "actions/controller/validation";
import { useSelector } from "react-redux";
import isEmpty from "lib/isEmpty";
import config from "lib/config";
import $ from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { toast, Flip } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import ExploreCard from "./separate/explore_card";
import Card from '../views/separate/Card.js';
import ActivityCard from "./separate/ActivityCard";
import Ticketcard from "../views/separate/ticketcard.js"
import { EventListNft } from "actions/controller/tokenControl";
import { MyUserData } from "actions/controller/userControl";
import { FollowAction } from "actions/v1/user";
import { MyItemCollection, MyItemSoulBound, MyItemSoulBoundOwn } from "actions/v1/token";
import Collectioncard from "./separate/collection_card";
import { useHistory, useParams } from "react-router-dom";
import { Audio, TailSpin } from "react-loader-spinner";
import Modal from 'react-modal';
import { TramRounded } from "@material-ui/icons";
import { Share } from "./separate/ShareNow"
import ReactReadMoreReadLess from "react-read-more-read-less";
import LazyLoad from 'react-lazyload';
import LazyLoader from "./lazyloader";
toast.configure();
//

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return null;
}

export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

export default function ChooseNFT(props) {
    const ShareNowRef = useRef();
    const classes = useStyles();
    const { ...rest } = props;
    const Wallet_Details = useSelector((state) => state.wallet_connect_context);
    // console.log("zbcjkabckjnakcas",Wallet_Details);
    const history = useHistory();
    const { useradd, userurl } = useParams();
    const InitialForm = {
        Name: "",
        CustomUrl: "",
        Joined: "",
        Bio: "",
        Twitter: "",
        Facebook: "",
        Instagram: "",
        Youtube: "",
        Count: {},
        curraddress: Wallet_Details.UserAccountAddr,
    };
    const [Formdata, setFormdata] = useState(InitialForm);
    const [MyItemNFTs, setMyItemNFTs] = useState({ onsale: { page: 1, list: [], onmore: true } });
    const [MyUserFollow, setMyUserFollow] = useState({
        followers: { page: 1, list: [], onmore: true },
        activity: { page: 1, list: [], onmore: true },
    });
    const [TabName, setTabName] = useState();
    const [Loader, setLoader] = useState(false);
    const [ShowModal, setShowModal] = useState(false)
    const [theme, setTheme] = useState("#000");

    useEffect(() => {

        if (document.getElementById("root").classList.contains("light_theme")) {
            setTheme("#FFF")
        }
        else {
            setTheme("#000")


        }
    }, []);

    const customStyles = {
        content: {
            position: 'fixed',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            // boxShadow: '0 27px 24px 0 rgb(0 0 0 / 20%), 0 40px 77px 0 rgb(0 0 0 / 22%)',
            borderRadius: '30px',
            border: 'none !important',
        },
        overlay: {
            backgroundColor: theme,
            zIndex: 2
        },
    };


    //Function
    const MyItemTab = async (tab, filter, Pageno) => {
        console.log("tab name", tab, tab && !MyItemNFTs[tab], MyItemNFTs[tab], MyItemNFTs, !MyItemNFTs[tab])

        if (tab && !MyItemNFTs[tab]) {
            console.log("asjdfmaslkd");
            setLoader(true);
            MyItemNFTs[tab] = { page: 1, list: [], onmore: true };
            setMyItemNFTs(MyItemNFTs);
        }
        var curraddress = Wallet_Details.UserAccountAddr;
        var Payload = {
            curraddress: curraddress,
            Tab: tab ? tab : "Creator",
            Action: "Click",
            Limit: 16,
            Page: Pageno || 1,
            filter: filter,
        };
        console.log("Event Playload NFT", Payload)
        if (tab === "activity" || tab === "following" || tab === "followers") {


        }
        else {
            console.log("payload to created tab", Payload)
            var myNFT = await EventListNft(Payload);
            console.log("create tab data", myNFT)
            if (myNFT.Success) {
                if (Pageno && Pageno !== MyItemNFTs[myNFT.Tab].page) {
                    MyItemNFTs[myNFT.Tab].page = Pageno
                    MyItemNFTs[myNFT.Tab].list = MyItemNFTs[myNFT.Tab].list.concat(myNFT.records);
                    MyItemNFTs[myNFT.Tab].onmore = (myNFT.records.length > 0)
                    // console.log("wdfwfewfewfwe", MyItemNFTs);
                    setMyItemNFTs([]);
                    setMyItemNFTs(MyItemNFTs);
                }
                else {
                    if (MyItemNFTs[myNFT.Tab].page === 1) {
                        MyItemNFTs[myNFT.Tab].list = myNFT.records;
                        // console.log("qweeretrertrtr", MyItemNFTs);
                        setMyItemNFTs([]);
                        setMyItemNFTs(MyItemNFTs);
                        setTimeout(() => {
                            setShowModal(false)
                        }, 1000);
                    }
                }

                // console.log("efwejfweifwi", Payload.Tab);
                setTabName(Payload.Tab);
            } else {
                setTabName(Payload.Tab);
            }
        }
        setTimeout(() => {
            setLoader(false);
        }, 1000);
    };

    //Component
    const MyItemCard = () => {
        return (
            <>
                {Loader ? (
                    <>
                        <div className="text-center py-5">
                            <TailSpin
                                wrapperClass="searreactloader"
                                color="#00BFFF"
                                height={100}
                                width={70}
                            />
                        </div>
                    </>
                ) : (

                    <>
                        {/* {console.log("wfrwfwrfrfrefrwe", TabName)} */}

                        {MyItemNFTs &&
                            MyItemNFTs[TabName] &&
                            MyItemNFTs[TabName].list.length > 0 ? (
                            <div className="row masonry m-0 ma_no_gap card_main_iner">
                                {MyItemNFTs[TabName].list.map((NFTDetails) => {
                                    return (
                                        // <ExploreCard
                                        //     Cardclass={
                                        //         "item col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3"
                                        //     }
                                        //     NFTDetails={NFT}
                                        // />
                                        <div className="item col-xl-3 col-12 col-sm-6 col-md-6 col-lg-3 mb-2 card_whole">
                                            <div className="card_inner_item">
                                                <div className="trendingban">

                                                    <LazyLoad height={200} placeholder={<LazyLoader />} offset={[-200, 0]} debounce={500}>
                                                        {NFTDetails.image != "" &&
                                                            (NFTDetails.image.split(".").pop() == "mp4" ? (
                                                                <video
                                                                    id="my-video"
                                                                    class="img-fluid"
                                                                    autoPlay
                                                                    playsInline
                                                                    loop
                                                                    muted
                                                                    preload="auto"
                                                                    controls disablePictureInPicture controlsList="nodownload"
                                                                >
                                                                    <source src={`${config.Back_URL}/nftImg/${NFTDetails.tokenCreator}/${NFTDetails.image}`} type="video/mp4" />
                                                                </video>
                                                            ) : (
                                                                <img src={`${config.Back_URL}/nftImg/${NFTDetails.tokenCreator}/${NFTDetails.image}`} alt="Collections" className="img-fluid" />
                                                            ))}
                                                    </LazyLoad>

                                                </div>
                                                <div className="img_desc_nft pt-2 cardItem card_price">
                                                    <Link to={"/"}>
                                                        <img
                                                            src={`${config.Back_URL}/Users/${NFTDetails.tokenOwnerInfo.PrimaryAddr}/profileImg/${NFTDetails.tokenOwnerInfo.image}`}
                                                            alt="User"
                                                            className="img-fluid profiel_img_pos"
                                                            title={`Owner : ${NFTDetails.tokenOwnerInfo.name
                                                                ? NFTDetails.tokenOwnerInfo.name
                                                                : NFTDetails.tokenOwnerInfo.curraddress
                                                                }`}
                                                        />
                                                        {/* <img
                                                            src={require("../../assets/images/star.png")}
                                                            alt="Collections"
                                                            className="img-fluid star_img_blue"
                                                        /> */}
                                                    </Link>
                                                    <div className="d-block mt-2 card_details">
                                                        <h2 className="tokename_title">{NFTDetails.tokenName}</h2>

                                                    </div>
                                                </div>
                                                <div className="usercontroliconstock">
                                                    <div>
                                                        {NFTDetails.auctionend &&
                                                            new Date(NFTDetails.auctionstart).getTime() <= Date.now() &&
                                                            new Date(NFTDetails.auctionend).getTime() > Date.now() ?
                                                            <div className="timeleft">
                                                                {/* {" "} */}
                                                                <i class="far fa-clock mr-1"></i>
                                                                <Countdown
                                                                    date={new Date(NFTDetails.auctionend).getTime()}
                                                                    renderer={renderer}
                                                                ></Countdown>
                                                            </div>
                                                            :
                                                            <h2 className="like_count">
                                                                {NFTDetails.tokenowners_current.balance} in Stock
                                                            </h2>

                                                        }
                                                    </div>

                                                    <div className="price">

                                                        <div className="price mr-auto">
                                                            <p>{(NFTDetails.tokenOwnerInfo.curraddress === Wallet_Details.UserAccountAddr) ?
                                                                (NFTDetails.tokenowners_current.balance === 0) ? "You Created This" : "You Own This"
                                                                :
                                                                new Date(NFTDetails.auctionend).getTime() > Date.now() ?
                                                                    `${NFTDetails.minimumBid} ${Wallet_Details.networkConfiguration.tokenSymbol}`
                                                                    : ""

                                                            }</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                {/* <Card /> */}
                            </div>
                        ) : (
                            <BrowseMarketPlace />
                        )}
                    </>

                )}

            </>
        );
    };

    const Ticketss = () => {
        return (
            <>
                {Loader ? (
                    <>
                        <div className="text-center py-5">
                            <TailSpin
                                wrapperClass="searreactloader"
                                color="#00BFFF"
                                height={100}
                                width={70}
                            />
                        </div>
                    </>
                ) : (

                    <>
                        {/* {console.log("wfrwfwrfrfrefrwe", TabName, MyItemNFTs)} */}

                        {MyItemNFTs &&
                            MyItemNFTs[TabName] &&
                            (TabName == "Ticket") &&
                            MyItemNFTs[TabName].list.length > 0 ? (
                            <div className="row tickets">
                                {MyItemNFTs["Ticket"].list.map((NFT) => {
                                    console.log("fhiewhfijwehfijrwe", NFT);
                                    return (
                                        <Ticketcard
                                            item={NFT}
                                        //  className="col-xl-3 col-lg-4 col-md-6 col-sm-12"
                                        />
                                        // <p>lsdfklsdflksdkl</p>
                                    );
                                })}
                                {/* <Card /> */}
                            </div>
                        ) : (
                            <BrowseMarketPlace />
                        )}
                    </>

                )}

            </>
        );
    };


    //Component
    const BrowseMarketPlace = () => {
        return (
            <div className="text-center py-5">
                <p className="not_found_text">No items found</p>
                <p className="not_found_text_sub">
                    Come back soon! Or try to browse something for you on our marketplace
                </p>
                <div className="mt-3">
                    <Link to="/explore/All">
                        <Button className="browsematdk">Browse Marketplace</Button>
                    </Link>
                </div>
            </div>
        );
    };

    return (
        <div className="home_header">
            <Header
                color="transparent"
                routes={dashboardRoutes}
                brand={
                    <Link to="/home">
                        <div className="header_logo_align">


                        </div>
                    </Link>
                }
                leftLinks={<HeaderLinks />}
                changeColorOnScroll={{
                    height: 20,
                    color: "white",
                }}
                {...rest}
            />
            <ScrollToTopOnMount />
            <div className={classes.pageHeader + " inner_pageheader items_header my_items"}>

                <div className="inner_pagh_bg_1">

                    <div className="container container_full">
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <nav className="masonry_tab_nav mt-4 mb-3 items_tab_outer">
                                    <div
                                        className="nav nav-tabs masonry_tab primary_tab items_tab justify-content-center items_tab_new pb-0 pl-0"
                                        id="nav-tab"
                                        role="tablist"
                                    >
                                        <a
                                            onClick={() => {
                                                MyItemTab("created");
                                            }}
                                            className="nav-link"
                                            id="created-tab"
                                            data-toggle="tab"
                                            href="#created"
                                            role="tab"
                                            aria-controls="created"
                                            aria-selected="false"
                                        >
                                            <div className="tab_head">Created</div>
                                        </a>
                                        <a
                                            onClick={() => {
                                                MyItemTab("Ticket");
                                            }}
                                            className="nav-link"
                                            id="Ticket-tab"
                                            data-toggle="tab"
                                            href="#Ticket"
                                            role="tab"
                                            aria-controls="Ticket"
                                            aria-selected="false"
                                        >
                                            <div className="tab_head">Ticket</div>
                                        </a>
                                    </div>
                                </nav>
                                <div
                                    className="tab-content explore_tab_content  myitemsdf"
                                    id="nav-tabContent"
                                >
                                    <div
                                        className="tab-pane fade show active"
                                        id={TabName}
                                        role="tabpanel"
                                        aria-labelledby={`${TabName}-tab`}
                                    >
                                        <div className="proposal_panel_overall">
                                            {
                                                TabName === "created" ? (
                                                    <>
                                                        <MyItemCard />
                                                        <div className="row">
                                                            {/* <Card /> */}
                                                        </div>
                                                        {MyItemNFTs[TabName] && MyItemNFTs[TabName].onmore &&
                                                            <div className="col-12 pb-5 text-center pt-4">
                                                                <button className="create_btn"
                                                                    onClick={() => MyItemTab(TabName, Formdata.curraddress, null, MyItemNFTs[TabName].page + 1)}>
                                                                    Load more items
                                                                </button>
                                                            </div>
                                                        }
                                                    </>
                                                ) :
                                                    <>
                                                        <Ticketss />
                                                        <div className="row">
                                                            {/* <Card /> */}
                                                        </div>
                                                    </>}
                                        </div>
                                    </div>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>
            <Footer />

            <Modal
                isOpen={ShowModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                {theme === "#000" ?
                    <div className="modal-body text-center loading_bgcolor">
                        <div className="laodingpart">
                            <img src={require("../assets/images/lazymintingnew/logo.png")} alt="Profile" className="img-fluid items_profile smal_main_lm" />
                            {/* {
             theme == "#FFF" ? 
             <p className="text-dark">Loading ....</p>
             :
             <p className="text-white">Loading ....</p>
           } */}

                        </div>
                    </div> :

                    <div className="modal-body text-center loading_bgcolor">
                        <div className="laodingpart">
                            <img src={require("../assets/images/lazymintingnew/logodark.png")} alt="Profile" className="img-fluid items_profile smal_main_lm" />
                            {/* {
    theme == "#FFF" ? 
    <p className="text-dark">Loading ....</p>
    :
    <p className="text-white">Loading ....</p>
  } */}

                        </div>
                    </div>

                }

            </Modal>
        </div>
    );
}
