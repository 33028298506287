import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import HeaderLinks from "components/Header/HeaderLinks.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import Footer from "components/Footer/Footer.js";
import { Link } from "react-router-dom";
import prof from "../assets/images/prof.png";
import { styled } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import {
    DataGrid,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import banimg from "../assets/images/my_items_baner.png";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import config from 'lib/config'

const dashboardRoutes = [];
const useStyles = makeStyles(styles);
// Scroll to Top
function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return null;
}

function Votedetails(props) {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory()
    const { ...rest } = props;
    const state = location.state;
    console.log("statestate", state, location);
    const [filemap, setFilemap] = useState([
        { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }
    ]);
    const [FaitRv, SetFiatRv] = useState(state?.VoterList)
    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
        border: 0,
        color:
            theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        WebkitFontSmoothing: 'auto',
        letterSpacing: 'normal',
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
        },
        '& .MuiDataGrid-iconSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            borderRight: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
                }`,
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
                }`,
        },
        '& .MuiDataGrid-cell': {
            color:
                theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
        },
        '& .MuiPaginationItem-root': {
            borderRadius: 0,
        },
        '& .MuiDataGrid-footerContainer': {
            display: "flex",
            justifyContent: "center"
        },

        ...customCheckbox(theme),
    }));

    const rows = FaitRv?.length > 0 && FaitRv?.map((item, index) => {
        console.log("dfasdadasdas", item);
        return ({ id: index + 1, WalletAddress: item?.WalletAddress })
    })

    const columns = [
        { field: 'id', headerName: 'ID', width: 10 },
        {
            field: 'WalletAddress',
            headerName: 'Wallet Address',
            width: 250,
            editable: false,
            sortable: false,
        },

    ];

    function CustomPagination() {
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        return (

            <Pagination
                color="primary"
                shape="rounded"
                page={page + 1}
                count={pageCount}
                renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
                onChange={(event, value) => apiRef.current.setPage(value - 1)}
            />
        );
    }

    function customCheckbox(theme) {
        return {
            '& .MuiCheckbox-root svg': {
                width: 16,
                height: 16,
                backgroundColor: 'transparent',
                border: `1px solid ${theme.palette.mode === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'
                    }`,
                borderRadius: 2,
            },
            '& .MuiCheckbox-root svg path': {
                display: 'none',
            },
            '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
                backgroundColor: '#e50914',
                borderColor: '#e50914',
            },
            '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
                position: 'absolute',
                display: 'table',
                border: '2px solid #fff',
                borderTop: 0,
                borderLeft: 0,
                transform: 'rotate(45deg) translate(-50%,-50%)',
                opacity: 1,
                transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
                content: '""',
                top: '50%',
                left: '39%',
                width: 5.71428571,
                height: 9.14285714,
            },
            '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
                width: 8,
                height: 8,
                backgroundColor: '#62626214',
                transform: 'none',
                top: '39%',
                border: 0,
            },

        };
    }
    const [percentage, setpercentage] = useState(66);
    const [perImg, setperImg] = useState(null);

    const ChangeStatuss = async (data) => {
        window.$('#documentimg_modal').modal('show');
        setperImg(data)
    }

    useEffect(() => {
        if (state) {
            let Percentagecal = ((state?.VoteCount || 0) / (location?.location || 0))
            let Multiply = Percentagecal * 100
            console.log("PercentagecalPercentagecal", Multiply);
            setpercentage(Math.round(Multiply))
        }
    }, [state])

    useEffect(() => {
        if (!state) {
            setTimeout(() => {
                history.goBack();
            }, 1000);
        }
    }, [state])


    return (

        <>
            <div className="home_header">
                <Header
                    color="transparent"
                    routes={dashboardRoutes}
                    brand={<Link to="/home">
                        <div className="header_logo_align">


                        </div>
                    </Link>}
                    leftLinks={<HeaderLinks />}
                    changeColorOnScroll={{
                        height: 20,
                        color: "white"
                    }}
                    {...rest}
                />
                <ScrollToTopOnMount />
                {/* <div className={classes.pageHeader + " inner_pageheader inner_pagh_bg_1 pb-5"}> */}
                <div className="container">
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} className="privacy_policy mt-4">
                        <button className="btn create_btn mt-4 mb-5"
                                onClick={() => history.goBack()}
                            >
                                Back
                            </button>
                            <div className="banner_image">
                                <img src={state?.BannerImage ? `${config.Back_URL + "/FractionalVote/" + state?.tokenOwner + "/BannerImage/" + state?.BannerImage}` : banimg} className="img-fluid banimg" />
                                {/* <h4 className="wtclr">Lorem Ipsum</h4> */}
                            </div>
                            <h5 className="text-center mt-4 mb-5 whtclrss" style={{ fontWeight: "700" }}>Lorem Ipsum</h5>
                            <div className="mt-4">
                                <h5 className="mb-3 whtclrss" style={{ fontWeight: "700" }}>Description : </h5>
                                {state?.VoteMsg ?
                                    <p className="whtclrss">{state?.Description}</p>
                                    :
                                    <p className="whtclrss">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>}
                            </div>
                            {state?.Multiplefiles?.length > 0 &&
                                <div className="mt-4 mb-3">
                                    <h5 className="mb-3 whtclrss" style={{ fontWeight: "700" }}>Images : </h5>
                                    <div className="row">
                                        {state?.Multiplefiles?.length > 0 && state?.Multiplefiles?.map((e, i) =>
                                            <div className="col-md-6 col-lg-4 mt-4">
                                                <img src={`${config.Back_URL + "/FractionalVote/" + state.tokenOwner + "/Multiplefiles/" + e}`} className="img-fluid profimg voteprofimg" onClick={() => { ChangeStatuss(`${config?.Back_URL + "/FractionalVote/" + state?.tokenOwner + "/Multiplefiles/" + e}`) }} />
                                                {/* <div className="card card_height">
                                                <h5>Document Name</h5>
                                                <a href="https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf" download="MyExampleDoc" target='_blank'><button className="btn create_btn">View document</button></a>
                                            </div> */}
                                            </div>)}
                                    </div>
                                </div>
                            }
                            <div className="">
                                <h5 className="mb-3 whtclrss" style={{ fontWeight: "700" }}>Vote List : </h5>
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <div style={{ height: 300, width: '100%' }}>
                                            <StyledDataGrid
                                                // checkboxSelection
                                                pageSize={5}
                                                rowsPerPageOptions={[5]}
                                                rows={rows}
                                                columns={columns}
                                                disableColumnMenu
                                                components={{
                                                    Pagination: CustomPagination,
                                                }}
                                                className='campaign_muitbl'

                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div style={{ width: 200, height: 200, margin: "auto" }}>
                                            <CircularProgressbar value={percentage} text={`${percentage}%`} styles={buildStyles({
                                                pathColor: `green, ${percentage / 100})`,
                                                textColor: 'green',
                                                trailColor: '#d6d6d6', backgroundColor: 'green'
                                            })} />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
                {/* </div> */}

                <Footer />
            </div>
            <div
                className="modal fade primary_modal"
                id="documentimg_modal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="documentimg_modalCenteredLabel"
                aria-hidden="true"
                data-backdrop="static"
                data-keyboard="false"
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-sm"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id="documentimg_modalLabel">

                            </h5>
                            <button
                                id="buy_closs"
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body px-0">
                            <div className="mb-3">
                                <h5 className="text-center mb-3">

                                </h5>
                                <img src={perImg} className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Votedetails