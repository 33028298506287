import React, { useEffect, useState } from "react";
import config from "lib/config";

export default function ActivityCard(props) {
  const { Activity } = props

  console.log("innnfiknsfknecardd",Activity,props.Activity)
  if(Activity.From.image !=="")
    var Image = `${config.Back_URL}/Users/${Activity.From.PrimaryAddr}/profileImg/${Activity.From.image}`;
  else
    var Image = `${require("../../assets/images/user_img_1.png")}`;

  const timediff = (a) =>{
    var date1 = new Date(Date.now());
    var date2 = new Date(a);
    var Difference_In_Time =   date2.getTime() - date1.getTime() ;
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    Difference_In_Days = (Difference_In_Days < 0)?Difference_In_Days*(-1) : Difference_In_Days
    return (Difference_In_Days).toFixed(1)
  }

  return (
    <div className="card mt-0 mb-3 acti_card rounded-corners-gradient-borders">
    <div className="card-body px-3">
      <div className="media follow_media icon_img">
        <div className="icon-img-div">
          <div className="img_media_new  mr-3">
            <img
              src={Image}
              alt="User"
              className="img-fluid"
            />
          </div>
        </div>
        <div className="media-body flex_body">
          <div>
            <p className="mb-2 media_text">
              {Activity.action}
            </p>
            <p className="media_text mb-0">
              {Activity.frommsg }
              <span className="media_text">
                @{(Activity.From.name)?Activity.From.name : (Activity.from).slice(0,20)}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="ml-2">
        <p className="mt-0 media_num mb-0 dur_text">
          {((timediff(Activity.created)*10) === 0)? `Just Now` : (timediff(Activity.created) < 2) ? `${(timediff(Activity.created)*10)} Hours Ago` : `${(timediff(Activity.created))} Days Ago`}
        </p>
      </div>
    </div>
  </div>
  );
}
