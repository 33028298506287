import React, { useState, useEffect } from "react";

import $ from 'jquery';
// core components
// import InfiniteScroll from "react-infinite-scroll-component";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
// import Headbannerimg from "../assets/images/lazymintingnew/banner.png";
import loginlogo from "../assets/images/lazymintingnew/loginlogo.png";
// import Tokencard from '../components/seperate/tokencard.js'
// import Connectwallet from "../components/connectwallet/wallet.js"

// import { MatEditor } from "maticzeditor"
import OwlCarousel from 'react-owl-carousel';
import { useSelector, useDispatch } from "react-redux";
import { toast, Flip } from "react-toastify";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Button } from "@material-ui/core";
// import Profile from "../assets/images/img_03.png"
import {
  LoginSocialGoogle,
  LoginSocialFacebook,
  LoginSocialTwitter,
  IResolveParams


} from 'reactjs-social-login';
import Cookies from 'universal-cookie';
import { v1_Featured } from "actions/v1/token";
import {
  FacebookLoginButton,
  GoogleLoginButton,
  GithubLoginButton,
  AmazonLoginButton,
  InstagramLoginButton,
  LinkedInLoginButton,
  MicrosoftLoginButton,
  TwitterLoginButton,
  AppleLoginButton,
} from 'react-social-login-buttons'

// import Qrcode from "../assets/images/lazymintingnew/qrcode.png"
import Qr from "../assets/images/lazymintingnew/qr.png"
import HeaderLinks from "components/Header/HeaderLinks.js";
import user from "../assets/images/lazymintingnew/user.png"
import config from "lib/config";
import Ticketcard from "../views/separate/ticketcard.js"
import {
  HomeCategory, LikeAction, ExplorePage
} from '../actions/v1/token';
import ReactHtmlParser from 'react-html-parser';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components

import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import isEmpty from "lib/isEmpty";
// import { loginuser } from "../actions/v1/user.js"
import { signinuser, loginuser, signupfacebook, getGoogleAccountDetails, signinAuth, forgotPassswordfn } from "../actions/v1/user.js"
import Modal from 'react-modal';
import { AddressUserDetails_GetOrSave_Action } from 'actions/v1/user';
import { Account_Connect, Account_disConnect } from "actions/redux/action";
import Wrong from "../assets/images/wrong.png"
import Metamaskconnect from "../assets/images/metamaskconnect.png"
import venly from "../assets/images/venly.png"
import { connectWallet } from '../hooks/useWallet';

export default function Home(props) {


  var loader_theme = localStorage.getItem("loadertheme");



  $(window).scroll(function () {
    if ($(this).scrollTop()) {
      $('header').addClass('fixedTop');
    } else {
      $('header').removeClass('fixedTop');
    }
  });

  const options = {
    dots: false,
    infinite: true,
    // autoplay:true,
    smartSpeed: 400,
    margin: 16,
    nav: true,
    speed: 1500,
    items: 6,
    responsive: {
      0: {
        items: 1
      },
      480: {
        items: 1
      },
      576: {
        items: 2
      },
      768: {
        items: 3
      },
      1000: {
        items: 4
      },
      1250: {
        items: 7
      },
      1500: {
        items: 6
      },
      1920: {
        items: 7
      }
    },
  }


  const options1 = {
    dots: false,
    infinite: true,
    // autoplay:true,
    smartSpeed: 400,
    margin: 16,
    nav: true,
    speed: 1500,
    items: 6,
    responsive: {
      0: {
        items: 1
      },
      480: {
        items: 1
      },
      576: {
        items: 2
      },
      768: {
        items: 3
      },
      1000: {
        items: 4
      },
      1250: {
        items: 5
      },
      1500: {
        items: 5
      },
      1920: {
        items: 6
      }
    },
  }


  const history = useHistory();
  const dispatch = useDispatch();
   const cookies = new Cookies();
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const [visibleset, setVisibleset] = React.useState("");
  // const [theme, setTheme] = useState(false);
  const [HomeCategoryData, SetHomeCategory] = React.useState({})
  const [Templike, setTemplike] = useState(false);
  const [TempCount, setTempCount] = useState(0);
  const [ExploreCardNFTs, setExploreCardNFTs] = useState({ 'loader': false, 'Ticket': { page: 1, list: [], onmore: true, Vault: "Show", VaultStatus: "Explore" } });
  const [CatName, setCatName] = useState("Ticket")
  const [ShowLoader, setShowLoader] = useState(false)
  const [CatPage, setCatPage] = useState({ "All": { page: 1 } })
  const [ShowModal, setShowModal] = useState(false)
  // const [googlelogin, setgooglelogin] = useState(false);
  // const [fblogin, setfblogin] = useState(false);
  const [HomeCms, setHomeCms] = useState({})
  const [MidsecHome, SetMidSecHome] = useState({})
  const [HomeMid, setHomeMid] = useState({})
  const [theme, setTheme] = useState("#000");

  useEffect(() => {

    if (document.getElementById("root").classList.contains("light_theme")) {
      setTheme("#FFF")
    }
    else {
      setTheme("#000")


    }
  }, []);
  const customStyles = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      // boxShadow: '0 27px 24px 0 rgb(0 0 0 / 20%), 0 40px 77px 0 rgb(0 0 0 / 22%)',
      borderRadius: '30px',
      border: 'none !important',
    },
    // overlay: {
    //   backgroundColor: 'rgba(255,255,255,255)',
    //   zIndex: 2
    // },
    overlay: {
      backgroundColor: theme,
      zIndex: 2
    },
  };

  useEffect(() => {
    setShowModal(true)

  }, []);
  const setTransition = (val, ele) => {
    $("#visible1 .owl-item").each(function () {
      $(this).removeClass("more_index");
      // $(this).find(".carddnone").removeClass("more_index");
    });
    $("#visible1").removeClass("hover_big");

    $("#" + val).addClass("hover_big");

    $(ele.target).parent().parent().parent().addClass("more_index");

    $(ele.target).parents('.owl-item').addClass("more_index");

  }

  const setTransition1 = (val, ele) => {
    $("#visible2 .owl-item").each(function () {
      $(this).removeClass("more_index");
    });
    $("#visible2").removeClass("hover_big");

    $("#" + val).addClass("hover_big");
    console.log("ele", $(ele.target).parent().parent());
    $(ele.target).parent().parent().parent().addClass("more_index");
    $(ele.target).parents('.owl-item').addClass("more_index");

  }
  const setTransition2 = (val, ele) => {
    $("#" + val + " .owl-item").each(function () {
      $(this).removeClass("more_index");
      // $(this).find(".carddnone").removeClass("more_index");
    });
    $("#" + val).removeClass("hover_big");

    $("#" + val).addClass("hover_big");


    $(ele.target).parents('.owl-item').addClass("more_index");

  }

  const setTransition3 = (val, ele) => {
    $("#visible4 .owl-item").each(function () {
      $(this).removeClass("more_index");
      // $(this).find(".carddnone").removeClass("more_index");
    });
    $("#visible4").removeClass("hover_big");

    $("#" + val).addClass("hover_big");
    console.log("ele", $(ele.target).parent().parent());
    $(ele.target).parent().parent().parent().addClass("more_index");
    $(ele.target).parents('.owl-item').addClass("more_index");

  }

  const setTransition4 = (val, ele) => {
    $("#visible5 .owl-item").each(function () {
      $(this).removeClass("more_index");
    });
    $("#visible5").removeClass("hover_big");

    $("#" + val).addClass("hover_big");
    console.log("ele", $(ele.target).parent().parent());

    $(ele.target).parent().parent().parent().addClass("more_index");
    $(ele.target).parents('.owl-item').addClass("more_index");

  }

  const setTransition5 = (val, ele) => {
    $("#visible6 .owl-item").each(function () {
      $(this).removeClass("more_index");
    });
    $("#visible6").removeClass("hover_big");

    $("#" + val).addClass("hover_big");
    console.log("ele", $(ele.target).parent().parent());
    $(ele.target).parent().parent().parent().addClass("more_index");
    $(ele.target).parents('.owl-item').addClass("more_index");

  }
  const [walletconnect, setWalletconnect] = React.useState(false);
  const dashboardRoutes = [];
  const { ...rest } = props;


  useEffect(() => {
    HomeCategoryList();
    ExploreCardNFT();
    setShowLoader(true)
    Featured();

  }, [CatName, Wallet_Details.UserAccountAddr])

  useEffect(() => {
    if(localStorage.userAddress){
    console.log("Wallet_Details.UserAccousjkdntAddr", Wallet_Details, localStorage.loggedinaddress);
    cookies.set('ClaimerCookie', localStorage.userAddress, { path: '/', domain: '.lazyminter.com',    sameSite: 'None', secure: true , expires: new Date(new Date().setFullYear(new Date().getFullYear() + 10)) });
    cookies.set('LoginMail', localStorage.loggedinaddress, { path: '/', domain: '.lazyminter.com',    sameSite: 'None', 
    secure: true  });
    }
    // localStorage.setItem('user_token', Resp?.data?.token);
    cookies.set('LoginMail1', Wallet_Details.LoginAddresss);
  },[Wallet_Details])
  console.log("Wallet_Details",Wallet_Details);

  const ExploreCardNFT = async (Catpage) => {
    var Payload = { curraddress: Wallet_Details.UserAccountAddr, Category: 'Ticket', Page: Catpage || 1, limit: 1000, Vault: "Show", VaultStatus: "Explore" }
    var NFT = await ExplorePage(Payload);
    console.log("wdfjwehfijwehfjkweh", NFT);
    if (NFT?.data?.Success) {
      setExploreCardNFTs(NFT?.data?.records);
      // if (NFT.data.records.length > 0) {
      //   setExploreCardNFTs(NFT.data);
      // if (((ExploreCardNFTs[CatName]?.list?.length !== 0) && (CatName === NFT.data.cat) && ((Catpage ? Catpage : ExploreCardNFTs[CatName].page) !== ExploreCardNFTs[CatName].page))) {
      //   ExploreCardNFTs[CatName].onmore = true
      //   ExploreCardNFTs[CatName].page = NFT.data.page
      //   ExploreCardNFTs[CatName].list = ExploreCardNFTs[CatName].list.concat(NFT.data.records)
      //   setExploreCardNFTs([])
      //   setExploreCardNFTs(ExploreCardNFTs.Ticket.list);
      // }
      // else if (ExploreCardNFTs[CatName].list.length === 0) {
      //   ExploreCardNFTs[CatName].onmore = true
      //   ExploreCardNFTs[CatName].list = NFT.data.records
      //   setExploreCardNFTs([])
      //   setExploreCardNFTs(ExploreCardNFTs.Ticket.list);
      // }
      // }
      // if (NFT.data.records.length === 0) {
      //   ExploreCardNFTs[CatName].onmore = false
      //   setExploreCardNFTs([])
      //   setExploreCardNFTs(ExploreCardNFTs)
      // }
      // setTimeout(() => {
      //   setShowLoader(false)
      // }, 1500);
    }
    else {
      toast.error("Oops..! Error Detected Refresh Page")
    }
  };

  const catChange = (Cat) => {
    if (Cat) {
      if (!ExploreCardNFTs[Cat]) {
        ExploreCardNFTs[Cat] = { page: 1, list: [], onmore: true };
        setExploreCardNFTs(ExploreCardNFTs);
      }
      setCatName(Cat)
    }
  }


  const HomeCategoryList = async () => {
    var HomeCategoryData = await HomeCategory()
    // console.log("HomeCategory",HomeCategoryData.data.data.HomeCategory);
    var FinalData = HomeCategoryData?.data?.data?.HomeCategory
    console.log("Final data of Home category", FinalData);
    if (HomeCategoryData?.data?.data?.HomeCategory) {
      SetHomeCategory(FinalData)
    }

    setTimeout(() => {
      setShowModal(false)
    }, 1000);
  }
  const LikeAct = async (token, Arr, item) => {
    var like = item.mylikes
    var count = item.likecount
    console.log("aksmdkjamaskd", like, count);
    if (Wallet_Details.UserAccountAddr !== "") {
      if (like) {
        toast.success("Removed from Liked", {
          closeButton: true,
          autoClose: 1500,
          transition: Flip,
        });
      }
      else {
        toast.success("Added to Liked", {
          closeButton: true,
          autoClose: 1500,
          transition: Flip,
        });
      }
      var Payload = {
        tokenID: token,
        tokenName: Arr,
        curraddress: Wallet_Details.UserAccountAddr,
      };
      var resp = await LikeAction(Payload);
      if (resp.data.Success) {

        if (like) {
          item.mylikes = false
          item.likecount = item.likecount + 1
          setTempCount(count + 1);
          setTemplike(true);
        } else {

          item.likecount = item.likecount - 1
          item.mylikes = true
          setTempCount(count - 1);
          setTemplike(false);
        }
      } else {

        toast.warning(resp.data.Message, {
          closeButton: true,
          autoClose: 1500,
          transition: Flip,
        });
      }
    } else history.push("/connect-wallet");
  };

  const [eye, Seteye] = useState(false);

  // login popiup functionality


  useEffect(() => {
    // console.log("homelogin status", localStorage.homepopblock)
    // if (Wallet_Details.UserAccountAddr)
    handlelogin();

  }, [Wallet_Details.UserAccountAddr])

  const handlelogin = () => {
    // console.log("data check home pop , loggedaddress", localStorage.homepopblock, localStorage.userAddress, localStorage.getItem("homepopblock"), localStorage.getItem("loggedinaddress"))
    // console.log("localStorage.homepopblock", localStorage.homepopblock, !localStorage.homepopblock, localStorage.userAddress)
    if (((localStorage.userAddress == "" || localStorage.userAddress == undefined)))
      window.$("#hoemlogin").click();


  }

  const initialValue = {
    "signemail": "",
    "signpassword": "",
    "signgame": "",
    "loginemail": "",
    "loginpassword": ""

  }

  const [formValue, setFormValue] = useState(initialValue);


  const handlechange = (e) => {

    const { id, value } = e.target;
    setFormValue({ ...formValue, [id]: value })
    // console.log("formdata", formValue);
  }

  const Featured = async () => {
    var Resp = await v1_Featured();
    if (Resp?.data && Resp?.data?.Success) {
      // console.log("Resp.data.Message", Resp.data.Message, Resp.data.Message.length)
      // setFeaturenNFT(Resp.data.Message);
      var filterhomw = (Resp?.data?.Homecms).filter((cms) => cms.question === 'home')
      var filtermid = (Resp?.data?.Homecms).filter((cms) => cms.question === "MidSec")
      var Footer = (Resp?.data?.Homecms).filter((cms) => cms.question === "Footer")
      // console.log("adfededewasc", Footer[0]);
      // console.log("dcsfdfdfdsfdsfsdfsd", filtermid);
      if (filtermid?.length > 0) { filtermid[0].answer = JSON.parse(filtermid[0].answer) }
      // Footer[0].answer = JSON.parse(Footer[0].answer)
      setHomeCms(filterhomw[0])
      setHomeMid(filtermid[0])
      SetMidSecHome(Footer[0])
      setTimeout(() => {
        setShowModal(false)
      }, 1000);
    }
    else { toast.warning("Error Detected Refresh Page"); }
  };
  return (

    <div className="home_header home homepage header_bar_align homesec">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home">
          <div className="header_logo_align" >


          </div></Link>}
        leftLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
      <div className="home_header">

        <section className="homebanner">
          <div className="headbanenimg">
            {/* {console.log("sdjdkjfndwkfndw", HomeMid)} */}
            {HomeMid && HomeMid.answer && HomeMid.answer.length > 0 ?
              HomeMid.answer.map((Midesec) => {
                // console.log("djbfjksbnfjburng", Midesec)
                return (

                  <img
                    src={Midesec.Image}
                    className="img-fluid"
                  />


                )
              }) :
              ""
            }
          </div>
          <div className="container-fluid custom-container">
            <div className="homebanerheight">
              {isEmpty(HomeCms) ? <>
                <h2>Discover,Collect & Sell Extraordinary NFT</h2>
              </>
                :
                <div className="">{ReactHtmlParser(HomeCms.answer)}</div>
              }
              <div className="mt-4">
                <Link to={"/explore/All"} className="homebanerheightexplore">Explore</Link>
                <Link to={"/create"} className="homebanerheightexplore">Create</Link>
              </div>
            </div>
          </div>

          <div>

          </div>
        </section>
        {/* <div className="container-fluid custom-container">


        <div className="lazytour">

<Button style={{"z-index":999999,"padding":"7px 15px","borderWidth":"2px","border-Style":"solid","border-radius":"8px;fontWeight:600","fontSize":"18px","filter":"drop-shadow(0px 0px 15px rgba(26, 19, 72, 0.25))","fontf\Family":"Poppins, Arial,sans-serif","whiteSpace":"nowrap","overflow":"hidden","textOverflow":"ellipsis","cursor":"pointer","background":"#9939EB","borderColor":"#FFFFFF","color":"#FFFFFF"}}  onClick={()=>window.open("https://app.storylane.io/demo/fc2stky9ahxd")}>LAZYMINTER TOUR</Button>
</div>
</div> */}

{/* <div>
            <MatEditor/>
          </div> */}

        {ExploreCardNFTs.length > 0 &&
          <section className="trending tickets mb-5" >
            <div className="container-fluid custom-container">
              <div className="trendingsec mb-3">
                <h2>Tickets  <i className="fas fa-angle-right" id="faAngleRight2" /></h2>
                <Link className="trendingsecexplore" to="/explore/All">
                  {/* <a className="trendingsecexplore" href="/explore/All"> */}
                  <h2>Explore All</h2>
                  <i class="fas fa-angle-right" className="faAngleRight" />
                  {/* </a> */}
                </Link>
              </div>
              {ExploreCardNFTs.length > 0 &&
                <div id="visible2" className="visible_def_big">
                  <OwlCarousel className='owl-theme' {...options1}>
                    {ExploreCardNFTs?.length > 0 &&
                      ExploreCardNFTs?.map((Item, index) => {
                        console.log("Ticketcategory for ticket componrnt", Item);
                        return (
                          <div className="style_prevu_kit full_card_view style_prevu_ki1t main_not_box" onMouseOver={(e) => { setTransition1("visible2", e) }}>

                            <Ticketcard
                              item={Item}
                            />

                          </div>
                        )
                      })}
                  </OwlCarousel>
                </div>
              }
            </div>


          </section>
        }

        {/* {console.log("HomeCategoryDataHomeCategoryData", HomeCategoryData)} */}

        {HomeCategoryData.length > 0 &&
          HomeCategoryData.map((Itemcategory, index) => {
            console.log("Itemcategory", Itemcategory);
            return (

              <section className="trending" id="artssec">
                {Itemcategory.dataA.length > 0 &&
                  <div className="container-fluid custom-container">
                    <div className="trendingsec mb-3">
                      <h2> {Itemcategory.name} <i className="fas fa-angle-right" id="faAngleRight2" /></h2>
                      <Link className="trendingsecexplore" to="/explore/All">
                        {/* <a className="trendingsecexplore" href="/lazymintingnft/explore/All"> */}
                        <h2>Explore All</h2>
                        <i class="fas fa-angle-right" className="faAngleRight" />
                        {/* </a> */}
                      </Link>
                    </div>

                    <div id={"visible" + (index + 3)}>
                      <OwlCarousel className='owl-theme' {...options}>
                        {Itemcategory.dataA.length > 0 && Itemcategory.dataA.map((itemData) => (

                          <div className="style_prevu_kit skille_theme" onMouseOver={(e) => { setTransition2("visible" + (index + 3), e) }}>
                            <Link to={`/info/${itemData.contractAddress}/${itemData.tokenOwner}/${itemData.tokenID}`}>
                              <div className="trendingban">

                                {/* {console.log("rfyireuhfirweuhfiwref", itemData)} */}

                                {
                                  itemData &&
                                  itemData.image &&
                                  (itemData.image.split(".").pop() == "mp4" ? (
                                    <video
                                      id="my-video"
                                      className="img-fluid"
                                      autoPlay
                                      playsInline
                                      loop
                                      muted
                                      preload="auto"
                                      controls disablePictureInPicture controlsList="nodownload"
                                    >
                                      <source src={itemData.image !== "" ? `${config.Back_URL}/nftImg/${itemData.tokenCreator}/${itemData.image}` : `${config.IPFS_IMG}/${itemData.ipfsimage}`} type="video/mp4" />
                                    </video>

                                  ) : (
                                    <img
                                      src={itemData.image !== "" ? `${config.Back_URL}/nftImg/${itemData.tokenCreator}/${itemData.image}` : `${config.IPFS_IMG}/${itemData.ipfsimage}`}
                                      alt="Collections"
                                      className="img-fluid"
                                    />

                                  ))}
                              </div>
                            </Link>
                            <div className="faHeartflex likedbtn">
                              <div className="d-flex align-items-center justify-content-center" onClick={() => {
                                LikeAct(itemData.tokenID, itemData.tokenName, itemData);
                              }}>

                                {Templike ? (
                                  <i className="far fa-heart liked"></i>
                                ) : (
                                  <i className="far fa-heart liked2 likedred"></i>
                                )}

                              </div>

                            </div>
                            <div className="carddnone">
                              <div className="usercontrolicon">
                                <div className="sliderbanncont">
                                  <div>
                                    {itemData.AccountAddrssImage === "" &&
                                      <Link to={`/my-items/${itemData.tokenOwner}`}>
                                        <img src={user} className="profilebamds" />
                                      </Link>
                                    }
                                    {
                                      itemData.AccountAddrssImage !== "" &&
                                      <Link to={`/my-items/${itemData.tokenOwner}`}>
                                        <img src={`${config.Back_URL}/Users/${itemData.PrimaryAddr}/profileImg/${itemData.AccountAddrssImage}`} />
                                      </Link>
                                    }
                                  </div>
                                  <div className="sliderbanncontinue">

                                    <h2><Link to={`/my-items/${itemData.tokenOwner}`}>{itemData.tokenOwner_name == '' ? "@" + itemData?.tokenOwner?.slice(0, 8) : "@" + itemData.tokenOwner_name}</Link></h2>
                                    <p><Link to={`/my-items/${itemData.tokenOwner}`}>{itemData.tokenName}</Link></p>

                                  </div>
                                </div>
                                <div className="usercontroliconstock">
                                  <h2>{itemData.balance} In Stock</h2>
                                  {/* {itemData.minimumBid != 0 || "" || null ?
                                    <h2>{"Price: " + itemData.minimumBid} {itemData.coinname}</h2>
                                    : */}

                                  <h2>{itemData.tokenPrice == '' || itemData.tokenPrice == null ? '' : `${"" + itemData.tokenPrice}`} {itemData.tokenPrice == "" || itemData.tokenPrice == null ? "" : itemData.coinname} </h2>
                                </div>


                              </div>
                              {/* <div className="usercontroliconstock"> */}

                              {/* <div>
                                  <h2>{itemData.balance} In Stock</h2>
                                

                                  <h2>{itemData.tokenPrice == '' || itemData.tokenPrice == null ? '' : `${"Price: " + itemData.tokenPrice}`} {itemData.tokenPrice == "" || itemData.tokenPrice == null ? "" : itemData.coinname} </h2>
                                  
                                </div> */}

                              {/* <div className="bidonowbtn">
                                  <Link to={`/info/${itemData.contractAddress}/${itemData.tokenOwner}/${itemData.tokenID}`}>
                                    {itemData.minimumBid != 0 || "" || null ?
                                      <span>{itemData.tokenOwner == Wallet_Details.UserAccountAddr ? 'You Own This':'Make offer'}</span>
                                      :
                                      <span>{itemData.tokenPrice == '' || itemData.tokenPrice == null  ? itemData.tokenOwner == Wallet_Details.UserAccountAddr ? 'You Own This': 'Make offer' : itemData.tokenOwner == Wallet_Details.UserAccountAddr ? 'You Own This':'Buy now'}
                                      </span>
                                    }
                                  </Link>
                                </div> */}



                              {/* </div> */}
                              <div className="buynowpad">
                                <Link to={`/info/${itemData.contractAddress}/${itemData.tokenOwner}/${itemData.tokenID}`}>
                                  {itemData.minimumBid != 0 || "" || null ?
                                    <button type="button" class="btn btn-danger buynowbtn">{itemData.tokenOwner == Wallet_Details.UserAccountAddr ? 'You Own This' : 'Make offer'}</button> :
                                    <button type="button" class="btn btn-danger buynowbtn">{itemData.tokenPrice == '' || itemData.tokenPrice == null ? itemData.tokenOwner == Wallet_Details.UserAccountAddr ? 'You Own This' : 'Make offer' : itemData.tokenOwner == Wallet_Details.UserAccountAddr ? 'You Own This' : 'Buy now'}</button>
                                  }
                                </Link>
                              </div>
                            </div>

                          </div>

                        ))}
                      </OwlCarousel>
                    </div>


                  </div>
                }
              </section>


            )
          })}



        {/* <section className="promotion my-5">
          <div className="container-fluid custom-container">
            <div className="promotionssec">
              <h2>Lorem Ipsum</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
              {console.log("dfjhweifiwehfiew", MidsecHome)}
              {MidsecHome && MidsecHome ?
                <p>
                  {ReactHtmlParser(MidsecHome.answer)}
                </p>
                :
                <p >
                  The world’s first and largest digital marketplace for crypto
                  collectibles and non-fungible tokens (NFTs). Buy, sell and
                  discover exclusive digital items.
                </p>
              }
              <a className="explroenft" href="/Lazyminter/explore/All">Explore NFT's</a>
            </div>
          </div>
        </section> */}
        <Footer />

        <Modal
          isOpen={ShowModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          {theme === "#000" ?
            <div className="modal-body text-center loading_bgcolor">
              <div className="laodingpart">
                <img src={require("../assets/images/lazymintingnew/logo.png")} alt="Profile" className="img-fluid items_profile smal_main_lm" />
                {/* {
             theme == "#FFF" ? 
             <p className="text-dark">Loading ....</p>
             :
             <p className="text-white">Loading ....</p>
           } */}

              </div>
            </div> :

            <div className="modal-body text-center loading_bgcolor">
              <div className="laodingpart">
                <img src={require("../assets/images/lazymintingnew/logodark.png")} alt="Profile" className="img-fluid items_profile smal_main_lm" />
                {/* {
    theme == "#FFF" ? 
    <p className="text-dark">Loading ....</p>
    :
    <p className="text-white">Loading ....</p>
  } */}

              </div>
            </div>

          }

        </Modal>

      </div>

      <>
        <>
          <button className="d-none" data-toggle="modal" data-target="#register_modal" id="hoemlogin" ></button>
        </>


      </>

    </div>



  )
}
