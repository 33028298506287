import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import EditProfCont from './EditProfCont';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { useSelector } from "react-redux";
import { UserpanelSub, getAllCounts, RouterChecker } from "../actions/v1/token"
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import config from 'lib/config'
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";





function UserProfile(props) {

  const dashboardRoutes = [];
  const { ...rest } = props;
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const history = useHistory();
  const [SubList, SetSubList] = useState([])
  const [counts, Setcounts] = useState([])
  const [Routeee, SetRouteeee] = useState("Not Allowed")
  const [Routeee1, SetRouteeee1] = useState("Not Allowed")
  const [sidebarShrink, setSidebarShrink] = useState(false);
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  useEffect(() => {
    getCounts()
  }, [Wallet_Details.UserAccountAddr])


  async function getCounts() {
    var data = {
      curradd: Wallet_Details.UserAccountAddr
    }
    var counts = await getAllCounts(data)

    if (counts) {
      Setcounts(counts.data.data)
    }
  }

  useEffect(() => {
    SubcriberList()
    Route()
  }, [Wallet_Details.UserAccountAddr])

  const SubcriberList = async () => {
    var Addresss = {
      Address: Wallet_Details.UserAccountAddr
    }
    var SubscriberList = await UserpanelSub(Addresss)
    if (SubscriberList?.data?.data?.Message == "success") {
      SetSubList(SubscriberList?.data?.data?.userValue)
    }
  }

  const Route = async () => {
    var Addresss = {
      Address: Wallet_Details.UserAccountAddr
    }
    var SubscriberList = await RouterChecker(Addresss)
    if (SubscriberList?.data?.data?.Message == "Allowed") {
      SetRouteeee(SubscriberList?.data?.data?.userValue)
    }
  }

  const setSidebarWidth = () => {
    setSidebarShrink(!sidebarShrink);
    if (sidebarShrink) {
      document.body.classList.remove('sidebar_shr');
    }
    else {
      document.body.classList.add('sidebar_shr');

    }
  }

  useEffect(() => {
    Route1()
  }, [Wallet_Details.UserAccountAddr])

  const Route1 = async () => {
    var Addresss = {
      Address: Wallet_Details.UserAccountAddr
    }
    var SubscriberList = await RouterChecker(Addresss)
    if (SubscriberList?.data?.data?.UserBalance.TokenScanner == "true") {
      SetRouteeee1(SubscriberList?.data?.data?.UserBalance)
    }
  }

  return (
    <>
      <div className='home_header home'>

        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand={<Link to="/home">
            <div className="header_logo_align">


            </div></Link>}
          leftLinks={<HeaderLinks />}
          changeColorOnScroll={{
            height: 20,
            color: "white"
          }}
          {...rest}
        />
        <div className='container-fluid fluid_padding_align d-flex'>
          <div className='home_header home'>
            <div id="sidebar" className={sidebarShrink ? "side_shrink" : ""}>

              <button className="get-started-btn rounded_btn_wal shrink_side_btn"
                onClick={() => setSidebarWidth()}
              >
                <i class="fa fa-chevron-right" aria-hidden="true"></i>
              </button>
              <div className='sidebar_btwn_dtls_align'>
                <div>
                  <div className='sidebar_whole_img_align'>

                  </div>
                  <ul className='sidebar_ul'>
                    <Link to='/userdashboard'><li className='sidebar_list_align'><i class="fa-solid fa-chart-line"></i><span className='sidebar_hiding_txt'>Dashboard</span></li></Link>
                    <Link to='/userprofile'><li className='sidebar_list_align'><i class="fa-solid fa-user"></i><span className='sidebar_hiding_txt'>Profile</span></li></Link>
                    {Routeee1.TokenScanner == "true" &&
                      <Link to='/qrcodescan'><li className='sidebar_list_align'><i class="fa-solid fa-qrcode"></i><span className='sidebar_hiding_txt'>Token Scanner</span></li></Link>}

                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className='scrolling_move_sec_align'>
            <div className="topcardsecs">
              <div>
                <div className='card blackcard_style mb-2'>
                  <div className='firstrow'>
                    <div><i class="fa fa-circle admint_nrm_round admin_green_round mb-2" /></div>
                    <div className='div_text'>Total Tokens</div>
                  </div>

                  <div className='graph_align'>
                    <div className='count_dtls'>
                      <span className='count_txt'>{counts.totaltokens ? counts.totaltokens : 0}</span><br />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='card blackcard_style mb-2'>
                  <div className='firstrow'>
                    <div><i class="fa fa-circle admint_nrm_round admin_red_round mb-2"></i></div>
                    <div className='div_text'>Used Tokens</div>
                  </div>

                  <div className='graph_align'>
                    <div className='count_dtls'>
                      <span className='count_txt'>{counts.usedTokens ? counts.usedTokens : 0}</span><br />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='card blackcard_style mb-2'>
                  <div className='firstrow'>
                    <div><i class="fa fa-circle admint_nrm_round admin_sandal_round mb-2"></i></div>
                    <div className='div_text'>Available Tokens</div>
                  </div>

                  <div className='graph_align'>
                    <div className='count_dtls'>
                      <span className='count_txt'>{counts.availableTokens ? counts.availableTokens : 0}</span><br />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='card blackcard_style mb-2'>
                  <div className='firstrow'>
                    <div><i class="fa fa-circle admint_nrm_round admin_violet_round mb-2"></i></div>
                    <div className='div_text'>Total Tokens Sent</div>
                  </div>

                  <div className='graph_align'>
                    <div className='count_dtls'>
                      <span className='count_txt'>{counts.totsoulavailable ? counts.totsoulavailable : 0}</span><br />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='card blackcard_style mb-2'>
                  <div className='firstrow'>
                    <div><i class="fa fa-circle admint_nrm_round admin_sandal_round mb-2"></i></div>
                    <div className='div_text'>Total SBT Sent</div>
                  </div>

                  <div className='graph_align'>
                    <div className='count_dtls'>
                      <span className='count_txt'>{counts.SBTsend ? counts.SBTsend : 0}</span><br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row mt-md-5 mt-3'>
              <div className='col-xl-8 col-lg-7 col-md-7 col-sm-12 col-12 mb-3'>
                <EditProfCont />
              </div>
              <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3'>
                <div className='subscriber_whole_dtls_bgalign'>
                  <div className='subscriber_whole_dtls_align d-flex justify-content-between w-100'>
                    <p className='admin_new_subscribers'>New Followers</p>
                  </div>
                  {SubList.length > 0 ?
                    <>
                      {SubList && SubList.map((item, index) => {
                        return (
                          <div className='admin_subscriber_dtls_align w-100'>
                            {item.image == null || undefined ?
                              <img className='admin_dropdown_img_align' src={require('../assets/images/faces/marc.jpg')} />
                              :
                              <img className='admin_dropdown_img_align' src={`${config.Back_URL}/Users/${item.userAddress}/profileImg/${item?.image}`} />
                            }
                            <div>
                              <p className='subscribers_align_dtls'>{item?.name == undefined ? item?.userAddress?.slice(0, 15) : item?.name}<span className='admin_badge_icon_align'><i class="fa-solid fa-certificate admin_certificate_icon" /><i class="fa-solid fa-check admin_tick_icon" /></span></p>
                              <span className='follwers_count_align'>22k Followers</span>
                            </div>
                          </div>
                        )
                      })}
                    </>
                    : <h6 className='no_fol_found'>No followers found</h6>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserProfile