import React from 'react'
import isEmpty from 'lib/isEmpty'
import config from 'lib/config'
import moment from "moment";
import { NFTnameValidation, CollectionValidate, SolBoundNameValidate, DomainNameValidate, TittleValidate } from 'actions/v1/token'
import $ from 'jquery'
import NumisEmpty from 'lib/NumIsEmpty'
import Web3 from "web3"

/**
 * This function validates the NFT input data for creating, editing, selling, buying or auctioning an NFT.
 * @param {Object} data - The NFT data to be validated.
 * @param {Boolean} Onsale - Whether the NFT is for sale or not.
 * @param {String} Saletype - The type of sale (Fixed, Auction, etc.).
 * @param {String} BuyType - The type of purchase (Fiat, Crypto, etc.).
 * @returns {Object} - Validation errors. If the validation is successful, an empty object is returned.
 */
export const NFTValidation = async (data, Onsale, Saletype, BuyType) => {
    console.log("NFTValidationss is comming data", data);
    var web3 = new Web3()

    var validationerr = {}
    var DBnamecheck = null
    let dateRegex = /^\d{2}([./-])\d{2}\1\d{4}$/;
    // /^([1][12]|[0]?[1-9])[\/-]([3][01]|[12]\d|[0]?[1-9])[\/-](\d{4}|\d{2})$/;  
    var arr = ['name', 'NFTCategory', 'royalties', 'NetworkName', 'Standard']
    for (const property in data) {
        // console.log("qwererewrewrwerwe", property, data);
        if (arr.includes(property) && isEmpty(data[property])) {
            validationerr[property] = `${property} validation failed`
        }
        if (data.NFTCategory == 'Ticket') {

            // console.log("cechk prop dta", data,dateRegex.test(data.Date));
            if (dateRegex.test(data.Date) == false) {
                // console.log("adsadasdsadasdzxczxnvmczn");
                validationerr.Date = 'Please Enter date in this format(DD-MM-YYYY)'
            }
            if (data.Date === '') {
                validationerr.Date = '(DD-MM-YYYY) cannot be Empty'
            }
            if (data.Location === '') {
                validationerr.Location = "Location(Address) cannot be Empty"
            }
            if (data.Section === '') {
                validationerr.Section = "Section cannot be Empty"
            }
            if (data?.TokenGating === '' && data.TokenGat === true) {
                validationerr.TokenGating = "TokenGating cannot be Empty"
            }

        }
        if (property === 'File' && data[property] === null)
            validationerr[property] = `${property} is Required`
        if (property === 'royalties') {
            if (data[property] <= 0 || data[property] > 20)
                validationerr.royalties = "Royalty Must be between 0 to 20"
            else if (data[property].includes('.'))
                validationerr.royalties = "Royalty Must be a whole Number"
        }
        if (Onsale)
            if ((Saletype === 'Fixed' && property === 'TokenPrice') || (Saletype === 'Auction' && property === 'MinimumBid'))
                if (data[property] < 0.0001 || data[property] >= 1e12)
                    validationerr[property] = `${property} Must be between 0.0001 to 10^12`
        if ((BuyType === 'Fiat' && property === 'TokenPrice'))
            if (data[property] < 1 || data[property] >= 1e12)
                validationerr[property] = `${property} Must be between 2$ to 10^10`
        if (Saletype !== true)
            if (Saletype === 'Fixed' && data.TokenPrice === undefined)
                validationerr.TokenPrice = "Price cannot be Empty"
        if (BuyType === 'Fiat' && data.TokenPrice === undefined)
            validationerr.TokenPrice = "Price cannot be Empty"
        if (Saletype === 'Auction' && data.MinimumBid === undefined)
            validationerr.MinimumBid = "Minimumbid cannot be Empty"
        if (Saletype === 'Auction' && (data.AuctionStart === undefined || data.AuctionEnd === undefined))
            validationerr.Auctiondates = "Start & End date cannot be Empty"
        if (data.Charity) {
            const emptyKeys = data.CharityDetails.reduce((prev, cur) =>
                [...prev, ...Object.keys(cur).filter((key) => !cur[key])], [])
                .filter((v, i, a) => a.indexOf(v) === i)
                .join(', ');

            console.log("EmptyKeysss", emptyKeys);
            if (emptyKeys) {
                if (emptyKeys == "Royality") {
                    validationerr.CharityRoyalty = "Royalty is Reqired For Every user"
                }
                if (emptyKeys == "Address") {
                    validationerr.CharityAddress = "Address is Reqired For Every user"
                }
                if (emptyKeys == "Share") {
                    validationerr.CharityShare = "Share is Reqired"
                }
            }
            var Address = data.CharityDetails.map((item) => { return web3.utils.isAddress(item.Address) })
            if (!Address) {
                validationerr.CharityAddress = "Enter Valid Address"
            }
            console.log("Royality", Royality);
            var Royality = data.CharityDetails.map((item) => { return item.Royality }).reduce((partialSum, a) => Number(partialSum) + Number(a), 0) + Number(data.royalties)
            console.log("Royality", Royality);
            if (Royality) {
                if (Royality <= 0 || Royality > 20)
                    validationerr.CharityRoyalty = "Your Split Royalty Must be 0 to 20 %"
                else if (String(Royality).includes('.'))
                    validationerr.CharityRoyalty = "Royalty Must be a whole Number"
            }
            var IncomeShare = data.CharityDetails.map((item) => { return item.Share }).reduce((partialSum, a) => Number(partialSum) + Number(a), 0)
            console.log("hcjhxbcjahdash", IncomeShare);
            if (IncomeShare) {
                if (IncomeShare <= 1 || IncomeShare > 80)
                    validationerr.CharityShare = "Your Income Share Must be 1 to 80 %"
                else if (String(IncomeShare).includes('.'))
                    validationerr.CharityShare = "Income Share Percentage Must be a whole Number"
            }
        }
    }
    // console.log("validationcheck", validationerr, Saletype)
    if (isEmpty(validationerr)) {
        DBnamecheck = await NFTnameValidation(data.name)
        // console.log("jkbjbbfjnejknka", DBnamecheck)
        if (!(DBnamecheck.Success)) {
            validationerr.name = DBnamecheck.Message
            validationerr.Success = DBnamecheck.Success
            return validationerr
        }
        else {
            validationerr.Success = DBnamecheck.Success
            return validationerr
        }
    }
    else {
        validationerr.Success = false
        return validationerr
    }
}

/**
 * Processes the validation of NFT token data for fractional NFTs.
 *
 * Validates the provided `data` object by checking if all required fields are present and
 * in the correct format. If a field is missing or has an incorrect format, an error message
 * is added to the `validationerr` object under the field name as the key. If all fields are
 * valid, it checks if a token with the same name already exists in the database and returns
 * the validation result as an object with a `Success` key.
 *
 * @param {Object} data - Object containing the NFT token details to be validated.
 * @param {boolean} Onsale - Determines if the token is being put on sale.
 * @param {boolean} Saletype - Determines if the token is being put on sale with a fixed price or auction.
 * @param {boolean} BuyType - Determines if the token is being bought with cryptocurrency or fiat.
 * @returns {Object} Validation result with a `Success` key.
 * @throws Will return an object with a `Success` key set to `false` if any of the fields are invalid.
 */
export const FractionValidation = async (data, Onsale, Saletype, BuyType) => {
    console.log("NFTValidationss is comming data", data);
    var web3 = new Web3()
    var validationerr = {}
    var DBnamecheck = null
    let dateRegex = /^\d{2}([./-])\d{2}\1\d{4}$/;
    var arr = ['name', 'NFTCategory', 'royalties', 'NetworkName', 'Standard']
    for (const property in data) {
        if (arr.includes(property) && isEmpty(data[property])) {
            validationerr[property] = `${property} validation failed`
        }
        if (property === 'File' && data[property] === null)
            validationerr[property] = `${property} is Required`
        if (property === 'royalties') {
            if (data[property] <= 0 || data[property] > 20)
                validationerr.royalties = "Royalty Must be between 0 to 20"
            else if (data[property].includes('.'))
                validationerr.royalties = "Royalty Must be a whole Number"
        }
        if (Onsale)
            if ((Saletype === 'Fixed' && property === 'TokenPrice') || (Saletype === 'Auction' && property === 'MinimumBid'))
                if (data[property] < 0.0001 || data[property] >= 1e12)
                    validationerr[property] = `${property} Must be between 0.0001 to 10^12`
        if ((BuyType === 'Fiat' && property === 'TokenPrice'))
            if (data[property] < 1 || data[property] >= 1e12)
                validationerr[property] = `${property} Must be between 2$ to 10^10`
        if (Saletype !== true)
            if (Saletype === 'Fixed' && data.TokenPrice === undefined)
                validationerr.TokenPrice = "Price cannot be Empty"
        if (BuyType === 'Fiat' && data.TokenPrice === undefined)
            validationerr.TokenPrice = "Price cannot be Empty"
        if (Saletype === 'Auction' && data.MinimumBid === undefined)
            validationerr.MinimumBid = "Minimumbid cannot be Empty"
        if (Saletype === 'Auction' && (data.AuctionStart === undefined || data.AuctionEnd === undefined))
            validationerr.Auctiondates = "Start & End date cannot be Empty"
    }
    if (isEmpty(validationerr)) {
        DBnamecheck = await NFTnameValidation(data.name)
        if (!(DBnamecheck.Success)) {
            validationerr.name = DBnamecheck.Message
            validationerr.Success = DBnamecheck.Success
            return validationerr
        }
        else {
            validationerr.Success = DBnamecheck.Success
            return validationerr
        }
    }
    else {
        validationerr.Success = false
        return validationerr
    }
}


/**
 * Checks if a given string is a valid email address
 *
 * @param {string} email - The string to test
 * @returns {boolean} - True if the string is a valid email address, false
 * otherwise
 */
export const isEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

/**
 * Checks if a given string is a valid date in the format DD/MM/YYYY
 * or DD-MM-YYYY
 *
 * @param {string} email - The string to test
 * @returns {boolean} - True if the string is a valid date, false
 * otherwise
 */
export const isDate = (email) => {
    var re = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
    return re.test(email);
}

/**
 * Validates a custom URL string against a set of rules.
 *
 * The URL is considered invalid if it matches the `config.nameFormat`
 * or `nameRegex` patterns, or if it contains a period, space, or matches
 * certain characters like '|', '/', or '\\'. If the URL is invalid,
 * the function returns `false`; otherwise, it returns `true`.
 *
 * @param {string} cusurl - The custom URL string to validate.
 * @returns {boolean} - Returns `true` if the custom URL is valid, otherwise `false`.
 */
export const isCustomURL = (cusurl) => {
    let nameRegex = /^[:]+(\s{0,1}[a-zA-Z-, ])*$/;
    var istrue = ((config.nameFormat).test(cusurl)) ||
        ((nameRegex).test(cusurl)) ||
        ((cusurl).includes('.')) ||
        ((cusurl).includes(' ')) ||
        ((cusurl).match(/^(?:.*[|,/,\\])/))
    return isEmpty(istrue)
}

/**
 * Validates user registration form data against a set of rules.
 *
 * The form data is checked for a valid email address, a custom URL
 * that does not contain a period, space, or certain characters like
 * '|', '/', or '\\', and a user name that does not contain a smiley.
 * If any of the fields are invalid, the function returns an object
 * with the field names as keys and the error messages as values.
 * Otherwise, it returns `true`.
 *
 * @param {Object} Formdata - The user registration form data to
 * validate.
 * @returns {Object|boolean} - Returns an object with error messages if
 * any of the fields are invalid, or `true` if the form data is valid.
 */
export const UserValidation = (Formdata) => {
    var valError = {}
    valError.Email = ((Formdata.Email) ? isEmail(Formdata.Email) : true) ? true : "Enter Valid Email"
    valError.CustomUrl = ((Formdata.CustomUrl) ? isCustomURL(Formdata.CustomUrl) : true) ? true : "CustomUrl Cannot have Smiley, ' . ' , ' / ' or Spaces "
    valError.Name = ((Formdata.Name) ? !((config.nameFormat).test(Formdata.Name)) : true) ? true : "Smiley's are Not allowed"
    if (valError.Email === true && valError.CustomUrl === true && valError.Name === true)
        return true
    else
        return valError
}

/**
 * Validates a file for image type and size.
 *
 * This function checks if a given file has a valid image type and size.
 * If the file type is invalid or the file size exceeds 30MB, it returns an
 * object with a `Success` property set to `false` and a `Message` property
 * containing an error message. Otherwise, it returns an object with a
 * `Success` property set to `true`.
 *
 * @param {Object} file - The file to be validated.
 * @param {String} Page - The page type, either 'Create' or 'Edit', to determine
 * which set of valid extensions to use.
 * @returns {Object} - Validation result object with a `Success` property and
 * an optional `Message` property.
 */
export const ImageValidation = (file, Page) => {
    // console.log("FILE AND PAGE VALIDATION IN CREATE AND BULK", file, Page);
    if (Page === 'Edit')
        var validExtensions = ["png", "webp", "jpeg", "PNG", "jpg", "JPEG", "JPG"];
    else
        var validExtensions = ["png", "webp", "jpeg", "PNG", "mp4", "jpg", "JPEG", "JPG"];
    var fileName = file.name;
    var fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
    if ($.inArray(fileNameExt, validExtensions) == -1)
        return { Success: false, Message: "Only these file types are accepted : " + validExtensions.join(", ") };
    if (30000000 < file.size)
        return { Success: false, Message: "File size exceeds 30 MB" };
    else
        return { Success: true }
}

/**
 * Validates the provided arguments for a popup, ensuring they meet specific criteria.
 *
 * This function checks various conditions for the auction and token parameters:
 * - Validates auction start and end times.
 * - Ensures the bid amount is above the minimum bid.
 * - Checks if the token price is within valid ranges, depending on whether it's in fiat or not.
 * - Validates the token quantity for type 1155 NFTs.
 *
 * @param {Object} Arg - The arguments containing parameters to validate.
 * @param {Object} Arg.NFT - The NFT object containing auction details.
 * @param {number} Arg.TokenPrice - The price of the token.
 * @param {boolean} Arg.Fiat - Whether the price is in fiat currency.
 * @param {number} Arg.type - The type of the NFT (e.g., 1155).
 * @param {number} Arg.NoofToken - The number of tokens.
 * @param {number} Arg.MaxToken - The maximum allowable tokens.
 * @returns {Object} - Returns an object with a `Success` property and a `Message` if validation fails.
 */
export const PopupValidation = (Arg) => {
    console.log("popup validation params", Arg)
    var Resp = null
    if (Arg.NFT && Arg.NFT.auctionstart && new Date(
        Arg.NFT.auctionend
    ).getTime() > Date.now()) {
        if (Arg.TokenPrice < Arg.NFT.minimumBid || Arg.TokenPrice <= 0) {
            Resp = { Success: false, Message: "Bid Amount must be above Minimum Bid" + " " + Arg.NFT.minimumBid }
            return Resp
        }
    }
    if (((!Arg.TokenPrice) || (Arg.TokenPrice < 0.0001) || (Arg.TokenPrice > 1e12)) && !Arg.Fiat)
        Resp = { Success: false, Message: "Enter valid price between 0.0001 to 1e12" }
    if (Arg.type === 1155) {
        if ((!Arg.NoofToken) || (Arg.NoofToken < 1) || (Arg.NoofToken > Arg.MaxToken))
            Resp = { Success: false, Message: "Enter valid Quantity between 1 to available" }
    }
    if (((!Arg.TokenPrice) || (Arg.TokenPrice < 2) || (Arg.TokenPrice >= 1e10)) && Arg.Fiat)
        Resp = { Success: false, Message: "Enter valid price between 2$ to 10^12" }
    if (!Resp)
        Resp = { Success: true }
    return Resp
}

/**
 * Validates NFT data for bulk operations.
 * 
 * This asynchronous function checks the provided `data` object for required fields and formats.
 * It validates fields such as 'NFTCategory', 'CollectionName', 'NetworkName', and 'NFTSubCategory'
 * by ensuring they are not empty. Additionally, it checks for certain conditions on the 'CollectionName'
 * and 'File' properties. If validation errors are found, they are stored in the `validationerr` object.
 * If no validation errors are detected, the function further validates the `CollectionName` against
 * a database and returns the validation result.
 * 
 * @param {Object} data - Object containing the details of the NFT to be validated.
 * @param {boolean} Onsale - Indicates if the NFT is on sale.
 * @param {boolean} Saletype - Specifies the type of sale.
 * @returns {Object} - An object with validation results, including a `Success` key.
 * @throws Will return an object with `Success` set to `false` if any fields are invalid or
 * if the collection name validation fails.
 */
export const BulkValidation = async (data, Onsale, Saletype) => {
    console.log("NFTValidationss is comming data", data);
    var validationerr = {}
    var DBnamecheck = null
    var arr = ['NFTCategory', 'CollectionName', 'NetworkName', "NFTSubCategory"]
    for (const property in data) {
        // console.log("qwererewrewrwerwe", property,data?.File?.length,property === 'File' && data?.File?.length > 3,property  && data?.File?.length );
        if (arr.includes(property) && isEmpty(data[property])) {
            validationerr[property] = `${property} validation failed`
        }
        // console.log("sdsdafjsdksdsdmnljflsdkj",property,data.CollectionName);
        if (property === 'CollectionName' && data?.CollectionName?.match(/\ /)) {
            validationerr[property] = `${property} enter without space (you can use underscore)`
        }
        // if (property === 'File' && data?.File?.length < 2 && data.NFTCategory !== 'Ticket') {
        //     validationerr[property] = `${property} folder must contain more than 2 images`
        // }
        // if(property === 'CollectionName' && data.CollectionName == null || data.CollectionName == "") 
        //     validationerr[property] = `${property} is Requried`
        if (property === 'File' && data[property] === null)
            validationerr[property] = `${property} is Required`
        if (property === 'Profile' && data[property] === null)
            validationerr[property] = `${property} is Required`
        if (property === 'Coverimg' && data[property] === null)
            validationerr[property] = `${property} is Required`
        if (property === 'Excel' && data[property] === null)
            validationerr[property] = `${property} is Required`


    }
    // console.log("validationcheck", validationerr, Saletype)
    if (isEmpty(validationerr)) {
        DBnamecheck = await CollectionValidate(data.CollectionName)
        // console.log("jkbjbbfjnejknka", DBnamecheck)
        if (!(DBnamecheck.Success)) {
            validationerr.name = DBnamecheck.Message
            validationerr.Success = DBnamecheck.Success
            return validationerr
        }
        else {
            validationerr.Success = DBnamecheck.Success
            return validationerr
        }
    }
    else {
        validationerr.Success = false
        return validationerr
    }
}

/**
 * Validates a file for image type and size in the bulk upload context.
 * @param {Object} file - The file to be validated.
 * @param {String} Page - The page type, either 'Create' or 'Edit', to determine
 * which set of valid extensions to use.
 * @returns {Object} - Validation result object with a `Success` property and
 * an optional `Message` property.
 */
export const ImageValidationBulk = (file, Page) => {
    console.log("FILE AND PAGE VALIDATION IN CREATE AND BULK", file, Page);
    if (Page === 'Edit')
        var validExtensions = ["png", "gif", "webp", "jpeg", "PNG", "jpg", "JPEG", "JPG"];
    else
        var validExtensions = ["png", "gif", "webp", "jpeg", "PNG", "jpg", "JPEG", "JPG"];
    var fileName = file.name;
    console.log("aweqweqweqweqw", fileName);
    var fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
    if ($.inArray(fileNameExt, validExtensions) == -1)
        return { Success: false, Message: "Only these file types are accepted : " + validExtensions.join(", ") };
    if (30000000 < file.size)
        return { Success: false, Message: "File size exceeds 30 MB" };
    else
        return { Success: true }
}

/**
 * Validates a file for Excel type and size in the bulk upload context.
 * @param {Object} file - The file to be validated.
 * @param {String} Page - The page type, either 'Create' or 'Edit', to determine
 * which set of valid extensions to use.
 * @returns {Object} - Validation result object with a `Success` property and
 * an optional `Message` property.
 */

/**
 * Validates a file for Excel type and size in the bulk upload context.
 *
 * This function checks if a given file has a valid Excel type and size.
 * It ensures the file extension is 'xlsx' and the file size does not
 * exceed 30MB. If the file type is invalid or the file size exceeds
 * the limit, it returns an object with `Success` set to `false` and a
 * `Message` containing an error message. Otherwise, it returns an
 * object with `Success` set to `true`.
 *
 * @param {Object} file - The file to be validated.
 * @param {String} Page - The page type, either 'Create' or 'Edit', to determine
 * which set of valid extensions to use.
 * @returns {Object} - Validation result object with a `Success` property and
 * an optional `Message` property.
 */
export const ImageValidationExcel = (file, Page) => {
    // console.log("FILE AND PAGE VALIDATION IN CREATE AND BULK", file, Page);
    if (Page === 'Edit')
        var validExtensions = ["xlsx"];
    else
        var validExtensions = ["xlsx"];
    var fileName = file.name;
    var fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
    if ($.inArray(fileNameExt, validExtensions) == -1)
        return { Success: false, Message: "Only these file types are accepted : " + validExtensions.join(", ") };
    if (30000000 < file.size)
        return { Success: false, Message: "File size exceeds 30 MB" };
    else
        return { Success: true }
}

//soul Bound Validation

/**
 * Validates data for SoulBound NFTs.
 *
 * This asynchronous function checks the provided `data` object for required fields
 * and formats specific to SoulBound NFTs. It validates fields such as 'CollectionName',
 * 'NetworkName', 'Category', and 'NFTSubCategory' by ensuring they are not empty.
 * Additionally, it checks for certain conditions on the 'CollectionName' and 'File'
 * properties. If validation errors are found, they are stored in the `validationerr` object.
 * If no validation errors are detected, the function further validates the `CollectionName`
 * against a database and returns the validation result.
 *
 * @param {Object} data - Object containing the details of the SoulBound NFT to be validated.
 * @param {boolean} Onsale - Indicates if the NFT is on sale.
 * @param {boolean} Saletype - Specifies the type of sale.
 * @returns {Object} - An object with validation results, including a `Success` key.
 * @throws Will return an object with `Success` set to `false` if any fields are invalid or
 * if the collection name validation fails.
 */
export const SoulBoundValidation = async (data, Onsale, Saletype) => {
    console.log("NFTValidationss is comming data", data);
    var validationerr = {}
    var DBnamecheck = null
    var arr = ['CollectionName', 'NetworkName', 'Category', 'NFTSubCategory']
    for (const property in data) {
        // console.log("qwererewrewrwerwe", property,data?.File?.length,property === 'File' && data?.File?.length > 3,property  && data?.File?.length );
        if (arr.includes(property) && isEmpty(data[property])) {
            validationerr[property] = `${property} validation failed`
        }
        // console.log("sdsdafjsdksdsdmnljflsdkj",property,data.CollectionName);
        if (property === 'CollectionName' && data?.CollectionName?.match(/\ /)) {
            validationerr[property] = `${property} enter without space (you can use underscore)`
        }
        if (property === 'File' && data?.File?.length < 2) {
            validationerr[property] = `${property} folder must contain more images`
        }
        // if(property === 'File' && data?.File?.length < 200) {
        //     validationerr[property] = `${property} folder must contain 200 images`
        // }
        // if(property === 'CollectionName' && data.CollectionName == null || data.CollectionName == "") 
        //     validationerr[property] = `${property} is Requried`
        if (property === 'File' && data[property] === null)
            validationerr[property] = `${property} is Required`
        if (property === 'Profile' && data[property] === null)
            validationerr[property] = `${property} is Required`
        if (property === 'Coverimg' && data[property] === null)
            validationerr[property] = `${property} is Required`
        if (property === 'Excel' && data[property] === null)
            validationerr[property] = `${property} is Required`


    }
    if (isEmpty(validationerr)) {
        DBnamecheck = await SolBoundNameValidate(data.CollectionName)
        // console.log("jkbjbbfjnejknka", DBnamecheck)
        if (!(DBnamecheck.Success)) {
            validationerr.name = DBnamecheck.Message
            validationerr.Success = DBnamecheck.Success
            return validationerr
        }
        else {
            validationerr.Success = DBnamecheck.Success
            return validationerr
        }
    }
    else {
        validationerr.Success = false
        return validationerr
    }
}

/**
 * Processes the validation of NFT token data for creating, editing, selling, buying or auctioning an NFT.
 *
 * Validates the provided `data` object by checking if all required fields are present and
 * in the correct format. If a field is missing or has an incorrect format, an error message
 * is added to the `validationerr` object under the field name as the key. If all fields are
 * valid, it checks if a token with the same name already exists in the database and returns
 * the validation result as an object with a `Success` key.
 *
 * @param {Object} data - Object containing the NFT token details to be validated.
 * @returns {Object} Validation result with a `Success` key.
 * @throws Will return an object with a `Success` key set to `false` if any of the fields are invalid.
 */
export const EventsValidation = async (data) => {
    console.log("EventsValidation is comming data", data);
    var validationerr = {}
    var DBnamecheck = null
    var arr = ['curraddress', 'domainname']
    var regex1 = /[^a-z]/g;
    var regex2 = /^(?:[A-Za-z]+)(?:[A-Za-z0-9 _]*)$/;
    for (const property in data) {
        console.log("sdsdafjsdksdsdmnljflsdkj", property, data.domainname);
        if (property === 'domainname' && data?.domainname?.match(/\ /)) {
            validationerr[property] = `${property} enter without space`
        }
        if (property === 'domainname' && data?.domainname?.match(regex1)) {
            validationerr[property] = `${property} enter without any special charaters,numbers and capital letters`
        }
        if (property === 'domainname' && data[property] === "")
            validationerr[property] = `${property} is Required`
        if (property === 'template' && data[property] === "")
            validationerr[property] = `${property} is Required`
        if (property === 'country' && data[property] === "")
            validationerr[property] = `${property} is Required`
        if (data.template !== "Fractional/Loyalty Page" && property === 'NoOfTreasure' && data[property] === 0)
            validationerr[property] = `${property} Must be greater than zero`
        if (data.template !== "Fractional/Loyalty Page" && property === 'mysteryNft' && data[property] === 0)
            validationerr[property] = `${property} Must be greater than zero`
        if (property === 'campaignname' && data[property] === "")
            validationerr[property] = `${property} is Required`
    }
    // if (data.CreateStatus == "Create") {
    if (isEmpty(validationerr)) {
        DBnamecheck = await TittleValidate(data.campaignname, data.campaigncode, data.CreateStatus)
        console.log("jkbjbbfjnejknka", DBnamecheck)
        if (!(DBnamecheck.Success)) {
            validationerr.campaignname = DBnamecheck.Message
            validationerr.Success = DBnamecheck.Success
            return validationerr
        }
        else {
            validationerr.Success = DBnamecheck.Success
            return validationerr
        }
    }
    if (isEmpty(validationerr)) {
        DBnamecheck = await DomainNameValidate(data.domainname, data.campaigncode, data.CreateStatus)
        console.log("jkbjbbfjnejknka", DBnamecheck)
        if (!(DBnamecheck.Success)) {
            validationerr.domainname = DBnamecheck.Message
            validationerr.Success = DBnamecheck.Success
            return validationerr
        }
        else {
            validationerr.Success = DBnamecheck.Success
            return validationerr
        }
    }
    else {
        validationerr.Success = false
        return validationerr
    }
    // } else {
    //     if(!isEmpty(validationerr)){
    //         validationerr.Success = false
    //         return validationerr
    //     }else{
    //         validationerr.Success = true
    //         return validationerr 
    //     }

    // }
}

/**
 * Validates data for voting.
 *
 * This asynchronous function checks the provided `data` object for required fields and formats.
 * It validates fields such as 'VotingCat', 'ShortName', 'SellAmount', 'Description', and 'VotingPercent'
 * by ensuring they are not empty or have correct values. If validation errors are found, they are stored
 * in the `validationerr` object. If no validation errors are detected, the function returns the validation
 * result with a `Success` key set to `true`. If validation errors are detected, the `Success` key is set
 * to `false`.
 *
 * @param {Object} data - Object containing the details of the voting to be validated.
 * @returns {Object} - An object with validation results, including a `Success` key.
 * @throws Will return an object with `Success` set to `false` if any fields are invalid.
 */
export const VotingValidation = async (data) => {
    console.log("VotingValidation is comming data", data);
    var validationerr = {}
    var arr = ['VotingCat', 'ShortName']
    for (const property in data) {
        if (arr.includes(property) && isEmpty(data[property])) {
            validationerr[property] = `${property} validation failed`
        }
    }
    if (data.VotingCat == "Selling Vote" && (data.SellAmount == 0)) {
        validationerr.SellAmount = "Sell Amount must be greater than zero"
    }
    if (data.CreateStatus == "Create" && data.Description == "") {
        validationerr.Description = "Description Validation Failed"
    }
    if (data.CreateStatus == "Update" && data.VoteMsg == "") {
        validationerr.Description = "Description Validation Failed"
    }
    if (data.promote == 0) {
        validationerr.VotingPercent = "Voting percentage must be greater than zero"
    }

    if (isEmpty(validationerr)) {
        validationerr.Success = true
        return validationerr
    }
    else {
        validationerr.Success = false
        return validationerr
    }
}