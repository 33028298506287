import { combineReducers } from 'redux';
export const Account_Connect = 'Account_Connect';
export const Account_disConnect = 'Account_disConnect';

const initial_wallet_connect = {
  UserAccountAddr: '',
  LoginAddresss: '',
  LoginMail: '',
  UserAccountBal: 0,
  providerss: null,
  Service_Fee: 0,
  Service_Fee_Fraction: 0,
  Web3Pro: null,
  Wen_Bln: 0,
  Wall: "",
  Accounts: '',
  shareTag: '',
  Social: '',
  Category: '',
  CategorySBT: '',
  CategoryFractional: '',
  WalletConnected: '',
  AddressUserDetails: null,
  tokenAddress: null,
  swapFee: 0,
  nftaction: "",
  tokenAddress: {},
  tokenAddressETH: {},
  tokenAddressBSC: {},
  networkoption: {},
  MyItemNFTs: [],
  MyItemNFTsMystry: [],
  Treasure: [],
  Mystry: [],
  SelectMystry: [],
  SelectedPosition: [],
  SelectedFinal: "true",
  TemplateName: "Collectible NFTs Template",
  PreviewContentShow: "false",
  TabsChoose: "Treasure",
  count: 0,
  StateChange: "one",
  FinalNfts: [],
  DupilcateFilter: [],
  DupilcateFilterMystry: [],
  DetailEvent: {},
  Update: "Create",
  theme: "DT",
  Reset: "Create",
  ScanLinks: null,
  VaultNFts: [],
  JorneyPosition: [],
  FinalInputVal: [],
  TokenLists:[],
  LMWallet: {},
  setshowvote: false,
  CreateOrEdit: "Create",
  currentFractions: {},
  CurrentVote: {},
  networkConfiguration:
  {
    currencySymbol: '',
    tokenSymbol: '',
    tokenABI: {},
    tokenAddr: {},
    singleContract: "",
    multipleContract: "",
    trade: "",
    BNBPROVIDER: "",
    networkVersion: '',
    chainId: '',
    Chainid: 0,
  }

}
console.log("cnnjdbvhcfbihvbdfsj", initial_wallet_connect);
function wallet_connect_context(state = initial_wallet_connect, action) {
  switch (action.type) {

    case Account_Connect:
      return {
        ...state,
        ...action.Account_Detail
      };
    case Account_disConnect:
      return {
        ...state,
        ...action.Account_Disconnect
      };
    default:
      return state;
  }
}
const birdApp = combineReducers({
  wallet_connect_context
});

export default birdApp;