import React, { useState } from "react";
import { Button } from "@material-ui/core";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { ipfsImageHashGet, NFTDetails2DB } from "actions/controller/tokenControl";
import { LMContactCalls, LMSignCall } from "actions/v1/token"
import { useMintReceipt } from "hooks/useReceipts";
import { useSignCall, useSignLock } from "hooks/useMethods";
import Web3Utils from 'web3-utils'
import { useSelector } from "react-redux";
import $ from "jquery";
import Web3 from 'web3';
import config from 'lib/config'
import { useHistory } from "react-router-dom";
import { convertToObject } from "typescript";
import randomInteger from 'random-int';
import Abi721 from '../../ABI/721.json'
import Abi1155 from '../../ABI/1155.json'
import AbiTOKEN from '../../ABI/token.json'
import TradeAbi from '../../ABI/market.json'

export default function MintingCard(props) {
  const { NFTDetails } = props;
  let history = useHistory()
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const [IpfsStatus, setIpfsStatus] = useState(false);
  const [MintStatus, setMintStatus] = useState(true);
  const [SignSellStatus, setSignSellStatus] = useState(true);
  const [IpfsStatusTxt, setIpfsStatusTxt] = useState("Start");
  const [MintStatusTxt, setMintStatusTxt] = useState("Start");
  const [SignTxt, SetSignTxt] = useState("Start");
  const [ApproveTrade, setApproveTrade] = useState('Start');
  const [SignatureHash, setSignatureHash] = useState('');
  const [NonceHash, setNonceHash] = useState(0);
  const [PurchaseCallStatus, setPurchaseCallStatus] = useState('')
  const [RandomName, setRandomName] = useState('')
  const [CloseStatus, setCloseStatus] = useState('Start')

  const IPFS = async () => {
    const id = toast.loading("Generating IPFS Data");
    setIpfsStatus(true);
    setIpfsStatusTxt("On progress");
    var result = await ipfsImageHashGet(NFTDetails, NFTDetails.File, Wallet_Details.UserAccountAddr);
    setTimeout(() => {
      if (result.Success) {
        setIpfsStatusTxt("Done");
        setMintStatus(false)
        NFTDetails.Ipfs = `${config.IPFS_IMG}/${result.data.ipfsmetadata}`
        NFTDetails.IpfsImage = result.data.ipfsimage
        NFTDetails.metajson = result.data.MetFileName
        NFTDetails.ImageName = result.data.ImageName
        NFTDetails.additionalImage = result.data.additionalImage
        toast.update(id, { render: result.Message, type: "success", isLoading: false, autoClose: 2500 });
      } else {
        setIpfsStatus(false);
        setIpfsStatusTxt("Try again");
        toast.update(id, { render: result.Message, type: "error", isLoading: false, autoClose: 2500 });
      }
    }, 1000);
  };


  ///// ---------> old Mint call

  // const MintCall = async()=>{
  //       const id = toast.loading("Awaiting Response");
  //       setMintStatusTxt("On Progress")
  //       setMintStatus(true)
  //       try{
  //       var Blockresp = await useMintReceipt(NFTDetails,NFTDetails.type,Wallet_Details)
  //       if(Blockresp && Blockresp.status)
  //       {
  //         var HexString = Blockresp.logs[1].topics[2];
  //         const TokenID = Web3Utils.hexToNumber(HexString);
  //         Object.assign(NFTDetails,{TokenID:TokenID,Status:Blockresp.status,Coinname:config.currencySymbol,HashValue:Blockresp.transactionHash})
  //         await NFTDetails2DB(NFTDetails,NFTDetails.File,Wallet_Details.UserAccountAddr) 
  //         toast.update(id, {render: "NFT Minted Successfully",type: "success",isLoading:false,autoClose:2000});
  //         setMintStatusTxt("Done")
  //         setSignSellStatus(false)
  //       }
  //       else
  //       {
  //         toast.update(id, {render: "Failed To Confirm",type: "error", isLoading:false,autoClose:2000});
  //         setMintStatusTxt("Try Again")
  //         setMintStatus(false)
  //       }
  //       console.log("respformminthook",Blockresp)
  //     }
  //     catch(e)
  //     {
  //       toast.update(id, {render: "Failed to Confirm",type: "error",isLoading:false,autoClose:2000});
  //       console.log("respformminthook",e)
  //       setMintStatusTxt("Try Again")
  //       setMintStatus(false)
  //     }
  // }

  const MintCall = async () => {
    const id = toast.loading("Awaiting Response");
    setMintStatusTxt("On Progress")
    setMintStatus(true)
    // console.log("to contract mintcall", NFTDetails)

    try {
     
        // var HexString = Blockresp.logs[1].topics[2];
        // const TokenID = Web3Utils.hexToNumber(HexString);
        // console.log("nftdetial obj before",NFTDetails)
        // Object.assign(NFTDetails,{Coinname:config.currencySymbol})
        var dummyTokenId = Date.now()

        Object.assign(NFTDetails, { TokenID: dummyTokenId, Status: true, HashValue: "not yet minted" })

        console.log("nftdetial obj after", NFTDetails)

       const NFTMint = await NFTDetails2DB(NFTDetails, NFTDetails.File, Wallet_Details.UserAccountAddr, RandomName, NonceHash, SignatureHash)
       console.log("NFTMint",NFTMint);
      if (NFTMint.Success) {
        toast.update(id, { render: "NFT Minted Successfully", type: "success", isLoading: false, autoClose: 2000 });
        setMintStatusTxt("Done")
        setSignSellStatus(false)
        $('#ClosingMintCard').trigger("click");
        history.push('/my-items');
      }
      else {
        toast.update(id, { render: "Failed To Confirm", type: "error", isLoading: false, autoClose: 2000 });
        setMintStatusTxt("Try Again")
        setMintStatus(false)
      }
    }
    catch (e) {
      toast.update(id, { render: "Failed to Confirm", type: "error", isLoading: false, autoClose: 2000 });
      setMintStatusTxt("Try Again")
      setMintStatus(false)
    }
  }

  async function getapproveFun() {
    try {
      setApproveTrade("On progress")
      var web3 = new Web3(Wallet_Details.providerss);
      var chain = await web3.eth.getChainId()
      var address = NFTDetails.type == '721' ? Wallet_Details.networkConfiguration.singleContract : Wallet_Details.networkConfiguration.multipleContract
      if (localStorage.walletConnectType !== "LMWallet") {
        var ConnectContract = await new web3.eth.Contract(NFTDetails.type == '721' ? Abi721 : Abi1155, address);
        var encoded = await ConnectContract.methods.setApprovalForAll(Wallet_Details.networkConfiguration.TradeContract, true).encodeABI();
        var gasPrice = await Wallet_Details.Web3Pro.eth.getGasPrice();
        const Transcation = {
          from: Wallet_Details.UserAccountAddr,
          to: address,
          data: encoded,
        };
        var gasLimit = await Wallet_Details.Web3Pro.eth.estimateGas(Transcation);
        Transcation["gas"] = gasLimit;
        if (chain == config.MATIC_ID) {
          var contract_Method_Hash = await ConnectContract.methods
            .setApprovalForAll(Wallet_Details.networkConfiguration.TradeContract, true)
            .send({ from: Wallet_Details.UserAccountAddr, gasLimit: parseInt(gasLimit * 1.5, 10), gasPrice: gasPrice }).on('transactionHash', async (transactionHash) => {
              console.log("transactionhash after useSoulBoundMintTransfer", transactionHash)
              return transactionHash
            })
        } else {
          var contract_Method_Hash = await
            ConnectContract
              .methods
              .setApprovalForAll(Wallet_Details.networkConfiguration.TradeContract, true)
              .send({
                from: Wallet_Details.UserAccountAddr
              }).on('transactionHash', (transactionHash) => {
                return transactionHash
              })
        }
        var HashValue = contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash
        var web3InRPC = new Web3(Wallet_Details.networkConfiguration.BNBPROVIDER);
        var receipt = await web3InRPC.eth.getTransactionReceipt(HashValue);
      } else {
        var Arg = {
          Method: "setApprovalForAll",
          Data: [Wallet_Details.networkConfiguration.TradeContract, true],
          Mail: localStorage.loggedinaddress,
          LoginAddress: localStorage.userAddress,
          ContactAddress: Wallet_Details.networkConfiguration.TradeContract,
          Type: NFTDetails.type == 721 ? "721" : "1155",
          Chain: String(chain),
          IntractContract: address
        }
        var receipt = await LMContactCalls(Arg)
        receipt = receipt.data.receipt
      }
      console.log("receipt,receipt",receipt);
      var need_data = {
        status: receipt.status,
        HashValue: receipt.transactionHash,
      }
      console.log("need_data",need_data);
      if (need_data.status === true) {
        setApproveTrade("done")
      }
      else {
        setApproveTrade("Try again")
      }
      return need_data;
    } catch (e) {
      setApproveTrade("Try again")
    }
  }


  async function signcall() {
    try {
      var generator = require('generate-password');
      var randomNum = randomInteger(10000000, 100000000);
      var password = generator.generate({
        length: 10,
        numbers: true
      });
      var web3RpcPro = new Web3(Wallet_Details.networkConfiguration.RPC_URL)
      var chain = NFTDetails.NetworkName == "polygon" ? config.MATIC_ID : NFTDetails.NetworkName == "camino" ? config.CAM_ID : config.ETH_ID
      const to = Wallet_Details.UserAccountAddr
      const _amount = NFTDetails.TokenPrice == "" || NFTDetails.TokenPrice == undefined ? 0 : web3RpcPro.utils.toWei(String(NFTDetails.TokenPrice));
      const _nonce = Date.now();
      setRandomName(password)
      var tot = _nonce + Number(randomNum);
      setNonceHash(tot);
      if (localStorage.walletConnectType !== "LMWallet") {
        if (Wallet_Details.Web3Pro) {
          var web3Rpc = new Web3(Wallet_Details.providerss)
          if (web3Rpc) {
            SetSignTxt('On progress')
            setCloseStatus('On progress')
            var web3RpcPro = new Web3(Wallet_Details.providerss);
            const result = web3RpcPro.utils.soliditySha3(to, _amount, password, tot);
            const signhash = await Wallet_Details.Web3Pro.eth.personal.sign(result, to);
            if (signhash) {
              SetSignTxt('done')
              setPurchaseCallStatus('init')
              setSignatureHash(signhash);
            }
          }
        }
      }
      else {
        var Arg = {
          to: to,
          _amount: _amount,
          password: password,
          tot: tot,
          Type: NFTDetails.type == 721 ? "721" : "1155",
          Chain: String(chain),
          Mail: localStorage.loggedinaddress,
          LoginAddress: localStorage.userAddress,
          Key: null
        }
        var receipt = await LMSignCall(Arg)
        if (receipt.data.Status) {
          SetSignTxt('done')
          setPurchaseCallStatus('init')
          setSignatureHash(receipt.data.signedTx);
        }
      }
    } catch (e) {
      SetSignTxt('Try again')
    }
  }


  return (
    <>
      {/* create_item Modal */}
      <div
        class="modal fade primary_modal"
        id="create_item_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="create_item_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="create_item_modalLabel">
                Follow Steps
              </h5>
              <button
                type="button"
                className={CloseStatus == "On progress" ? "close d-none" : "close"}
                data-dismiss="modal"
                id="ClosingMintCard"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                {!NFTDetails?.check &&
                  <div className="media approve_media">
                    {ApproveTrade === "Try again" ? (
                      <i
                        className="fas fa-exclamation-triangle mr-3 text-danger"
                        aria-hidden="true"
                      ></i>
                    ) : ApproveTrade === "done" ? (
                      <i
                        className="fas fa-check mr-3 pro_complete"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i className="fas fa-check mr-3" aria-hidden="true"></i>
                    )}
                    <div className="media-body">
                      <p className="mt-0 approve_text">Get Approve</p>
                    </div>
                  </div>
                }
                {!NFTDetails?.check &&
                  <div className="text-center my-3">
                    <Button
                      className="create_btn btn-block"
                      onClick={() => getapproveFun()}
                      disabled={(ApproveTrade === 'On progress' || ApproveTrade === 'done')}
                    >
                      {ApproveTrade == "On progress" && (
                        <i
                          class="fa fa-spinner mr-3 spinner_icon"
                          aria-hidden="true"
                        ></i>
                      )}
                      {ApproveTrade}
                    </Button>
                  </div>
                }

                <div className="media approve_media">
                  {SignTxt === "Try again" ? (
                    <i
                      className="fas fa-exclamation-triangle mr-3 text-danger"
                      aria-hidden="true"
                    ></i>
                  ) : SignTxt === "done" ? (
                    <i
                      className="fas fa-check mr-3 pro_complete"
                      aria-hidden="true"
                    ></i>
                  ) : (
                    <i className="fas fa-check mr-3" aria-hidden="true"></i>
                  )}
                  <div className="media-body">
                    <p className="mt-0 approve_text">Sign transaction</p>
                    <p className="mt-3 purchase_desc"></p>
                  </div>
                </div>
                <div>
                  <Button
                    type="button"
                    onClick={() => signcall()}
                    className={"create_btn btn-block"}
                    disabled={(ApproveTrade === 'On progress') || (SignTxt === "done") || (SignTxt === "On progress") || (NFTDetails?.check == false ? ApproveTrade !== 'done' : ApproveTrade === 'done')}
                  >
                    {SignTxt == "On progress" && (
                      <i
                        class="fa fa-spinner mr-3 spinner_icon"
                        aria-hidden="true"
                      ></i>
                    )}
                    {SignTxt}
                  </Button>
                </div>
                <div className="media approve_media">
                  {IpfsStatusTxt === "Try again" ? (
                    <i
                      className="fas fa-exclamation-triangle mr-3 text-danger"
                      aria-hidden="true"
                    ></i>
                  ) : IpfsStatusTxt === "Done" ? (
                    <i
                      className="fas fa-check mr-3 pro_complete"
                      aria-hidden="true"
                    ></i>
                  ) : (
                    <i className="fas fa-check mr-3" aria-hidden="true"></i>
                  )}
                  <div className="media-body">
                    <p className="mt-0 approve_text">IPFS MetaData</p>
                    <p className="mt-0 approve_desc">
                      Generates IPFS Metadata for Your NFT
                    </p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button
                    className="create_btn btn-block"
                    onClick={() => IPFS()}
                    disabled={SignTxt !== "done" || IpfsStatusTxt === "Done"}
                  >
                    {IpfsStatusTxt == "On progress" && (
                      <i
                        class="fa fa-spinner mr-3 spinner_icon"
                        aria-hidden="true"
                      ></i>
                    )}
                    {IpfsStatusTxt}
                  </Button>
                </div>
                <div className="media approve_media">
                  {MintStatusTxt === "Try again" ? (
                    <i
                      className="fas fa-exclamation-triangle mr-3 text-danger"
                      aria-hidden="true"
                    ></i>
                  ) : MintStatusTxt === "Done" ? (
                    <i
                      className="fas fa-check mr-3 pro_complete"
                      aria-hidden="true"
                    ></i>
                  ) : (
                    <i className="fas fa-check mr-3" aria-hidden="true"></i>
                  )}
                  <div className="media-body">
                    <p className="mt-0 approve_text">
                      Upload files & Mint token
                    </p>
                    <p className="mt-0 approve_desc">Call contract method</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button
                    className="create_btn btn-block"
                    disabled={MintStatus}
                    onClick={() => MintCall()}
                  >
                    {MintStatusTxt == "On progress" && (
                      <i
                        class="fa fa-spinner mr-3 spinner_icon"
                        aria-hidden="true"
                      ></i>
                    )}
                    {MintStatusTxt}
                  </Button>
                </div>

                {/* <div className="media approve_media">
                {SignLockStatusTxt === "Try again" ? (
                    <i
                      className="fas fa-exclamation-triangle mr-3 text-danger"
                      aria-hidden="true"
                    ></i>
                  ) : SignLockStatusTxt === "Done" ? (
                    <i
                      className="fas fa-check mr-3 pro_complete"
                      aria-hidden="true"
                    ></i>
                  ) : (
                    <i className="fas fa-check mr-3" aria-hidden="true"></i>
                  )}
                  <div className="media-body">
                    <p className="mt-0 approve_text">Sign lock order</p>
                    <p className="mt-0 approve_desc">
                      Sign lock order using tour wallet
                    </p>
                  </div>
                </div>
                <div className="text-center mt-3">
                  <Button
                    className="create_btn btn-block"
                    onClick={()=>SignLock()}
                    disabled={SignLockStatus}
                  >
                     {SignLockStatusTxt == "On progress" && (
                      <i
                        class="fa fa-spinner mr-3 spinner_icon"
                        aria-hidden="true"
                      ></i>
                    )}
                    {SignLockStatusTxt}
                  </Button>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end create_item modal */}
    </>
  );
}

