import React, {
    useEffect,
    useState,
    forwardRef,
    useImperativeHandle,
} from "react";
import Web3 from 'web3';
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import config from "lib/config";
import $ from "jquery"
import { ContactMintFraction } from "actions/v1/token";
import { v1_TransferFraction } from "actions/v1/Fractional"
import { useTokenFractionApprove } from "hooks/useReceipts";
import { useNFTBalCheckToken } from "hooks/useMethods";
import { v1_ConNFTBalCheck, LMBalanceOfFraction } from "actions/v1/token";
import { network } from "../../views/network"
import { connectWallet, getServiceFees } from '../../hooks/useWallet';
import { Account_Connect } from "actions/redux/action";
// import chargesheets from "../assets/images/SampleExcelSheet (13).xlsx";
import chargesheets from "../../assets/images/SampleExcelSheet (13).xlsx"
// import chargesheets from '../assets/images/lazymintingnew/Untitled_spreadsheet.xlsx';

export const TransferNFT = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const Wallet_Details = useSelector((state) => state.wallet_connect_context);
    const [BurnState, setBurnState] = useState(false);
    const [NoofToken, setNoofToken] = useState(0)
    const [NFTImage, setNFTImage] = useState("");
    const [NFTDet, setNFTDet] = useState({});
    const [NFT, setNFT] = useState({ CurrentOwner: {} });
    const [WallAddress, setWallAddress] = useState("")
    const [NFTFile, setNFTFile] = useState(null);
    const history = useHistory()

    useImperativeHandle(ref, () => ({
        async TransferNFTClick(ClickedNFT, NFTimage, NFT) {
            var chainid = NFT.SelectedNetwork
            const chainId = await Wallet_Details.Web3Pro.eth.getChainId();
            var networkConfiguration = {}
            if (Number(chainid) === network.MATIC.Chainid) {
                networkConfiguration = network.MATIC
            }
            else if (Number(chainid) === network.ETH.Chainid) {
                networkConfiguration = network.ETH
            }
            else if (Number(chainid) === network.CAM.Chainid) {
                networkConfiguration = network.CAM
            }
            var accountDetailsss = ''
            Serfee(chainid);
            Wallet_Details.networkConfiguration = networkConfiguration
            if (Number(chainId) == Number(NFT.SelectedNetwork)) {
                accountDetailsss = true
            }
            else {
                var accountDetailsss = await switchNetwork(chainid, networkConfiguration)
            }
            setNFTImage(NFTimage);
            setNFTDet(NFT);
            if (Number(chainid) === Number(chainId)) {
                if (Wallet_Details.UserAccountAddr) {
                    window.$('#Transfer_modal').modal('show');
                    setNFT(ClickedNFT);
                }
            }
        },
    }));

    async function switchNetwork(configdata) {
        var type = ""
        var networkConfiguration = {}
        const chainId = await Wallet_Details.Web3Pro.eth.getChainId();
        if (localStorage.walletConnectType !== "Torus") {
            if (Number(configdata) !== Number(chainId)) {
                const id = toast.loading("Switching Network", { closeButton: true, theme: "dark" });
                if (configdata) {
                    if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask') {
                        type = "MetaMask"
                    }
                    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'WalletConnect' && localStorage.walletConnectType != null) {
                        type = "WalletConnect"
                    }
                    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'Torus' && localStorage.walletConnectType != null) {
                        type = "Torus"
                    }
                    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'TorusWallet' && localStorage.walletConnectType != null) {
                        type = "TorusWallet"
                    }
                    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'LMWallet' && localStorage.walletConnectType != null) {
                        console.log("adasd comming on torus");
                        type = "LMWallet"
                        localStorage.setItem("ChainId", NFTDet.SelectedNetwork)
                    }
                    var accountDetails = await connectWallet(type, Number(configdata), localStorage.walletConnectType == "LMWallet" ? "register" : "Create", Wallet_Details, "Tor")
                    const chainId = await accountDetails?.web3?.eth?.getChainId();
                    if (Number(configdata) === network.MATIC.Chainid) {
                        networkConfiguration = network.MATIC
                    }
                    else if (Number(configdata) === network.ETH.Chainid) {
                        networkConfiguration = network.ETH
                    }
                    else if (Number(configdata) === network.CAM.Chainid) {
                        networkConfiguration = network.CAM
                    }
                    if (accountDetails != '') {
                        dispatch({
                            type: Account_Connect,
                            Account_Detail: {
                                UserAccountAddr: accountDetails.accountAddress,
                                UserAccountBal: accountDetails.coinBalance,
                                WalletConnected: "true",
                                Wen_Bln: accountDetails.tokenBalance,
                                Accounts: accountDetails.accountAddress,
                                Web3Pro: accountDetails?.web3,
                                providerss: accountDetails?.web3?._provider,
                                networkConfiguration: networkConfiguration
                            }
                        })
                        Serfee(networkConfiguration.Chainid);
                    }
                    if (networkConfiguration.currencySymbol) {
                        toast.update(id, {
                            render: "Network switched Succesfully",
                            type: "success",
                            isLoading: false,
                            autoClose: 2500,
                            closeButton: true,
                            theme: "dark"
                        });
                    }
                }
                return accountDetails
            } else {
                dispatch({
                    type: Account_Connect,
                    Account_Detail: {
                        UserAccountAddr: Wallet_Details?.UserAccountAddr,
                        UserAccountBal: Wallet_Details?.UserAccountBal,
                        WalletConnected: "true",
                        Wen_Bln: Wallet_Details?.Wen_Bln,
                        Accounts: Wallet_Details?.Accounts,
                        Web3Pro: Wallet_Details?.Web3Pro,
                        providerss: Wallet_Details?.providerss,
                        networkConfiguration: networkConfiguration
                    }
                })
                Serfee(networkConfiguration.Chainid);
                return Wallet_Details.Web3Pro
            }
        } else {
            try {
                const id = toast.loading("Switching Network", { closeButton: true, theme: "dark" });
                var accountDetails = await connectWallet(localStorage.walletConnectType, Number(configdata), "Create", Wallet_Details, "Torus")
                const chainId = await accountDetails?.web3?.eth?.getChainId();
                if (Number(configdata) === network.MATIC.Chainid) {
                    networkConfiguration = network.MATIC
                }
                else if (Number(configdata) === network.ETH.Chainid) {
                    networkConfiguration = network.ETH
                }
                else if (Number(configdata) === network.CAM.Chainid) {
                    networkConfiguration = network.CAM
                }
                if (accountDetails != '') {
                    dispatch({
                        type: Account_Connect,
                        Account_Detail: {
                            UserAccountAddr: accountDetails?.accountAddress,
                            UserAccountBal: accountDetails?.coinBalance,
                            WalletConnected: "true",
                            Wen_Bln: accountDetails?.tokenBalance,
                            Accounts: accountDetails?.accountAddress,
                            Web3Pro: accountDetails?.web3,
                            providerss: accountDetails?.web3?._provider,
                            networkConfiguration: networkConfiguration
                        }
                    })
                }
                var functiobn = await NefunctionTriger(Wallet_Details)
                if (networkConfiguration.currencySymbol) {
                    toast.update(id, {
                        render: "Network switched Succesfully",
                        type: "success",
                        isLoading: false,
                        autoClose: 2500,
                        closeButton: true,
                        theme: "dark"
                    });
                }
            } catch (e) {
                console.log("Switch Network Catch Console", e);
            }
        }
    }

    const NefunctionTriger = async (Wallet_Details1) => {
        const chainIdSwitched = await Wallet_Details1?.Web3Pro?.eth?.getChainId();
        var balance = await Wallet_Details1?.Web3Pro?.eth.getBalance(Wallet_Details1.UserAccountAddr);
        return chainIdSwitched
    }

    const Serfee = async (Address) => {
        var Fee = await getServiceFees(Address)
        dispatch({
            type: Account_Connect,
            Account_Detail: {
                Service_Fee: Fee?.Serfee,
                Service_Fee_Fraction: Fee?.SerfeeFraction
            }
        })
    }

    const BalCheck = async () => {
        var Arg = {
            NFTConAddress: NFTDet.ERC20_Address,
            NFTId: NFT.tokenID,
            NFTOwner: NFT.tokenOwner,
            Status: NFT.LazyStatus,
            BulK: NFTDet.Bulkmint
        }
        var Type = NFT.type
        if (localStorage.walletConnectType !== "LMWallet") {
            var ContractNFTBal = await NFTHookFun(Arg, Wallet_Details)
        } else {
            var key = null
            var Argument = [Arg.NFTOwner]
            var ContractNFTBal = await LMBalanceCheck(Arg, Type, Wallet_Details, Arg.NFTConAddress, Type == 721 ? "Single" : "Multiple", Arg.NFTConAddress, "balanceOf", Argument, key, Arg.NFTOwner)
        }
        console.log("fadfafasdasd", ContractNFTBal);
        if (Number(ContractNFTBal) !== NFT.balance) {
            Arg.balance = Number(ContractNFTBal)
            var Updated = await v1_ConNFTBalCheck(Arg)
            if (Updated.data && Updated.data.Success) {
                $('#Transfer_closs').trigger("click");
                toast.error("Owners NFT Balance Changed. Try Later", { autoClose: 4000, closeButton: true })
                setTimeout(() => {
                    history.push('/explore/All')
                }, 1500);
            }
            else {
                $('#Transfer_closs').trigger("click");
                toast.error("Error Occured. Try Later", { autoClose: 3000, closeButton: true })
                setTimeout(() => {
                    history.push('/explore/All')
                }, 1500);
            }
        }
        else {
            if (NFT.LazyStatus !== "pending") {
                var TransferAddress = WallAddress.toLowerCase();
                var web3 = new Web3()
                var validation = web3.utils.isAddress(TransferAddress)
                if (validation == true) {
                    ApproveTokenCall()
                } else {
                    toast.error("Please Enter Valid User Address(Metamask Address)")
                }

            } else {
                var TransferAddress = WallAddress.toLowerCase();
                var web3 = new Web3()
                var validation = web3.utils.isAddress(TransferAddress)
                if (validation == true) {

                    ApproveTokenCall()

                } else {
                    toast.error("Please Enter Valid User Address(Metamask Address)")
                }
            }
        }

    }

    const NFTHookFun = async (Arg, Wallet_Details) => {
        var ContractNFTBal = await useNFTBalCheckToken(Arg, Wallet_Details)
        return ContractNFTBal
    }

    const handleKeyDown = (e) => {
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            e.preventDefault();
        }
    };

    const LMBalanceCheck = async (Arg, Type, Wallet_Details, IntractContact, NameUse, Contract, method, data, Key, tokenOwner) => {
        console.log("LMBalanceCheck", Arg, Type, Wallet_Details, IntractContact, NameUse, Contract, method, data, Key);
        var Arg = {
            Method: method,
            Data: data,
            Mail: localStorage.loggedinaddress,
            LoginAddress: localStorage.userAddress,
            ContactAddress: Contract,
            Type: String(Type),
            Chain: String(NFTDet.SelectedNetwork),
            IntractContract: IntractContact,
            ContactType: NameUse,
            value: 0,
            Key: Key,
            tokenOwner: tokenOwner
        }
        var Balance = await LMBalanceOfFraction(Arg)
        console.log("Balance", Balance);
        return Balance.data.receipt
    }

    const ApproveTokenCall = async () => {
        console.log("NFTDetails.ERC20", NFT, NFTDet);
        const id = toast.loading("Awaiting Response");
        var Arg = [WallAddress.toLowerCase(), NoofToken]
        var Provider = Wallet_Details
        if (localStorage.walletConnectType !== "LMWallet") {
            var Receipt = await ContactHookTokenApp(Arg, Provider, "transfer", NFT.ERC20_Address)
        } else {
            var Key = null
            var Receipt = await LMWalletCall(Arg, Provider, "transfer", "Token", Key, NFTDet.ERC20_Address)
        }
        if (Receipt.status) {
            if (Receipt) {
                var Burn = { Type: NFT.type, Creater: NFT.tokenOwner, Owner: WallAddress.toLowerCase(), TokeninitialCreator: NFT.tokenCreator, id: NFT.tokenID, ConAddr: NFT.contractAddress, NoOfToken: NoofToken, Status: 'true', Hash: Receipt.transactionHash, Network: NFTDet.SelectedNetwork, QR_tokenID: NFT.QR_tokenID, ERC20_Address: NFTDet.ERC20_Address, ERCTrade: NFTDet.ERC20_Address }
                var Resp = await v1_TransferFraction(Burn)
                console.log("v1_TransferFraction", Resp);
                if (Resp.data) {
                    toast.update(id, { render: "Token Transfer Successfully", type: "success", isLoading: false, autoClose: 2500, });
                    $('#Transfer_closs').trigger("click");
                    setTimeout(() => {
                        history.push('/my-items')
                    }, 1000);
                } else {
                    setBurnState(false)
                    toast.update(id, {
                        render: "Token Tranfer Failed",
                        type: "error",
                        isLoading: false,
                        autoClose: 2500,
                    });
                }
            } else {
                toast.update(id, { render: "Failed to Transfer.", type: "error", isLoading: false, autoClose: 2000 });
            }
        } else {
            // toast.error("Try it Again Later.Please Check Your Funds")
            toast.update(id, { render: "Failed to Approve.", type: "error", isLoading: false, autoClose: 2000 });
        }
    }

    const ContactHookTokenApp = async (Arg, Provider, Method, TokenAddress) => {
        var Receipt = await useTokenFractionApprove(Arg, Provider, Method, TokenAddress)
        return Receipt
    }

    const LMWalletCall = async (Arg, Wallet_Details, method, NameUse, Key, TokenAddress) => {
        var receipt = null
        var Arg = {
            Method: method,
            Data: Arg,
            Mail: localStorage.loggedinaddress,
            LoginAddress: localStorage.userAddress,
            ContactAddress: Wallet_Details.networkConfiguration.FractionTradeContract,
            Chain: String(NFTDet.SelectedNetwork),
            ContactType: NameUse,
            IntractContract: Wallet_Details.networkConfiguration.FractionTradeContract,
            Key: Key,
            Status: false,
            TokenAddress: TokenAddress
        }
        console.log("asdasdasdasdasda", Arg);
        var Receiptfun = await ContactMintFraction(Arg)
        return Receiptfun.data.receipt
    }

    return (
        <div
            className="modal fade primary_modal"
            id="Transfer_modal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="cancel_order_modalCenteredLabel"
            aria-hidden="true"
            data-backdrop="static"
            data-keyboard="false"
        >
            <div
                className="modal-dialog modal-dialog-centered modal-sm"
                role="document"
            >
                <div className="modal-content">
                    <div className="modal-header text-center">
                        <h5 className="modal-title text-danger" id="cancel_order_modalLabel">
                            Transfer Token
                        </h5>
                        {!NFTDet ? (
                            <>
                                <TailSpin
                                    wrapperClass="reactloader mt-0 mb-3"
                                    color="#00BFFF"
                                    height={100}
                                    width={70}
                                />
                            </>
                        ) : (
                            <>
                                <div>
                                    <div className="change_price_img_div">
                                        {NFTDet.image &&
                                            NFTDet.image.split(".").pop() == "mp4" ? (
                                            <video
                                                id="my-video"
                                                class="img-fluid"
                                                autoPlay
                                                playsInline
                                                loop
                                                muted
                                                preload="auto"
                                            >
                                                <source src={NFTImage && NFTImage} type="video/mp4" />
                                            </video>
                                        ) : (
                                            <img
                                                src={
                                                    NFTImage
                                                        ? NFTImage
                                                        : require("../../assets/images/masonary_04.png")
                                                }
                                                alt="Collections"
                                                className="img-fluid"
                                            />
                                        )}
                                    </div>
                                    <p className="text-gray font_we_600 font_14">
                                        You are about to <span className="text-danger">Transfer</span> the NFT{" "}
                                        <span className="text-danger">{NFTDet?.tokenName?.slice(0, 10)}</span>
                                    </p>
                                    <p className="text-gray font_we_600 font_14">
                                        You only own <span className="text-danger">{NFT.balance}</span> Quantity
                                    </p>

                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        id="Transfer_closs"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    {/* {config.AdminAddress.toLowerCase() == Wallet_Details.UserAccountAddr.toLowerCase() && */}
                                    {NFTDet.Bulkmint == 'Bulk' &&
                                        <Button className="gf fileindees pb-0"><p className="donwloadfiles">You can download your sample excel sheet format here</p>
                                            <a href={chargesheets} download="SampleExcelSheet"><i class="fas fa-download"></i> </a></Button>}
                                    {/* } */}
                                    <div className="form-row modaltoggles">
                                        <div className="form-group col-md-12">
                                            {/* {config.AdminAddress.toLowerCase() == Wallet_Details.UserAccountAddr.toLowerCase() && */}
                                            <div className="d-flex justify-content-between align-items-center grid_toggle mt-3">
                                                {/* <div className="text-left">
                            <label className="primary_label mb-0" htmlFor="inputEmail4">
                              Bulk Transfer
                            </label>
                            <p className="form_note">
                              Upload Your Bulk Transfer Excel Sheet
                            </p>
                          </div>
                          <label className="switch toggle_custom mb-4">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setUnlockable((prevState) => !prevState)
                              }
                            />
                            <span className="slider"></span>
                          </label> */}
                                            </div>
                                            {/* } */}
                                            {NFTDet.Bulkmint == 'Bulk' && (
                                                <div className="form-group col-md-12 px-0 mt-3">
                                                    <div className="d-flex justify-content-between align-items-start">
                                                        <div className="text-left">
                                                            {/* <p className="form_note">
                              Upload Your Bulk Transfer Excel Sheet
                            </p>  */}
                                                            <label className="primary_label" htmlFor="inputEmail4">
                                                                Upload file
                                                            </label>
                                                            <p className="form_note">
                                                                Only xlsx format
                                                            </p>

                                                        </div>
                                                        <div className="file_btn btn ">
                                                            {NFTFile == null ? "Upload" : "Uploaded"}
                                                            <input
                                                                className="inp_file"
                                                                type="file"
                                                                name="file"
                                                                autoComplete="off"
                                                                onChange={(e) => setNFTFile(e.target.files[0])}
                                                            />
                                                        </div>

                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="modal-body px-0 pt-0">
                                        <form className="px-4 bid_form">
                                            {NFTDet.Bulkmint !== 'Bulk' &&
                                                <>
                                                    <label htmlFor="bid" className="text-center">
                                                        <input
                                                            type="number"
                                                            id="bid"
                                                            className="form-control text-center modal_inp_white"
                                                            placeholder="Enter Quantity to Transfer"
                                                            aria-label="bid"
                                                            aria-describedby="basic-addon2"
                                                            onChange={(e) => setNoofToken(e.target.value)}
                                                            onKeyDown={handleKeyDown}
                                                            onWheel={(e) => e.target.blur()}
                                                        />
                                                    </label>
                                                    {((NoofToken > NFT.balance) || (NoofToken < 1)) &&
                                                        <p className="text-gray font_we_600 font_14">
                                                            Please Enter <span className="text-danger"> Valid Quantity (Max : {NFT.balance})</span>
                                                        </p>
                                                    }

                                                    <label htmlFor="bid" className="text-center">
                                                        <input
                                                            type="text"
                                                            id="bid"
                                                            className="form-control text-center modal_inp_white"
                                                            placeholder="Enter Wallet Address"
                                                            aria-label="bid"
                                                            aria-describedby="basic-addon2"
                                                            onChange={(e) => setWallAddress(e.target.value)}
                                                        />
                                                    </label>
                                                    {/* {((NoofToken > NFT.balance) || (NoofToken < 1)) &&
                          <p className="text-gray font_we_600 font_14">
                            Please Enter <span className="text-danger"> Valid Quantity (Max : {NFT.balance})</span>
                          </p>
                        } */}
                                                </>
                                            }

                                            <>
                                                <div className="text-center mt-3">
                                                    <Button
                                                        className="create_btn btn-block"
                                                        disabled={!NoofToken || (NoofToken < 1) || (NoofToken > NFT.balance) || BurnState}
                                                        onClick={() => {
                                                            BalCheck();
                                                            //mintHashcheck();    // added for lazyminter
                                                            // ApproveTokenCall()
                                                        }}
                                                    >
                                                        {BurnState && (
                                                            <i
                                                                class="fa fa-spinner mr-3 spinner_icon"
                                                                aria-hidden="true"
                                                            ></i>
                                                        )}
                                                        Transfer Token
                                                    </Button>
                                                </div>
                                            </>

                                        </form>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
})