import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from "@material-ui/core";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link, useLocation, useParams } from "react-router-dom";
import Countdown from "react-countdown";
import Select from "react-select";
import Collectioncard from "./separate/collection_card";
import CopyToClipboard from "react-copy-to-clipboard";
import Profile from "../assets/images/lazymintingnew/footer_logo.png"
import Banner from "../assets/images/lazymintingnew/myitemsprofile.jpg"
//Functionality Components
import MintingCard from "./separate/Minting";
import Calender from "../components/Calender/Calender";
import axios from "axios";
import $ from "jquery";
import { NFTValidation, ImageValidation } from "actions/controller/validation";
import { ListingGetdata ,ListingGetSoulbound } from '../actions/v1/token';

import config from "../lib/config";
import "react-toastify/dist/ReactToastify.css";
import { toast, Slide, Zoom, Flip, Bounce } from "react-toastify";
import { useSelector } from "react-redux";
toast.configure();

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return null;
}

export default function CreateSingle(props) {

    // console.log("propsssssssssspropsss", props);
    const classes = useStyles();
    const { ...rest } = props;

    const options = [
        { value: "Art", label: "Art" },
        { value: "Collectibles", label: "Collectibles" },
        { value: "Domain Names", label: "Domain Names" },
        { value: "Music", label: "Music" },
        { value: "Photography", label: "Photography" },
        { value: "Sports", label: "Sports" },
        { value: "Trading Cards", label: "Trading Cards" },
        { value: "Utility", label: "Utility" },
        { value: "Virtual Worlds", label: "Virtual Worlds" },
    ];
    const Wallet_Details = useSelector((state) => state.wallet_connect_context);
    // console.log("Wallet_DetailsWallet_Details", Wallet_Details.UserAccountAddr);
    const CreatePage = useLocation();
    const Bulkminting = CreatePage.pathname === "/Bulkminting";
    // console.log("loctionfor page path", CreatePage);
    const InitialForm = {
        NFTCategory: "",
        type: 1155,
        contractaddress: config.multipleContract,
        CollectionName: ""
    };
    const [Formdata, setFormdata] = useState(InitialForm);
    const [PutonSale, setPutonSale] = useState(false);
    const [FixedPrice, setFixedPrice] = useState(false);
    const [Auction, setAuction] = useState(false);
    const [USDPrice, setUSDPrice] = useState(0);
    const [NFTFile, setNFTFile] = useState(null);
    const [NFTFileExcel, setNFTFileExcel] = useState(null);
    const [NFTFilename, setNFTFilename] = useState(null);
    const [Coinname, setCoinname] = useState('')
    const [categorysate, Setcategorysate] = useState({})
    const [Preview, setPreview] = useState(
        require("../assets/images/lazymintingnew/footer_logo.png")
    );
    const [put, setPut] = useState("");
    const [category, Setcategory] = useState("")
    const [ValidationError, setValidationError] = useState(null)

    const [MinUSD, setMinUSD] = useState(0);
    const [ListData, SetListData] = useState({});
    var { Address, CollectionName } = useParams();
    // console.log("qwewqeqwerwqrqreqr", Address, CollectionName);
    const handleChange = () => {
        // this.setState({ selectedOption });
        // console.log(`Option selected:`, selectedOption);
    };
    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#fff",
            position: "relative",
            top: "3px",
            left: "0px",
            width: "100%",
            maxWidth: "100%",
            color: "#000"
        }),
        option: (provided, state) => ({
            ...provided,
            marginBottom: "5px",
            backgroundColor: "transparent",
            maxWidth: "356px",
            color: "#fff",
            borderBottom: "#fff",
            "&:hover": {
                transition: "all 0.5s ease",
                color: "#e50914"
            },
            "&:active": {
                transition: "all 0.5s ease",
                color: "#000"
            }
        }),
        control: (provided, state) => ({
            ...provided,
            border: "1px solid transparent",
            boxShadow: "0px 0px",
            "&:hover": {
                border: "1px solid transparent",
                boxShadow: "0px 0px",
            },
            "&:focus": {
                border: "1px solid transparent"
            }
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            border: "1px solid transparent"
        })
    }
    const renderer = ({
        days,
        Month,
        Year,
        hours,
        minutes,
        seconds,
        completed,
    }) => {
        if (completed) {
            return <span></span>;
        } else {
            return (
                <span>
                    {days}d {hours}h {minutes}m {seconds}s left
                </span>
            );
        }
    };

    useEffect(() => {
        ListCollection();
    }, [])

    const ListCollection = async () => {
        var reqData = {
            Address: Address,
            CollectionName: CollectionName
        }
        console.log("asdweqqsdklsdk",reqData);
        var collectiongal = await ListingGetSoulbound(reqData);
        console.log("Collection card data icomming", collectiongal)
        SetListData(collectiongal.data.data.ListingCollection[0])
    }



    return (
        <div className="home_header create_single_mulitple">
            <Button className="
      d-none" data-toggle="modal" data-target="#login_modal" id="triggerlogin" ></Button>

            <Header
                color="transparent"
                routes={dashboardRoutes}
                brand={
                    <Link to="/home">
                        <div className="header_logo_align">
            

            </div>
                    </Link>
                }
                leftLinks={<HeaderLinks />}
                changeColorOnScroll={{
                    height: 20,
                    color: "white",
                }}
                {...rest}
            />
            <ScrollToTopOnMount />
            <div className={classes.pageHeader + " inner_pageheader cooled mt-0"}>
                <div>
                    <GridContainer className="mx-0">
                        <GridItem xs={12} sm={12} md={12} className="px-0">
                            <div className="items_bg">
                                <div className="overlay_my_bg"></div>
                                {
                                    ListData.CoverImg === "" &&
                                    <img src={Banner} alt="profile" className="img-fluid" />
                                }
                                {
                                    ListData.CoverImg !== "" &&
                                    <img src={`${config.Back_URL}/Soulbound/${ListData.Address}/CoverImg/${ListData.CoverImg}`} />
                                }

                                <div className="container container_full edit_cover_container">
                                    {/* <p
                    className="edit_cover_text"
                    data-toggle="modal"
                    data-target="#edit_cover_modal"
                  >
                    Edit cover
                  </p> */}
                                </div>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
                <div className="inner_pagh_bg_1">
                    <div className="container container_full">
                        <div className="item_prof">
                            <div className="item_prof_img">

                                {
                                    ListData.ProfileImg === "" &&
                                    <img
                                        src={Banner}
                                        alt="profile"
                                        className="img-fluid items_profile"
                                    />
                                }
                                {
                                    ListData.ProfileImg !== "" &&
                                    <img src={`${config.Back_URL}/Soulbound/${ListData.Address}/ProfileImg/${ListData.ProfileImg}`} />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="container container_full">
                        <div className="row">
                            <div className="col-12 col-md-5 col-lg-6">
                                <p className="my_items_head">{CollectionName}</p>

                                <div className="input-group mb-2 inut_gro_stke_1">
                                    <input
                                        type="text"
                                        className="form-control bgidd"
                                        placeholder="Enter URl"
                                        disabled
                                        value={Address}
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text" id="basic-addon2">
                                            <CopyToClipboard
                                                text={Address}
                                                onCopy={() =>
                                                    toast.success("Address Copied", {
                                                        closeButton: true,
                                                        autoClose: 1500,
                                                        transition: Flip,

                                                        theme: "dark",
                                                    })
                                                }
                                            >
                                                <img
                                                    src={require("../assets/images/copy_icon.png")}
                                                    className="img-fluid cur_pointer"
                                                />
                                            </CopyToClipboard>
                                        </span>
                                    </div>
                                </div>



                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid custom-container mb-5 mt-5">
                <div className="tickets_collection">
                <div className="row">

                    {ListData?.NFTs &&
                        ListData.NFTs.map((Item, index) => {
                            // console.log("Ticketcategory for ticket componrnt", Item);
                            return (
                                <Collectioncard 
                                Item = {Item}/>
                            )
                        })}
                </div>
                </div>
            </div>

            <Footer />

        </div>
    );
}
